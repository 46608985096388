import { headerAuthorization } from '../common-utils'

import { employeeOpenListUrl } from '../const'
import { BaseApi } from '../baseApi'

class EmployeesOpenListApi extends BaseApi {


  getEmployeesList = async () => {
    try {
      const url = `${employeeOpenListUrl}`
      const response = await this.axios.get(
        url,
        {

          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }



}

export default EmployeesOpenListApi
