import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl } from '../const/index'

class EmployeeLeaveHistoryApi extends BaseApi {
  getEmployeeLeaveHistory = async num => {
    try {
      const response = await this.axios.get(
        `${baseUrl}leave_history/?page=${num ? num : 1}`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeLeaveHistoryApi
