import React, { useEffect, useState } from 'react'
import { Col, Form } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'

const GradeForm = observer(({ setVisible, data, update, viewDetail, setRefreshTable }) => {


  const [form] = Form.useForm()
  const [updatedData, setUpdatedData] = useState({})
  const {
    branchModel: { getBranchData, loadBranch, nextBranchPage },
    gradeDetails: {
      setGrade,
      updateGrade,
      loadGrade,
      getGradeData,
      nextGra,
    },
  } = useStore(null)

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    await loadGrade(1, 20, '', true)
    await loadBranch(1, 20, '', true)
  }


  const updateTask = async changeValue => {
    const key = Object.keys(changeValue)[0]
    updatedData[key] = changeValue[Object.keys(changeValue)[0]]
    setUpdatedData(updatedData)
  }
  useEffect(() => {
    form.resetFields()
    if (update) {
      form.setFieldsValue({
        title: data.title,
        value: data?.value.toString(),
        branch: data.branch,
        parent_grade: data.parentGrade
      })
    }
  }, [data, update])


  const submitData = async values => {
    let response = null
    if (update) {
      response = await updateGrade(data.id, updatedData)
    } else {
      response = await setGrade(values)
    }
    if (response?.success) {
      setVisible(false)
      setRefreshTable(true)
      form.resetFields()
      loadGrade(1, 20, '', true)
    }
  }

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={submitData}
      onValuesChange={updateTask}
      id='grade-form'
    >
      <Form.Item
        name='title'
        label='Title'
        rules={[{ required: true, message: 'Title is required field' }, {
          whitespace: true,
          message: 'Can\'t be just whitespace',
        }]}
      >
        <CommonInput
          disabled={viewDetail}
          inputType='text'
          className='w-100 inputField'
          placeholder='Please Enter Title of Grade'
        />
      </Form.Item>
      <Form.Item
        name='value'
        label='Level Value'
        rules={[{ required: true, message: 'Level value is required field' }, {
          whitespace: true,
          message: 'Can\'t be just whitespace',
        }]}
      >
        <CommonInput
          disabled={viewDetail}
          inputType='number'
          className='w-100 inputField'
          placeholder='Please Enter Level Value'
        />
      </Form.Item>
      <Form.Item
        name='parent_grade'
      >
        <Col xs={24} sm={24} >
          <DynamicSelect
            getData={getGradeData}
            loadData={loadGrade}
            nextPage={nextGra}
            queryParam={'title'}
            valueKey1={'title'}
            isForm={true}
            name={'parent_grade'}
            formLabel={'Parent Grade'}
            placeholder='Please select Parent Grade...'
            fetchData={false}
          />
        </Col>
      </Form.Item>
      <Form.Item
        name='branch'
      >
        <Col span={24} >
          <DynamicSelect
            isForm={true}
            name={'branch'}
            placeholder={'Please Select Branch...'}
            getData={getBranchData}
            loadData={loadBranch}
            nextPage={nextBranchPage}
            valueKey1={'title'}
            formLabel={'Branch'}
            disable={data?.isMain}
            queryParam={'title'}
            fetchData={false}
            formRules={[
              { required: true, message: 'Branch is required' },
            ]}
          />
        </Col>
      </Form.Item>
    </Form>
  )
})

export default GradeForm
