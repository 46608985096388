import React, { useState, useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import CustomButton from '@commonComponents/button'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { CommonSelect } from '@commonComponents/select'
import { getAlphabetRegex } from '@utils/common-functions/formValidations'

export interface SectionProps {
  update: boolean;
  data?: {
    title: string;
    formValues: any;
    id: number;
    branch: string;
    department: string;
    parentSection: string;
    description: string;
    isActive: boolean;
  };
  formValues: {
    title: string;
    branchName: string;
    department: string;
    parentSection: string;
    description: string;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setIsUpdate: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setVisible: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRefreshTable: any;
}
const SectionForm = observer(
  ({ update, data, setIsUpdate, setVisible, setRefreshTable }) => {
    const [form] = Form.useForm()
    const [submitLoading, setSubmitLoading] = useState(false)
    const [ismain, setIsmain] = useState(false)

    const {
      sectionModal: { setSectionData, updateSection },
      departmentModal: { loadDepartment, getDepartmentData, nextDep },
      branchModel: { getBranchData, loadBranch, nextBranchPage },
      userInfo: { getcompany },
    } = useStore(null)

    useEffect(() => {
      setIsmain(true)
    }, [])

    useEffect(() => {
      const load = async () => {
        await loadDepartment(1, 20, '', true)
      }
      load()
    }, [ismain])

    useEffect(() => {
      const load = async () => {
        if (ismain) {
          await loadBranch(1, 20, '', true)
        }
      }
      load()
    }, [ismain])

    useEffect(() => {
      form.resetFields()
      if (update) {
        form.setFieldsValue({
          branchName: data.branch,
          department: data.department,
          title: data.title,
          parentSection: data.parentSection,
          description: data.description,
        })
      }
    }, [data, update, form])

    const submitData = async (values: SectionProps['formValues']) => {
      let response = null
      setSubmitLoading(true)

      values['branch'] = getcompany?.details?.branch?.id

      if (update && values.department === data.department) {
        response = await updateSection(data.id, values)
      } else {
        response = await setSectionData(values)
      }
      if (response?.success) {
        setRefreshTable(true)
        setVisible(false)
        form.resetFields()
        setIsUpdate(false)
      }
      setSubmitLoading(false)
      setVisible(false)
    }
    const branch = []
    ismain &&
      getBranchData?.map(e => {
        branch.push({ key: e.id, value: e.title })
      })

    return (
      <Form
        form={form}
        layout='vertical'
        id='section-form'
        onFinish={submitData}
      >
        <Row>
          <Col sm={24} xs={24}>
            <Form.Item
              name='title'
              label='Title'
              rules={[
                { required: true, message: 'Section name is required' },
                {
                  whitespace: true,
                  message: "Can't be just whitespace",
                },
                {
                  message: 'Use Only Alphabets',
                  pattern: getAlphabetRegex(),
                },
              ]}
            >
              <CommonInput
                className='inputField'
                inputType='text'
                placeholder='Title of Section'
              />
            </Form.Item>
          </Col>
          <Col span={13}></Col>
        </Row>
        <Row>
          <Col sm={24} xs={24}>
            <DynamicSelect
              isForm={true}
              name={'branchName'}
              formLabel={'Branch'}
              getData={getBranchData}
              loadData={loadBranch}
              queryParam={'branchName'}
              nextPage={nextBranchPage}
              valueKey1={'title'}
              placeholder='Branch'
              clearMyData={true}
              fetchData={false}
              formRules={[
                {
                  required: true,
                  message: 'Branch name is required',
                },
              ]}
            />
          </Col>
          <Col span={2}></Col>
          <Col xs={24} sm={24}>
            <DynamicSelect
              isForm={true}
              name={'department'}
              formLabel={'Department'}
              nextPage={nextDep}
              getData={getDepartmentData}
              loadData={loadDepartment}
              queryParam={'Department'}
              valueKey1={'title'}
              placeholder='Department'
              clearMyData={true}
              fetchData={false}
              formRules={[
                {
                  required: true,
                  message: 'Department name is required',
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24}>
            <Form.Item
              name='parentSection'
              label='Parent Branch (optional)'
              rules={[
                { required: false, message: 'Parent Branch is required' },
              ]}
            >
              <CommonSelect
                listHeight={125}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder='Please Select a parent Branch'
                data={getBranchData}
                showSearch
                filterOption={(input, option) => {
                  String(option?.children).toLowerCase().includes(input.toLowerCase())
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name='description' label='Description'>
              <CommonInput
                className='inputField'
                inputType='textarea'
                placeholder='Write Description Here..'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className='d-none'>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={update ? 'Update' : 'Add'}
              loading={submitLoading}
              disabled={submitLoading}
              shape='round'
            />
          </Col>
        </Row>
      </Form>
    )
  }
)

export default SectionForm
