import { BaseApi } from '../baseApi'
import { leaveCounts } from '../const/index'
class EmployeeLeaveCountApi extends BaseApi {
  getEmployeeLeaveCount = async (num,id) => {
    try {
      const response = await this.axios.get(
        id ? `${leaveCounts}/?employee_id=${id}` : 
          `${leaveCounts}/?page=${num ? num : 1}`,
      )
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}
export default EmployeeLeaveCountApi
