import React, { useEffect } from 'react';
import { Row, Col, Form, Radio } from 'antd'
import { CommonInput } from '@commonComponents/input'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import SearchFilter from '@commonComponents/SearchFilter';
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'
import { colors } from 'constant/colors';

const { TealishBlue } = colors
interface CategoryFormProps {
  setQueryParam: (data: object) => void;
}

const SearchCategory = observer((props: CategoryFormProps) => {

  const {
    hrCategoryModal: { loadCategory, getCategoriesData },
  }
    = useStore(null)

  useEffect(() => {
    const apiCallHandler = async () => {
      if (!getCategoriesData?.length) {
        await loadCategory()
      }
    }
    apiCallHandler()
  }, [loadCategory])


  return (
    <div>
      <SearchFilter
        {...props}
        children={

          <Row gutter={4}>
            <Col xs={24} sm={12} md={12} lg={7}>
              <Form.Item name={'title'}>
                <CommonInput
                  placeholder='Category Name'
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={1}></Col>
            <Col sm={24} xs={24} md={15} lg={15}>
              <StyledFilterRadioWrapper>
                <StyleRadio>
                  <Form.Item name='category'>
                    <Radio.Group >
                      <Radio value='asset'>Asset Category</Radio>
                      <Radio value='expense'>Expense Category</Radio>
                    </Radio.Group>
                  </Form.Item>
                </StyleRadio>
              </StyledFilterRadioWrapper>
            </Col>
          </Row>
        } />
    </div>
  )
})
export default SearchCategory;


export const StyledFilterRadioWrapper = styled.div`
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #525353; 
    word-spacing: 4px;
  }

  .ant-radio-wrapper {
    .ant-radio {
      .ant-radio-inner {
        background: #ffffff;
        border: 1.2px solid #e2e2e2;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 26.4px;
        width: 24px;
        height: 24px;

        &::after {
          content: unset;
        }
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border: 7px solid ${TealishBlue};

          &:after {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
    }
  }

`

const StyleRadio = styled.div`
  margin-top: 8px !important;
`