import React, { useRef, useState } from 'react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { Form, Row, Space } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import 'antd/dist/antd.css'
import { constRoute } from '@utils/route'
import AllowanceFrom from './allowanceForm'
import SearchAllowance from '@mainPage/Allowence/searchAllowance';
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react-lite'
import CustomButton from '@commonComponents/button'
import { AllowanceUrl } from '@api/const'
import { Table } from '@commonComponents/table-v2'
import CustomDrawer from '@commonComponents/drawer'
import DeletePopUp from '@commonComponents/delete-popup';
import { MdDelete, MdEdit } from 'react-icons/md'
import styled from 'styled-components'
import showColData from '@commonComponents/show-col-data/showColData'
import ShowOnPermission from '@commonComponents/permit'
import { ALLOWANCE_MOD, DELETE_METHOD, PATCH_METHOD, POST_METHOD, VIEW_ALL } from 'constant/constant'

const Allowance = observer(() => {
  const [form] = Form.useForm()
  const [openModal, setOpenModal] = useState(false)
  const [deleteName, setDeleteName] = useState(null)
  const [allowanceId, setAllowanceId] = useState(null)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedAllowance, setSelectedAllowance] = useState({ id: null })
  const [IsUpdate, setIsUpdate] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const tableRef = useRef(null);
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const [resetTable, setResetTable] = useState(false)
  const [queryParam, setQueryParam] = useState()

  const { allowanceModel: { isLoading, deleteAllowance } } = useStore(null)

  const deleteAllowanceData = async () => {
    const response = await deleteAllowance(allowanceId)
    if (response?.success) {
      setIsDeleteModalVisible(false)
      if (totalRecordRemaining == 1) {
        setResetTable(true)
      } else {
        setRefreshTable(true)
      }
      setAllowanceId(null)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      render: showColData
    },
    {
      title: 'Type',
      dataIndex: 'wageType',
      render: showColData
    },
    {
      title: 'Value',
      dataIndex: 'amount',
      render: showColData
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (text, data) => (
        <StyleEDBBtn className='accordingToTheme'>
          <ShowOnPermission mod={ALLOWANCE_MOD} method={PATCH_METHOD}>
            <ActionWrapper>
              <Tooltip minLength={0} title='Edit'>
                <MdEdit id='allowence_edit' onClick={() => {
                  setSelectedAllowance(data)
                  setOpenModal(true),
                    setIsUpdate(true)
                }}
                />
              </Tooltip>
            </ActionWrapper>
          </ShowOnPermission>
          <ShowOnPermission mod={ALLOWANCE_MOD} method={DELETE_METHOD}>
            <ActionWrapper>
              <Tooltip minLength={0} title='Delete'>
                <MdDelete id='allowence_delete' onClick={() => {
                  setIsDeleteModalVisible(true)
                  setDeleteName(data.title)
                  setAllowanceId(data.id)
                }}
                />
              </Tooltip>
            </ActionWrapper>
          </ShowOnPermission>
        </StyleEDBBtn>
      ),
    },
  ]

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className=''>
            <div className='d-flex justify-content-between mb-4'>
              <BreadCrumbs
                list={[
                  { name: 'Dashboard', link: constRoute.home },
                  { name: 'Allowance', link: constRoute.allowance },
                ]}
                pageTitle={'Allowance'}
              >
              </BreadCrumbs>
              <div>
                <ShowOnPermission mod={ALLOWANCE_MOD} method={POST_METHOD}>
                  <CustomButton onClick={() => {
                    setOpenModal(true),
                      setIsUpdate(false),
                      setSelectedAllowance({ id: null })
                  }}
                    title='Add Allowance'
                  />
                </ShowOnPermission>
              </div>
            </div>
          </div>
        </div>
        <ShowOnPermission mod={ALLOWANCE_MOD} method={VIEW_ALL}>
          <SearchAllowance setQueryParam={setQueryParam} />
        </ShowOnPermission>
        <div>
          <div className='table-responsive'>
            <Table
              ref={tableRef}
              dataUrl={AllowanceUrl}
              columns={columns}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              resetTable={resetTable}
              setResetTable={setResetTable}
              queryParam={queryParam}
              setTotalRecordRemaining={setTotalRecordRemaining}
              setPageNumber={data => setPageNumber(data)}
            />
          </div>
        </div>
        <div className='row'>
          <CustomDrawer
            className='addDrawer'
            title={selectedAllowance?.id ? 'Update Allowance' : 'Add Allowance'}
            visible={openModal}
            setVisible={() => {
              setOpenModal(false)
              setSelectedAllowance({ id: null })
              form.resetFields()
            }}
            width={700}
            customFooter={
              <Space>
                <CustomButton
                  htmlType='submit'
                  type='primary'
                  title={'Cancel'}
                  className='cancelButton'
                  onClick={() => {
                    setOpenModal(false)
                    setSelectedAllowance({ id: null })
                  }}
                />
                <CustomButton
                  form='allowance-form'
                  htmlType='submit'
                  type='primary'
                  title={selectedAllowance?.id ? 'Update' : 'Add'}
                  className='addButton'
                />
              </Space>
            }
          >
            <AllowanceFrom
              data={selectedAllowance}
              queryParam={queryParam}
              setOpenModal={setOpenModal}
              setRefreshTable={setRefreshTable}
              IsUpdate={IsUpdate}
              setIsUpdate={setIsUpdate}
            />
          </CustomDrawer>
          <DeletePopUp
            delete={deleteAllowanceData}
            msgTitle='Delete Allowance'
            visibleDelete={isDeleteModalVisible}
            isLoading={isLoading}
            disabled={isLoading}
            okText='Confirm'
            handleCancel={() => setIsDeleteModalVisible(false)}
            msg={deleteName}
          />
        </div>

      </div>
      <div style={{ display: 'none' }}>{pageNumber}</div>
    </div>
  )
})

export default Allowance;

const StyleEDBBtn = styled(Row)`
display:flex;
`

const ActionWrapper = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  &:hover {
   background-color: #dad8d8;
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`
