import React from 'react'
import { Col, DatePicker, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { SearchOutlined } from '@ant-design/icons'
import { CommonInput } from '@commonComponents/input'
import SearchFilter from '@commonComponents/SearchFilter'
import { APPROVED, LARGE, PENDING, REJECTED, STATUS } from 'constant/constant'
import { CommonSelect } from '@commonComponents/select'
import styled from 'styled-components'
import { useStore } from '@stores/root-store'
import { ASSET_HANDLING, UPDATE_METHOD } from '../../../constant/constant'
import { permit } from '@utils/permission/permission'
import { AiFillCalendar } from 'react-icons/ai'
interface AssetHandlingProps {
  setQueryParam: (data: object) => void;
  setResetTable?: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab?: any;
  setResetFilters: any;
  resetFilters: any;
}

const SearchAssetHandling = observer((props: AssetHandlingProps) => {
  const {
    userInfo: { getcompany },
  } = useStore(null)

  return (
    <>
      <div className='row filter-row'>
        {(getcompany.admin ||
          getcompany?.isLead ||
          permit(getcompany, ASSET_HANDLING, UPDATE_METHOD)) && (
          <SearchFilter
            {...props}
            format='YYYY-MM-DD'
            resetFilters={props.resetFilters}
            setResetFilters={props.setResetFilters}
            children={
              <div className='d-lg-flex w-100'>
                <Row gutter={8} className='rowDiv flex-grow-1'>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item name='name'>
                      <CommonInput
                        placeholder='Name'
                        prefix={<SearchOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  {props.activeTab === 'assetrequested' && (
                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item name={STATUS}>
                        <Customselect
                          listHeight={120}
                          placeholder='Status'
                          allowClear
                          size={LARGE}
                          getPopupContainer={trigger => trigger.parentNode}
                          data={[
                            { key: PENDING, value: PENDING },
                            { key: APPROVED, value: APPROVED },
                            { key: REJECTED, value: REJECTED },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {props.activeTab === 'assetsubmission' && (
                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item name='date'>
                        <DatePicker
                          format='YYYY-MM-DD'
                          getPopupContainer={trigger => trigger.parentElement}
                          allowClear={false}
                          className='form-control'
                          placeholder='Select Date'
                          suffixIcon={<AiFillCalendar />}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </div>
            }
          />
        )}
      </div>
    </>
  )
})
export default SearchAssetHandling

const Customselect = styled(CommonSelect)`
  .ant-select-selection-item {
    margin-top: 0px;
  }
`
