import React, { useEffect, useState } from 'react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import styled from 'styled-components'
import { Avatar, Progress, Collapse, Button } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import listIcon from '../../../../assets/img/list-icon.svg'
import blueListIcon from '../../../../assets/img/list-icon-blue.svg'
import boardIcon from '../../../../assets/img/board-icon.svg'
import blueBoardIcon from '../../../../assets/img/board-icon-blue.svg'
import plusIcon2 from '../../../../assets/img/plus-icon-2.svg'
import sprintArrow from '../../../../assets/img/sprint-arrow.svg'
import ListData from './listData'
import { useParams } from 'react-router-dom';
import { useStore } from '@stores/root-store'
import { useLocation } from 'react-router-dom'
import DisplayPicture from '@commonComponents/avatar'
import { observer } from 'mobx-react-lite'
import BoardView from './boardView'
import { MenuOutlined } from '@ant-design/icons'
import SettingSprint from './settingSprint'
import { CommonSelect } from '@commonComponents/select'
import DeletePopUp from '@commonComponents/delete-popup'
import deleteBtnIcon from '../../../../assets/img/delete-basket-icon.svg';
import { colors } from 'constant/colors'

const { TealishBlue, mediumGray,charcoal } = colors


const Board = observer(() => {
  const [activeView, setActiveView] = useState('board')
  const [isOpen, setIsOpen] = useState(false)
  const { Panel } = Collapse
  const { projectID } = useParams();
  const [settingsp, setSettingsp] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [sprintToBeDeletedId, setSprintToBeDeletedId] = useState(null)
  const [activeSprintId, setActiveSprintId] = useState(null)
  const [length, setLength] = useState(null)
  const [sprint , setSprint] = useState(false)
  const location = useLocation()
  const getData = location.state
  const {
    boardDetails: {
      loadBoardDetails,
      getCompleteBoard,
      loadBoardListDetails,
      getBoardListData,
      loadSprintList,
      getSprint,
      deleteSprint,
      isLoading
    },
  } = useStore(null)


  useEffect(() => {
    const load = async () => {
      await loadBoardDetails(projectID)
    }
    load();
  }, [])

  useEffect(() => {
    if(sprint){
      loadSprintList(getCompleteBoard?.[0]?.id)
      setSprint(false)
    }
  },[sprint])

  useEffect(() => {
    const load = async () => {
      getCompleteBoard && await loadSprintList(getCompleteBoard?.[0]?.id)
    }
    load()
  }, [getCompleteBoard])

  useEffect(() => {
    setLength(getSprint?.length)
  },[getSprint])

  useEffect(() => {
    setActiveSprintId(getSprint?.[0]?.id)
  },[getSprint])

  const handleSprintSelection = id => {
    setActiveSprintId(id)
    id != activeSprintId &&  loadBoardListDetails(id)
  }
  const handleclick = () => {
    setSettingsp(true)
  }
  const toggleCollapsed = () => {
    setIsOpen(!isOpen)
  }

  const handleDeleteSprint = id => {
    setSprintToBeDeletedId(id)
    setVisibleDelete(true)
  }

  const deleteSprintHandler = async () => {
    await deleteSprint(sprintToBeDeletedId)
    setVisibleDelete(false)
    await loadSprintList(getCompleteBoard?.[0]?.id)
  }
  const Sprints = Array.isArray(getSprint)
    ? getSprint.map(item => ({
      key: item.id,
      value: (
        <div className='gap-2 d-flex'>
          <div>
            <img src={sprintArrow} alt='sprint icon' />
          </div>
          <div>{item.name}</div>
        </div>
      ),
    }))
    : [];


  return (
    <>
      <div className='page-wrapper' style={{ padding: '0 !important' }}>
        {/* Page Content */}
        <Container className='content container-fluid'>
          {/* Page Header */}
          <div className='page-header'>
            <div className='customTabs row align-items-center'>
              <div>
                <div className='d-flex justify-content-between align-items-start'>
                  <BreadCrumbs
                    list={[
                      { name: 'Dashboard', link: constRoute.home },
                      { name: 'Projects', link: constRoute.projects },
                      { name: 'Project' },
                    ]}
                    pageTitle='Project'
                    children={''}
                  ></BreadCrumbs>
                  <StyleMain className='d-flex'>
                    <p>Lead</p>
                    <StyledAvatar>
                      {getData ? (
                        <Avatar.Group maxCount={2} size='large'>
                          {getData.projectLeader?.map((e, index) => (
                            <StyleAvatar index={index} key={index} length={getData?.length}
                            >
                              <Tooltip title={`${e.firstName} ${e.lastName}`}>
                                <span>
                                  <DisplayPicture
                                    size={35}
                                    src={e.employeeImage}
                                    name={e.firstName}
                                    style={
                                      index === 0
                                        ? { backgroundColor: '#99e6ff' }
                                        : { backgroundColor: '#809fff' }
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </StyleAvatar>
                          ))}
                        </Avatar.Group>
                      ) : (
                        <div>
                          <strong>-</strong>
                        </div>
                      )}
                    </StyledAvatar>
                  </StyleMain>

                  <StyleMain className='d-flex'>
                    <p>Team</p>
                    <StyledAvatar>
                      {getData ? (
                        <Avatar.Group maxCount={2} size='large'>
                          {getData.projectTeam?.map((e, index) => (
                            <StyleAvatar index={index} key={index}>
                              <Tooltip title={`${e.firstName} ${e.lastName}`}>
                                <span>
                                  <DisplayPicture
                                    size={35}
                                    src={e.employeeImage}
                                    name={e.firstName}
                                    style={
                                      index === 0
                                        ? { backgroundColor: '#edafbc' }
                                        : { backgroundColor: '#edb777' }
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </StyleAvatar>
                          ))}
                        </Avatar.Group>
                      ) : (
                        <div>
                          <strong>-</strong>
                        </div>
                      )}
                    </StyledAvatar>
                  </StyleMain>
                </div>
                <SubHeader>
                  <View>
                    <AllTaskHeading>All Tasks</AllTaskHeading>
                    <Choices>
                      <Choice onClick={() => setActiveView('board')}>
                        <img
                          src={activeView == 'list' ? boardIcon : blueBoardIcon}
                          alt='list'
                        />
                        <ChoiceText activeView={activeView} text='board'>
                          Board
                        </ChoiceText>
                      </Choice>
                      <Choice onClick={() => setActiveView('list')}>
                        <img
                          src={activeView == 'list' ? blueListIcon : listIcon}
                          alt='list'
                        />
                        <ChoiceText activeView={activeView} text='list'>
                          List
                        </ChoiceText>
                      </Choice>
                    </Choices>
                  </View>
                  <ProgressDiv>
                    <ProgressHeadig>PROGRESS</ProgressHeadig>
                    <ProgressBar percent={80} strokeColor='#55CE63' />
                  </ProgressDiv>
                </SubHeader>
              </div>
              {
                isOpen &&
                <div className='sprint1'>
                  <CommonSelect
                    placeholder='Select Sprint'
                    allowClear
                    data={Sprints}
                  />
                  <Button type='primary' size='large' shape='round' onClick={handleclick}>Add New Sprint</Button>
                </div>
              }

              <ContentDiv>
                {activeView == 'board' ? (
                  <BoardDiv>
                    <BoardView projectID={projectID} activeSprintId={activeSprintId}/>
                  </BoardDiv>
                ) : (
                  <>
                    <ListDiv>
                      {getBoardListData?.map(data => (
                        <ListData data={data} color={data.color} />
                      ))}
                    </ListDiv>
                  </>
                )}
                {isOpen && <SprintDiv className='sprint antCollapse'>
                  <ProjectName>OMS</ProjectName>
                  <Collapse defaultActiveKey={['1']} collapsible='header'>
                    <Panel
                      key='1'
                      header={
                        <StyledPanelHeader>
                          <p>Space</p>
                        </StyledPanelHeader>
                      }
                    >
                      <>
                        <AddSprintBtn onClick={handleclick}>
                          <div><img src={plusIcon2} /> </div>
                          <div> Add New Sprint</div>
                        </AddSprintBtn>
                        <SprintList>
                          {getSprint?.map(item => (
                            <><SprintText activeSprint={item?.id == activeSprintId} key={item.id} onClick={() => handleSprintSelection(item?.id)}>
                              <SprintIcon src={sprintArrow} alt='sprint icon' />
                              <div className='setInner'>
                                <NameText>{item.name}</NameText>
                                <img style={{cursor: 'pointer'}} onClick={() => {handleDeleteSprint(item?.id)}} src={deleteBtnIcon} alt='_delete'/>
                              </div>
                            </SprintText></>
                          ))}
                        </SprintList>
                      </>
                    </Panel>
                  </Collapse>
                </SprintDiv>}
                <IconDiv>
                  <MenuOutlined onClick={toggleCollapsed} />
                </IconDiv>
              </ContentDiv>
            </div>
            <DeletePopUp
              delete={deleteSprintHandler}
              msgTitle={'Delete Sprint'}
              visibleDelete={visibleDelete}
              okText='Delete'
              isLoading={isLoading}
              handleCancel={() => setVisibleDelete(false)}
              renderMsg={<span>Are you sure you want to delete this Sprint?</span>}
            />
          </div>
        </Container>
      </div>
      <SettingSprint setSettingsp={setSettingsp} settingsp={settingsp} length={length} setSprint={setSprint} boardID={getCompleteBoard?.[0]?.id}/>
      <>
      </>
    </>
  )
})
const IconDiv = styled.span`
font-size: 21px;
margin-top: 14px;
margin-left: -21px;
height: 26px;
color: ${TealishBlue};
`
export default Board
const Container = styled.div`
  .sprint{
    @media screen and (max-width: 1000px) {
      display: none !important;
    }
  }
  .sprint1{
    display: flex;
    gap: 5px;
    margin-top: 10px;
    @media screen and (min-width: 1000px) {
      display: none;
    }
    .ant-btn{
      border-radius: 8px;
      height: 44px;
    }
  }
`

const ProgressDiv = styled.div`
  display: flex;
  width: 70%;
  gap: 12px;
  align-items: center;
  margin-top: 24px;
  @media screen and (min-width: 0px) and (max-width: 777px) {
    width: 100%;
  }
`
const ProgressHeadig = styled.span`
  color: #2f2f2f;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
`
const ProgressBar = styled(Progress)`
  .ant-progress-inner {
    background-color: #dadada;
  }
`
const BoardDiv = styled.div`
  border-radius: 12px;
  display: flex;
  padding: 0;
  overflow-x: scroll;
  flex-basis: 72%;
  flex-grow: 1;
  min-height: 70vh;
`
const View = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const AllTaskHeading = styled.p`
  color: #010101;
  font-family: Outfit;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
`

const Choices = styled.div`
  display: flex;
  gap: 48px;
`
const Choice = styled.div`
  display: flex;
  gap: 6px;
  cursor: pointer;
`
const ChoiceText = styled.p`
  color: ${props => (props.activeView == props.text ? TealishBlue : charcoal)};
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
`
const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
  @media screen and (min-width: 0px) and (max-width: 777px) {
    gap: 0px;
  }
`
const ContentDiv = styled.div`
  display: flex;
`

const SprintDiv = styled.div`
  flex-basis: 28%;
  flex-grow: 1;
  border-left: solid 2px ${mediumGray};
`
const ProjectName = styled.p`
  color: ${TealishBlue};
  font-family: Outfit;
  font-size: 26px;
  font-weight: 500;
  padding: 10px 10px
`
const StyledPanelHeader = styled.div`
  color: #525353;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`
const AddSprintBtn = styled.div`
  background: rgba(45, 113, 216, 0.3);
  padding: 10px;
  display: flex;
  gap: 10px;
  width: -webkit-fill-available;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 18px;
  cursor:pointer;
  align-items: center !important;
  justify-content: center !important;
  &:hover{
    background: rgba(45, 113, 216, 0.5);
  }
`
const SprintIcon = styled.img`
  width: 15px;
  height: 15px;
`
const SprintText = styled.div`
align-items: center;
  display: flex;
  gap: 5px;
  padding: 7px;
  background-color: ${props => (props.activeSprint ? 'rgba(165, 169, 173, 0.3)' : '')};

  &:hover{
    background-color: rgba(165, 169, 173, 0.3);
    border-radius: 8px;
  }
  .setInner{
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
  }
`
const NameText = styled.div`
  color: #525353;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
`

const SprintList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 16px;
  max-height: 100vh;
  overflow-y: auto;
  margin-left: -30px;
`

const ListDiv = styled.div`
border-radius: 12px;
padding: 0;
overflow-x: scroll;
flex-basis: 72%;
flex-grow: 1;
min-height: 70vh;
`

const StyleMain = styled.div`
   margin-right: 120px;  
   p{ 
    margin-right: 33px;
    margin-top: 2px; 
    font-size: 20px;
   } 
   .ant-avatar-group{
   gap: 18px !important;
 }
`

const StyleAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${props => (props.index === 0 ? '-25px' : '')};
  position: ${props => (props.index === 1 ? 'absolute' : '')};
`;



const StyledAvatar = styled.div`
  .ant-avatar-circle {
    width: 42px !important;
    height: 42px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: ${props => (props.index === 0 ? '#99e6ff' : '#809fff')};
  }
`;