import cross from '@assets/img/cross.svg'
import tick from '@assets/img/tick.svg'
import styled from 'styled-components'
import { colors } from 'constant/colors';
const {PaleGrey,TreeGreen,darkRed,DareRed} = colors;

export const presentAbsent = data => {
  return (
    <SetImg>
      {data? <img className='tick' src={tick} alt='true' /> : <img className='cross' src={cross} alt='false' />}
    </SetImg>
  )
};

const SetImg = styled.div`
  img{
    padding: 5px;
  }
  .tick{
    background-color: ${PaleGrey};
    color: ${TreeGreen};
  }
  .cross{
    color: ${darkRed};
    background-color: ${DareRed};
  }
`