import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeLeaveApi } from '@api'
import { notification } from '@utils/notifications'

export const lead = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})

export const employeeLeave = types.model({
  id: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  leaveStartDate: types.maybeNull(types.string),
  leaveEndDate: types.maybeNull(types.string),
  leaveTypeName: types.maybeNull(types.string),
  leaveType: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  employeeFirstName: types.maybeNull(types.string),
  employeeLastName: types.maybeNull(types.string),
  employee: types.maybeNull(types.string),
  lead: types.maybeNull(lead),
  numberOfDays: types.maybeNull(types.number),
  company: types.maybeNull(types.number),
  branch: types.maybeNull(types.number),
  approvedBy: types.maybeNull(types.number),
  rejectedBy: types.maybeNull(types.number),
  isFullLeave: types.maybeNull(types.boolean),
  fullCancelLeave: types.maybeNull(types.boolean),
  revertLeave : types.maybeNull(types.boolean),
  rejectionLeaveReason: types.maybeNull(types.string),
  cancelNumberOfDays:types.maybeNull(types.number),
})

export const employeeLeaveModal = types
  .model({
    data: types.maybeNull(types.array(employeeLeave)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getEmployeeLeaveData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setEmployeeLeaveData = flow(function* (data) {
      try {
        self.loading = true
        yield employeeLeaveApi.setEmployeeLeave(data)
        notification.success('Leave applied successfully!')
        return { success: true }
      } catch (error) {
        const message = error?.response?.data?.message || error?.response?.data
          notification.error(message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadEmployeeLeave = flow(function* (num = 1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeLeaveApi.getEmployeeLeave(num)
        self.data = cast(res.results)
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Employee Leave')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeLeave = flow(function* (data) {
      try {
        self.loading = true
        yield employeeLeaveApi.updateEmployeeLeave( data)
        notification.success('Leave updated successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEmployeeLeave = flow(function* (id) {
      try {
        self.loading = true
        yield employeeLeaveApi.deleteEmployeeLeave(id)
        notification.success('Leave deleted successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const halfCancelLeave = flow(function* (id, data) {
      try {
        self.loading = true
        yield employeeLeaveApi.halfCancelLeave(id, data)
        notification.success('Half Cancellation Applied successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'Something went wrong')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setEmployeeLeaveData,
      loadEmployeeLeave,
      deleteEmployeeLeave,
      updateEmployeeLeave,
      halfCancelLeave,
    }
  })

export function initEmployeeLeaveModal() {
  return employeeLeaveModal.create({})
}
