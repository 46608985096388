import Tooltip from '@commonComponents/CustomToolTip'
import DisplayPicture from '@commonComponents/avatar'
import { NAME } from 'constant/constant'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { checkUUID } from 'utils/common-functions'

const employeeName = (setVisible, setRecord, calledFrom) => {
  const getCircleColor = lastStatus => {
    switch (lastStatus) {
      case 'A':
        return 'red';
      case 'P':
        return 'green';
      case 'L':
        return 'yellow';
      default:
        return 'black';
    }
  }
  return {
    title: NAME,
    fixed: window.innerWidth >= 700 ? ('left' as const) : false,
    id: 1,
    name: 'Name',
    render: record => (
      <div
        className={`table-avatar ${!record?.status && 'disableCursor'
          } d-flex align-items-center avatar-dark-theme`}
        style={{
          cursor:
            record?.status === 'Approved' &&
              (calledFrom == 'termination' || calledFrom == 'resignation')
              ? 'not-allowed'
              : '',
        }}
      >
        <DisplayPictures
          size={35}
          src={record?.employeeImage}
          name={record?.user?.UserFullName || record?.userFullName || record?.fullName || record?.name || record?.employeeFirstName}
          style={{
            backgroundColor: 'white',
            border: `2px solid ${record?.attendanceList?.length > 0
                ? (() => {
                  const lastStatus = record?.attendanceList[record?.attendanceList?.length - 1].status;
                  return getCircleColor(lastStatus);
                })()
                : getCircleColor(record.status)
              }`
          }}
        />
        <div style={{ width: '172px' }}>
          {(calledFrom == 'termination' && record?.status == 'Approved') ||
            (calledFrom == 'resignation' && record?.status == 'Approved') ? (
            <StyledDiv>
              <Tooltip
                title={record?.employeeName || record?.UserFullName || record?.name}
              >
                <DIV calledFrom={calledFrom} status={record?.status}>
                  {record?.employeeName || record?.UserFullName || record?.name}
                </DIV>
              </Tooltip>
            </StyledDiv>
          ) : (
            <Link
              to={
                checkUUID(record?.employeeId)
                  ? `/app/newpage/${record?.employeeId}`
                  : checkUUID(record?.employee)
                    ? `/app/newpage/${record?.employee}`
                    : `/app/newpage/${record?.id}`
              }
              onClick={() => {
                if (setVisible && setRecord) {
                  setRecord(record)
                  setVisible({ visible: true, type: 'update' })
                }
              }}
            >
              <Tooltip placement='topLeft' minLength={1}
                title={record?.name
                  ?? record?.userFullName
                  ?? record?.user?.fullName
                  ?? record?.fullName
                  ?? (record?.employeeFirstName && record?.employeeLastName
                    ? `${record.employeeFirstName} ${record.employeeLastName}`
                    : ''
                  )}
              >
                <StyledDiv>
                  <Span>
                    {record?.name ||
                      record?.userFullName ||
                      record?.user?.fullName ||
                      record?.fullName ||
                      record?.employeeFirstName + ' ' + record?.employeeLastName ||
                      '-'}
                  </Span>
                </StyledDiv>
              </Tooltip>
            </Link>
          )}
          <DesignationDiv className='subTitle'>
            {record?.designation?.title ||
              record?.designation ||
              record?.designationTitle ||
              record?.employeeDesignation
            }
          </DesignationDiv>
        </div>
      </div>
    ),
  }
}

export default employeeName
const DisplayPictures = styled(DisplayPicture)`
  .ant-avatar-circle {
    border: 2px solid red !important;
    line-height: 32px !important;
    span {
      line-height: 32px !important;
    }
  }
`
const DesignationDiv = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`

const DIV = styled.div`
  cursor: ${props =>
    (props.calledFrom == 'termination' && props.status == 'Approved') ||
      (props.calledFrom == 'resignation' && props.status == 'Approved')
      ? 'not-allowed'
      : ''};
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 142px;
`

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`

const Span = styled.div`
  width: 142px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
