import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { dashboardEmployeePayrollApi } from '@api'
import { notification } from '@utils/notifications'

export const DashboardEmployeePayroll = types.model({
  employeeFirstName: types.maybeNull(types.string),
  employeeLastName: types.maybeNull(types.string),
  employeeDesignation: types.maybeNull(types.string),
  netSalary: types.maybeNull(types.number),

})

export const dashboardEmployeePayrollModal = types
  .model({
    data: types.maybeNull(types.array(DashboardEmployeePayroll)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getDashboardEmployeePayrollData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const loadDashboardEmployeePayroll = flow(function* () {
      self.loading = true
      try {
        self.loading = true
        const res = yield dashboardEmployeePayrollApi.getDashboardEmployeePayroll()
        self.data = cast(res)
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Dashboard Employee Payroll')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })


    return {
      loadDashboardEmployeePayroll,
    }
  })

export function initDashboardEmployeePayrollModal() {
  return dashboardEmployeePayrollModal.create({})
}
