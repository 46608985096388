import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function PunchBtnLoader() {
  return (
    <button className={'punchinButton'}>{<Spin indicator={antIcon} />}</button>
  )
}

export default PunchBtnLoader
