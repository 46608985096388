import React from 'react'
import { Form, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { CommonSelect } from '@commonComponents/select'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { EMPLOYEE_NAME } from './constant'
import { SearchOutlined } from '@ant-design/icons'
import {  LARGE, DISMISSAL_STATUS, REJECTED_LOWERCASE, APPROVED_LOWERCASE, PENDING_LOWERCASE } from 'constant/constant'
import SearchFilter from '@commonComponents/SearchFilter'
interface ResignationFormProps {
  setQueryParam: any;
  setRefreshTable: any;
  setResetTable?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchResignation = observer((props: ResignationFormProps) => {
    return (
      <div className='row filter-row input-dark-theme commonSelect-dark-theme'>
          <SearchFilter
            {...props}
            children={
              <div className='w-100'>
                <Row gutter={10} className='w-100'>
                  <Col xs={24} sm={8} lg={6}>
                    <Form.Item name={EMPLOYEE_NAME}>
                      <CommonInput
                        className='inputField'
                        placeholder='Employee'
                        prefix={<SearchOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={6}>
                    <Form.Item name={DISMISSAL_STATUS}>
                      <Customselect
                        listHeight={120}
                        placeholder='Status'
                        allowClear
                        size={LARGE}
                        getPopupContainer={trigger => trigger.parentNode}
                        data={[
                          { key: PENDING_LOWERCASE, value: 'Pending' },
                          { key: APPROVED_LOWERCASE, value: 'Approved' },
                          { key: REJECTED_LOWERCASE, value: 'Rejected' },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>} />
      </div>
    )
  }
)
export default SearchResignation

const Customselect = styled(CommonSelect)`
.ant-select-selection-item {
  margin-top: 0px !important;
  }
`