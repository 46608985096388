import { employmentCompanyUrl } from '../const'
import { BaseApi } from '../baseApi'
export default class EmploymentCompanyApi extends BaseApi{
  getEmploymentCompany = async (num,pageSize,queryParam={}) => {
    try {
      let url = employmentCompanyUrl
      url += `?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
     
} 
