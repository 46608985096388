import React, { useState, useRef, useMemo, useEffect } from 'react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import 'antd/dist/antd.css'
import CustomButton from '@commonComponents/button'
import { Space } from 'antd'
import 'antd/dist/antd.min.css'
import ResignationapplyForm from './resignationapplyForm'
import { Table } from '@commonComponents/table-v2'
import SearchResignation from './searchResignation'
import CustomDrawer from '@commonComponents/drawer'
import styled from 'styled-components'
import { getColumns } from './columns'
import {
  ADD_BUTTON_CLASS,
  ADD_DRAWER,
  APPLY,
  APPLY_RESIGNATION,
  CANCEL_BUTTON_CLASS,
  CANCEL_TITLE,
  DASHBOARD,
  POST_METHOD,
  PRIMARY,
  RESET,
  RESIGNATION_MOD,
  SUBMIT,
  UPDATE,
  UPDATE_RESIGNATION,
  VIEW_ALL
} from 'constant/constant'
import { RESIGNATIN_TITLE, RESIGNATION_FORM } from './constant'
import DeletePopUp from '@commonComponents/delete-popup'
import { resignationUrl } from '@api/const'
import ShowOnPermission from '@commonComponents/permit'
import getAuthRoles from '@utils/common-functions'
import lead from '@api/lead/lead'
const Resignation = observer(() => {
  const [isApplyresignation, setApplyresignation] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [update, setUpdate] = useState(false)
  const [queryParam, setQueryParam] = useState({})
  const [btnLoader, setBtnLoader] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [isResignationDisable, setIsResignationDisable] = useState(false)
  const [resetTable, setResetTable] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [visibleDelete, setVisibleDelete] = useState(false)

  const { employee, Admin } = getAuthRoles()
  const tableRef = useRef(null);

  const {
    resignationModel: { deleteResignation, isLoading ,loadResignation ,getResignationData},
    themesModel: { getColorsData }
  } = useStore(null)

  const onEdit = val => {
    setCurrentData(val)
    setApplyresignation(true)
    setUpdate(true)
  }

  useEffect(() => {
    setIsResignationDisable(false)
    loadResignation()
   }, [refreshTable])

  const onAdd = () => {
    setCurrentData({})
    setUpdate(false)
    setApplyresignation(true)
  }


  const deleteResignHandler = async () => {
    const res = await deleteResignation(currentId?.id, currentId?.employeeId)
    if (res.success) {
      setCurrentId('')
      setVisibleDelete(false)
      setRefreshTable(true)
    }
  }

  const onDelete = row => {
    setCurrentId(row)
    setVisibleDelete(true)
  }

  const columns = useMemo(() => getColumns(onEdit, setRefreshTable, setCurrentData, onDelete, setIsResignationDisable, getResignationData), [onEdit,getResignationData])
  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header mb-4'>
          <div className='row align-items-center justify-content-center'>
            <div className='d-flex justify-content-between mb-3 mb-sm-0'>
              <BreadCrumbs
                list={[
                  { name: DASHBOARD, link: constRoute.home },
                  { name: RESIGNATIN_TITLE, link: constRoute.resignation },
                ]}
                pageTitle={RESIGNATIN_TITLE}
              >
              </BreadCrumbs>
              <ShowOnPermission mod={RESIGNATION_MOD} method={POST_METHOD}>
                {(employee || lead) && !Admin && (
                  <CustomButton
                    disabled={isResignationDisable}
                    title={`Apply ${RESIGNATIN_TITLE}`}
                    onClick={onAdd}
                  />
                )}
              </ShowOnPermission>
            </div>
            <StyledDiv>
              <ShowOnPermission mod={RESIGNATION_MOD} method={VIEW_ALL}>
                <SearchResignation
                  setRefreshTable={setRefreshTable}
                  setQueryParam={setQueryParam}
                  setResetTable={setResetTable}
                />
              </ShowOnPermission>
            </StyledDiv>
          </div>
          <div className='table-responsive'>
            <ResignationTable
              ref={tableRef}
              dataUrl={resignationUrl}
              columns={columns}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              queryParam={queryParam}
              setResetTable={setResetTable}
              resetTable={resetTable}
              rowClassName={record => (record.status === 'Approved' ? 'approved-row' : '')}
              theme={getColorsData?.theme}
            />
          </div>
        </div>
        <div>
          <CustomDrawer
            className={ADD_DRAWER}
            title={update ? UPDATE_RESIGNATION : APPLY_RESIGNATION}
            setVisible={setApplyresignation}
            visible={isApplyresignation}

            width={400}
            customFooter={
              <Space>
                <CustomButton
                  form={RESIGNATION_FORM}
                  htmlType={RESET}
                  type={PRIMARY}
                  title={CANCEL_TITLE}
                  className={CANCEL_BUTTON_CLASS}
                  onClick={() => setApplyresignation(false)}
                />
                <CustomButton
                  form={RESIGNATION_FORM}
                  htmlType={SUBMIT}
                  type={PRIMARY}
                  title={update ? UPDATE : APPLY}
                  // title="button"
                  loading={btnLoader}
                  disabled={btnLoader}
                  className={ADD_BUTTON_CLASS}
                />
              </Space>
            }
          >
            <ResignationapplyForm
              isUpdate={update}
              setApplyresignation={setApplyresignation}
              closeModal={setApplyresignation}
              setRefreshTable={setRefreshTable}
              currentData={currentData}
              setBtnLoader={setBtnLoader}
            />
          </CustomDrawer>
          <DeletePopUp
            delete={deleteResignHandler}
            msgTitle={'Delete Resignation'}
            visibleDelete={visibleDelete}
            isLoading={isLoading}
            okText='Confirm'
            handleCancel={() => setVisibleDelete(false)}
            renderMsg='Are you sure you want to delete  Your Resignation'
          />
        </div>
      </div>
    </div>
  )
})

export default Resignation

const StyledDiv = styled.div`
margin-top:12px !important;
`
const ResignationTable = styled(Table)`
  tbody tr.approved-row td {
    background-color: ${props => props.theme === 'dark' ? '#170c0c' : 'rgb(245, 242, 242)'} !important;
    cursor:not-allowed !important;
  }
`

