import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl } from '../const/index'
class ClientLogsApi extends BaseApi {
  getClientLogs = async num => {
    try {
      const response = await this.axios.get(
        `${baseUrl}client_logs/?page=${num ? num : 1}`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ClientLogsApi
