/*eslint prefer-const: "error"*/

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function humanize(str: any) {
  if(typeof (str) != 'string') return str
  const frags = str?.split(str?.includes('_') ? '_' : '-')
  let i = 0
  for (i = 0; i < frags?.length || 0; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags?.join(' ')
}

export function numberFormat(num: number) {
  if (num) {
    return new Intl.NumberFormat().format(num)
  }
  return '0'
}