import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import PhoneInput from 'react-phone-input-2'
import { isCurrentUsersId } from '@utils/common-functions'
import CustomButton from '@commonComponents/button'
import { MdAddCircleOutline, MdClose } from 'react-icons/md'
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { colors } from 'constant/colors'

const { TealishBlue, lightGray, black } = colors

interface EmergencyData {
  title: string;
  relation: string;
  contact_number_1: string;
  contact_number_2: string;
  employee_id?: string;
}

interface EmergencyInfo {
  id: string;
  employeeId: string;
  title: string;
  relation: string;
  contactNumber1: string;
  contactNumber2: string;
}

interface Emergency {
  empId?: string;
  closeDrawer: () => void;
}

interface EmergencyForm {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: any;
  closeDrawer: () => void;
  data?: EmergencyInfo;
  formId?: string;
  method: string;
  empId: string;
}

const Emergency = observer(({ empId, closeDrawer }: Emergency) => {
  const [showForm, setShowForm] = useState(false)
  const ref = useRef(null)
  const canModify = isCurrentUsersId(empId)

  const {
    emergencyDetails: {
      updateEmergencyDetails,
      loadEmergencyDetails,
      setEmergencyDetails,
      deleteEmergencyDetails,
      emergencyData,
      isLoading,
    },
    themesModel: { getColorsData },
  } = useStore(null)
  const isDarkTheme = getColorsData?.theme === 'dark'

  const load = async () => {
    await loadEmergencyDetails(empId)
  }

  useEffect(() => {
    if (!emergencyData?.length) {
      load()
    }
  }, [empId])

  const addForm = () => {
    setShowForm(true)
    setTimeout(() => {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      })
    }, 500)
  }

  useEffect(() => {
    if (!emergencyData?.length && !showForm) {
      setShowForm(true)
    }
  }, [emergencyData, showForm])

  const onUpdate = async (data: EmergencyData, id: string) => {
    const res = await updateEmergencyDetails(id, data)
    if (res?.success) {
      load()
    }
  }

  const onCreate = async (formData: EmergencyData) => {
    const data = { ...formData }
    data['employee_id'] = empId
    const res = await setEmergencyDetails(data)
    if (res?.success) {
      setShowForm(false)
      load()
    }
  }

  const onDelete = async (id: string) => {
    const res = await deleteEmergencyDetails(id)
    if (res?.success) {
      load()
    }
  }

  if (!emergencyData?.length && isLoading) {
    return <SkeletonRingLoader width='100%' height='50px' align='center' />
  }

  return (
    <Wrapper ref={ref} isDarkTheme={isDarkTheme}>
      <div className={'addMore'}>
        <AddButton onClick={addForm} isDarkTheme={isDarkTheme} disabled={!canModify}>
          <MdAddCircleOutline
            fill={getColorsData?.bodyColor || 'blue'}
            className='add-icon'
          />
        </AddButton>
      </div>

      {/* Mapping over fetched education details to render a form for each one to update it */}
      {emergencyData?.map((data, index) => (
        <Fragment key={data.id}>
          <div
            className='d-flex align-items-center mb-1 mt-3 details'
            style={{ gap: '16px' }}
          >
            <h5 className='titleName mt-2' style={{ whiteSpace: 'nowrap' }}>
              Emergency Contact {index + 1}
            </h5>
            <div className='separator' />
            <button
              type='button'
              className='deleteBtn d-flex align-items-center'
              onClick={() => onDelete(data?.id)}
              disabled={!canModify}
            >
              <MdClose className='deleteIcon' />
            </button>
          </div>
          <EmergencyContactForm
            data={data}
            onSubmit={formData => onUpdate(formData, data?.id)}
            method='Update'
            closeDrawer={closeDrawer}
            empId={empId}
          />
        </Fragment>
      ))}

      {showForm && (
        <>
          <div
            className='d-flex align-items-center mb-1 mt-3 details'
            style={{ gap: '16px' }}
          >
            <h5 className='titleName mt-2' style={{ whiteSpace: 'nowrap' }}>
              Emergency Contact {(emergencyData?.length || 0) + 1}
            </h5>
            <div className='separator' />
            <button
              type='button'
              className='deleteBtn d-flex align-items-center'
              onClick={() => setShowForm(false)}
              disabled={!canModify}
            >
              <MdClose className='deleteIcon' />
            </button>
          </div>
          <EmergencyContactForm
            formId='add-emergency-info'
            onSubmit={onCreate}
            method='Add'
            closeDrawer={closeDrawer}
            empId={empId}
          />
        </>
      )}
    </Wrapper>
  )
})

export default Emergency

const EmergencyContactForm = observer(
  ({ data, formId, onSubmit, method, closeDrawer, empId }: EmergencyForm) => {
    const [form] = Form.useForm()
    const {
      themesModel: { getColorsData },
    } = useStore(null)
    const buttonColor = getColorsData?.bodyColor
    const canModify = isCurrentUsersId(empId)

    const setFieldValues = () => {
      form.setFieldsValue({
        title: data?.title || '',
        relation: data?.relation || '',
        contact_number_1: data?.contactNumber1 || '',
        contact_number_2: data?.contactNumber2 || '',
      })
    }

    useEffect(() => {
      setFieldValues()
    }, [data])

    return (
      <Form
        id={data?.id || formId}
        layout='vertical'
        onFinish={onSubmit}
        form={form}
        disabled={!canModify}
      >
        <Labelalign gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['title']}
              label='Name'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Name'),
                validateNoWhiteSpace(),
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['relation']}
              label='Relation'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Relationship'),
                validateNoWhiteSpace(),
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
        </Labelalign>
        <Labelalign gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['contact_number_1']}
              label='Phone no 1'
              style={{
                position: 'relative',
              }}
              rules={[validateRequired('Phone Number 1')]}
            >
              <PhoneInput
                country={'pk'}
                placeholder={'+92 3XX-XXXXXXXX'}
                buttonStyle={{ borderWidth: '1px', zIndex: 9 }}
                disabled={!canModify}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['contact_number_2']}
              label='Phone no 2'
              style={{
                position: 'relative',
              }}
            >
              <PhoneInput
                country={'pk'}
                placeholder={'+92 3XX-XXXXXXXX'}
                buttonStyle={{ borderWidth: '1px', zIndex: 9 }}
                disabled={!canModify}
              />
            </Form.Item>
          </Col>
        </Labelalign>
        <Labelalign gutter={24}>
          <Aligncontent>
            <Styledbutton
              title='Cancel'
              htmlType='button'
              size='small'
              type='primary'
              onClick={closeDrawer}
              style={{ backgroundColor: buttonColor }}
            />
            <Styledbutton
              form={data?.id || formId}
              title={`${method} Detail`}
              htmlType='submit'
              size='small'
              key='submit'
              type='primary'
              style={{ backgroundColor: buttonColor }}
            />
          </Aligncontent>
        </Labelalign>
      </Form>
    )
  }
)

const AddButton = styled.button<{isDarkTheme?: boolean}>`
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props?.isDarkTheme ? '#000' : ''};

  .add-icon {
    width: 20px;
    height: 20px;
  }
`

const Wrapper = styled.div<{ isDarkTheme?: boolean }>`
  padding: 12px 0;
  .addMore {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin: -23px 0 20px 0;
    z-index: 12;
    position: relative;
    img {
      cursor: pointer;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: ${TealishBlue};
      word-spacing: 0px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
  .separator {
    height: .5px;
    flex-grow: 1;
    background-color: ${props => props?.isDarkTheme ? '#fff' : '#1f1f1f'};
  }
  .details {
    padding: 0 16px 0 16px;
  }
  .titleName {
    color: ${props => props?.isDarkTheme ? '#fff' : '#000'};
    white-space: nowrap;
  }
  .deleteBtn {
    outline: none;
    border: none;
    background-color: ${props => props?.isDarkTheme ? black : lightGray};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;

    :hover {
      background-color: #fa9494;
    }
  }

  .deleteIcon {
    width: 18px;
    height: 18px;
    fill: #f42c2c;
  }
`

const Labelalign = styled(Row)`
  padding: 0 16px 0 16px !important;
  .ant-form-item-label {
    padding: 0 3px 3px !important;
  }
`

const Aligncontent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
`

const Styledbutton = styled(CustomButton)`
  border-radius: 5px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  min-width: 100px;
  font-weight: 500 !important;
  padding: 16px;

  &:hover {
    color: white !important;
  }
`
