import { flow, types } from 'mobx-state-tree'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { userRoleApi } from '../../api'


export const UserRole = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  allowedMethod: types.frozen(),
})


export const userRoleDetails = types
  .model({
    Data: types.maybeNull(types.array(UserRole)),
    SingleData: types.maybeNull(UserRole),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get getUserRoleInfo() {
      return toJS(self.Data)
    },
    get getSingleUserRoleInfo() {
      return toJS(self.SingleData)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setUserRoleDetails = flow(function* (data) {
      try {
        yield userRoleApi.setUserRoleDetails(data)
        notification.success('User Role Added Successfully')
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading =false
      }
    })

    const loadUserRoleDetails = flow(function* () {
      self.loading = true
      try {
        self.loading = true
        const res = yield userRoleApi.getUserRoleDetails()
        self.Data = res
      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Role';
        notification.error(message);
      } finally {
        self.loading = false
      }
    })
    const updateUserRoleDetails = flow(function* (data, recordId) {
      try {
        self.loading = true
        const res = yield userRoleApi.updateUserRoleDetails(data, recordId)
        self.Data = res
        notification.success('User Role Updated Successfully')
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteUserRoleDetails = flow(function* (id) {
      try {
        yield userRoleApi.deleteUserRoleDetails(id)
        const idx = self.Data.findIndex(data => data.id === id)
        self.Data.splice(idx, 1)
        notification.success('User Role Deleted Successfully')
      }  finally {
        self.loading = false
      }
    })
    const loadSingleUserRoleDetails = flow(function* (id) {
      self.loading = true
      try {
        self.loading = true
        const res = yield userRoleApi.getSingleUserRoleDetails(id)
        self.SingleData = res
      }finally {
        self.loading = false
      }
    })
    return {
      setUserRoleDetails,
      loadUserRoleDetails,
      deleteUserRoleDetails,
      updateUserRoleDetails,
      loadSingleUserRoleDetails,
    }
  })

export function initUserRoleDetails() {
  return userRoleDetails.create({})
}
