import { useLocation } from 'react-router-dom'
import React from 'react'
import Routing from 'components/router_service'
import { observer } from 'mobx-react-lite'
import { constRoute } from '@utils/route'
import PrivateLayout from './PrivateLayout'
import ScrollToTop from './ScrollToTop'
import Styled from 'styled-components'

export const PublicLayout: React.FC = () => {
  return (
    <StyledDashboardWrapper id='OMS-dashboard'>
      <ScrollToTop />
      <Routing />
    </StyledDashboardWrapper>
  )
}

const DefaultLayout = observer(() => {
  const router = useLocation()
  const path = router.pathname

  const publicPages = [
    constRoute.login,
    constRoute.register,
    constRoute.forgotPassword,
    constRoute.resendVerification,
  ]

  return publicPages.includes(path) ? <PublicLayout /> : <PrivateLayout />
})

export default DefaultLayout

const StyledDashboardWrapper = Styled.div`
  z-index: 54;
`