import { flushTokenFromCookies } from '@api/common-utils'
import { Col, Dropdown, Menu, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logos from '../../../assets/img/eng-logo.svg'
import logoOne from '../../../assets/img/eng-logo-one.svg'
import { LogoutOutlined, SettingOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons'
import { useStore } from '@stores/root-store'
import useWindowSize from '@utils/hooks/useWindowSize'
import style from '../style.module.scss'
import { observer } from 'mobx-react'
import { requestForToekn, onMessageListener } from './firebase';
import { notification } from '../../../../src/utils/notifications/index'
import styled from 'styled-components'
import DisplayPicture from '@commonComponents/avatar'
import CustomButton from '@commonComponents/button'
import { constRoute } from '@utils/route'
import { notification as antdNotification } from 'antd'

export interface PopupData {
  title: string;
  body: string;
  duration?: number;
  onContinue?: () => void;
}


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const Header = observer(({ setCollapsed, collapsed, logo, isHeader }: any) => {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [notificationInfo, setNotification] = useState<PopupData>({
    title: '',
    body: '',
    duration: 2,
    onContinue: null,
  });
  const [visible, setVisible] = useState(false)
  const [load, setLoad] = useState(false)
  
  useEffect(() => {
    requestForToekn()
  }, [])
  const handleClick = async () => {
    antdNotification.close('info')
  }

  function showPopup(data: PopupData) {
    setNotification({
      title: data?.title || '',
      body: data?.body || '',
      duration: 300,
      onContinue: handleClick
    })
    setShowAlert(true)
  }

  onMessageListener().then(payload => {

    const { data } = payload
    if (data?.check) {
      showPopup(data)
    } else {
      setNotification({
        title: data?.title || '',
        body: data?.body || '',
        duration: null
      })

      setShow(true)
    }
  })

  const navigate = useNavigate()
  const onLogout = () => {
    localStorage.setItem('userRegister', 'true')
    flushTokenFromCookies()
    localStorage.removeItem('token')
    localStorage.removeItem('isHierarchy');
    localStorage.removeItem('queryStates');
    window.location.pathname = '/login'
  }
  const data = useWindowSize().width
  const {
    userInfo: { getcompany, onBoardUser, data: userData },
    profileDetails: { profileData },
    emailModal: { setEmail }
  } = useStore(null)

  useEffect(() => {
    if (data < 600) {
      setCollapsed(true)
    }
  }, [data])

  useEffect(() => {
    async function load() {
      await onBoardUser();
    }
    load();
  }, [profileData])

  const dropdownMenu = (
    <Menu className={style.logoutCard}>
      {userData?.id && (
        <Menu.Item
          onClick={() => {
            navigate(`app/newpage/${userData?.id}`)
          }}
        >
          <UserOutlined style={{ marginRight: '5px' }} />
          Profile
        </Menu.Item>
      )}
      <Link to={constRoute.settings}>
        <Menu.Item
        >
          <SettingOutlined style={{ marginRight: '5px' }} />
          Settings
        </Menu.Item>
      </Link>
      <Menu.Item onClick={onLogout}>
        <LogoutOutlined style={{ marginRight: '5px' }} />
        Logout
      </Menu.Item>
    </Menu>
  )

  const submitData = async () => {
    const data = getcompany?.user?.email
    let response = null
    setLoad(true)
    response = await setEmail({ email: data })
    setLoad(false)
    if (response?.success) {
      localStorage.removeItem('token')
      window.location.pathname = '/changePassword'
    }
  }

  return (
    <>
      {showAlert &&
        notification.alertNotification(notificationInfo)
      }
      {show &&
        notification.firebaseInfo(notificationInfo)
      }

      <DivWrapper className='d-flex justify-content-between oms-header'
      >
        {/* Logo */}
        <div className='d-flex align-items-center'>
          <Icon logo={logo} isHeader={isHeader}
            onClick={() => setCollapsed(!collapsed)}
            className=' p-3 fs-5 d-flex align-items-center'
          />
          <Link to='/'>
            {logo && isHeader?.length ? <img src={logoOne} alt='logo' className={style.enigmatixLogo} /> : <img src={logos} alt='logo' className={style.enigmatixLogo} />}
          </Link>
        </div>
        <ul className='nav user-menu d-flex align-items-center' >
          <li
            className='nav-item menu-item-dropdown'
          >
            <Row style={{ marginLeft: '4px' }} className='align-items-center'>
              <div className={style.profile}>
                <Dropdown overlay={dropdownMenu} trigger={['click']}>
                  <a onClick={e => e.preventDefault()}>
                    <DisplayPicture size={40} src={getcompany?.employeeImage} name={getcompany?.user?.firstName?.[0]} passingFrom='header' />
                  </a>
                </Dropdown>
                <div className={style.active}></div>
              </div>
            </Row>
          </li>
        </ul>
      </DivWrapper>
      <Modal style={{
        width: '300px',
        textAlign: 'center'
      }}
        title={'Confirm Change Password'}
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        footer={false}
        destroyOnClose={true}
      >
        <p>Do you Really want to Change your Password? <br />
          If yes, click on 'Change' and a token will be sent to your email
          and you will be
          redirected to Change Password Page.</p>
        <br />
        <Row>
          <Col span={10}></Col>
          <Col span={4}>
            <CustomButton
              type='primary'
              htmlType='submit'
              title='Change'
              loading={load}
              onClick={submitData}
            ></CustomButton>
          </Col>
        </Row>
      </Modal>
    </>
  )
})

export default Header

const Icon = styled(MenuOutlined)`
svg{
  color: ${props => props.logo && props.isHeader ? 'white' : 'black'} !important;
}
`
const DivWrapper = styled.div`

	border-bottom: 1px solid transparent;   
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	height: 60px;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 55 ;
  .user-menu{
    .ant-card{
    background-color:red !important;
    }
  }
	.menu-item-dropdown {
		display: flex;
		align-items: center;

		.mobile {
			display: none;

			@media only screen and (max-width: 575.98px) {
				display: block;
			}
		}

		.desktop {
			display: block;

			@media only screen and (max-width: 575.98px) {
				display: none !important;
			}
		}
	}
	@media (max-width:430px) {
		div{
			span[role='img']{
				svg{
					width: 15px !important;
				}
			}
			a{
				img{
					width: 54px;
				}
			}
		}
		ul{
			padding-right: 4px !important;
			display: flex;
			li{
				flex-basis: 30% !important;
			}
		}
	}


.header .header-left {
	float: left;
	height: 60px;
	padding: 0 20px;
	position: relative;
	text-align: center;
	width: 230px;
	z-index: 1;
	transition: all 0.2s ease-in-out;
}

.header .header-left .logo {
	display: inline-block;
	line-height: 60px;
}

.header .navbar-nav .badge {
	position: absolute;
	right: 7px;
	top: 4px;
}

.header .dropdown-menu>li>a {
	position: relative;
}

.header .dropdown-toggle::after {
	display: none;
}

.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
	content: "";
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.user-menu {
	float: right;
	margin: 0;
	position: relative;
	z-index: 99;
	padding-right: 24px;

	li {
		padding-inline: 12px;
		@media (max-width:430px) {
			padding: 4px;
		}

	}
}

.user-menu.nav>li>a {
	color: #fff;
	font-size: 15px;
	line-height: 60px;
	padding: 0 15px;
	height: 60px;
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav>li>a .badge {
	color: #fff;
	font-weight: 700;
	position: absolute;
	right: 3px;
	top: 6px;
}

.user-menu.nav>li>a .badge {
	background-color: #7460ee;
}

.user-menu.nav>li>a>i {
	font-size: 20px;
	line-height: 60px;
}

.user-menu.nav>li>a .badge {
	color: #fff;
	font-weight: 700;
	position: absolute;
	right: 3px;
	top: 6px;
}

.user-menu.nav>li>a .badge {
	background-color: #7460ee;
}

.user-menu.nav>li>a>i {
	font-size: 20px;
	line-height: 60px;
}
.user-menu .user-img .status {
	bottom: 12px;
}
.nav-dropdown {
	position: relative;
	margin: 0;
	padding: 6px 0;
	text-align: left;
	list-style-type: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 2px;
	outline: none;
	margin-top: 14px;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}
`