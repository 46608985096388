import { getAuthorizationHeader, headerAuthorization } from '../common-utils'

import { baseUrl,clientUrl } from '../const'
import { BaseApi } from '../baseApi'

class ClientApi extends BaseApi {
  setClientDetails = async data => {
    try {
      const response = await this.axios.post(`${baseUrl}client/`, data, {
        headers: { 
          'content-type': 'multipart/form-data',
          Authorization: getAuthorizationHeader() 
        },
        cancelToken: this.cancelToken,
      })

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getClientDetails = async (num ,pageSize, queryParam={}) => {
    try {
      const url = `${clientUrl}?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  filterClientDetails = async (
    clientId, firstName, LastName, company, num
  ) => {
    try {
      let url = clientUrl 
        
      url += `?clientID=${clientId}&first_name=${firstName}&last_name=${LastName}&company=${company}&page=${
        num ? num : 1}`
      const response = await this.axios.get(
        url,
        headerAuthorization(this)
      )
  
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateClientDetails = async (data, recordId) => {
    try {
      const response = await this.axios.patch(
        `${baseUrl}client/${recordId}/`,
        data,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteClientDetails = async (id: number) => {
    try {
      const response = await this.axios.delete(`${baseUrl}client/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ClientApi
