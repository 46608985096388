import { BaseApi } from '../baseApi'
import { sectionUrl } from '../const/index'

class SectionApi extends BaseApi {
  setSection = async data => {
    try {
      const response = await this.axios.post(sectionUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getSection = async (num, pageSize, queryParam = {}) => {
    try {
      let url = sectionUrl
      url += `?page=${num ? num : 1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateSection = async (id, data) => {
    try {
      const response = await this.axios.patch(
        `${sectionUrl+id}/`,
        {
          ...data,
        },
       
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteSection = async id => {
    try {
      const response = await this.axios.delete(`${sectionUrl+id}/`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default SectionApi
