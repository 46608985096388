import React, { useEffect, useState } from 'react'
import { Form, Col, Row, DatePicker, Card } from 'antd'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { imgBaseUrl } from '@api/const'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import moment from 'moment'
import { CommonSelect } from '@commonComponents/select'
import styled from 'styled-components'
import { FIRST_NAME_PARAM, LAST_NAME_PARAM } from 'constant/constant'
import Dragger from 'antd/lib/upload/Dragger'
import { StyleAttach } from '@mainPage/Asset/AssetForm'
import { DeleteOutlined } from '@ant-design/icons';
import { getAlphabetRegex, validateRequired } from '@utils/common-functions/formValidations'
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const ClientForm = observer(
  ({ update, data, setVisible, setRefreshTable }: any) => {
    const [form] = Form.useForm()
    const [fileList, setFileList] = useState([])
    const [updatedData, setUpdatedData] = useState({})
    const [countryCode, setCountryCode] = useState('')
    const [attachmentList, setattachmentList] = useState(null)

    const {
      clientDetails: { setClientInfo, updateClientDetails },
      employeeRole: { getEmployeeRoleData, loadEmployeeRole },
      branchModel: { getBranchData, loadBranch, nextBranchPage },
      employeeDetails: { getEmployeeData, loadEmployeeDetails, nextEmp },
      userInfo: { getcompany },
    } = useStore(null)

    useEffect(() => {
      async function load() {
        if (!getEmployeeRoleData?.length) {
          await loadEmployeeRole(true)
        }
        if (!getBranchData?.length) {
          await loadBranch(1, 20, '', true)
        }
        if (!getEmployeeData?.length) {
          await loadEmployeeDetails(1, 10, '', true)
        }
      }
      load()
    }, [])




    const children = getEmployeeRoleData?.map(({ id, title }) => ({ key: id, value: title }));


    useEffect(() => {
      form.resetFields()
      setFileList([])
      if (update) {
        form.setFieldsValue({
          first_name: data.firstName,
          last_name: data.lastName,
          ClientID: data.clientId,
          client_image: imgBaseUrl + data.clientImage,
          client_owner: data.clientOwner.firstName + ' ' + (data?.clientOwner?.lastName || ''),
          mobile_number: data.mobileNumber,
          status: data.status,
          email: data.email,
          client_role: data.clientRole,
          start_date: data.startDate ? moment(data.startDate) : '',
          branch: data.branch,
        })
        imgUpdate(data)
      }
    }, [data, update])

    const imgUpdate = data => {
      if (data.client_image?.lastIndexOf('/')) {
        const index = data.client_image?.lastIndexOf('/') + 1
        const image = {
          name: data.client_image?.substr(index, data.client_image?.length),
          url: imgBaseUrl + data.client_image,
        }
        setFileList([image])
      }
    }

    const submitData = async (values: any) => {
      let response = null
      values.branch = getcompany?.details?.branch?.id
      if (data?.isMain) {
        values.branch = getcompany?.details?.branch?.id
      }
      const formData = new FormData()
      if (update) {
        if (updatedData['client_image']) {
          formData.append('client_image', fileList[0])
        } else if (updatedData['client_image'] === '') {
          formData.append('client_image', '')
        }

        if (updatedData) {
          const keys = Object.keys(updatedData)
          for (let i = 0; i < keys?.length; i++) {
            if (keys[i] !== 'client_image') {
              formData.append(`${keys[i]}`, updatedData[`${keys[i]}`])
            }
          }
        }
        response = await updateClientDetails(formData, data?.id)
      } else {
        values.status = true
        values.start_date = values.start_date?.format('YYYY-MM-DD')
        fileList[0] && formData.append('client_image', fileList[0])
        formData.append('first_name', values.first_name)
        values.last_name && formData.append('last_name', values.last_name)
        formData.append('username', values.email.split('@')[0])
        formData.append('email', values.email)
        formData.append('status', values.status)
        values.mobile_number &&
          formData.append('mobile_number', values.mobile_number)
        formData.append('start_date', values.start_date)
        formData.append('client_owner', values.client_owner)
        formData.append('password', values.password)
        formData.append('client_role', values.client_role)
        response = await setClientInfo(formData)
      }

      if (response?.success) {
        setVisible(false)
        setRefreshTable(true)
        form.resetFields()
        setUpdatedData({})
        setFileList([])
      }
    }

    const propsUpload = {
      onRemove: () => {
        setFileList([])
        setUpdatedData({ client_image: '' })
      },
      beforeUpload: file => {
        setFileList([file])
        return false
      },
      fileList,
    }

    const updateClient = changeValue => {
      const key = Object.keys(changeValue)[0]
      if (key === 'image') {
        updatedData[key] = fileList[0]
        setUpdatedData(updatedData)
      } else {
        updatedData[key] = changeValue[Object.keys(changeValue)[0]]
        setUpdatedData(updatedData)
      }
    }

    const setCountryCodeClient = country => {
      setCountryCode(country)
    }
    const delAttachment = async (attachment_id, index) => {
      attachmentList?.splice(index, 1);
      const newList = [...attachmentList];
      setattachmentList(newList);
      const formData = new FormData()
      formData.append('attachments_delete', attachment_id)
    }
    return (
      <>
        <MyForm
          layout='vertical'
          form={form}
          onFinish={submitData}
          onValuesChange={updateClient}
          id='client-form'
        >
          <div className='form-auto-height'>
            <Row>
              <Col xs={24} sm={11}>
                <Form.Item
                  label='First Name'
                  name='first_name'
                  rules={[
                    { required: true, message: 'First name is required' },
                    {
                      message: 'Use Only Alphabets',
                      pattern: getAlphabetRegex(),
                    },
                    {
                      whitespace: true,
                      message: 'Can\'t be just whitespace',
                    },
                  ]}
                >
                  <CommonInput
                    className='form-control inputField'
                    inputType='text'
                    placeholder='First Name'
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col xs={24} sm={11}>
                <Form.Item label='Last Name' name='last_name'
                  rules={[
                    { required: true, message: 'Last name is required' },
                    {
                      message: 'Use Only Alphabets',
                      pattern: getAlphabetRegex(),
                    },
                    {
                      whitespace: true,
                      message: 'Can\'t be just whitespace',
                    },
                  ]}>
                  <CommonInput
                    className='form-control inputField'
                    inputType='text'
                    placeholder='Last Name'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={11}>
                <Form.Item
                  name='email'
                  label='Email'
                  validateTrigger={['onBlur']}
                  rules={[
                    { type: 'email', message: 'Email is invalid' },
                    { required: true, message: 'Email is required' },
                  ]}
                >
                  <CommonInput
                    className='form-control inputField'
                    placeholder='example@gmail.com'
                    inputType='email'
                    disabled={update}
                    onFocus={() =>
                      form.setFields([{ name: 'email', errors: [] }])
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <PasswocrdCol
                xs={24}
                sm={11}
              >
                <Form.Item
                  name={['password']}
                  label='Password'
                  rules={[
                    { required: !update, message: 'Password is required' },
                  ]}
                >
                  <CommonInput
                    className={`inputField`}
                    inputType='password'
                    placeholder='Enter Password'
                    disabled={update}
                  />
                </Form.Item>
              </PasswocrdCol>

            </Row>
            <Row>
              <Col xs={24} sm={11}>
                <Form.Item
                  name={['client_role']}
                  label='Client Role'
                  rules={[
                    { required: true, message: 'Client Role is required' },
                  ]}
                >
                  <CommonSelect
                    listHeight={120}
                    allowClear
                    placeholder='Client Role'
                    size='large'
                    getPopupContainer={trigger => trigger.parentNode}
                    showSearch
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    data={children}
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col xs={24} sm={11}>
                <Form.Item
                  name='ClientID'
                  label='ClientID'
                  rules={[
                    { required: true, message: 'Client ID is required' },
                  ]}
                >
                  <CommonInput
                    className='form-control inputField'
                    inputType='text'
                    placeholder='EWW-EMP-20'
                  />

                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={11}>
                <Form.Item
                  name={['start_date']}
                  label='Joining Date'
                  className='customDate'
                  style={{
                    position: 'relative',
                  }}
                  rules={[
                    { required: true, message: 'Joining date is required' },
                  ]}
                >
                  <DatePicker
                    format='YYYY-MM-DD'
                    getPopupContainer={trigger => trigger.parentElement}
                    className='form-control'
                    disabled={update}
                    onFocus={e => {
                      if (!e.target.value) {
                        form.setFieldsValue({ start_date: moment(new Date()) })
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <FormCol xs={24} sm={11}>
                <Row>
                  <Col xs={24} sm={24}>
                    <StyledDiv>
                      <DynamicSelect
                        formLabel='Contact Person'
                        getData={getEmployeeData}
                        loadData={loadEmployeeDetails}
                        valueKey1={FIRST_NAME_PARAM}
                        valueKey2={LAST_NAME_PARAM}
                        isForm={true}
                        name={'client_owner'}
                        fetchData={true}
                        mode='multiple'
                        formRules={[validateRequired('Contact person')]}
                        placeholder='Select'
                        nextPage={nextEmp}
                      />
                    </StyledDiv>
                  </Col>
                </Row>
              </FormCol>
            </Row>

            <Row>
              <Col xs={24} sm={11}>
                <DynamicSelect
                  isForm={true}
                  name={'branch'}
                  placeholder={'Select Branch...'}
                  getData={getBranchData}
                  loadData={loadBranch}
                  queryParam={'branch_name'}
                  nextPage={nextBranchPage}
                  valueKey1={'branchName'}
                  formLabel={'Branch'}
                  fetchData={false}
                  disable={!data?.isMain}
                  formRules={[
                    { required: true, message: 'Branch is required' },
                  ]}
                />
              </Col>
              <Col span={2}></Col>
              <Col xs={24} sm={11}>
                <Form.Item
                  name='mobile_number'
                  label='Contact Number'
                  rules={[
                    {
                      max: 16,
                      message: 'Contact number must be less than 16 characters.',
                    },
                    {
                      min: 11,
                      required: true,
                      message: 'Contact number is required',
                    },
                  ]}
                >
                  <PhoneInput
                    country={'pk'}
                    value={countryCode}
                    onChange={setCountryCodeClient}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <Form.Item
                  name={['attachments']}
                  label='Attachment'
                >
                  <UploadCardWrapper>
                    <Card className='upload-card'>
                      <Dragger
                        {...propsUpload}>
                        <p className='ant-upload-text'>
                          Drop your image here or browse <a>browser</a>
                          <br /> Support .jpg, .jpeg, .png
                        </p>
                      </Dragger>
                      {data && data?.attachments && (
                        <StyleAttach>
                          {attachmentList?.map((item, index) => (
                            <AttachmentDiv>
                              <a key={index} href={item?.attachments} target='_blank' rel='noopener noreferrer'>
                                Attachment {index + 1}
                              </a>
                              <span onClick={() => delAttachment(item?.id, index)}>
                                <DeleteOutlined />
                              </span>
                            </AttachmentDiv>
                          ))}
                        </StyleAttach>
                      )}
                    </Card>
                  </UploadCardWrapper>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </MyForm>
      </>
    )
  }
)

export default ClientForm

const MyForm = styled(Form)`
  .submitBtn {
    .ant-btn {
      padding: 10px 30px;
      margin: 0 auto;
    }
  }
`
const PasswocrdCol = styled(Col)`
.ant-input {
  box-shadow: none !important;
}`

const FormCol = styled(Col)`
  margin-top: 8px !important;
`
const StyledDiv = styled.div`
 .ant-select-selection-item{
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
   height: auto !important;
  }
  .ant-select-selector{
    height: auto !important;
  }
`
const UploadCardWrapper = styled.div`
  .ant-card{
    border:none;
    .ant-card-body{
      padding: 6px;
      span{
        .ant-upload{
          border-radius: 10px;
        }
      }
    }
  }
`
const AttachmentDiv = styled.div`
  display: flex;
  gap: 20px;

  a{
    width: 100%;
  }
  span{
    display: flex;
    align-items: center;
  }
`