import React, { useState, useRef, useEffect } from 'react'
import { Card, Row, Space, Dropdown, Menu, Button, Tooltip } from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import ExpanseForm from './expenseForm'
import BreadCrumbs from '@commonComponents/breadcrums'
import CustomButton from '@commonComponents/button'
import { Table } from '@commonComponents/table-v2'
import { ExpenseUrl, getExpenseForApprovalUrl } from '@api/const'
import SearchExpense from '@mainPage/expenses/searchExpense'
import CustomDrawer from '@commonComponents/drawer'
import { MdEdit } from 'react-icons/md'
import { StyledAttachmentWrapper, StyledChildDiv, LeftImageWrpper, StyledLeftImage, RightImageWrapper, StyledRightImage } from '@commonComponents/attachment/index.styles'
import styled from 'styled-components'
import { ADD_EXPENSE, APPROVAL_TAB, EXPENSE_TAB, } from './const'
import { MdRemoveRedEye } from 'react-icons/md';
import { DropDownMenuWrapper, StyleStatusImage, StyleStatusMain } from '@mainPage/Asset/assets';
import { StatusButtons, convertToUppercase, statusIcons } from '@utils/common-functions';
import ShowOnPermission from '@commonComponents/permit';
import { EXPENSES_MOD, POST_METHOD, PUT_METHOD, VIEW_ALL } from 'constant/constant';
import { permit } from '@utils/permission/permission';

const ExpensesTable = observer(() => {

  const [visible, setVisible] = useState(false)
  const [isUpdate, setisUpdate] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [queryParam, setQueryParam] = useState({})
  const tableRef = useRef(null)
  const [showAttachment, setShowAttachment] = useState(false)
  const [activeTab, setActiveTab] = useState('')
  const [tabList, setTabList] = useState([])
  const [currentData, setCurrentData] = useState(null)
  const [attachment, setAttachments] = useState([])
  const [defaut, setDefault] = useState('')
  const [istrue, setIsTrue] = useState(false)
  const [resetFilters, setResetFilters] = useState(false)
  const [expenseAmount, setExpenseAmount] = useState(false)

  const {
    totalExpenseAmountModal: { loadExpenseAmount, getTotalExpenseAmount, loadMonthlyExpense, getMonthlyExpenseAmount, exportExpenseRecord },
    hrExpenseModal: { approveExpense, rejectExpense },
    themesModel: { getColorsData },
    userInfo: { getRoles }

  } = useStore(null)
  const permission = permit(getRoles ,EXPENSES_MOD,PUT_METHOD)


  useEffect(() => {
    async function loadData() {
       await loadExpenseAmount()
       await loadMonthlyExpense()
    }
    loadData()
  }, [])

  const onAdd = () => {
    setisUpdate(false)
    setVisible(true)
    setCurrentData(null)
  }

  const onTabChange = key => {
    setActiveTab(key)
    setQueryParam({})
    setResetFilters(true)
  }

  
  useEffect(() => {
    let tab_List = []
    permission ? tab_List = [...EXPENSE_TAB, ...APPROVAL_TAB] : tab_List = [...EXPENSE_TAB]
    setTabList(tab_List)
    setActiveTab(tab_List[0].key)
  }, [])
  

  const editExpenses = row => {
    setCurrentData(row)
    setVisible(true)
    setisUpdate(true)
  }
  const showAttachmentmodal = data => {
    setShowAttachment(true)
    setAttachments(data.attachments)
    setDefault(data?.attachments?.[0]?.attachments)
  }

  const onPreview = item => {
    setDefault(item)
  }

  const onStatusChange = async (expenseId: string, status) => {
    let res
    if (status === 'approved') {
      res = await approveExpense(expenseId)
    } else if (status === 'rejected') {
      res = await rejectExpense(expenseId)
    }
    res?.success && setRefreshTable(true) 
  }


  const columns = [
    {
      title: 'Item',
      fixed: 'left' as const,
      dataIndex: 'title',
      render: text => (
        <Tooltip title={text} placement='topLeft'>{text}</Tooltip>
      ),
    },
    {
      title: 'Expense Category',
      dataIndex: 'categoryTitle',
      render: data => <Tooltip title={data}>{data ? data : '-'}</Tooltip>
    },
    {
      title: 'Purchased By',
      dataIndex: 'purchasedByName',
      render: (text =><Tooltip title={text}>{text}</Tooltip>),
    },
    {
      title: 'Purchased From',
      dataIndex: 'purchasedFrom',
      render: (data, record) => (
        <Tooltip title={data} placement='topLeft'>
          <PurchaseFrom>
            <div className='font-16'>{data ? data : '-'}</div>
            <div className='font-12'>{record.purchasedDate ? record.purchasedDate : '-'}</div>
          </PurchaseFrom>
        </Tooltip>
      )
    },
    {
      title: 'Authorized By',
      dataIndex: 'authorizedByName',
      render: (text => <Tooltip title={text?.trim() ? text : 'Unauthorized'}>{text?.trim() ? text : 'Unauthorized'}</Tooltip>),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text,record) => (
      <Tooltip title={text}>PKR {text}
      <div className='font-12'>{record.paymentType}</div>
      
      </Tooltip>
      
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text,record) => (
        <>
        {activeTab === 'expense' ?
        <div className={`${text}Icon`}>{statusIcons[text]}<div>{convertToUppercase(text)}</div></div>:
        <StyleStatusMain>
          <p className={`${text}`}>{convertToUppercase(text)}</p>
          <StyleStatusImage>
          {Object.entries(StatusButtons).map(([status,image])=>(
              <img src={image} alt={status} onClick={() => onStatusChange(record.id, status)} />
            ))}
          </StyleStatusImage>
        </StyleStatusMain>}
        </>
      ),
    },

    {
      title: 'Action',
      render: (_, row) => (
        <div className='d-flex accordingToTheme'>
          <ShowOnPermission mod={EXPENSES_MOD} method={PUT_METHOD}>
          <div className='actionIcons'>
            <Tooltip title='Edit'>
              <MdEdit onClick={() => editExpenses(row)} />
            </Tooltip>
          </div>
          </ShowOnPermission>
          <div className='actionIcons'>
            <Tooltip title='Attachment'>
              <MdRemoveRedEye onClick={() => showAttachmentmodal(row)} />
            </Tooltip>
          </div>
        </div>
      ),
    }
  ];

  const selectedColumns = columns.filter(text => text.title !== 'Authorized By' && text.title !== 'Action')

  const contentList = {
    expense: (
      <div className='table-responsive mt-3'>
        <Table
          ref={tableRef}
          dataUrl={ExpenseUrl}
          columns={columns}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
        />
      </div>
    ),
    approval: (
        <Table
          dataUrl={getExpenseForApprovalUrl}
          columns={selectedColumns}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
        />
    ),
  }

  
  const handleOptionClick = ({ key }) => {
    exportExpenseRecord(key)
  };
  const options = (
    <DropDownMenuWrapper>
      <Menu onClick={handleOptionClick}>
        <Menu.Item key='pdf'>PDF Format</Menu.Item>
        <Menu.Item key='csv'>CSV Format</Menu.Item>
      </Menu>
    </DropDownMenuWrapper>
  );

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='d-flex justify-content-between'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Expenses', link: constRoute.expenses },
              ]}
              pageTitle={'Expenses'}
            >
            </BreadCrumbs>
            <ShowOnPermission mod={EXPENSES_MOD} method={POST_METHOD}>
            <CustomButton
              onClick={onAdd}
              title={ADD_EXPENSE} />
            </ShowOnPermission>
          </div>
        </div>
        <div className='customTabs' >
          <StyledAndCard style={{ marginBottom: '20px' }}
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={key => {
              onTabChange(key)
            }}
          >
          </StyledAndCard>
          {/* /Page Header */}
          <ShowOnPermission mod={EXPENSES_MOD} method={VIEW_ALL}>
          <SearchExpense
            activeTab={activeTab}
            setQueryParam={setQueryParam}
            resetFilters={resetFilters}
            setResetFilters={setResetFilters}
          />
          </ShowOnPermission>
          {activeTab !== 'approval' &&
            (
              <>
                <StyledAmmountRow>
                  <ExpenseAmountWrapper theme={getColorsData?.theme}>
                    <div>{`${expenseAmount ? 'Monthly Expense' : 'Total Expense'}`}</div>
                    <div>{`${expenseAmount ? getMonthlyExpenseAmount?.currentMonthExpense : getTotalExpenseAmount?.totalExpense === undefined ? '':  getTotalExpenseAmount?.totalExpense}`}</div>
                  </ExpenseAmountWrapper>

                  <Row className='d-flex gap-2'>
                    <CustomButton title='Month Expense' onClick={() => setExpenseAmount(true)} />
                    <CustomButton title='Total Expense' onClick={() => setExpenseAmount(false)} />
                  </Row>
                </StyledAmmountRow>
                <StyleButton theme={getColorsData?.bodyColor}>
                  <Dropdown overlay={options} trigger={['hover']}>
                    <Button type='primary' shape='round' icon={<DownloadOutlined />} size={'large'}>Download all records</Button>
                  </Dropdown>
                </StyleButton>
              </>
            )
          }
          <div >
            {contentList[activeTab]}
          </div>
        </div>

      </div>
      {/* Add & Edit Expense Modal */}
      <CustomDrawer
        className='addDrawer'
        visible={visible}
        title={isUpdate ? 'Update Expense' : 'Add Expense'}
        setVisible={() => {
          setVisible(false)
        }}
        width={600}
        customFooter={
          <Space>
            <div>
              <CustomButton
                htmlType='submit'
                type='primary'
                title={'Cancel'}
                onClick={() => setVisible(false)}
              />
            </div>
            <div>
              <CustomButton
                form='expense-form'
                htmlType='submit'
                title={isUpdate ? 'Update' : 'Add'}
                type='primary'
                loading={istrue}
                disabled={istrue}
              />
            </div>
          </Space>
        }
      >
        <ExpanseForm
          setVisible={setVisible}
          update={isUpdate}
          setRefreshTable={setRefreshTable}
          data={currentData}
          setIstrue={setIsTrue}
        />
      </CustomDrawer>
      <CustomDrawer
        title='Attachment'
        className='addDrawer'
        visible={showAttachment}
        setVisible={() => {
          setShowAttachment(false)
        }}
        width={600}
      >{attachment && attachment.length ? (
          <StyledAttachmentWrapper>
            <StyledChildDiv flexBasis='20%'>
              <LeftImageWrpper>
                {attachment?.map(item => {
                  return (
                    item?.attachments?.includes('.pdf') ? (
                      <CustomCsvWrapper href={item.attachments} target='_blank'>
                        <div>Download Pdf Attachment</div>
                      </CustomCsvWrapper>
                    ) : item?.attachments?.includes('.csv') ?
                      (
                        <CustomCsvWrapper href={item.attachments} >
                          <div>Download Csv Attachment</div>
                        </CustomCsvWrapper>
                      ) : (
                        <StyledLeftImage src={item.attachments} onClick={() => { onPreview(item.attachments) }} />
                      )
                  )
                })}
              </LeftImageWrpper>
            </StyledChildDiv>
            <StyledChildDiv flexBasis='80%'>
              <RightImageWrapper>
                {
                  defaut.includes('.pdf') ?
                    <iframe src={defaut} width='100%' height='500px' />
                    :
                    <StyledRightImage src={defaut} />
                }
              </RightImageWrapper>
            </StyledChildDiv>
          </StyledAttachmentWrapper>
        ) : (
          <SetAttach>
            <span className='d-flex justify-content-center'>No Attachments</span>
          </SetAttach>)}
      </CustomDrawer>
    </div>
  )
})

export default ExpensesTable

export const StyledAmmountRow = styled(Row)`
display: flex;
align-items: center;
justify-content: space-between;
`

export const DownloadButton = styled(Button)`
   .downloadIcon{
    color: white;
  }
  background: ${props => props.bodyColor} !important;
  color: white !important;
  
  &:hover {
    border: 1px solid black
  }
`


export const ExpenseAmountWrapper = styled.div`
   display:flex;
  justify-content: space-between;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  border-radius: 40px;
  padding: 8px 20px;
  background: ${props => props.theme === 'dark' && 'black'} !important;
  width: 70%;
  @media (max-width: 1070px){
    width: 60%;
  }
  
  @media (max-width: 746px){
    width: 100%;
  }

`

const StyledAndCard = styled(Card)`
    margin-bottom: 15px;
`

export const IconWrapper = styled.div`
 border-radius: 50%;
width: 36px;
height: 36px;
padding: 8px;
margin-left:50px;
&:hover {
 background: #dad8d8;
  cursor: pointer;
}

svg {
  width: 19px;
  height: 20px;
  color: ${props => props.RedEye ? '#F36B68' : '#525353'} !important;
} 
`

export const CustomDiv = styled.div`
   display: flex;
  flex-direction: column; 
`

export const CustomCsvWrapper = styled.a`
   color: black ;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 1px; 
`
const SetAttach = styled.div`
font-size: 12px;
font-style: italic;
color: rgb(145 142 142) 
`

export const NameField = styled.div`
  width: 104px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
`


const PurchaseFrom = styled.div`
  .font-16{
    font-size: 16px;
  }
  .font-12{
    font-size: 12px;
  }
`

const StyleButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
  .ant-btn{
  background-color: ${props => props.theme} !important;
  }
`