import CustomButton from '@commonComponents/button'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import React, { useState } from 'react'
import logo from '../../assets/img/logo.png'
import { useNavigate, Link } from 'react-router-dom'
import style from './style.module.scss'
import styled from 'styled-components'
const ResetPassword = () => {
  const navigate = useNavigate()
  const [load, setLoad] = useState(false)
  const [loadCancel, setLoadCancel] = useState(false)


  const {
    emailModal: { setEmail },
    userInfo: {getcompany}
  } = useStore(null)

  const submitData = async () => {
    const data = getcompany?.user?.email
    let response = null
    setLoad(true)
    response = await setEmail({email:data})
    setLoad(false)
    if (response?.success) {
      localStorage.removeItem('token')
      window.location.pathname = '/changePassword'
    }
  }

  return (
    <DivWrapper>
      <div className='account-content'>
        <div className='account-box reset-password-page'>
          <div className='account-logo'>
            <Link to={constRoute.home}>
              <img src={logo} alt='Enigmatix Pvt Ltd.' />
            </Link>
          </div>
          <div className='account-wrapper'>
            <h3 className='account-title'>Change Password?</h3>
            <div style={{gap:5,justifyContent:'center'}}
			className={style.accountForm}>
              <CustomButton
                className={'form-btn'}
                type='primary'
                htmlType='submit'
                title='Change'
                loading={load}
                onClick={submitData}
              ></CustomButton>
              <CustomButton
                className={'form-btn'}
                type='primary'
                htmlType='submit'
                title='Cancel'
                loading={loadCancel}
                onClick={() => {
                  setLoadCancel(true),
                  navigate(-1), //on cancel move to previous page 📜
                  setLoadCancel(false)

                }}
              ></CustomButton>
            </div>
          </div>
        </div>
      </div>
    </DivWrapper>
  )
}

export default ResetPassword

const DivWrapper = styled.div`

.account-subtitle {
	color: #4c4c4c;
	font-size: 18px;
	margin-bottom: 10px;
	text-align: center;
	word-spacing: -6px !important;

	@media screen and (max-width: 575px) {
		margin-bottom: 10px;
	}
}
.account-title {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 5px;
	text-align: center;
	word-spacing: -6px;

	@media screen and (max-width: 575px) {
		margin-bottom: 13px;
	}

}

.account-logo img {
	max-width: 250px;
	height: auto;

	@media(max-width:768px) {
		width: 100%;
	}
}

.change-password-wrapper {
	.account-title {
		font-size: 16px;
		margin-bottom: 10px;

		@media(max-width:575px) {
			margin-left: 20px;
		}
	}

	.account-subtitle {
		font-size: 14px;
		font-weight: 400 !important;
		padding-right: 20px;
		margin-bottom: 10px;

		@media(max-width:575px) {
			margin-left: 20px;
		}
	}
}
.account-box {
	width: 100%;
	min-height: 100vh;
	background-color: #fff;
	height: 100%;

	.ant-form-item-with-help {
		.ant-form-item-explain {
			margin-top: -7px;
			min-height: 15px;
		}
	}

	.account-wrapper {
		margin: 0 auto;
		width: 70%;
		padding: 0px 20px 20px 20px;

		@media(max-width:950px) {
			width: 100%;
		}

		@media(max-width:575px) {
			padding: 0;
		}

		.account-logo {
			text-align: center;
			margin-bottom: 10px;

			@media(max-width:575px) {
				text-align: center;
				margin: -100px 0 27px 0;

				img {
					max-width: 230px;
				}
			}

		}

		.form-wrapper {
			@media (max-width:575px) {
				padding: 0 20px;
			}
		}

		.text-block {
			text-align: center;

			@media(max-width:480px) {
				margin-top: 10px;
				padding: 0 20px;
			}

			p {
				margin: .5rem 0 1rem 0;

				@media(max-width:575x) {
					padding: 0 0 1rem 0 !important;
				}

				a {
					color: $color-cyan-primary !important;

					span {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}

		.ant-form-item {
			margin-bottom: 0 !important;
		}

	
	}

}
.account-box .form-control {
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	height: 46px;
}

.account-box label {
	color: #1f1f1f;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 5px;
}

.reset-password-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	.account-logo {
		padding: 0 30px 20px 20px;
	}

	p {
		margin-bottom: 30px;
		font-size: 14px;
	}

	.account-form {
		text-align: center;
		display: flex;
		margin: 0 auto;
		max-width: 300px;
		padding-left: 7px;

		button {
			display: inline-block;
		}
	}
	form-btn{
		margin: 0 auto;
		max-width: 300px;
	}
}
`