import { Checkbox, Col, Form, Row } from 'antd'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { CHECKED } from 'constant/constant'
import { SALARY_TITLE, CANTEEN_TITLE, LOAN_TITLE, ASSETSDETAILS } from './constant'
import styled from 'styled-components';
import { colors } from 'constant/colors'
import { CommonInput } from '@commonComponents/input'

const { WhiteSmoke, mediumGray } = colors

const Updateclearance = observer(({ showModal, setRefreshTable, data, updateEmployeeClearance }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      employee: `${data?.fullName}`,
      loan: data?.loanStatus || false,
      salary: data?.salaryStatus || false,
      e_canteen: data?.eCanteenStatus || false,
      asset: data?.assetStatus || false,
      reason: data?.reason
    })
  }, [])

  const onSubmit = async e => {
     const updateData = {
      employee: data?.employee,
      reason: e.reason,
      loan_status: e.loan,
      e_canteen_status: e.e_canteen,
      salary_status: e.salary,
      asset_status: e.asset,
    }    

    const res = await updateEmployeeClearance(updateData, data?.id)    
    res.success ? (showModal(false), setRefreshTable(true)) : null;
  }
  return (
    <Form
      form={form}
      id='update-clearance'
      onFinish={onSubmit}
      initialValues={ {'checkbox-group': ['1', '2']} }
      layout='vertical'>
            <>
              <Wrapper>
                <div className='form-auto-height'>
                  <Row gutter={[12, 24]}>
                    <Col span={24} >
                      <Form.Item name='employee' label='Employee Name'>
                        <CommonInput className='inputField' inputType='text' disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[12, 24]}>
                    <Col span={24} >
                      <Form.Item name='reason' label='Leave Office Reason'>
                        <CommonInput className='inputField' inputType='text' disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className='mb-2'>
                      <h3 >Employee Clearance Checklist</h3>
                    </Col>
                  </Row>
                  <Row >
                    <Col xs={24} lg={24} className={` mb-2 `}>
                      <div>

                      <h5>{ASSETSDETAILS}</h5>
                        <Form.Item valuePropName={CHECKED} name='asset'>
                          <span className='d-flex gap-2'>
                            <Checkbox defaultChecked={data.assetStatus} value={data?.assetStatus} style={{ marginTop: '5px' }} />
                            <span className='title flex-grow-1'> Company {ASSETSDETAILS}</span>
                          </span>
                        </Form.Item>

                        <h5>{LOAN_TITLE}</h5>
                        <Form.Item valuePropName={CHECKED} name='loan'>
                          <span className='d-flex gap-2'>
                            <Checkbox defaultChecked={data?.loanStatus} style={{ marginTop: '5px' }} />
                            <span className='title flex-grow-1'> Company {LOAN_TITLE}</span>
                            - <span className='digit'>0  PKR</span>
                          </span>
                        </Form.Item>

                        <h5>{SALARY_TITLE}</h5>
                        <div>
                          <Form.Item valuePropName='checked' name='salary'>
                            <span className='d-flex gap-2'>
                              <Checkbox defaultChecked={data?.salaryStatus} style={{ marginTop: '5px' }} />
                              <span className='title flex-grow-1'>Employee {SALARY_TITLE}</span>
                              - <span className='digit'>0 PKR</span>
                            </span>
                          </Form.Item>
                        </div>
                        <h5>E-{CANTEEN_TITLE}</h5>
                        <div>
                          <Form.Item valuePropName='checked' name='e_canteen'>
                            <span className='d-flex gap-2'>
                              <Checkbox defaultChecked={data?.eCanteenStatus} style={{ marginTop: '5px' }} />
                              <span className='title flex-grow-1'>{CANTEEN_TITLE} Bill</span>
                              - <span className='digit'>0 PKR</span>
                            </span>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Wrapper>
            </>
    </Form>
  )
})

export default Updateclearance;
const Wrapper = styled.div`
  .dynamicSelect  .ant-select-selector {
  
  color: rgba(0, 0, 0, .25);
  background: ${WhiteSmoke} !important;
  cursor: not-allowed;
  width: 100%;
}

.digit{color: #525353 ;
  border: 1px solid ${mediumGray};
    border-radius: 7px;
    width: 128px;
    padding:4px 0 0 11px;}


  .title{border: 1px solid ${mediumGray};
    width: 499px;
    border-radius: 7px;
    color: #525353 ;
    height: 32px;
    padding: 3px 0 0 8px;}
  
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 44px;
      border-radius: 7px;
    }
 `
