import { headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class BoardTaskApi extends BaseApi {

  setSprintTaskDetails = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}task/`,
        data,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateBoardTaskDetails = async (data, taskID) => {
    try {
      const response = await this.axios.patch(`${baseUrl}task/${taskID}/`, data, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteBoardTaskDetails = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}task/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default BoardTaskApi
