import '@assets/css/bootstrap.css'
import '@assets/css/custom.scss'
import '@assets/css/font-awesome.min.css'
import ConfirmPassword from '@initialPage/confirmPassword'
import { constRoute } from '@utils/route'
import { useEffect } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import DefaultLayout from './Layouts/MainLayout'
import Registrationpage from './RegistrationPage'
import Loginpage from './loginpage'
import './style.module.scss'
const App = () => {
  const token = localStorage.getItem('token')
  useEffect(() => {
    if (token && window.location.pathname === '/login') {
      window.location.pathname = '/'
    }
  }, [])
  return (
    <>
      <Router>
        <Routes>
          <Route path='*' element={<DefaultLayout />} />
          {!token ?
            <>
              <Route path={constRoute.signup} element={<Registrationpage />} />
              <Route path={constRoute.login} element={<Loginpage />} />
              <Route
                path={`${constRoute.confirmPassword}`}
                element={<ConfirmPassword />}
              /> </> : <Route path='*' element={<DefaultLayout />} />}
        </Routes>
      </Router>
    </>
  )
}

export default App
