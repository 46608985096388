import { useStore } from '@stores/root-store'
import { calculateLeaves } from '@utils/common-functions'
import { Button, Col, Progress, Row, Select, Skeleton } from 'antd'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { BsFillBriefcaseFill } from 'react-icons/bs'
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { mauvePink, peach, willow, hamGreen, grassGreen, TealishBlue } = colors

const EmployeeLeaveCount = observer(({ setQueryParam }) => {
  const [clicked, setClicked] = useState({ clicked: false, type: 'total' })
  const [filteredLeaves, setFilteredLeaves] = useState([
    {
      leavetype: '',
      totalTakenLeaves: 0,
      leaveTotalDays: 0,
      remainingLeaves: 0,
    }
  ]);
  const { Option } = Select
  const {
    employeeLeaveTypeModal: { loadEmployeeLeaveType, isLoading, getEmployeeLeaveTypeData },
    employeeLeaveCountModal: {
      loadEmployeeLeaveCount,
      getEmployeeLeaveCountData,
    },
    themesModel: { getColorsData },
  } = useStore(null)

  useEffect(() => {
    async function loadData() {
      await loadEmployeeLeaveType();
      await loadEmployeeLeaveCount(1, '');
    }
    loadData();
  }, []);

  useEffect(() => {
    if (getEmployeeLeaveTypeData && getEmployeeLeaveCountData) {
      const leaveTypeTitles = new Set(getEmployeeLeaveTypeData.map(item => item.title));
      const leaves = getEmployeeLeaveCountData.filter(item =>
        leaveTypeTitles.has(item.leavetype)
      );
      setFilteredLeaves(leaves);
    }
  }, [getEmployeeLeaveCountData, getEmployeeLeaveTypeData]);


  const totalTakenLeaves = calculateLeaves(filteredLeaves, 'totalTakenLeaves')
  const totalLeaves = calculateLeaves(filteredLeaves, 'leaveTotalDays')

  const totalPercentageLeaves = (+totalTakenLeaves / +totalLeaves) * 100
  const onOptionClick = val => {
    val == 'total' && setQueryParam()
    setClicked({ clicked: true, type: val })
  }

  const progressColor = [mauvePink, peach, willow, hamGreen, grassGreen]
  return (
    <>
      <div className='row-wrap justify-content-between'>
        <ResCountDiv align='start'>
          <ResCount md={24} lg={24}>
            <Skeleton
              active
              loading={isLoading}
              paragraph={{ rows: 1 }}
              style={{ paddingLeft: 40, paddingRight: 250 }}
            >
              <LeaveBox className='row-wrap justify-content-end'>
                <LeaveCount
                  className='Leave-count-button'
                  bgColor
                  clicked={clicked}
                  theme={getColorsData?.theme}
                  item={{ type: 'total' }}
                  onClick={() => {
                    setClicked({ clicked: true, type: 'total' })
                    clicked.type !== 'total' && setQueryParam({})
                  }}
                >
                  <div className='progressDiv'>
                    <Progress
                      type='circle'
                      strokeColor={'goldenYellow'}
                      format={() => (
                        <BsFillBriefcaseFill style={{ color: 'goldenYellow' }} />
                      )}
                      percent={Math.floor(totalPercentageLeaves)}
                    />
                    <div className='totalLeavesDiv'>
                      <Number>{`${totalTakenLeaves}/${totalLeaves}`}</Number>
                      <LeaveType large>Total</LeaveType>
                    </div>
                  </div>
                </LeaveCount>

                {filteredLeaves?.map((item, index) => {

                  const strokeColor = progressColor[index % progressColor.length];
                  return (
                    <LeaveCount
                      className='Leave-count-button'
                      clicked={clicked}
                      item={item}
                      theme={getColorsData?.theme}
                      onClick={() => {
                        setClicked({ clicked: true, type: item.leavetype })
                      }}
                    >
                      <div className='progressDiv'>
                        <Progress type='circle' strokeColor={strokeColor} format={() => <BsFillBriefcaseFill style={{ color: strokeColor }} />} percent={Math.floor((item.totalTakenLeaves / item.leaveTotalDays) * 100)} />
                        <div className='totalLeavesDiv'>
                          <Number>{item.totalTakenLeaves}/{item.leaveTotalDays}</Number>
                          <LeaveType large>{item.leavetype.split(' ')[0]}</LeaveType>
                        </div>
                      </div>
                    </LeaveCount>
                  )
                })}
              </LeaveBox>
            </Skeleton>
          </ResCount>
        </ResCountDiv>
        <StyledDropdown
          defaultValue='total'
          onChange={value => onOptionClick(value)}
        >
          <Option
            key='total'
            clicked={clicked}
            item={{ type: 'total' }}
            onClick={() => {
              setClicked({ clicked: true, type: 'total' }), setQueryParam()
            }}
          >
            Total ( {`${totalTakenLeaves}/${totalLeaves}`} )
          </Option>
          {getEmployeeLeaveCountData?.map(item => (
            <Option
              key={item.leavetype}
            >{`${item.leavetype} ( ${item.totalTakenLeaves}/${item.leaveTotalDays} )`}</Option>
          ))}
        </StyledDropdown>
      </div>
    </>
  )
})

export default EmployeeLeaveCount

const ResCount = styled(Col)`
  width: -webkit-fill-available;
  @media screen and (min-width: 992px) and (max-width: 1289px) {
    max-width: inherit !important;
  }
  @media screen and (min-width: 1289px) and (max-width: 1600px) {
    display: contents;
  }
`
const LeaveBox = styled.div`
  margin-right: 12px;
  justify-content: end;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgb(227, 227, 227);
`
const LeaveCount = styled(Button)`
  width: 150px !important;
  height: auto !important;
  padding: 20px !important;
  border: none !important;
  text-align: center;
  width: 90px;
  word-spacing: 0;
  background: ${({ clicked, item, theme }) =>
    clicked?.type === item?.type && theme == 'dark' ? 'gray' :
      clicked?.type === item?.type && theme == 'light' ? '#DFEAF9' : ''}!important;
  &:hover {
    background: rgba(45, 113, 216, 0.15);
  }
  @media screen and (min-width: 0px) and (max-width: 556px) {
    height: auto !important;
  }
  @media screen and (min-width: 0px) and (max-width: 850px) {
    text-decoration: none;
    padding: 2px;
    font-size: 10px;
    margin: auto !important;
  }
  @media screen and (min-width: 0px) and (max-width: 478px) {
    padding: 2px 2px !important;
  }

  .progressDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px !important;
    .totalLeavesDiv {
      padding: 5px !important;
    }
    .ant-progress-circle .ant-progress-inner {
      width: 50px !important;
      height: 50px !important;
      font-size: 14px !important;
    }
  }
`
const Number = styled.div`
  font-weight: 600;
  line-height: 30px;
  color: ${TealishBlue};
  @media screen and (min-width: 0px) and (max-width: 800px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
    font-size: 16px !important;
  }
  font-size: 20px;
`
const LeaveType = styled.div`
  font-weight: 400;
  font-size: '16px';
  line-height: 24px;
  color: #525353;
  word-spacing: 0;
  font-family: 'Poppins';
  @media screen and (min-width: 0px) and (max-width: 600px) {
    font-size: 12px;
  }
`
const ResCountDiv = styled(Row)`
  justify-content: ${props => (props.align == 'end' ? 'end' : '')};
  @media screen and (min-width: 992px) and (max-width: 1289px) {
    display: block !important;
  }
  width: fit-content !important;
  @media screen and (min-width: 701px) and (max-width: 768px) {
    display: block !important;
  }
  ${props =>
    props.align == 'start' &&
    '@media screen and (min-width: 0px) and (max-width: 700px){display: none}'}
`
const StyledDropdown = styled(Select)`
  display: none;
  @media screen and (min-width: 0px) and (max-width: 700px) {
    display: block;
    width: 100% !important;
    .ant-select-selector {
      border-radius: 8px !important;
      height: inherit !important;
      padding: 2px !important;
      padding-left: 20px !important;
    }
  }
`
