import React, { useState, useRef } from 'react'
import { Switch, Row, Col, Space } from 'antd'
import { observer } from 'mobx-react'
import Tooltip from '@commonComponents/CustomToolTip'
import { useStore } from '@stores/root-store'
import { LoadingOutlined } from '@ant-design/icons'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Table } from '@commonComponents/table-v2'
import { permit } from '@utils/permission/permission'
import CustomButton from '@commonComponents/button'
import { shiftUrl } from '@api/const'
import ShiftForm from './shiftForm'
import CustomDrawer from '@commonComponents/drawer'
import { DELETE_METHOD, DELETE_SHIFT, PATCH_METHOD, POST_METHOD, SHIFIT_MOD } from 'constant/constant'
import styled from 'styled-components'
import { MdEdit } from 'react-icons/md'
import { MdDelete } from 'react-icons/md'
import DeletePopUp from '@commonComponents/delete-popup'
import ShowOnPermission from '@commonComponents/permit'

const Shift = observer(() => {
  const [data, setData] = useState('')
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [visible, setVisible] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [resetTable, setResetTable] = useState(false)
  const [statusLoader, setDropDownLoader] = useState(false)
  const tableRef = useRef(null)

  const {
    shiftsModal: {
      updateShift,
      deleteShift
    },
    userInfo: {getRoles}
  } = useStore(null)
  
  const canEdit  = permit(getRoles,SHIFIT_MOD,PATCH_METHOD)
  const canDelete = permit(getRoles,SHIFIT_MOD,DELETE_METHOD)
 
  const onStatusChange = async (id, value) => {
    let response = null
    setCurrentData({ id: id })
    setIsUpdate(false)
    setDropDownLoader(true)
    response = await updateShift(id, { is_active: value })
    if (response?.success) {
      setRefreshTable(true)
      setDropDownLoader(false)
    }
  }
  const onAdd = () => {
    setIsUpdate(false)
    setVisible(true)
  }

  const onEdit = data => {
    setCurrentData(data)
    setIsUpdate(true)
    setVisible(true)
  }
  const onDelete = data => {
    setData(data?.title)
    setCurrentData({ id: data?.id })
    setVisibleDelete(true)
  }
  const deleteShiftHandler = async () => {
    const response = await deleteShift(currentData?.id)
    if (response?.success) {
      setData('')
      setRefreshTable(true)
    }
    setVisibleDelete(false)
  };
  const columns = [

    {
      title: 'Title',
      dataIndex: 'title',
      render: text => (
        <div>
          <Tooltip title={text}>
            {text ? text : '-'}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      render: text => (
        <p>{text ? text : '-'}</p>
      )
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      render: text => (
        <p>{text ? text : '-'}</p>
      )
    },
    {
      width: 100,
      title: <ActionCenter>Status</ActionCenter>,
      render: (text, row) => (
        <FlexWrapper>
          <div>
            <StatusDiv>
              <Switch
                checked={row.isActive}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                onClick={id => onStatusChange(row.id, id)}
                disabled={!canEdit}
              />
              <div style={{ marginTop: '-4px' }}>
                {row.id === currentData?.id && statusLoader && (
                  <LoadingOutlined />
                )}
              </div>
            </StatusDiv>
          </div>
        </FlexWrapper>
      ),

    },
   ( canEdit || canDelete ) && {
      width: 100,
      title: <ActionCenter>Action</ActionCenter>,
      render: (text, row) => (
        <FlexWrapper className='accordingToTheme'>
          <ShowOnPermission method={PATCH_METHOD} mod={SHIFIT_MOD}>
            <Tooltip minLength={0} title='Edit'>
              <IconWrapper >
                <MdEdit onClick={() => onEdit(row)} />
              </IconWrapper>
            </Tooltip>
          </ShowOnPermission>
         <ShowOnPermission method={DELETE_METHOD} mod={SHIFIT_MOD}>
            <Tooltip minLength={0} title='Delete'>
              <IconWrapper >
                <MdDelete onClick={() => onDelete(row)} />
              </IconWrapper>
            </Tooltip>
         </ShowOnPermission>
        </FlexWrapper>
      ),
    }
    // : [])
  ]

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='d-flex justify-content-between'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Shifts', link: constRoute.shift },
              ]}
              pageTitle={'Shift'}
            >
            </BreadCrumbs>
            <div>
              <ShowOnPermission mod={SHIFIT_MOD} method={POST_METHOD}>
                <CustomButton
                  onClick={onAdd}
                  title='Add Shift'
                />
              </ShowOnPermission>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className='row'>
          <div className=''>
            <div className='tableWidth'>
            </div>
          </div>
        </div>
        <Row>
          <Col span={24}>
            <div className='table-responsive custom-Pagination'>
              <div>
                <Table
                  ref={tableRef}
                  dataUrl={shiftUrl}
                  columns={columns.filter(Boolean)}
                  refreshTable={refreshTable}
                  resetTable={resetTable}
                  setRefreshTable={setRefreshTable}
                  setResetTable={setResetTable}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div> 
      {/* /Page Content */}
      {/* Add Section Modal */}
      <CustomDrawer
        visible={visible}
        title='Add Shift'
        setVisible={setVisible}
        className='addDrawer'
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='shift-form'
              htmlType='submit'
              type='primary'
              title={isUpdate ? 'Update ' : 'Add '}
              className='addButton'
            />
          </Space>
        }
      >
        {visible && (
          <ShiftForm
            update={isUpdate}
            data={currentData}
            setIsUpdate={val => setIsUpdate(val)}
            setVisible={val => setVisible(val)}
            setRefreshTable={setRefreshTable}
          />
        )}
      </CustomDrawer>
      {/* /Edit Section Modal */}
      <DeletePopUp
        delete={deleteShiftHandler}
        msgTitle={DELETE_SHIFT}
        visibleDelete={visibleDelete}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
        msg={data}
      />
    </div>
    
  )
})

export default Shift

const ActionCenter = styled.div`
  display:block;
  width:fit-content;
  margin:auto;
`
const FlexWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
`
const StatusDiv = styled.div`
  display: flex;
  svg{ 
    color: #ffffff !important;
  }
`


const IconWrapper = styled.div`
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
&:hover {
  background-color: #dad8d8;
  cursor: pointer;
}
svg {
  width: 20px;
  height: 20px;
  
}
`
