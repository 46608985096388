import { BaseApi } from '../baseApi'
import { feedbackQuestion } from '@api/const'

class FeedbackLeadQuestionsApi extends BaseApi {
  loadLeadFeedbackQuestions = async () => {
    try {
      const response = await this.axios.get(feedbackQuestion)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  editQuestion = async (questionId, questionTitle) => {
    try {
      const response = await this.axios.put(`${feedbackQuestion}/${questionId}`, {
        title: questionTitle,
      })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  setQuestion = async title => {
    try {
      const response = await this.axios.post(feedbackQuestion, {
        title,
      })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteQuestion = async questionId => {
    try {
      const response = await this.axios.delete(`${feedbackQuestion}/${questionId}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default FeedbackLeadQuestionsApi
