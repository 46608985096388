import React, { useState } from 'react'
import { Button, Card } from 'antd'
import { observer } from 'mobx-react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
// import UserLevel from '@mainPage/userLevel/userLevel'
import AccountSettings from '@mainPage/generalSettings/accountSettings'
import Appearnace from '@mainPage/generalSettings/appearnace'
import NotificationSettings from '@mainPage/generalSettings/notificationSettings'
import style from './style.module.scss'
import { useStore } from '@stores/root-store'
import EmployeeLeaveSettings from '@mainPage/EmployeeLeave/EmployeeLeave/LeadAdminView/employeeLeaveSettings'
import styled from 'styled-components'
// import LoanSetting from '@mainPage/Loan/LoanSetting'

const Settings = observer(() => {
  const {
    userInfo: { getcompany },
  } = useStore(null);
  const isAdmin = getcompany.admin;
  const [activeTab, setActiveTab] = useState('leaveSettings')
  const [accountActiveTab, setAccountActiveTab] = useState(isAdmin ? 'appearnace' : 'accountSettings')
  const [setting, seSetting] = useState<number>(1)

  const tabList = [
    {
      key: 'leaveSettings',
      tab: 'Leave Settings',
    },
    // {
    //   key: 'userLevelSettings',
    //   tab: 'User Level Settings',
    // },
    // {
    //   key: 'loanSettings',
    //   tab: 'Loan Settings',
    // },
  ]
  
  const onTabChange = key => {
    setActiveTab(key)
  }

  const contentList = {
    leaveSettings: (
      <>
        <EmployeeLeaveSettings />
      </>
    ),
    // userLevelSettings: (
    //   <>
    //     <UserLevel />
    //   </>
    // ),
    // loanSettings: (
    //   <>
    //     <LoanSetting />
    //   </>
    // ),
  }

  const tabList2 = [
    { key: 'accountSettings',
      tab: 'Account Settings',
    },
    {
      key: 'appearnace',
      tab: 'Appearance',
    },
    {
      key: 'notificationsettings',
      tab: 'Notification Settings',
    },
  ]

  
  const contentList2 = {
    accountSettings: (
      <>
        <AccountSettings />
      </>
    ),
    appearnace: (
      <>
        <Appearnace />
      </>
    ),
    notificationsettings: (
      <>
        <NotificationSettings />
      </>
    ),
  }

  const [tabType, setTabType] = useState(1)
  const isActive = tabName => {
    if (tabName === tabType) {
      return 'active'
    }
    return 2
  }


  return (
    <div className='page-wrapper'>
      {/* Page Content */}
        <div className='content container-fluid'>
          {/* Page Header */}
          <div className='page-header'>
            <SettingsFlexedStyledWrapper className='d-flex'>
              <BreadCrumbs
                list={[
                  { name: 'Dashboard', link: constRoute.home },
                  { name: 'Settings', link: constRoute.settings },
                ]}
                pageTitle={'Settings'}
              >
                {' '}
              </BreadCrumbs>
              <div className={`switchButton mt-2`}>
                <SpacLS className='switch-btn-flex'>
                  {getcompany.admin || getcompany.superadmin  || getcompany?.isLead? <> 
                    <CustomLeavesButton className={isActive(1)}
                      onClick={() => { setTabType(1), seSetting(1) }}
                    >
                  Leaves Setting
                    </CustomLeavesButton> 
                
                    <CustomGeneralButton 
                      className={isActive(2)} 
                      onClick={() => {seSetting(2) , setTabType(2)
                      }}
                    >
                  General Setting
                    </CustomGeneralButton>
                  </>: ''
                  }

                </SpacLS>
              </div>
            </SettingsFlexedStyledWrapper>
          </div>
          {/* /Page Header */}
          {setting === 1  && (getcompany?.admin || getcompany?.superadmin || getcompany?.isLead) ? (
            <>
              <div className='row'>
                <div className='col-md-12'>
                  <div className={`${style.tabstyle} table-responsive`}>
                    <Card
                      className='TabProfile'
                      tabList={tabList}
                      activeTabKey={activeTab}
                      onTabChange={key => {
                        onTabChange(key)
                      }}
                    ></Card>
                    {contentList[activeTab]}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='row'>
              <div className='col-md-12'>
                <div className={`${style.tabstyle} table-responsive`}>
                  <Card
                    className={`${style.tabcardSettings} TabProfile`}
                    tabList={tabList2}
                    activeTabKey={accountActiveTab}
                    onTabChange={key => {
                      setAccountActiveTab(key)
                    }}
                  ></Card>
                  {contentList2[accountActiveTab]}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  )
})

export default Settings


const SettingsFlexedStyledWrapper = styled.div`
  justify-content: space-between;

  @media (max-width: 560px) {
    display: block !important;
  }
  
  .switchButton{
    .switch-btn-flex{
      width: 100%;
    }
  }
`
const CustomLeavesButton = styled(Button)`

@media screen and (min-width: 0px) and (max-width: 430px) {
  width: inherit !important;
}
`
const CustomGeneralButton = styled(Button)`
@media screen and (min-width: 0px) and (max-width: 430px) {
  width: inherit !important;
  margin-left: 0px !important;
}
`

const SpacLS = styled.div`
display: flex;
gap:5px;
`