import React, { useRef} from 'react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import SearchReports from './SearchReports'
import { reportColumns } from './Colums'
import { Table } from '@commonComponents/table-v2'
import { reportUrl } from '@api/const'


const Reports = () => {
  const tableRef = useRef(null)
  const columns: any = reportColumns()



  return (
    <>
      <div className='page-wrapper'>
        {/* Page Content */}
        <div className='content container-fluid'>
          {/* Page Header */}
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='d-flex justify-content-between'>
                <BreadCrumbs
                  list={[
                    { name: 'Dashboard', link: constRoute.home },
                    { name: 'Reports', link: constRoute.reports },
                  ]}
                  pageTitle={'Reports'}
                  isAlpha
                >
                  <div />
                </BreadCrumbs>
              </div>
            </div>
          </div>
          <SearchReports />
          {/* /Page Header */}
          <div className='row'>
            <div className=''>
          <div className='table-responsive'>
              <Table columns={columns} ref={tableRef} dataUrl={reportUrl} />
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  )
}

export default Reports

