import CustomButton from '@commonComponents/button'
import { CommonInput } from '@commonComponents/input'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import { Col, Form, Row } from 'antd'
import React, { useState } from 'react'
import { Link} from 'react-router-dom'

import topIcon from '../../assets/img/topicon.svg'
import bottomicons from '../../assets/img/bottomicons.svg'
import enigmatixlogo from '../../assets/img/enigmatixlogo.svg'
import check from '../../assets/img/check.svg'
import logoblur from '../../assets/img/logoblur.svg'
import style from './style.module.scss'
import { MdLock, MdArrowBack, MdVpnKey } from 'react-icons/md';
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { red } = colors

interface DataType {
  password: string;
  token: string;
}

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

const ConfirmPassword = () => {
  const [form] = Form.useForm()
  const [updated , setUpdated] =useState(false)
  const [newPassword , setNewPassword] =useState('')

  const {
    passwordModal: { setPassword },
  } = useStore(null)

  const submitData = async (formData: DataType) => {
    const response = await setPassword(formData)
    if (response?.success) {
      setUpdated(true)
    }
  }

  return (
    <DivWrapper>
      <div className={style.SignIn}>
        <Row>
          <Col xs={24} md={12} lg={12}>
            <div className={style.polygon}>
              {!updated && <div className={style.backTologin}>
                <Link to={`${constRoute.login}`} className={style.backArrow}>
                  <MdArrowBack className={style.backicon} />
                </Link>
                <span>Back to logIn</span>
              </div>}
              {updated ? (
                <div className={style.signInform}>
                  <h1>Updated Successfully</h1>
                  <p className={style.textSpace}>
                    Your password has been successfully reset.
                    <br /> Please Click the 'Login' button below to proceed to the login page
                  </p>
                  <div className={style.signinForm}>
                    <div className={style.imgSpace}>
                      <img className={style.checkImg} src={check} alt='check' />
                    </div>
                    <div className={style.signinButton}>
                      <Link to={`${constRoute.login}`}>
                        <CustomButton
                          className='d-block w-100 mt-5'
                          title='LOGIN'
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={style.signInform}>
                  <h1>Set new password</h1>
                  <p className='mb-4'>
                    Your new password must be strong and different from previous
                    password
                  </p>
                  <Form
                    layout='vertical'
                    className={style.signinForm}
                    form={form}
                    onFinish={submitData}
                    validateMessages={validateMessages}
                  >
                    <Form.Item
                      label='New Password'
                      className={style.passwordField}
                      name='password'
                      rules={[
                        { required: true, message: 'New Password is required' },
                      ]}
                    >
                      <CommonInput
                        className='inputField'
                        inputType='password'
                        onInput={e => {
                          e.target.value = e.target.value.trim()
                        }}
                        onChange={e => setNewPassword(e.target.value)}
                        icon={<MdLock className={style.LockOutlined} />}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Confirm Password'
                      className={style.passwordField}
                      name='password2'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if (value !== newPassword) {
                              return Promise.reject(
                                new Error('Password did not match!')
                              )
                            }
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <CommonInput
                        className='inputField'
                        inputType='password'
                        onInput={e => {
                          e.target.value = e.target.value.trim()
                        }}
                        icon={<MdLock className={style.LockOutlined} />}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Token'
                      name='token'
                      rules={[{ required: true, message: 'Token is required' }]}
                      className={style.commonInputField}
                    >
                      <CommonInput
                        className='form-control inputField'
                        inputType='text'
                        icon={<MdVpnKey className={style.MdKeyboard} />}
                      />
                    </Form.Item>
                    <Form.Item className={style.signinButton}>
                      <CustomButton
                        htmlType='submit'
                        className='d-block w-100'
                        title='Change Password'
                      />
                    </Form.Item>
                  </Form>
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className={style.barnd}>
              <div className={style.oms}>
                <img className={style.topIcon} src={topIcon} alt={topIcon} />
                <img className={style.logoblur} src={logoblur} alt={logoblur} />
              </div>
              <div>
                <img
                  className={style.bottomicons}
                  src={bottomicons}
                  alt={bottomicons}
                />
              </div>
              <div>
                <img
                  className={style.enigmatixlogo}
                  src={enigmatixlogo}
                  alt={'enigmatixlogo'}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </DivWrapper>
  )
}

export default ConfirmPassword

const DivWrapper = styled.div`
& > div:first-child {
  overflow: hidden !important;
  height: inherit !important;
}
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none;
	visibility: hidden;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
	display: inline-block;
	margin-right: 4px;
	color: ${red};
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "*";
	position: absolute;
	right: -15px;
}
`