import showColData from '@commonComponents/show-col-data/showColData'
import { Modal, Select } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import styled from 'styled-components'
import { DELETE_METHOD, PATCH_METHOD, STATUS, STATUS_TITLE, TERMINATION_MOD } from 'constant/constant'
import { MdDelete, MdRemoveRedEye } from 'react-icons/md'
import { useState } from 'react'
import { useStore } from '@stores/root-store'
import employeeName from '@utils/employee_name'
import { StatusButtons, convertToUppercase, statusIcons } from '@utils/common-functions'
import { StyleStatusImage, StyleStatusMain } from '@mainPage/Asset/assets'
import ShowOnPermission from '@commonComponents/permit'
import { colors } from 'constant/colors'

const { darkGray4, white, black } = colors

export const getColumns = (updateEmployeeStatus, _setCurrentData, _setIsUpdate, _setVisible, onDelete) => {

  const { themesModel: { getColorsData } } = useStore(null)
  const [isModalOpen, setIsModalOpen] = useState({open:false,reason: null});
  const currentColor = getColorsData?.bodyColor;
  const isDarkTheme = getColorsData?.theme === 'dark'

  return (
    [
      ...([employeeName('setRecord', 'setVisible', 'termination')]),
      {
        title: 'Terminate Date',
        dataIndex: 'resignationDate',
        render: showColData,
      },
      {
        title: STATUS_TITLE,
        dataIndex: STATUS,
        render: (text, row) => {
          return (
            <>
            <ShowOnPermission mod={TERMINATION_MOD} method={PATCH_METHOD}>
            {text === 'pending' &&
            <StyleStatusMain>
              <p className={`${text}`}>{convertToUppercase(text)}</p>
              <StyleStatusImage>
                {StatusButtons && <img id='termination_approved' src={Object.entries(StatusButtons)[0][1]} onClick={() => updateEmployeeStatus(row, 'approved')} alt='Approved' />}
            </StyleStatusImage>
            </StyleStatusMain>}
            </ShowOnPermission>
            {text === 'approved' &&<div className={`${text}Icon`}>{statusIcons[text]}<div className='status'>{convertToUppercase(text)}</div></div>}
            </>
          )
        }
      },
      {
        title: <FlexWrapper>Actions</FlexWrapper>,
        dataIndex: 'id',
        key: 'action',
        render: (_text, row) => {
          return (
            <FlexWrapper className='accordingToTheme'>
              {row?.status === 'pending' && (
                <ShowOnPermission mod={TERMINATION_MOD} method={DELETE_METHOD}>
                  <div className='actionIcons'>
                    <Tooltip title={'Delete'} minLength={0}>
                      <MdDelete id='termination_delete' onClick={() => onDelete(row)} />
                    </Tooltip>
                  </div>
                </ShowOnPermission>
              )}
              <div className='actionIcons'>
                <Tooltip title={'Reason'} minLength={0}>
                  <MdRemoveRedEye id='termination_reason' onClick={() => setIsModalOpen({...isModalOpen,open:true,reason:row.reason})} />
                </Tooltip>
              </div>
              <ModelWrapper 
                container={false} 
                title={'Reason'} 
                open={isModalOpen.open} 
                onCancel={() => setIsModalOpen({...isModalOpen,open:false})} 
                currentColor={currentColor} 
                isDarkTheme={isDarkTheme} 
                centered
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}
              >
                <p>{isModalOpen?.reason}</p>
              </ModelWrapper>
            </FlexWrapper>
          )
        }
      },
    ]
  )
}

export const StyledSelectWrapper = styled(Select)`
min-width: 136px;
  .ant-select-selector{
    height: 40px !important;
    border-radius: 6px !important;
    .ant-select-selection-item{
      display: flex !important;
      line-height: 27px !important;
    }
  }
`
const FlexWrapper = styled.div`
display: flex;
justify-content: center;
`
const ModelWrapper = styled(Modal)`
.ant-modal-content{
  border-radius:11px;
}
.ant-modal-header{
  background-color: ${color => color.currentColor || 'blue'};
  border-radius: 10px 10px 0px 0px;
}
.ant-modal-content {
  box-shadow: none !important;
}
.ant-modal-title{
  color:white;
  font-weight:600;
}
.ant-modal-footer{
  display:none;
}
.ant-modal-body {
  padding: 24px;
  text-align: center;
  background-color: ${props => props.isDarkTheme ? darkGray4 : white};
  color: ${props => props.isDarkTheme ? white : black};
  line-height: 24px;
}
.anticon-close {
  color:white;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  transition-delay: 0.2;
  padding:7px 7px;
    &:hover {
  color: #fff;
  border-radius: 32px;
  box-shadow: 0px 0px 10px rgba(0, 0.1, 0, 0.5);
  transform: rotate(180deg)
  }
}

span{
font-size:20px;
font-weight:600;
color:${color => color.currentColor};
}
`