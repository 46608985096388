import { getUserUrl } from '@api/const'
import { BaseApi } from '../baseApi'
import { loginUrl } from '../const/index'

const baseUrl = '' // just for now ---> please change this later
class UserLoginData extends BaseApi {

  setCompany = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}create_company/`,
        {
          name: data.name,
          username: data.username,
          email: data.email,
          password: data.password
        },
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getCompany = async () => {
    try {
      const response = await this.axios.get(getUserUrl)
      return { success: true, data: response.data }
    } catch (error) {
      return { success: false, data: {} }
    }
  }
  loginUser = async data => {
    try {
      const response = await this.axios.post(loginUrl, { ...data })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default UserLoginData
