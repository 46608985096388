import { observer } from 'mobx-react'
import styled from 'styled-components'
import { colors } from 'constant/colors'
import { MdDelete } from 'react-icons/md'
import { useStore } from '@stores/root-store'
import { Col, Form, Row, Tooltip } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { CommonInput } from '@commonComponents/input'
import { humanize, numberFormat } from '../../utils/string_formate'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { validateRequired } from '@utils/common-functions/formValidations'
import SupervisorSelect from '@commonComponents/supervisorDropdown/supervisorDropdown'
import PayrollAllowanceAndDeduction from '@mainPage/E-payroll/PayrollAllowanceAndDeduction'

const { TealishBlue } = colors

interface PayrollFormProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setEditModalVisible: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRefreshTable: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  editPayroll: any;
}
const PayrollForm = observer(
  ({ setEditModalVisible, setRefreshTable, editPayroll }: PayrollFormProps) => {
    const [form] = Form.useForm()
    const [allowances, setAllowances] = useState([])
    const [deductions, setDeductions] = useState([])
    const [formattedAllowanceDeduction, setFormattedAllowanceDeduction] = useState(null)
    const [updateformattedAllowanceDeduction, setupdateformattedAllowanceDeduction] = useState(null)
    const [editRecord, setEditRecord] = useState({})
    const [total, setTotal] = useState(0)
    const [basicSalaryError, setBasicSalaryError] = useState(false)
    const [salaryDisplay, setSalaryDisplay] = useState({
      basicSalary: 0,
      bonus: 0,
      increment: 0,
      allowances: 0,
      deductions: 0,
    })
    const {
      allowanceModel: { loadAllowance },
      employeeDetails: {
        loadEmployeesWithoutPayroll,
        getEmployeesWithoutPayroll,
        nextEmp,
        loadEmployeeDetails,
        getEmployeeData,
      },
      employeePayroll: {
        createEmployeePayroll,
        updateEmployeePayroll,
        errorMessage,
      },
    } = useStore(null)

    useEffect(() => {
      async function loadData() {
        await loadEmployeesWithoutPayroll()
        await loadAllowance(1, 20, '', true)
      }
      loadData()
    }, [])

    useEffect(() => {
      const subTotal =
        +salaryDisplay.basicSalary +
        +salaryDisplay.bonus +
        +salaryDisplay.increment +
        +salaryDisplay.allowances
      setTotal(subTotal - +salaryDisplay.deductions)
    }, [salaryDisplay])

    useEffect(() => {      
      if (editPayroll) {
        setAllowances(
          filterAllowanceAndDeduction(editPayroll?.wages, 'allowance')
        )
        setDeductions(filterAllowanceAndDeduction(editPayroll?.wages, 'deduction'))
        form.setFieldsValue({
          basic_salary: editPayroll.basicSalary,
          bonus: editPayroll.bonus,
          last_increment: editPayroll.lastIncrement,
          employee: editPayroll.employee,
          recommendedBy: `${editPayroll.recommendedUserFirstName} ${editPayroll.recommendedUserLastName}`,
          reason: editPayroll.reason,
        })
      }
    }, [editPayroll])

    const getupdatedFormattedAllowanceDeduction = (allowances, deductions) => {
      const formatItems = items => (items || []).map(item => ({
        wage_id: item.wageId || item.id,
        value: item.amount,
        ...(item.wageId && { id: item.id }),
      }));
      return {
        allowances: formatItems(allowances),
        deductions: formatItems(deductions),
      };
    }

    const getFormattedAllowanceDeduction = (allowances, deductions) => {
      const formatItems = items => (items || []).map(item => ({
        wage_id: item.id,
        value: item.amount,
      }));
      return {
        allowances: formatItems(allowances),
        deductions: formatItems(deductions),
      };
    }

    const displayPayroll = (editPayroll, salaryDisplay, allowances, deductions) => {
      const mergeValues = key => editPayroll?.[key] || salaryDisplay?.[key];
      return {
        basicSalary: mergeValues('basicSalary') || 0,
        bonus: mergeValues('bonus') || 0,
        increment: mergeValues('lastIncrement') || 0,
        allowances: allowances.reduce((accumulator, items) => accumulator + items.amount, 0),
        deductions: deductions.reduce((accumulator, items) => accumulator + items.amount, 0),
      };
    };


    useEffect(() => {
      
      setupdateformattedAllowanceDeduction(getupdatedFormattedAllowanceDeduction(allowances, deductions))
      setFormattedAllowanceDeduction(getFormattedAllowanceDeduction(allowances, deductions))
      setSalaryDisplay(displayPayroll(editPayroll, salaryDisplay, allowances, deductions))

    }, [allowances, deductions])

    useEffect(() => {
      if (errorMessage) {
        const errorDetails = errorMessage.split(':')
        form.setFields([{ name: errorDetails[0], errors: [errorDetails[1]] }])
      }
    }, [errorMessage?.length])

    const filterAllowanceAndDeduction = (allowances, type) => {
       return allowances?.filter(item => item.type === type)
      .map(({ id, wageTitle, amount, wageId }) => ({ id, title: wageTitle, amount, wageId: wageId }))
      || [];
    };



    const resetValues = () => {
      form.resetFields()
      setEditModalVisible(false)
      setRefreshTable(true)
      setAllowances([])
      setDeductions([])
      setFormattedAllowanceDeduction({})
    }

    const onFinish = async values => {
      let employeeResponse


      if (editPayroll) {
        values.bonus = parseInt(values?.bonus || 0)
        values.last_increment = parseInt(values?.last_increment || 0)
        delete values['basic_salary']

        const updatedValues = {
          reason: values.reason,
          employee_id: editPayroll.employeeId,
          basic_salary: values.basic_salary,
          ...(values.bonus ? {bonus: values.bonus}: {}),
          ...(values.last_increment ? {last_increment: values.last_increment}: {}),
          recommended_by_id: editPayroll.recommendedById,
        }

        employeeResponse = await updateEmployeePayroll(
          {
            ...updatedValues,
            ...updateformattedAllowanceDeduction,
            employee: editPayroll.employee,
          },
          editPayroll.id
        )
      } else {
        employeeResponse = await createEmployeePayroll({
          reason: values.reason,
          recommended_by_id: values.recommendedBy,
          employee_id: values.employeeId,
          basic_salary: +values.basic_salary,
          ...(values.bonus ? { bonus: +values.bonus } : {}),
          ...formattedAllowanceDeduction,
        })
      }
      if (employeeResponse.success) resetValues()
    }

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const onlyNumber = (e: any) => {
      if (
        e.keyCode === 38 ||
        e.keyCode === 40 ||
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189
      )
        e.preventDefault()
    }

    const updateAllowanceOrDeduction = (record, index, setRecord, value) => {
      const tempRecord = [...record]
      tempRecord[index].value = parseInt(value)
      setRecord(tempRecord)
    }

    const deleteAllowanceOrDeduction = (wageId, record, index, setRecord) => {
      const tempRecord = [...record]
      tempRecord.splice(index, 1)
      setRecord(tempRecord)
    }

    const handleSalaryDisplayChange = (e, field) => {
      setSalaryDisplay(prevSalaryDisplay => ({
        ...prevSalaryDisplay,
        [field]: e.target.value,
      }));
      field === 'basicSalary' && setBasicSalaryError(false)
    }

    useEffect(()=>{
      loadEmployeeDetails(1,100,{is_active : false})
    },[])

  const getEmployeesWithoutPayrollFiltered =   useMemo(()=>{
      if(getEmployeeData && getEmployeesWithoutPayroll){
       const FilteredData =  getEmployeesWithoutPayroll?.filter(item=>{
          const data = getEmployeeData?.find(subItem => subItem.id === item.id);
          if(data){
            return data?.isActive
          }
          return true
        })
        return FilteredData;
      }

    },[getEmployeeData,getEmployeesWithoutPayroll])

    return (
      <MainForm
        form={form}
        onFinish={onFinish}
        layout='vertical'
        id='payroll-form'
      >
        <FormWrapper className={`form-auto-height`}>
          <Row gutter={24}>
            <Col xs={24} lg={16}>
              {!editPayroll && (
                <Row gutter={24} className='rowDiv'>
                  <Col xs={24} md={24} lg={24}>
                    <DynamicSelect
                      getData={getEmployeesWithoutPayrollFiltered}
                      loadData={loadEmployeesWithoutPayroll}
                      nextPage={nextEmp}
                      isForm={true}
                      showSearch={true}
                      valueKey1='firstName'
                      valueKey2='lastName'
                      name={'employeeId'}
                      placeholder='Please Select Employee'
                      formLabel={'Select Employee'}
                      fetchData={true}
                      formRules={[validateRequired('Employee name')]}
                    />
                  </Col>
                </Row>
              )}
              <Row gutter={24}>
                <Col xs={24} md={24} lg={12}>
                  <SupervisorSelect
                    formLabel='Recommended By'
                    disable={editPayroll}
                    placeholder='Select Supervisor'
                    isForm={true}
                    name='recommendedBy'
                    formRules={[validateRequired('Recommended By')]}
                    fetchData={false}
                  />
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <Formitems
                    label='Basic Salary'
                    name={'basic_salary'}
                    rules={[
                      validateRequired('Basic Salary'),
                      () => ({
                        validator(_, value) {
                          if (value) {
                            setBasicSalaryError(false)
                            return Promise.resolve()
                          } else {
                            setBasicSalaryError(true)
                            return Promise.reject(
                              new Error('Basic Salary is required')
                            )
                          }
                        },
                      }),
                    ]}
                    validateStatus={basicSalaryError ? 'error' : ''}
                    help={basicSalaryError ? 'Basic Salary is required' : ''}
                  >
                    <CommonInput
                      className={`form-control inputField ${basicSalaryError ? 'error' : ''
                        }`}
                      disabled={editPayroll}
                      onKeyDown={e => onlyNumber(e)}
                      onChange={e => handleSalaryDisplayChange(e, 'basicSalary')}
                      inputType={'number'}
                      placeholder='Ex: 50000'
                    />
                  </Formitems>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item label='Bonus' name='bonus'>
                    <CommonInput
                      className='form-control inputField'
                      onKeyDown={e => onlyNumber(e)}
                      inputType={'number'}
                      placeholder='Ex: 2000'
                      onChange={e => handleSalaryDisplayChange(e, 'bonus')}
                    />
                  </Form.Item>
                </Col>
                {editPayroll && (
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item label='Increment' name='last_increment'>
                      <CommonInput
                        className='form-control inputField'
                        onKeyDown={e => onlyNumber(e)}
                        inputType={'number'}
                        placeholder='Ex: 2000'
                        onChange={e => handleSalaryDisplayChange(e, 'increment')}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <AllowanceAndDeductionDiv>
                <AllowanceAndDeductionInnerDiv>
                  <Textsize xs={24} md={24} lg={24}>
                    <h4>Allowance</h4>
                  </Textsize>
                  <EditIcon xs={24} md={24} lg={24}>
                    <Row>
                      <Col span={5}>
                        <hr />
                      </Col>
                      <Col span={14}>
                        <PayrollAllowanceAndDeduction
                          modalType={'allowance'}
                          record={allowances}
                          setRecord={setAllowances}
                          editRecord={editRecord}
                          setEditRecord={setEditRecord}
                        />
                      </Col>
                      <Col span={5}>
                        <hr />
                      </Col>
                    </Row>
                    {allowances.map((item, index) => {
                      return (
                        <Form.Item
                          key={index}
                          label={
                            <>
                              <Tooltip
                                placement='topLeft'
                                title={humanize(item)}
                              >
                                {humanize(item.title)}
                              </Tooltip>
                            </>
                          }
                        >
                          <Tooltip placement='top' title='Delete'>
                            <MDelete
                              onClick={() =>
                                deleteAllowanceOrDeduction(
                                  item.wageId,
                                  allowances,
                                  index,
                                  setAllowances
                                )
                              }
                            />
                          </Tooltip>
                          <CommonInput
                            onChange={e =>
                              updateAllowanceOrDeduction(
                                allowances,
                                index,
                                setAllowances,
                                e.target.value
                              )
                            }
                            value={item.amount}
                            inputType='number'
                          />
                        </Form.Item>
                      )
                    })}
                  </EditIcon>
                </AllowanceAndDeductionInnerDiv>
                <AllowanceAndDeductionInnerDiv>
                  <Textsize xs={24} md={24} lg={24}>
                    <h4>Deductions</h4>
                  </Textsize>
                  <EditIcon xs={24} md={24} lg={24}>
                    <Row>
                      <Col span={5}>
                        <hr />
                      </Col>
                      <Col span={14}>
                        <PayrollAllowanceAndDeduction
                          modalType={'deduction'}
                          record={deductions}
                          setRecord={setDeductions}
                          editRecord={editRecord}
                          setEditRecord={setEditRecord}
                        />
                      </Col>
                      <Col span={5}>
                        <hr />
                      </Col>
                    </Row>
                    {deductions.map((item, index) => {
                      return (
                        <Form.Item
                          key={index}
                          label={
                            <>
                              <Tooltip
                                placement='topLeft'
                                title={humanize(item)}
                              >
                                {humanize(item.title)}
                              </Tooltip>
                            </>
                          }
                        >
                          <Tooltip placement='top' title='Delete'>
                            <MDelete
                              onClick={() =>
                                deleteAllowanceOrDeduction(
                                  item.wageId,
                                  deductions,
                                  index,
                                  setDeductions
                                )
                              }
                            />
                          </Tooltip>
                          <CommonInput
                            onChange={e =>
                              updateAllowanceOrDeduction(
                                deductions,
                                index,
                                setDeductions,
                                e.target.value
                              )
                            }
                            value={item.amount}
                            inputType='number'
                          />
                        </Form.Item>
                      )
                    })}
                  </EditIcon>
                </AllowanceAndDeductionInnerDiv>
              </AllowanceAndDeductionDiv>
              <Row className='rowDiv'>
                <Reason span={24}>
                  <Form.Item name={'reason'} label={'Reason'} rules={[validateRequired('Reason')]}>
                    <Commoninput
                      placeholder='Write the reason here.'
                      inputType={'textarea'}
                    />
                  </Form.Item>
                </Reason>
              </Row>
            </Col>
            <Col xs={24} lg={8}>
              <Lists>
                <Headers>
                  <HeaderTitle>Salary Details</HeaderTitle>
                </Headers>
                <div>
                  <ul>
                    <li>
                      <div>Basic salary</div>
                      <div>{numberFormat(salaryDisplay['basicSalary'])}</div>
                    </li>
                    <li>
                      <div>Increment</div>
                      <div>{numberFormat(salaryDisplay['increment'])}</div>
                    </li>
                    <li>
                      <div>Bonus</div>
                      <div>{numberFormat(salaryDisplay['bonus'])}</div>
                    </li>
                    <li>
                      <div>Allowances</div>
                      <div>{numberFormat(salaryDisplay['allowances'])}</div>
                    </li>
                    <li>
                      <div>Deductions</div>
                      <div>{numberFormat(salaryDisplay['deductions'])}</div>
                    </li>
                  </ul>
                  <ListFooter>
                    <Footertext>Total Net Salary</Footertext>
                    <Footertext>{numberFormat(total)}</Footertext>
                  </ListFooter>
                </div>
              </Lists>
            </Col>
          </Row>
        </FormWrapper>
      </MainForm>
    )
  }
)

export default PayrollForm

const Formitems = styled(Form.Item)`
  .error {
    border-color: red !important;
  }
`
const MainForm = styled(Form)`
  .formField {
    .addMore {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-top: 9px;
      span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        color: ${TealishBlue};
        word-spacing: 0px;
        cursor: pointer;
      }
      svg {
        cursor: pointer;
      }
    }
  }
  h4 {
    color: $color-cyan-primary;
    text-align: start;
  }
  .submitBtn {
    button {
      margin: 0 auto;
    }
  }
  .submitBtn {
    .ant-btn-primary {
      padding: 0 25px;
      text-shadow: 0px 1px 1px rgba(183, 14, 14, 0.25);
    }
  }
  textarea {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  .editIcon {
    position: relative;
    .edit {
      position: absolute;
      right: 30px;
      top: -27px;
      font-size: 14px;
      color: $color-cyan-primary;
      cursor: pointer;
    }
    .delete {
      position: absolute;
      right: 10px;
      top: -27px;
      font-size: 14px;
      color: $color-cyan-primary;
      cursor: pointer;
    }
    .ant-form-item-label {
      label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
  .iconedit {
    .edit {
      top: -26px;
    }
    .delete {
      top: -26px;
    }
    p {
      font-size: 16px;
    }
  }
  .anticon-close {
    position: absolute;
    top: 14px;
    right: 15px;
    background: #b2b9c3;
    border-color: #b2b9c3;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
  }
  .list {
    padding: 0px 0px 8px;
    width: 100%;
    background: rgba(45, 113, 216, 0.05);
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    font-family: 'Poppins';
    .header {
      background: rgba(45, 113, 216, 0.1);
      border-bottom: 1px solid #e2e2e2;
      border-radius: 7px 7px 0px 0px;
      padding: 12px 0px 12px 19px;
      .headerTitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${TealishBlue};
        word-spacing: 0 !important;
      }
    }
    ul {
      list-style: none;
      padding: 24px 12px;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #525353;
        padding: 15px 0;
        border-bottom: 1px solid #e2e2e2;
        &:last-child {
          border-bottom: 1px solid #e2e2e2;
        }
        div {
          word-spacing: 0 !important;
        }
      }
    }
    .listfooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      word-spacing: 0 !important;
      padding: 0px 10px;
      .footerText {
        word-spacing: 0 !important;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #010101;
      }
      .totalSalary {
        word-spacing: 0 !important;
      }
    }
  }
`
const FormWrapper = styled.div`
  position: relative;
`
const Textsize = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  h4 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 50px;
    color: #010101;
  }
`
const EditIcon = styled(Col)`
  position: relative;
  .edit {
    position: absolute;
    right: 30px;
    top: -27px;
    font-size: 14px;
    color: $color-cyan-primary;
    cursor: pointer;
  }
  .delete {
    position: absolute;
    right: 10px;
    top: -27px;
    font-size: 14px;
    color: $color-cyan-primary;
    cursor: pointer;
  }
  .ant-form-item-label {
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
`
const Reason = styled(Col)`
  .ant-input {
    background: #ffffff;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 150px;
  }
  .ant-form-item {
    label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #525353;
    }
  }
`
const Commoninput = styled(CommonInput)`
  &::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: rgba(82, 83, 83, 0.5);
  }
`
const MDelete = styled(MdDelete)`
  position: absolute;
  right: 10px;
  top: -27px;
  font-size: 14px;

  cursor: pointer;
`
const Lists = styled.div`
  padding: 0px 0px 8px;
  width: 100%;
  background: rgba(45, 113, 216, 0.05);
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  font-family: 'Poppins';
  .header {
    background: rgba(45, 113, 216, 0.1);
    border-bottom: 1px solid #e2e2e2;
    border-radius: 7px 7px 0px 0px;
    padding: 12px 0px 12px 19px;
    .headerTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${TealishBlue};
      word-spacing: 0 !important;
    }
  }
  ul {
    list-style: none;
    padding: 24px 12px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #525353;
      padding: 15px 0;
      border-bottom: 1px solid #e2e2e2;
      &:last-child {
        border-bottom: 1px solid #e2e2e2;
      }
      div {
        word-spacing: 0 !important;
      }
    }
  }
  .listfooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-spacing: 0 !important;
    padding: 0px 10px;
    .footerText {
      word-spacing: 0 !important;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #010101;
    }
    .totalSalary {
      word-spacing: 0 !important;
    }
  }
`
const Headers = styled.div`
  background: rgba(45, 113, 216, 0.1);
  border-bottom: 1px solid #e2e2e2;
  border-radius: 7px 7px 0px 0px;
  padding: 12px 0px 12px 19px;
  .headerTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${TealishBlue};
    word-spacing: 0 !important;
  }
`
const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  word-spacing: 0 !important;
`
const ListFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-spacing: 0 !important;
  padding: 0px 10px;
  .footerText {
    word-spacing: 0 !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #010101;
  }
  .totalSalary {
    word-spacing: 0 !important;
  }
`
const Footertext = styled.div`
  word-spacing: 0 !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #010101;
`
const AllowanceAndDeductionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (min-width: 0px) and (max-width: 665px) {
    flex-direction: column;
  }
`
const AllowanceAndDeductionInnerDiv = styled.div`
  width: 50%;
  @media screen and (min-width: 0px) and (max-width: 665px) {
    width: 100%;
  }
`
