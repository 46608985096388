import { cast, flow, types } from 'mobx-state-tree'
import { employmentCompanyApi } from '@api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
  
export const employmentCompanyInfo = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  

})
export const employmentCompany = types
  .model({
    employmentCompany: types.maybeNull(types.array(employmentCompanyInfo)),
    loading: types.optional(types.boolean, false),
    countEmploymentCompany: types.maybeNull(types.number),
    nextLd: types.optional(types.number,1),
    erorMessage: types.maybeNull(types.string),
  })
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get getEmploymentCompanyData() {
      return toJS(self.employmentCompany)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadEmploymentCompany = flow(function* (
      page = 1,
      page_size = 20,
      queryParam = '',
      resetList = false,
      saveInStore = true,
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.employmentCompany = cast([])
          self.nextLd = 1
        } 
        const res = yield employmentCompanyApi.getEmploymentCompany(page, page_size, queryParam)
        if (!res?.isCancel && saveInStore) {
          self.employmentCompany = cast([...toJS(self.employmentCompany ?? []), ...res.items])
          self.countEmploymentCompany = res.count
          if (res.next) {
            self.nextLd = self.nextLd + 1
          } else {
            self.nextLd = 1
          }
        } else if (!res?.isCancel && !saveInStore) {
          return res || []
        }


      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Employment Company')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })


    const errorMsgClear = () => {
      self.erorMessage = null
    }
    return {
      errorMsgClear,
      loadEmploymentCompany,

    }
  })

export function initEmploymentCompany() {
  return employmentCompany.create({})
}