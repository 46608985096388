import React from 'react'
import { permit } from '@utils/permission/permission'
import { useStore } from '@stores/root-store'

const ShowOnPermission = ({ children, mod, method }) => {
  const {
    userInfo: { getRoles },
  } = useStore(null)
  
  const isExist = permit(getRoles, mod, method)
  if (!isExist) return <div />

  return (
    <>
      {children}
    </>
  )
}

export default ShowOnPermission
