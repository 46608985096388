import React, { useState, useRef, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import DeletePopUp from '@commonComponents/delete-popup'
import DepartmentForm from '@mainPage/Department/departmentForm'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import CustomButton from '@commonComponents/button'
import { Table } from '@commonComponents/table-v2'
import { departmentUrl } from '@api/const'
import CustomDrawer from '@commonComponents/drawer'
import { Space } from 'antd'
import getColumns from './columns'
import { ADD_DEPARTMENT, DELETE_DEPARTMENT, UPDATE_DEPARTMENT, ADD, CANCEL, UPDATE, DEPARTMENT, DEPARTMENT_MOD, POST_METHOD, VIEW_ALL } from 'constant/constant'
import Searchdepartment from './Searchdepartment'
import ShowOnPermission from '@commonComponents/permit'
import styled from 'styled-components'


const Department = observer(() =>
{
  const [currentData, setCurrentData] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [data, setData] = useState('')
  const [visible, setVisible] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [statusLoader, setStatusLoader] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const tableRef = useRef(null)
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const [resetTable, setResetTable] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [queryParam, setQueryParam] = useState()

  const {
    departmentModal: { updateDepartment, deleteDepartment, isLoading },
    userInfo: { getRoles },
    themesModel: { getColorsData }
  } = useStore(null)

  const onEdit = data =>{
    setCurrentData(data)
    setIsUpdate(true)
    setVisible(true)
  }

  const onStatusChange = async (id, value) =>{
    let response = null
    setCurrentData({ id: id })
    setStatusLoader(true)
    response = await updateDepartment(id, { isActive: value })
    if (response?.success){
      setRefreshTable(true)
      setStatusLoader(false)
    }
  }

  const onDelete = data =>
  {
    setData(data?.departmentName)
    setCurrentData({ id: data?.id })
    setVisibleDelete(true)
  }

  const onAdd = () =>
  {
    setCurrentData({})
    setIsUpdate(false)
    setVisible(true)
  }

  const deleteDepartmentHandler = async () =>{
    const response = await deleteDepartment(currentData?.id)
    if (response?.success)
    {
      setVisibleDelete(false)
      setData('')
      totalRecordRemaining == 1 ? setResetTable(true) : setRefreshTable(true)
    } 
    else {
      setVisibleDelete(false)
    }
  };
  const columns = useMemo(() => getColumns(getRoles,getColorsData, currentData, onDelete, onEdit, statusLoader, onStatusChange), [statusLoader,currentData])

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='d-flex justify-content-between'>
              <BreadCrumbs
                list={[
                  { name: 'Dashboard', link: constRoute.home },
                  { name: 'Departments', link: constRoute.departments }
                ]}
                pageTitle={DEPARTMENT}
              >
                <div />
              </BreadCrumbs>
              <div>
                <ShowOnPermission mod={DEPARTMENT_MOD} method={POST_METHOD}>
                  <CustomButton
                    onClick={onAdd}
                    title={ADD_DEPARTMENT}
                  />
                </ShowOnPermission>
              </div>
            </div>
          </div>
        </div>
        <ShowOnPermission mod={DEPARTMENT_MOD} method={VIEW_ALL} >
          <Searchdepartment
            setQueryParam={setQueryParam}
          />
        </ShowOnPermission>
        {/* /Page Header */}
          <div className='table-responsive'>
            <TableWrapper>
              <Table
                ref={tableRef}
                dataUrl={departmentUrl}
                columns={columns}
                queryParam={queryParam}
                refreshTable={refreshTable}
                setRefreshTable={setRefreshTable}
                resetTable={resetTable}
                setResetTable={setResetTable}
                setTotalRecordRemaining={setTotalRecordRemaining}
              />
            </TableWrapper>
        </div>
      </div>
      {/* /Page Content */}
      {/* Add Department Modal */}
      <div >
        <CustomDrawer
          title={isUpdate ? UPDATE_DEPARTMENT : ADD_DEPARTMENT }
          setVisible={setVisible}
          visible={visible}
          className='addDrawer'
          width={400}
          customFooter={
            <Space>
              <CustomButton
                htmlType='submit'
                type='primary'
                title={CANCEL}
                className='cancelButton'
                onClick={() => setVisible(false)}
              />
              <CustomButton
                form='department-form'
                htmlType='submit'
                type='primary'
                title={isUpdate ? UPDATE : ADD}
                loading={btnLoader}
                disabled={btnLoader}
                className='addButton'
              />
            </Space>
          }
        >
          <DepartmentForm
            update={isUpdate}
            data={currentData}
            setBtnLoader={setBtnLoader}
            setVisible={setVisible}
            setRefreshTable={setRefreshTable}
          />
        </CustomDrawer>
      </div>
      {/* /Edit Department Modal */}
      {/* Delete Department Modal */}
      <div>
        <DeletePopUp
          delete={deleteDepartmentHandler}
          msgTitle={DELETE_DEPARTMENT}
          visibleDelete={visibleDelete}
          isLoading={isLoading}
          okText='Yes! Delete'
          handleCancel={() => setVisibleDelete(false)}
          msg={data}
        />
      </div>
    </div>
  )
})

export default Department


const TableWrapper = styled.div`
.ant-table-container table>thead>tr:first-child th:last-child{
  text-align: center;
}
`
