import { flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeRemoteCancelLeaveApproval } from '@api'
import { notification } from '@utils/notifications'

export const data = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})
export const updatedBy = types.model({
  approvedById: types.maybeNull(types.string),
  approvedByFirstName: types.maybeNull(types.string),
  approvedByLastName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  userStatus: types.maybeNull(types.string),
})

export const employeeRemoteCancelLeaveApprovalData = types.model({
  id: types.maybeNull(types.string),
  employee: types.maybeNull(data),
  lead: types.maybeNull(data),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  cancelNumberOfDays:types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  cancelReason: types.maybeNull(types.string),
  isRevert:types.maybeNull(types.boolean),
  rejectionReason:types.maybeNull(types.string),
  fullCancel:types.maybeNull(types.boolean),
  revertFrom:types.maybeNull(types.string),
})

export const employeeRemoteCancelLeaveApprovalModal = types
  .model({
    data: types.maybeNull(types.array(employeeRemoteCancelLeaveApprovalData)),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get getEmployeeRemoteCancelLeaveApprovalData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setEmployeeRemoteLeaveCancellation = flow(function* (data) {
      try {
        self.loading = true
        yield employeeRemoteCancelLeaveApproval.setEmployeeRemoteLeaveCancellation(data)
        notification.success('Remote Cancel Leave Update Applied Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
   
    const loadEmployeeRemoteCancelLeaveApproval = flow(function* (num = 1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeRemoteCancelLeaveApproval.getEmployeeRemoteLeaveCancelApproval(num)
        self.data = res.results
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Remote Cancel Leaves for Approval')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeRemoteCancelLeaveApproval = flow(function* (id) {
      try {
        self.loading = true
        yield employeeRemoteCancelLeaveApproval.updateEmployeeRemoteLeaveCancelApproval(id)
        notification.success('Remote Cancel Leave Status Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const halfCancelLeave = flow(function* (id, data) {
      try {
        self.loading = true
        yield employeeRemoteCancelLeaveApproval.halfCancelLeave(id, data)
        notification.success('Remote half Cancellation Applied successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'Something went wrong')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setEmployeeRemoteLeaveCancellation,
      loadEmployeeRemoteCancelLeaveApproval,
      updateEmployeeRemoteCancelLeaveApproval,
      halfCancelLeave,
    }
  })

export function initEmployeeRemoteCancelLeaveApprovalModal() {
  return employeeRemoteCancelLeaveApprovalModal.create({})
}
