import CustomButton from '@commonComponents/button'
import { useStore } from '@stores/root-store'
import { formatDateValue, getYearMonthDate } from '@utils/common-functions'
import { Col, Form, Space } from 'antd'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import style from './style.module.scss'

const SearchFilter = observer((props: any) => {

  const [searchForm] = Form.useForm()
  const [isFilterData, setIsFilterData] = useState(false)

  const {
    employeeDetails: { searchEmployees, loadHierarchyListParent },
    userInfo: { getcompany },
  } = useStore(null)

  const { id } = getcompany || {}

  const checkFilterValues = formValues => {
    return Object.values(formValues).filter(item => item).length
  }

  useEffect(()=>{
    if(props.resetFilters){ 
      props.setQueryParam({})
      searchForm.resetFields()
      props.setResetFilters(false)
    }
  },[props.resetFilters])

  const submitFilterData = async values => {
    if (props?.calledFrom == 'attendance') {
      values = {
        ...props.queryParam,
        full_name: values.name,
        month: getYearMonthDate(values.date?._d),
      }

      props.setQueryParam(values)
    } else if (props?.calledFrom == 'employee') {
      if (!props.showHierarchy) {
        if(props.checkedFilter) {
          values = {
            ...values,
            [props.checkedFilter]: props.checkedFilter === 'is_active' ? false : true,
          }
        }
        props.setQueryParam(values)
      } else {
        searchEmployees(values)
      }
    } else if(props.calledFrom === 'simpleEmpView') {
      values = {
        month: values.date && moment(values.date?._d).format('MM'),
        year: values.date && moment(values.date?._d).format('YYYY'),
      }
      await props.setQueryParam(values)
    } else if(props.calledFrom === 'Timesheet') {
      const newObj = {...values, supervisor_id: id, date: moment(values.date).format('YYYY-MM-DD')};
      props.setQueryParam(newObj)
    } else if(props.calledFrom === 'payroll_filter') {
      if(values.salary_range) {
        const [minSalary, maxSalary] = values.salary_range.split('-');
        values = {
          ...values,
          salary_range_start: minSalary,
          salary_range_end: maxSalary
        }
      }
      delete values.salary_range
      await props.setQueryParam(values)
    } else {
      formatDateValue(values, props.format)
      const formattedValues = Object.values(values).filter(item => item)
      if (formattedValues.length) {
                await props.setQueryParam(values)
      }
    }
  }

  const resetSearchForm = () => {
    props?.setCheckedData && props?.setCheckedData([])
    let values:any;
    searchForm.resetFields()
    setIsFilterData(false)
    props.setQueryParam({})
    if (props?.calledFrom == 'employee') {
      if (props.showHierarchy) {
        loadHierarchyListParent()
      } else {
        values = {
          ...values,
          [props.checkedFilter]: props.checkedFilter === 'is_active' ? false : true,
        }
        props.setQueryParam(values)
      }
    }
    if (props.calledFrom == 'Timesheet') {
      props.setQueryParam({})
    }
    if(props.calledFrom === 'simpleEmpView') {
      // loadAttendanceV2(false, {}, 'employee_attendance_filter')
    }
  }

  useEffect(() => {
    searchForm.resetFields()
    setIsFilterData(false)
    props.showHierarchy && props.setQueryParam({})
  }, [props.showHierarchy])

  return (
    <SearchFil>
      <Form
        layout='horizontal'
        form={searchForm}
        onFinish={submitFilterData}
        className={`${style.searchForm} d-lg-flex justify-content-between gap-lg-2 `}
        onValuesChange={() => setIsFilterData(!!checkFilterValues(searchForm.getFieldsValue()))}
      >
        {props.children}
        <div >
          <Col xs={24} sm={3} md={3} lg={3} >
            <Form.Item className={style.allowanceForm} >
              <Space >
                <CustomButton
                  type='primary'
                  disabled={!isFilterData}
                  htmlType='submit'
                  title='Search'
                  size='large'
                  className={style.search}
                  id='filter-submit-button'
                ></CustomButton>
                <CustomButton
                  htmlType='reset'
                  size='large'
                  title='Reset'
                  disabled={!isFilterData}
                  className={style.buttonissue}
                  onClick={resetSearchForm}
                  id='filter-reset-button'
                ></CustomButton>
              </Space>
            </Form.Item>
          </Col>
        </div>
      </Form>
    </SearchFil>
  )

})
export default SearchFilter

const SearchFil = styled.div`
.ant-picker {
  height: 45px !important;
}
@media (min-width: 992px) and (max-width: 1250px) {
  margin-bottom: 15px;
}
.ant-select-item-option-content {
  word-spacing: 1px;
}
.ant-select-selector{
  font-weight:normal !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px !important
}
.ant-form-item-control-input{
  width:100% !important
}
`