import { headerAuthorization } from '../common-utils'

import { baseUrl, newDashboardUrl } from '../const'
import { BaseApi } from '../baseApi'

class NewDashboardApi extends BaseApi {

  setNewDashboard = async data => {
    try {
      const response = await this.axios.post(`${baseUrl}dashboard_detail/`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  newDashboardExpenseGraph = async () => {
    try{
      const response = await this.axios.get(`${baseUrl}expense_graph/`,headerAuthorization(this))
      return response.data
    } catch (error){
      const apiError = error
      throw apiError
    }
  }

  getNewDashboard = async queryParams => {
    try {
      const url = queryParams?.date ? `${newDashboardUrl}/?date=${queryParams?.date}` : `${newDashboardUrl}${queryParams}`
      const response = await this.axios.get(
        url, headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateNewDashboard = async (id, data) => {
    try {
      const url = `${newDashboardUrl}${id}/`
      const response = await this.axios.patch(
        url, data,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

}

export default NewDashboardApi
