import React from 'react'
import { Form, Col, Row} from 'antd'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { TealishBlue } = colors


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const ViewSalary = ({record}: any) => {
  return (
    <div className='form-auto-height'>
      <MainForm
        layout='vertical'
      >
        <Row className='rowDiv'>
          <Col span={24}>
            <Form.Item
              label='Select Employee'
            >
              <CommonInput defaultValue={record?.employeeName} disabled={true}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item
              name='basic_salary'
              label='Basic Salary'
            >
              <CommonInput defaultValue={record.basicSalary} disabled={true}/>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name='recomended_by'
              label='Recommended By'
            >
              <CommonInput defaultValue={record?.recomendedBy?.fullName} disabled={true}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className='mb-3'>
          <Col xs={24} lg={8}>
            <h4>Allowance</h4>
            <Form.Item
              name='Basic_Allowance'
              label='Basic Allowance'>
              <CommonInput defaultValue={record.totalEarning} disabled={true}/>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <h4>Deductions</h4>
            <Form.Item
              name='Deductions'
              label='Deductions'>
              <CommonInput defaultValue={record.totalDeduction} disabled={true}/>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <h4>Increments</h4>
            <Form.Item
              style={{marginTop: '40px'}}
              name='Increment Value'>
              <CommonInput defaultValue={record.incrementSalary}  disabled={true}/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name='totalsalry'
              label='Total Net Salary'
            >
              <CommonInput defaultValue={record.netSalary} disabled={true}/>
            </Form.Item>
          </Col>
        </Row>
      </MainForm>
    </div>
  )
}

export default ViewSalary

const MainForm = styled(Form)`
.formField {
  .addMore {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 9px;

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: ${TealishBlue};
      word-spacing: 0px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
}

h4 {
  color: $color-cyan-primary;
  text-align: start;
}

.submitBtn {
  button {
    margin: 0 auto;
  }
}

.submitBtn {
  :global(.ant-btn-primary) {
    padding: 0 25px;
    text-shadow: 0px 1px 1px rgba(183, 14, 14, 0.25);
  }
}

textarea {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.editIcon {
  position: relative;

  .edit {
    position: absolute;
    right: 30px;
    top: -27px;
    font-size: 14px;
    color: $color-cyan-primary;
    cursor: pointer;
  }

  .delete {
    position: absolute;
    right: 10px;
    top: -27px;
    font-size: 14px;
    color: $color-cyan-primary;
    cursor: pointer;
  }

  :global(.ant-form-item-label) {
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

.iconedit {
  .edit {
    top: -26px;
  }

  .delete {
    top: -26px;
  }

  p {
    font-size: 16px;
  }
}

:global(.anticon-close) {
  position: absolute;
  top: 14px;
  right: 15px;
  background: #b2b9c3;
  border-color: #b2b9c3;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}

.list {
  padding: 0px 0px 8px;
  width: 100%;
  background: rgba(45, 113, 216, 0.05);
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  font-family: 'Poppins';

  .header {
    background: rgba(45, 113, 216, 0.1);
    border-bottom: 1px solid #e2e2e2;
    border-radius: 7px 7px 0px 0px;
    padding: 12px 0px 12px 19px;

    .headerTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${TealishBlue};
      word-spacing: 0 !important;
    }
  }

  ul {
    list-style: none;
    padding: 24px 12px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #525353;
      padding: 15px 0;
      border-bottom: 1px solid #e2e2e2;

      &:last-child {
        border-bottom: 1px solid #e2e2e2;
      }

      div {
        word-spacing: 0 !important;
      }
    }
  }

  .listfooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-spacing: 0 !important;
    padding: 0px 10px;

    .footerText {
      word-spacing: 0 !important;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #010101;
    }

    .totalSalary {
      word-spacing: 0 !important;
    }
  }
}
`