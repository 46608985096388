import {types} from 'mobx-state-tree'
import { toJS } from 'mobx'

export const colorObj = types.model({
  bodyColor: types.maybeNull(types.string),
  headerColor: types.maybeNull(types.string),
  sidebarColor: types.maybeNull(types.string),
  theme: types.maybeNull(types.string)

})



export const themesModel = types
  .model({
    colors: types.maybeNull(colorObj),
  })
  .views(self => ({
    get getColorsData() {
      return toJS(self.colors)
    }
  }))

  .actions(self => {
    // eslint-disable-next-line require-yield
    const setColor = color=>{
      localStorage.setItem('colorTheme', JSON.stringify(color));
      self.colors = {bodyColor: color.bodyColor, headerColor: color.headerColor, sidebarColor: color.sidebarColor, theme: color.theme}
    }
    const loadColor = ()=>{
      const theme = JSON.parse(localStorage.getItem('colorTheme'))
      self.colors = {bodyColor:theme.bodyColor || '#2d71d8', headerColor: theme.headerColor || '', sidebarColor: theme.sidebarColor || '', theme: theme.theme || 'light'}
    }
    return {
      setColor, loadColor
    }
  })

export function initThemeModel() {
  return themesModel.create({})
}
