import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl } from '../const/index'
class ClientTypeApi extends BaseApi {
  setclientType = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}client_type/`,
        { client_type: data.client_type },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getClientType = async num => {
    try {
      const response = await this.axios.get(
        `${baseUrl}client_type/?page=${num ? num : 1}`,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateClientType = async (id, data) => {
    try {
      const response = await this.axios.put(
        `${baseUrl}client_type/${id}/`,
        { client_type: data },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteClientType = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}client_type/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ClientTypeApi
