import { flow, types, cast } from 'mobx-state-tree'
import { assetCategoryApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'

export const assetCategory = types.model({
  id: types.maybeNull(types.number),
  categoryName: types.maybeNull(types.string),
})

export const assetCategoryModel = types
  .model({
    data: types.maybeNull(types.array(assetCategory)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextAssCategory: types.optional(types.number, 1)
  })
  .views( self =>({
    get getAssetCategory() {
      return toJS(self.data);
    },
    get isLoading() {
      return self.loading
    }
  })
  )
  .actions(self => {
    const setAssetCategoryData = flow(function* (data) {
      try {
        self.loading = true
        yield assetCategoryApi.setAssetCategory(data)
        notification.success('Asset Category Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadAssetCategory = flow(function* (page=1, pageSize=20, queryParam='', resetList=false, saveInStore=true) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextAssCategory = 1
        }
        const res = yield assetCategoryApi.getAssetCategory(page , pageSize, queryParam)
        if (!res?.isCancel && saveInStore){
          self.data = cast([...toJS(self.data ?? []), ...res.results])
          if (res.next) {
            self.nextAssCategory = self.nextAssCategory + 1
          } else {
            self.nextAssCategory = 1
          }
        } else if(!res?.isCancel && !saveInStore){
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Assets Category')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateAssetCategory = flow(function* (id, data) {
      try {
        self.loading = true
        yield assetCategoryApi.updateAssetCategory(id, data)
        notification.success('Asset Category Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })


    return {
      setAssetCategoryData,
      loadAssetCategory,
      updateAssetCategory,
  
    }
  })

export function initassetCategoryModel() {
  return assetCategoryModel.create({})
}
