import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl } from '../const/index'

class EmployeeLeaveStatsApi extends BaseApi {
  setEmployeeLeaveStats = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}employee_leave_stats/`,
        data,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeeLeaveStats = async num => {
    try {
      const response = await this.axios.get(
        `${baseUrl}employee_leave_stats/?page=${num ? num : 1}`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeLeaveStatsApi