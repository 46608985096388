import { attendanceHierarchyParent, employeeServiceUrl } from '@api/const'
import { BaseApi } from '../baseApi'

class AttendanceApi extends BaseApi {
  getAttendanceChildrenHierarchy = async id => {
    try {
      const url = `${employeeServiceUrl}attendance/attendance_hierarchy/${id}`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getAttendanceHierarchyParent = async () => {
    try {
      const response = await this.axios.get(attendanceHierarchyParent)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default AttendanceApi
