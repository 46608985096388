import { certificateInfoUrl } from '../const'
import { BaseApi } from '../baseApi'

interface CertificateData {
  employeeId: string;
  title: string;
  authority: string;
  issueDate: string;
  expireDate: string | null;
  licenseNumber: string | null;
  url: string | null;
  expires: boolean;
}

class CertificateApi extends BaseApi {
  setCertificateDetails = async (data: CertificateData) => {
    try {
      const response = await this.axios.post(certificateInfoUrl, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getCertificateDetails = async (employee_id: string) => {
    try {
      const url = `${certificateInfoUrl}/emp/${employee_id}`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateCertificateDetails = async (id: string, data: CertificateData) => {
    try {
      const url = `${certificateInfoUrl}/${id}`
      const response = await this.axios.put(url, data)
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteCertificateDetails = async (id: string) => {
    try {
      const url = `${certificateInfoUrl}/${id}`
      const response = await this.axios.delete(url)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default CertificateApi
