import { BaseApi } from '../baseApi'
import { resignationUrl, hrServicesUrl } from '../const/index'

class resignationApi extends BaseApi {
  setResignation = async data => {
    try {
      const response = await this.axios.post(`${hrServicesUrl}resignation/`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getResignation = async (num = 1, pageSize = 20, queryParam = {}) => {
    try {
      const url = `${resignationUrl}?page=${num}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {}
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateResignation = async (data, id,employee_id) => {
    try {
      const response = await this.axios.patch(`${hrServicesUrl}resignation/lead/${id}/?employee_id=${employee_id}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateEmployeeResignation = async (data, id) => {
    try {
      const response = await this.axios.patch(`${hrServicesUrl}resignation/${id}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteResignation = async (id,employee_id) => {
    try {
      const response = await this.axios.delete(`${hrServicesUrl}resignation/${id}?employee_id=${employee_id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}
export default resignationApi
