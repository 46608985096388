
import FeedbackApi from './Feedback/Feedback'
import ClientApi from './client/client'
import LoanApi from './employeeloan/loan'
import ClientTypeApi from './client/clientType'
import EmployeeApi from './employee/employee'
import EmployeesOpenListApi from './employee/employeesOpenList'
import LanguageApi from './language/language'
import ProjectsApi from './projects/projects'
import EmployeePayrollApi from '@api/Payroll'
import ClientLogsApi from './client/clientLogsApi'
import ProjectLanguageApi from './projects/projectLanguage'
import TeamMembersApi from './team/teamMembers'
import RoleApi from './team/role'
import TeamsApi from './team/teams'
import UserRoleApi from './user/userRole'
import UserLoginData from './user/userLogin'
import ReportsApi from './reports/reports'
import BoardProjectApi from './board/boardProject'
import BoardTaskApi from './board/taskApi'
import BoardLabelApi from './board/boardLabel'
import BoardApi from './board'
import DepartmentApi from './department/department'
import DesignationApi from './designation/designation'
import HRExpensesApi from './hr/hrExpensesApi'
import LeadApi from './lead/lead'
import HRCategoriesApi from './hr/hrCategoriesApi'
import EmployeeLeaveApi from './employeeLeave/employeeLeave'
import AssetsApi from './asset/asset'
import AllowanceApi from './allowance/allowance'
import ResignationApi from './resignation/resignation'
import TerminationApi from './termination/termination'

import AssetsHistoryApi from './asset/assetHistory'
import EmployeeLeaveStatsApi from './dashboard/employeeLeaveStats'
import EmployeeStatsApi from './dashboard/employeeStats'

import AssetsStatsApi from './dashboard/assetStats'
import ExpenseDashboardApi from './dashboard/expenseDashboard'
import ProjectsClientsApi from './dashboard/projects'

import EducationApi from './employeeProfile/education_information'
import ExperienceApi from './employeeProfile/experience'
import EmployeeProfileApi from './employeeProfile/profile'
import FamilyApi from './employeeProfile/family'
import EmployeeRoleApi from './role/roleApi'
import ClientProfileApi from './client/clientProfileApi'
import ForgotPasswordApi from './forgetPassword/forgotPasswordApi'
import ChangePasswordApi from './forgetPassword/changePassword'
import EmployeeBankApi from './employeeProfile/bank'
import EmployeePersonalApi from './employeeProfile/personal'
import EmployeeEmergencyApi from './employeeProfile/emergency'
import CertificateApi from './employeeProfile/certification'
import ProfileProjectsApi from './employeeProfile/profileProjects'
import EmployeeLeaveTypeApi from './employeeLeave/employeeLeaveType'
import EmployeeLeaveHistoryApi from './employeeLeave/employeeLeaveHistory'
import EmployeeLeaveApprovalApi from './employeeLeave/employeeLeaveApproval'
import UserLevelApi from './userLevel/userLevelApi'
import EmployeeLeaveCountApi from './employeeLeave/employeeLeaveCount'
import EmployeeCancelLeaveApi from './employeeLeave/employeeLeaveCancellation'
import GradeApi from './grade/gradeApi'
import BranchApi from './branch/branch'
import SectionApi from './section/sectionApi'
import PunchApi from './attendance/punch'
import AttendanceApi from './attendance/attendance'
import EmployeeLeaveCancelApprovalApi from './employeeLeave/employeeLeaveCancelApproval'
import AttendanceStatsApi from './attendance/attendanceStats'
import ShiftApi from './shift/shift'
import AssetCategoryApi from './asset/assetCategory'
import DashboardEmployeePayrollApi from './dashboard/dashboardEmployeePayroll'
import SerialNumberApi from './asset/serialNumber'
import ClearanceApi from './clearance/clearance'
import ECanteenApi from './eCanteen/eCanteen'
import ECanteenMenuApi from './eCanteen/eCanteenMealMenu'
import DashboardAttendanceApi from './attendanceOverview/dashboardAttendanceOverview'
import EmpClearanceDataApi from './EmpClearance/EmpClearanceData'
import LeadsAssetApi from './assetsleads/assetsleads'
import EmploymentCompanyApi from './employmentCompany/employmentCompany'
import EmployeeHistoryApi from './employee/EmployeeHistoryApi'
import OnBoardingApi from './onboarding/onboarding'
import FeedbackLeadQuestionsApi from './Feedback/FeedbackQuestions'
import OmsNotificationApi from './notification/notification'
import TotalExpenseAmountApi from './hr/TotalExpenseAmountApi'
import AssetAttributeApi from './asset/assetAttribute'
import OnBoardingHistoryApi from './onboarding/onboardingHistory'
import EmployeeRemoteLeaveApi from './employeeLeave/employeeRemoteLeave'
import EmployeeRemoteLeaveCancelApprovalApi from './employeeLeave/employeeRemoteLeaveCancelApproval'
import EmployeeRemoteLeaveHistoryApi from './employeeLeave/employeeRemoteLeaveHistory'
import AssetPermissionApi from './assetHandlingApi/assetHandlingApi'
import NewDashboardApi from './dashboard/newDashboardAPI'
import DashBoardApi from './dashboard/dashboardapi'
import WorkStatsApi from './WorkTimeStatics/workStats'


export const feedbackApi = new FeedbackApi()
export const clientApi = new ClientApi()
export const loanApi = new LoanApi()
export const assetsLeadsApi = new LeadsAssetApi()
export const employmentCompanyApi = new EmploymentCompanyApi()
export const clientTypeApi = new ClientTypeApi()
export const languageApi = new LanguageApi()
export const employeeApi = new EmployeeApi()
export const employeesOpenListApi = new EmployeesOpenListApi()
export const employeeLeaveApi = new EmployeeLeaveApi()
export const employeeRemoteLeaveApi = new EmployeeRemoteLeaveApi()
export const employeeLeaveStatsApi = new EmployeeLeaveStatsApi()
export const employeeStatsApi = new EmployeeStatsApi()
export const assetsStatsApi = new AssetsStatsApi()
export const projectsClientsApi = new ProjectsClientsApi()
export const expenseDashboardApi = new ExpenseDashboardApi()
export const boardApi = new BoardApi()
export const boardProjectApi = new BoardProjectApi()
export const boardTaskApi = new BoardTaskApi()
export const boardLabelApi = new BoardLabelApi()
export const projectsApi = new ProjectsApi()
export const employeePayrollApi = new EmployeePayrollApi()
export const clientLogsApi = new ClientLogsApi()
export const projectLanguageApi = new ProjectLanguageApi()
export const teamMembersApi = new TeamMembersApi()
export const roleApi = new RoleApi()
export const teamsApi = new TeamsApi()
export const userRoleApi = new UserRoleApi()
export const UserLoginDataApi = new UserLoginData()
export const reportsApi = new ReportsApi()
export const departmentApi = new DepartmentApi()
export const designationApi = new DesignationApi()
export const expensesApi = new HRExpensesApi()
export const totalExpenseAmountapi = new TotalExpenseAmountApi()
export const leadApi = new LeadApi()
export const categoriesApi = new HRCategoriesApi()
export const assetsApi = new AssetsApi()
export const assetAttributeApi = new AssetAttributeApi()
export const allowancesApi = new AllowanceApi()
export const resignationApi = new ResignationApi()
export const terminationApi = new TerminationApi()
export const dashboardAttendanceApi = new DashboardAttendanceApi()
export const assetshistoryApi = new AssetsHistoryApi()
export const assetCategoryApi = new AssetCategoryApi()
export const educationApi = new EducationApi()
export const experienceApi = new ExperienceApi()
export const employeeProfileApi = new EmployeeProfileApi()
export const familyApi = new FamilyApi()
export const employeeRoleApi = new EmployeeRoleApi()
export const clientProfileApi = new ClientProfileApi()
export const forgotPasswordApi = new ForgotPasswordApi()
export const changePasswordApi = new ChangePasswordApi()
export const employeeBankApi = new EmployeeBankApi()
export const employeePersonalApi = new EmployeePersonalApi()
export const employeeEmergencyApi = new EmployeeEmergencyApi()
export const certificateApi = new CertificateApi()
export const profileProjectsApi = new ProfileProjectsApi()
export const employeeLeaveTypeApi = new EmployeeLeaveTypeApi()
export const employeeLeaveHistoryApi = new EmployeeLeaveHistoryApi()
export const employeeLeaveApprovalApi = new EmployeeLeaveApprovalApi()
export const userLevelApi = new UserLevelApi()
export const employeeLeaveCountApi = new EmployeeLeaveCountApi()
export const employeeCancelLeaveApi = new EmployeeCancelLeaveApi()
export const gradeApi = new GradeApi()
export const branchApi = new BranchApi()
export const sectionApi = new SectionApi()
export const punchApi = new PunchApi()
export const attendanceApi = new AttendanceApi()
export const employeeLeaveCancelApprovalApi = new EmployeeLeaveCancelApprovalApi()
export const attendanceStatsApi = new AttendanceStatsApi()
export const shiftApi = new ShiftApi()
export const dashboardEmployeePayrollApi = new DashboardEmployeePayrollApi()
export const serialNumberApi = new SerialNumberApi()
export const clearanceApi = new ClearanceApi()
export const ecanteenApi = new ECanteenApi()
export const eCanteenMealMenu = new ECanteenMenuApi()
export const empClearanceDataApi = new EmpClearanceDataApi()
export const employeeHistoryApi = new EmployeeHistoryApi()
export const onBoardingApi = new OnBoardingApi()
export const onBoardingHistoryApi = new OnBoardingHistoryApi()
export const employeeRemoteCancelLeaveApproval = new EmployeeRemoteLeaveCancelApprovalApi()
export const employeeRemoteLeaveHistoryApi = new EmployeeRemoteLeaveHistoryApi()
export const feedbackLeadQuestionsApi = new FeedbackLeadQuestionsApi()
export const omsNotificationApi = new OmsNotificationApi()
export const assetPermissionApi = new AssetPermissionApi()
export const newDahboardApi = new NewDashboardApi()
export const dashBoardApi = new DashBoardApi()
export const workStatsApi = new WorkStatsApi()
