import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'antd'
import style from './style.module.scss'
import styled from 'styled-components'
import { isColorDark } from '@commonComponents/drawer'
import { CommonInput } from '@commonComponents/input'
import { validateRequired } from '@utils/common-functions/formValidations'
interface LeaveApprovedPopupProps {
  setRejectReason?: any;
  msgTitle?: any;
  approvedHandler?: any;
  isLoading?: any;
  visibleDelete?: any;
  handleCancel?: any;
  okText?: any;
  className?: string;
  leaveType?: string;
}

const LeaveApprovedPopup = ({
  msgTitle,
  isLoading,
  visibleDelete,
  handleCancel,
  okText,
  approvedHandler,
  leaveType,
  setRejectReason,
}: LeaveApprovedPopupProps) => {
  const [color, setColor] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    setColor(JSON.parse(localStorage.getItem('colorTheme')))
  }, [])
  const handleOk = () => {
    form.validateFields().then(values => {
      approvedHandler()
      setRejectReason(values.reason)
    })
  }

  return (
    <div>
      <StyledModal
        className={style.ApprovePopup}
        title={msgTitle}
        color={color || 'blue'}
        textColor={isColorDark(color?.bodyColor || 'blue')}
        visible={visibleDelete}
        onOk={handleOk}
        confirmLoading={isLoading}
        okText={okText}
        onCancel={() => {
          handleCancel()
          form.resetFields()
        }}
        destroyOnClose={true}
        width={700}
      >
        <div className={`${style.ApprovedConetent} align-items-center mb-4`}>
          <Form form={form} layout='vertical'>
            <Form.Item
              label='Reason For Rejection'
              name='reason'
              rules={[validateRequired('Reason')]}
            >
              <CommonInput
                inputType='textarea'
                className='w-100 inputField'
                onBlur={() => form.setFields([{ name: 'reason', errors: [] }])}
              />
            </Form.Item>
            <div className={style.modalText}>
              <p>
                Are you sure you want to <span>Reject</span> this {leaveType}{' '}
                leave?
              </p>
            </div>
          </Form>
        </div>
      </StyledModal>
    </div>
  )
}
export default LeaveApprovedPopup

const StyledModal = styled(Modal)`
  .ant-modal-header {
    background: ${props =>
      props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
    color: ${props => (props.textColor ? 'white' : 'black')} !important;
  }
  .ant-modal-footer {
    .ant-btn-primary {
      background: ${props =>
        props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
      color: ${props => (props.textColor ? 'white' : 'black')} !important;
    }
    .ant-btn-default {
      color: ${props =>
        props.color?.bodyColor ? props.color?.bodyColor : 'black'} !important;
      border-color: ${props =>
        props.color?.bodyColor ? props.color?.bodyColor : 'black'} !important;
    }
  }
  .ant-modal-close-icon {
    margin-top: 11px;
    svg {
      width: 30px;
      height: 30px;
      border-radius: 32px;
      padding: 6px;
      background: ${props =>
        props.color?.bodyColor ? props.color?.bodyColor : 'blue'};
      color: ${props =>
        props.color?.textColor
          ? props.color?.textColor + '!important'
          : 'white !important'};
      border-color: ${props =>
        props.color?.bodyColor ? props.color?.bodyColor : 'black'} !important;
      transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      transition-delay: 0.2;
      &:hover {
        color: #fff;
        border-radius: 32px;
        box-shadow: 0px 0px 10px rgba(0, 0.1, 0, 0.5);
        transform: rotate(180deg);
      }
    }
  }
`
