import { educationInfoUrl } from '../const'
import { BaseApi } from '../baseApi'

interface EducationData {
  institution: string;
  degree: string;
  starting_date: string;
  complete_date?: string;
  subject?: string;
  grade?: number;
  employee_id?: string;
  is_continue?: boolean;
}

class EducationApi extends BaseApi {
  setEducationDetails = async (data: EducationData) => {
    try {
      const response = await this.axios.post(educationInfoUrl, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEducationDetails = async (employee_id: string) => {
    try {
      const url = `${educationInfoUrl}/emp/${employee_id}`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEducationDetails = async (id: string, data: EducationData) => {
    try {
      const url = `${educationInfoUrl}/${id}`
      const response = await this.axios.put(url, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteEducationDetails = async (id: string) => {
    try {
      const url = `${educationInfoUrl}/${id}`
      const response = await this.axios.delete(url)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EducationApi
