import { headerAuthorization } from '../common-utils'

import { personalUrl } from '../const'
import { BaseApi } from '../baseApi'
class EmployeePersonalApi extends BaseApi {
  getEmployeePersonalDetails = async (profile, employee_id) => {
    try {
      let url = personalUrl
      if (!profile) {
        url += `?employee_id=${employee_id}`
      }
      const response = await this.axios.get(url, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeePersonalDetails = async (profile, employee_id, id, data) => {
    try {
      let url = personalUrl + `${id}/`
      if (!profile) {
        url += `?employee_id=${employee_id}`
      }
      const response = await this.axios.put(url, data, headerAuthorization(this))
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeePersonalApi
