
import { BaseApi } from '../baseApi'
import { leaveCancelApprovalUrl, leaveHalfCancelUrl, remoteCancelLeaveUrl } from '../const'

class EmployeeRemoteLeaveCancelApprovalApi extends BaseApi {
  setEmployeeRemoteLeaveCancellation = async data => {
    try {
      const response = await this.axios.post(`${remoteCancelLeaveUrl}`, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getEmployeeRemoteLeaveCancelApproval = async num => {
    try {
      const response = await this.axios.get(
        `${remoteCancelLeaveUrl}/?page=${num ? num : 1}`
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeeRemoteLeaveCancelApproval = async (id: string) => {
    try {
      const response = await this.axios.put(
        `${leaveCancelApprovalUrl}${id}/remote_leave`
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  halfCancelLeave = async (
    id: string,
    data: {
      cancel_start_date: string;
      cancel_end_date: string;
      cancellation_reason: string;
    }
  ) => {
    const res = await this.axios.put(`${leaveHalfCancelUrl}/${id}`, data)
    return res.data
  }
}

export default EmployeeRemoteLeaveCancelApprovalApi
