import style from './style.module.scss'
import { CommonSelect } from '@commonComponents/select'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useStore } from '@stores/root-store'

interface supervisorDropdownProps {
  setRefreshTable?: (value) => void;
  rowRecord?: any;
  initialValue?: string;
  placeholder?: string;
  isForm?: boolean;
  onchange?: (value) => void;
  currentData?: any;
  allowClear?: boolean;
  clearMyData?: boolean;
  callFrom?: any;
  disabled?: boolean;
  listHeight?: number;
  dataFetch?: boolean;
  record ?: any;
}

const SupervisorDropdown = observer((props: supervisorDropdownProps) => {
  const [employee, setEmployee] = useState<string | null>(null)
  const [data, setData] = useState<Array<{ key: string; value: string }>>([])
  const {
    employeeDetails: { getEmployeeAllList },
  } = useStore(null)

  useEffect(() => {
    const allEmployeeList =
        getEmployeeAllList?.filter(item => item?.id !== props?.record?.id && item?.id !== props?.record?.supervisorId )?.map(value => ({
        key: value.id,
        value: value.fullName,
      })) || []

    setData(allEmployeeList)
  }, [getEmployeeAllList])
  return (
    <div className={style.tableSelectheight}>
      <div className={`${style.selectTable} position-relative`}>
        <CommonSelect
          callFrom={props.callFrom}
          listHeight={120}
          placeholder={props.placeholder}
          allowClear={props?.clearMyData}
          size='large'
          value={employee ?? props.initialValue ?? props.placeholder}
          getPopupContainer={trigger => trigger.parentNode}
          showSearch
          filterOption={(input, option) =>
            option?.children
              ?.toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          data={data}
          onChange={(value: string) => {
            props.onchange && props.onchange(value)
            setEmployee(value)
          }}
          {...props}
        />
      </div>
    </div>
  )
})

export default SupervisorDropdown
