import React, { useEffect, useState } from 'react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import { observer } from 'mobx-react'
import { Col, List, Row, Tooltip } from 'antd'
import 'antd/dist/antd.css'
import logoo from '../../../assets/img/logoo.svg'
import { useNavigate, useParams } from 'react-router-dom';
import html2pdf from 'html2pdf.js'
import { numberFormat } from 'components/utils/string_formate'
import { MdDownload, MdLocalPrintshop } from 'react-icons/md'
import { useStore } from '@stores/root-store';
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

const PayrollSlip = observer(() => {
  const params = useParams()
  const navigate = useNavigate()
  const [heighestLength, setHeighestLength] = useState(0)
  const [colortheme, setColorTheme] = useState({ bodyColor: TealishBlue })
  const [payrollDetail, setPayrollDetail] = useState({
    employeeFirstName: null,
    employeeLastName: null,
    employId: null,
    paySlipId: null,
    employeeDesignation: null,
    joiningDate: null,
    basicSalary: null,
    lastIncrement: null,
    bonus: null,
    allowances: [],
    netAllowance: null,
    netDeduction: null,
    netSalary: null,
    companyAddress: null,
    month: null,
    salaryMonth: null
  })

  const {

    employeePayroll: {
      retrieveEmployeePayroll
    },
  } = useStore(null)

  const Color = JSON.parse(localStorage.getItem('colorTheme'))

  useEffect(() => {
    setColorTheme(Color ? Color?.bodyColor : TealishBlue)
  }, [])



  useEffect(() => {
    const retrieveEmployeePayrollById = async () => {
      const resp = await retrieveEmployeePayroll(params?.id, false)

      if (resp.success) {
        setPayrollDetail(resp.res)
      } else {
        navigate(`${constRoute.payroll}`);
      }
    }
    retrieveEmployeePayrollById()
  }, [])

  const count =[];
  for(let i=3; i < heighestLength; i++) {
    count.push(i);
  }

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

  const currentDate = new Date();
  let { month, year } = { month: currentDate.getMonth(), year: currentDate.getFullYear() };
  month = (month === 0) ? 11 : month - 1;
  year = (month === 11) ? year - 1 : year;

  useEffect(()=>{
    const deduction = payrollDetail?.allowances?.filter(e => e.isDeduction==true)?.length
    const allowances = payrollDetail?.allowances?.filter(e => e.isDeduction==false)?.length
    const length = (deduction > allowances) ? true : false
    length ? setHeighestLength(deduction) : setHeighestLength(allowances)
  },[payrollDetail])

  const downloadPayslip = () => {
    const element = document.getElementById('capture');
    const opt = {
      margin: .25,
      filename: `${payrollDetail?.employeeFirstName + ' ' + payrollDetail?.employeeLastName}_${monthNames[month]}_${year}_slip.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  }
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const totalSalary: any = payrollDetail?.basicSalary + payrollDetail?.lastIncrement + payrollDetail?.bonus;

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='d-flex justify-content-between'>
              <BreadCrumbs
                list={[
                  { name: 'Dashboard', link: constRoute.home },
                  { name: 'Payroll', link: constRoute.payroll },
                ]}
                pageTitle={'Payslip'}
              >
              </BreadCrumbs>
              <Payrollslip>
                <Downloadpdf style={{backgroundColor:colortheme}}>
                  <Tooltip placement='top' title='Download PDF'>
                    <a onClick={downloadPayslip}><MdDownload /></a>
                  </Tooltip>
                </Downloadpdf>
                <Printpdf style={{backgroundColor:colortheme}}>
                  <Tooltip placement='top' title='Print'>
                    <MdLocalPrintshop />
                  </Tooltip>
                </Printpdf>
              </Payrollslip>
            </div>
          </div>
        </div>
        <Payrollwrapper id='capture'>
          <Row>
            <Col span={24}>
              <PayrollHeader>
                <div>
                  <img src={logoo} alt='logoo' />
                </div>
                <h4>PAYSLIP FOR MONTH OF {monthNames[month]} {year}</h4>
              </PayrollHeader>
            </Col>
          </Row>
          <div>
            <Row>
              <Col xs={24} md={12} lg={12}>
                <Userdetails>
                  <ul>
                    <li>
                      <Keys>Full Name:</Keys>
                      <Values><span>{payrollDetail?.employeeFirstName + ' ' + payrollDetail?.employeeLastName || ''}</span></Values>
                    </li>
                    <li>
                      <Keys>Designation:</Keys>
                      <Values><span>{payrollDetail?.employeeDesignation}</span></Values>
                    </li>
                    <li>
                      <Keys>Employee ID:</Keys>
                      <Values><span>{payrollDetail?.employId}</span></Values>
                    </li>
                    <li>
                      <Keys>Joining Date:</Keys>
                      <Values><span>{payrollDetail?.joiningDate}</span></Values>
                    </li>
                  </ul>
                </Userdetails>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Textright>
                  <ul>
                    <li>
                      <Keys>PAYSLIP: </Keys>
                      <Values><span>{payrollDetail?.paySlipId}</span></Values>
                    </li>
                    <li>
                      <Keys>Salary Month: </Keys>
                      <Values><span>{monthNames[month]} {year}</span></Values>
                    </li>
                    <li>
                      <Keys>Company Address:</Keys>
                      <Values><span>{payrollDetail?.companyAddress}</span></Values>
                    </li>
                  </ul>
                </Textright>
              </Col>
            </Row>
            <Userlist>
              <Row>
                <Col xs={8} md={8} lg={8}>
                  <List style={{ borderBottom: '0px' }}
                    size='large'
                    header={<span>Salary</span>}
                    bordered
                  >
                    <List.Item>
                      <p>Basic Salary</p>
                      <p>{numberFormat(payrollDetail?.basicSalary)}</p>
                    </List.Item>
                    <List.Item>
                      <p>Increment</p>
                      <p>{numberFormat(payrollDetail?.lastIncrement)}</p>
                    </List.Item>
                    <List.Item>
                      <p>Bonus</p>
                      <p>{numberFormat(payrollDetail?.bonus)}</p>
                    </List.Item>
                    {heighestLength > 3 ? count?.map(item=>
                      <List.Item key={item} style={{justifyContent: 'center'}}>
                        <p>-</p>
                      </List.Item>) : ''}
                  </List>
                </Col>

                <Allowncetxt span={8} xs={8} md={8} lg={8}>
                  <List style={{ borderLeft: '0px' }}
                    size='large'
                    header={<span>Allowances</span>}
                    bordered
                  >
                    {payrollDetail?.allowances?.map((item, index) => {
                      if (!item.isDeduction) {
                        return (
                          <List.Item key={index}>
                            <p>{item.name}</p>
                            <p key={index}>{item.value}</p>
                          </List.Item>
                        )
                      }
                      return null;
                    })}
                    {Array.from({ length: heighestLength - payrollDetail?.allowances?.filter(item => !item.isDeduction).length }).map((_, index) => (
                      <List.Item key={index} style={{ justifyContent: 'center' }}>
                        <p>-</p>
                      </List.Item>
                    ))}
                  </List>
                </Allowncetxt>

                <Dedecationtxt span={8} xs={8} md={8} lg={8}>
                  <List style={{ borderLeft: '0px' }}
                    size='large'
                    header={<span>Deductions</span>}
                    bordered
                  >
                    {payrollDetail?.allowances?.map((item, index) => {
                      if (item.isDeduction) {
                        return (
                          <List.Item key={index}>
                            <p>{item.name}</p>
                            <p key={index}>{item.value}</p>
                          </List.Item>
                        )
                      }
                      return null;
                    })}
                    {Array.from({ length: heighestLength - payrollDetail?.allowances?.filter(item => item.isDeduction).length }).map((_, index) => (
                      <List.Item key={index} style={{ justifyContent: 'center' }}>
                        <p>-</p>
                      </List.Item>
                    ))}
                  </List>
                </Dedecationtxt>

                <Col span={8} xs={8} md={8} lg={8} >
                  <Netsalry
                    style={{ borderBottom: '0px', borderRight: '0px' }}>
                    {payrollDetail?.netSalary >= 0 && (
                      <List.Item>
                        <p>Total Salary</p>
                        <p>+{numberFormat(totalSalary)}</p>
                      </List.Item>
                    )}
                  </Netsalry>
                </Col>
                <Col span={8} xs={8} md={8} lg={8}>
                  <Netsalry
                    style={{ borderBottom: '0px', borderRight: '0px' }}>
                    {payrollDetail?.netAllowance >= 0 && (
                      <List.Item>
                        <p>Total Allowance</p>
                        <p>+{numberFormat(payrollDetail?.netAllowance)}</p>
                      </List.Item>
                    )}
                  </Netsalry>
                </Col>
                <Col span={8} xs={8} md={8} lg={8}>
                  <Netsalry
                    style={{ borderBottom: '0px' }}>
                    {payrollDetail?.netDeduction >= 0 && (
                      <List.Item>
                        <p>Total Deduction</p>
                        <p>-{numberFormat(payrollDetail?.netDeduction)}</p>
                      </List.Item>
                    )}
                  </Netsalry>
                </Col>

                <Col xs={24} md={24} lg={24}>
                  <Finalsalary>
                    {payrollDetail?.netSalary >= 0 && (
                      <List.Item>
                        <p>This Month Net Salary</p>
                        <p>{numberFormat(payrollDetail?.netSalary) + ' '}</p>
                      </List.Item>
                    )}
                  </Finalsalary>
                </Col>
              </Row>
            </Userlist>
          </div>
          {/* history end here */}
        </Payrollwrapper>
      </div>
    </div>
  )
})

export default PayrollSlip;

const Payrollslip = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`
const Downloadpdf = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    color: #ffffff;
    position: relative;
    top: 3px;
  }
`
const Printpdf = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    color: #ffffff;
  }
`
const Payrollwrapper = styled.div`
  padding: 70px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0px 0px;
    .textRight {
      text-align: left;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        li {
          padding-bottom: 5px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 32px;
          color: #010101;
          display: flex;
        }
      }
      @media (max-width: 767px) {
        text-align: center;
        padding: 0;
        margin-bottom: 15px;
      }
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #010101;
    }
`
const PayrollHeader = styled.div`
  background: #fff;
  text-align: center;
  margin-bottom: 50px;
  h4 {
    display: inline-block;
    padding-top: 25px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #010101;
    word-spacing: 0px;
  }
`
const Userdetails =  styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    li {
      padding-bottom: 5px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #010101;
      display: flex;
      .key {
        flex-basis: 50%;
        word-spacing: 1px;
      }
      .value {
        flex-basis: 50%;
        text-align: left;
        word-spacing: 1px;
      }
    }
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #010101;
  }
`
const Keys= styled.div`
  flex-basis: 50%;
  word-spacing: 1px;

`
const Values = styled.div`
  flex-basis: 50%;
  text-align: left;
  word-spacing: 1px;
`
const Textright = styled.div`
  text-align: left;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    li {
      padding-bottom: 5px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #010101 !important;
      display: flex;
    }
  }
  @media (max-width: 767px) {
    text-align: center;
    padding: 0;
    margin-bottom: 15px;
  }

`
const Userlist = styled.div`
  h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .ant-list-header {
    background: #525353;
    border-collapse: collapse;
    padding: 16px 14px !important;
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      word-spacing: 1px;
    }
  }
  .ant-list {
    overflow: auto hidden;
    border-color: #747979;
    .ant-list-item {
      padding: 14px 16px;
      display: flex;
      flex-wrap: nowrap !important;
      min-height: 40px;
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: rgba(0, 0, 0, 0.85);
        word-spacing: 1px !important;
      }
    }
    &:ant-list-bordered {
      border-top: 0px;
    }
  }
  .mb {
    margin-bottom: 0px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .netSalary {
    width: 100%;
    border: 1px solid #747979;
    border-collapse: collapse;
    padding: 0;
    padding-bottom: 15px;
    font-size: 20px;
    margin-left: -1px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #010101;
      word-spacing: 1px !important;
    }
  .ant-list-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .allowances {
    border-right: 1px solid #747979;
    .ant-list {
      border-bottom: 0px;
      border-right: 0px;
    }
  }
  .deductions {
    border-right: 1px solid #747979;
    .ant-list {
      border-right: 0px;
      border-bottom: 0px;
    }
  }
`
const Allowncetxt = styled(Col)`
  border-right: 1px solid #747979;
  .ant-list {
    border-bottom: 0px;
    border-right: 0px;
  }
`
const Dedecationtxt = styled(Col)`
  border-right: 1px solid #747979;
  .ant-list {
    border-right: 0px;
    border-bottom: 0px;
  }
`
const Netsalry = styled.div`
  width: 100%;
  border: 1px solid #747979;
  border-collapse: collapse;
  padding: 0;
  padding-bottom: 15px;
  font-size: 20px;
  margin-left: -1px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #010101;
    word-spacing: 1px !important;
  }
  .ant-list-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`
const Finalsalary = styled.div`
  width: 100%;
  border: 1px solid #747979;
  border-collapse: collapse;
  padding: 0;
  padding-bottom: 15px;
  font-size: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 27px;
    color: #010101;
    word-spacing: 1px !important;
  }
  .ant-list-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`