import { headerAuthorization } from '../common-utils';
import { employeehistoryUrl } from '../const';
import { BaseApi } from '../baseApi';
class EmployeeHistoryApi extends BaseApi {
  getEmployeeHistoryById = async id => {

    try {
      const response = await this.axios.get(
        `${employeehistoryUrl}/${id}/`,
        headerAuthorization(this)
      );
      return response?.data;
    } catch (error) {
      const x = error;
      throw x;
    }
  };
}

export default EmployeeHistoryApi;

  