import { flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeLeaveApprovalApi } from '@api'
import { notification } from '@utils/notifications'

export const data = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})
export const updatedBy = types.model({
  approvedById: types.maybeNull(types.string),
  approvedByFirstName: types.maybeNull(types.string),
  approvedByLastName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  userStatus: types.maybeNull(types.string),
})

export const employeeLeaveApproval = types.model({
  id: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  leaveStartDate: types.maybeNull(types.string),
  leaveEndDate: types.maybeNull(types.string),
  leaveType: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  employee: types.maybeNull(data),
  lead: types.maybeNull(data),
  nextLead: types.maybeNull(types.array(types.string)),
  rejectionLeaveReason: types.maybeNull(types.string),
  approvedBy: types.maybeNull(types.number),
  rejectedBy: types.maybeNull(types.number),
  updatedBy:types.maybeNull(types.array(updatedBy)),
  createdAt:types.maybeNull(types.string),
  numberOfDays:types.maybeNull(types.number),
  leaveTypeName:types.maybeNull(types.string),

})

export const employeeLeaveApprovalModal = types
  .model({
    data: types.maybeNull(types.array(employeeLeaveApproval)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getEmployeeLeaveApprovalData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setEmployeeLeaveApprovalData = flow(function* (data) {
      try {
        self.loading = true
        yield employeeLeaveApprovalApi.setEmployeeLeaveApproval(data)
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadEmployeeLeaveApproval = flow(function* (searchParams?, num = 1 , size=10) {
      try {
        self.loading = true
        const res = yield employeeLeaveApprovalApi.getEmployeeLeaveApproval(searchParams, num,size)
        self.data = res.results
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Leaves for Approval')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeLeaveApproval = flow(function* (id) {
      try {
        self.loading = true
        yield employeeLeaveApprovalApi.updateEmployeeLeaveApproval(id)
        notification.success('Leave Status Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEmployeeLeaveApproval = flow(function* (id) {
      try {
        self.loading = true
        yield employeeLeaveApprovalApi.deleteEmployeeLeaveApproval(id)
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const leaveRejection = flow(function* (id, data) {
      try {
        self.loading = true
        yield employeeLeaveApprovalApi.employeeLeaveRejection(id, data)
        notification.success('Leave Status Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    
    return {
      setEmployeeLeaveApprovalData,
      loadEmployeeLeaveApproval,
      deleteEmployeeLeaveApproval,
      updateEmployeeLeaveApproval,
      leaveRejection
    }
  })

export function initEmployeeLeaveApprovalModal() {
  return employeeLeaveApprovalModal.create({})
}
