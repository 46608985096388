import PunchBtnLoader from '@mainPage/attendence/PunchButtonCounter/punchBtnLoader'
import Stopwatch from '@mainPage/attendence/PunchButtonCounter/timer'
import { convertDurationToSeconds, getLunchTime, getPrayerTime, getProductiveTime } from '@mainPage/attendence/utils'
import { useStore } from '@stores/root-store'
import { Card, Col, Row, Skeleton } from 'antd'
import { colors } from 'constant/colors'
import { BEGIN_RECESS, COMMENCE_PRAYER, CONCLUDE_PRAYER, FINISH_RECESS, PUNCH_IN, PUNCH_OUT} from 'constant/constant'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LATITUDE, LONGITUTE } from '@mainPage/constant'

const { TealishBlue } = colors

export const EmployeePunchButton = observer(
  ({ refreshStatistics,
    refreshPunchActivity,
    setRefreshStatistics,
    setRefreshPunchActivity,
   }: any) => {
    const {
      punchModal: { getPunchData, loadPunchData, setPunch },
      themesModel: { getColorsData },
    } = useStore(null)

    const [punchState, setPunchState] = useState('')
    interface LoadingIndicatorTypes {
      type: 'prayer' | 'lunch' | '';
      loading: boolean;
    }

    const [activeLoader, setActiveLoader] = useState<LoadingIndicatorTypes>({
      type: '',
      loading: false,
    })

    const [isContactLoader, setIsContactLoader] = useState(false)
    const [textColor, setTextColor] = useState(null)
    const [productiveTimeStatus, setProductiveTimeStatus] = useState('')
    const [prayerTimeStatus, setPrayerTimeStatus] = useState('')
    const [lunchTimeStatus, setLunchTimeStatus] = useState('')

    useEffect(() => {
      getData()
    }, [])

    const getData = async () => {
      setIsContactLoader(true)
      await loadPunchData(),
      setIsContactLoader(false)
    }
    

    useEffect(() => {
      const stateAttendance = getPunchData?.status
      setPunchState(stateAttendance)

      const productiveTimerState =
        stateAttendance === FINISH_RECESS ||
          stateAttendance === CONCLUDE_PRAYER ||
          stateAttendance === PUNCH_IN
          ? PUNCH_OUT
          : PUNCH_IN

      const recessTimerState =
        stateAttendance === BEGIN_RECESS ? FINISH_RECESS : BEGIN_RECESS

      const prayerTimerState =
        stateAttendance === COMMENCE_PRAYER
          ? CONCLUDE_PRAYER
          : COMMENCE_PRAYER

      setProductiveTimeStatus(productiveTimerState)
      setLunchTimeStatus(recessTimerState)
      setPrayerTimeStatus(prayerTimerState)
    }, [getPunchData])

    const punchTypeData = {
      lunch: lunchTimeStatus,
      prayer: prayerTimeStatus,
    };

    const handleButtonClick = async type => {
      setActiveLoader({
        type: type,
        loading: true,
      })
      
      const punchType = punchTypeData[type];
      
      setPunchState(prevCount => prevCount + 1)
      await setPunch(LATITUDE, LONGITUTE, punchType)
      await loadPunchData()
      setRefreshStatistics(!refreshStatistics)

      setTimeout(() => {
        setActiveLoader({
          type: type,
          loading: false,
        })
      }, 1000)
      setRefreshPunchActivity(!refreshPunchActivity)
    }

    useEffect(() => {
      getProductiveTime(getPunchData);
      getPrayerTime(getPunchData)
      getLunchTime(getPunchData)
    }, [])

    useEffect(() => {
      const storedTheme = JSON.parse(localStorage.getItem('colorTheme'))
      setTextColor(storedTheme?.theme === 'dark' ? 'white' : 'rgb(64, 64, 64)')
    })
    return (
      <PunchWrapper theme={getColorsData?.theme}>
        <TodayTimeSheet>Today’s Timesheet</TodayTimeSheet>
        <StyleText style={{ color: textColor }}>
          Today's Productive Time
        </StyleText>
        <Skeleton active loading={isContactLoader}>
          <StyledTimeSheet>
            <Stopwatch
              PunchState={productiveTimeStatus}
              elapsedTimeProp={convertDurationToSeconds(getProductiveTime(getPunchData))}
            />
          </StyledTimeSheet>

          <RespponsiveRow>
            <Timerstylings xs={24} sm={8} md={8} lg={8}>
              <Row className='align-items-center' justify='space-between'>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className='punchButton'>
                    {activeLoader.loading && activeLoader.type === 'prayer' ? (
                      <PunchBtnLoader />
                    ) : (
                      <button
                        disabled={false}
                        className={'punchinButton'}
                        style={{
                          fontSize: '20px',
                          background:
                          punchState === 'commence_prayer'
                              ? 'red'
                              : getColorsData?.theme === 'dark'
                                ? 'black'
                                : getColorsData?.bodyColor,
                        }}
                        onClick={() => handleButtonClick('prayer')}
                      >
                        {'Prayer'}
                      </button>
                    )}
                    <TimerSet style={{ width: 'max-content' }}>
                      <Stopwatch
                        PunchState={prayerTimeStatus}
                        elapsedTimeProp={convertDurationToSeconds(getPrayerTime(getPunchData))}
                        
                      />
                    </TimerSet>
                  </div>
                  <BottomText style={{ color: textColor }}>
                    Prayer Break Time
                  </BottomText>
                </Col>
              </Row>
            </Timerstylings>

            {/* lunch timer */}
            <Timerstylings xs={24} sm={8} md={8} lg={8}>
              <Row className='align-items-center' justify='center'>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className='punchButton'>
                    {activeLoader.loading && activeLoader.type === 'lunch' ? (
                      <PunchBtnLoader />
                    ) : (
                      <button
                        disabled={false}
                        className={'punchinButton'}
                        style={{
                          fontSize: '20px',
                          background:
                          punchState === 'begin_recess'
                              ? 'red'
                              : getColorsData?.theme === 'dark'
                                ? 'black'
                                : getColorsData?.bodyColor,
                        }}
                        onClick={() => handleButtonClick('lunch')}
                      >
                        {'Lunch'}
                      </button>
                    )}
                    <TimerSet style={{ width: 'max-content' }}>
                      <Stopwatch
                          PunchState={lunchTimeStatus}
                        elapsedTimeProp={convertDurationToSeconds(getLunchTime(getPunchData))}
                      />
                    </TimerSet>
                  </div>
                  <BottomText style={{ color: textColor }}>
                    Lunch Break Time
                  </BottomText>
                </Col>
              </Row>
            </Timerstylings>
          </RespponsiveRow>
        </Skeleton>
      </PunchWrapper>
    )
  }
)

const RespponsiveRow = styled(Row)`
  display: flex !important ;
  justify-content: space-around !important;
  align-items: center !important;
  gap: 15px !important;

  @media screen and (min-width: 0px) and (max-width: 700px) {
    .ant-row {
      display: flex;
      flex-direction: column;
    }
  }
`
const BottomText = styled.p`
  display: block;
  width: max-content;
  margin: auto;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  line-height: 27px !important;
`
const TimerSet = styled.div`
  margin-top: 14px;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: rgb(64, 64, 64) !important;
  text-transform: capitalize !important;
  line-height: 27px !important;
`

const PunchWrapper = styled(Card)`
  min-height: 450px !important;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: 'poppins';
  margin-bottom: 25px;

  h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #010101;
    @media (max-width: 576px) {
      text-align: center;
    }
  }

  h4 {
    font-size: 16px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: baseline;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    color: #525353;
  }

  h1 {
    color: ${TealishBlue};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 0px;
    }
  }

  span {
    font-style: normal;
    font-family: 'Outfit';
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #525353;
    word-spacing: 2px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  @media (max-width: 305px) {
    .timeSheetCard {
      :global(.ant-card-body) {
        padding: 10px !important;
      }
    }
  }

  .punchinBorder {
    border-right: none;
    h1 {
      font-size: 27px !important;
      @media (max-width: 576px) {
        font-size: 20px !important;
      }
    }
    @media (max-width: 1600px) {
      border: none !important;
    }
    @media (max-width: 576px) {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      align-items: center;
    }
  }

  .borderLeft {
    padding-left: 30px;
    h1 {
      font-size: 27px !important;
      @media (max-width: 576px) {
        font-size: 20px !important;
      }
    }
  }

  @media (max-width: 1600px) {
    .borderLeft {
      padding-left: 0;
    }
    .Border {
      border: none;
    }
  }
  @media (max-width: 576px) {
    .borderLeft {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      align-items: center;
    }
  }

  .punchButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .punchinButton {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 650;
    color: #ffffff;
    font-size: 26px;
    line-height: 33px;
    width: 190px;
    height: 190px;
    border: 3px solid #f8f8f8;
    box-shadow: ${props =>
    props.theme === 'dark'
      ? 'rgb(6 6 6 / 15%) 0px 0px 15px, rgb(92 85 85 / 80%) 0px 0px 38px inset'
      : '0px 0px 15px rgba(0, 0, 0, 0.15), inset 0px 0px 38px rgba(248, 248, 248, 0.8)'};
    border-radius: 50%;
    @media (max-width: 1500px) {
      height: 165px;
      width: 165px;
    }

    h3 {
      color: #ffffff;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 33px;
      margin-top: 8px;
      align-items: center;
    }

    @media (max-width: 768px) {
      width: 150px;
      height: 150px;
      line-height: 22px;
      font-size: 22px;
    }
  }

  @media (max-width: 768px) {
    .punchinButton {
      height: 130px;
      width: 130px;
    }
  }
`

const Timerstylings = styled(Col)`
  .ant-row h4 {
    justify-content: center !important;
    h1 {
      font-size: 35px !important;
      font-weight: 600;
    }
  }
`

const StyledTimeSheet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
`
const StyleText = styled.div`
  text-align: center;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  line-height: 27px !important;
  @media (max-width: 450px) {
    font-size: 14px;
  }
`
const TodayTimeSheet = styled.div`
  text-align: center;
  font-family: 'Poppins' !important;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1px;
`
