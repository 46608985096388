import {constRoute} from '@utils/route';

export const SALARY_RANGE = [
  { key: '10000-20000', value: '10,000 > 20,000' },
  { key: '20000-30000', value: '20,000 > 30,000' },
  { key: '30000-40000', value: '30,000 > 40,000' },
  { key: '40000-50000', value: '40,000 > 50,000' },
  { key: '50000-60000', value: '50,000 > 60,000' },
  { key: '60000-70000', value: '60,000 > 70,000' },
  { key: '70000-80000', value: '70,000 > 80,000' },
  { key: '80000-90000', value: '80,000 > 90,000' },
  { key: '90000-100000', value: '90,000 > 1,00,000' },
]
export const PAYROLL_BREAD_CRUMBS = [
  { name: 'Dashboard', link: constRoute.home },
  { name: 'Payroll', link: constRoute.payroll },
]
