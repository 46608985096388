
import { BaseApi } from '../baseApi'
import { rolePermissionUrl } from '../const'


class UserRoleApi extends BaseApi {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setUserRoleDetails = async (data: any) => {
    try {
      const response = await this.axios.post(rolePermissionUrl, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getUserRoleDetails = async () => {
    try {
      const response = await this.axios.get(rolePermissionUrl)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getSingleUserRoleDetails = async id => {
    try {
      const response = await this.axios.get(rolePermissionUrl + id)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateUserRoleDetails = async (data, recordId) => {
    try {
      const response = await this.axios.put(
        rolePermissionUrl + recordId, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteUserRoleDetails = async (id: number) => {
    try {
      const response = await this.axios.delete(rolePermissionUrl + id)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}
export default UserRoleApi
