import { BaseApi } from '../baseApi'
import { headerAuthorization } from '../common-utils'
import {
  employeeEmpUrl,
  employeeBaseUrl,
  employeesHierarchyUrl,
  employeesCSVUrl,
  EmployeeWithoutPayroll,
  employeeFieldManagementUrl,
  allEmployeeListUrl,
  SupervisorListUrl,
  leadShift,
  leadDesignation,
  leadRole,
} from '../const'

export interface EmployeeDetailsTypes {
  user: {
    first_name: string;
    last_name: string;
    email: string;
  };
  designation: string;
  department?: string;
  supervisor?: string;
  role?: string |[];
  shift?: string;
  employment_model: string[];
}
class EmployeeApi extends BaseApi {
  setEmployeeDetails = async (data: EmployeeDetailsTypes) => {
    try {
      const response = await this.axios.post(employeeBaseUrl, data)
      return response.data
    } catch (error) {
      if (error?.response?.data?.user) {
        const emailError = error.response.data.user?.email?.[0]
        throw new Error(emailError)
      }

      throw error
    }
  }

  getEmployeeDetails = async (num, pageSize, queryParam = {}) => {
    try {
      const queryParams = { page: num, page_size: pageSize, ...queryParam }
      const response = await this.axios.get(employeeEmpUrl, {
        params: queryParams,
      })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeeList = async () => {
    try {
      const response = await this.axios.get(allEmployeeListUrl)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getSupervisorList = async () => {
    try {
      const response = await this.axios.get(SupervisorListUrl)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getLeadShift = async () => {
    try {
      const response = await this.axios.get(leadShift)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getLeadDesignation = async (num ,pageSize, queryParam={}) => {
    try {
      const url = `${leadDesignation}?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getLeadRole = async () => {
    try {
      const response = await this.axios.get(leadRole)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeeHierarchy = async supervisor_id => {
    try {
      const url = `${employeesHierarchyUrl}/${supervisor_id}`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeeHierarchyParent = async () => {
    try {
      const response = await this.axios.get(employeesHierarchyUrl)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateEmployeeDetails = async (id, data) => {
    try {
      const response = await this.axios.put(
        `${employeeEmpUrl}?pk=${id}`,
        data
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  employeeColField = async data => {
    function updateTitle({ title, ...rest }) {
      if (typeof title !== 'string') {
        return {
          ...rest,
          title: 'Action',
        }
      }

      return { title, ...rest }
    }

    function updateFields(fields) {
      for (const fieldName in fields) {
        if (Array.isArray(fields[fieldName])) {
          fields[fieldName] = fields[fieldName].map(updateTitle)
        }
      }
      return fields
    }

    const fields = { fields: updateFields(data.fields) }

    try {
      const response = await this.axios.post(
        employeeFieldManagementUrl,
        fields
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  loademployeeColField = async () => {
    try {
      const response = await this.axios.get(employeeFieldManagementUrl)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  exportEmployeeRecord = async (queryParam = {}) => {
    try {
      const response = await this.axios.get(employeesCSVUrl, {
        params: queryParam ? { ...queryParam } : {},
      })

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getAllEmployeeList = async (num, pageSize, queryParam = {}) => {
    try {
      const url = `${employeeEmpUrl}?page=${
        num ? num : 1
      }&page_size=${pageSize}`
      const response = await this.axios.get(url, {
        params: queryParam ? { ...queryParam } : {},
        ...headerAuthorization(this),
      })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  filterEmployeeDetails = async (
    employeeID,
    empname,
    designation,
    supervisor,
    role,
    num,
    pageSize
  ) => {
    try {
      const url = `${employeeEmpUrl}?designation=${designation}&employeeID=${employeeID}&name=${empname}&page=${
        num ? num : 1
      }&page_size=${pageSize}&role=${role}&supervisor=${supervisor}`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteEmployeeDetails = async id => {
    try {
      const response = await this.axios.delete(`${employeeBaseUrl}employee/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeesWithoutPayroll = async () => {
    try {
      const url = `${EmployeeWithoutPayroll}`;
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  
  searchFilterEmployees = async queryParams => {
    try {
      const url = `${employeeBaseUrl}/employee_filter/`
      const response = await this.axios.get(url, {
        params: queryParams ? { ...queryParams } : {},
      })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeApi
