import { cast, flow, types } from 'mobx-state-tree'
import { employeeHistoryApi } from '../../api'
import { toJS } from 'mobx'


export const historyEmployee = types.model({
  branch: types.maybeNull(types.string),
  designation: types.maybeNull(types.string),
  joiningDate: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  shift : types.maybeNull(types.string),
  supervisor: types.maybeNull(types.string),
})

export const historyhistoryInfo = types.model({
  updatedAt: types.maybeNull(types.string),
  history: types.maybeNull(historyEmployee)

})

export const employeeData = types.model({
  employee: types.maybeNull(types.string),
  designation: types.maybeNull(types.string),
  joiningDate: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  department : types.maybeNull(types.string),
  supervisor: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  mobile: types.maybeNull(types.string),
  emergencyContactName: types.maybeNull(types.string),
  emergencyContactNumber: types.maybeNull(types.string),
  emergencyContactRelation: types.maybeNull(types.string),
  history: types.maybeNull(types.array(historyhistoryInfo)),
})

export const assetHistory = types.model({
  assetUser: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  assetAttributes: types.maybeNull(types.array(types.string)),

})

export const assetInfo = types.model({
  assetAssetId: types.maybeNull(types.string),
  assetName: types.maybeNull(types.string),
  updatedFields: types.maybeNull(assetHistory),
})


export const employeeAssets = types.model({
  name: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string)
})

export const employeeAttendance= types.model({
  date: types.maybeNull(types.string),
  totalLeaves: types.maybeNull(types.number),
  totalPresence: types.maybeNull(types.number),
  totalAbsence: types.maybeNull(types.number),
  totalWorkingHours: types.maybeNull(types.number)
})

export const historyObject = types.model({
  role: types.maybeNull(types.string),
  shift: types.maybeNull(types.string),
  branch: types.maybeNull(types.string),
  supervisor: types.maybeNull(types.string),
  designation: types.maybeNull(types.string),
  joiningDate: types.maybeNull(types.string),
})

export const newHistory = types.model({
  history: types.maybeNull(historyObject),
})

export const basicInfo = types.model({
  employee: types.maybeNull(types.string),
  designation: types.maybeNull(types.string),
  joiningDate: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  department : types.maybeNull(types.string),
  supervisor: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  mobile: types.maybeNull(types.string),
  emergencyContactName: types.maybeNull(types.string),
  emergencyContactRelation: types.maybeNull(types.string),
  history:types.maybeNull(types.array(newHistory))
})

export const employeeLoan = types.model({
  loanName: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  loanAmount: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  applyDate: types.maybeNull(types.string),
  loanDisbursementDate: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
})

export const salaryHistory = types.model({
  bonus : types.maybeNull(types.number),
  netSalary : types.maybeNull(types.number),
  basicSalary : types.maybeNull(types.number),
  netAllowance : types.maybeNull(types.number),
  netDeduction : types.maybeNull(types.number),
  incrementValue : types.maybeNull(types.number),
  incrementedDate : types.maybeNull(types.string),
  salaryStartDate: types.maybeNull(types.string),
})

export const employeeSalary= types.model({
  id: types.maybeNull(types.string),
  payroll: types.maybeNull(types.string),
  editedBy: types.maybeNull(types.number),
  history: types.maybeNull(salaryHistory)
})

export const employeeHistoryModel = types.model({
  assets:types.maybeNull(types.array(employeeAssets)),
  attendance:types.maybeNull(types.array(employeeAttendance)),
  loan:types.maybeNull(types.array(employeeLoan)),
  salary:types.maybeNull(types.array(employeeSalary)),
  basicInfo: types.maybeNull(basicInfo)
})

export const employeeHistory = types
  .model({
    employeeInfo: types.maybeNull(employeeData),
    assetsInfo: types.maybeNull(types.array(assetInfo)),
    newemployeeHistory: types.maybeNull(employeeHistoryModel),
    loading: types.optional(types.boolean, false),
    erorMessage: types.maybeNull(types.string),
  })
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get getEmployeeBasicData() {
      return toJS(self.employeeInfo)
    },
    get getEmployeeAssetHistoryData() {
      return toJS(self.assetsInfo)
    },
    get employeeHistoryData() {
      return toJS(self.newemployeeHistory)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const loadEmployeeHistory = flow(function* (id) {
      self.newemployeeHistory = cast({})
      self.loading = true
      try {
        const res = yield employeeHistoryApi.getEmployeeHistoryById(id)                
        if (res) {
          self.newemployeeHistory = toJS(res)
        }
        return toJS(res.results)
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadEmployeeHistory,
    }
  })

export function initEmployeeHistory() {
  return employeeHistory.create({})
}
