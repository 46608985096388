import React from 'react'
import { Modal } from 'antd'
import style from './style.module.scss'

const PermissionPopUp = props => {
  return (
    <div>
      <Modal
        className={style.permissionPopup}
        title={props.msgTitle}
        visible={props.visibleDelete}
        onOk={props.delete}
        confirmLoading={props.isLoading}
        okText={props.okText}
        onCancel={props.handleCancel}
        destroyOnClose={true}
      >
        <div className={style.modalText}>
          <p>
            <span className={style.fontBold}> {props.msg} </span> ?
          </p>
        </div>
      </Modal>
    </div>
  )
}
export default PermissionPopUp
