import { toJS } from 'mobx'
import { flow, types } from 'mobx-state-tree'
import { forgotPasswordApi } from '../../api'
import { notification } from '@utils/notifications'

export const emailModal = types
  .model({
    email: types.maybeNull(types.array(types.string)),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get getEmail() {
      return toJS(self.email)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setEmail = flow(function* (data) {
      try {
        self.loading = true
        yield forgotPasswordApi.setEmail(data)
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.detail || 'Failed to Load Profile Projects')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setEmail,
    }
  })

export function initEmail() {
  return emailModal.create({})
}
