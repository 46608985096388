import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { cast, flow, types } from 'mobx-state-tree'
import { eCanteenMealMenu } from '../../api'

export const ecanteenmealmenu = types.model({
  id: types.maybeNull(types.number),
  itemName: types.maybeNull(types.string),
  quantity: types.maybeNull(types.number),
  price: types.maybeNull(types.number),
  location: types.maybeNull(types.string),

})

export const eCanteenMenuModal = types
  .model({
    data: types.maybeNull(types.array(ecanteenmealmenu)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextDes: types.optional(types.number, 1),
  })
  .views(self => ({
    get geteCanteenmenu() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const seteCanteenmenu = flow(function* (data) {
      try {
        self.loading = true
        yield eCanteenMealMenu.seteCanteenmenu(data)
        notification.success('Canteen Meal Menu Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadEcanteenmenu = flow(function* (page = 1, pageSize = 10, queryParam = '', resetList = false) {
      self.loading = true
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextDes = 1
        }
        const res = yield eCanteenMealMenu.geteCanteenmenu(page, pageSize, queryParam)
        if (!res?.isCancel) {
          self.data = cast([...toJS(self.data ?? []), ...res.results])
          self.count = res.count
          if (res.next) {
            self.nextDes = self.nextDes + 1
          } else {
            self.nextDes = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Canteen Meal Menu')
        } return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEcanteenmenu = flow(function* (id, data) {
      try {
        self.loading = true
        yield eCanteenMealMenu.updateEcanteenmenu(id, data)
        notification.success('Canteen Meal Menu Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEcanteenmenu = flow(function* (id) {
      try {
        self.loading = true
        yield eCanteenMealMenu.deleteEcanteenmenu(id)
        notification.success('Canteen Meal Menu Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      seteCanteenmenu,
      loadEcanteenmenu,
      deleteEcanteenmenu,
      updateEcanteenmenu,
    }
  })

export function initeCanteenMenuModal() {
  return eCanteenMenuModal.create({})
}
