import { LoadingOutlined } from '@ant-design/icons'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { permit } from '@utils/permission/permission'
import { observer } from 'mobx-react'
import { POST_METHOD} from 'constant/constant'
import { useStore } from '@stores/root-store'
import { useEffect, useState } from 'react'
import styled from 'styled-components';

interface SupervisorDropdownProps {
  setRefreshTable?: (value) => void;
  rowRecord?: any;
  initialValue?: string;
  placeholder?: string;
  clearMyData?: boolean;
  formRules?: any[];
  isForm?: boolean;
  recordId?: string;
  name?: string;
  setSelected?: any;
  onSupervisorChange?: (value) => void;
  currentData?: any;
  formLabel?: string;
  showSearch?: boolean;
  mode?: any;
  filterOption?: any;
  className?: any;
  selectCheck?: string;
  fetchData?: boolean;
  getPopupContainer?:any;
  callFrom?:any;
  onchange?: (value) => void;
  disable?: any;
  listHeight?: number;
}

const SupervisorSelect = observer((props: SupervisorDropdownProps) => {

  const [supervisor, setSupervisor]=useState(null)
  const {
    userInfo: { getRoles },
    employeeDetails:{ getsupervisorList,loadSupervisorList }
  } = useStore(null)

  useEffect(() => {    
    async function loadData() {
      await loadSupervisorList()
    }
      loadData()
  }, [])
  
const supervisorList = getsupervisorList?.map(value => ({ id: value.employeeId, fullName: value.fullName })) || [];
  
  return (
    <div >
        <DivWrapper>
          <DynamicSelect
            callFrom={props.callFrom}
            disable={!props.isForm &&permit(getRoles, 'lead', POST_METHOD)}
            getData={supervisorList}
            loadData={loadSupervisorList}
            nextPage={1}
            allowClear
            queryParam={'search'}
            valueKey1={'fullName'}
            recordId={props.recordId}
            value={ supervisor? supervisor :  supervisor == '' ?  null : props.initialValue }
            setSelected={value=>{props.onSupervisorChange && props.onSupervisorChange(value); setSupervisor(value) }}
            {...props}
          />
          {!props.isForm && props.recordId == props.currentData?.id && props.selectCheck == 'supervisor' && (
            <LoadingOutlined />
          )} 
        </DivWrapper>
    </div>
  )
})

export default SupervisorSelect

const DivWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .anticon-spin{
    width: 10%;
    height: min-content !important;
    margin-right: -23px !important;
}

.ant-select-disabled{
  .ant-select-selection-item{
    cursor: not-allowed;
  }
}
.ant-form-item{
  width: 100% !important;
}
`
