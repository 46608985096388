
import { SearchOutlined } from '@ant-design/icons'
import SearchFilter from '@commonComponents/SearchFilter'
import { CommonInput } from '@commonComponents/input'
import getAuthRoles from '@utils/common-functions'
import { Col, DatePicker, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import styled from 'styled-components'

interface AttendanceFormProps {
  setQueryParam: (data: object) => void;
  calledFrom: string;
}
export const TimesheetFilters = observer((props: AttendanceFormProps) => {
  const { leadOrAdmin } = getAuthRoles()
  return (
    <div className='row filter-row'>
      <SearchFilter
        {...props}
        format={'YYYY-MM-DD'}
        calledFrom={props.calledFrom}
        children={
          <div className='d-lg-flex w-100'>
            <Row gutter={24} className='rowDiv searchForm flex-grow-1'>
              {leadOrAdmin &&
                <ColPadding xs={24} md={8} lg={8}>
                  <div className='icon'>
                    <Form.Item name='full_name'>
                      <CommonInput
                        placeholder='Search by Name '
                        className='searchInput'
                        inputType='text'
                        prefix={<SearchOutlined />}
                      />
                    </Form.Item>
                  </div>
                </ColPadding>}
              <ColPadding xs={24} md={8} lg={8}>
                <Form.Item name='date'>
                  <DatePicker
                    format='YYYY-MM-DD'
                    getPopupContainer={trigger => trigger.parentElement}
                    className='form-control'
                    placeholder='Date'
                    allowClear={true}
                  />
                </Form.Item>
              </ColPadding>
            </Row>
          </div>} />
    </div>
  )
})
export default TimesheetFilters

const ColPadding = styled(Col)`
    @media only screen and (min-width:991px) {
   .form-control{
   margin-left: -15px !important;
    }
}
`