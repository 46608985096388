import { permit } from '@utils/permission/permission'
import Tooltip from '@commonComponents/CustomToolTip'
import { Option } from 'antd/lib/mentions'
import styled from 'styled-components'
import { STATUS, STATUS_TITLE, UPDATE_METHOD } from 'constant/constant'
import { Select } from 'antd'
import { MdEdit } from 'react-icons/md'
import {
  CheckCircleFilled,
  ClockCircleFilled,
  StopOutlined,
} from '@ant-design/icons'
  
const getColumns =(updateEmployeeStatus,setCurrentData,setIsUpdate,setVisible,getcompany)=>{
  return [
    {
      title: 'Employee',
      dataIndex: 'employeeName',
      render: (text, record) => (
        <Tooltip placement='topLeft' title={record.employeeName}>
          {record.employeeName ? record.employeeName : ''}
        </Tooltip>
      ),
    },
    {
      title: 'Loan Type',
      dataIndex: 'loanName',
      render:(text, record)=>(
        <Tooltip placement='topLeft' title={record.loanName}>
          {record.loanName ? record.loanName : ''}
        </Tooltip>
      )
    },
    {
      title: 'Loan Amount',
      dataIndex: 'loanAmount',
      render:(text, record)=>(
        <Tooltip placement='topLeft' title={record.loanAmount}>
          {record.loanAmount ? record.loanAmount : ''}
        </Tooltip>
      )
    },
    {
      title: 'apply Date',
      dataIndex: 'applyDate',
    },
    {
      title: 'Disbursmant Date',
      dataIndex: 'loanDisbursementDate',
    },
    {
      title: 'No of Installment',
      dataIndex: 'noOfInstalments',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render:(text, record)=>(
        
        <Tooltip placement='topLeft' title={record.reason} minLength={24}>
          <ReasonDiv>{record.reason ? record.reason : ''}</ReasonDiv>
        </Tooltip> 
      )
    },
    {
      title: <IconCenter>{STATUS_TITLE}</IconCenter>,
      dataIndex: STATUS,
      render: (_, row) => (
        <>
          {row.isClearance &&
          permit(getcompany, 'termination', UPDATE_METHOD) ? (
              <StyledSelectWrapper
                disabled={row.status === 'Approved'}
                value={row.status}
                placeholder='Select Status'
                onChange={status => updateEmployeeStatus(row, status)}
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option value='Approved'>
                  <div className='d-flex align-items-center'>
                    <div className='approvedIcon'>
                      <CheckCircleFilled/>
                    </div>
                    <span style={{ marginLeft: '0px' }}>Approved</span>
                  </div>
                </Option>
                <Option value='Pending_Approval'>
                  <div className='d-flex align-items-center'>
                    <div className='pendingIcon'>
                      <ClockCircleFilled />
                    </div>
                    <span style={{ marginLeft: '0px' }}>Pending</span>
                  </div>
                </Option>
                <Option value='Rejected'>
                  <div className='d-flex align-items-center'>
                    <div className='rejectedIcon'>
                      <StopOutlined />
                    </div>
                    <span style={{ marginLeft: '0px' }}>Rejected</span>
                  </div>
                </Option>
              </StyledSelectWrapper>
            ) : (
             
              <div className='d-flex align-items-center justify-content-center'>
                <div className='pendingIcon'>
                  {row.status == 'Approved' ? (
                    <CheckCircleFilled style={{ color: '#50D61E' }} />
                  ) : row.status == 'Rejected' ? (
                    <StopOutlined style={{ color: '#EF0F0F' }} />
                  ) : (
                    <ClockCircleFilled />
                  )}
                </div>
                <span style={{ marginLeft: '0px' }}>
                  {row.status == 'Approved'
                    ? 'Approved'
                    : row.status == 'Rejected'
                      ? 'Rejected'
                      : 'Pending'}
                </span>
              </div>
              
            )}
        </>
      ),
    },
    {
      title:  <IconCenter>Action</IconCenter>,
      render: data => (
        <>
          <IconCenter>
            <StyledActionIconWrapper>
              <Tooltip title='Update Loan' minLength={0}>
                <MdEdit onClick={() => 
                {
                  
                  setCurrentData(data)
                  setIsUpdate(true)
                  setVisible(true)
                }} />
              </Tooltip>
            </StyledActionIconWrapper>
          </IconCenter>
        </>
      ),
    },
  ]
}

export default getColumns;

const StyledSelectWrapper = styled(Select)`
  min-width: 136px;
  .ant-select-selector {
    height: 40px !important;
    border-radius: 6px !important;
    .ant-select-selection-item {
      display: flex !important;
      line-height: 27px !important;
    }
  }
`
const StyledActionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    &:hover {
      background-color: #dad8d8;
      cursor: pointer;
    }
    svg {
      width: 20px;
      height: 20px;
    }
`
const IconCenter = styled.div`
    display: flex;
    justify-content: center;
`
const ReasonDiv = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`