import moment from 'moment/moment'
import present from '@assets/img/tick.svg'
import absent from '@assets/img/cross.svg'
import leave from '@assets/img/leavestar.svg'
import halfLeaveAbsent from '@assets/img/leaveAbsent.svg'
import halfLeavePresent from '@assets/img/leavePresent.svg'
import remoteLeave from '@assets/img/remoteWork.svg'
import sat from '@assets/img/saturday.svg'
import sund from '@assets/img/sunday.svg'
import React from 'react'
import {
  ATTENDENCE_FILTER_PARAMS,
  FORMAT,
  HOURS,
  BEGIN_RECESS,
  COMMENCE_PRAYER,
} from 'constant/constant'
import { timeFormat } from '@utils/common-functions'

const showTitle = (date, day) => (
  <div
    className=' d-flex justify-content-center'
    style={{ textAlign: 'center' }}
  >
    <div>
      <p>{date}</p>
      <p>{day}</p>
    </div>
  </div>
)

const getAllDaysInMonth = (year, month) => {
  const date = new Date(year, month, 1)
  const dates = []

  while (date.getMonth() === month) {
    dates.push(moment(new Date(date)).format('YYYY-MM-DD'))
    date.setDate(date.getDate() + 1)
  }

  return dates
}
export const convertDurationToSeconds = (timeDuration: string): number => {
  const duration = moment.duration(timeDuration)
  const seconds = Math.floor(duration.asSeconds())
  return seconds
}

export const getProductiveTime = getPunchData => {
  const totalFreeTime  = addTimes(getPunchData?.prayerTime, getPunchData?.recessTime)
  const checkInMoment  = timeFormat(getPunchData?.firstCheckIn)
  if (!checkInMoment.isValid()) {
    console.error('Invalid firstCheckIn date');
    return 'Invalid date';
  }
  const timeCheckedIn = checkInMoment.add(5, 'hours').format(FORMAT);
  const timeDifference  =  moment().subtract(timeCheckedIn)
  const totalProducetiveTime = timeDifference.subtract(totalFreeTime).format(FORMAT)
  return totalProducetiveTime;

}

function addTimes(time1, time2) {
  const duration1 = moment.duration(time1);
  const duration2 = moment.duration(time2);

  const totalDuration = duration1.add(duration2);
  const formattedTotalDuration = moment.utc(totalDuration.asMilliseconds()).format('HH:mm:ss');

  return formattedTotalDuration;
}



export const getPrayerTime = getPunchData => {
  // console.log(getPunchData?.status,BEGIN_RECESS)
  if (getPunchData?.status === COMMENCE_PRAYER) {

    const lastPrayerIn = timeFormat(getPunchData?.lastPrayerIn)

    const difference = moment
      .utc(moment().diff(lastPrayerIn))
      .subtract(5, HOURS)
    

    // console.log(difference.format(FORMAT),"this is the difference")
    // console.log("prayer time function")
  
    const result = difference.add(getPunchData?.prayerTime).format(FORMAT)
    return result
  }

  return getPunchData?.prayerTime
}

export const getLunchTime = getPunchData => {
  if (getPunchData?.status === BEGIN_RECESS) {
    const lastRecessIn = timeFormat(getPunchData?.lastRecessIn)
    
    const difference = moment
      .utc(moment().diff(lastRecessIn))
      .subtract(5, HOURS)

          

    // console.log(currentTime.format(FORMAT),"this is the current time",lastRecessIn.format(FORMAT),"this is the last recess in",difference.format(FORMAT),"this is the difference")
    // console.log("lunch time function")


    const result = difference.add(getPunchData?.recessTime).format(FORMAT)
    return result
  }
  return getPunchData?.recessTime
}

export const showColumns = (
  queryParam = ATTENDENCE_FILTER_PARAMS,
  showDrawer: (record, date?: any) => void,
  CustomHalfLeaveAbsentIcon,
  CustomHalfLeavePresentIcon
) => {
  const now = new Date()
  // the queryParam.month is in this format:- 2024-01
  const month = queryParam?.month
    ? parseInt(queryParam.month.slice(5)) - 1
    : now.getMonth()
  const year = queryParam?.month
    ? parseInt(queryParam.month.slice(0, 4))
    : now.getFullYear()

  const dates = getAllDaysInMonth(year, month)

  return dates.map(date => {
    const dateFormat = moment(date).format('DD-MM-YYYY')
    const someDate = moment(dateFormat, 'DD-MM-YYYY')
    const dayName = someDate.format('ddd')
    const day = dateFormat.split('-')[0]
    const showStatus = {
      P: { text: '', icon: present, style: 'present', type: 'present' },
      A: { text: '', icon: absent, style: 'absent', type: 'absent' },
      L: { text: '', icon: leave, style: 'absent', type: 'leave' },
      AL: {
        text: '',
        icon: halfLeaveAbsent,
        style: 'halfLeaveAbsent',
        type: 'halfLeaveAbsent',
      },
      PL: {
        text: '',
        icon: halfLeavePresent,
        style: 'halfLeavePresent',
        type: 'halfLeavePresent',
      },
      RL: {
        text: '',
        icon: remoteLeave,
        style: 'remoteLeave',
        type: 'remoteLeave',
      },
    }

    return {
      title: showTitle(dayName, day),
      dataIndex: date,
      render: (_, row) => {
        const columnsData = row?.attendanceList
          ?.slice()
          .reverse()
          .find(data => data?.date == date)
        const status = columnsData?.status || ''

        return (
          <div>
            {status != 'W' && status.length ? (
              <>
                {status === 'PL' ? (
                  <div
                    className='d-flex justify-content-center'
                    style={{ cursor: 'pointer' }}
                    onClick={() => showDrawer(row, columnsData.date)}
                  >
                    <CustomHalfLeavePresentIcon />
                  </div>
                ) : status === 'AL' ? (
                  <div className='d-flex justify-content-center'>
                    <CustomHalfLeaveAbsentIcon />
                  </div>
                ) : status === 'RL' ? (
                  <div
                    className='d-flex justify-content-center'
                    style={{ cursor: 'pointer' }}
                    onClick={() => showDrawer(row, columnsData.date)}
                  >
                    <img
                      className={showStatus[status]?.style}
                      src={showStatus[status]?.icon}
                      style={{
                        cursor:
                          showStatus[status]?.type === 'remoteLeave'
                            ? 'pointer'
                            : 'auto',
                        width: '25px',
                        aspectRatio: '1',
                      }}
                    />
                  </div>
                ) : (
                  <img
                    className={showStatus[status]?.style}
                    src={showStatus[status]?.icon}
                    style={{
                      cursor:
                        showStatus[status]?.type === 'present'
                          ? 'pointer'
                          : 'auto',
                    }}
                    onClick={() => {
                      showStatus[status].style === 'present' &&
                        showDrawer(row, columnsData.date)
                    }}
                  />
                )}
                <div>{showStatus[status]?.text}</div>
              </>
            ) : dayName === 'Sat' ? (
              <>
                <img className='half' src={sat} />
              </>
            ) : dayName === 'Sun' ? (
              <>
                <img className='half' src={sund} />
              </>
            ) : (
              ''
            )}
          </div>
        )
      },
    }
  })
}
