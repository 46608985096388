import { headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class ReportsApi extends BaseApi {
  setReportsDetails = async () => {
    try {
      const response = await this.axios.get(`${baseUrl}reports/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateReportDetails = async data => {
    try {
      const response = await this.axios.get(`${baseUrl}reports/?${data}`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ReportsApi
