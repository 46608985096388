import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Checkbox, Col, DatePicker, Form, Row } from 'antd'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import moment from 'moment'
import { formatDateValue, isCurrentUsersId } from '@utils/common-functions'
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader'
import { MdAddCircleOutline, MdClose } from 'react-icons/md'
import CustomButton from '@commonComponents/button'
import { ISO_DATE_FORMAT } from '@mainPage/constant'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { colors } from 'constant/colors'

const { black, white, lightRed, darkRed4, lightGray, TealishBlue } = colors


interface EducationData {
  institution: string;
  degree: string;
  starting_date: string;
  complete_date?: string;
  subject?: string;
  grade?: number;
  isContinue?: boolean;

}

interface EducationInfo {
  id: string;
  institution: string;
  degree: string;
  startingDate: string;
  completeDate?: string;
  subject?: string;
  grade?: string;
  isContinue?: boolean;
}

interface Education {
  empId?: string;
  closeDrawer: () => void;
}

interface EducationForm {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: any;
  formId?: string;
  data?: EducationInfo;
  method: string;
  closeDrawer: () => void;
  empId: string;
}

const Education = observer(({ empId, closeDrawer }: Education) => {
  const [showForm, setShowForm] = useState(false)
  const getRef = useRef(null)
  const canModify = isCurrentUsersId(empId)

  const {
    educationDetails: {
      deleteEducationDetails,
      educationData,
      loadEducationDetails,
      updateEducationDetails,
      setEducationDetails,
      isLoading,
    },
    themesModel: { getColorsData },
  } = useStore(null)
  const isDarkTheme = getColorsData?.theme === 'dark'

  const addForm = () => {
    setShowForm(true)
    setTimeout(() => {
      getRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      })
    }, 500)
  }

  const load = async () => {
    await loadEducationDetails(empId)
  }

  useEffect(() => {
    if (!educationData?.length) {
      load()
    }
  }, [empId])

  useEffect(() => {
    if (!educationData?.length && !showForm) {
      setShowForm(true)
    }
  }, [educationData, showForm])

  const onUpdate = async (data: EducationData, id: string) => {
    formatDateValue(data, ISO_DATE_FORMAT)
    for (const key in data) {
      if (data[key] === '') {
        delete data[key]
      }
    }
    if (data?.isContinue) {
      delete data['complete_date']
    }
    const res = await updateEducationDetails(id, data)
    if (res?.success) {
      load()
    }
  }

  const onCreate = async (data: EducationData) => {
    formatDateValue(data, ISO_DATE_FORMAT)
    for (const key in data) {
      if (data[key] === '') {
        delete data[key]
      }
    }
    data['employee_id'] = empId
    if (data?.isContinue) {
      delete data['complete_date']
    }
    const res = await setEducationDetails(data)
    if (res?.success) {
      setShowForm(false)
      load()
    }
  }

  const deleteEducation = async (id: string) => {
    const res = await deleteEducationDetails(id)
    if (res?.success) {
      load()
    }
  }

  if (!educationData?.length && isLoading) {
    return <SkeletonRingLoader width='100%' height='50px' align='center' />
  }

  return (
    <Wrapper ref={getRef} isDarkTheme={isDarkTheme}>
      <div className={'addMore'}>
        <AddButton onClick={addForm} isDarkTheme={isDarkTheme} disabled={!canModify}>
          <MdAddCircleOutline
            fill={getColorsData?.bodyColor || 'blue'}
            className='add-icon'
          />
        </AddButton>
      </div>

      {/* Mapping over fetched education details to render a form for each one to update it */}
      {educationData?.map((data, index) => (
        <Fragment key={data.id}>
          <div
            className='d-flex align-items-center mb-1 mt-3 details'
            style={{ gap: '16px' }}
          >
            <h5 className='titleName mt-2' style={{ whiteSpace: 'nowrap' }}>
              Education Detail {index + 1}
            </h5>
            <div className='separator' />
            <button
              type='button'
              className='deleteBtn d-flex align-items-center'
              onClick={() => deleteEducation(data?.id)}
              disabled={!canModify}
            >
              <MdClose className='deleteIcon' />
            </button>
          </div>
          <EducationForm
            data={data}
            onSubmit={formData => onUpdate(formData, data?.id)}
            method='Update'
            closeDrawer={closeDrawer}
            empId={empId}
          />
        </Fragment>
      ))}

      {showForm && (
        <>
          <div
            className='d-flex align-items-center mb-1 mt-3 details'
            style={{ gap: '16px' }}
          >
            <h5 className='titleName mt-2' style={{ whiteSpace: 'nowrap' }}>
              Education Detail {(educationData?.length || 0) + 1}
            </h5>
            <div className='separator' />
            <button
              type='button'
              className='deleteBtn d-flex align-items-center'
              onClick={() => setShowForm(false)}
              disabled={!canModify}
            >
              <MdClose className='deleteIcon' />
            </button>
          </div>
          <EducationForm
            formId='add-bank-detail'
            onSubmit={onCreate}
            method='Add'
            closeDrawer={closeDrawer}
            empId={empId}
          />
        </>
      )}
    </Wrapper>
  )
})

export default Education

const EducationForm = observer(
  ({ data, formId, onSubmit, method, closeDrawer, empId }: EducationForm) => {
    const [checked, setChecked] = useState(false)
    const [form] = Form.useForm()
    const {
      themesModel: { getColorsData },
    } = useStore(null)
    const buttonColor = getColorsData?.bodyColor
    const canModify = isCurrentUsersId(empId)

    const setFieldValues = () => {
      form.setFieldsValue({
        institution: data?.institution || '',
        subject: data?.subject || '',
        starting_date: data?.startingDate ? moment(data?.startingDate) : '',
        complete_date: data?.completeDate ? moment(data?.completeDate) : '',
        degree: data?.degree || '',
        grade: data?.grade || '',
        isContinue: data?.isContinue || false,
      })
      setChecked(data?.isContinue || false)
    }

    useEffect(() => {
      setFieldValues()
    }, [data])
    const handlePresentCheckboxChange = e => {
      const isChecked = e.target.checked;
      setChecked(isChecked);
      if (isChecked) {
        // If "Present" is checked, remove validation rule for "Complete Date"
        form.setFields([
          {
            name: ['complete_date'],
            errors: [],
          },
        ]);
      } else {
        // If "Present" is unchecked, add validation rule back for "Complete Date"
        form.validateFields(['complete_date']);
      }
    };
    return (
      <Form
        id={data?.id || formId}
        layout='vertical'
        onFinish={onSubmit}
        form={form}
        disabled={!canModify}
      >
        <Labelalign gutter={24}>
        <Col xs={24} sm={12}>
  <Form.Item
    name={['starting_date']}
    label={<span>Starting Date<span style={{ color: 'red' }}>*</span></span>}
    style={{
      position: 'relative',
    }}
   
    
    rules={[
      ({getFieldValue}) => ({
        validator(_, value) {
          if (!value) {
            validateRequired('Start date')
          }
          const endDate = getFieldValue('complete_date');
          const isAfter = value.isAfter(endDate);
          if (isAfter) {
            return Promise.reject('Start Date can\'t be greater than Completion Date');
          }
          return Promise.resolve();
        },
        
      }),
      validateNoWhiteSpace()
   
    ]}
  >
    <DatePicker
      format={ISO_DATE_FORMAT}
      getPopupContainer={trigger => trigger.parentElement}
      className='form-control'
    />
  </Form.Item>
</Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name={['complete_date']}
              label='Complete Date'
              style={{
                position: 'relative',
              }}
              rules={[
                {
                  required: !checked,
                  message: 'Completion Date is required',
                },
              ]}            >
              <DatePicker
                format={ISO_DATE_FORMAT}
                getPopupContainer={trigger => trigger.parentElement}
                className='form-control'
                disabled={!canModify || checked}
                suffixIcon={checked ? null : undefined} 

              />
            </Form.Item>
          </Col>
        </Labelalign>

        <Labelalign gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['institution']}
              label='Institution'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Institution Name'),
                validateNoWhiteSpace()
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['subject']}
              label='Subject'
              style={{
                position: 'relative',
              }}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
        </Labelalign>

        <Labelalign gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['degree']}
              label='Degree'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Degree'),
                validateNoWhiteSpace()
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['grade']}
              label='CGPA/Grade'
              style={{
                position: 'relative',
              }}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
        </Labelalign>

        <Labelalign gutter={24}>
          <Col xs={24} lg={12} className='d-flex align-items-end'>
            <Form.Item name={['isContinue']} valuePropName='checked'>
              <CustomCheckbox
                isDarkTheme={getColorsData?.theme === 'dark'}
                onChange={handlePresentCheckboxChange}

              >
                <p> Present</p>
              </CustomCheckbox>
            </Form.Item>
          </Col>
        </Labelalign>
        <Labelalign gutter={24}>
          <Aligncontent>
            <Styledbutton
              title='Cancel'
              htmlType='button'
              size='small'
              type='primary'
              onClick={closeDrawer}
              style={{ backgroundColor: buttonColor }}
            />
            <Styledbutton
              form={data?.id || formId}
              title={`${method} Detail`}
              htmlType='submit'
              size='small'
              key='submit'
              type='primary'
              style={{ backgroundColor: buttonColor }}
              disabled={!canModify}
            />
          </Aligncontent>
        </Labelalign>
      </Form>
    )
  }
)

const AddButton = styled.button<{ isDarkTheme?: boolean }>`
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props?.isDarkTheme ? black : ''};

  .add-icon {
    width: 20px;
    height: 20px;
  }
`

const Labelalign = styled(Row)`
  padding: 0 16px 0 16px !important;
  .ant-form-item-label {
    padding: 0 3px 3px !important;
  }
`

const Aligncontent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
`

const Styledbutton = styled(CustomButton)`
  background-color: blue;
  border-radius: 5px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  min-width: 100px;
  font-weight: 500 !important;
  padding: 16px;

  &:hover {
    color: white !important;
    background-color: blue;
  }

  &:focus {
    background-color: blue;
  }
`

const Wrapper = styled.div<{ isDarkTheme?: boolean }>`
  padding: 12px 0;
  .addMore {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin: -23px 0 20px 0;
    z-index: 12;
    position: relative;
    img {
      cursor: pointer;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: ${TealishBlue};
      word-spacing: 0px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
  .separator {
    height: .5px;
    flex-grow: 1;
    background-color: ${props => props?.isDarkTheme ? white : black};
  }
  .details {
    padding: 0 16px 0 16px;
  }
  .titleName {
    color: ${props => props?.isDarkTheme ? white : black};
    white-space: nowrap;
  }
  .deleteBtn {
    outline: none;
    border: none;
    background-color: ${props => props?.isDarkTheme ? black : lightGray};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;

    :hover {
      background-color: ${lightRed};
    }
  }

  .deleteIcon {
    width: 18px;
    height: 18px;
    fill: ${darkRed4};
  }
`
const CustomCheckbox = styled(Checkbox)`
  color: ${props => props?.isDarkTheme ? white : black};
`