import { secretEncryptionKey } from './const'

const AUTH_TOKEN = 'token'
const AUTH_REFRESH_TOKEN = 'refresh_token';

export function getTokenFromCookies() {
  return localStorage.getItem(AUTH_TOKEN) || ''
}

export const getRefreshTokenFromCookies = () => {
  const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN)
  return decrypt(refreshToken)
}

export function setTokenIntoCookies(access_token: string, refresh_token?: string) {
  const encryptedAccessToken = encrypt(access_token);
  localStorage.setItem(AUTH_TOKEN, encryptedAccessToken);

  if (refresh_token) {
    const encryptedRefreshToken = encrypt(refresh_token);
    localStorage.setItem(AUTH_REFRESH_TOKEN, encryptedRefreshToken);
  }
}

export function flushTokenFromCookies() {
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(AUTH_REFRESH_TOKEN)
}

export function getAuthorizationHeader() {
  return `Bearer ${getTokenFromCookies()}`
}

export function headerAuthorization(instance) {
  return {
    headers: { Authorization: getAuthorizationHeader() },
    cancelToken: instance.cancelToken,
  }
}

export const encrypt = text => {

  let result = '';
  for (let i = 0; i < text.length; i++) {
    result += String.fromCharCode(text.charCodeAt(i) ^ secretEncryptionKey.charCodeAt(i % secretEncryptionKey.length));
  }
  return window.btoa(result);
};

// Simple XOR decryption function
export const decrypt = text => {

  const decodedText = window.atob(text);
  let result = '';
  for (let i = 0; i < decodedText.length; i++) {
    result += String.fromCharCode(decodedText.charCodeAt(i) ^ secretEncryptionKey.charCodeAt(i % secretEncryptionKey.length));
  }
  return result;
};