import { headerAuthorization } from '../common-utils'

import { onboardingHistoryUrl} from '../const'
import { BaseApi } from '../baseApi'

class OnBoardingHistoryApi extends BaseApi {

  getOnBoardingHistory = async (id:any) => {
    try {
      const url = `${onboardingHistoryUrl}${id}`
      const response = await this.axios.get(
        url,
        {
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }


}
export default OnBoardingHistoryApi
