
import { roleDefaultPermissions, rolePermissionUrl } from '../const'
import { BaseApi } from '../baseApi'

class EmployeeRoleApi extends BaseApi {
  setEmployeeRole = async data => {
    try {
      const response = await this.axios.post(rolePermissionUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError

    }
  }
  getEmployeeRole = async (num = 1, pageSize = 25) => {
    try {
      const response = await this.axios.get(
        rolePermissionUrl + `?page=${num}&page_size=${pageSize}`,
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeeDropdownRole = async (num = 1, pageSize = 25) => {
    try {
      const response = await this.axios.get(`${rolePermissionUrl}?page=${num}&page_size=${pageSize}`
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeeRole = async (id, data) => {
    try {
      const response = await this.axios.patch(`${rolePermissionUrl + id}/`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteEmployeeRole = async id => {
    try {
      const response = await this.axios.delete(`${rolePermissionUrl + id}/`)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getRoleDefaultPermissions = async () => {
    try {
      const response = await this.axios.get(roleDefaultPermissions)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeRoleApi
