import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl } from '../const/index'

class ExpenseDashboardApi extends BaseApi {

  getExpenseDashboard = async () => {
    try {
      const response = await this.axios.get(
        `${baseUrl}expense_dashboard/`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ExpenseDashboardApi