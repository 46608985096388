import React, { useState, useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import TextArea from 'antd/lib/input/TextArea'
import styled from 'styled-components'
import { FormItem } from '@mainPage/Designation/designationForm'
export interface DepartmentProps {
  update: boolean;
  data?: {
    id: number;
    title: string;
    branch: number;
    description: string;
  };
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setBtnLoader?: React.Dispatch<React.SetStateAction<boolean>>;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRefreshTable: any;
  refreshDeps?: () => Promise<void>;
}

const DepartmentForm = observer(({update, data, setVisible, setRefreshTable, setBtnLoader, refreshDeps,}: DepartmentProps) => {
  const [form] = Form.useForm()
  const [isMain, setIsmain] = useState(false)
  const { departmentModal: { setDepartmentData, updateDepartment },
    branchModel: { getBranchData, loadBranch, nextBranchPage },
    userInfo: { getcompany },
  } = useStore(null)
  useEffect(() => {
    //remove it in future
setIsmain(true)
  }, [])
    
  useEffect(()  => {
    const load = async () => {
      isMain && !getBranchData?.length &&  await loadBranch(1, 20, '', true)}
    load();
  }, [isMain])

  useEffect(() => {
    form.resetFields()
    if (update) {
      form.setFieldsValue({
        title: data ?.title, description: data ?.description,  branch: data?.branch || '',
      })
    }
  }, [data, update])



  const submitData = async values => {
    let response = null
    setBtnLoader(true)
    if (update) {
      isMain &&
          (response = await updateDepartment(data.id, {
            title: values ?.title,description: values ?.description, branch: data?.branch || '',
          }))
      response.success && setVisible(false)
    } else {
      response = await setDepartmentData({
        title: values?.title,
        isActive: true,
        branch: isMain ? values ?.branch : getcompany ?. details?.branch?.id,description: values ?.description,
      })
    }
    if (response?.success) {
      setRefreshTable(true)
      setVisible(false)
      form.resetFields()
      await loadBranch(1,20,'',true)
      if (refreshDeps !== undefined) {
        await refreshDeps()
      }
      
    }
    setBtnLoader(false)
  }
  return (
    <Form
      form={form}
      id='department-form'
      layout='vertical'
      onFinish={submitData}
    >
      <Row>
        <Col span={24}>
          <FormItem
            name='title'
            label='Title'
            rules={[{ required: true, message: 'Department title is required' },
              {
                whitespace: true,
                message: 'Can\'t be just whitespace',
              },
            ]}
          >
            <CommonInput
              testId='title'
              className='inputField'
              inputType='text'
              placeholder='Name of the Department'/>
          </FormItem>
        </Col>
      </Row>
      <Row>
  <Col span={24}>
    <FormItem>

    <DynamicSelect
      isForm={true}
      name={'branch'}
      formLabel={'Branch'}
      getData={getBranchData}
      loadData={loadBranch}
      queryParam={'branch'}
      nextPage={nextBranchPage}
      valueKey1={'title'}
      placeholder='Branch'
      clearMyData={true}
      fetchData={false}
      formRules={[
        {
          required: true,
          message: 'Branch name is required',
        },
      ]}
      
      />
      </FormItem>
  </Col>
</Row>

      <Row>
        <Col span={24} >
          <FormItem
            name='description'
            label='Description'
          >
            <TextAreaField testId='department-description'className='textArea' placeholder='add description about the department' />
          </FormItem>
        </Col>
      </Row>
    </Form>
  )
}
)

export default DepartmentForm


const TextAreaField = styled(TextArea)`
height:200px !important;
border: 1px solid #E2E2E2;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
border-radius: 8px;
`