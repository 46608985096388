import { headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class BoardProjectApi extends BaseApi {
  setBoardListDetails = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}board_list/`,
        {
          sprint: data.sprint,
          name: data.label,
          color:data.color
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getBoardListDetails = async sprintID => {
    try {
      const response = await this.axios.get(
        `${baseUrl}board_list/?sprint=${sprintID}`,
        headerAuthorization(this)
      )
      return response
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getBoardProjectDetails = async projectID => {
    try {
      const response = await this.axios.get(
        `${baseUrl}board/?project=${projectID}`,
        headerAuthorization(this)
      )
      return response
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  
  updateBoardListDetails = async data => {
    try {
      const response = await this.axios.patch(
        `${baseUrl}board_list/${data?.id}/`,
        {
          name: data.name,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteBoardListDetails = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}board_list/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default BoardProjectApi
