import { AlphaSpan } from '@mainPage/Ecanteen/eCanteen';
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export interface breadcrumbListProp {
  link?: string;
  name?: string;
}

export interface BreadCrumbsProps {
  className?: string;
  list?: breadcrumbListProp[];
  pageTitle?: string;
  children?: ReactNode;
  isAlpha?: boolean | false;
}

const BreadCrumbs = (props: BreadCrumbsProps) => {
  return (
    <div className='col'>
      <CustomH3 className='page-title'>{props.pageTitle} {props.isAlpha &&  <AlphaSpan>Alpha</AlphaSpan>}</CustomH3>
      <BreadCrumbsList className='breadcrumb'>
        {props.list?.map((element, index) => (
          <li
            className={`breadcrumb-item ${
              props.list.length === (index + 1) && 'active'
            }`}
            key={index}
          >
            <Linkwrapper to={element.link}>{element.name}</Linkwrapper>
          </li>
        ))}
      </BreadCrumbsList>
      {props.children}
    </div>
  )
}

export default BreadCrumbs

const Linkwrapper = styled(Link)<{ theme: string }>`
  color: black;
  font-size: 13px;
  padding: 2px;
  border-radius: 2px;
`

const CustomH3 = styled.h3`
  font-size: 23px !important;
`
const BreadCrumbsList = styled.ul`
  margin-bottom: 5px;
  letter-spacing: 0.5px;
  font-size: 13px;
`
