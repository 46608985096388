import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from 'axios'
import { camelCase, isArray, isObject, mapKeys, mapValues } from 'lodash'
import { notification } from '../utils/notifications'
import {
  decrypt,
  flushTokenFromCookies,
  getRefreshTokenFromCookies,
  setTokenIntoCookies,
} from './common-utils'
import { baseServiceUrl } from './const'
import { constRoute } from '@utils/route'

const tokenSources: {
  [key: string]: CancelTokenSource;
} = {}

export type ApiErrorType = {
  statusCode: number;
  errorCode: string;
  message: string;
}

async function onCancelRequest(config: AxiosRequestConfig) {
  // if cancelToken is defined, we will cancel previous request and create a new token for new request.
  if (config.cancelToken) {
    const key = config.url
    let tokenSource = tokenSources[key ?? 0]
    if (tokenSource && tokenSource?.cancel) {
      tokenSource?.cancel('Operation canceled due to new request')
    }
    tokenSource = axios.CancelToken.source()
    config.cancelToken = tokenSource.token
    tokenSources[key ?? 0] = tokenSource
  }

  return config
}

async function onError(
  error: AxiosError,
  axiosInstance: AxiosInstance,
  errorList?: ApiErrorType[]
) {
  let throwError = true
  // return valid/empty response and exit
  if (axios.isCancel(error)) {
    console.warn(error)
    return Promise.resolve({ data: { isCancel: true } })
  }
  // Common error handling
  const { response } = error
  if (!response) {
    notification.info('Something went wrong.')
  } else {
    // Any other error.
    const { status, data } = response
    if (errorList && errorList?.length) {
      const errorItem = errorList
        .filter(
          err => err.statusCode === status && err.errorCode === data.code
        )
        .pop()
      if (errorItem) {
        throwError = false
        notification.info(errorItem.message)
      }
    }
  }
  if (throwError) {
    throw error
  }
}

function keysToCamelCase<T>(obj: T): T {
  if (isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return obj.map(keysToCamelCase)
  }
  if (!isObject(obj)) {
    return obj
  }
  const fixedKeys = mapKeys(obj, (value, key) => camelCase(key))
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return mapValues(fixedKeys, keysToCamelCase)
}

// tslint:disable-next-line:no-any
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function transformResponse(data: any): any {
  return keysToCamelCase(data)
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function getDefaultTransformResponse(): any[] {
  const axiosDefault = axios.defaults.transformResponse
  if (axiosDefault == null) {
    return [transformResponse]
  }
  if (isArray(axiosDefault)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return axiosDefault?.concat(transformResponse)
  }
  return [axiosDefault, transformResponse]
}

const CAMEL_CASE_DEFAULT_CONFIG: AxiosRequestConfig = {
  transformResponse: getDefaultTransformResponse(),
}

export class BaseApi {
  axios: AxiosInstance;
  axiosWithoutAuth: AxiosInstance;
  cancelToken: CancelToken;

  constructor(baseURL?: string) {
    const sharedConfig = {
      ...CAMEL_CASE_DEFAULT_CONFIG,
      baseURL,
    };

    this.axios = axios.create(sharedConfig);
    this.axios.interceptors.response.use(this.handleResponseSuccess, this.handleResponseError);
    this.axios.interceptors.request.use(onCancelRequest);

    this.axiosWithoutAuth = axios.create(sharedConfig);
    this.cancelToken = axios.CancelToken.source().token;

    this.axios.interceptors.request.use(this.handleRequest, this.handleRequestError);
  }

  handleResponseSuccess = (res: AxiosResponse) => res;
  handleResponseError = async (err: AxiosError) => {
    const { config, response } = err;

    if (response && response.status === 401) {
      const errorData = response.data;
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      window.location.href = constRoute.login;

      if (errorData && errorData.code === 'token_not_valid') {
        try {
          const access = await this.refreshToken();
          config.headers['Authorization'] = 'Bearer ' + access;
          return axios(config);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(err);
  };

  handleRequest = async (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    const decryptedToken = decrypt(token);

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + decryptedToken;
    }

    // do not remove without consulting me
    config.headers['x-tenant'] = 'enigmatix'

    return config;
  };

  handleRequestError = (error: AxiosError) => onError(error, this.axios);

  handleLoginRoute = () => {
    flushTokenFromCookies()
    window.location.href = constRoute.login;
  }

  async refreshToken() {
    const refresh = getRefreshTokenFromCookies();

    if (!refresh) {
      return Promise.reject(new Error('No refresh token available'));
    }

    try {
      const { data } = await axios.post(`${baseServiceUrl}refresh-token/`, { refresh });

      if (data.access) {
        setTokenIntoCookies(data.access);
        return data.access;
      } else {
        return Promise.reject(new Error('No access token received'));
      }
    } catch (error) {
      this.handleLoginRoute();
      return Promise.reject(error);
    }
  }
}
