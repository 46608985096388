import { Wrapper } from '@commonComponents/theme/theme'
import Header from '@initialPage/Sidebar/header'
import Sidebar from '@initialPage/Sidebar/sidebar'
import { useStore } from '@stores/root-store'
import { Layout } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import classNames from 'classnames'
import withAuthentication from 'middleware/authentication'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import packageFile from '../../../../package.json'
import style from '../style.module.scss'
import { PublicLayout } from './MainLayout'
import WentWrong from '.././../../assets/img/WentWrong.png'
import { colors } from 'constant/colors'


const PrivateLayout: React.FC = withAuthentication(
  observer(() => {
    const [collapsed, setCollapsed] = useState(false)
    const { TealishBlue } = colors
    const [color, setColor] = useState({
      bodyColor: TealishBlue,
      headerColor: '',
      sidebarColor: '',
      theme: 'light',
    })
    const {
      themesModel: { getColorsData, loadColor },
    } = useStore(null)

    const location = useLocation()

    useEffect(() => {
      async function load() {
        await loadColor()
      }
      load()
    }, [])

    useEffect(() => {
      getColorsData && setColor(getColorsData)
    }, [getColorsData])

    function isColorDark(color) {
      const hex = color?.replace('#', '')
      const r = parseInt(hex.substr(0, 2), 16)
      const g = parseInt(hex.substr(2, 2), 16)
      const b = parseInt(hex.substr(4, 2), 16)
      const brightness = (r * 299 + g * 587 + b * 114) / 1000
      return brightness < 128
    }
 
    return (
      <Wrapper
        color={color}
        textColor={isColorDark(color?.bodyColor || '#5F5E5E')}
      >
        <Layout className={`h-100 ${style.layoutSetting}`}>
          <Sider
            className={
              !collapsed
                ? classNames(style.mobileHide, style.sidebarSetting)
                : classNames(style.mobileshow, style.sidebarSetting)
            }
            collapsible
            collapsed={collapsed}
          >
            <Sidebar collapsed={collapsed} />
          </Sider>
          <Layout>
            <Header
              setCollapsed={setCollapsed}
              isHeader={color?.headerColor}
              collapsed={collapsed}
              logo={isColorDark(color?.bodyColor || '#5F5E5E')}
            />
            <ErrorBoundary
              key={location.pathname}
              fallback={<SomethingWentWrong />}
            >
              <PublicLayout />
            </ErrorBoundary>
            <div
              className={`${style.versionDiv} versionDiv`}
              style={{ zIndex: '200' }}
            >
              Version: {packageFile.version}
            </div>
          </Layout>
        </Layout>
      </Wrapper>
    )
  })
)

export default PrivateLayout

const SomethingWentWrong = () => {
  return (
    <Error>
        <div className='container'>
        <img src = {WentWrong}/>
        <h1>Something Went Wrong!</h1>
        <p>Apologies for the inconvenience – we're currently experiencing technical issues. Our team is actively working to resolve the problem. Thank you for your patience!
</p>
        </div>
    </Error>
  )
}

const Error = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  text-align: center;

  img {
    max-width: 100%;
  }

  .container {
    width: 70%;
  }

  @media (max-width: 768px) {
    .container {
      width: 90%; 
    }
  }
`;
