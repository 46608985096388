import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeRemoteLeaveHistoryApi } from '@api'
import { notification } from '@utils/notifications'


export const employeeLeaveHistory = types.model({
  id: types.maybeNull(types.number),
  approvedBy: types.maybeNull(types.string),
  approvedByName: types.maybeNull(types.string),
  cancelNumberOfDays : types.maybeNull(types.number),
  employee: types.maybeNull(types.string),
  employeeleave: types.maybeNull(types.string),
  isCancelLeave: types.maybeNull(types.boolean),
  lead: types.maybeNull(types.string),
  numberOfDays: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
})

export const employeeRemoteLeaveHistoryModal = types
  .model({
    data: types.maybeNull(types.array(employeeLeaveHistory)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getEmployeeLeaveHistoryData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadEmployeeRemoteLeaveHistory = flow(function* (num = 1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeRemoteLeaveHistoryApi.getEmployeeRemoteLeaveHistory(num)
        self.data = cast(res.results)
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Leave History')
        }
      } finally {
        self.loading = false
      }
    })

    return {
      loadEmployeeRemoteLeaveHistory,
    }
  })

export function initEmployeeRemoteLeaveHistoryModal() {
  return employeeRemoteLeaveHistoryModal.create({})
}
