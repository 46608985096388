
import { experienceInfoUrl } from '../const'
import { BaseApi } from '../baseApi'

interface ExperienceData {
  company: string;
  position: string;
  address: string;
  present: boolean;
  from_date: string;
  to_date?: string;
  employee_id?: string;
}

class ExperienceApi extends BaseApi {
  setExperienceDetails = async (data: ExperienceData) => {
    try {
      const response = await this.axios.post(experienceInfoUrl, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getExperienceDetails = async (employee_id: string) => {
    try {
      const url = `${experienceInfoUrl}/${employee_id}`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateExperienceDetails = async (id: string, data: ExperienceData) => {
    try {
      const url =  `${experienceInfoUrl}/${id}`
      const response = await this.axios.put(url, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteExperienceDetails = async (id: string) => {
    try {
      const url = `${experienceInfoUrl}/${id}`
      const response = await this.axios.delete(url)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ExperienceApi
