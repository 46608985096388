import { colors } from 'constant/colors';
import styled from 'styled-components';

const { CyanBlue } = colors

// Attachment Parent Wrapper
export const StyledAttachmentWrapper = styled.div`
	width: '100%';
 	display: flex;
	gap: 10px;
	background-color: white;
  margin-top: 20px;
`;

//Attachment ParentWrapper childDiv basis on flexBasis
export const StyledChildDiv = styled.div<{ flexBasis: string }>`
  flex-basis: ${props => props.flexBasis};
  `;


//Attachment Left Image Wrapper
export const LeftImageWrpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  flex-direction: column;
`;
  
//Styled Left Image tag
export const StyledLeftImage = styled.img`
    width: 100%;
    object-fit: cover;
    flex-direction: column;
    /* border: 1px dashed #E2E2E2; */
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 10px;
 &:hover { 
    border: 1px solid ${CyanBlue}; 
    transform: scale(1.1);
    box-shadow: 0 0 5px ${CyanBlue};
  }
`


//Attachment Right Image Wrapper
export const RightImageWrapper = styled.div`
display: grid;
place-items: center;
  width: 100%;
  background: #FFFFFF;
border-radius: 10px;
`;

//Styled right Image tag
export const StyledRightImage = styled.img`
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
`;
