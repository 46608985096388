import { getAuthorizationHeader, headerAuthorization } from '../common-utils'

import { baseUrl,loanUrl } from '../const'
import { BaseApi } from '../baseApi'

class LoanApi extends BaseApi {
  setLoanDetails = async data => {
    try {
      const response = await this.axios.post(`${baseUrl}employee_loan/`, data, {
        headers: { 
          'content-type': 'multipart/form-data',
          Authorization: getAuthorizationHeader() 
        },
        cancelToken: this.cancelToken,
      })

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getLoanDetails = async (num ,pageSize, queryParam={}) => {
    try {
      const url = `${loanUrl}?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateLoanDetails = async (data, recordId) => {
    try {
      const response = await this.axios.patch(
        `${baseUrl}employee_loan/${recordId}/`,
        data,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteClientDetails = async (id: number) => {
    try {
      const response = await this.axios.delete(`${baseUrl}employee_loan/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteLoanDetails: any
}

export default LoanApi
