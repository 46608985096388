import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Table as AntdTable } from 'antd';
import styled from 'styled-components';
import { BaseApi } from '@api/baseApi';
import { AxiosResponse } from 'axios';
import { usePrevious } from '@utils/hooks/usePrevious';
import { getAuthorizationHeader } from '@api/common-utils';
import { Pagination } from '../pagination';
import { ColumnType, TableProps } from 'antd/lib/table';
import { SkeletonHorizontalLoaderGray } from '../skeleton/horizontal-loader';
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
interface Props extends TableProps<any> {
  dataUrl?: string;
  className?: string;
  responseDataParam?: string;
  responseCountParam?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  columns: ColumnType<any>[];
  refreshTable?: boolean;
  resetTable?: boolean;
  queryParam?: object;
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setResetTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalRecordRemaining?: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>;
  tableName?: any;
  dataComp?: any;
  calledFrom?: string;
  loaderPosition? : string;
}

interface TableResponse {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  items: any[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  responseDataParam: any[];
  responseCountParam: number;
  count?: number;
  total?: number;
  isCancel?: boolean;
  children: any[];
  length: any;
}

export const Table = forwardRef(({
  dataUrl,
  className,
  responseDataParam = '',
  setPageNumber,
  responseCountParam = '',
  refreshTable = false,
  columns,
  queryParam,
  resetTable,
  ...props }: Props, ref
) => {
  const defaultParams = {
    ...queryParam,
    page_size: 10,
    size: 10,
    page: 1
  };

  const sortedColumns = columns.find(e => e.defaultSortOrder);
  if (sortedColumns && sortedColumns['sortField']) {
    const sortOrder = sortedColumns.defaultSortOrder == 'descend' ? '-' : '';
    defaultParams['ordering'] = `${sortOrder}${sortedColumns['sortField']}`;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [dataSource, setDataSource] = useState<any>([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(1);
  const [params, setParams] = useState(defaultParams);
  const baseApi = useRef(new BaseApi());
  const prevDataUrl = usePrevious(dataUrl);
  const generateLoadingColumns = () => {
    return columns.map(column => {
      return {
        ...column,
        render: () => {
          return (
            <div style={{ width: '100%' }}>
              <SkeletonHorizontalLoaderGray lightLoader  height='40px' skeletonHeight='10px' align={props.loaderPosition} />
            </div>
          );
        },
      };
    });
  };

  const setPage = pageNumber => {
    setParams({ ...defaultParams, ...queryParam, page: pageNumber });
    if (setPageNumber)
      setPageNumber(pageNumber)
  }

  useEffect(() => {
    if (refreshTable) {
      getTableData();
      props.setRefreshTable(false)
    }
  }, [refreshTable])

  useEffect(() => {
    if (resetTable) {
      setPage(1)
      getTableData();
      props.setResetTable(false)
    }
  }, [resetTable])

  useEffect(() => {
    // setParams({ ...defaultParams, ...queryParam, page_size: params.page_size, size:params.page_size });
    setParams({ ...defaultParams, ...queryParam, size: params.page_size, page: 1 });

  }, [queryParam])

  const getTableData = async () => {
    setLoading(true);
    if (dataUrl) {
      try {
        const { data }: AxiosResponse<TableResponse> = await baseApi.current.axios.get(
          dataUrl,
          {
            params: dataUrl.includes('api/v1/resign/?employee=') ? null : { ...params },
            headers: { Authorization: await getAuthorizationHeader() },
            cancelToken: baseApi.current.cancelToken,
          });
        if (data.isCancel) {
          return false
        } else {
          if (dataUrl.endsWith('hierarchy/')) {
            setDataSource(responseDataParam ? data[responseDataParam] : data.children?.length ? data?.children : data?.length >= 1 ? data : [data]);
            setLoading(false);
          }
          else if (dataUrl?.includes('get_attendance_employee_hierarchy_list_month')) {
            const allData = data?.items?.map(item => item?.employeeInfo)
            setDataSource(responseDataParam ? data[responseDataParam] : allData);
            setTotal(responseCountParam ? data[responseCountParam] : data?.total ?? data?.count);
            props?.setTotalRecordRemaining && props?.setTotalRecordRemaining((responseDataParam ? data[responseDataParam] : data.items).length % params.page_size);
            setLoading(false);
          }
          else {
            setDataSource(responseDataParam ? data[responseDataParam] : data.items);
            setTotal(responseCountParam ? data[responseCountParam] : data?.total ?? data?.count);
            props?.setTotalRecordRemaining && props?.setTotalRecordRemaining((responseDataParam ? data[responseDataParam] : data.items).length % params.page_size);
            setLoading(false);
          }
        }
      } catch (err) {
        setLoading(false);
        setDataSource(dataSource)
      }
    }
    else {
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    if (setPageNumber)
      setPageNumber(page)
    setParams({ ...params, page, page_size: pageSize, size: pageSize });

  };

  const handleTableChange = () => {
    setParams({ ...params });
  };

  useEffect(() => {
    if (dataUrl && prevDataUrl !== dataUrl) {
      setParams({ ...params, page: 1 });
    }
    getTableData();
  }, [params, dataUrl]);

  useImperativeHandle(ref, () => ({
    reloadTable() {
      if (prevDataUrl !== dataUrl) {
        setParams({ ...params, page: 1 });
      }
      getTableData();
    },
    getResults() {
      if (dataSource?.length) {
        return dataSource;
      }
    },
  }));

  const initializedLoading = !props.dataSource ? (!dataSource || !dataSource?.[0] || Object.keys(dataSource?.[0])?.length === 0) : false

  return (
    <Wrapper className={className} isPagination={!dataSource?.length} dataLength={!props.tableName ? dataSource?.length : 10}
      isLength={props.dataComp && !Object.keys(props.dataComp ? props.dataComp : dataSource[0])?.length ? true : false} dataSource={dataSource}>
      <AntdTableStyled
        dataSource={dataSource}
        columns={(initializedLoading || loading) ? generateLoadingColumns() : columns}
        pagination={false}
        onChange={handleTableChange}
        scroll={{ x: 'auto' }}
        dataLength={!props.tableName ? dataSource?.length : 10}
        className={dataSource?.length < 12 && !props.tableName ? 'customTableHeight' : ''}
        {...props}
      >
      </AntdTableStyled>
      {total > 10 && !props.dataComp?.length && (
        <PaginationStyled
          onChange={handlePaginationChange}
          total={total}
          pageSize={params['page_size']}
          current={params['page']}
        />
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  /* background-color: black; */
  border-radius: 0px;
  overflow: hidden;
  .ant-pagination {
    display: ${props => (props.isPagination ? 'none' : 'visible')};
  }
  .ant-table {
    .absent,
    .present,
    .half {
      width: 24px;
      margin-left: 13px;
    }
    .absent,
    .present{
width:21px     }
  }
  .tableSupervisor{
    width:180px !important;
  }
  tbody .ant-table-cell {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    word-spacing: 0 !important;
    font-size: 14px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    padding: 10px 16px !important;
    overflow: unset !important;
    min-width: 180px;
    height:66px !important;
    &[colspan] {
      height: auto !important;
    }
    .ant-select .ant-select-selection-item {
      font-size: 14px !important;
    }
    a {
      font-family: 'Poppins' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      word-spacing: 0 !important;
      color: #525353 !important;
      font-size: 14px !important;
    }
    .actionsDiv {
      display: flex !important;
      align-items: center !important;
      margin-left: -10px;
      .actions svg,
      img {
        border-radius: 50% !important;
        height: 43px !important;
        padding: 4px !important;
      }
    }
    .ant-switch-checked {
      background-color: #525353;
    }
    .ant-switch-checked
    .ant-switch-handle{
      left: calc(100% - 15px) !important;
      width: 13px !important;
    height: 13px !important;
    }
    .ant-switch-handle{
      width: 13px !important;
    height: 13px !important;
    }
.ant-switch-checked
.ant-switch-inner{
  margin: 0 19px 0 7px !important;
}
.ant-switch{
  height: 17px !important;
    line-height: 20px !important;
}
    .ant-rate {
      display: flex !important;
    }
    p {
      font-size: 14px !important;
      margin-left: 8px ;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
    }
    .ant-avatar-circle {
      margin-right: 10px;
    }
  }
  .ant-table-wrapper {
    overflow-x: ${props => !props?.dataSource?.length ? 'hidden' : 'auto'} !important;

    .ant-table-content{
      overflow: ${props => props?.isLength ? 'hidden' : 'auto'}  // !important; use only if fixed size table content contain some vertical scroll issues
      }

   
  }
  
  .ant-table-thead > tr > th {
    display: ${props => !props.dataSource?.length ? 'none' : 'auto'} !important;
  }

  .ant-table-thead > tr > th {
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #404040 !important;
    text-transform: capitalize !important;
    width: fit-content !important;
    min-width: fit-content !important;
    max-width: 230px !important;
    line-height: 27px !important;
    &:last-child {
      width: 180px !important;
    }
  }
  .ant-table .ant-table-tbody tr:nth-child(even) {
    background-color: white;
  }
  .ant-table-container table > tbody > tr td:first-child {
    padding-left: 16px !important;
  }
`

const AntdTableStyled = styled(AntdTable)`
`

const PaginationStyled = styled(Pagination)`
  padding: 12px 25px;
  text-align: right;
  margin-top: 10px;
  border-top: 1px solid #e8e8e8 !important;

  .ant-pagination-item {
    min-width: 25px !important;
    height: 25px !important;
  }
  .ant-pagination-prev {
    width: 25px !important;
    height: 25px !important;
  }
  .ant-pagination-next {
    width: 25px !important;
    height: 25px !important;
  }
  .ant-pagination-disabled {
    min-width: 25px !important;
    height: 25px !important;
  }
  .ant-pagination-item-link span svg {
    display: flex;
    margin-bottom: 2px;
    align-items: center;
    justify-content: center;
  }
  .ant-pagination-prev {
    min-width: 25px !important;
    max-height: 25px !important;
  }
  .ant-pagination-next {
    min-width: 25px !important;
    max-height: 25px !important;
  }
  .ant-pagination-options {
    height: 25px !important;
  }
  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-right: 15px;
  }
`
