import { flow, types, cast } from 'mobx-state-tree'
import { resignationApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'


const resignation = types.model({
  id: types.maybeNull(types.string),
  designation: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  noticePeriod: types.maybeNull(types.number),
  resignationDate: types.maybeNull(types.string),
  appliedDate: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
})
export const resignationModel = types.model({
  data: types.maybeNull(types.array(resignation)),
  loading: types.optional(types.boolean, false),
  count: types.maybeNull(types.number),
  nextAss: types.optional(types.number, 1)
})
  .views(self => ({
    get getResignationData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setResignationData = flow(function* (data) {
      try {

        self.loading = true
        yield resignationApi.setResignation(data)
        notification.success('Resignation Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadResignation = flow(function* (page = 1, pageSize = 20, queryParam = '', resetList = false) {
      try {
        self.loading = true
        self.data = cast([])
        if (resetList) {
          self.data = cast([])
          self.nextAss = 1
        }
        const res = yield resignationApi.getResignation(page, pageSize, queryParam)
        self.data = res.items;
                
        if (!res?.isCancel) {
          self.data = cast([...toJS(self.data ?? []), ...res.items])
          if (res?.next) {
            self.nextAss = self.nextAss + 1
          } else {
            self.nextAss = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load resignation')
        } return { success: false }
      } finally {
        self.loading = false
      }
    })
    const updateResignation = flow(function* (data, id,employee_id) {
      self.loading = true
      try {
        yield resignationApi.updateResignation(data, id,employee_id)
        notification.success('Resignation Updated Successfully')
        return { success: true }
      }

      catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeResignation = flow(function* (data, id) {
      self.loading = true
      try {
        yield resignationApi.updateEmployeeResignation(data, id)
        notification.success('Resignation Updated Successfully')
        return { success: true }
      }

      catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })


    const deleteResignation = flow(function* (id,employee_id) {
      try {
        self.loading = true
        yield resignationApi.deleteResignation(id,employee_id)
        notification.success('Resignation Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setResignationData,
      loadResignation,
      updateResignation,
      deleteResignation,
      updateEmployeeResignation
    }
  })
export function initresignationModel() {
  return resignationModel.create({})
}