import { leaveTypeUrl } from '@api/const'
import Tooltip from '@commonComponents/CustomToolTip'
import CustomButton from '@commonComponents/button'
import DeletePopUp from '@commonComponents/delete-popup'
import CustomDrawer from '@commonComponents/drawer'
import { Table } from '@commonComponents/table-v2'
import { useStore } from '@stores/root-store'
import { permit } from '@utils/permission/permission'
import { Space } from 'antd'
import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import styled from 'styled-components'
import EmployeeLeaveTypeForm from '../EmployeeLeaveForm/employeeLeaveTypeForm'

const EmployeeLeaveSettings = observer(() => {
  const [currentData, setCurrentData] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [viewDetail, setViewDetail] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [queryParam] = useState({})
  const tableRef = useRef(null)
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const [resetTable, setResetTable] = useState(false)

  const {
    employeeLeaveTypeModal: {
      getEmployeeLeaveTypeData,
      loadEmployeeLeaveType,
      deleteEmployeeLeaveType,
      isLoading,
    },
    userInfo: { getcompany },
  } = useStore(null)

  useEffect(() => {
    async function loadData() {
      permit(getcompany, 'user', 'read') && await loadEmployeeLeaveType()
    }
    getEmployeeLeaveTypeData?.length && loadData()
  }, [])


  const onEdit = data => {
    setCurrentData(data)
    setIsUpdate(true)
    setVisible(true)
  }

  const onDelete = data => {
    setCurrentData({ id: data.id, name: data.leaveTypeName })
    setVisibleDelete(true)
  }

  const onAdd = () => {
    setCurrentData({})
    setIsUpdate(false)
    setVisible(true)
  }

  const deleteEmployeeLeaveHandler = async () => {
    const res = await deleteEmployeeLeaveType(currentData?.id)
    if (res.success) {
      setVisibleDelete(false)
      totalRecordRemaining == 1 ? setResetTable(true) : setRefreshTable(true)
    } else {
      setVisibleDelete(false)
    }
  }


  const columns = [
    {
      title: 'Leave Name',
      dataIndex: 'title',
      render: text => (
        <Tooltip placement='topLeft' title={text} minLength={48}>
          <p> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: 'Leave Type',
      dataIndex: 'isHalfLeave',
      render: text => (
        <p>
          {text ? 'Short Leave' : 'Full Leave'}
        </p>
      ),
    },
    {
      title: 'Leave Duration',
      dataIndex: 'availableDays',
      render: text => <p>{text}</p>,
    },
    {
      title: 'Actions',
      render: (text, row) => (
        <>
          <ActionDiv>
            <div>
              {/* <ShowOnPermission mod={LEAVE_TYPE_MOD} method={UPDATE_METHOD}> */}
                <Tooltip minLength={0} title='Edit'>
                  <IconWrapper className='actions'>
                    <MdEdit onClick={() => {
                      onEdit(row)
                      setViewDetail(false)
                    }} />
                  </IconWrapper>
                </Tooltip>
              {/* </ShowOnPermission> */}
            </div>
            <div className={'actions'}>
              {/* <ShowOnPermission mod={LEAVE_TYPE_MOD} method={DELETE_METHOD}> */}
                <Tooltip minLength={0} title='Delete'>
                  <IconWrapper className='actions'>
                    <MdDelete onClick={() => {
                      onDelete(row)
                      setViewDetail(false)
                    }} />
                  </IconWrapper>
                </Tooltip>
              {/* </ShowOnPermission> */}
            </div>
          </ActionDiv>
        </>
      ),
    }
  ]
  return (
    <>
      {/* Page Header */}
      <div className='page-header mb-0'>
        <div className='row align-items-center'>
            <div className='col-auto float-end ml-auto' style={{marginLeft: 'auto'}}>
              <AddBtn>
                <CustomButton
                  onClick={() => {
                    onAdd()
                    setViewDetail(false)
                  }}
                  title='Add Leave Type'
                />
              </AddBtn>
            </div>
        </div>
      </div>
      {/* /Page Header */}
      <div className='row'>
        <div className='col-md-12'>
          <div className='table-responsive'>
            <div className='tableWidth custom-Pagination'>
              <div>
                <Table
                  ref={tableRef}
                  dataUrl={leaveTypeUrl}
                  columns={columns}
                  refreshTable={refreshTable}
                  setRefreshTable={setRefreshTable}
                  queryParam={queryParam}
                  resetTable={resetTable}
                  setResetTable={setResetTable}
                  setTotalRecordRemaining={setTotalRecordRemaining}
                  loaderPosition={'center'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
      {/* Add EmployeeLeave Modal */}
      <CustomDrawer
        title={
          viewDetail
            ? 'Leave Types Details'
            : isUpdate
              ? 'Update Leave Type'
              : 'Add Leave Type'
        }
        visible={visible}
        className='addDrawer'
        setVisible={() => {
          setVisible(false)
        }}
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='employee-form'
              htmlType='submit'
              type='primary'
              title={isUpdate ? 'Update' : 'Save Leave Type'}
              className='addButton'
            />
          </Space>
        }
      >
        <EmployeeLeaveTypeForm
          update={isUpdate}
          data={currentData}
          viewDetail={viewDetail}
          setVisible={setVisible}
          setRefreshTable={setRefreshTable}
        />
      </CustomDrawer>
      {/* /Edit EmployeeLeave Modal */}
      {/* Delete EmployeeLeave Modal */}
      <DeletePopUp
        delete={deleteEmployeeLeaveHandler}
        msgTitle='Delete Leave Application'
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
        msg={currentData?.name}
      />
      {/* /Delete EmployeeLeave Modal */}
    </>
  )
})

export default EmployeeLeaveSettings


const AddBtn = styled.div`
  .ant-btn{
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left:auto;

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      word-spacing: 0px;
    }
  }
`
const ActionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const IconWrapper = styled.div`
border-radius: 32px;
width: 36px;
height: 36px;
display: grid;
place-items: center;
padding: 8px;

&:hover {
  background: rgba(45, 113, 216, 0.15);
  cursor: pointer;
}

svg {
  width: 20px;
  height: 20px;
}
`