import { Skeleton } from 'antd'
import React from 'react'
import style from './style.module.scss'

export interface SkeletonLoaderProps {
    width?: string | number;
    height?: string | number;
    loading?: boolean;
    active?: boolean;
    rows?: number;
    num?: number;
    className?: string;
}

const CustomSkeleton = ({
  width = '100%',
  height = '20px',
  rows = 0,
  num = 10,
  active= true,
  loading = false,
  className = style.customStyleContainer,

}: SkeletonLoaderProps) => {
  const loaderArr = []
  for (let i = 0; i < num; i++) {
    loaderArr.push(num)
  }
  return (
    <>
      {
        loaderArr?.length && loaderArr?.map(() => {
          return (
            <Skeleton
              active={active}
              loading={loading}
              title={{ width: width, style: { height: height } }}
              paragraph={{ rows: rows }}
              className={className}

            />
          )
        })
      }
    </>
  )
}

export default CustomSkeleton