import style from './style.module.scss'
import { CommonSelect } from '@commonComponents/select'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useStore } from '@stores/root-store'

interface checkDropdownProps {
  setRefreshTable?: (value) => void;
  rowRecord?: any;
  initialValue?: string;
  placeholder?: string;
  isForm?: boolean;
  onchange?: (value) => void;
  currentData?: any;
  allowClear?: boolean;
  clearMyData?: boolean;
  callFrom?: any;
  disabled?: boolean;
  listHeight?: number;
  dataFetch?: boolean;
}

const ShiftDropdown = observer((props: checkDropdownProps) => {
  const [shift, setShift] = useState<string | null>(null)
  const [data, setData] = useState<Array<{ key: string; value: string }>>([])
  const {
    employeeDetails: { getLeadShift },
  } = useStore(null)

  useEffect(() => {
    const getLeadShiftData = getLeadShift?.map(({ id, title }) => ({
      key: id,
      value: title,
    }))
    setData(getLeadShiftData)
  }, [getLeadShift])

  return (
    <div className={style.tableSelectheight}>
      <div className={`${style.selectTable} position-relative`}>
        <CommonSelect
          callFrom={props.callFrom}
          listHeight={120}
          placeholder={props.placeholder}
          allowClear={props?.clearMyData}
          size='large'
          value={shift ?? props.initialValue ?? props.placeholder}
          getPopupContainer={trigger => trigger.parentNode}
          showSearch
          filterOption={(input, option) =>
            option?.children
              ?.toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          data={data}
          onChange={(value: string) => {
            props.onchange(value)
            setShift(value)
          }}
          {...props}
        />
      </div>
    </div>
  )
})

export default ShiftDropdown
