
import { BaseApi } from '../baseApi'
import { designationUrl } from '../const/index'

class DesignationApi extends BaseApi {
  setDesignation = async data => {
    try {
      const response = await this.axios.post(designationUrl,data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getDesignation = async (num ,pageSize, queryParam={}) => {
    try {
      const url = `${designationUrl}?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  
  updateDesignation = async (id, data) => {
    try {
      const response = await this.axios.put(`${designationUrl+id}/`,data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteDesignation = async id => {
    try {this
      const response = await this.axios.delete(`${designationUrl+id}/`)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default DesignationApi
