import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Timeline, Form, Card } from 'antd'
import { assetshistoryApi } from '@api';
import { useRequest } from 'ahooks';
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader';
import { humanize } from '../../utils/string_formate';
import { IGNORE_PROPERTIES } from './const';
import { CommonInput } from '@commonComponents/input';
import styled from 'styled-components';


const showTimeLine = (key, value) => {
  return (
    <Timeline.Item color='green' label={humanize(key)}> {humanize(value)}</Timeline.Item>
  )
}


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const AssetsHistory = observer(({ historyData }): any => {
  const { loading, data } = useRequest(() => assetshistoryApi.getAssetsHistory(historyData.asset_id), {
    refreshDeps: [historyData]
  })

  if (loading) return (
    <SkeletonRingLoader />
  )
  const { history, assetDetails } = data ? data : { history: [], assetDetails: {} }

  return (
    <div>
      <div className='content container-fluid'>
        <Row gutter={22}>
          {Object.keys(assetDetails).map(asset => {
            if (IGNORE_PROPERTIES?.includes(asset)) return
            return (
              <Col xs={12}>
                <Form.Item name='name' style={{ marginBottom: '3px' }}
                  label={humanize(asset)}>
                </Form.Item>
                <SetPlaceholder className=' mb-3'>
                  <CommonInput placeholder={assetDetails[asset]}  ></CommonInput>
                </SetPlaceholder>
              </Col>
            )
          })}
        </Row>
        <Row className='mt-5'>
          <Col>
            <div >
              <h3 className='text-primary'>Time Line of Asset</h3>
            </div>
          </Col>
        </Row>
        <div className='mt-5'>
          <div>
            {history?.length ?
              <Timeline mode='left' >

                {history?.map(item => {
                  const updatedFields = item?.updatedFields || {}
                  const dateObj = new Date(item.updatedAt)
                  const formattedDate = dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
                  return (
                    <Timeline.Item color='#C9C9C9' style={{ right: '200px'}} label={<p >{formattedDate}</p>}>
                      <div >
                        <Card style={{     margin: '0', borderRadius: '8px'}}>

                          {item.status? <p style={{ fontWeight: 'bold' }}> {item.status} </p> : ''}
                          {item.name ? <p>Asset Name : {item.name}</p> : ''}
                          {item.approvedBy ? <p>Approved by : {item.approvedBy}</p> : ''}
                          {item.updatedByUser ? <p>Updated by : {item.updatedByUser}</p> : ''}
                          {item.assignedTo ? <p>Assign To : {item.assignedTo}</p> : ''}
                          {item.assignedBy ? <p>Assign by : {item.assignedBy}</p> : ''}
                          {item.purchaseFrom ? <p>Purchased From : {item.purchaseFrom}</p> : ''}
                          {item.purchaseDate ? <p>Purchase Date : {item.purchaseDate}</p> : ''}
                          {item.assetCategory ? <p>Asset Category : {item.assetCategory}</p> : ''}
                          {item.condition ? <p>Condition : {item.condition}</p> : ''}
                          {item.purchasedAmount ? <p>Purchased Amount : {item.purchasedAmount}</p> : ''}
                          {item.serialNumber ? <p>Serail Number : {item.serialNumber}</p> : ''}
                          {item.description ? <p> Description : {item.description}</p> : ''}
                          {item.assetAttributes ?  
                            <div>
                              <p style={{ fontWeight: 'bold' }}>Asset Attributes:</p>
                              {item.assetAttributes.map(item=>{
                                return <p>{item.name} : {item.value}</p>
                              })}
                            </div>
                            :''} 
                        </Card>
                      </div>
                      {Object.keys(updatedFields).map(field => (
                        showTimeLine(field, updatedFields[field])
                      ))}
                      <StyleBorder ></StyleBorder>
                    </Timeline.Item>


                  );
                })}
              </Timeline> : 'No History'}
          </div>

        </div>
      </div>
    </div>
  )
})

export default AssetsHistory;

const StyleBorder = styled.div`
  position: absolute;
   top: 11px;
   border-Bottom: 2px solid #C9C9C9;
   width: 15px;
   left: -17px;

  
`;

const SetPlaceholder = styled.div`
pointer-events: none;
`;