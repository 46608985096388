import React, { useState } from 'react'
import { Card, Switch } from 'antd'
import style from '@mainPage/rolePermission/style.module.scss'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import CommonTable from '@commonComponents/table'
import { toCapitalizedWords } from '@utils/common-functions'


function ShowAllowanceAccess({ valMenu, modules, changeData, data, columns }) {

  const ShowCards = ({ val }) => {
    const isChecked = !!modules?.filter(e => e?.api == val?.name)?.length
    const hasProperty = Object.prototype.hasOwnProperty.call(data, val.name)
    const [isOpen, setIsOpen] = useState(hasProperty)

    const cardHandler = event => {
      event.stopPropagation()
      changeData(!isOpen, { api: val.name, name: val.name, })
      setIsOpen(!isOpen)
    }

    return (
      <>
        <Card className={style.roleCard} onClick={cardHandler}>
          {hasProperty ? <MdOutlineKeyboardArrowUp size={20} /> : <MdOutlineKeyboardArrowDown size={20} />}
          <p>{toCapitalizedWords(val?.name)} </p>
          <div>
            <Switch
              checked={isChecked}
              onClick={cardHandler}
            />
          </div>
        </Card>
        {hasProperty && (
          <div className={style.roleTable}>
            <CommonTable
              rowClassName={style.tableColor}
              showHeader={false}
              dataSource={hasProperty ? [valMenu.find(obj => obj.name === val.name)] : []}
              columns={columns}
              rowKey={record => record.id}
            />
          </div>
        )}
      </>
    )
  }

  return (valMenu?.map(val => <ShowCards val={val} />))
}

export default ShowAllowanceAccess;