import { Card, Col, Row, Switch, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Title } = Typography

const notificationSettings = () => {
  return (
    <NotificationCard>
      <Row>
        <Col 
        >
          <Title level={4} className='text-start mb-2'>Notification Settings</Title>
          <p className='mb-2'>Select the kinds of notification you get about your activities and recommendations.</p>
        </Col>
        <Settings>
          <ContentOne>
            <Title level={5}>Email notification</Title>
            <p className='mb-2'>When your website login receive the notification for your email.</p>
          </ContentOne>
          <ContentTwo>
            <Switch /> 
          </ContentTwo>  
        </Settings>
        <Settings>
          <ContentOne>
            <Title level={5}>Sounds</Title>
            <p className='mb-2'>Play a sound when each new notification is received.</p>
          </ContentOne>
          <ContentTwo>
            <Switch /> 
          </ContentTwo>
        </Settings>
        <Settings>
          <ContentOne>
            <Title level={5}>Reminders</Title>
            <p className='mb-2'>These are notification to remind you of  updates you might have missed.</p>
          </ContentOne>
          <ContentTwo>
            <Switch />
          </ContentTwo>    
        </Settings>
      </Row>
    </NotificationCard>
  )
}

export default notificationSettings

const NotificationCard = styled(Card)`
  
  border-radius: 12px;
    .received {
      color: #34444c;
    }
    padding: 20px;
  margin-top: 30px;
  `
const Settings = styled.div`
      display: flex;
      width: 100%;
      margin: 10px;
      `

const ContentOne =styled.div`
      width: 90%;
      `
const ContentTwo =styled.div`
      align-self: center;
    `
