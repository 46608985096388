import { Button } from 'antd';
import { colors } from 'constant/colors';
import styled from 'styled-components';

const { TealishBlue } = colors

const projectHistory = (() => {
  const data = [
    {
      week: '1st week',
      color: '#16D61E',
      items: [
        { date: '12-03-23', amount: '$111,23' },
        { date: '13-03-23', amount: '$222,23' },
        { date: '14-03-23', amount: '$333,23' },
        { date: '15-03-23', amount: '$444,23' },
      ],
    },
    {
      week: '2nd week',
      color: '#EF0F0F',
      items: [
        { date: '12-03-23', amount: '$111,23' },
        { date: '13-03-23', amount: '$222,23' },
        { date: '14-03-23', amount: '$333,23' },
        { date: '15-03-23', amount: '$444,23' },
      ],
    },
    {
      week: '3rd week',
      color: TealishBlue,
      items: [
        { date: '12-03-23', amount: '$111,23' },
        { date: '13-03-23', amount: '$222,23' },
        { date: '14-03-23', amount: '$333,23' },
        { date: '15-03-23', amount: '$444,23' },
      ],
    },
    {
      week: '4th week',
      color: '#F1DB14',
      items: [
        { date: '12-03-23', amount: '$111,23' },
        { date: '13-03-23', amount: '$222,23' },
        { date: '14-03-23', amount: '$333,23' },
        { date: '15-03-23', amount: '$444,23' },
      ],
    },

  ];

  return (
    <StyledMainDiv>
      <StyleBtn className='d-flex justify-content-between'>
        <p>Default Account</p>
        <Button type='primary'>Current Month</Button>
      </StyleBtn>
      <div>
        {data.map(weekData => (
          <StyleTop key={weekData.week}>
            <p className='mt-2'>{weekData.week}</p>
            <div>
              {weekData.items.map((item, index) => (
                <StyleMain key={index}>
                  <div className='d-flex align-items-center  gap-2'>
                    <div style={{ background: weekData.color, width: '10px', height: '10px' }}></div>
                    <p>{item.date}</p>
                  </div>
                  <div>{item.amount}</div>
                </StyleMain>
              ))}
            </div>
          </StyleTop>
        ))}
      </div>
    </StyledMainDiv>

  )
})

export default projectHistory;


const StyleMain = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 2px solid #E3E3E3;
padding: 5px;

&.second-week{
    img{
        color: red !important;
    }
}
`

const StyleTop = styled.div`
    p{
        color: #52535399;
    }
`

const StyledMainDiv = styled.div`
    padding: 6px;
    margin-top: 10px
    `

const StyleBtn = styled.div`
    .ant-btn{
background-color: ${TealishBlue};
border-radius: 12px;
    }
`