import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import { Modal, Avatar } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { imgBaseUrl } from '@api/const'
import TaskForm from './taskForm'
import LabelForm from './labelForm'
import BreadCrumbs from '@commonComponents/breadcrums'

const TaskBoard = observer(() => {
  const { boardID, projectID } = useParams()
  const [boardListID] = useState(0)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [updateBoardIndex] = useState(null)
  const [id, setID] = useState(0)
  const [editTask, setEditTaskVisisbility] = useState(false)
  const [boardVisibility, setBoardVisibility] = useState(false)
  const [boardEditVisibility, setBoardEditVisibility] = useState(false)
  const [projectData, setProjectData] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)

  const {
    boardDetails: {
      loadBoardDetails,
      isLoading,
      deleteBoardListDetails,
    },
  } = useStore(null)

  const {
    projectsModel: { getProjectsData, loadProjects },
  } = useStore(null)

  useEffect(() => {
    setID(parseInt(boardID))
    async function load() {
      await loadBoardDetails(projectID)
      await loadProjects(1)
    }
    load()
  }, [])
  useEffect(() => {
    if (id === parseInt(boardID)) {
      setProjectData(
        getProjectsData?.filter(project => project.id === projectID)[0]
      )
    }
  }, [getProjectsData])

  const deleteBoardList = async () => {
    const res = await deleteBoardListDetails(boardListID)
    if (res.success) {
      setDeleteVisible(false)
    }
  }

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid position-absolute'>
        {/* Page Header */}
        <div className='page-header'>
          <BreadCrumbs
            list={[
              { name: 'Dashboard', link: constRoute.home },
              { name: 'Project', link: constRoute.projects },
              { name: 'Board', link: `${constRoute.boardList}/${projectID}` },
              {
                name: 'Task Board',
                link: `${constRoute.taskBoard}/${boardID}/${projectID}`,
              },
            ]}
            pageTitle={
              projectData && projectData['name'] ? projectData['name'] : ''
            }
            children={''}
          ></BreadCrumbs>
        </div>
        {/* /Page Header */}
        <div className='row board-view-header'>
          <div className='col-8'>
            <div className='pro-teams'>
              <div className='pro-team-lead'>
                <h4>Lead</h4>
                <div className='avatar-group'>
                  <div className='avatar d-flex w-100'>
                    {projectData &&
                      projectData['project_leader']?.map(leader => {
                        return (
                          <Avatar
                            size={29}
                            className='avatar-img rounded-circle border border-white'
                            alt='User Image'
                            src={imgBaseUrl + leader.employee_image}
                          />
                        )
                      })}
                  </div>
                </div>
              </div>
              <div className='pro-team-members'>
                <h4>Team</h4>
                <div className='avatar-group'>
                  <div className='avatar d-flex w-100'>
                    {projectData &&
                      projectData['project_team']?.map(team => {
                        return (
                          <Avatar
                            size={29}
                            className='avatar-img rounded-circle border border-white'
                            alt='User Image'
                            src={imgBaseUrl + team.employee_image}
                          />
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-4 text-end'>
            <a
              href='#'
              className='btn btn-white float-end ml-2'
              onClick={() => {
                setBoardEditVisibility(true)
                setIsUpdate(false)
                setBoardVisibility(true)
              }}
            >
              <i className='fa fa-plus' /> Create List
            </a>
            <a
              href='#'
              className='btn btn-white float-end ml-2'
              onClick={() => {
                setBoardEditVisibility(true)
                setIsUpdate(false)
                setBoardVisibility(false)
              }}
            >
              <i className='fa fa-plus' /> Create Labels
            </a>

            <Link
              to={`/app/projects/projects-view/${projectID}`}
              className='btn btn-white float-end'
              title='View Board'
            >
              <i className='fa fa-link' />
            </Link>
          </div>
          <div className='col-12'>
            <div className='pro-progress'>
              <div className='pro-progress-bar'>
                <h4>Progress</h4>
                <div className='progress'>
                  <div
                    className='progress-bar bg-success'
                    role='progressbar'
                    style={{ width: '20%' }}
                  />
                </div>
                <span>20%</span>
              </div>
            </div>
          </div>
        </div>
        <div className='kanban-board card mb-0'>
          <div className='card-body'>
            <div className='kanban-cont'>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}

      <Modal
        title='Board List'
        visible={boardEditVisibility}
        footer={[]}
        onCancel={() => setBoardEditVisibility(false)}
        destroyOnClose={true}
      >
        <LabelForm
          boardListIdex={boardListID}
          isUpdate={isUpdate}
          boardVisibility={boardVisibility}
          boardEditVisibility={setBoardEditVisibility}
        />
      </Modal>

      <Modal
        title='Task'
        visible={editTask}
        footer={[]}
        onCancel={() => setEditTaskVisisbility(false)}
        destroyOnClose={true}
      >
        <TaskForm
          isUpdate={isUpdate}
          bID={boardID}
          updateBoardIndex={updateBoardIndex}
          visibility={setEditTaskVisisbility}
        />
      </Modal>

      <Modal
        title={'Confirm Deletion'}
        visible={deleteVisible}
        onOk={deleteBoardList}
        onCancel={() => setDeleteVisible(false)}
        confirmLoading={isLoading}
        okText='Confirm'
        destroyOnClose={true}
      >
        <p>{'Are you sure to delete this Board List?'}</p>
      </Modal>
    </div>
  )
})

export default TaskBoard
