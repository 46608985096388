import { toJS } from 'mobx'
import { workStatsApi } from '@api'
import { flow, types } from 'mobx-state-tree'
import { notification } from '@utils/notifications'

const WorkStats = types.model({
  dates: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  hours: types.maybeNull(types.number),
})

export const WorkStatsModel = types
  .model({
    WorkStats: types.maybeNull(types.array(WorkStats)),
  })
  .views(self => ({
    get WorkStatsData() {
      return toJS(self.WorkStats)
    },
  }))
  .actions(self => {
    const loadWorkTimeStats = flow(function* () {
      try {
        const res = yield workStatsApi.getAttendanceWorkStats()
        self.WorkStats = res
      } catch (error) {
        const message = error?.response?.data?.message
        notification.error(message)
      }
    })
    return {
      loadWorkTimeStats,
    }
  })

export function initWorkStatsModel() {
  return WorkStatsModel.create({})
}
