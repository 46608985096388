
import { BaseApi } from '../baseApi'
import { confirmPasswordUrl } from '@api/const'

class ChangePasswordApi extends BaseApi {
  setPassword = async data => {
    try {
      await this.axios.post(confirmPasswordUrl, data)
      return true
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ChangePasswordApi
