import React, { useState, useRef, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { Table } from '@commonComponents/table-v2'
import { leaveCancelGetUrl } from '@api/const'
import EmployeeLeaveForm from '../EmployeeLeaveForm/employeeLeaveForm'
import CustomDrawer from '@commonComponents/drawer'
import {
  LEAVE_DETAILS, EDIT_LEAVE_APPLICATION, ADD_LEAVE_APPLICATION,
} from '../../const'
import styled from 'styled-components'
import { getLeaveApprovalColumns } from '@mainPage/EmployeeLeave/utils'
import EmployeeLeaveHistoryDrawer from '@mainPage/EmployeeLeave/employeeLeaveSideDrawer'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const EmployeeCancelLeaveApprovalTable = observer(({ refreshTable, setRefreshTable, queryParam, setResetTable ,resetTable }: any) => {
  const [currentData, setCurrentData] = useState(null)
  const [statusLoader, setStatusLoader] = useState(false)
  const [visible, setVisible] = useState(false)
  const [viewDetail] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [empLeaveData , setEmpLeaveData] = useState({})
  const [open, setOpen] = useState(false);

  const tableRef = useRef(null);

  const {
    employeeCancelLeaveApprovalModal: { updateEmployeeCancelLeaveApproval },
  } = useStore(null)

  const onApprove = async (id: string) => {
    setCurrentData({ id })
    setStatusLoader(true)
    const res = await updateEmployeeCancelLeaveApproval(id)
    if (res.success) {
      setRefreshTable(true)
      setStatusLoader(false)
    }
  }

  const showDrawer = data => {
    setEmpLeaveData(data)
    setOpen(true);
  };

  const columns = useMemo(() => getLeaveApprovalColumns(statusLoader , currentData , onApprove , false , true , showDrawer , 'CancelLeaveApproval',null,null,null),[refreshTable])

  return (
    <>
      <div className='tableWidth custom-Pagination'>
        <EmployeeCancelLeaveApprovalStyledTable
          ref={tableRef}
          dataUrl={leaveCancelGetUrl}
          columns={columns}
          refreshTable={refreshTable}
          queryParam={Object.keys(queryParam).length !== 0 && queryParam}
          setRefreshTable={setRefreshTable}
          resetTable={resetTable}
          setResetTable={setResetTable}
          loaderPosition={'center'}
        />
      </div>

      {/* Add EmployeeLeave Modal */}
      <CustomDrawer
        width={400}
        className='addDrawer'
        title={
          viewDetail
            ? LEAVE_DETAILS
            : isUpdate
              ? EDIT_LEAVE_APPLICATION
              : ADD_LEAVE_APPLICATION
        }
        visible={visible}
        setVisible={() => {
          setVisible(false)
          setIsUpdate(false)
          setCurrentData(null)
        }}
      >
        {visible && (
          <EmployeeLeaveForm
            update={isUpdate}
            data={currentData}
            viewDetail={viewDetail}
            setVisible={setVisible}
            leaveApproval={false}
            setRefreshTable={val => setRefreshTable(val)}
          />
        )}
      </CustomDrawer>
      <EmployeeLeaveHistoryDrawer
        open={open}
        onClose={() => setOpen(false)}
        empLeaveData={empLeaveData && empLeaveData}
        isCancel={true}
        onStatusChange={onApprove}
      />
    </>
  )
})

export default EmployeeCancelLeaveApprovalTable

const EmployeeCancelLeaveApprovalStyledTable = styled(Table)`
.ant-pagination-item a{
  justify-content:center !important;
}
.ant-select .ant-select-selector{
  justify-content: center;
    display: flex;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  justify-content: center;
    text-align: center;
    display: flex;
}
.ant-select .ant-select-selection-item {
  text-align: center;
  justify-content: center;
  display: flex !important;
}
`