import React, { useEffect, useState } from 'react'
import { Form, Col, Row} from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import CustomButton from '@commonComponents/button'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import style from './style.module.scss'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'

const ProjectsSearch = observer(props => {
  const [searchForm] = Form.useForm()
  const [projectName, setProjectName] = useState('')
  const [isFilterData, setIsFilterData] = useState(false)
  const [selected,setSelected] = useState([])
  const {
    clientDetails: { getClientInfo, loadClientInfo, nextCli },
  } = useStore(null)

  useEffect(() => {
    async function loadData() {
      if (!getClientInfo?.length) {
        await loadClientInfo(1, 20, '', true)
      }
    }
    loadData()
  }, [])

  const submitFilterData = async values => {
    if (
      !values.name &&
      !selected
    ) {
      return
    } else {
      props.setQueryParam({
        project_name: values.name || '',
        client_name: selected[0]?.value || '',
      })
      props.setRefreshTable(true)
      props.setLoad(false)
    }
  }
  const resetSearchForm = async () => {
    setIsFilterData(false)
    searchForm.resetFields()
    props.setQueryParam({ page: 1 })
    props.setRefreshTable(true)
    setIsFilterData(false)
    searchForm.resetFields()
  }

  const onValuesChange = e => {
    if (e?.name || e?.client) {
      setIsFilterData(true)
    } else {
      setIsFilterData(false)
    }
  }
  return (
    <div>
      <Form
        layout='horizontal'
        className={style.projectSearch}
        form={searchForm}
        onFinish={submitFilterData}
        onValuesChange={e => onValuesChange(e)}
      >
        <CustomRow
          gutter={4}
          className={`rowDiv gap-lg-0  gap-xl-0 gap-xxl-2 flex-grow-1`}
          justify={'space-between'}
        >
          <div style={{ display: 'flex', flex: 1 , flexWrap: 'wrap'}}>
            <Col xs={24} sm={12} md={8} lg={6} className={style.searchProject}>
              <Form.Item name='name'>
                <CommonInput
                  value={projectName}
                  placeholder='Project Name'
                  className='inputField'
                  prefix={<SearchOutlined />}
                  onChange={e => setProjectName(e.target.value)}
                />
              </Form.Item>
            </Col>
            {
              props.isActive === 'Account' ? 
                <><StyledCol xs={24} sm={12} md={8} lg={6} className={style.errorSpace}>
                  <DynamicSelect
                    isForm={true}
                    name={'client'}
                    calledFrom={'Project'}
                    placeholder={'Team First Name'}
                    getData={getClientInfo}
                    loadData={loadClientInfo}
                    nextPage={nextCli}
                    queryParam={'search'}
                    valueKey1={'firstName'}
                    valueKey2={'lastName'}
                    setSelected={setSelected}
                    fetchData={false} />
                </StyledCol><StyledCol xs={24} sm={12} md={8} lg={6} className={style.errorSpace}>
                  <DynamicSelect
                    isForm={true}
                    name={'client'}
                    calledFrom={'Project'}
                    placeholder={'Team Designation'}
                    getData={getClientInfo}
                    loadData={loadClientInfo}
                    nextPage={nextCli}
                    queryParam={'search'}
                    valueKey1={'firstName'}
                    valueKey2={'lastName'}
                    setSelected={setSelected}
                    fetchData={false} />
                </StyledCol></>
                :
                <StyledCol xs={24} sm={12} md={8} lg={6} className={style.errorSpace}>
                  <DynamicSelect
                    isForm={true}
                    name={'client'}
                    calledFrom={'Project'}
                    placeholder={'Please Select Client...'}
                    getData={getClientInfo}
                    loadData={loadClientInfo}
                    nextPage={nextCli}
                    queryParam={'search'}
                    valueKey1={'firstName'}
                    valueKey2={'lastName'}
                    setSelected={setSelected}
                    fetchData={false}
                  />
                </StyledCol>}
          </div>
          <Col className='mt-2'>
            <Form.Item className={style.searchBtn}>
              <div className='d-flex'>
                <CustomButton
                  type='primary'
                  htmlType='submit'
                  disabled={!isFilterData}
                  title='Search'
                  size='large'
                  className={style.search}
                ></CustomButton>
                <CustomButton
                  htmlType='reset'
                  disabled={!isFilterData}
                  size='large'
                  title='Reset'
                  onClick={resetSearchForm}
                ></CustomButton>
              </div>
            </Form.Item>
          </Col>
        </CustomRow>
      </Form>
    </div>
  )
})

export default ProjectsSearch

const StyledCol = styled(Col)`
  .ant-select{
margin-top: 7px !important;
  }
`

const CustomRow = styled(Row)`

@media (max-width: 772px) {
    display: block;
    flex: none;
  }
`