import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeLeaveTypeApi } from '@api'
import { notification } from '@utils/notifications'

export const total_leaves = types.model({
  numberOfDaysSum: types.maybeNull(types.number),
})

export const employeeLeaveType = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  numberOfDays: types.maybeNull(types.number),
  isHalfLeave: types.maybeNull(types.boolean),
})

export const employeeLeaveTypeModal = types
  .model({
    data: types.maybeNull(types.array(employeeLeaveType)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextType: types.optional(types.number, 1),
  })
  .views(self => ({
    get getEmployeeLeaveTypeData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setEmployeeLeaveTypeData = flow(function* (data) {
      try {
        self.loading = true
        yield employeeLeaveTypeApi.setEmployeeLeaveType(data)
        notification.success('Leave Type Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error('Leave Type Name Already exists')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadEmployeeLeaveType = flow(function* (
      page = 1,
      pageSize = 20,
      queryParam = '',
      resetList = false
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextType = 1
        }
        const res = yield employeeLeaveTypeApi.getEmployeeLeaveType(
          page,
          pageSize,
          queryParam
        )
        if (!res?.isCancel) {
          self.data = cast(res)
          self.count = res.count
          if (res.next) {
            self.nextType = self.nextType + 1
          } else {
            self.nextType = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Employee')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeLeaveType = flow(function* (id, data) {
      try {
        self.loading = true
        yield employeeLeaveTypeApi.updateEmployeeLeaveType(id, data)
        notification.success('Leave Type Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEmployeeLeaveType = flow(function* (id) {
      try {
        self.loading = true
        yield employeeLeaveTypeApi.deleteEmployeeLeaveType(id)
        notification.success('Leave Type Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setEmployeeLeaveTypeData,
      loadEmployeeLeaveType,
      deleteEmployeeLeaveType,
      updateEmployeeLeaveType,
    }
  })

export function initEmployeeLeaveTypeModal() {
  return employeeLeaveTypeModal.create({})
}
