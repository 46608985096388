import { CommonInput } from '@commonComponents/input'
import React , {useState , useEffect} from 'react'
import { MdEdit } from 'react-icons/md';
import { Tooltip, Modal, Button, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import SupervisorSelect from '@commonComponents/supervisorDropdown/supervisorDropdown';
import styled from 'styled-components'
import { ADD_METHOD, PAYROLL_MOD } from 'constant/constant'
import ShowOnPermission from '@commonComponents/permit'
import { colors } from 'constant/colors';

const { TealishBlue } = colors

const IncrementAndBonusInput = observer(({
  type,
  employeeId,
  employeeName,
  selectedType,
  setSelectedType,
  selectedRecord,
  setSelectedRecord,
  displayValue,
  inputValue,
  setInputValue,
  lastRecommendedBy,
  recommendedBy,
  setRecommendedBy,
  resetValues,
  rowRecord,
  onSubmit,

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
}: any
) => {

  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)

  const [color, setColor] = useState(null)

  useEffect(() => {
    setColor(JSON.parse(localStorage.getItem('colorTheme')))
  }, [])

  function isColorDark(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onlyNumber = (e: any) => {
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189)
      e.preventDefault();
  }
  const displayField = () => {
    setSelectedType(type)
    setSelectedRecord(employeeId)
    setInputValue(null)
    setOpen(true)
    setInputValue(displayValue !== '-' ? displayValue : '');
    setRecommendedBy(rowRecord?.recommendedBy)
  }

  const handleOk = () => {
    form.validateFields().then(() => {
      onSubmit();
      resetValues();
      setOpen(false);
    })
  };

  const handleCancel = () => {
    resetValues()
    setOpen(false);
  };

  useEffect(() => {
    form.resetFields();
    if (rowRecord && (type === selectedType)) {
      form.setFieldsValue({
        recommended_by: lastRecommendedBy ? lastRecommendedBy : undefined,
        bouns: displayValue == '-' ? '' : displayValue,
      })
    }
  }, [open])

  if (!(selectedRecord === employeeId) || !(type === selectedType)) return (
    <div className='d-flex align-items-center justify-content-between gap-4 mb-2' >
      {lastRecommendedBy && displayValue !== '-' ? (
        <Tooltip title={`Recommended By: ${lastRecommendedBy}`} color={TealishBlue} >
          <p>{displayValue}</p>
        </Tooltip>
      ) : (
        <p>{displayValue}</p>
      )}
      <ShowOnPermission mod={PAYROLL_MOD} method={ADD_METHOD}>
        <IconDiv className='editAction' onClick={() => displayField()}>
          <Tooltip title={'Edit'}>
            <MdEdit />
          </Tooltip>
        </IconDiv>
      </ShowOnPermission>
    </div> 
    
  )
  return (
    <div className='d-flex align-items-center gap-3'>
      <CustomModal 
        color={color || 'blue'} textColor={isColorDark(color?.bodyColor || 'blue')}
        title={employeeName}
        open={open}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button className='modalbtn' key='back' onClick={handleCancel} title='Cancel'>
            Cancel
          </Button>,
          <Button className='modalbtn' key='submit' type='primary' onClick={handleOk}>
            Submit
          </Button>
        ]}
      >        

        <div className={`align-items-center mb-6`}>
          <Form
            form={form}
            onFinish={handleOk}
            layout='vertical'
          >
            <StyledPopoverInputWrapper>
              <SupervisorSelect
                initialValue={recommendedBy}
                isForm={true}
                placeholder='Select Supervisor'
                name='recommended_by'
                setSelected={setRecommendedBy}
                fetchData={false}
                formLabel={'Supervisor Name'}
                formRules={[{ required: true, message: 'Supervisor is required' }]}
              />
              <Form.Item
                label={type}
                name={'bouns'}
                rules={[{ required: true, message: `${type} is required` }]}
              >
                <CommonInput
                  className='form-control inputField'
                  onKeyDown={e => onlyNumber(e)}
                  value={inputValue || ''}
                  onChange={e => setInputValue(+e.target.value)}
                  inputType={'number'}
                  placeholder='Ex: 2000'
                />
              </Form.Item>
            </StyledPopoverInputWrapper>
          </Form>
        </div>
      </CustomModal>
    </div>
  )
})

export default IncrementAndBonusInput

const StyledPopoverInputWrapper = styled.div`
.ant-form-item{
  margin-bottom: 10px;
}
 .ant-select .ant-select-selection-item {
   display: flex !important;
   align-items: center !important;
  font-size: 14px;
  -webkit-box-align: center;
  line-height: 40px;
  color: rgb(51, 51, 51);
}
span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #525353;
  word-spacing: 0px;
}
.ant-select-arrow {
  margin-top: 0px;
}
.ant-select-selector {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 35px !important;
}
.ant-input {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 35px !important;
  width: 200px;
}
.ant-select-selector input {
  cursor: pointer !important;
  margin-top: -5px !important;
}
`
const CustomModal = styled(Modal)`
.ant-modal-content {
  border-radius: 20px;
}
.ant-btn-primary, .ant-modal-header{
	background-color: ${props => props.color?.bodyColor || TealishBlue} !important;
  color:${props => props.textColor ? 'white' : 'black'} !important;
  border-color: ${props => props.color?.bodyColor} !important;
}
.ant-btn:hover{
  border-color: ${props => props.color?.bodyColor} !important;
  color:${props => props.textColor ? 'black' : props.textColor};
}
.ant-modal-title {
  color: #ffffff;
}
.ant-modal-close-x{
  color: #ffffff;
}
.ant-modal-header{
  border-radius: 20px 20px 0px 0px;
}
.ant-modal-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px
}
.modalbtn{
    border-radius: 20px;
    height: 43px;
  .hover {
    color: #ffffff;
  }
}
`
const IconDiv = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 32px;
  display: inline-block;
  cursor: pointer;
  text-align:center;
  svg {
    width: 15px;
    height: 15px;
    position: relative;
    top: 10px;
  }
  &:hover {
    background-color: rgb(218, 216, 216);
    cursor: pointer;
  }
`