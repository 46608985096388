import { familyInfoUrl } from '../const'
import { BaseApi } from '../baseApi'

interface FamilyData {
  name: string;
  relation: string;
  phone_number1: string;
  phone_number2?: string;
  employee_id?: string;
}

class FamilyApi extends BaseApi {
  setFamilyDetails = async (data: string) => {
    try {
      const response = await this.axios.post(familyInfoUrl, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getFamilyDetails = async (employee_id: string) => {
    try {
      const url = `${familyInfoUrl}/emp/${employee_id}`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateFamilyDetails = async (id: string, data: FamilyData) => {
    try {
      const url = `${familyInfoUrl}/${id}`
      const response = await this.axios.put(url, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteFamilyDetails = async (id: string) => {
    try {
      const url = `${familyInfoUrl}/${id}`
      const response = await this.axios.delete(url)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default FamilyApi
