import React, { useState, useRef, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Space } from 'antd'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import DeletePopUp from '@commonComponents/delete-popup'
import CategoryForm from './categoryForm'
import BreadCrumbs from '@commonComponents/breadcrums'
import CustomButton from '@commonComponents/button'
import { Table } from '@commonComponents/table-v2'
import { categoryUrl } from '@api/const'
import CustomDrawer from '@commonComponents/drawer'
import SearchCategory from './SearchCategory'
import getColumns from './columns'
import { DELETE_CATEGORY, UPDATE, ADD, CANCEL, UPDATE_CATEGORY, ADD_CATEGORY, CANCEL_BUTTON_CLASS, ADD_BUTTON_CLASS, CATEGORY_MOD, POST_METHOD, VIEW_ALL } from 'constant/constant'
import ShowOnPermission from '@commonComponents/permit'

const AccountCategories = observer(() => {
  const {
    hrCategoryModal: {
      isLoading,
      deleteCategory,
    },
  } = useStore(null)
  const [queryParam, setQueryParam] = useState({})
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [visible, setVisible] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [isUpdate, setisUpdate] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [resetTable, setResetTable] = useState(false)
  const [deleteName, setDeleteName] = useState('')
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const tableRef = useRef(null)

  const deleteEmployeeHandler = async () => {
    const res = await deleteCategory(currentId)
    if (res.status) {
      setCurrentId('');
      setDeleteName('');
      (totalRecordRemaining === 1) ? setResetTable(true) : setRefreshTable(true);
    }
    setVisibleDelete(false)
  }

  const onEdit = data => {
    setCurrentData(data)
    setisUpdate(true)
    setVisible(true)
  }

  const onDelete = async data => {
    setDeleteName(data?.title)
    setCurrentId(data?.id)
    setVisibleDelete(true)
  }

  const onAdd = () => {
    setisUpdate(false)
    setVisible(true)
  };
  const columns = useMemo(() => getColumns(onDelete, onEdit), [])



  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='d-flex justify-content-between page-header'>
          <BreadCrumbs
            list={[
              { name: 'Dashboard', link: constRoute.home },
              { name: 'Categories', link: constRoute.accountCategories },
            ]}
            pageTitle={'Categories'}
          >
          </BreadCrumbs>
          <ShowOnPermission mod={CATEGORY_MOD} method={POST_METHOD}>
            <CustomButton
              onClick={onAdd}
              title={ADD_CATEGORY}
            />
          </ShowOnPermission>
        </div>
        {/* /Page Header */}
        <ShowOnPermission mod={CATEGORY_MOD} method={VIEW_ALL}>
        <SearchCategory
          setQueryParam={setQueryParam}
        />
        </ShowOnPermission>
        <div className='table-responsive custom-Pagination'>
          <Table
            ref={tableRef}
            dataUrl={categoryUrl}
            columns={columns}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            resetTable={resetTable}
            queryParam={queryParam}
            setResetTable={setResetTable}
            setTotalRecordRemaining={setTotalRecordRemaining}
          />
        </div>
      </div>
      {/* Add & Edit Category Modal */}
      <CustomDrawer
        className='addDrawer'
        visible={visible}
        title={isUpdate ? UPDATE_CATEGORY : ADD_CATEGORY}
        setVisible={() => {
          setVisible(false)
        }}
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={CANCEL}
              className={CANCEL_BUTTON_CLASS}
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='category-form'
              htmlType='submit'
              type='primary'
              className={ADD_BUTTON_CLASS}
              title={isUpdate ? UPDATE : ADD}
            />
          </Space>
        }
      >
        {visible && (
          <CategoryForm
            setVisible={setVisible}
            update={isUpdate}
            data={currentData}
            setRefreshTable={setRefreshTable}
          />
        )}
      </CustomDrawer>
      {/* /Add & Edit Category Modal*/}
      {/* Delete Category Modal */}
      <DeletePopUp
        delete={deleteEmployeeHandler}
        msgTitle={DELETE_CATEGORY}
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
        renderMsg={`Are you sure you want to delete  ${deleteName} category?
         Deleting  ${deleteName} category will also delete it's child catagories`}
      />
      {/* /Delete Category Modal */}
    </div>
  )
})

export default AccountCategories

