import { headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class BoardLabelApi extends BaseApi {

  // Board Label APIs
  setBoardLabelDetails = async (data, boardID) => {
    try {
      const response = await this.axios.post(
        `${baseUrl}board_label/`,
        {
          name: data.name,
          color: data.color,
          boardID: parseInt(boardID),
          text_color: data?.text_color,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getBoardLabelDetails = async boardID => {
    try {
      const response = await this.axios.get(
        `${baseUrl}board_label/?boardID=${boardID} `,
        headerAuthorization(this)
      )
      return response
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateBoardLabelDetails = async (boardID, labelID, data) => {
    try {
      const response = await this.axios.patch(
        `${baseUrl}board_label/${labelID}/?boardID=${boardID}`,
        data,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteBoardLabelDetails = async (labelID, boardID) => {
    try {
      const response = await this.axios.delete(
        `${baseUrl}board_label/${labelID}/?boardID=${boardID}`,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  // Sprint APIs

  postSprint = async data => {    
    try {
      const response = await this.axios.post(`${baseUrl}sprints/`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getProjectSprint = async id => {
    try {
      const response = await this.axios.get(
        `${baseUrl}sprints/?id=${id}`,
        headerAuthorization(this)
      )
      return response
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteSprint = async id => {
    try {
      const response = await this.axios.delete(
        `${baseUrl}sprints/${id}/`,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

}

export default BoardLabelApi
