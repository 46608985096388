import { flow, types } from 'mobx-state-tree'
import { serialNumberApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'


export const serialNumberModel = types
  .model({
    data: types.maybeNull(types.array(types.string)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    erorMessage: types.maybeNull(types.string),
    nextser: types.optional(types.number, 1)

  })
  .views(self => ({
    get getSerialNumberData() {
      return toJS(self.data)
    },
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get isLoading() {
      return self.loading
    },
   
  }))

  .actions(self => {
    
    const loadSerialNumber = flow(function* () {
      try {
        self.loading = true
        const res = yield serialNumberApi.getSerialNumberApi()
        self.data = res?.filter(item=>item)
      } catch (error) {
        const message = error?.response?.data?.message
        if(error?.response?.status == 400){
          self.erorMessage = error?.response?.data?.message;
        }
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Serail Number')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })
    
    return {
      loadSerialNumber

    }
  })

export function initSerialNumberModel() {
  return serialNumberModel.create({})
}
