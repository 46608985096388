import { CommonInput } from '@commonComponents/input'
import { CommonSelect } from '@commonComponents/select'
import { useStore } from '@stores/root-store'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface FormProps {
  setBtnLoad?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  loadLeadDesignation?: any;
  getDesignationData?: any;
  getLeadRole?: any;
  loadLeadRole: any;
}

interface EmployeeFormValues {
  first_name: string;
  last_name: string;
  email: string;
  employment_model: string[];
  shift?: string;
  designation: string;
  supervisor?: string;
  roles?: [];
  department?: string;
  branch?: string;
}

const employeeForm = observer((props: FormProps) => {
  const [form] = Form.useForm()
  const [emailError, setEmailError] = useState(false)

  const {
    employeeDetails: {
      loadEmployeeDetails,
      setEmployeeDetails,
      errorMessage,
      loadAllEmployeeList,
      getEmployeeAllList,
      loadLeadShift,
      getLeadShift,
    },
    departmentModal: { getDepartmentData, loadDepartment },
    branchModel: { getBranchData, loadBranch },
    // userInfo: { getcompany },
    employmentCompany: {
      loadEmploymentCompany,
      getEmploymentCompanyData
    }
  } = useStore(null)

  useEffect(() => {
    getApiData()
  }, [])

  useEffect(() => {
    (async function () {
      // if (getcompany?.isLead) {
        await loadLeadShift()
      // }
      // if (!getDepartmentData?.length) {
        await loadDepartment()
      // }
    })()
  }, [])

  useEffect(() => {
    if (errorMessage == 'employee with this email already exists.') {
      setEmailError(true)
    }
  }, [errorMessage])

  const getApiData = async () => {
    loadEmploymentCompany()
    await loadAllEmployeeList()
    if (!getBranchData?.length) {
      await loadBranch(1, 20, '', true)
    }
  }

  const submitData = async (values: EmployeeFormValues) => {
    const data = {
      user: {
        first_name: values?.first_name,
        last_name: values?.last_name,
        email: values?.email,
        roles: ([values?.roles]).filter(Boolean),
      },
      designation: values?.designation,
      department: values?.department,
      supervisor: values?.supervisor,
      branch: values?.branch,
      shift: values?.shift ?? '',
      employment_model: Array.isArray(values?.employment_model) ? values?.employment_model : [],
    }

    props.setBtnLoad(true)
    const response = await setEmployeeDetails(data)

    if (response?.success) {
      props?.setRefreshTable && props?.setRefreshTable(true)
      props.setVisible(false)
      form.resetFields()
      loadEmployeeDetails(1, 200, '', true)
    }
    props.setBtnLoad(false)
  }
  function mapDataToKeyValue(data) {
    return (
      data?.map(({ id, title }) => ({
        key: id,
        value: title,
      })) || []
    )
  }

  const filteredDesignation = mapDataToKeyValue(props.getDesignationData)
  const filterRole = mapDataToKeyValue(props.getLeadRole)
  const filteredShift = mapDataToKeyValue(getLeadShift)
  const filteredDepartment = mapDataToKeyValue(getDepartmentData)
  const filteredBranch = mapDataToKeyValue(getBranchData)
  const filteredEmployement = mapDataToKeyValue(getEmploymentCompanyData)
  const allEmployees = getEmployeeAllList?.map(value => ({
    key: value.id,
    value: value.fullName,
  })) || []
  return (
    <EmployeeForm
      id='employee-form'
      form={form}
      onFinish={submitData}
      layout='vertical'
      initialValues={{
        create_user: true,
        is_lead: false,
        is_intern: false,
      }}
    >
      <Row>
        <Col xs={24} sm={11}>
          <Form.Item
            name={['first_name']}
            label='First Name'
            rules={[validateRequired('First Name'), validateNoWhiteSpace()]}
          >
            <CommonInput
              className={'inputField'}
              inputType='text'
              placeholder='Enter your first name'
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={11}>
          <Form.Item
            name={['last_name']}
            label='Last Name'
            rules={[validateRequired('Last Name'), validateNoWhiteSpace()]}
          >
            <CommonInput
              className={'inputField'}
              inputType='text'
              placeholder='Enter your last name'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={11}>
          <Form.Item
            name={['email']}
            label='Email'
            rules={[
              { type: 'email', message: 'Email is invalid' },
              { required: true, message: 'Email is required' },
            ]}
            validateTrigger={['onBlur']}
          >
            <CommonInput
              inputType='email'
              placeholder='example@gmail.com'
              onFocus={() => form.setFields([{ name: 'email', errors: [] }])}
            />
          </Form.Item>
          {emailError ? (
            <div style={{ color: 'red' }}>{errorMessage}</div>
          ) : null}
        </Col>
        <Col xs={24} sm={11}>
          <Form.Item
            name={['designation']}
            label='Designation'
            rules={[validateRequired('Designation')]}
            validateTrigger={['onBlur']}
          >
            <CommonSelect
              listHeight={120}
              placeholder='Select Designation'
              size='large'
              allowClear
              getPopupContainer={trigger => trigger.parentNode}
              data={filteredDesignation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={11}>
          <Form.Item
            name={['branch']}
            label='Branch'
            validateTrigger={['onBlur']}
            rules={[validateRequired('Branch')]}
          >
            <CommonSelect
              listHeight={120}
              placeholder='Select Branch'
              size='large'
              allowClear
              getPopupContainer={trigger => trigger.parentNode}
              data={filteredBranch}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={11}>
          <Form.Item
            name={['supervisor']}
            label='Supervisor'
            validateTrigger={['onBlur']}
            rules={[validateRequired('Supervisor')]}
          >
            <CommonSelect
              listHeight={125}
              getPopupContainer={trigger => trigger.parentNode}
              placeholder='Select Employee'
              data={allEmployees}
              showSearch
              filterOption={(input, option) => {
                const { children } = option
                return children.toLowerCase().includes(input.toLowerCase())
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={11}>
          <Form.Item
            name={['roles']}
            label='Role'
            validateTrigger={['onBlur']}
            rules={[validateRequired('Role')]}
          >
            <CommonSelect
              placeholder='Select Role'
              data={filterRole}
              getPopupContainer={trigger => trigger.parentNode}
              showSearch
              filterOption={(input, option) => {
                const { children } = option
                return children.toLowerCase().includes(input.toLowerCase())
              }}
            />

          </Form.Item>
        </Col>

        <Col xs={24} sm={11}>
          <Form.Item
            name={['shift']}
            label='Shift'
            validateTrigger={['onBlur']}
            rules={[validateRequired('Shift')]}
          >
            <CommonSelect
              listHeight={120}
              placeholder='Select Shift'
              size='large'
              allowClear
              getPopupContainer={trigger => trigger.parentNode}
              data={filteredShift}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
      <Col xs={24} sm={11}>
        <Form.Item
          name={['department']}
          label='Department'
          validateTrigger={['onBlur']}
          rules={[validateRequired('Department')]}
        >
          <CommonSelect
            listHeight={120}
            placeholder='Select Department'
            size='large'
            allowClear
            getPopupContainer={trigger => trigger.parentNode}
            data={filteredDepartment}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={11}>
        <Form.Item
          name={['employment_model']}
          label='Employment'
          validateTrigger={['onBlur']}
        >
          <CommonSelect
            listHeight={120}
            placeholder='Select Employment'
            size='large'
            allowClear
            getPopupContainer={trigger => trigger.parentNode}
            data={filteredEmployement}
          />
        </Form.Item>
      </Col>
      </Row>
    </EmployeeForm>
  )
})

export default employeeForm

const EmployeeForm = styled(Form)`
  .ant-row {
    justify-content: space-between;
  }
`
