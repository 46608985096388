import { notification } from '@utils/notifications'
import { flow, types } from 'mobx-state-tree'
import { projectLanguageApi } from '../../api/index'

export const idAndNameLan = types.model({
  name: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
})
export const idAndName = types.model({
  name: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
})
export const project = types.model({
  project: types.maybeNull(idAndName),
  language: types.maybeNull(types.array(idAndNameLan)),
  id: types.maybeNull(types.string),
})
export const projectLanguageModal = types
  .model({
    data: types.maybeNull(types.array(project)),
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getProjectLanguageData() {
      return self.data
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setProjectLanguageData = flow(function* (data) {
      try {
        self.loading = true
        yield projectLanguageApi.setProjectLanguage(data)
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })
    const loadProjectLanguage = flow(function* (num = 1) {
      try {
        self.loading = true
        const res = yield projectLanguageApi.getProjectLanguage(num)
        self.data = res.results
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Project Language')
        }
      } finally {
        self.loading = false
      }
    })
    const updateProjectLanguage = flow(function* (data, id) {
      try {
        self.loading = true
        const res = yield projectLanguageApi.updateProjectLanguage(data, id)
        self.data = res.results
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteProjectLanguage = flow(function* (id) {
      try {
        self.loading = true
        const res = yield projectLanguageApi.deleteProjectLanguage(id)
        self.data = res.results
      } catch (error) {
        // error catch
      } finally {
        self.loading = false
      }
    })

    return {
      setProjectLanguageData,
      loadProjectLanguage,
      updateProjectLanguage,
      deleteProjectLanguage,
    }
  })

export function initProjectSalaryModal() {
  return projectLanguageModal.create({})
}
