import { Avatar, Button, DatePicker, Dropdown, Input, Menu, Modal, Tooltip, Select } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { BsFillPlayCircleFill } from 'react-icons/bs'
import { BsPersonFillAdd } from 'react-icons/bs'
import { BsFlagFill } from 'react-icons/bs'
import { FaPauseCircle } from 'react-icons/fa'
import styled from 'styled-components'
import Duedate from '../../../../assets/img/Group.svg'
import Timelapse from '../../../../assets/img/GroupTime.svg'
import plus from '../../../../assets/img/plus.svg'
import { DownOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { StyleFooter, StyleMenuTime, StyledCenteredText, StyledDiv } from './boardView'
import moment from 'moment'
import { useStore } from '@stores/root-store'
import { text } from '@fortawesome/fontawesome-svg-core'
import { AttachmentDiv, StyleAttach } from '@mainPage/Asset/AssetForm'
import { DeleteOutlined } from '@ant-design/icons';


const taskDetails = (props: any) => {
  const [isFocused, setIsFocused] = useState(false)
  const [datePicker, setDatePicker] = useState(false)
  const [, setEstimate] = useState(false)
  const [timer, setTimer] = useState(0)
  const [start, setStart] = useState(false)
  const [empname, setEmpName] = useState([]);
  const [selectDropdown, setSelectDropdown] = useState(false)
  const [comment, setComment] = useState(null)
  const [discription, setDiscription] = useState(null)
  const [fileList, setFileList] = useState([])

  const { Option } = Select;
  const { TextArea } = Input;

  const {
    employeesOpenList: { loadEmployeesOpenList, getEmployeesOpenList },
    boardDetails: {
      updateTaskDetails, loadBoardListDetails, getBoardListData, getCompleteBoard, updateBoardListDetails
    },
  } = useStore(null);

  const getPriority = props?.details?.e?.priority
  const getData = props?.details?.e
  const getFullDetail = props?.details?.item


  useEffect(() => {
    loadBoardListDetails(getCompleteBoard?.[0]?.id)
  }, [])

  useEffect(() => {
    async function load() {
      await loadEmployeesOpenList();
    }
    load();
  }, []);


  useEffect(() => {
    const savedValue = getData?.description;
    if (savedValue) {
      setDiscription(savedValue);
    } else {
      setDiscription('');
    }
  }, [getData?.description]);



  useEffect(() => {
    let startTimer
    if (start) {
      startTimer = setInterval(() => {
        setTimer(time => time + 1)
      }, 1000)
    }
    return (() => clearInterval(startTimer))
  }, [start, timer])


  const formatTime = () => {
    return moment.utc(timer * 1000).format('HH:mm:ss')
  }


  const startTimer = () => {
    setStart(true)
  }

  const hadlePriorityValue = (item, value) => {
    if (item === 'tracked_time') {
      setStart(false);
      const formattedTrackedTime = moment.utc(value * 1000).format('HH:mm:ss');
      const updatedData = { [item]: formattedTrackedTime };
      updateTaskDetails(updatedData, getData?.id);
    }
    else if(item === 'attachments'){
      const formData = new FormData()

      value.attachments = fileList.map(file => {
        formData.append('attachments', file);
      });
      updateTaskDetails(formData, getData?.id);
    }
    else {
      const updatedData = { [item]: value };
      const res = updateTaskDetails(updatedData, getData?.id);
      if (res) {
        setComment(null)
      }
    }
  }



  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }


  const handleDelete = employeeToDelete => {
    const updatedEmpname = empname.filter(employee => employee !== employeeToDelete);
    setEmpName(updatedEmpname);
  };

  const fileProps = {
    onRemove: file => {
      const newFileList = fileList.filter(f => f.uid !== file.uid);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const EstimateMenu = (
    <StyleMenuTime>
      <Menu>
        <div className='d-flex styleinput'>
          <label>Time Estimate ?</label>
          <Input onBlur={e => hadlePriorityValue('estimated_time', e?.target?.value)} />
        </div>
        <StyleFooter className='d-flex justify-content-center gap-3 text-align-center'>
          <span>Total with subtasks 2h</span>
          <p>Changes Automatically saved</p>
        </StyleFooter>
      </Menu>
    </StyleMenuTime>
  )


  const handleChangeStatus = async item => {
    const update = {
      id: getData?.id,
      name: item?.name,
    }
    await updateBoardListDetails(update);
  };


  const SelectDropdown = (
    <StyleSelectDropdown>
      <Menu>
        {getBoardListData?.map(item => (
          <Menu.Item >
            <div className='d-flex align-items-center gap-2 setItem'>
              <div style={{ backgroundColor: item.color, width: '10px', height: '10px' }}></div>
              <div onClick={() => handleChangeStatus(item)}>{item?.name}</div>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    </StyleSelectDropdown>
  )


  const priorityMenu = (
    <StylePriority>
      <Menu>
        <Menu.Item key='medium' >
          <div className=' setMenu' onClick={() => hadlePriorityValue('priority', 'MEDIUM')}>
            <BsFlagFill color='#6fddff'></BsFlagFill>
            NORMAL
          </div>
        </Menu.Item>
        <Menu.Item key='high'>
          <div className='setMenu' onClick={() => hadlePriorityValue('priority', 'HIGH')}>
            <BsFlagFill color='#ff0800'></BsFlagFill>
            HIGH
          </div>
        </Menu.Item>
        <Menu.Item key='low' >
          <div className='setMenu' onClick={() => hadlePriorityValue('priority', 'LOW')}>
            <BsFlagFill color='#00f31b'></BsFlagFill>
            LOW
          </div>
        </Menu.Item>
      </Menu>
    </StylePriority>
  );

  return (
    <StyleModal>
      <Modal
        title={getFullDetail?.sprintName}
        centered
        visible={props?.open}
        onOk={() => props?.setOpen(false)}
        onCancel={() => props?.setOpen(false)}
        width={1500}
        footer={null}
        getContainer={false}
      >
        <div>
          <div className='d-flex'>
            <div className='CustomNavbar'>
              <div className='NavItem'>
                <StyleDropDown color={getFullDetail?.color}>
                  <Dropdown.Button overlay={SelectDropdown} trigger={['click']} placement='bottomCenter' icon={<DownOutlined />}> {getFullDetail?.name}</Dropdown.Button>
                </StyleDropDown>
                <div>
                  <StyledCenteredText >
                    <Avatar.Group maxCount={4} size={32}>
                      {empname?.map((employee, index) => (
                        <StyledDiv
                          index={index}
                          length={text?.length}
                          style={{ color: 'white !important' }}>
                          <div className='AvatarIcon'>
                            <Avatar key={index} size='large' style={
                              { backgroundColor: index === 0 ? '#edafbc' : '#edb777', zIndex: getEmployeesOpenList?.length - index }} >
                              {employee?.charAt(0).toUpperCase()}
                            </Avatar>
                            <img src={plus} key={index} alt='' style={{ zIndex: getEmployeesOpenList?.length + 1 }} className='img3 srtZ' onClick={() => handleDelete(employee)} />
                          </div >
                        </StyledDiv>
                      ))}
                      <div className='addIcon'>
                        <BsPersonFillAdd onClick={() => setSelectDropdown(true)}></BsPersonFillAdd>
                      </div>
                    </Avatar.Group>
                    <div className='taskDetailSelect'>
                      <Select
                        style={{ width: 200, visibility: selectDropdown ? 'visible' : 'hidden', marginTop: selectDropdown && '10px' }}
                        open={selectDropdown}
                        showSearch
                        placeholder='Select Assignee'
                        onSelect={e => {
                          hadlePriorityValue('assigned_to', e);
                          setSelectDropdown(false);
                        }} 
                        onBlur={() => setSelectDropdown(false)}
                      >
                        {getEmployeesOpenList?.map((name, item) => (
                          <Option key={item} value={name?.firstName}>
                            {name?.firstName}
                          </Option>
                        ))} 
                      </Select>
                    </div>
                  </StyledCenteredText>
                </div>

                <div className='d-flex'>
                  <Dropdown overlay={priorityMenu} trigger={['click']}>
                    <div className='d-flex priorityFlag'>
                      <StylePriorityFLag getPriority={getPriority}>
                        <BsFlagFill color={getPriority === 'MEDIUM' && '#6fddff' || getPriority === 'HIGH' && '#ffcc00' || getPriority === 'URGENT' && 'red' || getPriority === 'LOW' && '#00f31b'} />
                      </StylePriorityFLag>
                      <img className='img3' src={plus} />
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className='CustomNavbar'>
              <div className='NavItem'>
                <div className='setNav'>
                  CREATED <br />
                  <span className='setTime'>
                    {getData?.createdAt && moment(getData?.createdAt).format('DD-MM-YYYY')}
                  </span>                </div>
                <div className='setNav'>
                  TIME TRACKED <br />
                  <div className='d-flex gap-2 iconColor'>
                    <div>
                      {start ?
                        <Tooltip title='Stop Timer'>
                          <FaPauseCircle onClick={e => hadlePriorityValue('tracked_time', e)} />
                        </Tooltip> :
                        <Tooltip title='Start Timer'>
                          <BsFillPlayCircleFill onClick={startTimer} />
                        </Tooltip>
                      }
                    </div>
                    <div className='setTime'>{formatTime()}</div>
                  </div>
                </div>
                <div className='gap-2 d-flex align-items-center'>
                  <Dropdown overlay={EstimateMenu} trigger={['click']}>
                    <Tooltip title='Estimate Task'>
                      <img src={Timelapse} onClick={() => setEstimate(true)} alt='' />
                    </Tooltip>
                  </Dropdown>
                  <div>
                    <p className='color'>Time Estimate</p>
                    <p>{getData?.estimatedTime}</p>
                  </div>
                </div>
                <div className='d-flex datepicker'>
                  <div className='d-flex'>
                    <Tooltip title='Due Date'>
                      <img src={Duedate} onClick={() => setDatePicker(true)} alt='' />
                    </Tooltip>
                    <div>
                      <DatePicker open={datePicker} onOpenChange={() => setDatePicker(false)} onChange={date => hadlePriorityValue('due_date', moment(date).format('YYYY-MM-DDTHH:mm'))}></DatePicker>
                    </div>
                  </div>
                  <div>
                    <div className='color' >Due Date</div>
                    <div>{getData?.dueDate && moment(getData?.dueDate).format('DD-MM-YYYY')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='div1'>
              <div className='divItems'>
                <h3 contentEditable='true' onBlur={e=>hadlePriorityValue('name',e?.target?.textContent)}>
                  {getData?.name}
                </h3>
                <TextArea
                  className='mt-2'
                  rows={10}
                  value={discription}
                  onChange={e => setDiscription(e?.target?.value)}
                  placeholder='write something or type ‘/’ for commands'
                  onBlur={() => {
                    if (discription !== '') {
                      hadlePriorityValue('description', discription);
                    }
                  }}
                />
                <div className='mt-3 mr-4 mb-4'>
                  <label>Attachment</label>
                  <Dragger onChange={e => hadlePriorityValue('attachments', e)} {...fileProps}>
                    <p className='ant-upload-text'>
                      Drop your image here or browse <a>browser</a>
                      <br /> Support .jpg, .jpeg, .png
                    </p>
                  </Dragger>
                  {
                    <StyleAttach>
                      <AttachmentDiv>
                        <a href={getData?.attachments} target='_blank' rel='noopener noreferrer'>
                          Attachment
                        </a>
                        <span>
                          <DeleteOutlined />
                        </span>
                      </AttachmentDiv>
                    </StyleAttach>
                  }
                </div>
              </div>
            </div>
            <div className='div2'>
              <div className='divItems'>
                <div className='nav2' style={{ height: isFocused ? '350px' : '415px' }}>
                  <p>Asad Ahmad Chohan created this task</p>
                  <p>{'>'} 7 more updates</p>
                  <p>You changed status from To Do to In Progress </p>
                  <p>{'>'} 25 more updates</p>
                  <p>
                    Asad Ahmad Chohan assigned to: Areeba Jahangeer jul 10 at
                    11:01 am
                  </p>
                  <p>
                    Asad Ahmad Chohan assigned to: Areeba Jahangeer jul 10 at
                    11:01 am
                  </p>
                  <p>
                    Asad Ahmad Chohan assigned to: Areeba Jahangeer jul 10 at
                    11:01 am
                  </p>
                </div>
                {getData?.comments &&
                  <div className='d-flex gap-2 setComment'>
                    <Avatar>U</Avatar>
                    <p>{getData?.comments}</p>
                  </div>}
                <br />
                <div></div>
              </div>
              <div className='textArea'>
                <TextArea
                  rows={isFocused ? 4 : 1}
                  placeholder="Comment OR type '/' for commands"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={comment}
                  onChange={e => setComment(e?.target?.value)}
                />
                {
                  comment &&
                  <Button type='primary' onClick={() => hadlePriorityValue('comments', comment)}>Comment</Button>
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </StyleModal>
  )
}

export default taskDetails

const StyleModal = styled.div`
  .ant-modal-content {
    height: 80%;
    display: flex;
    flex-direction: column;
    border-radius: 15px !important;
  }

  .ant-modal-body {
    flex-grow: 1;
    overflow: auto !important;
    padding: 0px !important;
  }

   .ant-modal-header {
    border-radius: 13px 13px 0 0;
    background-color: #d9d9d94d;
  } 

  .ant-modal-title {
    width: fit-content;
    padding: 9px;
    background-color: white;
  }

  .CustomNavbar {
    position: relative;
  width: 50pc;
  padding: 12px 0;
  border: 1px solid #cec8c4;
  }

  .NavItem{
    padding: 8px 8px;
  display: flex;
  margin-left: 20px;
  gap: 60px;

  .ant-picker{
    visibility: hidden !important;
    right: 60px !important;
    top: 5px !important;
  }
  .ant-picker-input{
    visibility: hidden !important;
    display: none !important;
  }
  }


  .ant-avatar-group {
    .ant-avatar.ant-avatar-circle {
      width: 40px !important;
      height: 40px !important;
      img {
        width: 36px !important;
        height: 36px !important;
      }
    }
  }
  

  .priorityFlag{
    display: flex;
  position: relative;
  img{
    width: 20px;
    height: 20px;
  }
  .img3{
    top: -5px;
    left: 26px;
    position: absolute;
    transition: transform 0.3s ease-in-out; 
    rotate: 45deg;
    visibility: hidden;
    height: 20px;
    width: 20px;
    background: white;
    border-radius: 12px;
    border: 1px solid white;
  }
  &:hover .img3 {
  visibility: visible;
  }
  .srtZ{
      z-index: 3;
  }
  }

  .setNav{
    color: #cec8c4 !important;
  .iconColor{
    svg {
      color: #0e9a14;
      margin-top: 3px;
    }    
  }
  .setTime{
    color: black !important;
  }
  }

  .div1{
    background-color: #ffff;
  width: 50pc;
  border: 1px solid #cec8c4;
  border-top: none;
  border-right: none;
  }

  .divItems{
    margin-top: 15px;
  padding-left: 25px;
  margin-left: -12px;
  h3 {
    margin-right: 15px;
    padding: 8px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #cec8c4;
      border-radius: 8px;
    }
  }
  h3,
  TextArea {
    outline: none;
    box-shadow: none !important;
  }

  TextArea {
    border-radius: 8px;
    width: 45pc !important;
    border: 1px solid #cec8c4 !important;
    &:hover {
      border: 1px solid #cec8c4 !important;
      box-shadow: none !important;
    }
  }
  }

  .div2{
    position: relative !important;
  background-color: #d9d9d91a;
  width: 50pc;
  border: 1px solid #cec8c4 !important;
  border-top: none !important;
  }

  .nav2{
    overflow-y: auto;
  p {
    color: #a4a3a3;
    margin-top: 20px;
  }
  }

  .textArea{
    position: relative;
    .ant-input{
      padding: 15px;
      border-radius: none !important;
      box-shadow: 0px -5px 8px rgba(117, 115, 115, 0.3) !important;
      outline: none !important;
      border: none !important;
    }
    .ant-btn{
      position: absolute;
  bottom: 10px;
  right: 10px;
    }
  }

.color{
  color: rgb(206, 200, 196) !important;;
}

  .AvatarIcon{
    position: relative;
    display: flex;
    .img3{
      position: absolute;
      margin-left: 26px;
    margin-top: -4px;
    rotate: 45deg;
    visibility: hidden;
    height: 20px;
    width: 20px;
    background: white;
    border-radius: 12px;
    border: 1px solid white;
    }

    &:hover .img3 {
  visibility: visible;
  }
  }

.addIcon{
  position: relative;
  margin-left: -9px;
  svg{
    border: 2px solid;
    border-radius: 5pc;
    height: 40px;
    width: 40px;
  }
}

.taskDetailSelect{
  .ant-select{
    position: absolute;
  }
  .ant-select-selector{
    z-index: 2;
  }
}

.datepicker{
  .ant-picker-dropdown{
    left: 1207px !important;
    top: 139px !important;
  }
}

.setComment{
  p{
    border: 1px solid #a6a6a6;
      padding: 10px;
      border-radius: 10px;
      width: fit-content;
      background: white;
  }
}
`
const StyleSelectDropdown = styled.div`
  .ant-dropdown-menu{
   right: 24px !important;
   width: 150px !important;
   margin-top: 12px !important;
 border-radius: 7px !important;
}
  .setItem{
    padding: 4px 4px 7px 5px;
  }
`

const StylePriority = styled.div`
  .setMenu{
    display: flex;
    align-items: center;
    gap: 5px;
  }
`
const StylePriorityFLag = styled.div`
      width: 40px !important;
      height: 40px !important;
      padding: 8px 6px 8px 5px !important;
      border: 1px solid ${props => props.getPriority === 'MEDIUM' && '#6fddff' || props.getPriority === 'HIGH' && '#ffcc00' || props.getPriority === 'URGENT' && 'red' || props.getPriority === 'LOW' && '#00f31b'} !important;
      border-radius: 50% !important;
    svg{
    width: 100%;
    height: 100%;
    }
  
`
const StyleDropDown = styled.div`
    .ant-btn{
      background: ${props => props?.color} !important;
  }
`