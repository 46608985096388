import { flow, types } from 'mobx-state-tree'
import { certificateApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { errorHandling } from '@utils/common-functions'

export const certificate = types.model({
  id: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  authority: types.maybeNull(types.string),
  licenseNumber: types.maybeNull(types.string),
  issueDate: types.maybeNull(types.string),
  expireDate: types.maybeNull(types.string),
  expires: types.maybeNull(types.boolean),
  url: types.maybeNull(types.string),
})

export const certificateDetails = types
  .model({
    data: types.maybeNull(types.array(certificate)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get certificateData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setCertificateDetails = flow(function* (data) {
      try {
        self.loading = true
        yield certificateApi.setCertificateDetails(data)
        notification.success('Certificate Added Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Something Went Wrong!')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadCertificateDetails = flow(function* (employee_id) {
      try {
        self.loading = true
        const res = yield certificateApi.getCertificateDetails(employee_id)
        self.data = res
      } catch (error) {
        errorHandling(error,'Failed to Load Certificates')
      } finally {
        self.loading = false
      }
    })

    const updateCertificateDetails = flow(function* (id, data) {
      try {
        self.loading = true
        yield certificateApi.updateCertificateDetails(id, data)
        notification.success('Certificate Updated Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to Update Certificate')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteCertificateDetails = flow(function* (id) {
      try {
        self.loading = true
        yield certificateApi.deleteCertificateDetails(id)
        notification.success('Certificate Deleted Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to Delete Certificate')
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setCertificateDetails,
      loadCertificateDetails,
      updateCertificateDetails,
      deleteCertificateDetails,
    }
  })

export function initEmployeeCertificateDetails() {
  return certificateDetails.create({})
}
