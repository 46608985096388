import React from 'react'
import { Modal } from 'antd'
import style from './style.module.scss'
import { CommonInput } from '@commonComponents/input'

const DeletePopUp = props => {
  return (
    <div>
      <Modal
        className={style.deletePopup}
        title={props.msgTitle}
        visible={props.visibleDelete}
        onOk={props.delete}
        confirmLoading={props.isLoading}
        okText={props.okText}
        onCancel={props.handleCancel}
        destroyOnClose={true}
        okButtonProps={{ disabled: (props.isDisable && !props.isDeleteClicked), id: 'popup-confirm-button' }}
        cancelButtonProps={{ id: 'popup-cancel-button' }}
      >
        {(props.isCancelLeavePopUp && !props.isDeleteClicked) && <div className={`${style.ApprovedConetent} align-items-center mb-4`}>
          <h5>Cancellation Reason</h5>
          <CommonInput
            inputType='textarea'
            className='w-100 inputField'
            onChange={e => { props.setIsDisable(e.target.value.length > 5 ? false : true); props.setCancellationReason(e.target.value) }}
          />
        </div>}
        <div className={style.modalText}>
          <p>
            {props.renderMsg ? props.renderMsg : 'Are you sure you want to delete'}
            <span className={style.fontBold}> {props.msg} </span> ?
          </p>
        </div>
      </Modal>
    </div>
  )
}
export default DeletePopUp
