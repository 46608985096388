import {
  baseUrl,
  FeedBackUrl,
  singleFeedBackUrl,
  employeeFeedBackUrl,
} from '@api/const'
import { BaseApi } from '../baseApi'
import { notification } from '@utils/notifications'

class FeedbackApi extends BaseApi {
  createFeedback = async data => {
    try {
      const response = await this.axios.post(FeedBackUrl, data)
      return response.data
    } catch (error) {
        notification.error(error.response.data.message || error.response.data.detail || 'Fail to Create Feedback')
    }
  }

  setLeadToEmpFeedback = async data => {
    try {
      const response = await this.axios.post(employeeFeedBackUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  
  getLeadToEmpFeedback = async id => {
    try {
      const response = await this.axios.get(`${employeeFeedBackUrl}?employee_id=${id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateLeadToEmpFeedback = async (id, data) => {
    try {
      const response = await this.axios.put(`${employeeFeedBackUrl}/${id}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  loadLeadFeedback = async (num = 1, pageSize) => {
    try {
      const response = await this.axios.get(
        `${baseUrl}feedback/?page=${num}&page_size=${pageSize}`
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  loadLeadSingleFeedback = async () => {
    try {
      const response = await this.axios.get(singleFeedBackUrl)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default FeedbackApi
