export const ADD_EXPENSE = 'Add Expense'
export const EXPENSE_APPROVAL_MOD = 'expenses_approval'
export const EXPENSE_UPDATE = 'edit'
export const EXPENSE_READ = 'read'

export const EXPENSE_TAB = [
  {
    key: 'expense',
    tab: 'Expense',
  }
]
export const APPROVAL_TAB = [
  {
    key: 'approval',
    tab: 'Expense for Approval',
  },
]