import Tooltip from '@commonComponents/CustomToolTip'
import ShowOnPermission from '@commonComponents/permit'
import {
  faAddressCard,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStore } from '@stores/root-store'
import employeeName from '@utils/employee_name'
import { Rate, Switch } from 'antd'
import { colors } from 'constant/colors'
import {
  EMPLOYEE_MOD,
  EMPLOYEE_ROLE,
  EMPLOYMENT,
  // NOT_ASSIGNED,
  STATUS,
  VIEW_ALL,
} from 'constant/constant'
import moment from 'moment'
import { useEffect } from 'react'
import { MdOutlineHistory } from 'react-icons/md'
import styled from 'styled-components'
import RoleSelect from '@commonComponents/roleDropdown/roleDropdown'
import SupervisorDropdown from '@commonComponents/roleDropdown/supervisorDropdown'
import ShiftDropdown from '@commonComponents/roleDropdown/shiftDropdowm'
import EmploymentDropdown from '@commonComponents/roleDropdown/employmentDropdown'
import { CopyText } from '@commonComponents/CopyText'
import { permit } from '@utils/permission/permission'

const { TealishBlue } = colors

export const getColumns = (
  getcompany,
  setshowFeedBackDrawer,
  onStatusChange,
  onSupervisorChange,
  onShiftChange,
  onRoleChange,
  setEmpRecord,
  onEmploymentChange,
  employeeHistoryModal,
  themeColor,
  getLeadRole,
  getEmpFields
) => {
  const {
    employmentCompany: { getEmploymentCompanyData, loadEmploymentCompany },
    employeeDetails: {
      loadAllEmployeeList,
      loadLeadShift,
      getLeadShift,
      loadEmployeeDetails,
      loadLeadRole,
    },
    userInfo: { data: userData, getRoles },
  } = useStore(null)
  const currentUserID = userData?.id
  const isAdmin = permit(getRoles, 'accessView', 'is_admin')
  const isLead = getcompany?.isLead

  useEffect(() => {
    if (isAdmin || isLead) {
      loadEmployeeDetails()
      loadLeadShift()
      loadAllEmployeeList()
      loadEmploymentCompany()
      loadLeadRole()
    }
  }, [isAdmin, isLead])


  const columns  =  [
    employeeName(
      () => null,
      () => null,
      'EmpTable'
    ),
    {
      title: <CenteredDiv>ID</CenteredDiv>,
      id: 2,
      name:'ID',
      render: (_, record) => <CenteredDiv>{record?.employeeId}</CenteredDiv>,
    },
    {
      title: <CenteredDiv>Contact Detail</CenteredDiv>,
      dataIndex: 'userEmail',
      name:'Contact Detail',
      id: 3,
      render: (text, record) => (
        <>
          <div className='gap-2 d-flex accordingToTheme'>
            <Tooltip minLength={18} title={text}>
              <div className='emailWidth d-flex align-items-center cursor-pointer'>
                <FontAwesomeIcon icon={faEnvelope} />
                <Email>{record?.user?.email ?? text ?? '-'}</Email>
              </div>
            </Tooltip>
            <CopyText text={text} tooltipTitle='Copy Email' copySuccesTooltip='Copied' />
          </div>
          {record?.userContactNumber && (
            <div
              className='emailWidth accordingToTheme'
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faPhone} /> {record?.userContactNumber}
            </div>
          )}
        </>
      ),
    },
    {
      title: <CenteredDiv>Joining Date</CenteredDiv>,
      dataIndex: 'joiningDate',
      name:'Joining Date',
      id: 4,
      render: text => (
        <CenteredDiv>
          {text ? moment(text).format('YYYY-MM-DD') : 'N/A'}
        </CenteredDiv>
      ),
    },

    {
      title: <CenteredDiv>{EMPLOYEE_ROLE}</CenteredDiv>,
      dataIndex: 'roles',
      name:'Employee Role',
      id: 5,
      render: (_, record) => {
        return record?.id === currentUserID ? (
          <CenteredDiv>{record?.roles?.[0]?.title || '-'}</CenteredDiv>
        ) : (
          <RoleSelect
            disabled={!record?.isActive}
            listHeight={125}
            callFrom={{ zIndex: '1' }}
            currentData={getLeadRole}
            initialValue={record?.roles?.[0]?.title}
            placeholder={'Please Select Employee Role'}
            onchange={value => onRoleChange(record, value)}
          />
        )
      },
    },

    {
      title: <CenteredDiv>Shift</CenteredDiv>,
      dataIndex: 'shiftTitle',
      name:'Shift',
      id: 6,
      render: (text, record) => {
        return record?.id === currentUserID ? (
          <CenteredDiv>{text || '-'}</CenteredDiv>
        ) : (
          <ShiftDropdown
            disabled={!record?.isActive}
            currentData={getLeadShift}
            onchange={value => onShiftChange(record, value)}
            initialValue={text}
          />
        )
      },
    },

    {
      title: <CenteredDiv>Supervisor</CenteredDiv>,
      dataIndex: 'supervisorFullName',
      name:'Supervisor',
      id:7,
      render: (text, record) => {
        return record?.id === currentUserID ? (
          <CenteredDiv>{text || '-'}</CenteredDiv>
        ) : (
          <SupervisorDropdown
            disabled={!record?.isActive}
            onchange={value => onSupervisorChange(record, value)}
            initialValue={text}
            record={record}
          />
        )
      },
    },
   isAdmin ? {
      title: <CenteredDiv>{EMPLOYMENT}</CenteredDiv>,
      dataIndex: ['employment_model'],
      name:'Employment',
      id: 8,
      render: (text, record) => {
        return (
          <EmploymentDropdown
            disabled={!record?.isActive}
            currentData={getEmploymentCompanyData}
            onchange={value => onEmploymentChange(record, value)}
            initialValue={record?.employmentTitle?.map(item => item?.title)}
          />
        )
      },
    } : null ,
    {
      title: <CenteredDiv>Rating</CenteredDiv>,
      id: 9,
      name:'Rating',
      render: (_, row) => (
        <div>
          <div className={`text-center d-flex justify-content-center`}>
            <Rate
              allowHalf
              disabled
              value={row?.avgRating ? row?.avgRating : 0}
            />
          </div>
        </div>
      ),
    },
    {
      title: <CenteredDiv>Action</CenteredDiv>,
      id: 10,
      name:'Action',
      render: (_, row) => (
        <ActionsDiv color={themeColor}>
         {isAdmin || isLead ?
          (<Tooltip placement='top' minLength={1} title={'Status'}>
          <div>
            <ShowOnPermission mod={EMPLOYEE_MOD} method={VIEW_ALL}>
              <StyledSwitch
                checked={row?.isActive}
                defaultChecked
                onClick={id => {
                  onStatusChange(row, id, STATUS)
                }}
                disabled={
                  row?.status === false || row?.id === getcompany?.employeeId || row?.id === currentUserID
                }
              />
            </ShowOnPermission>
          </div>
        </Tooltip>) : null
        }

          {(isAdmin || isLead) && (
            <Tooltip minLength={5} title={'Feedback'}>
              <Actionicons
                onClick={() => {
                  setEmpRecord(row)
                  setshowFeedBackDrawer && setshowFeedBackDrawer(true)
                }}
                color={themeColor}
              >
                <FontAwesomeIcon icon={faAddressCard} color={themeColor} />
              </Actionicons>
            </Tooltip>
          )}

          <Tooltip title='Employee History'>
            <Actionicons color={themeColor}>
              <MdOutlineHistory
                onClick={() => {
                  employeeHistoryModal(row.id), setEmpRecord(row)
                }}
                color={themeColor}
              />
            </Actionicons>
          </Tooltip>
        </ActionsDiv>
      ),
    },
  ]

  const data = columns.map(col=>getEmpFields?.[0]?.fields?.employeeFiled?.find(item=>item?.title === col?.name)?.isActive ? col : false).filter(Boolean);
  return data?.length ? data : columns.filter(Boolean);
}

const Email = styled.div`
  margin-left: 8px !important;
`
const ActionsDiv = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center;
  gap: 10px;

  .ant-switch-checked {
    background-color: ${props => props?.color || TealishBlue} !important;
  }
`

// const FlexCenterDiv = styled.div`
//   width: 100% !important;
//   display: flex;
//   justify-content: center;
// `

const Actionicons = styled.div`
  filter: brightness(0.5);
  border-radius: 32px;
  display: flex;
  margin: 0;
  &:hover {
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;
    color: ${props => props?.color || TealishBlue} !important;
  }
`
const StyledSwitch = styled(Switch)`
  .ant-switch-inner {
    span {
      svg {
        color: white !important;
      }
    }
  }
`

export const CenteredDiv = styled.div`
  text-align: center;
`
