import { faBarsProgress, faChartLine, faGear, faUsersSlash, faUsers, faRightToBracket, faStar, faCodeBranch, faPeopleArrows, faFolderTree, faCheckToSlot, faMoneyBill, faDiagramProject, faCreditCard , faUser, faCube, faBuilding, faHouseLaptop, faPuzzlePiece, faCalendarPlus, faDollyBox, faUserClock, faPaperPlane, faUserXmark, faPersonWalkingArrowRight, faListCheck} from '@fortawesome/free-solid-svg-icons'

export const MenuIcon = {
  assets: 'assets',
  allowance: 'allowance',
  employee: 'employee',
  attendance: 'attendance',
  branch: 'branch',
  categoies: 'categoies',
  clearance: 'clearance',
  client: 'client',
  departments: 'departments',
  designations: 'designations',
  eCanteen: 'eCanteen',
  expenses: 'expenses',
  generalSettings: 'generalSettings',
  grade: 'grade',
  leads: 'leads',
  leaveApplication: 'leaveApplication',
  loan: 'loan',
  payroll: 'payroll',
  project: 'project',
  resignation: 'resignation',
  rolePermission: 'rolePermission',
  section: 'section',
  shift: 'shift',
  termination: 'termination',
  dashboard: 'dashboard',
  employeeHistory: 'employeeHistory',
  feedback: 'feedback',
  onBoarding: 'employonboarded',
  reports:'section',
  assetHandling: 'assetHandling',
}


export const customIcon ={
  assets: faCube,
  allowance: faCreditCard,
  employee: faUser,
  attendance: faCalendarPlus,
  branch: faCodeBranch,
  categoies: faDiagramProject,
  clearance: faCheckToSlot,
  client: faPeopleArrows,
  departments: faBuilding,
  designations: faHouseLaptop,
  eCanteen: faDollyBox,
  expenses: faMoneyBill,
  generalSettings: faGear,
  grade: faStar,
  leads: faUsers,
  leaveApplication: faPaperPlane,
  loan: faBarsProgress,
  payroll: faListCheck,
  project: faFolderTree,
  resignation: faPersonWalkingArrowRight,
  rolePermission: faUsersSlash,
  section: faPuzzlePiece,
  shift: faUserClock,
  termination: faUserXmark,
  dashboard: faChartLine,
  employeeHistory: faUser,
  feedback: faUser,
  employonboarded: faRightToBracket,
  assetHandling: faUser,
}