import React, { useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import style from './style.module.scss'
import {
  INITIAL_VALUES,
} from '@mainPage/rolePermission/constants'

interface RoleFormProps {
  update?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<{
    isOpen: boolean;
    isUpdate: boolean;
  }>>;
  data?: { 
    id: string;
    title: string;
   };
}

const EmployeeRoleForm = observer(({ update, data, setVisible }: RoleFormProps) => {
  const [form] = Form.useForm()
  
  const {
    employeeRole: { loadEmployeeRole, updateEmployeeRole, setEmployeeRole },
  } = useStore(null)

  useEffect(() => {
    form.resetFields()
      form.setFieldsValue({
        title: data?.title || '',
      })
  }, [data])

  const submitData = async (formData: { title: string }) => {
    let response = null
    if (update) {
      response = await updateEmployeeRole(data?.id, formData)
    } else {
      response = await setEmployeeRole(formData)
    }
    if (response?.success) {
      setVisible(INITIAL_VALUES)
      form.resetFields()
      await loadEmployeeRole(true)
    }
  }


  return (
      <Form
        form={form}
        onFinish={submitData}
        layout='vertical'
        className={style.addRule}
        id='role-form'
      >
        <Row className='rowDiv'>
          <Col span={24}>
            <Form.Item
              name='title'
              label='Role Name'
              rules={[
                {
                  required: true,
                  message: 'Role is required',
                },
                {
                  max: 20,
                  message: 'Ensure this field has no more than 20 characters',
                },
                {
                  whitespace: true,
                  message: 'Can\'t be just whitespaces',
                },
              ]}
            >
              <CommonInput
                className='inputField'
                inputType='text'
                placeholder='Please Select Role'
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
  )
})

export default EmployeeRoleForm
