export const validateRequired = msg => {
    return { required: true, message: `${msg} is required` }
}

export const validateNoWhiteSpace = () => {
  return { whitespace: true, message: `Can't be just whitespace.` }
}

export const emailValidationRule = () => {
  return { type: 'email', message: 'Email is invalid' }
}
  
export const getAlphabetRegex = () => new RegExp(/^(?!\s)[A-Za-z\s]+$/)

export const validateMinimumLength = (fieldName: string, minLength: number) => {
  return {
    message: `${fieldName} should have at least ${minLength} characters`,
    validator: (_, value) => {
      return value.length >= minLength ? Promise.resolve() : Promise.reject()
    }
  }
}
