import { flow, types, cast } from 'mobx-state-tree'
import { empClearanceDataApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'


export const empclearance = types.model({
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  employee: types.maybeNull(types.string),
})

export const empClearanceModal = types
  .model({
    data: types.maybeNull(types.array(empclearance)),
    loading: types.optional(types.boolean, false),
    nextEmp: types.optional(types.number, 1),
  })
  .views(self => ({
    get getempclearancedata() {
      const newData = self.data?.map(item => {
        return {
          firstName: item?.firstName || '',
          lastName: item?.lastName || '',
          id: item?.employee
        }
      }) || []
      return toJS(newData)
    },
    get isLoading() {
      return self.loading
    },
  }))

  .actions(self => {
    const loadEmpClearance = flow(function* (queryParam = '', resetList = true) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
        }
        const res = yield empClearanceDataApi.getEmpClearance(queryParam)
        self.data = cast(res.employee ?? [])
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load attendance')
        } return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      loadEmpClearance
    }
  })
export function initempClearanceModal() {
  return empClearanceModal.create({})
}