import { BaseApi } from '../baseApi'
import { remoteLeaveHistoryUrl } from '../const'

class EmployeeRemoteLeaveHistoryApi extends BaseApi {
  getEmployeeRemoteLeaveHistory = async num => {
    try {
      const response = await this.axios.get(
        `${remoteLeaveHistoryUrl}?page=${num ? num : 1}`,
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeRemoteLeaveHistoryApi
