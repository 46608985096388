import { BaseApi } from '../baseApi'
import { assetCategoryUrl } from '../const/index'

class assetCategoryApi extends BaseApi {
  setAssetCategory = async data => {
    try {
      const response = await this.axios.post(assetCategoryUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getAssetCategory = async (num ,pageSize, queryParam={}) => {
    try {
      const url = `${assetCategoryUrl}?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {}
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateAssetCategory = async (id, data) => {
    try {
      const response = await this.axios.patch(
        `${assetCategoryUrl+id}/`,
        {
          department_name: data.categoryName,
        },
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default assetCategoryApi


