import React from 'react'
import { Avatar } from 'antd'
import styled from 'styled-components'

interface DisplayPictureProps {
  src?: string;
  size?: number;
  style?: React.CSSProperties;
  name: string;
  passingFrom?: string;
}

const DisplayPicture: React.FC<DisplayPictureProps> = ({
  src,
  size,
  style,
  name,
  passingFrom,
}) => {
    return (
      <StyledNameAvatar src={src} size={size} style={style} passingFrom={passingFrom}>
        {name?.length > 1 ? name[0].toUpperCase() : name}
      </StyledNameAvatar>
    )
}

export default DisplayPicture

const StyledNameAvatar = styled(Avatar)`
  min-width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: black !important;
    line-height: ${props =>
    props.passingFrom === 'header' ? 'auto' : '30px'} !important;
  }
`