/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseApi } from '../baseApi'
import { assetCsvUrl, assetUrl, assetPdfUrl, assetApprovalPdf, assetApprovalCsv } from '../const/index'

class AssetsApi extends BaseApi {
  setAssets = async data => {    
    try {
      const response = await this.axios.post(assetUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getAssets = async (page ,size, queryParam={}) => {
    try {
      const url = `${assetUrl}?page=${page || 1}&size=${size}`;
      const response = await this.axios.get(url, { params: queryParam });
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  exportAssetRecord = async (queryParam = {}, format = '') => {
    try {
      let response: any;
      if (format === 'csv') {
        response = await this.axios.get(
          assetCsvUrl,{params: queryParam ? { ...queryParam } : {}}
        )
      } else if (format === 'pdf') {
        response = await this.axios.get(
          assetPdfUrl,{params: queryParam ? { ...queryParam } : {}}
        )
      }
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }


  exportAssetApprovalRecord = async (queryParam = {}, format = '') => {
    try {
      let response: any;
      if (format === 'csv') {
        response = await this.axios.get(
          assetApprovalCsv,{params: queryParam ? { ...queryParam } : {}}
        )
      } else if (format === 'pdf') {
        response = await this.axios.get(
          assetApprovalPdf,
          {params: queryParam ? { ...queryParam } : {}}
        )
      }
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getAssetsUser = async (num ,pageSize, queryParam={}) => {
    try {
      const url = `${assetUrl}/?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,{params: queryParam ? {...queryParam} : {}}
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
 
  updateAssets = async (id, data) => {
    try {
      const response = await this.axios.put(`${assetUrl}${id}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default AssetsApi
