import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { SearchOutlined } from '@ant-design/icons'
import { CommonSelect } from '@commonComponents/select'
import SearchFilter from '@commonComponents/SearchFilter'
import { STATUS_LIST } from './constants'

const SearchOnboarding = observer(props => {
  return (
    <div className='row filter-row'>
      <SearchFilter
        {...props}
        children={
          <div className='d-flex flex-wrap w-100'>
            <Row gutter={16} className='rowDiv searchForm flex-grow-1'>
              <Col xs={24} sm={12} lg={7} >
                  <Form.Item name={'name'}>
                    <CommonInput
                      className = {'searchInput'}
                      placeholder='Name'
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={7} >
                <Form.Item name='onboard_status'>
                  <CommonSelect
                    listHeight={120}
                    placeholder='Status'
                    size='large'
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    data={STATUS_LIST}
                  />
                </Form.Item>
              </Col>
            </Row>

          </div>} />
    </div>
  )
})
export default SearchOnboarding