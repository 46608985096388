import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { projectsClientsApi } from '@api'
import { notification } from '@utils/notifications'

export const projectsClients = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName:types.maybeNull(types.string),
  company: types.maybeNull(types.number),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  employeeImage:types.maybeNull(types.string)

})

export const clientData = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  mobileNumber: types.maybeNull(types.number)
})

export const leadData = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})

export const projectTableData = types.model({
  id: types.maybeNull(types.string),
  branch:types.maybeNull(types.number),
  client: types.maybeNull(clientData),
  company:types.maybeNull(types.number),
  contractType: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  priority: types.maybeNull(types.string),
  projectHours:types.maybeNull(types.number),
  projectId: types.maybeNull(types.string),
  projectLeader:types.maybeNull(types.array(leadData)),
  projectTeam: types.maybeNull(types.array(leadData)),
  rate: types.maybeNull(types.number),
  startDate:types.maybeNull(types.string),
  progress: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  user: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.string),
})
export const projectsClientsModal = types
  .model({
    data: types.maybeNull(types.array(projectTableData)),
    loading: types.optional(types.boolean, false),
    countproject: types.maybeNull(types.number),
    nextproject: types.optional(types.number, 1)
    
  })
  .views(self => ({
    get getProjectsClientsData() {
      return toJS(self.data)
    },
    get getclientData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadProjectsClient = flow(function* (page=1, pageSize=20, queryParam='', resetList=false) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextproject = 1
        }
        const res = yield projectsClientsApi.getProjectsClient(page , pageSize, queryParam)
        if (!res?.isCancel){
          self.data = res.results
          self.countproject = res.count
          if (res.next) {
            self.nextproject = self.nextproject + 1
          } else {
            self.nextproject = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Project and Client Detail')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })


    return {
      loadProjectsClient,
    }
  })

export function initProjectsClientsModal() {
  return projectsClientsModal.create({})
}