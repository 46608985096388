import React, { useState, useRef, useMemo } from 'react'
import { Space } from 'antd'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import DeletePopUp from '@commonComponents/delete-popup'
import DesignationForm from './designationForm'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import CustomButton from '@commonComponents/button'
import { Table } from '@commonComponents/table-v2'
import { designationUrl } from '@api/const'
import CustomDrawer from '@commonComponents/drawer'
import Searchdesignation from './Searchdesignation'
import getColumns from './columns'
import { ADD, DESIGNATION, DELETE_DESIGNATION, UPDATE, UPDATE_DESIGNATION, ADD_DESIGNATION, CANCEL, DESIGNATION_MOD, POST_METHOD, VIEW_ALL } from 'constant/constant'
import ShowOnPermission from '@commonComponents/permit'



const Designations = observer(() => {
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [message, setMessage] = useState('')
  const [visible, setVisible] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [statusLoader, setStatusLoader] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const tableRef = useRef(null)
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const [resetTable, setResetTable] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [queryParam, setQueryParam] = useState()
  const {
    designationModal: {
      updateDesignation,
      deleteDesignation,
      isLoading,
    },
    userInfo: { getRoles },
    themesModel: { getColorsData }
  } = useStore(null)

  const onEdit = data => {
    setCurrentData(data)
    setIsUpdate(true)
    setVisible(true)
  }

  const onStatusChange = async (data, check) => {
    const designFor = (data?.company?.length && 'company') ||
    (data?.branch?.length && 'branch') ||
    (data?.department?.length && 'department') ||
    (data?.section?.length && 'section') 
    const payload={
      title:data?.title,
      designation_for:designFor,
      object_id:data?.objectId,
      is_active:check
    }
    let response = null
    setCurrentData(data)
    setStatusLoader(true)
    response = await updateDesignation(data.id, payload )
    if (response?.success) {
      setRefreshTable(true)
      setStatusLoader(false)
    }
  }

  const onDelete = data => {
    setMessage(data?.title)
    setCurrentData({ id: data?.id })
    setVisibleDelete(true)
  }

  const onAdd = () => {
    setCurrentData(null)
    setIsUpdate(false)
    setVisible(true)
  }

  const deleteDesignationHandler = async () => {
    const response = await deleteDesignation(currentData?.id)
    if (response?.success) {
      setMessage('')
      if (totalRecordRemaining == 1) {
        setResetTable(true)
      } else {
        setRefreshTable(true)
      }
    }
    setVisibleDelete(false)
  };
  const columns = useMemo(() => getColumns(getRoles, currentData, onDelete, onEdit, statusLoader, onStatusChange,getColorsData), [currentData, statusLoader])
  return (
    <div className='page-wrapper'>
        {/* Page Content */}
      <div className='content container-fluid designationTable'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='d-flex justify-content-between'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Designation', link: constRoute.designations },
              ]}
              pageTitle={DESIGNATION}
              children={''}
            />
            <div>
              <ShowOnPermission mod={DESIGNATION_MOD} method={POST_METHOD} >
              <CustomButton
                onClick={onAdd}
                title={ADD_DESIGNATION}
              />
              </ShowOnPermission>

            </div>
          </div>
        </div>
        <ShowOnPermission  mod={DESIGNATION_MOD} method={VIEW_ALL}>
          <Searchdesignation
            setQueryParam={setQueryParam}
          />
        </ShowOnPermission>
        {/* /Page Header */}
        <div className='table-responsive'>
          <Table
            ref={tableRef}
            dataUrl={designationUrl}
            columns={columns}
            queryParam={queryParam}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            resetTable={resetTable}
            setResetTable={setResetTable}
            setTotalRecordRemaining={setTotalRecordRemaining}
          />
        </div>
      </div>
      {/* /Page Content */}
      <CustomDrawer
        title={isUpdate ? UPDATE_DESIGNATION : ADD_DESIGNATION}
        setVisible={setVisible}
        visible={visible}
        className='addDrawer'
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={CANCEL}
              className='cancelButton'
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='designation-form'
              htmlType='submit'
              type='primary'
              title={isUpdate ? UPDATE : ADD}
              loading={btnLoader}
              disabled={btnLoader}
              className='addButton'
            />
          </Space>
        }
      >
        <DesignationForm
          update={isUpdate}
          data={currentData}
          setBtnLoader={setBtnLoader}
          setVisible={setVisible}
          setRefreshTable={setRefreshTable}
        />
      </CustomDrawer>
      {/* Delete Designation Modal */}
      <DeletePopUp
        delete={deleteDesignationHandler}
        msgTitle={DELETE_DESIGNATION}
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
        msg={message}
      />
    </div>
  )
})

export default Designations
