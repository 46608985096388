import { toJS } from 'mobx'
import { cast,flow, types } from 'mobx-state-tree'
import { loanApi } from '../../api'
import { notification } from '@utils/notifications'



export const laonInfo = types.model({
  id: types.maybeNull(types.string),
  employee: types.maybeNull(types.string),
  employeeName: types.maybeNull(types.string),
  loanName: types.maybeNull(types.string),
  applyDate: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  installmentStartDate: types.maybeNull(types.string),
  loanDisbursementDate: types.maybeNull(types.string),
  status: types.maybeNull(types.boolean),
  noOfInstalments: types.maybeNull(types.number),
  loanAmount: types.maybeNull(types.number),
  loanId: types.maybeNull(types.string)
})

export const laonDetails = types
  .model({
    laonInfo: types.maybeNull(types.array(laonInfo)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextCli: types.optional(types.number, 1),
    filter: types.maybeNull(types.string),
  })
  .views(self => ({
    get getLoanInfo() {
      return toJS(self.laonInfo)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setLoanDetails = flow(function* (data) {
      try {
        self.loading = true
        yield loanApi.setLoanDetails(data)
        notification.success('Laon Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      } 
    })
    const getLoanDetails = flow(function* (page=1, pageSize=20, queryParam='', resetList=false) {
      self.loading = true
      try {
        self.loading = true
        if (resetList) {
          self.laonInfo = cast([])
          self.nextCli = 1
        }
        const res = yield loanApi.getLoanDetails(page , pageSize, queryParam)
        if (!res?.isCancel){
          self.laonInfo = cast([...toJS(self.laonInfo ?? []), ...res.results])
          self.count = res.count
          if (res.next) {
            self.nextCli = self.nextCli + 1
          } else {
            self.nextCli = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error('Failed to Load Loans')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    
    const updateLoanDetails = flow(function* (data, id) {
      try {
        self.loading = true
        yield loanApi.updateLoanDetails(data,id)
        notification.success('Loan Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteLoanDetails = flow(function* (id) {
      try {
        self.loading = true
        yield loanApi.deleteLoanDetails(id)
        notification.success('Loan Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setLoanDetails,
      getLoanDetails,
      updateLoanDetails,
      deleteLoanDetails,
    }
  })

export function initLoanDetails() {
  return laonDetails.create({})
}
