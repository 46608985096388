import React from 'react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { POST_METHOD, ROLE, ROLE_BREADCRUMBS } from '../../../constant/constant'
import CustomButton from '@commonComponents/button'
import {INITIAL_VALUES} from '@mainPage/rolePermission/constants';
import style from '@mainPage/rolePermission/style.module.scss';
import ShowOnPermission from '@commonComponents/permit';


function RolePermissionHeader({setDesVisible}) {
  return (
    <div className='page-header'>
      <div className={style.page}>
        <BreadCrumbs
          list={ROLE_BREADCRUMBS}
          pageTitle={'Role & Permissions'}
          children={''}
        ></BreadCrumbs>
          <ShowOnPermission mod={ROLE} method={POST_METHOD}>
            <CustomButton
              onClick={() => {
                setDesVisible({...INITIAL_VALUES, isOpen: true })
              }}
              title='Add Role'
            />
        </ShowOnPermission>
      </div>
    </div>
  )
}

export default RolePermissionHeader