import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { useMemo } from 'react'
import { initSigninInfo, signinInfo } from './singin/Signin'
import { initSignupInfo, signupInfo } from './signup/signup'
import { initClientDetails, clientDetails } from './client/Client'
import { initLoanDetails, laonDetails } from './Loan/loan'
import { initClientTypeModal, clientTypeModal } from './client/ClientType'
import { initLanguageModal, languageModal } from './language/Language'
import { initEmployeeDetails, employeeDetails } from './employee/Employee'
import {
  initEmployeesOpenList,
  employeesOpenList,
} from './employee/EmployeesOpenList'
import { initDashBoardModal, DashBoardModel } from './dashboard/Dashboard'
import { initprojectsModel, projectsModel } from './projects/projects'
import { initassetsModel, assetsModel } from './asset/asset'
import { initAllowanceModel, allowanceModel } from '@stores/Allowance'
import { initThemeModel, themesModel } from './theme/theme'
import {
  initterminationModel,
  terminationModel,
} from './termination/termination'
import {
  initresignationModel,
  resignationModel,
} from './resignation/resignation'
import {
  initassetCategoryModel,
  assetCategoryModel,
} from './asset/assetCategory'
import { clearanceModel, initClearanceModel } from './clearance/clearance'
import { initEmployeePayroll, employeePayroll } from '@stores/Payroll'
import { initClientLogsModel, clientLogsModel } from './client/ClientLogs'
import {
  initProjectSalaryModal,
  projectLanguageModal,
} from './projects/ProjectLanguage'
import { initTeamMembersModal, teamMembersModal } from './team/teamMembers'
import { initRoleModal, roleModal } from './team/role'
import { teamsModal, initTeamsModal } from './team/teams'
import { userRoleDetails, initUserRoleDetails } from './user/userRole'
import { userInfoModal, userInfoModalStore } from './user'
import { initReportsDetails, reportsDetails } from './reports/reports'
import { boardDetails, initBoardDetails } from './board/boatfDetail'
import { boardHomeDetails, initBoardHomeDetails } from './board/boardHome'
import { departmentModal, initDepartmentModal } from './department/Department'
import {
  designationModal,
  initDesignationModal,
} from './designation/Designation'
import { initHRExpenseModal, hrExpenseModal } from './hr/HRExpenses'
import { initLead, leadsList } from './leads/Leads'
import { hrCategoryModal, initHRCategoryModal } from './hr/HRCategories'
import {
  employeeLeaveModal,
  initEmployeeLeaveModal,
} from './employeeLeave/EmployeeLeave'
import {
  initEmployeeEducationDetails,
  educationDetails,
} from './employeeProfile/Education'
import {
  initEmployeeExperienceDetails,
  experienceDetails,
} from './employeeProfile/Experience'
import {
  initEmployeeFamilyDetails,
  familyDetails,
} from './employeeProfile/Family'
import {
  initEmployeeProfileDetails,
  profileDetails,
} from './employeeProfile/Profile'
import { initEmployeeBankDetails, bankDetails } from './employeeProfile/Bank'
import {
  initEmployeePersonalDetails,
  personalDetails,
} from './employeeProfile/Personal'
import {
  initEmployeeEmergencyDetails,
  emergencyDetails,
} from './employeeProfile/Emergency'

import { employeeRoles, initEmployeeRole } from './role/role'
import { clientProfileDetails, initClientProfile } from './client/clientProfile'
import { emailModal, initEmail } from './forgotPassword/forgotPassword'
import { passwordModal, initPassword } from './forgotPassword/changePassword'
import {
  employeeLeaveTypeModal,
  initEmployeeLeaveTypeModal,
} from './employeeLeave/employeeLeaveType'
import {
  employeeLeaveStatsModal,
  initEmployeeLeaveStatsModal,
} from './dashboard/employeeLeaveStats'
import {
  dashboardEmployeePayrollModal,
  initDashboardEmployeePayrollModal,
} from './dashboard/dashboardEmployeePayroll'
import { assetStatsModal, initAssetStatsModal } from './dashboard/assetStats'
import {
  employeeLeaveCancellationModal,
  initEmployeeLeaveCancellationModal,
} from './employeeLeave/employeeLeaveCancellation'
import {
  employeeStatsModal,
  initEmployeeStatsModal,
} from './dashboard/employeeStats'
import {
  expenseDashboardModal,
  initExpenseDashboardModal,
} from './dashboard/expenseDashboard'
import {
  projectsClientsModal,
  initProjectsClientsModal,
} from './dashboard/projects'
import {
  initEmployeeCertificateDetails,
  certificateDetails,
} from './employeeProfile/Certification'
import {
  initProfileProjectsDetails,
  profileProjects,
} from './employeeProfile/ProfileProjects'
import {
  initEmployeeLeaveHistoryModal,
  employeeLeaveHistoryModal,
} from './employeeLeave/employeeLeaveHistory'
import {
  initEmployeeLeaveApprovalModal,
  employeeLeaveApprovalModal,
} from './employeeLeave/employeeLeaveApproval'
import { initUserLevelModal, userLevelModal } from './userLevel/userLevel'
import {
  employeeLeaveCountModal,
  initEmployeeLeaveCountModal,
} from './employeeLeave/employeeLeaveCount'

import { gradeDetails, initGradeDetails } from './grade/grade'
import { sectionModal, initSectionModal } from './section/section'
import {
  employeeCancelLeaveApprovalModal,
  initEmployeeCancelLeaveApprovalModal,
} from './employeeLeave/employeeCancelLeaveApprove'
import { branchModel, initBranchModel } from './branch/Branch'
import { punchModal, initPunchModal } from './attendance/punch'
import { attendanceModal, initAttendanceModal } from './attendance/attendance'
import {
  attendancePunchModal,
  initAttendancePunchList,
} from './attendance/attendanceList'
import {
  singlePunchModal,
  initSinglePunchModal,
} from './attendance/getSinglePunch'
import {
  attendanceStatsModal,
  initAttendanceStatsModal,
} from './attendance/attendanceStats'
import { totalCountModel, initEmployeeCount } from './dashboard/employeeCount'
import { shiftsModal, initShiftModal } from './shift/Shift'
import { serialNumberModel, initSerialNumberModel } from './asset/serialNumber'
import {
  eCanteenMenuModal,
  initeCanteenMenuModal,
} from './eCanteen/eCanteenMealMenu'
import {
  dashboardAttendanceModal,
  initdashboardAttendanceModal,
} from './dashboardAttendance/dashboardAttendanceOverview'
import {
  empClearanceModal,
  initempClearanceModal,
} from './EmpClearanceStore/empClearanceStore'
import { assetLeads, initLeadDetails } from './assetsleads/assetLeads'
import {
  employeeHistory,
  initEmployeeHistory,
} from './employee/EmployeeHistory'
import {
  feedbackLeadQuestionModal,
  initFeedbackLeadQuestionModal,
} from './Feedback/leadQuestionFeedback'
import { feedbackModal, initFeedbackModal } from './Feedback/Feedback'
import {
  employmentCompany,
  initEmploymentCompany,
} from './employmentCompany/employmentCompany'
import {
  initNotificationOms,
  notificationOms,
} from './notification/notification'
import {
  initExpenseAmountModal,
  totalExpenseAmountModal,
} from './hr/TotalExpenseAmount'
import { initonboardingModal, onboardingModal } from './onboarding/onboarding'
import {
  assetAttributeModel,
  initassetAttributeModel,
} from './asset/assetAttribute'
import {
  initonboardingHistoryModal,
  onboardingHistoryModal,
} from './onboarding/onboardingHistory'
import {
  initEmployeeRemoteLeaveModal,
  employeeRemoteLeaveModal,
} from './employeeLeave/employeeRemoteLeave'
import {
  initEmployeeRemoteCancelLeaveApprovalModal,
  employeeRemoteCancelLeaveApprovalModal,
} from './employeeLeave/employeeRemoteCancelLeaveApproval'
import {
  employeeRemoteLeaveHistoryModal,
  initEmployeeRemoteLeaveHistoryModal,
} from './employeeLeave/employeeRemoteLeaveHistory'
import {
  assetHandlingModal,
  initassetHandlingModal,
} from './assetHandling/assetHandling'
import {
  initNewDashboardModal,
  newDashboardModal,
} from './dashboard/NewDashboardStore'
import { initWorkStatsModel, WorkStatsModel } from './WorkTimeStatics/workTimeStats'

const RootStore = types
  .model({
    feedbackLeadQuestionModal: feedbackLeadQuestionModal,
    onboardingModal: onboardingModal,
    newDashboardModal: newDashboardModal,
    onboardingHistoryModal: onboardingHistoryModal,
    feedbackModal: feedbackModal,
    signupInfo: signupInfo,
    signinInfo: signinInfo,
    clientDetails: clientDetails,
    laonDetails: laonDetails,
    boardDetails: boardDetails,
    boardHomeDetails: boardHomeDetails,
    clientTypeModal: clientTypeModal,
    languageModal: languageModal,
    employeeDetails: employeeDetails,
    employeesOpenList: employeesOpenList,
    reportsDetails: reportsDetails,
    projectsModel: projectsModel,
    assetsModel: assetsModel,
    assetAttributeModel: assetAttributeModel,
    allowanceModel: allowanceModel,
    themesModel: themesModel,
    terminationModel: terminationModel,
    resignationModel: resignationModel,
    assetCategoryModel: assetCategoryModel,
    employeePayroll: employeePayroll,
    clientLogsModel: clientLogsModel,
    projectLanguageModal: projectLanguageModal,
    userInfo: userInfoModal,
    teamMembersModal: teamMembersModal,
    roleModal: roleModal,
    teamsModal: teamsModal,
    userRoleDetails: userRoleDetails,
    hrExpenseModal: hrExpenseModal,
    totalExpenseAmountModal: totalExpenseAmountModal,
    hrCategoryModal: hrCategoryModal,
    departmentModal: departmentModal,
    designationModal: designationModal,
    employmentCompany: employmentCompany,
    leadsList: leadsList,
    employeeLeaveModal: employeeLeaveModal,
    educationDetails: educationDetails,
    experienceDetails: experienceDetails,
    profileDetails: profileDetails,
    bankDetails: bankDetails,
    emergencyDetails: emergencyDetails,
    personalDetails: personalDetails,
    familyDetails: familyDetails,
    employeeRole: employeeRoles,
    clientProfileDetails: clientProfileDetails,
    emailModal: emailModal,
    passwordModal: passwordModal,
    certificateDetails: certificateDetails,
    profileProjects: profileProjects,
    employeeLeaveTypeModal: employeeLeaveTypeModal,
    employeeLeaveHistoryModal: employeeLeaveHistoryModal,
    employeeLeaveApprovalModal: employeeLeaveApprovalModal,
    userLevelModal: userLevelModal,
    employeeLeaveCountModal: employeeLeaveCountModal,
    gradeDetails: gradeDetails,
    sectionModal: sectionModal,
    branchModel: branchModel,
    punchModal: punchModal,
    employeeLeaveCancellationModal: employeeLeaveCancellationModal,
    attendanceModal: attendanceModal,
    attendancePunchModal: attendancePunchModal,
    employeeCancelLeaveApprovalModal: employeeCancelLeaveApprovalModal,
    attendanceStatsModal: attendanceStatsModal,
    totalCountModel: totalCountModel,
    shiftsModal: shiftsModal,
    employeeLeaveStatsModal: employeeLeaveStatsModal,
    assetStatsModal: assetStatsModal,
    dashboardEmployeePayrollModal: dashboardEmployeePayrollModal,
    expenseDashboardModal: expenseDashboardModal,
    projectsClientsModal: projectsClientsModal,
    employeeStatsModal: employeeStatsModal,
    serialNumberModel: serialNumberModel,
    clearanceModel: clearanceModel,
    eCanteenMenuModal: eCanteenMenuModal,
    dashboardAttendanceModal: dashboardAttendanceModal,
    singlePunchModal: singlePunchModal,
    empClearanceModal: empClearanceModal,
    assetLeads: assetLeads,
    employeeHistory: employeeHistory,
    notificationOms: notificationOms,
    employeeRemoteLeaveModal: employeeRemoteLeaveModal,
    employeeRemoteCancelLeaveApprovalModal:
      employeeRemoteCancelLeaveApprovalModal,
    employeeRemoteLeaveHistoryModal: employeeRemoteLeaveHistoryModal,
    assetHandlingModal: assetHandlingModal,
    DashBoardModel: DashBoardModel,
    WorkStatsModel: WorkStatsModel,
  })
  .actions(self => {
    let initialState = {}
    return {
      afterCreate: () => {
        initialState = getSnapshot(self)
      },
      reset: () => {
        applySnapshot(self, initialState)
      },
    }
  })

export type RootStoreType = Instance<typeof RootStore>

let store: RootStoreType

export function resetStore() {
  store.reset()
}

export function initializeStore(snapshot = null) {
  const _store =
    store ??
    RootStore.create({
      feedbackLeadQuestionModal: initFeedbackLeadQuestionModal(),
      onboardingModal: initonboardingModal(),
      onboardingHistoryModal: initonboardingHistoryModal(),
      feedbackModal: initFeedbackModal(),
      signupInfo: initSignupInfo(),
      signinInfo: initSigninInfo(),
      clientDetails: initClientDetails(),
      laonDetails: initLoanDetails(),
      boardDetails: initBoardDetails(),
      boardHomeDetails: initBoardHomeDetails(),
      clientTypeModal: initClientTypeModal(),
      languageModal: initLanguageModal(),
      employeeDetails: initEmployeeDetails(),
      employeesOpenList: initEmployeesOpenList(),
      reportsDetails: initReportsDetails(),
      projectsModel: initprojectsModel(),
      assetsModel: initassetsModel(),
      allowanceModel: initAllowanceModel(),
      themesModel: initThemeModel(),
      assetCategoryModel: initassetCategoryModel(),
      assetAttributeModel: initassetAttributeModel(),
      terminationModel: initterminationModel(),
      resignationModel: initresignationModel(),
      employeePayroll: initEmployeePayroll(),
      clientLogsModel: initClientLogsModel(),
      projectLanguageModal: initProjectSalaryModal(),
      userInfo: userInfoModalStore,
      teamMembersModal: initTeamMembersModal(),
      roleModal: initRoleModal(),
      teamsModal: initTeamsModal(),
      userRoleDetails: initUserRoleDetails(),
      hrExpenseModal: initHRExpenseModal(),
      totalExpenseAmountModal: initExpenseAmountModal(),
      hrCategoryModal: initHRCategoryModal(),
      departmentModal: initDepartmentModal(),
      designationModal: initDesignationModal(),
      employmentCompany: initEmploymentCompany(),
      leadsList: initLead(),
      employeeLeaveModal: initEmployeeLeaveModal(),
      educationDetails: initEmployeeEducationDetails(),
      experienceDetails: initEmployeeExperienceDetails(),
      familyDetails: initEmployeeFamilyDetails(),
      profileDetails: initEmployeeProfileDetails(),
      bankDetails: initEmployeeBankDetails(),
      personalDetails: initEmployeePersonalDetails(),
      emergencyDetails: initEmployeeEmergencyDetails(),
      employeeRole: initEmployeeRole(),
      clientProfileDetails: initClientProfile(),
      emailModal: initEmail(),
      passwordModal: initPassword(),
      certificateDetails: initEmployeeCertificateDetails(),
      profileProjects: initProfileProjectsDetails(),
      employeeLeaveTypeModal: initEmployeeLeaveTypeModal(),
      employeeLeaveHistoryModal: initEmployeeLeaveHistoryModal(),
      employeeLeaveApprovalModal: initEmployeeLeaveApprovalModal(),
      userLevelModal: initUserLevelModal(),
      employeeLeaveCountModal: initEmployeeLeaveCountModal(),
      gradeDetails: initGradeDetails(),
      sectionModal: initSectionModal(),
      branchModel: initBranchModel(),
      punchModal: initPunchModal(),
      employeeLeaveCancellationModal: initEmployeeLeaveCancellationModal(),
      employeeLeaveStatsModal: initEmployeeLeaveStatsModal(),
      assetStatsModal: initAssetStatsModal(),
      attendanceModal: initAttendanceModal(),
      attendancePunchModal: initAttendancePunchList(),
      employeeCancelLeaveApprovalModal: initEmployeeCancelLeaveApprovalModal(),
      attendanceStatsModal: initAttendanceStatsModal(),
      employeeStatsModal: initEmployeeStatsModal(),
      totalCountModel: initEmployeeCount(),
      shiftsModal: initShiftModal(),
      dashboardEmployeePayrollModal: initDashboardEmployeePayrollModal(),
      expenseDashboardModal: initExpenseDashboardModal(),
      projectsClientsModal: initProjectsClientsModal(),
      serialNumberModel: initSerialNumberModel(),
      clearanceModel: initClearanceModel(),
      eCanteenMenuModal: initeCanteenMenuModal(),
      dashboardAttendanceModal: initdashboardAttendanceModal(),
      singlePunchModal: initSinglePunchModal(),
      empClearanceModal: initempClearanceModal(),
      assetLeads: initLeadDetails(),
      employeeHistory: initEmployeeHistory(),
      notificationOms: initNotificationOms(),
      employeeRemoteLeaveModal: initEmployeeRemoteLeaveModal(),
      employeeRemoteCancelLeaveApprovalModal:
        initEmployeeRemoteCancelLeaveApprovalModal(),
      employeeRemoteLeaveHistoryModal: initEmployeeRemoteLeaveHistoryModal(),
      assetHandlingModal: initassetHandlingModal(),
      newDashboardModal: initNewDashboardModal(),
      DashBoardModel: initDashBoardModal(),
      WorkStatsModel: initWorkStatsModel(),
    })
  if (snapshot) {
    applySnapshot(_store, snapshot)
  }
  if (typeof window === 'undefined') {
    return _store
  }

  if (!store) {
    store = _store
  }
  return _store
}

export function useStore(
  initialState: null | undefined
): Instance<typeof RootStore> {
  return useMemo(() => initializeStore(initialState), [initialState])
}
