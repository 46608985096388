import React, { useState, useEffect } from 'react'
import { Col, DatePicker, Form, Row, TimePicker } from 'antd' //Select
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import moment from 'moment'
import type { Moment } from 'moment'
import { SwapRightOutlined } from '@ant-design/icons'

import {
  LEAVE_DATE_IS_REQUIRED,
  LEAVE_TYPE_IS_REUIRED,
  PlASE_SELECT_TYPE_FIRST,
  TIME_IS_REQUIRED,
  SELECT_DATES_YOU_WANT_TO_CANCEL,
  LEAVE_DATES,
  REASON_IS_REQUIRED,
  DATE_FORMATE,
  TIME_FORMATE,
} from '../../const'
import { employeeLeaveApi } from '@api'
import { CommonSelect } from '@commonComponents/select'

export interface EmployeeLeaveProps {
  update: boolean;
  data?: {
    id: number;
    reason: string;
    leaveStartDate: string;
    leaveEndDate: string;
    leaveType: number;
    leaveTypeTitle: string;
    leaveTypeId: string;
    status: string;
    statusReason: string;
    employee: number;
    lead: number;
    approvedBy: number;
    rejectedBy: number;
    isFullLeave: boolean;
    fullCancelLeave: boolean;
    startDate: string;
    endDate: string;
    isHalfLeave?: string;
  };
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
  // setIsAddBtnDisable?: React.Dispatch<React.SetStateAction<boolean>>;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  counts?: any;
  reconsider?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  viewDetail: boolean;
  leaveApproval: boolean;
  isCancel?: boolean;
}

type RangeValue = [Moment | null, Moment | null] | null

const EmployeeLeaveForm = observer(
  ({
    update,
    data,
    setVisible,
    viewDetail,
    setRefreshTable,
    isCancel,
  }: EmployeeLeaveProps) => {
    //counts
    const [form] = Form.useForm()
    const [leaveDates, setLeaveDates] = useState([])
    const [updateLeaveDate, setUpdateLeaveDate] = useState({})
    const [arrayLeaveDate, setArrayLeaveDate] = useState([])
    const [text, setText] = useState('(Please Select Type First)')
    const [dates, setDates] = useState<RangeValue>(null)
    const [hackValue, setHackValue] = useState<RangeValue>(null)
    const [value, setValue] = useState<RangeValue>(null)
    const [selectDay, setSelectDay] = useState('')
    const [cancelText, setCancelText] = useState(false)
    const [leaveData, setLeaveData] = useState<any>([])

    const [selectedLeaves, setSelectedLeaves] = useState<any>({})

    const {
      employeeLeaveModal: {
        setEmployeeLeaveData,
        updateEmployeeLeave,
        halfCancelLeave,
      },
      employeeLeaveTypeModal: { getEmployeeLeaveTypeData },
    } = useStore(null)
    const { RangePicker } = DatePicker
    useEffect(() => {
      getPrevLeaveData()
    }, [])

    useEffect(() => {
      form.resetFields()
      setText(' (' + PlASE_SELECT_TYPE_FIRST + ')')
      if (update) {
        setText('')
        let type
        if (data.leaveTypeTitle) {
          type = data.leaveTypeId
        }
        if (data.status == 'pending') {
          form.setFieldsValue({
            reason: data.reason,
          })
        } else {
          setCancelText(true)
        }
        const leaveStartDate = data.startDate ? moment(data.startDate) : ''
        const leaveEndDate = data.endDate ? moment(data.endDate) : ''
        setLeaveDates([leaveStartDate, leaveEndDate])
        form.setFieldsValue({
          leave_dates: [leaveStartDate, leaveEndDate],
          leave_date: moment(data.startDate),
          time: [moment(data.startDate), moment(data.endDate)],
          leave_type_id: type,
          status: data.status,
          employee: data.employee,
        })
      } else if (isCancel) {
        form.setFieldsValue({
          leave_type: data.leaveType,
        })
      }
    }, [data, update])

    const submitData = async values => {
      let response = null
      if (selectedLeaves?.isHalfLeave || data?.isHalfLeave ) {
        values.start_date =
          moment(values.leave_date).format(DATE_FORMATE) +
          ' ' +
          moment(values.time[0]).format(TIME_FORMATE)
        values.end_date =
          moment(values.leave_date).format(DATE_FORMATE) +
          ' ' +
          moment(values.time[1]).format(TIME_FORMATE)
        delete values.leave_date
        delete values.time
      } else {
        values.start_date = moment(leaveDates[0]).format(
          'YYYY-MM-DD[T]00:00:00.00[Z]'
        )
        values.end_date = moment(leaveDates[1]).format(
          'YYYY-MM-DD[T]00:00:00.00[Z]'
        )
        delete values.leave_dates
      }

      if (isCancel) {
        const cancelData = {
          cancel_start_date: values.start_date,
          cancel_end_date: values.end_date,
          cancellation_reason: values.cancel_reason,
        }
        const res = await halfCancelLeave(data.id, cancelData)
        if (res?.success) {
          setVisible(false)
          setRefreshTable(true)
          form.resetFields()
        }
        return
      }

      if (update) {
        const updateData = {
          ...values,
          leave_id: data.id,
          reason: values.reason,
        }
        // eslint-disable-next-line
        const { leave_type_id, ...valuesUpdate } = updateData
        response = await updateEmployeeLeave(valuesUpdate)
      } else {
        response = await setEmployeeLeaveData({ ...values, is_remote: false })
      }

      // !response.success && setIsAddBtnDisable(true)
      if (response?.success) {
        setVisible(false)
        setRefreshTable(true)
        form.resetFields()
        setText(PlASE_SELECT_TYPE_FIRST)
      }
    }

    const onOpenChange = (open: boolean) => {
      if (open) {
        setHackValue([null, null])
        setDates([null, null])
      } else {
        Object.keys(updateLeaveDate).length > 0 &&
          setArrayLeaveDate([updateLeaveDate])
        setHackValue(null)
      }
    }

    const onOk = time => {
      if (!time[1]) {
        const hour = parseInt(moment(time[0]).format('HH'))
        const arr = []
        for (let i = 0; i < hour; i++) {
          arr.push(i)
        }
        const hour2 = hour + 4
        for (let j = hour2 + 1; j <= 24; j++) {
          arr.push(j)
        }
      }
      // setIsAddBtnDisable(false)
    }

    const disbaleTimeRange = current => {
      if (moment(selectDay).format('DD') === moment(current).format('DD')) {
        return moment().format('dd:HH') > moment(current).format('dd:HH')
      }
    }

    const getPrevLeaveData = async () => {
      const res = await employeeLeaveApi.getEmployeeLeave(1)
      setLeaveData(res.items)
    }

    const disableDate = current => {
      if (!dates) {
        return false
      }

      if (current && current._d) {
        const currentDate = current._d

        for (const leaveObj of leaveData) {
          if (leaveObj.status === 'approved') {
            const leaveStart = new Date(leaveObj.startDate)
            const leaveEnd = new Date(leaveObj.endDate)

            const leaveEndDate = new Date(leaveEnd)
            leaveObj?.isCancelled &&
              leaveEndDate.setDate(leaveEnd.getDate() + 1)
            const leaveStartDate = new Date(leaveStart)  
            leaveObj?.isCancelled && leaveStartDate.setDate(leaveStart.getDate() - 1)
            if (leaveObj?.cancelNumberOfDays > 0) {
              const leaveStart = new Date(leaveObj.leaveStartDate)
              leaveStartDate.setDate(leaveStart.getDate())
              if (
                currentDate >= leaveStartDate &&
                currentDate <= leaveEndDate
              ) {
                return false
              }
            } else {
              if ( 
                currentDate >= leaveStartDate &&
                currentDate <= leaveEndDate
              ) {
                return true
              }
            }
          }
        }
      }

      if (dates[0]) {
        const tooLate = dates[0] && current.diff(dates[0], 'days') //> dateCount - 1
        const tooEarly = dates[1] && dates[1].diff(current, 'days') // > dateCount
        return !!tooEarly || !!tooLate
      } else {
        return current.isBefore(moment().subtract(1, 'day'))
      }
    }
  

    const leaveTypeData = getEmployeeLeaveTypeData?.map(e => ({
      key: e.id,
      value: e.title,
    }))
    const onLeaveTypeSelect = (event: string) => {
      const data = getEmployeeLeaveTypeData.find(
        leaveType => leaveType.id === event
      )
      setSelectedLeaves(data)
    }

    return (
      <Form
        form={form}
        layout='vertical'
        onAbort={() => setText(PlASE_SELECT_TYPE_FIRST)}
        onFinish={submitData}
        id='employee-form'
      >
        <Row className='w-100'>
          <Col xs={24} lg={12}>
            <Form.Item
              name='leave_type_id'
              label='Leave Type'
              rules={[
                !viewDetail
                  ? { required: true, message: LEAVE_TYPE_IS_REUIRED }
                  : {},
              ]}
            >
              <CommonSelect
                disabled={update}
                listHeight={125}
                className=''
                getPopupContainer={trigger => trigger.parentNode}
                placeholder=' Select Leave Type'
                data={leaveTypeData}
                showSearch
                onChange={e => onLeaveTypeSelect(e)}
                filterOption={(input, option) =>
                  option?.children
                    ?.toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {selectedLeaves?.isHalfLeave || data?.isHalfLeave ? (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                name={['leave_date']}
                label='Leave Date'
                style={{
                  position: 'relative',
                }}
                rules={[{ required: true, message: LEAVE_DATE_IS_REQUIRED }]}
              >
               <DatePicker
                  format={DATE_FORMATE}
                  inputReadOnly
                  getPopupContainer={trigger => trigger.parentElement}
                  className='form-control'
                  disabledDate={current => {
                    return moment().add(-1, 'days') >= current
                  }}
                  onFocus={e => {
                    if (!e.target.value) {
                      form.setFieldsValue({ leave_date: moment(new Date()) })
                    }
                  }}
                  onChange={() => form.setFieldsValue({ time: '' })}
                  onBlur={e => setSelectDay(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name={['time']}
                label='Select Time'
                rules={[{ required: true, message: TIME_IS_REQUIRED }]}
              >
                <TimePicker.RangePicker
                  inputReadOnly
                  disabled={viewDetail}
                  format='hh:mm'
                  disabledDate={current => disbaleTimeRange(current)}
                  onCalendarChange={onOk}
                  onOk={onOk}
                  use12Hours={true}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row>
            <Form.Item
              name={['leave_dates']}
              label={
                (isCancel ? SELECT_DATES_YOU_WANT_TO_CANCEL : LEAVE_DATES) +
                (isCancel ? '' : text)
              }
              rules={[
                !viewDetail
                  ? { required: true, message: LEAVE_DATE_IS_REQUIRED }
                  : {},
              ]}
              className='w-100 position-relative'
            >
              {isCancel ? (
                <RangePicker
                  disabledDate={
                    d =>
                      !d ||
                    d.isBefore(moment(data.startDate, DATE_FORMATE)) ||
                    d.isAfter(moment(data.endDate, DATE_FORMATE))
                  }
                  inputReadOnly
                  defaultValue={[
                    moment(data.leaveStartDate, DATE_FORMATE),
                    moment(data.leaveEndDate, DATE_FORMATE),
                  ]}
                  className='w-100 inputField'
                  value={hackValue || value}
                  onChange={val => {
                    setValue(val), setLeaveDates(val)
                    setUpdateLeaveDate({
                      start_date: moment(val[0]).format(DATE_FORMATE),
                      end_date: moment(val[1]).format(DATE_FORMATE),
                    })
                  }}
                  onCalendarChange={val => setDates(val)}
                  onOpenChange={onOpenChange}
                  getPopupContainer={trigger => trigger.parentElement}
                />
              ) : (
                <RangePicker
                  value={hackValue || value}
                  inputReadOnly
                  disabledDate={disableDate}
                  disabled={!update && !Object.keys(selectedLeaves).length}
                  onCalendarChange={val => setValue(val)}
                  onChange={val => (setValue(val), setLeaveDates(val))}
                  onOpenChange={onOpenChange}
                  className='w-100 inputField'
                  getPopupContainer={trigger => trigger.parentElement}
                />
              )}
            </Form.Item>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              name={cancelText ? ['cancel_reason'] : ['reason']}
              label={cancelText ? 'Cancellation Reason' : 'Reason'}
              rules={[
                !viewDetail
                  ? { required: true, message: REASON_IS_REQUIRED }
                  : {},
                {
                  whitespace: true,
                  message: "Can't be just whitespace",
                },
              ]}
            >
              <CommonInput
                disabled={viewDetail}
                inputType='textarea'
                placeholder='Write reason here'
                className='w-100 inputField'
              />
            </Form.Item>
          </Col>
          {isCancel && value && (
            <Col span={24}>
              <Form.Item name={['leave-update']} label='Cancel Dates'>
                {
                  <div>
                    <Row>
                      <Col xs={24} lg={16}>
                        <Row>
                          <Col lg={10}>
                            {moment(
                              arrayLeaveDate[arrayLeaveDate.length - 1]
                                ?.start_date
                            ).format(DATE_FORMATE)}
                          </Col>
                          <Col lg={4}>
                            <SwapRightOutlined />
                          </Col>
                          <Col lg={10}>
                            {moment(
                              arrayLeaveDate[arrayLeaveDate.length - 1]
                                ?.end_date
                            ).format(DATE_FORMATE)}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} lg={8}>
                        {' '}
                        {moment(
                          arrayLeaveDate[arrayLeaveDate.length - 1]?.end_date
                        )?.diff(
                          moment(
                            arrayLeaveDate[arrayLeaveDate.length - 1]
                              ?.start_date
                          ),
                          'days'
                        ) + 1}
                      </Col>
                    </Row>
                  </div>
                }
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    )
  }
)

export default EmployeeLeaveForm
