import React, { useState, useRef, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Table } from '@commonComponents/table-v2'
import { remoteLeaveUrl } from '@api/const'
import CustomDrawer from '@commonComponents/drawer'
import {
  ADD_LEAVE,
  CANCEL,
  DELETE,
  DELETE_LEAVE_APPLICATION,
  UPDATE_LEAVE,
  CANCEL_LEAVE_APPLICATION,
  CANCEL_MY_LEAVE,
  CANCEL_FULL_LEAVE_MESSAGE,
} from '../../const'
import styled from 'styled-components'
import { getLeaveColumns, getSideDrawerHeader } from '../../utils'
import { useStore } from '@stores/root-store'
import EmployeeRemoteLeaveForm from '../EmployeeRemoteForm/employeeRemoteLeaveForm'
import DeletePopUp from '@commonComponents/delete-popup'
import { Space } from 'antd'
import CustomButton from '@commonComponents/button'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const EmployeeRemoteLeaveTable = observer(
  ({
    refreshTable,
    setRefreshTable,
    isRemote,
    setIsRemoteLeaveDisable,
  }: any) => {
    const [currentData, setCurrentData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const tableRef = useRef(null)
    const [viewDetail, setViewDetail] = useState(false)
    const [isLeaveDisable, setIsLeaveDisable] = useState(false)
    const [isDeleteClicked, setIsDeleteClicked] = useState(false)
    const [isCancel, setIsCancel] = useState(false)
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [cancellationReason, setCancellationReason] = useState('')
    const [isDisable, setIsDisable] = useState(true)
    const {
      userInfo: { getRoles },
      employeeRemoteLeaveModal: { deleteEmployeeRemoteLeave },
      employeeLeaveCancellationModal: { setEmployeeLeaveCancellation },
    } = useStore(null)

    const cancelLeave = data => {
      setCurrentData(data)
      setIsCancel(true)
      setIsUpdate(true)
      setVisible(true)
      setIsLeaveDisable(isLeaveDisable)
    }

    const onDelete = data => {
      setCurrentData(data)
      setVisibleDelete(true)
    }

    const onEdit = data => {
      setCurrentData(data)
      setIsUpdate(true)
      setVisible(true)
      setIsCancel(false)
    }

    const deleteEmployeeLeaveHandler = async () => {
      const res = await deleteEmployeeRemoteLeave(currentData?.id)
      if (res.success) {
        setVisibleDelete(false)
        setRefreshTable(true)
      }
    }

    const onCancelLeave = async () => {
      const props = {
        cancellation_reason: cancellationReason,
      }

      const response = await setEmployeeLeaveCancellation(
        currentData?.id,
        props
      )
      if (response?.success) {
        setVisibleDelete(false)
        setRefreshTable(true)
      }
    }

    const columns = useMemo(
      () =>
        getLeaveColumns(
          setIsLeaveDisable,
          cancelLeave,
          onDelete,
          onEdit,
          setViewDetail,
          setIsDeleteClicked,
          getRoles,
          setIsRemoteLeaveDisable
        ),
      [refreshTable]
    )

    return (
      <>
        <div className='tableWidth table-responsive custom-Pagination'>
          <EmployeeRemoteApprovalTable
            ref={tableRef}
            dataUrl={remoteLeaveUrl}
            columns={columns}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            loaderPosition={'center'}
          />
        </div>
        {/* Add EmployeeLeave Modal */}
        <StyledCustomDrawer
          title={getSideDrawerHeader(viewDetail, isUpdate, isRemote)}
          visible={visible}
          setVisible={setVisible}
          className='addDrawer'
          width={500}
          customFooter={
            !viewDetail && (
              <Space>
                <CustomButton
                  htmlType='submit'
                  type='primary'
                  title={CANCEL}
                  className='cancelButton'
                  onClick={() => setVisible(false)}
                />
                <CustomButton
                  form='employee-form'
                  htmlType='submit'
                  type='primary'
                  title={isUpdate ? UPDATE_LEAVE : ADD_LEAVE}
                  className='addButton'
                />
              </Space>
            )
          }
        >
          <EmployeeRemoteLeaveForm
            update={isUpdate}
            isCancel={isCancel}
            data={currentData}
            viewDetail={viewDetail}
            setVisible={setVisible}
            setRefreshTable={setRefreshTable}
          />
        </StyledCustomDrawer>
        {/* Delete EmployeeLeave Modal */}

        <DeletePopUp
          delete={
            currentData?.type?.length
              ? onCancelLeave
              : deleteEmployeeLeaveHandler
          }
          msgTitle={
            currentData?.type?.length
              ? CANCEL_LEAVE_APPLICATION
              : DELETE_LEAVE_APPLICATION
          }
          renderMsg={
            currentData?.type == 'cancel'
              ? CANCEL_FULL_LEAVE_MESSAGE
              : null
          }
          visibleDelete={visibleDelete}
          okText={currentData?.type?.length ? CANCEL_MY_LEAVE : DELETE}
          handleCancel={() => {
            setVisibleDelete(false)
            setIsDisable(true)
          }}
          type={currentData?.type || ''}
          isCancelLeavePopUp={true}
          setCancellationReason={setCancellationReason}
          setIsDisable={setIsDisable}
          isDisable={isDisable}
          isDeleteClicked={isDeleteClicked}
        />
      </>
    )
  }
)

export default EmployeeRemoteLeaveTable

const EmployeeRemoteApprovalTable = styled(Table)`
  .ant-pagination-item a {
    justify-content: center !important;
  }
  .ant-select .ant-select-selector {
    justify-content: center;
    display: flex;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    justify-content: center;
    text-align: center;
    display: flex;
  }
  .ant-select .ant-select-selection-item {
    text-align: center;
    justify-content: center;
    display: flex !important;
    margin-top: 3px;
  }
  .ant-table-cell:nth-child(3) {
    width: 130px !important;
    min-width: 130px;
  }
`
const StyledCustomDrawer = styled(CustomDrawer)`
  .ant-drawer-close {
    margin-top: 12px;
  }
`
