
import { notificationUrl } from '../const'
import { BaseApi } from '../baseApi'

class OmsNotificationApi extends BaseApi {

  getNotification = async () => {
    try {
      const response = await this.axios.get(notificationUrl)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }


  storeFcmToken = async data => {
    try {
      const response = await this.axios.post(notificationUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default OmsNotificationApi


