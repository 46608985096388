import React, { useEffect, useState } from 'react'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react-lite'
import EmployeeRoleForm from './roleForm'
import CustomDrawer from '@commonComponents/drawer'
import { roleHeader } from '@mainPage/rolePermission/utils'
import { Space } from 'antd'
import CustomButton from '@commonComponents/button'
import RolePermissionHeader from '@mainPage/rolePermission/RolePermissionHeader'
import RolePermissionLists from '@mainPage/rolePermission/RolePermissionLists'
import ModuleAccess from '@mainPage/rolePermission/ModuleAccess'
import { INITIAL_VALUES } from '@mainPage/rolePermission/constants'

const RolePermisson = observer(() => {
  const [desVisible, setDesVisible] = useState(INITIAL_VALUES)
  const [roleId, setRoleId] = useState(null)
  const [record, setRecord] = useState(null)

  const {
    employeeRole: {
      loadEmployeeRole,
      loadDefaultPermissions
    },
  } = useStore(null)

  useEffect(() => {
    async function loadData() {
      await loadEmployeeRole(true)
      await loadDefaultPermissions()
    }
    loadData()
  }, [])

  return (
    <div className='page-wrapper '>
      {/* Page Content */}
        <div className='content container-fluid'>
          <RolePermissionHeader setDesVisible={setDesVisible} />
          <div className='row justify-content-between'>
            <RolePermissionLists
              roleId={roleId}
              setRoleId={setRoleId}
              setDesVisible={setDesVisible}
              setRecord={setRecord}
            />
            <ModuleAccess
              roleId={roleId}
            />
          </div>
        </div>

      {/* /Delete Role Modal */}
      <CustomDrawer
        title={roleHeader(desVisible.isUpdate)}
        className='addDrawer'
        visible={desVisible.isOpen}
        setVisible={() => {
          setDesVisible(INITIAL_VALUES)
        }}
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => setDesVisible(INITIAL_VALUES)}
            />
            <CustomButton
              form='role-form'
              htmlType='submit'
              type='primary'
              title={desVisible.isUpdate ? 'Update' : 'Add'}
              className='addButton'
            />
          </Space>}
      >
       
            <EmployeeRoleForm
              data={record}
              update={desVisible.isUpdate}
              setVisible={setDesVisible}
            />
          
      </CustomDrawer>
    </div>
  )
})
export default RolePermisson
