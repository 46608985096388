export const MARGIN_RIGHT = { marginRight: '5px' }
export const ICON_CLASS = 'fa fa-dot-circle-o'
export const RESIGNATIN_TITLE = 'Resignation'
export const RESIGNATION = 'resignation'
export const RESIGNATION_FORM = 'resignation-form'
export const EMPLOYEE_NAME = 'name'
export const PENDING='pending'
export const APPROVED='approved'
export const REJECTED='rejected'
export const WAITING_APPROVEL_TEXT = 'Waiting for Approval'
export const CLASS_WARNING = 'text-warning'
export const APPROVED_TEXT = 'Approved'
export const REJECTED_TEXT = 'Rejected'

export const CLASS_SUCCESS = 'text-success'
export const CLASS_REJECTED = 'text-danger'


export const APPROVAL_OBJ = {
  text: WAITING_APPROVEL_TEXT,
  className: CLASS_WARNING
}

export const RESIGN_TYPES = {
  Pending: APPROVAL_OBJ,
  Pending_Approval: APPROVAL_OBJ,
  Forward: APPROVAL_OBJ,
  Approved: {text:APPROVED_TEXT,className: CLASS_SUCCESS},
  Rejected: {text:REJECTED_TEXT,className: CLASS_REJECTED}

}