import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { eCanteenmenuUrl } from '../const/index'
class ECanteenMenuApi extends BaseApi {
  seteCanteenmenu = async data => {
    try {
      const response = await this.axios.post(`${eCanteenmenuUrl}/`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  geteCanteenmenu = async (num = 1, pageSize = 10, queryParam = {}) => {
    try {
      const url = `${eCanteenmenuUrl}?page=${num}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }


  updateEcanteenmenu = async (data, id) => {
    try {
      const response = await this.axios.patch(`${eCanteenmenuUrl}/${id}/`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteEcanteenmenu = async id => {
    try {
      const response = await this.axios.delete(`${eCanteenmenuUrl}/${id}/`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ECanteenMenuApi
