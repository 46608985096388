import React, { useEffect } from 'react'
import { Form, Row, DatePicker, Col } from 'antd'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import moment from 'moment'
import { DATE_FORMAT } from 'constant/constant'

export interface AssetHandleProps {
  currentData?: any;
  isUpdate?: any;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssetHandlingForm = observer(
  ({
    currentData,
    isUpdate,
    setVisible,
    setRefreshTable,
  }: AssetHandleProps) => {
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker

    const {
      assetHandlingModal: {
        getemployeeAssect,
        setAssetPermission,
        loadEmployeeAssetsData,
        updateEmployeeAssetsData,
        nextassetHandle,
      },
    } = useStore(null)

    useEffect(() => {
      const load = async () => {
        await loadEmployeeAssetsData()
      }
      !getemployeeAssect?.length && load()
    }, [])

    useEffect(() => {
      form.resetFields()
      if (isUpdate) {
        const dates = [
          moment(currentData?.startDate),
          moment(currentData?.endDate),
        ]
        form.setFieldsValue({
          id: currentData?.asset,
          reason: currentData?.reason,
          selectedDate: dates,
        })
      }
    }, [])
    
    const submitData = async (values: any) => {
      let response = null
      const payload = {
        asset: values?.id,
        start_date: moment(values?.selectedDate[0])?.format(DATE_FORMAT),
        end_date: moment(values?.selectedDate[1])?.format(DATE_FORMAT),
        reason: values?.reason,
      }

      if (isUpdate) {
        response = await updateEmployeeAssetsData(payload, currentData?.id)
        if (response?.success) {
          setVisible(false)
          setRefreshTable(true)
        }
      } else {
        response = await setAssetPermission(payload)
        if (response?.success) {
          setVisible(false)
          setRefreshTable(true)
        }
      }
      form.resetFields()
    }

    function disabledDate(current) {
      return current && current < moment().startOf('day');
    }
    
    
    return (
      <>
        <Form
          form={form}
          id='assetHandle-form'
          layout='vertical'
          onFinish={submitData}
        >
          <Dynamic lg={24} md={12} sm={6}>
            <DynamicSelect
              mode='multiple'
              placeholder='Select Asset'
              getData={getemployeeAssect}
              loadData={loadEmployeeAssetsData}
              nextPage={nextassetHandle}
              valueKey1='name'
              name='id'
              isForm={true}
              fetchData={false}
              formLabel={'Asset'}
              formRules={[{ required: true, message: 'Asset is required' }]}
              clearMyData={true}
            />
          </Dynamic>
          <Row>
            <Col lg={24} md={24} sm={24}>
              <Form.Item
                label='Select Date'
                name='selectedDate'
              >
                <RangePicker
                  format={'YYYY-MM-DD'}
                  className='w-100 inputField' 
                  inputReadOnly={true}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24}>
              <Form.Item
                label={'Reason'}
                name='reason'
                rules={[{ required: true, message: 'Reason is required' }, {
                  whitespace: true,
                  message: 'Can\'t be just whitespace',
                }]}
              >
                <FormCommonInput
                  inputType='textarea'
                  placeholder='Describe Reason Here'
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
)

export default AssetHandlingForm

const FormCommonInput = styled(CommonInput)`
  height: 250px !important;
`
const Dynamic = styled.div`
  .ant-select-selector {
    height: 55px !important;
    .ant-select-selection-item {
      display: flex !important;
      align-items: center;
      height: 36px !important;
      margin: 0px;
      padding-top: 0px !important;
      .ant-select-selection-item-content{
        height: 36px;
      }
      .ant-select-selection-item-remove{
        height: 36px;
      }
    }
    .ant-select-selection-overflow-item-suffix{
      height: 36px;
    }
  }
`
