import React, { useState, useRef } from 'react'
import { Avatar, Row } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { permit } from '@utils/permission/permission'
import { Table } from '@commonComponents/table-v2'
import ShowOnPermission from '@commonComponents/permit'
import { PROJECTS_MOD, UPDATE_METHOD } from 'constant/constant'
import styled from 'styled-components'
import Recieved from '@assets/img/check2.svg'
import { AiFillClockCircle } from 'react-icons/ai'
import { BsClock } from 'react-icons/bs'
import ProjectHistory from './projectHistory'
import CustomDrawer from '@commonComponents/drawer'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

const ProjectsTable = observer(props => {
  const [, setPageNumber] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const tableRef = useRef(null);

  const {
    userInfo: { getcompany },
  } = useStore(null)

  const getHistory = () => {
    setIsModalOpen(true)
  }

  const HistoryCancel = () => {
    setIsModalOpen(false)
  }
  const isActionsExist = permit(getcompany, 'projects', 'edit') || permit(getcompany, 'projects', 'delete');
  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      render: () => (
        <Tooltip title='OMS' placement='topLeft'>
          <p>OMS</p>
        </Tooltip>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: () => (
        <Tooltip title='Saad Bin Abid' placement='topLeft'>
          <p>Saad Bin Abid</p>
        </Tooltip>
      ),
    },
    {
      title: 'Lead Name',
      dataIndex: 'projectLeader',
      key: 'projectLeader',
      render: () => (
        <StyledAvatarSpan>
          <div className='d-flex gap-2'>
            <Avatar>A</Avatar>
            <Avatar>B</Avatar>
          </div>

        </StyledAvatarSpan>
      ),
    },
    {


      title: 'Rate',
      dataIndex: 'projectType',
      key: 'projectType',
      render: () => (
        <Tooltip title={'20$'} placement='topLeft'>
          <p>20$</p>
        </Tooltip>
      ),
    },
    {
      title: <span> Start Payment Date <br /> Payment Recieved Date</span>,
      dataIndex: 'rate',
      key: 'rate',
      render: () => (
        <p>12-02-23<br />12-03-23</p>
      ),
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <>
          {text == 'Recieved' ?
            <StyleDiv>
              <img src={Recieved} alt='' /> Recieved
            </StyleDiv> :
            <div className='d-flex'>
              <StyleDiv className='pendingIcon'>
                <AiFillClockCircle />
              </StyleDiv>
              <div>Pending</div>
            </div>
          }
        </>
      ),
    },
    ...(isActionsExist ? [{
      title: 'Actions',
      dataIndex: 'id',
      key: 'action',
      render: () => (
        <Row>
          <ShowOnPermission mod={PROJECTS_MOD} method={UPDATE_METHOD}>
            <Tooltip title='History'>
              <StyleClock>
                <BsClock size={20} onClick={getHistory} />
              </StyleClock>
            </Tooltip>
          </ShowOnPermission>
        </Row>
      ),
    },] : [])
  ]

  return (


    <div>
      <div>
        <StyleTable>
          <Table
            ref={tableRef}
            columns={columns}
            refreshTable={props.refreshTable}
            setRefreshTable={props.setRefreshTable}
            setPageNumber={data => setPageNumber(data)}
            resetTable={props.resetTable}
            setResetTable={props.setResetTable}
            queryParam={props.queryParam}
            setTotalRecordRemaining={props.setTotalRecordRemaining}
          />
        </StyleTable>
        <>
          <CustomDrawer
            className='addDrawer'
            width={600}
            title={
              <StyleTitile>
                <h5>Project name: OMS</h5>
                <h3 className='mt-3'>Received Transaction History</h3>
                <h3 className='mt-2'>Details</h3>
              </StyleTitile>
            }
            visible={isModalOpen}
            setVisible={HistoryCancel}
          >
            <ProjectHistory />
          </CustomDrawer>
        </>
      </div>
    </div>
  )
})

export default ProjectsTable

const StyledAvatarSpan = styled.span`
display: flex !important;
  justify-content: center !important;
  align-items: center !important;
.ant-avatar-circle{
  margin-right: -1px !important;
  width: 42px !important;
  height: 42px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
};
`


const StyleTable = styled.div`
  .ant-table-cell:nth-child(5){
    min-width: 250px;
  }
  `

const StyleDiv = styled.div`
    margin-right: 4px;
  `

const StyleClock = styled.div`
  svg{
    color: ${TealishBlue} !important;
  }
  `

const StyleTitile = styled.div`
  padding: 10px;
  
  h3,h5{
    margin: 0;
    color: white;
}
`

