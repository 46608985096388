import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { eCanteenUrl } from '../const/index'
class ECanteenApi extends BaseApi {
  seteCanteen = async data => {
    try {
      const response = await this.axios.post(eCanteenUrl, data, headerAuthorization(this))
      return response
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  geteCanteen = async (num = 1, pageSize = 20, queryParam = {}) => {
    try {
      let url = eCanteenUrl
      url += `?page=${num}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }


  updateEcanteenOrder = async (data, id) => {
    try {
      const response = await this.axios.put(`${eCanteenUrl}${id}/`, data, headerAuthorization(this))  
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateEcanteen = async (data, id) => {
    
    try {
      const response = await this.axios.patch(`${eCanteenUrl}${id}/`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteEcanteen = async id => {
    try {
      const response = await this.axios.delete(`${eCanteenUrl}${id}/`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ECanteenApi
