import { notification } from '@utils/notifications';
import { useState } from 'react';
import { MdDone } from 'react-icons/md';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';


export const CopyText = ({ text,copySuccesTooltip, ...props }) => {
  const [textCopied, setTextCopied] = useState(false);

  const textCopy = () => {
    if (textCopied) return;
    navigator.clipboard.writeText(text);
    notification.success('Text Copied to clipboard');
    setTextCopied(true);
    setTimeout(() => setTextCopied(false), 2000);
  };

  return (
    <div style={{ cursor: 'pointer' }} onClick={textCopy}>
      <Tooltip title={textCopied? copySuccesTooltip : props?.tooltipTitle }>
        {textCopied ? (
          <MdDone  />
        ) : (
            <CopyOutlined  />
          )}
      </Tooltip>
    </div>
  );
};
