import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { assetsStatsApi } from '@api'
import { notification } from '@utils/notifications'

export const assetStats = types.model({
  assignedAssets: types.maybeNull(types.number),
  damagedAssets: types.maybeNull(types.number),
  deadAssets: types.maybeNull(types.number),
  totalAssets: types.maybeNull(types.number),
  unassignedAssets: types.maybeNull(types.number),
  underRepairAssets: types.maybeNull(types.number),
  workingAssets: types.maybeNull(types.number),
  notWorkingAssets: types.maybeNull(types.number)
})

export const assetStatsModal = types
  .model({
    data: types.maybeNull(assetStats),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getAssetStatsData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const loadAssetStats = flow(function* () {
      self.loading = true
      try {
        self.loading = true
        const res = yield assetsStatsApi.getAssetsStats()
        self.data = cast(res)
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Employee Asset Stats')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })


    return {
      loadAssetStats,
    }
  })

export function initAssetStatsModal() {
  return assetStatsModal.create({})
}
