import BreadCrumbs from '@commonComponents/breadcrums'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { constRoute } from '@utils/route'
import { useStore } from '@stores/root-store'
import { Space, Card } from 'antd'
import CustomButton from '@commonComponents/button'
import { permit } from '@utils/permission/permission'
import ApplyLoan from './applyLoan'
import SearchLoan from './searchLoan'
import LoanTable from './loanTable'
import CustomDrawer from '@commonComponents/drawer'
import ShowOnPermission from '@commonComponents/permit'
import LoanForApproval from './loanForApproval'
import {
  ADD_METHOD,
  EMPLOYEE_MOD
} from 'constant/constant'
import { loanUrl } from '@api/const'
import styled from 'styled-components'
import { APPROVAL_LIST, LOAN_LIST } from './const'
import getColumns from './column'

const Loan = () => {
  const [visible, setVisible] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [isUpdate , setIsUpdate] = useState(false)
  const tableRef = useRef(null)
  const [refreshTable, setRefreshTable] = useState(false)
  const [queryParam, setQueryParam] = useState({})
  const [setPage] = useState(null)
  const [resetTable, setResetTable] = useState(false)
  const [setBtnLoad] = useState(false)
  const [setStatus] = useState(null)
  const [tabList, setTabList] = useState([])
  const [activeTab, setActiveTab] = useState('')
  const {
    employeeDetails: { loadEmployeeDetails },
    employeeRole: { loadEmployeeRole },
    userInfo: { getcompany },
    designationModal: { loadDesignation },
    laonDetails: { updateLoanDetails },
  } = useStore(null)

  const updateEmployeeStatus = async (data, status) => {
    setStatus(status)
    const formData = new FormData()
    formData.append('status', status)
    await updateLoanDetails(formData, data.id)
    setRefreshTable(true)
  }

  useEffect(() => {
    const load = async () => {
      await loadDesignation(1, 20, '', true)
      await loadEmployeeDetails(1, 20, '', true)
      if (permit(getcompany, 'user', 'read')) {
        await loadEmployeeRole(true)
      }
    }
    load()
  }, [])
  useEffect(() => {
    let tab_List = []
    if (getcompany) { //getcompany?.admin || getcompany?.userLevel
      tab_List = [...LOAN_LIST, ...APPROVAL_LIST]
    }
    else {
      tab_List = [...LOAN_LIST]
    }
    setTabList(tab_List)
    setActiveTab(tab_List[0].key)
  }, [])
  
  const onAdd = () => {
    setVisible(true)
    setIsUpdate(false)
  }
  const columns = useMemo(() => getColumns(updateEmployeeStatus,setCurrentData,setIsUpdate,setVisible,getcompany), [])

  const contentList = {
    loan: (
      <div className='table-responsive'>
      <LoanTable
        ref={tableRef}
        dataUrl={loanUrl}
        columns={columns}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        queryParam={queryParam}
        setPageNumber={() => setPage}
        resetTable={resetTable}
        setResetTable={setResetTable}
      />
      </div>
  ),
    loanForApproval: (
      <div className='table-responsive'>
      <LoanForApproval 
        ref={tableRef}
        dataUrl={loanUrl}
        columns={columns}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        queryParam={queryParam}
        setPageNumber={() => setPage}
        resetTable={resetTable}
        setResetTable={setResetTable}
      />
    </div>
    )
  }
  const onTabChange = key => {
    setActiveTab(key)
    setQueryParam({})
  }
  
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className={` d-flex mb-4`}>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Employee Loan', link: constRoute.newEmployee },
              ]}
              isAlpha
              pageTitle={'Employee Loan'} children={''}
            ></BreadCrumbs>
            <div>
              <ShowOnPermission mod={EMPLOYEE_MOD} method={ADD_METHOD}>
                <CustomButton
                  onClick={onAdd}
                  title='Apply Loan'
                />
              </ShowOnPermission>
            </div>
          </div>
          <div className='div'>
            <div className='customTabs'>
              <TabCard
                className='d-flex justify-content-between align-items-center mb-4'
                tabList={tabList}
                activeTabKey={activeTab}
                onTabChange={key => {
                  onTabChange(key)
                }}
              >
              </TabCard>
            </div>
          </div>
        </div>
        <SearchLoan setQueryParam={setQueryParam} />
        {contentList[activeTab]}
        <CustomDrawer
          title={isUpdate ? 'Update Loan' : 'Apply Loan'}
          setVisible={setVisible}
          visible={visible}
          className='addDrawer'
          width={400}
          customFooter={
            <Space>
              <CustomButton
                htmlType='submit'
                type='primary'
                title={'Cancel'}
                className='cancelButton'
                onClick={() => {
                  setVisible(false)
                }}
              />
              <CustomButton
                form='apply-loan'
                htmlType='submit'
                title={isUpdate ? 'Update' : 'Add'}
                type='primary'
                className='addButton'
              />
            </Space>
          }
        >
          <ApplyLoan
            setRefreshTable={setRefreshTable}
            setBtnLoad={setBtnLoad}
            setVisible={setVisible}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            data={currentData} 
          />
        </CustomDrawer>
      </div>
    </div>
  )
}
export default Loan

const TabCard = styled(Card)`
  .ant-tabs-nav-more{
    visibility: hidden;
    order: 1;
  }
`
