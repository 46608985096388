import { flow, types ,cast} from 'mobx-state-tree'
import {attendanceStatsApi } from '@api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'


export const attendanceStats = types.model({
  monthTotalHours: types.maybeNull(types.number),
  monthWorkingHours:types.maybeNull(types.number),
  todayTotalHours:types.maybeNull(types.number),
  todayWorkingHours:types.maybeNull(types.number),
  weekTotalHours:types.maybeNull(types.number),
  weekWorkingHours:types.maybeNull(types.number),
})

export const attendanceStatsModal = types
  .model({
    data: types.maybeNull(attendanceStats),
  })
  .views(self => ({
    get getAttendanceStatsData() {
      return toJS(self.data)
    },
  }))
  .actions(self => {
    const loadAttendanceStats = flow(function* (resetList=false) {
      try {
        if (resetList) {
          self.data = cast({})
        }
        const res = yield attendanceStatsApi.getAttendenceStats()
        if (!res?.isCancel){
          self.data = toJS(res)
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Attendance Statistics Data')
        }        return { success: false }
      }
    })

    return {
      loadAttendanceStats,
    }
  })

export function initAttendanceStatsModal() {
  return attendanceStatsModal.create({
    data: {
      todayTotalHours: 0,
      todayWorkingHours: 0,
      weekTotalHours: 0,
      weekWorkingHours: 0,
      monthTotalHours: 0,
      monthWorkingHours: 0,
    }
  })
}
