import React, { useState } from 'react';
import BreadCrumbs from '@commonComponents/breadcrums';
import { constRoute } from '@utils/route';
import { observer } from 'mobx-react-lite';
import CustomButton from '@commonComponents/button';
import CustomDrawer from '@commonComponents/drawer';
import SearchOnboarding from './SearchOnboarding';
import { Space } from 'antd';
import OnboardingTable from './onboardingTable';
import OnboardingForm from './onboardingForm';
import ShowOnPermission from '@commonComponents/permit';
import { ONBOARDING_MOD, POST_METHOD, VIEW_ALL } from 'constant/constant';

const OnBoarding = observer(() => {
  const [visible, setVisible] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [queryParam, setQueryParam] = useState({});


  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='d-flex justify-content-between'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Onboarding', link: constRoute.onBoarding },
              ]}
              isAlpha
              pageTitle={'Onboarding'} children={''}></BreadCrumbs>
            <ShowOnPermission mod={ONBOARDING_MOD} method={POST_METHOD}>
              <CustomButton
                onClick={() => setVisible(true)}
                title='Add Candidate'
              />
            </ShowOnPermission>
          </div>
        </div>
        <ShowOnPermission mod={ONBOARDING_MOD} method={VIEW_ALL}>
          <SearchOnboarding
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            queryParam={queryParam}
            setQueryParam={setQueryParam}
          />
        </ShowOnPermission>
        <OnboardingTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
        />
      </div>

      <CustomDrawer
        title='Add Candidate'
        setVisible={setVisible}
        visible={visible}
        className='addDrawer'
        width={700}
        customFooter={
          <Space>
            <CustomButton
              form='onboarding-form'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='onboarding-form'
              htmlType='submit'
              type='primary'
              title={'Add'}
              className='addButton'
            />
          </Space>
        }
      >
        <OnboardingForm setVisible={setVisible} setBtnLoad={() => null} setRefreshTable={setRefreshTable} />
      </CustomDrawer>
    </div>
  );
});

export default OnBoarding;
