import { colors } from 'constant/colors'

const { TealishBlue } = colors

export const THEME = {
  light: {color1: '#00c5fb', color2: '#0253cc', theme: 'light'},
  dark: {color1: '#9570FE', color2: '#5405BB', theme: 'dark'},
  auto: {color1: TealishBlue, color2: TealishBlue, theme: 'auto'}
}
export const punchType = 'web'
export const LATITUDE = 29.389801
export const LONGITUTE = 71.712582
export const INITIAL_TIME = '00:00:00'
export const PUNCH_TYPE = {Punch_in:'Checked in',Punch_out:'Checked out',  Lunch_in:'Lunch In',
  Lunch_out:'Lunch out',
  Prayer_in:'Prayer In',
  Prayer_out:'Prayer out',}
export const PUNCH_TYPE_BTN = {Punch_in:'Check In',Punch_out:'Check Out', Lunch_in:'Lunch In', Lunch_out : 'Lunch Out',Prayer_in:'Prayer In', Prayer_out : 'Prayer Out',  }
export const ACTIVITY_PUNCH_TYPE = {
  Punch_in:'Checked In',
  Punch_out:'Checked out',
  Lunch_in:'Lunch In',
  Lunch_out:'Lunch out',
  Prayer_in:'Prayer In',
  Prayer_out:'Prayer out',
}
export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const MDY_DATE_FORMAT = 'MMM DD, YYYY'

export const NO_REASON_PROVIDED = 'No reason provided'
export const NO_WORK_REPORT_PROVIDED= 'No work report provided'

export const BLOOD_GROUP_LIST = [
  { key: 'A+', value: 'A+' },
  { key: 'A-', value: 'A-' },
  { key: 'B+', value: 'B+' },
  { key: 'B-', value: 'B-' },
  { key: 'AB+', value: 'AB+' },
  { key: 'AB-', value: 'AB-' },
  { key: 'O+', value: 'O+' },
  { key: 'O-', value: 'O-' },
]
