import { toJS } from 'mobx'
import { flow, types } from 'mobx-state-tree'
import { clientProfileApi } from '../../api'
import { notification } from '@utils/notifications'

export const clientProfile = types.model({
  id: types.maybeNull(types.number),
  country: types.maybeNull(types.string),
  clientCompany: types.maybeNull(types.string),
  clientDesignation: types.maybeNull(types.string),
  birthDate: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
})

export const clientProfileDetails = types
  .model({
    clientInfo: types.maybeNull(types.array(clientProfile)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getClientProfile() {
      return toJS(self.clientInfo)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setClientProfile = flow(function* (data) {
      try {
        self.loading = true
        yield clientProfileApi.setClientProfile(data)
        notification.success('Client Details Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadClientProfile = flow(function* (num) {
      try {
        self.loading = true
        const res = yield clientProfileApi.getClientProfile(num)
        self.clientInfo = res.results
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Client')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateClientProfile = flow(function* (data, recordId) {
      try {
        self.loading = true
        yield clientProfileApi.updateClientProfile(data, recordId)
        notification.success('Client Details Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteClientProfile = flow(function* (id) {
      try {
        self.loading = true
        yield clientProfileApi.deleteClientProfile(id)
        notification.success('Client Details Deleted Successfully')
      } catch (error) {
        notification.error(error?.response?.data?.message)
      } finally {
        self.loading = false
      }
    })
    return {
      setClientProfile,
      loadClientProfile,
      deleteClientProfile,
      updateClientProfile,
    }
  })

export function initClientProfile() {
  return clientProfileDetails.create({})
}
