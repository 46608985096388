import moment from 'moment'
import 'moment-timezone'
import _ from 'lodash'
import { useStore } from '@stores/root-store'
import statusApproved from '@assets/img/leaveApproved.svg'
import statusReject from '@assets/img/leaveReject.svg'
import { CheckCircleFilled, ClockCircleFilled, StopOutlined } from '@ant-design/icons'
import { EMAIL_REGEX } from './regex'
import { permit } from '@utils/permission/permission'
import { FORMAT, HOURS } from 'constant/constant'
import { notification } from '@utils/notifications'

{/* get theme color*/ }
export const getThemeColor = () => (JSON.parse(localStorage?.getItem('colorTheme'))?.theme === 'dark') ? 'white' : 'black';

export const assetIdGenerator = (data, type, compName) => {
  const IDNumber = []
  data?.map(value => {
    IDNumber.push(value[`${type}`]?.split('-')[2])
  })
  const maxNumber = IDNumber?.length == 0 ? 1 : Math.max(...IDNumber) + 1
  return `${compName.slice(0, 3).toUpperCase()}-${type
    .slice(0, 3)
    .toUpperCase()}-${maxNumber}`
}

export const getDayHour = (startDate, endDate) => {
  const duration = moment.duration(moment(endDate).diff(startDate))
  const days = Math.round(duration.asHours() / 24)
  const hours = Math.round(duration.asHours() % 24)
  return {
    hours: hours > 4 ? 0 : hours,
    days: hours >= 6 ? days + 1 : hours >= 4 ? days + 0.5 : days,
  }
}

export const checkUUID = uuid => {
  const uuidRegex =
    /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4[a-fA-F0-9]{3}-[89aAbB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/
  return uuidRegex.test(uuid)
}

export const addDebounce = (fn, delay) => {
  let timer
  return (() => {
    clearTimeout(timer)
    timer = setTimeout(() => fn(), delay)
  })()
}

export const exportCsv = data => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' }) // type here
  const link = document.createElement('a')
  if (link.download !== undefined) {
    const fileName = 'Downloaded'
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const exportPdf = data => {
  const blob = new Blob([data], { type: 'text/pdf;' }) // type here
  const link = document.createElement('a')
  if (link.download !== undefined) {
    const fileName = 'Downloaded.pdf'
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
export const getMonthName = (monthNumber: string) => {
  if (!monthNumber) return monthNumber
  const date = new Date()
  date.setMonth(parseInt(monthNumber) - 1)
  return date.toLocaleString('en-US', { month: 'long' })
}

export const getYearName = (yearNumber: string) => {
  if (!yearNumber) return yearNumber
  const date = new Date()
  date.setFullYear(parseInt(yearNumber) - 1)
  return `${date.toLocaleString('en-US', { month: 'long' })}  ${
    date.getFullYear() + 1
  }`
}

// format date picker values
export const formatDateValue = (values, format) => {
  for (const key in values) {
    values[key]?._isAMomentObject
      ? (values[key] = values[key].format(format))
      : ''
  }
}

export const timezoneFormat = (time, format,add = null) => {
  const systemTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZone = moment.tz(
    `${moment().format('YYYY-MM-DD')} ${time}`,
    'Asia/Karachi'
  )
  add && timeZone.add(5,'hours')
  const timeZoneChange = timeZone.clone().tz(`${systemTimeZone}`)
  return timeZoneChange.format(format)
}

export const removeEmptyUndefNull = data => {
  return _.pickBy(data)
}

// Converts underscore-separated or camelCase text to capitalized words (e.g., from 'my_name' to 'My Name' and 'MyName' to 'My Name')
export const toCapitalizedWords = (input: string | undefined | null) => {
  if (input === undefined || input === null) {
    return '';
  }
     input = input.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    input = input === 'Approved' ? 'Approve' : input === 'Rejected' ? 'Reject' : input;
    return input;
};


export function getTotalSec(timeString: string | undefined | null): number {
  if (!timeString) {
    console.error('Invalid time string:', timeString)
    return 0
  }

  const [hours, minutes, seconds] = timeString.split(':').map(Number)

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    console.error('Invalid time format:', timeString)
    return 0
  }

  return hours * 3600 + minutes * 60 + seconds
}

export const validateEmptySpaces = (rule, value, callback) => {
  value && value.trim() === '' ?  callback('Value cannot be just spaces') : callback();
 };
//convert Camel To Snake
export const convertCamelToSnake = str => str.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`)

export const convertToUppercase = (inputText => inputText && inputText.charAt(0).toUpperCase() + inputText.slice(1))
export function hasValue(obj: Record<string, string>): boolean {
  for (const key in obj) {
    if (obj[key]) {
      return true
    }
  }
  return false
}
export const convertTimeFormat = (inputTimeString: string,add = null) => {  
  if (!inputTimeString) {
    return null;
  }
  const totalDuration = moment.duration(inputTimeString);
  add && totalDuration.add(5,HOURS)
  const formattedTotalTimeSpent = moment
    .utc(totalDuration.asMilliseconds())
    .format('hh:mm A')
  return formattedTotalTimeSpent
}

export function formatMinutesAndSeconds(timeString: string | undefined | null,status?) {
  const timeParts = (timeString ?? '').split(':')

  const hours = parseInt(timeParts[0] || '0')
  const minutes = parseInt(timeParts[1] || '0')

  if (hours === 0 && minutes === 0) return undefined
  const formattedTime = `${hours} hrs ${minutes} min's`
  if(['punch_out','punch_in'].includes(status)){
    const time = moment(formattedTime,FORMAT).add(HOURS)
    const get = time?.hours()
    const mints = time?.minutes()    
  return `${get} hrs ${mints} min's`
  }
  return formattedTime
}

export const isCurrentUsersId = (id: string) => {
  const { userInfo: { data } } = useStore(null)
  return data?.id === id
}


export const StatusButtons = {
  approved: statusApproved,
  rejected: statusReject
}

export const statusIcons = {
  approved: <CheckCircleFilled />,
  rejected: <StopOutlined />,
  pending: <ClockCircleFilled />
}

export function getFullName(row) {
  const firstName = row?.employee?.firstName
  const lastName = row?.employee?.lastName

  const fullNameParts = [firstName, lastName].filter(
    part => part !== null && part !== undefined
  )

  if (fullNameParts.length > 0) {
    return fullNameParts.join(' ')
  } else {
    return null
  }
}

export function checkIfValueisEmpty<T>(value: T) {
  if (value === undefined || value === null || value === '') return undefined
  return value
}

export function calculateLeaves(data, value) {
  return data
    ? data.reduce((sum, item) => sum + (item[value] || 0), 0)
    : 0
}

export function isValidEmail(_,value,message) {  
  value && !EMAIL_REGEX.test(value) ? message('Invalid email address') : message();
}

export const getYearMonthDate = (date: string) => {
  return moment(date).format('YYYY-MM')
}

export const getFullDateTime = (date: string) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}


export const getNormalMonthYearDate = (date: string) => {
  return moment(date).format('MMMM YYYY')
}

export const hasPermission = (getRoles, moduleName: string, permissions: string[]) => {
  const permissionList = permissions.map(permission => permit(getRoles, moduleName, permission))
  return permissionList.includes(true)
}


export const timeFormat = time =>{
return moment(time,FORMAT)
}

const getAuthRoles = () => {
  const { userInfo: { getRoles , getcompany }} = useStore(null);
  const { accessView } = getRoles;
  const Admin = accessView?.includes('is_admin');
  const Lead = getcompany?.isLead;
  const leadOrAdmin = Admin || Lead;
  const employee = !leadOrAdmin
  return { Admin, Lead, leadOrAdmin, employee};
};

export default getAuthRoles;


export const errorHandling = (error, msg?) => {
  return notification.error(error?.response?.data?.detail?.[0]?.msg || msg);
};


export const addHours = time =>{
  return moment.utc(time,FORMAT).add(HOURS).format(FORMAT)
}