import { flow, types } from 'mobx-state-tree'
import { educationApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { errorHandling } from '@utils/common-functions'

export const education = types.model({
  id: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  institution: types.maybeNull(types.string),
  subject: types.maybeNull(types.string),
  startingDate: types.maybeNull(types.string),
  completeDate: types.maybeNull(types.string),
  degree: types.maybeNull(types.string),
  grade: types.maybeNull(types.string),
  isContinue: types.maybeNull(types.boolean),
})

export const educationDetails = types
  .model({
    data: types.maybeNull(types.array(education)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get educationData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setEducationDetails = flow(function* (data) {
      try {
        self.loading = true
        yield educationApi.setEducationDetails(data)
        notification.success('Education Added Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to create Education Detail')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadEducationDetails = flow(function* (id) {
      try {
        self.loading = true
        const res = yield educationApi.getEducationDetails(id)
        self.data = res
      } catch (error) {
        errorHandling(error,'Failed to Load Education Details')
      } finally {
        self.loading = false
      }
    })

    const updateEducationDetails = flow(function* (
      id,
      data
    ) {
      try {
        self.loading = true
        yield educationApi.updateEducationDetails(
          id,
          data
        )
        notification.success('Education Updated Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to update education detail')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEducationDetails = flow(function* (id: string) {
      try {
        self.loading = true
        yield educationApi.deleteEducationDetails(id)
        notification.success('Education Deleted Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to delete Education Detail')
      } finally {
        self.loading = false
      }
    })
    return {
      setEducationDetails,
      loadEducationDetails,
      updateEducationDetails,
      deleteEducationDetails,
    }
  })

export function initEmployeeEducationDetails() {
  return educationDetails.create({})
}
