import moment from 'moment/moment'
import { constRoute } from '@utils/route'

export const APPROVED = 'Approved'
export const APPROVED_LOWERCASE = 'approved'
export const PENDING = 'Pending'
export const PENDING_LOWERCASE = 'pending'
export const REJECTED = 'Rejected'
export const REJECTED_LOWERCASE = 'rejected'
export const ACTION = 'Action'
export const UPDATE_SECTION = 'Update Section'
export const ADD_SECTION = 'Add Section'
export const ACTION_TITLE = 'Action'
export const UPDATE_METHOD = 'edit'

export const ALL_DATA = 'view_all'
export const ADD_METHOD = 'add'
export const FEEDBACK = 'feedback'
export const STATUS = 'status'
export const DISMISSAL_STATUS = 'dismissal_status'
export const STATUS_TITLE = 'Status'
export const DASHBOARD = 'Dashboard'
export const ADD = 'Add'
export const ADDS = 'add'
export const ADD_TITLE = 'Add'
export const CANCEL = 'Cancel'
export const CANCEL_TITLE = 'Cancel'
export const UPDATE = 'Update'
export const APPLY = 'Apply'
export const SUBMIT = 'submit'
export const PRIMARY = 'primary'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const VERTICAL = 'vertical'
export const SEARCH = 'search'
export const SEARCH_TITLE = 'Search'
export const HORIZONTAL = 'horizontal'
export const LARGE = 'large'
export const RESET = 'reset'
export const RESET_TITLE = 'Reset'
export const REASON = 'reason'
export const REASON_TITLE = 'Reason'
export const REASON_LEAVE = 'Reason To Leave'
export const EDIT_TITLE = 'Edit'
export const ADD_DRAWER = 'addDrawer'
export const CANCEL_BUTTON_CLASS = 'cancelButton'
export const ADD_BUTTON_CLASS = 'addButton'
export const SELECT_EMPLOYEE = 'Select Employee'
export const CONTECT_PERSON = 'contact person'
export const EMPLOYEE_HISTORY = 'Employee Histroy'
export const CHECKED = 'checked'
export const USER = 'user'
export const READ = 'read'
export const HISTORY = 'History'
export const FIRST_NAME_PARAM = 'firstName'
export const LAST_NAME_PARAM = 'lastName'
export const DELETE = 'Delete'
export const NAME = 'Name'
export const EMAIL = 'Email'
export const MOBILE = 'Mobile'
export const JOINING_DATE = 'Joining Date'
export const DESIGNATION = 'Designation'
export const EMPLOYEE = 'employee'
export const EMPLOYEE_ROLE = 'Employee Role'
export const NOT_ASSIGNED = 'Not Assigned'
export const SELECTLOADER = 'selectLoader'
export const SUPERVISOR = 'Supervisor'
export const EMPLOYMENT = 'Employment'
export const ATTENDENCE_FILTER_PARAMS = {
  name: '',
  month: '',
  year: '',
}
export const ASSET_HANDLING = 'assetHandling'

export const ATTENDENCE_TIMESHEET_PARAMS = {
  today: moment().format('YYYY-MM-DD'),
}

export const CURRENT_DATE = moment().format('DD')
export const CURRENT_MONTH = moment().format('MMMM')
export const CURRENT_YEAR = moment().format('YYYY')
export const DELETE_DESIGNATION = 'Delete Designation'
export const DELETE_BRANCH = 'Delete Designation'
export const DELETE_SHIFT = 'Delete Shift'

export const ADD_DESIGNATION = 'Add Designation'
export const UPDATE_DESIGNATION = 'Update Designation'
export const DESIGNATION_NAME = 'Designation Name'
export const BRANCH_NAME = 'Branch Name'
export const BRANCH = 'Branch'
export const SECTION = 'Section'
export const COMPANY = 'Company'
export const Description = 'Description'
export const TITLE = 'Title'

export const GET_MONTH_LIST = [
  { key: '01', value: 'Jan' },
  { key: '02', value: 'Feb' },
  { key: '03', value: 'Mar' },
  { key: '04', value: 'Apr' },
  { key: '05', value: 'May' },
  { key: '06', value: 'Jun' },
  { key: '07', value: 'Jul' },
  { key: '08', value: 'Aug' },
  { key: '09', value: 'Sep' },
  { key: '10', value: 'Oct' },
  { key: '11', value: 'Nov' },
  { key: '12', value: 'Dec' },
]
export const IS_LEAD = 'is_lead'
export const IS_ADMIN = 'is_admin'
export const IS_INTERN = 'is_intern'
export const IS_HR = 'is_hr'
export const VIEW_INTERN = 'view_intern'


export const PERMISSIONS_LIST = [
  IS_LEAD,
  IS_ADMIN,
  IS_INTERN,
  IS_HR,
  VIEW_INTERN,
]
// Permission base Methods
export const GET_METHOD = 'get'
export const POST_METHOD = 'post'
export const PUT_METHOD = 'put'
export const PATCH_METHOD = 'patch'
export const DELETE_METHOD = 'delete'
export const VIEW_ALL = 'view_all'

export const METHODS_LIST = [
  GET_METHOD,
  POST_METHOD,
  PUT_METHOD,
  PATCH_METHOD,
  DELETE_METHOD,
  VIEW_ALL,
]

export const ROLE_BREADCRUMBS = [
  { name: 'Dashboard', link: constRoute.home },
  {
    name: 'Role & Permissions',
    link: constRoute.rolePermission,
  },
]

export const ADD_DEPARTMENT = 'Add Department'
export const DELETE_DEPARTMENT = 'Delete Department'
export const UPDATE_DEPARTMENT = 'Update Department'
export const DEPARTMENT = 'Department'
export const DEPARTMENT_NAME = 'Department Name'
export const DELETE_CATEGORY = 'Delete Category'
export const UPDATE_CATEGORY = 'Update Category'
export const ADD_CATEGORY = 'Add Category'
export const CATEGORY = 'category'
export const ADD_CATEGORIES = 'Add Categories'
export const PARENT_CATEGORY = 'Parent Category'
export const CATEGORY_TYPE = 'Category Type'
export const CATEGORY_DESCRIPTION = 'Description'
export const CATEGORY_NAME = 'Category Name'

//Permissions mods
export const ROLE = 'role'
export const BRANCH_MOD = 'branch'
export const DESIGNATION_MOD = 'designation'
export const GRADE_MOD = 'gradeLevel'
export const USER_LEVEL_MOD = 'userLevel'
export const DEPARTMENT_MOD = 'department'
export const SECTION_MOD = 'section'


export const APPLY_RESIGNATION = 'Apply Resignation'
export const UPDATE_RESIGNATION = 'Update Resignation'


export const ASSETS_MOD = 'asset'
export const ASSET_CATEGORY_MOD = 'assetCategory'
export const CATEGORY_MOD = 'category'
export const ALLOWANCE_MOD = 'allowance'
export const CANTEEN_MOD = 'canteen'
export const ATTEDENCE_MOD = 'attendance'
export const FEEDBACK_MOD = 'feedback'

export const CLIENT_MOD = 'client'
export const PAYROLL_MOD = 'payroll'
export const LEAVE_TYPE_MOD = 'leave_type'
export const Remote_Work = 'remoteWork'
export const EMPLOYEE_MOD = 'employee'
export const ONBOARDING_MOD = 'employeeOnBoarded'
export const ADD_ONBOARDING = 'Add Onboarding'
export const UPDATE_ONBOARDING = 'Update Onboarding'
export const TERMINATION_MOD = 'termination'
export const CLEARANCE_MOD = 'clearance'
export const RESIGNATION_MOD = 'resignation'

export const EXPENSES_MOD = 'expenses'
export const EXPENSE_APPROVAL_MOD = 'expenses_approval'
export const EXPENSE_UPDATE = 'edit'
export const PROJECTS_MOD = 'projects'
export const SHIFIT_MOD = 'shift'
export const HR = 'HR'
export const LEAD = 'LEAD'
export const ADMIN = 'ADMIN'
export const CURRENT_STATUS = 'Current Status'
export const EMPLOYEE_STATUS = 'Employee Status'
export const ACTIONS = 'Actions'
export const ONBOARDING_LEAD = 'Lead'
export const REFFERTO_LEAD = 'Refer to Lead'
export const INTERVIEW_DATE = 'Interview Date'
export const DEFAULT_COLOR = 'rgb(45, 113, 216)'

export const STATIC_ROLES = [HR, LEAD, ADMIN]
export const FEEDBACK_TAB = 'feedback'
export const ADMIN_TAB = 'admin_tab'
export const RATINGS = ['Poor', 'Bad', 'Avarage', 'Good', 'Excellent']
export const FORMAT = 'HH:mm:ss'
export const MINUTES = 'minutes'
export const SECONDS = 'seconds'
export const HOURS = 'hours'
export const COMMENCE_PRAYER = 'commence_prayer'
export const CONCLUDE_PRAYER = 'conclude_prayer'
export const FINISH_RECESS = 'finish_recess'
export const BEGIN_RECESS = 'begin_recess'
export const PUNCH_OUT = 'punch_out'
export const PUNCH_IN = 'punch_in'