import { toJS } from 'mobx'
import { flow, types, cast } from 'mobx-state-tree'
import { expensesApi } from '../../api'
import { notification } from '@utils/notifications'

export const expense = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  categoryTitle: types.maybeNull(types.string),
  purchasedByName: types.maybeNull(types.string),
  purchasedFrom: types.maybeNull(types.string),
  authorizedByName: types.maybeNull(types.string),
  amount: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  purchasedDate: types.maybeNull(types.string),
  summary: types.maybeNull(types.string),
  categoryId: types.maybeNull(types.string),
  authorizedById: types.maybeNull(types.string),
})

export const hrExpenseModal = types
  .model({
    data: types.maybeNull(types.array(expense)),
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    erorMessage: types.maybeNull(types.string),
    nextExp: types.optional(types.number, 1),

  })
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get getExpensesData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setExpenseData = flow(function* (data) {
      try {
        self.loading = true
        yield expensesApi.setExpense(data)
        notification.success('Expense Add Successfully')
        return { status: true }
      } catch (error) {
        self.erorMessage = null
        self.erorMessage = error?.response?.data?.message
        return { success: false }
      }
      finally {
        self.loading = false
      }
    })

    const loadExpense = flow(function* (page = 1, pageSize = 20, queryParam = '', resetList = false) {
      self.loading = true
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextExp = 1
        }
        const res = yield expensesApi.getExpense(page, pageSize, queryParam)        
        if (!res?.isCancel) {
          self.data = res.items
          self.count = res.count
          if (res.next) {
            self.nextExp = self.nextExp + 1
          } else {
            self.nextExp = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Expense')
        }
      } finally {
        self.loading = false
      }
    })

    const updateExpenseData = flow(function* (id, data) {
      try {
        self.loading = true
        yield expensesApi.updateExpenseData(id, data)
        notification.success('Expense Updated Successfully')
        return { status: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
      finally {
        self.loading = false
      }
    })

    const approveExpense = flow(function* (id) {
      try {
        self.loading = true
        yield expensesApi.approveExpense(id)
        notification.success('Expense Approved Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
      finally {
        self.loading = false
      }
    })

    const rejectExpense = flow(function* (id) {
      try {
        self.loading = true
        yield expensesApi.rejectExpense(id)
        notification.success('Expense Rejected Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
      finally {
        self.loading = false
      }
    })

    return {
      setExpenseData,
      loadExpense,
      updateExpenseData,
      approveExpense,
      rejectExpense,
    }
  })

export function initHRExpenseModal() {
  return hrExpenseModal.create({})
}
