import { flow, types } from 'mobx-state-tree'
import { employeeEmergencyApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'

export const emergency = types.model({
  id: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  relation: types.maybeNull(types.string),
  contactNumber1: types.maybeNull(types.string),
  contactNumber2: types.maybeNull(types.string),
})

export const emergencyDetails = types
  .model({
    data: types.maybeNull(types.array(emergency)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get emergencyData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setEmergencyDetails = flow(function* (data) {
      try {
        self.loading = true
        yield employeeEmergencyApi.setEmergencyDetails(data)
        notification.success('Emergency Data Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'Failed to create emergency contact')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadEmergencyDetails = flow(function* (employee_id) {
      try {
        self.loading = true
        const res = yield employeeEmergencyApi.getEmployeeEmergencyDetails(employee_id)
        self.data = res
      } catch (error) {
        notification.error( error?.response?.data?.message || 'Failed to Load Emergency Contacts')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmergencyDetails = flow(function* (id, data) {
      try {
        self.loading = true
        yield employeeEmergencyApi.updateEmployeeEmergencyDetails(
          id,
          data
        )
        notification.success('Emergency Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'Failed to update contact')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEmergencyDetails = flow(function* (id) {
      try {
        self.loading = true
        yield employeeEmergencyApi.deleteEmployeeEmergencyDetails(id)
        notification.success('Emergency Contact Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error('Failed to delete Emergency Contact')
      } finally {
        self.loading = false
      }
    })

    return {
      loadEmergencyDetails,
      updateEmergencyDetails,
      setEmergencyDetails,
      deleteEmergencyDetails,
    }
  })

export function initEmployeeEmergencyDetails() {
  return emergencyDetails.create({})
}
