import React, { useEffect } from 'react'
import { Col, Form, Radio, Row, } from 'antd'
import 'antd/dist/antd.css'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { red } = colors

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const AllowanceForm = observer((props: any, IsUpdate) => {
  const [form] = Form.useForm()

  const { allowanceModel: { createAllowance, updateAllowance } } = useStore(null)

  useEffect(() => {
    form.resetFields()
    if (props) {
      form.setFieldsValue({
        title: props.title,
        amount: props.amount,
      })
    }
  }, [props])

  useEffect(() => {
    form.resetFields()
    if (IsUpdate) {
      form.setFieldsValue({
        title: props.data.title,
        amount: props.data.amount,
      })
    }
  }, [props.data, IsUpdate])

  const onFinish = async values => {
    let response = null
    if (props?.data?.id) {
      response = await updateAllowance(values, props?.data?.id)
    } else {
      response = await createAllowance(values)
    }
    if (response.success) {
      await props.setRefreshTable(true)
      props.setOpenModal(false)
      props.resetSelectedData()
      props.setIsUpdate(false)
    }
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        scrollToFirstError
        id='allowance-form'
      >
        <div className='form-auto-height'>
          <Row>
            <Icon>Type</Icon>
          </Row>

          <StyledRadioBtnWrapper>
            <Row>
              <Form.Item name='wage_type' initialValue={props.IsUpdate ? props?.data?.wageType : 'Allowance'}>
                <Radio.Group>
                  <Row>
                    <Col span={12}>
                      <Radio value={'allowance'}>
                        <p>Allowance</p>
                      </Radio>
                    </Col>
                    <Col span={12}>
                      <StyleRadio value={'deduction'}>
                        <p>Deduction</p>
                      </StyleRadio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Row>
          </StyledRadioBtnWrapper>
          <Row>
            <Col span={24}>
              <Form.Item
                name='title'
                label='Name'
                rules={[{ required: true, message: 'Enter valid name' }]}
              >
                <CommonInput
                  inputType='text'
                  placeholder='Enter Name'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name='amount'
                label='Amount'
                rules={[{ pattern: new RegExp(/^[0-9]+$/i), required: true, message: 'Enter Amount in Numbers' }]}
              >
                <CommonInput
                  inputType='number'
                  placeholder='Enter Amount in Numbers'
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>

  )
})
export default AllowanceForm;

const Icon = styled.span`
::after{
  display: inline-block;
  margin-right: 4px;
  color: ${red};
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
`

const StyledRadioBtnWrapper = styled.div`
  display: flex;
  justify-content: left;
  font-weight: 400;
  margin: 21px 0px 29px 33px;
  font-family: 'Poppins';
  gap: 3px;
  .ant-radio-inner {
    width: 24px;
    height: 24px;
    margin: 0px 3px -3.5px 0px;
    background: #ffffff;
    border: 1.2px solid #e2e2e2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 26.4px;
    &::after {
      width: 24px;
      height: 24px;
      top: 7px;
      left: 7px;
    }
  }
  p {
    word-spacing: 2px !important;
  }
`
const StyleRadio = styled(Radio)`
margin-left:6.4rem;
`