import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { leaveUrl } from '../const/index'

class EmployeeCancelLeaveApi extends BaseApi {
  setEmployeeLeaveCancellation = async (leaveId: string, data: {cancellation_reason: string}) => {
    try {
      const response = await this.axios.put(`${leaveUrl}/cancel-leave/${leaveId}`, data)
    
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getEmployeeLeaveCancellation = async num => {
    try {
      const response = await this.axios.get(
        `${leaveUrl}/get-cancel-leaves?remote=false&page=${num ? num : 1}`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}
      
export default EmployeeCancelLeaveApi
