import moment from 'moment'
import { useEffect } from 'react'
import { feedbackApi } from '@api'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { RATINGS } from 'constant/constant'
import { useStore } from '@stores/root-store'
import TextArea from 'antd/lib/input/TextArea'
import { Col, Form, Rate, Row, Skeleton } from 'antd'
import { validateRequired } from '@utils/common-functions/formValidations'

interface Props {
  question_id: string;
  message: string;
  rating: number;
  answers: string;
  for_employee: string;
  note: string;
  feedback_id: string;
}

export const FeedbackQuestionDrawer = observer(props => {
  const [form] = Form.useForm()

  const {
    feedbackModal: {
      loadLeadSingleFeedBack,
      getFeedback,
      isLoading,
      loadLeadToEmpFeedBack,
      getFeedbackData,
    },
  } = useStore(null)

  useEffect(() => {
    async function getData() {
      await loadLeadSingleFeedBack()
      await loadLeadToEmpFeedBack(props?.empRecord?.id)
    }
    getData()
  }, [props?.empRecord?.id])

  const currentDate = moment();
  const formattedDate = currentDate.format('YYYY-MM-DD');
  const isDisabled = (formattedDate >= getFeedback?.startDate && formattedDate <= getFeedback?.endDate) ? false : true;


  const submitFeedback = async (values: Props) => {
    const answers = getFeedback?.questions?.map(
      (question: { questionId: string }) => ({
        question_id: question.questionId,
        message: values[`message-${question.questionId}`],
        rating: values[`rating-${question.questionId}`],
      })
    )

    const updateAnswers = (getFeedbackData, values) =>
      getFeedbackData?.answers?.map(e => ({
        id: e.id,
        question_id: e.questionId,
        message: values[`message-${e.questionId}`],
        rating: values[`rating-${e.questionId}`],
      }));

    const data = {
      answers: answers,
      for_employee: props?.empRecord?.id,
      note: values.note,
      feedback_id: getFeedback.id,
    }

    const UpdateData = {
      answers: updateAnswers,
      for_employee: props?.empRecord?.id,
      note: values.note,
      feedback_id: getFeedback.id,
    }

    if (getFeedbackData) {
      await feedbackApi.updateLeadToEmpFeedback(props?.empRecord?.id, UpdateData)
    } else {
      await feedbackApi.setLeadToEmpFeedback(data)
    }
    props.setshowFeedBackDrawer(false), props.setRefreshTable(true)
  }

  useEffect(() => {
    if (getFeedbackData?.note) {
      getFeedbackData?.answers?.forEach(ques => {
        form.setFieldsValue({
          [`message-${ques.questionId}`]: ques.message,
          [`rating-${ques.questionId}`]: ques.rating,
          note: getFeedbackData.note,
        })
      })
    } else {
      form.resetFields()
    }
  }, [getFeedbackData])

  return (
    <>
      <Skeleton
        active
        loading={isLoading}
        paragraph={{ rows: 5 }}
        style={{ paddingLeft: 40, paddingRight: 250, paddingTop: 50 }}
      >
        <QuestionFeed>
          <Form
            form={form}
            id='feedback-form'
            onFinish={submitFeedback}
            layout='vertical'
          >
            {getFeedback?.questions?.length
              ? getFeedback.questions.map(question => (
                <Col key={question.questionId} className='feedbackFields'>
                  <Row className='justify-content-between'>
                    <p className='questionTag'>
                      {question.question}
                      <span className='asteriskDesign'>*</span>
                    </p>
                    <Form.Item
                      className='rating'
                      name={`rating-${question.questionId}`}
                      rules={[validateRequired('Rating')]}
                    >
                      <Rate
                        tooltips={RATINGS}
                        disabled={isDisabled}
                      />
                    </Form.Item>
                  </Row>
                  <Col>
                    <Form.Item
                      name={`message-${question.questionId}`}
                      rules={[validateRequired('Answer')]}
                    >
                      <CustomTextArea
                        data-testid='testH3'
                        className='feedbackInput'
                        rows={1}
                        placeholder='Write your feedback'
                        disabled={isDisabled}
                      />
                    </Form.Item>
                  </Col>
                </Col>
              ))
              : 'No Feedback'}
            {getFeedback?.questions?.length ? (
              <Col>
                <FormItem
                  label='Additional Note'
                  name='note'
                  rules={[validateRequired('Additional Note')]}
                >
                  <CustomTextArea
                    className='feedbackInput'
                    placeholder='A brief statement'
                    disabled={isDisabled}
                  />
                </FormItem>
              </Col>
            ) : (
              ''
            )}
          </Form>
        </QuestionFeed>
      </Skeleton>
    </>
  )
})

const QuestionFeed = styled.div`
  padding: 30px !important;
  .questionTag {
    align-items: center;
    color: #2e71d8;
    margin-bottom: 8px;
    margin-top: 8px;
    display: flex;
    width: fit-content;

    &:first-letter {
      text-transform: capitalize !important;
    }
  }
  .feedbackInput {
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    color: dimgray;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }
  .rating {
    margin-bottom: 11px !important;
  }
  .feedbackFields {
    padding: 12px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dfdfdf;
  }
  .asteriskDesign {
    color: red;
    font-size: 14px;
    padding-left: 3px;
  }
`
const FormItem = styled(Form.Item)`
  .ant-form-item-label label {
    color: #2e71d8 !important;
  }
`
const CustomTextArea = styled(TextArea)`
  display: flex;
  justify-content: center;
  align-items: center;
`
