export const INITIAL_VALUES = { isOpen: false, isUpdate: false }

export const METHOD_NAMES = {
    get: 'get',
    post: 'post',
    put: 'put',
    patch: 'patch',
    delete: 'delete',
    view_all: 'view_all',
  };

  export const PERMISSIONS_NAME = {
    is_lead: 'is_lead',
    is_admin: 'is_admin',
    is_intern: 'is_intern',
    // is_hr: 'is_hr',
    view_intern: 'view_intern',
  };

