import { flow, types, cast } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { onBoardingApi } from '@api'
import { notification } from '@utils/notifications'

export const onboarding = types.model({
  id: types.maybeNull(types.string),
  attachments: types.maybeNull(types.string),
  contact_number: types.maybeNull(types.number),
  department: types.maybeNull(types.string),
  department_name: types.maybeNull(types.string),
  designation: types.maybeNull(types.string),
  designation_name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  first_name: types.maybeNull(types.string),
  last_name: types.maybeNull(types.string),
  is_intern: types.maybeNull(types.boolean),
  referred_name: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  referred_to_id: types.maybeNull(types.string),  
  summery: types.maybeNull(types.string),
})
export const onboardingModal = types
  .model({
    data: types.maybeNull(types.array(onboarding)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextDep: types.optional(types.number, 1),
  })
  .views(self => ({
    get getOnboardingData() {
      return toJS(self.data)
    },
  
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setOnboardingData = flow(function* (data) {
      try {
        self.loading = true
        yield onBoardingApi.setOnBoarding(data)
        notification.success('Employee onboard Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {   
        self.loading = false
      }
    })
    const loadOnboarding = flow(function* (page=1, pageSize=20, queryParam='', resetList=false, saveInStore=true) {
      try {
        self.loading = true
        self.data = cast([])
        if (resetList) {
          self.data = cast([])
          self.nextDep = 1
        }
        const res = yield onBoardingApi.getOnBoarding(page , pageSize, queryParam)
        self.data = res?.items

        if (!res?.isCancel && saveInStore){
          self.data = cast([...toJS(self.data ?? []), ...res.items])
          self.count = res.count
          if (res.next) {
            self.nextDep = self.nextDep + 1
          } else {
            self.nextDep = 1
          } 
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          // notification.success('Failed to Load Department')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateOnBoarding = flow(function* (id, data) {
      try {
        self.loading = true
        yield onBoardingApi.updateOnBoarding(id, data)
        notification.success('Employee onboard  Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateOnBoardingLead = flow(function* (id, data) {
      try {
        self.loading = true
        yield onBoardingApi.updateOnBoardingLead(id, data)
        notification.success('Employee onboard  Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })


    return {
      setOnboardingData,
      loadOnboarding,
      updateOnBoarding,
      updateOnBoardingLead
    }
  })

export function initonboardingModal() {
  return onboardingModal.create({})
}
