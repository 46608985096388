import moment from 'moment'
import styled from 'styled-components'
import { MdEdit } from 'react-icons/md'
import { Checkbox, Tooltip } from 'antd'
import { useStore } from '@stores/root-store'
import { feedbackQuestion } from '@api/const'
import { Table } from '@commonComponents/table-v2'
import CustomButton from '@commonComponents/button'
import { SetStateAction, useEffect, useState } from 'react'
import { feedbackApi, feedbackLeadQuestionsApi } from '@api'
import { permit } from '@utils/permission/permission'
import { FEEDBACK_MOD, PUT_METHOD } from 'constant/constant'

interface Props {
  setRefreshTable: React.Dispatch<SetStateAction<boolean>>;
  refreshTable: boolean;
  setVisible: React.Dispatch<SetStateAction<boolean>>;
  getCurrentQuestion: (question: string) => void;
}

const FeedbackTable = ({
  setRefreshTable,
  refreshTable,
  getCurrentQuestion,
  setVisible,
}: Props) => {

  const {
    feedbackModal: {
      getFeedback,
      loadLeadSingleFeedBack,
    },
    userInfo:{getRoles}
  } = useStore(null)


  const canEdit = permit(getRoles,FEEDBACK_MOD,PUT_METHOD)

  const [selectedQuestions, setSelectedQuestions] = useState([])

  const currentDate = moment();
  const formattedStartDate = moment({ year: currentDate.year(), month: currentDate.month(), day: 20 }).format('YYYY-MM-DD');
  const formattedEndDate = moment({ year: currentDate.year(), month: currentDate.month(), day: 28 }).format('YYYY-MM-DD');

  const submitData = async () => {
    await feedbackApi.createFeedback({
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      questions: selectedQuestions,
    })
  }
  const handleChange = (value, status) => {
    setSelectedQuestions(prev => status ? [...prev, value] : prev.filter(e => e !== value))
  }

   const  checkQuestionIdAvailability = id => {
    if (getFeedback?.questions) {
      return getFeedback?.questions?.some(question => question?.questionId === id);
    }
    return false;
  }

  useEffect(() => {
    const getData = async () => {
      await feedbackLeadQuestionsApi.loadLeadFeedbackQuestions()
      await loadLeadSingleFeedBack()
    }
    getData()
  }, [])

  const formattedDate = currentDate.format('YYYY-MM-DD')
  const isDisabled = (!getFeedback?.startDate || !getFeedback?.endDate || (formattedDate >= getFeedback?.startDate && formattedDate <= getFeedback?.endDate)) ? false : true;

  const columns = [
    {
      title: <Styleleft style={{ display: 'flex', justifyContent: 'space-between' }}><div>Question</div><CustomButton title={'Add Question'} onClick={submitData} /></Styleleft>,
      render: (_, row) => (
        <QuestionColoum>
          <Checkbox style={{ marginRight: 10 }} onChange={e => handleChange(row.id, e.target.checked)} defaultChecked={checkQuestionIdAvailability(row.id)} disabled={isDisabled} />
          <NameField>{row.title}</NameField>
        </QuestionColoum>
      ),
    },
    canEdit &&  {
      title: <Styleleft>Actions</Styleleft>,
      render: text => (
        <FlexedContainer>
          <Tooltip title={isDisabled ? '' : 'Edit Question'}>
            <button
              disabled={isDisabled}
              onClick={() => {
                setVisible(true)
                getCurrentQuestion(text)
              }}
            >
              <MdEdit size={20} />
            </button>
          </Tooltip>
        </FlexedContainer>
      ),
    },
  ]

  return (
    <Table
      columns={columns.filter(Boolean)}
      dataUrl={feedbackQuestion}
      refreshTable={refreshTable}
      setRefreshTable={setRefreshTable}
    />
  )
}

export default FeedbackTable

export const NameField = styled.div`
  text-align: start;
  font-size: 70;
  word-wrap: break-word;
`
const QuestionColoum = styled.div`
display: flex;
flex-direction  : row;
`

const Styleleft = styled.div`
  display: flex;
  justify-content: flex-start;
`

const FlexedContainer = styled.div`
  display: flex;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:hover {
      background-color: lightgrey;
      cursor: pointer;
    }
  }
`
