import { flow, types} from 'mobx-state-tree'
import { toJS } from 'mobx'
import { onBoardingHistoryApi } from '@api'
import { notification } from '@utils/notifications'


export const historyModel = types.model({
  updatedAt:types.maybeNull(types.string),
  lead:types.maybeNull(types.string),
  status:types.maybeNull(types.string),
  currentStatus:types.maybeNull(types.string),
  onPayroll:types.maybeNull(types.string),
})

export const onboardedDetailModel = types.model({
  candidateName:types.maybeNull(types.string),
  departmentDepartmentName:types.maybeNull(types.string),
  mobileNumber:types.maybeNull(types.string),
  email:types.maybeNull(types.string),
})
export const onboardingHistory = types.model({
  onboardedDetail: types.maybeNull(onboardedDetailModel),
  history: types.maybeNull(types.array(historyModel)),
  
})
export const onboardingHistoryModal = types
  .model({
    data: types.maybeNull(onboardingHistory),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextDep: types.optional(types.number, 1),
  })
  .views(self => ({
    get getOnboardingHistoryData() {
      return toJS(self.data)
    },
  
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
   
    const loadOnboardingHistory = flow(function* (id) {
      try {
        self.loading = true
    
        const res = yield onBoardingHistoryApi.getOnBoardingHistory(id)
        if (!res?.isCancel){
          self.data = toJS(res)
          self.count = res?.count && res.count
          if (res.next) {
            self.nextDep = self.nextDep + 1
          } else {
            self.nextDep = 1
          } 
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Onboarding')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadOnboardingHistory,
    }
  })

export function initonboardingHistoryModal() {
  return onboardingHistoryModal.create({})
}
