import { getMessaging, onMessage, getToken } from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import { omsNotificationApi } from '../../../api/index'

const firebaseConfig = {
  apiKey: 'AIzaSyAAn-V0SJ5K4hjdec9Csqr9Mw2OBr-32lE',
  authDomain: 'enigma-oms.firebaseapp.com',
  projectId: 'enigma-oms',
  storageBucket: 'enigma-oms.appspot.com',
  messagingSenderId: '576279665383',
  appId: '1:576279665383:web:a6c20cbf8bc45f85762042',
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const requestForToekn = () => {
  return getToken(messaging).then(currentToken => {
    if (currentToken) {
      const storedToken = localStorage.getItem('fcmToken')
      if (storedToken !== currentToken) {
        const data={
          registration_id: currentToken,
          type: 'web',
        }
        omsNotificationApi.storeFcmToken(data)
        localStorage.setItem('fcmToken', currentToken)
      }
    }
  })
}

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload)
    })
  })
