import { headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class TeamMembersApi extends BaseApi {
  setTeamMembersDetails = async (data, id) => {
    try {
      const response = await this.axios.post(
        `${baseUrl}teamMembers/`,
        {
          team: id,
          member: data.member_name,
          role: data.member_role,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getTeamMembersDetails = async () => {
    try {
      const response = await this.axios.get(`${baseUrl}teamMembers/`, headerAuthorization(this))
      return response
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateTeamMembersDetails = async (data, recordId, teamID) => {
    try {
      const response = await this.axios.put(
        `${baseUrl}teamMembers/${recordId}/`,
        {
          team: teamID,
          member: data.member_name,
          role: data.member_role,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteTeamMembersDetails = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}teamMembers/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default TeamMembersApi
