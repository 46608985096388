import {useNavigate } from 'react-router-dom'
import { Form, Row, Col } from 'antd'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import { CommonInput } from '@commonComponents/input'
import { MdPerson, MdMail, MdOutlineBusiness, MdLock } from 'react-icons/md'
import signupBg from '@assets/img/signupBg.png'
import signinlogo from '@assets/img/signinlogo.svg'
import React from 'react'
import styled from 'styled-components'
import CustomButton from '@commonComponents/button'
import { Helmet } from 'react-helmet'
import { documentTitle } from '@utils/page-title'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

const RegistrationPage = () => {
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const {
    signupInfo: { registerInfo, isLoading },
  } = useStore(null)

  const onSignup = async values => {
    const response = await registerInfo({
      name: values.companyName,
      username: values.userName,
      email: values.email,
      password: values.password,
    })
    if (response.success) {
      navigate(`${constRoute.login}`)
      localStorage.setItem('userRegister', 'true')
      form.resetFields()
    }
  }

  return (
    <>
      <WrapperPage>
        <Helmet>
          <title>{documentTitle.signup}</title>
        </Helmet>
        <LeftSide>
          <div style={{ position: 'absolute', top: '32px', left: '37px' }}>
            <img style={{width:'80%',height:'80%'}} src={signinlogo} />
          </div>

          <ButtonStyle>
            <AlreadySign>Already Signed up?</AlreadySign>
            <ParagraphT>
              Login Your account soyou can continue your working
            </ParagraphT>
            <Link to={`${constRoute.login}`}>
              <Buttonstyle>Login</Buttonstyle>
            </Link>
          </ButtonStyle>
        </LeftSide>
        <RightSide>
          <HeaderText>Signup For an Account</HeaderText>
          <ParagraphText>
            Lets get youall set up so you can start creating your first
            onbording experience
          </ParagraphText>
          <Form
            layout='vertical'
            form={form}
            name='basic'
            initialValues={{ remember: true }}
            onFinish={onSignup}
            autoComplete='off'
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 12]}>
              <Col xs={24} md={12} xl={12}>
                <Form.Item
                  label={<LabelName>User Name</LabelName>}
                  name='userName'
                  rules={[{ required: true, message: 'Username is required' }]}
                >
                  <InputDesign
                    prefix={<MdPerson />}
                    inputType='text'
                    placeholder='John H'
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={12}>
                <Form.Item
                  name='companyName'
                  rules={[
                    { required: false, message: 'Company name is required' },
                  ]}
                  label={<LabelName>Company Name</LabelName>}
                >
                  <InputDesign
                    placeholder='Enigmatix'
                    prefix={<MdOutlineBusiness />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col sm={24} md={24} xl={24}>
              <Form.Item
                name='email'
                label={<LabelName>Email</LabelName>}
                validateTrigger={['onBlur']}
                rules={[
                  { type: 'email', message: 'Email is invalid' },
                  { required: true, message: 'Email is required' },
                ]}
              >
                <CommonInput
                  inputType='email'
                  prefix={<MdMail />}
                  onFocus={() =>
                    form.setFields([{ name: 'email', errors: [] }])
                  }
                  placeholder='example@test.com'
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={24} xl={24}>
              <Form.Item
                label={<LabelName>Password</LabelName>}
                name='password'
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <CommonInput
                  prefix={<MdLock />}
                  inputType='password'
                  placeholder='Ex: Admin@123'
                  onInput={e => {
                    e.target.value = e.target.value.trim()
                  }}
                />
              </Form.Item>
            </Col>
            <Form.Item>
              {' '}
              <StyledLoginButton
                loading={isLoading}
                htmlType='submit'
                className='d-block w-100'
                title='SIGNUP'
              />
            </Form.Item>
          </Form>
        </RightSide>
      </WrapperPage>{' '}
    </>
  )
}

export default observer(RegistrationPage)
const StyledLoginButton = styled(CustomButton)`
  border-radius: 8px !important;
` 

const WrapperPage = styled.div`
  display: flex;
  height: 100vh;
  background: url(${signupBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`
const LeftSide = styled.div`
  width: 39.5%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const RightSide = styled.div`
  width: 60.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:80px;
`
const HeaderText = styled.div`
  font-family: 'Poppins';
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  color: ${TealishBlue};
  width: 100%;
  text-align: center;
`
const ParagraphText = styled.p`
  font-family: 'Poppins';
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: ${TealishBlue};
  width: 75%;
  margin-top: 20px;
  margin-bottom: 20px;
`

const LabelName = styled.span`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: ${TealishBlue};
`
const InputDesign = styled(CommonInput)`
  height: 52px;
`




const AlreadySign = styled.div`
  font-family: 'Poppins';
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 5px;
`

const ParagraphT = styled.div`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
`
const Buttonstyle = styled.button`
  width: 60%;
  color: #ffffff;
  border: 1px solid #ffffff;
  outline: none;
  display: block;
  margin: auto;
  background: #${TealishBlue};
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  margin-top: 20px;
`
const ButtonStyle = styled.div`
  width: 60%;
  margin-right: 10px;
`
