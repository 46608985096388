
import { gradeLevelUrl } from '../const'
import { BaseApi } from '../baseApi'

class GradeApi extends BaseApi {
  setGrade = async data => {
    try {
      const response = await this.axios.post(gradeLevelUrl, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getGrade = async (num: 1, pageSize, queryParam = {}) => {
    try {
      const url = `${gradeLevelUrl}?page=${num}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateGrade = async (id, data) => {
    try {
      const response = await this.axios.patch(`${gradeLevelUrl + id}/`, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteGrade = async id => {
    try {
      const response = await this.axios.delete(`${gradeLevelUrl + id}/`)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default GradeApi
