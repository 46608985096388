import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Card, Space } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import DeletePopUp from '@commonComponents/delete-popup'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import CustomButton from '@commonComponents/button'
import { Table } from '@commonComponents/table-v2'
import { employeeLeaveUrl } from '@api/const'
import CustomDrawer from '@commonComponents/drawer'
import {
  UPDATE_LEAVE,
  CANCEL,
  LEAVE_APPLICATION,
  ADD_LEAVE,
  APPLY_LEAVE,
  CANCEL_MY_LEAVE,
  DELETE,
  CANCEL_LEAVE_APPLICATION,
  DELETE_LEAVE_APPLICATION,
  TAB_LIST,
  EMPLOYEE_TAB_LIST,
  CANCEL_FULL_LEAVE_MESSAGE,
} from './const'
import { getLeaveColumns, getSideDrawerHeader } from './utils'
import styled from 'styled-components'
import EmployeeLeaveForm from './EmployeeLeave/EmployeeLeaveForm/employeeLeaveForm'
import EmployeeCancelLeaveApprovalTable from './EmployeeLeave/LeadAdminView/employeeCancelLeaveApprovalTable'
import EmployeeLeaveApprovalTable from './EmployeeLeave/LeadAdminView/employeeLeaveApprovalTable'
import EmployeeLeaveHistoryTable from './EmployeeLeave/LeadAdminView/employeeLeaveHistoryTable'
import EmployeeFilter from './EmployeeLeave/LeadAdminView/employeeFilter'
import EmployeeLeaveCount from './EmployeeLeave/EmployeeLeadView/leaveCount'
import { permit } from '@utils/permission/permission'
import ShowOnPermission from '@commonComponents/permit'

const EmployeeLeave = observer(() => {
  const [searchParams] = useSearchParams()
  useEffect(() => {
    searchParams.get('leave_status') &&
      setQueryParam({ leave_status: searchParams.get('leave_status') })
    searchParams.get('plan') &&
      setQueryParam({ plan: searchParams.get('plan') })
    searchParams.get('id') && setQueryParam({ id: searchParams.get('id') })
  }, [
    searchParams.get('leave_status'),
    searchParams.get('plan'),
    searchParams.get('id'),
  ])

  const [currentData, setCurrentData] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [viewDetail, setViewDetail] = useState(false)
  const [activeTab, setActiveTab] = useState('')
  const [refreshTable, setRefreshTable] = useState(false)
  const tableRef = useRef(null)
  const [isCancel, setIsCancel] = useState(false)
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const [resetTable, setResetTable] = useState(false)
  const [isLeaveDisable, setIsLeaveDisable] = useState(false)
  const [tabList, setTabList] = useState([])
  const [queryParam, setQueryParam] = useState({})
  // const [, setIsAddBtnDisable] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')
  const [isDisable, setIsDisable] = useState(true)
  const [isDeleteClicked, setIsDeleteClicked] = useState(false)
  const [isRemote, setIsRemote] = useState(false)
  const [resetFilters, setResetFilters] = useState(false)
  const [state, setState] = useState(false)
  const [checkedData, setCheckedData] = useState([])

  const {
    employeeLeaveModal: { isLoading, deleteEmployeeLeave },
    userInfo: { getRoles, getcompany },
    employeeLeaveCancellationModal: { setEmployeeLeaveCancellation },
  } = useStore(null)
  const isLead = getcompany?.isLead
  const isAdmin = permit(getRoles, 'accessView', 'is_admin')

  useEffect(() => {
    setCheckedData([])
  }, [queryParam])  

  useEffect(() => {
    let tab_List = []
      if (!isAdmin) {
        tab_List = tab_List.concat(EMPLOYEE_TAB_LIST);
      }
      if (isLead || isAdmin) {
        tab_List = tab_List.concat(TAB_LIST);
      }
    setTabList(tab_List)
    setActiveTab(tab_List[0].key)
  }, [isAdmin, isLead])

  const onEdit = data => {
    setCurrentData(data)
    setIsUpdate(true)
    setVisible(true)
    setIsCancel(false)
  }

  const cancelLeave = data => {
    setCurrentData(data)
    setIsCancel(true)
    setIsUpdate(true)
    setVisible(true)
  }

  const onDelete = data => {
    setCurrentData(data)
    setVisibleDelete(true)
  }

  const onCancelLeave = async () => {
    const props = {
      cancellation_reason: cancellationReason,
    }
    const response = await setEmployeeLeaveCancellation(currentData?.id, props)
    if (response?.success) {
      setVisibleDelete(false)
      setRefreshTable(true)
    }
  }
  const deleteEmployeeLeaveHandler = async () => {
    const res = await deleteEmployeeLeave(currentData?.id);
    if (res.success) {
      setVisibleDelete(false);
      if (totalRecordRemaining === 1) {
        setResetTable(true);
      } else {
        setRefreshTable(true);
      }
      setIsLeaveDisable(false);
    }
  };

  const onTabChange = key => {
    setActiveTab(key)
    setResetFilters(true)
    setQueryParam({})
  }
  const columns = useMemo(
    () =>
      getLeaveColumns(
        setIsLeaveDisable,
        cancelLeave,
        onDelete,
        onEdit,
        setViewDetail,
        setIsDeleteClicked,
        false,
        setIsLeaveDisable
      ),
    [refreshTable]
  )
  const contentList = {
    myLeaves: (
      <>
        <br />
        <div className='table-responsive'>
          <MyLeavesTable
            ref={tableRef}
            dataUrl={`${employeeLeaveUrl}`}
            columns={columns}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            resetTable={resetTable}
            queryParam={queryParam}
            setResetTable={setResetTable}
            setTotalRecordRemaining={setTotalRecordRemaining}
            loaderPosition={'center'}      
          />
        </div>
      </>
    ),
    approval: (
      <div className='table-responsive'>
        <EmployeeLeaveApprovalTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
          resetTable={resetTable}
          setResetTable={setResetTable}
          state={state}
          setState={setState}
          checkedData={checkedData}
          setCheckedData={setCheckedData}
          loaderPosition={'center'}      
        />
      </div>
    ),
    cancelApproval: (
      <div className='table-responsive'>
        <EmployeeCancelLeaveApprovalTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
          resetTable={resetTable}
          setResetTable={setResetTable}
          loaderPosition={'center'}  
        />
      </div>
    ),
    history: (
      <div className='table-responsive'>
        <EmployeeLeaveHistoryTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
          resetTable={resetTable}
          setResetTable={setResetTable}
          loaderPosition={'center'}
        />{' '}
      </div>
    ),
  }

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='customTabs row align-items-center'>
            <div className='d-flex justify-content-between align-items-start mb-3'>
              <BreadCrumbs
                list={[
                  { name: 'Dashboard', link: constRoute.home },
                  { name: 'Leave Application', link: constRoute.leaveEmployee },
                ]}
                pageTitle={LEAVE_APPLICATION}
              ></BreadCrumbs>
              {!isAdmin && (
                <ShowOnPermission mod={'leaveApplication'} method={'post'}>
                  <StyledCustomButton
                    id='apply-leave-button'
                    disabled={isLeaveDisable}
                    onClick={() => {
                      setViewDetail(false)
                      setVisible(true)
                      setIsUpdate(false)
                      setIsCancel(false)
                      // setIsAddBtnDisable(false)
                      setIsRemote(false)
                    }}
                    title={APPLY_LEAVE}
                  />
                </ShowOnPermission>
              )}
            </div>
            <>
              <TabCard
                className='d-flex justify-content-between align-items-center mb-4'
                tabList={tabList}
                tabProps={{ id: 'leave' }}
                activeTabKey={activeTab}
                onTabChange={key => {
                  onTabChange(key)
                }}
              />
              <div className='mb-0'>
                {(isAdmin || isLead) && activeTab !== 'myLeaves' && (
                  <EmployeeFilter
                    activeTab={activeTab}
                    setRefreshTable={setRefreshTable}
                    setQueryParam={setQueryParam}
                    setResetTable={setResetTable}
                    resetFilters={resetFilters}
                    setResetFilters={setResetFilters}
                  />
                )}
                {!isAdmin && activeTab === 'myLeaves' && (
                  <EmployeeLeaveCount setQueryParam={setQueryParam} />
                )}

                {checkedData.length ? (
                  <StyledButton
                    className='approved'
                    onClick={() => setState(true)}
                  >
                    Approve
                  </StyledButton>
                ) : null}
              </div>
            </>
          </div>
        </div>
        {/* /Page Header */}
        {contentList[activeTab]}
      </div>

      {/* /Page Content */}
      {/* Add EmployeeLeave Modal */}
      <StyledCustomDrawer
        title={getSideDrawerHeader(viewDetail, isUpdate, isRemote)}
        visible={visible}
        setVisible={setVisible}
        className='addDrawer'
        width={500}
        customFooter={
          !viewDetail && (
            <Space>
              <CustomButton
                htmlType='submit'
                type='primary'
                title={CANCEL}
                className='cancelButton'
                onClick={() => setVisible(false)}
                id='leave-drawer-cancel'
              />
              <CustomButton
                // show={isAddBtnDisable}
                form='employee-form'
                htmlType='submit'
                type='primary'
                title={isUpdate ? UPDATE_LEAVE : ADD_LEAVE}
                className='addButton'
                id='leave-drawer-submit'
              />
            </Space>
          )
        }
      >
        <EmployeeLeaveForm
          update={isUpdate}
          isCancel={isCancel}
          data={currentData}
          viewDetail={viewDetail}
          setVisible={setVisible}
          counts={[]} // need to add logic to show counts for the Employee Leave
          setRefreshTable={setRefreshTable}
          leaveApproval={true}
          // setIsAddBtnDisable={setIsAddBtnDisable}
        />
      </StyledCustomDrawer>
      {/* Delete EmployeeLeave Modal */}
      <DeletePopUp
        delete={
          currentData?.type?.length ? onCancelLeave : deleteEmployeeLeaveHandler
        }
        msgTitle={
          currentData?.type?.length
            ? CANCEL_LEAVE_APPLICATION
            : DELETE_LEAVE_APPLICATION
        }
        renderMsg={
          currentData?.type == 'cancel'
            ? CANCEL_FULL_LEAVE_MESSAGE
            : null
        }
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText={currentData?.type?.length ? CANCEL_MY_LEAVE : DELETE}
        handleCancel={() => {
          setVisibleDelete(false)
          setIsDisable(true)
        }}
        type={currentData?.type || ''}
        isCancelLeavePopUp={true}
        setCancellationReason={setCancellationReason}
        setIsDisable={setIsDisable}
        isDisable={isDisable}
        isDeleteClicked={isDeleteClicked}
      />
    </div>
  )
})

export default EmployeeLeave

const TabCard = styled(Card)`
  .ant-tabs-nav-more {
    visibility: hidden;
    order: 1;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgb(45, 113, 216) !important;
  }
`
const MyLeavesTable = styled(Table)`
  .ant-pagination-item a {
    justify-content: center !important;
  }
  .ant-select .ant-select-selector {
    justify-content: center;
    display: flex;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    justify-content: center;
    text-align: center;
    display: flex;
  }
  .ant-select .ant-select-selection-item {
    text-align: center;
    justify-content: center;
    display: flex !important;
    margin-top: 3px;
  }
  .ant-table-cell:nth-child(4) {
    width: 130px !important;
    min-width: 130px;
  }
`
const StyledCustomDrawer = styled(CustomDrawer)`
  .ant-drawer-close {
    margin-top: 2px;
  }
`

const StyledCustomButton = styled(CustomButton)`
  float: right;
  margin-bottom: 30px !important;
`
const StyledButton = styled.button`
  @media screen and (max-width: 991px) {
    float: none;
  }
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16d61e;
  color: #ffffff;
  border-radius: 20px;
  height: 42px;
  margin-top: -15px;
  margin-bottom: -36px;
  border-color: transparent;
  width: 130px;
  .icon {
    display: flex;
    background: white;
    border-radius: 50%;
    margin-right: 4px;
    svg {
      color: #525353;
      text-align: center;
      padding: 2px;
    }
  }
`
