import { Button, Space, notification as antdNotification } from 'antd'
import error from '../../assets/img/error.png'
import success from '../../assets/img/success.png'
import alert_bell from '../../assets/img/alert_bell.png'
import info from '../../assets/img/info.png'
import warning from '../../assets/img/warning.png'
import alertIcon from '../../assets/img/alertIcon.png'
import style from './style.module.scss';
import { PopupData } from '../../components/initialpage/Sidebar/header'
export const notification = {
  error(message: string) {
    return antdNotification.error({
      message: '',
      placement: 'topRight',
      description: message,
      key: 'error',
      duration: 2,
      closeIcon: false,
      className: style.allNotification,
      icon: <img style={{ width: '90px', marginLeft: '-29px', marginTop: '-21px' }} src={error}></img>

    })
  },

  success(message: string) {
    return antdNotification.success({
      message: '',
      placement: 'topRight',
      description: message,
      key: 'success',
      duration: 2,
      closeIcon: false,
      className: style.allNotification,
      icon: <img style={{ width: '90px', marginLeft: '-29px', marginTop: '-21px' }} src={success}></img>,
    })
  },

  info(message: string) {
    return antdNotification.info({
      message: '',
      placement: 'topRight',
      key: 'info',
      description: message,
      duration: 2,
      closeIcon: false,
      className: style.allNotification,
      icon: <img style={{ width: '80px', marginLeft: '-29px', marginTop: '-21px' }} src={info} />

    })
  },


  alertNotification(data: PopupData) {

    const btn = (
      <Space>
        <Button type='primary' size='small' onClick={data.onContinue}>
          Continue
        </Button>
      </Space >
    );
    return antdNotification.warning({
      message: data?.title,
      placement: 'topRight',
      key: 'info',
      description: data?.body,
      closeIcon: false,
      btn,
      duration: data?.duration || 300,
      icon: <img style = {{
        width: 60,
        marginLeft: -13,
        marginTop: 18,
        paddingRight: 10
      }} src={alert_bell} />

    })
  },
  firebaseInfo(data: PopupData) {
    return antdNotification.info({
      message: data.title,
      placement: 'topRight',
      key: 'info',
      description: data.body,
      closeIcon: false,
      className: style.allNotification,
      duration: 2,
      icon: <img style={{ width: '90px', marginLeft: '-29px', marginTop: '-21px' }} src={info}></img>

    })
  },

  warning(message: string) {
    return antdNotification.warning({
      message: 'Something went wrong!',
      placement: 'bottomRight',
      key: 'warning',
      description: message,
      duration: 3,
      icon: <img style={{ width: '60%', marginLeft: '-4px', marginTop: '-5px' }} src={warning}></img>

    })
  },
  attendenceAlert(message: string, name: string) {
    return antdNotification.open({
      message: `Hi,${name}`,
      placement: 'topRight',
      key: 'warning',
      description: message,
      duration: 2,
      className: 'alert-notification',
      closeIcon: <img style={{ width: '70px', marginLeft: '0px', marginTop: '-2px', height: '66px' }} src={alertIcon}></img>
    })
  },
  inactiveEmployees(message: string) {
    return antdNotification.open({
      placement: 'topRight',
      key: 'warning',
      description: <div style={{ width: '300px' }}>{message}</div>,
      duration: 2,
      className: 'alert-notification',
      closeIcon: (
        <img
          style={{ width: '50px', marginLeft: '0px', marginTop: '-2px', height: '55px' }}
          src={alertIcon}
          alt='Alert Icon' />
      ),
      message: ''
    });
  }
}
