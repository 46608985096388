import React, { useEffect } from 'react';
import { Row, Col, Form } from 'antd'
import { CommonInput } from '@commonComponents/input'
import { observer } from 'mobx-react'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { useStore } from '@stores/root-store'
import SearchFilter from '@commonComponents/SearchFilter';

import { SearchOutlined } from '@ant-design/icons'

interface SectionFormProps{
  setQueryParam: (data: object) => void;
}
const SearchSection = observer((props: SectionFormProps) =>
{
  const {
    departmentModal: { loadDepartment, getDepartmentData, nextDep },

  } = useStore(null)
  useEffect(() =>
  {
    async function load(){
      if (!getDepartmentData?.length)
      {
        await loadDepartment(1, 20, '', true)
      }
    }
    load()
  }, [])

  return (
    <div className='row filter-row'>
      <SearchFilter
        {...props}
        children={
          <div className='d-lg-flex w-100'>
            <Row gutter={8} className={`rowDiv flex-grow-1`}>
              <Col xs={24} sm={24} md={12} lg={6} >
                <Form.Item name='section_name'>
                  <CommonInput
                    prefix={<SearchOutlined />}
                    placeholder='Title'
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}  lg={8} xl={6}>
                <DynamicSelect
                  getData={getDepartmentData}
                  loadData={loadDepartment}
                  nextPage={nextDep}
                  isForm={true}
                  valueKey1={'title'}
                  name={'department'}
                  placeholder={'Select Department'}
                  clearMyData={true}
                  fetchData={true}
                />
              </Col>
            </Row>
          </div>} />
    </div>
  )
})
export default SearchSection;