import CustomSkeleton from '@commonComponents/custom-skeleton'
import React from 'react'
import style from './style.module.scss'

interface loadingSkeletonRoleProps {
  loading?: boolean;
  num?: number;
}

const LoadingSkeletonRole = ({
  loading,
  num = 10,
}: loadingSkeletonRoleProps) => {
  return (
    <div className='row'>
      <div className='col-12'>
        <div className={style.roleCustomSkeletonContainer}>
          <CustomSkeleton
            loading={loading}
            height={'100%'}
            className={style.roleCustomSkeleton}
            num={num}
          />
        </div>
      </div>
    </div>
  )
}

export default LoadingSkeletonRole
