import { punchApi } from '@api'
import DisplayPicture from '@commonComponents/avatar'
import { Drawer, Skeleton, Timeline } from 'antd'
import 'antd/dist/antd.css'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import styled from 'styled-components'
import TodayAttendance from './PunchInOutHistorySidebar'
import { FORMAT, HOURS } from 'constant/constant'
import { useStore } from '@stores/root-store'
import { NO_REASON_PROVIDED, NO_WORK_REPORT_PROVIDED } from '@mainPage/constant'

interface AttendaceSideDrawerProps {
  onClose?: () => void;
  open?: boolean;
  employeeRecord?: any;
  drawerEmpId?: string;
  empId?: any;
  clickedDate?: any;
  calledFrom?: any;
}

function AttendanceSideDrawer({
  onClose,
  open,
  employeeRecord,
  empId,
  clickedDate,
  drawerEmpId,
}: AttendaceSideDrawerProps) {
  const { designationName, name} = employeeRecord || {}
  const {
    punchModal: { getPunchData, loadPunchData },
  } = useStore(null)
  const [drawerLoader, setDrawerLoader] = useState(false)
  const [punchHistory, setPunchHistory] = useState(null)
  const [color, setColor] = useState(null)

  useEffect(()=>{
    const load = async ()=>{
      getPunchData !== null && await loadPunchData()
    }
    load()
  },[])

  useEffect(() => {
    setColor(JSON.parse(localStorage.getItem('colorTheme')))
  }, [])

  const getPunchDetails = async () => {
    setDrawerLoader(true)
    const data = await punchApi.getSinglePunch(`${drawerEmpId}`, clickedDate)
    setPunchHistory(data)
    setDrawerLoader(false)
  }

  useEffect(() => {
    open && getPunchDetails()
  }, [empId, clickedDate, employeeRecord])

  const getMinutesAndHours = (punchDuration: string) => {
    const momentDuration = moment.duration(punchDuration)

    const hours = momentDuration.hours()
    const minutes = momentDuration.minutes()

    if (hours === 0) {
      return `${minutes} minutes`
    } else {
      return `${hours} Hours and ${minutes} minutes`
    }
  }

  const gotProductiveTime = ()=>{
    if(punchHistory?.punchDuration === null){
      const getTime = moment(getPunchData?.firstCheckIn, FORMAT);      
      const nowTime = moment();   
      return moment.utc(nowTime.diff(getTime)).subtract(5, HOURS).format(FORMAT)      
    }else{
      return moment.utc(punchHistory?.punchDuration,FORMAT).format(FORMAT)
    }
    }

    const outOfRangeReason = punchHistory?.results?.length 
  ? punchHistory.results[punchHistory.results.length - 1]?.reason 
  : NO_REASON_PROVIDED;  

  const workReport = punchHistory?.results?.length 
  ? punchHistory.results[punchHistory.results.length - 1]?.workReport 
  : NO_WORK_REPORT_PROVIDED;  

  return (
    <AttendaceReasonDrawer
      placement={'right'}
      width={625}
      open={open}
      closable={null}
      onClose={onClose}
    >
      <Skeleton active loading={drawerLoader}>
        <ModalHeader
          className='modal-header-parent-container'
          color={color || 'blue'}
        >
          <div className='modal-header'>
            <DisplayPicture
              size={90}
              style={{
                border: '2px solid white',
                background: 'white',
                color: 'black',
                fontSize: '35px',
                display: 'flex',
                alignItems: 'center',
              }}
              src={employeeRecord?.profileImage ?? punchHistory?.profileImage}
              name={employeeRecord?.fullName ?? name}
            />
            <div className='emp-details-1'>
              <h3>{employeeRecord?.fullName ?? name}</h3>
              <span className='category'>{designationName ?? '-'}</span>
              <span className='date'>
                <strong>Date</strong>:{' '}
                {moment(clickedDate).format('MMM Do YYYY')}
              </span>
            </div>
            <div className='emp-details-2'>
              <span>
                <strong>Productive Time</strong> :{' '}
                {getMinutesAndHours(gotProductiveTime()) ?? ' 0 hours'}
              </span>
              <span>
                <strong>Break Time</strong> :{' '}
                {getMinutesAndHours(punchHistory?.recessDuration) ?? '0 hours'}
              </span>
              <span>
                <strong>Prayer Time</strong> :{' '}
                {getMinutesAndHours(punchHistory?.prayerDuration) ?? ' 0 hours'}
              </span>
            </div>
          </div>
          <CloseIcon className='close-icon' color={color || 'blue'}>
            <SubCloseIconDiv
              className='sub-close-icon-div'
              onClick={() => onClose()}
            >
              <AiOutlineClose />
            </SubCloseIconDiv>
          </CloseIcon>
        </ModalHeader>
      </Skeleton>

      <div className='modal-body'>
        {workReport && (
          <Skeleton active loading={drawerLoader}>
            <Heading>Today’s Work Report</Heading>
            <TextBoxes>
              {workReport?.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </TextBoxes>
          </Skeleton>
        )}
        {outOfRangeReason && (
          <>
            <Skeleton active loading={drawerLoader}>
              <Heading style={{ paddingTop: '15px' }}>
                Reason for Being Out of Range
              </Heading>
              <TextBoxes>
              {outOfRangeReason?.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
              </TextBoxes>
            </Skeleton>
          </>
        )}
        <Skeleton active loading={drawerLoader}>
          <ActivityDiv>
            <Heading>Today’s Activity</Heading>
            <CustomTimeline color={color || 'blue'}>
              <TodayAttendance punchHistory={punchHistory} />
            </CustomTimeline>
          </ActivityDiv>
        </Skeleton>
      </div>
    </AttendaceReasonDrawer>
  )
}

export default AttendanceSideDrawer

const AttendaceReasonDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 625px;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          padding: 0 !important;
        }
      }
    }
  }
  @media screen and (min-width: 0px) and (max-width: 630px) {
    .ant-drawer-content-wrapper {
      width: -webkit-fill-available !important;
    }
  }
  .modal-header {
    padding: 0 !important;
    border-bottom: none !important;
    display: flex !important;
    gap: 13px !important;

    .emp-details-1 {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .category {
        font-family: Poppins;
        font-weight: 400;
        font-size: 11.6667px;
        color: #d7d7d7;
      }

      .date {
        font-weight: 600;
        font-size: 11.6667px;
        color: #ffffff;
      }
      h3 {
        font-family: Outfit !important;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 0 !important;
        text-align: left;
        width: 210px;
      }
    }

    .emp-details-2 {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-left: -20px;

      span {
        font-family: Poppins;
        font-weight: 400;
        font-size: 13px;
        color: #ffffff;

        strong {
          font-weight: 600;
        }
      }
    }

    .modal-header-image {
      width: 73.33px;
      height: 73.33px;
      border: 0.833333px solid #8ab8fd;
      border-radius: 50%;
    }

    h2 {
      font-family: Poppins;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 0 !important;
    }
  }

  .modal-body {
    padding: 20px 0 4px 20px;

    .leave-reason-details {
      margin-top: 20px;
    }

    .today-work-details {
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      height: fit-content;
      margin: 25px 30px 0 0;

      .work-report-details {
        padding: 17px 0 0 34px;
        height: 148px;
      }
    }

    .work-report-container,
    .leave-reason-details {
      font-family: Poppins;
      font-weight: 400;
      font-size: 12.8831px;
      color: #525353;

      .work-report-details {
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        height: 148px;

        h3 {
          font-family: Outfit !important;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 1px;
          color: #010101;
        }
      }
    }
    h3 {
      font-family: Outfit;
      font-weight: 700;
      font-size: 20px;
      color: #010101;
    }
  }
`
const Heading = styled.h3`
  color: #010101;
  font-size: 18px;
  font-family: Outfit;
  font-weight: 600 !important;
`
const TextBoxes = styled.div`
  height: 148px;
  border-radius: 12px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin-right: 10px;
`
const ActivityDiv = styled.div`
  border-radius: 12px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  height: auto;
  margin-top: 15px;
`
const CustomTimeline = styled(Timeline)`
  .ant-card {
    border: none !important;
  }
  .ant-steps-icon-dot {
    background: ${props =>
      props.color?.bodyColor
        ? props.color?.bodyColor + '!important'
        : 'blue !important'};
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail:after {
    background: ${props =>
      props.color?.bodyColor
        ? props.color?.bodyColor + '!important'
        : 'blue !important'};
  }
`
const ModalHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  justify-content: space-between;
  background: ${props =>
  props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
  color: #f8f9fa;
  padding: 9px 20px;
  height: 133.33px;
  @media screen and (min-width: 0px) and (max-width: 630px) {
    overflow-x: scroll;
  }
`
const CloseIcon = styled.div`
  cursor: pointer;
  right: -12px;
  background: ${props =>
    props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
  border-radius: 32px;
  top: 13px;
  right: 30px;
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  background: #286ace;
  color: #fff;
  &:hover {
    color: #fff;
    border-radius: 32px;
    box-shadow: 0px 0px 10px rgba(0, 0.1, 0, 0.5);
  }
`

const SubCloseIconDiv = styled.div`
  cursor: pointer;
  height: 17px;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  transition-delay: 0.2;
  ${CloseIcon}:hover & {
    transform: rotate(180deg);
  }
`
