import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeLeaveCountApi } from '@api'
import { notification } from '@utils/notifications'

export const leavesInfo = types.model({
  leavetype: types.maybeNull(types.string),
  totalTakenLeaves: types.maybeNull(types.number),
  leaveTotalDays: types.maybeNull(types.number),
  remainingLeaves: types.maybeNull(types.number),
})
export const employeeLeaveCountModal = types
  .model({
    data: types.maybeNull(types.array(leavesInfo)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getEmployeeLeaveCountData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadEmployeeLeaveCount = flow(function* (num = 1,id) {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeLeaveCountApi.getEmployeeLeaveCount(num,id)
        self.data = cast(res)
        return res
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Leave Counts')
        }      } finally {
        self.loading = false
      }
    })

    return {
      loadEmployeeLeaveCount,
    }
  })

export function initEmployeeLeaveCountModal() {
  return employeeLeaveCountModal.create({})
}
