import { flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeLeaveCancelApprovalApi } from '@api'
import { notification } from '@utils/notifications'

export const data = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})
export const updatedBy = types.model({
  approvedById: types.maybeNull(types.string),
  approvedByFirstName: types.maybeNull(types.string),
  approvedByLastName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  userStatus: types.maybeNull(types.string),
})

export const employeeCancelLeaveApproval = types.model({
  id: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  leaveStartDate: types.maybeNull(types.string),
  leaveEndDate: types.maybeNull(types.string),
  leaveType: types.maybeNull(types.number),
  leaveTypeName:types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  employee: types.maybeNull(data),
  lead: types.maybeNull(data),
  nextLead: types.maybeNull(types.array(types.string)),
})

export const employeeCancelLeaveApprovalModal = types
  .model({
    data: types.maybeNull(types.array(employeeCancelLeaveApproval)),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get getEmployeeCancelLeaveApprovalData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const loadEmployeeCancelLeaveApproval = flow(function* (num = 1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeLeaveCancelApprovalApi.getEmployeeLeaveCancelApproval(num)
        self.data = res.results
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Cancel Leaves for Approval')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeCancelLeaveApproval = flow(function* (id: string) {
      try {
        self.loading = true
        yield employeeLeaveCancelApprovalApi.updateEmployeeLeaveCancelApproval(id)
        notification.success('Cancel Leave Status Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadEmployeeCancelLeaveApproval,
      updateEmployeeCancelLeaveApproval,
    }
  })

export function initEmployeeCancelLeaveApprovalModal() {
  return employeeCancelLeaveApprovalModal.create({})
}
