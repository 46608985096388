import { punchApi } from '@api'
import { toJS } from 'mobx'
import { cast, flow, types } from 'mobx-state-tree'
import { NullableString } from './punch'

const Result = types.model({
  deviceType: NullableString,
  punch: NullableString,
  dateTime: NullableString,
  duration: NullableString,
})

const NullableResult = types.maybeNull(Result)

const punchModel = types.model({
  id: NullableString,
  deviceType: NullableString,
  punchDuration: NullableString,
  recessDuration: NullableString,
  prayerDuration: NullableString,
  results: types.maybeNull(types.array(NullableResult)),
})

export const singlePunchModal = types
  .model({
    data: types.maybeNull(punchModel),
  })
  .views(self => ({
    get getSinglePunchData() {
      return toJS(self.data)
    },
  }))
  .actions(self => {
    const loadSinglePunch = flow(function* (empID, clickedDate) {
      try {
        const res = yield punchApi.getSinglePunch(empID, clickedDate)
        if (!res?.isCancel) {
          self.data = cast(res)
        }
        return res
      } catch (error) {
        const myMess = error?.response?.data
        const message = myMess.message
        if (message == '') {
          return { success: false }
        }
      }
    })

    return {
      loadSinglePunch,
    }
  })

export function initSinglePunchModal() {
  return singlePunchModal.create({})
}
