import { punchApi } from '@api'
import { PUNCH_TYPE } from '@mainPage/constant'
import { notification } from '@utils/notifications'
import { humanize } from 'components/utils/string_formate'
import { toJS } from 'mobx'
import { flow, types } from 'mobx-state-tree'

export const NullableString = types.maybeNull(types.string)

export const punch = types.model({
  productiveTime: NullableString,
  prayerTime: NullableString,
  recessTime: NullableString,
  lastCheckIn: NullableString,
  lastPrayerIn: NullableString,
  lastRecessIn: NullableString,
  status: NullableString,
  firstCheckIn: NullableString,
  lastCheckOut: NullableString
})

export const punchModal = types
  .model({
    data: types.maybeNull(punch),
  })
  .views(self => ({
    get getPunchData() {
      return toJS(self.data)
    },
  }))
  .actions(self => {
    const loadPunchData = flow(function* () {
      try {
        const res = yield punchApi.getPunch()
        self.data = res
      } catch (error) {
        return error?.response?.data?.message
      }
    })

    const setPunch = flow(function* (latitude, longitude, punchType) {
      try {
        const response = yield punchApi.setPunch(latitude, longitude, punchType)
        if (response?.success) {
          notification.success(
            `${humanize(PUNCH_TYPE[punchType])} successfully!`
          )
          return response
        }
      } catch (error) {
        notification.error(error?.response?.data?.message)
      }
    })

    return {
      loadPunchData,
      setPunch,
    }
  })

export function initPunchModal() {
  return punchModal.create({})
}
