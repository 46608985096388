import React, { useEffect, useState } from 'react'
import { Button, Space } from 'antd'
import 'antd/dist/antd.css'
import styled from 'styled-components'
import { useStore } from '@stores/root-store'
import DeletePopUp from '@commonComponents/delete-popup'
import { observer } from 'mobx-react'
import ClientForm from './clientForm'
import { constRoute } from '@utils/route'
import BreadCrumbs from '@commonComponents/breadcrums'
import SearchClients from './searchClients'
import ClientTable from './clientTable'
import CustomButton from '@commonComponents/button'

import CustomDrawer from '@commonComponents/drawer'
import ShowOnPermission from '@commonComponents/permit'
import { ADD_METHOD, CLIENT_MOD, UPDATE_METHOD } from 'constant/constant'
import CustomColumnSelect from '@commonComponents/customColumn/customColumnSelect'
import { DownloadBtnWrapper, DownloadRecordWrapper } from '@mainPage/newEmployee/newEmployee'
import { permit } from '@utils/permission/permission'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

const ClientsList = observer(() => {
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [isUpdate, setisUpdate] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [queryParam, setQueryParam] = useState({})
  const [clientData, setClientData] = useState('')
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const [resetTable, setResetTable] = useState(false)
  const [showIcons, setShowIcons] = useState(true);
  const [columns, setColumns] = useState(null)
  const [customCol, setCustomCol] = useState([])
  const [selectedCol, setSelectedColumn] = useState(null)
  const [color, setColor] = useState(null)

  const {
    clientDetails: { deleteClientDetails, isLoading },
    employeeDetails: { setEmployeeColField, loadEmployeeColField, getEmpFields },
    userInfo: { getcompany }
  } = useStore(null)

  const deletClientData = async data => {
    setCurrentData({ id: data?.id })
    setClientData(data?.name)
    setVisibleDelete(true)
  }

  const deleteData = async () => {
    const response = await deleteClientDetails(currentData?.id)
    if (response?.success) {
      setVisibleDelete(false)
      setCurrentData(null)
      if (totalRecordRemaining == 1) {
        setResetTable(true)
      } else {
        setRefreshTable(true)
      }
      setClientData('')
    }
  }

  useEffect(() => {
    const bodycolor = JSON.parse(localStorage.getItem('colorTheme'))
    setColor(bodycolor?.bodyColor || TealishBlue)

  }, [])

  const handleCancel = () => setVisibleDelete(false)

  const openClient = item => {
    setCurrentData(item)
    setisUpdate(true)
    setVisible(true)
  }

  const onAdd = () => {
    setisUpdate(false)
    setVisible(true)
  }
  const handleAdvanceClick = () => {
    setShowIcons(!showIcons);
  };
  useEffect(() => {
    if (getEmpFields?.length && getEmpFields[0]?.fields?.clientsFields?.length) {
      const newCol = columns?.filter((col, index) => {
        if (getEmpFields[0]?.fields?.clientsFields[index]?.isActive && col.id == getEmpFields[0]?.fields?.clientsFields[index]?.id) {
          return col;
        }
      });

      if (Array.isArray(newCol) && newCol.length > 0) {
        setCustomCol([...getEmpFields[0].fields.clientsFields]);
        setSelectedColumn([...newCol]);
      } else {
        // Handle the case where newCol is not iterable or empty
        // You can provide a default value or perform another action as needed.
        // For example, you can log an error or set default values.
        setCustomCol([]);
        setSelectedColumn([]);
      }
    }
  }, [getEmpFields]);


  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='d-flex'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Clients', link: constRoute.client },
              ]}
              isAlpha
              pageTitle={'Clients'}
            >
              {' '}
            </BreadCrumbs>
            <div>
              <ShowOnPermission mod={CLIENT_MOD} method={ADD_METHOD}>
                <ClientCustomButton onClick={onAdd} title='Add Client' />
              </ShowOnPermission>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        {/* Search Filter */}
        <SearchClients
          setQueryParam={setQueryParam}
        />

        {permit(getcompany, CLIENT_MOD, `${UPDATE_METHOD}`) && (
          <DownloadRecordWrapper className='advanceBtn d-flex justify-content-end'>
            <DownloadBtnWrapper>
              {showIcons ? (
                <DownloadExtendWrapper>
                  <CustomColumnSelect
                    customCol={customCol}
                    setCustomCol={setCustomCol}
                    loadColField={loadEmployeeColField}
                    setColField={setEmployeeColField}
                    getColFields={getEmpFields}
                    columns={columns}
                    callFrom={'clientsFields'}
                  />
                </DownloadExtendWrapper>
              ) : null}
              <Button onClick={handleAdvanceClick} style = {{backgroundColor: `${color}` , color : 'white'}}>
                Advance
              </Button>
            </DownloadBtnWrapper>
          </DownloadRecordWrapper>
        )}

        {/* Search Filter */}
        <div className='row'>
          <div className='col-md-12'>
            <ClientTablediv>
              <ClientTable
                openClient={data => openClient(data)}
                deletClientData={data => deletClientData(data)}
                refreshTable={refreshTable}
                setRefreshTable={setRefreshTable}
                resetTable={resetTable}
                setResetTable={setResetTable}
                setTotalRecordRemaining={setTotalRecordRemaining}
                queryParam={queryParam}
                setColumns={setColumns}
                selectedCol={selectedCol}
              />
            </ClientTablediv>
          </div>
        </div>
      </div>
      {/* /Page Content */}
      {/* Add Client Modal */}
      <CustomDrawer
        className='addDrawer'
        title={isUpdate ? 'Update Client' : 'Add Client'}
        visible={visible}
        setVisible={() => {
          setVisible(false)
        }}
        width={600}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='client-form'
              htmlType='submit'
              type='primary'
              title={isUpdate ? 'Update' : 'Add'}
              className='addButton'
            />
          </Space>
        }
      >
        {visible && (
          <ClientForm
            update={isUpdate}
            data={currentData}
            setRefreshTable={setRefreshTable}
            setVisible={setVisible}
          />
        )}
      </CustomDrawer>
      <DeletePopUp
        delete={deleteData}
        msgTitle='Delete Client'
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText='Confirm'
        handleCancel={handleCancel}
        msg={clientData}
      />
      {/* /Delete Client Modal */}
    </div>
  )
})

export default ClientsList

const ClientCustomButton = styled(CustomButton)`
  margin-right: -12px;
  padding: 0px 20px;
  margin-bottom: -0.1rem;
`
const ClientTablediv = styled.div`
  tr {
   .ant-table-cell {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
   .ant-table-cell:nth-child(1) {
      .ant-avatar-circle {
        display: grid;
        place-items: center;
       .ant-avatar-string {
          margin: 2px 0 0 0 !important;
        }
      }
      h2 {
        span {
          max-width: 140px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .ant-table-cell:nth-child(2) {
      max-width: 180px;
    }
   .ant-table-cell:nth-child(3){
      max-width: 180px;
    }
   .ant-table-cell:nth-child(4) {
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      div {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .ant-table-cell:nth-child(5) {
      max-width: 140px;
      overflow: unset;
      .ant-select-selector {
        height: 32px !important;
      }
    }
  }
`
const DownloadExtendWrapper = styled.div`
    width: hug(100px);
    height: hug(48px);
    border-radius: 36px 0px 0px 36px;
    border-right: 0px;
    width: 70px;
    `