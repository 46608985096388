import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeRemoteLeaveApi } from '@api'
import { notification } from '@utils/notifications'

export const data = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})
export const employeeRemoteLeave = types.model({
  id: types.maybeNull(types.string),
  employee: types.maybeNull(data),
  lead: types.maybeNull(data),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  numberOfDays: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  isRevert: types.maybeNull(types.boolean),
  rejectionReason: types.maybeNull(types.string),
})

export const employeeRemoteLeaveModal = types
  .model({
    data: types.maybeNull(types.array(employeeRemoteLeave)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getEmployeeRemoteLeaveData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setEmployeeRemoteLeaveData = flow(function* (data) {
      try {
        self.loading = true
        yield employeeRemoteLeaveApi.setEmployeeRemoteLeave(data)
        notification.success('Leave applied successfully!')
        return { success: true }
      } catch (error) {
        const message = error?.response?.data?.message
        
        if(message){
          notification.error(message)
        } else {
          notification.error('Your have already applied for the Remote leave!')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadEmployeeRemoteLeave = flow(function* (searchParams?, num = 1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeRemoteLeaveApi.getEmployeeRemoteLeave(searchParams, num)
        self.data = cast(res.results)
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Employee Leave')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeRemoteLeaveRejected = flow(function* (id, data) {
      try {
        self.loading = true
        yield employeeRemoteLeaveApi.updateEmployeeRemoteLeaveRejected(id, data)
        notification.success('Remote Leave updated successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeRemoteLeaveStatus = flow(function* ( data) {
      try {
        self.loading = true
        yield employeeRemoteLeaveApi.updateEmployeeRemoteLeaveStatus(data)
        notification.success('Remote Leave updated successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeRemoteLeave = flow(function* ( data) {
      try {
        self.loading = true
        yield employeeRemoteLeaveApi.updateEmployeeRemoteLeave(data)
        notification.success('Remote Leave updated successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEmployeeRemoteLeave = flow(function* (id) {
      try {
        self.loading = true
        yield employeeRemoteLeaveApi.deleteEmployeeRemoteLeave(id)
        notification.success('Remote Leave deleted successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setEmployeeRemoteLeaveData,
      loadEmployeeRemoteLeave,
      deleteEmployeeRemoteLeave,
      updateEmployeeRemoteLeave,
      updateEmployeeRemoteLeaveStatus,
      updateEmployeeRemoteLeaveRejected,
    }
  })

export function initEmployeeRemoteLeaveModal() {
  return employeeRemoteLeaveModal.create({})
}
