import React from 'react'



/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const EditIcon = (props: any) => (
  <a href='#' className='edit-icon' onClick={props.onClick}>
    {props.edit ? <i className='fa fa-pencil' /> : <i className='fa fa-plus' />}
  </a>
)

export default EditIcon
