export const LOAN_LIST = [
  {
    key: 'loan',
    tab: 'Loan',
  }
]
export const APPROVAL_LIST = [
  
  {
    key: 'loanForApproval',
    tab: 'Loan For Approval'
  }
]
  
export const conditionAsset=[   
  { key: 'New', value: 'New' },
  { key: 'Branded', value: 'Branded' },
  { key: 'Used', value: 'Used' },   
  { key: 'Under Repair', value: 'Under Repair' },
  { key: 'Damaged', value: 'Damaged' },   
  { key: 'Working', value: 'Working' }, 
  { key: 'Not Working', value: 'Not Working' }, 
  { key: 'Assigned', value: 'Assigned' }, 
  { key: 'UnAssigned', value: 'UnAssigned' },   
  { key: 'Dead', value: 'Dead' }
]