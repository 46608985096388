import React, { useRef, useState } from 'react'
import { Table } from '@commonComponents/table-v2'
import { assetpermissionUrl } from '@api/const'
import { observer } from 'mobx-react-lite'
import Tooltip from '@commonComponents/CustomToolTip'
import { StatusDropdown } from '@commonComponents/select'
import { STATUS_TITLE, STATUS, DATE_FORMAT } from 'constant/constant'
import { LoadingOutlined } from '@ant-design/icons'
import { useStore } from '@stores/root-store'
import { AiFillClockCircle } from 'react-icons/ai'
import { MdCheckCircle, MdBlockFlipped, MdEdit, MdDelete } from 'react-icons/md'
import moment from 'moment'
import styled from 'styled-components'
import { ASSET_HANDLING, UPDATE_METHOD } from '../../../constant/constant'
import { permit } from '@utils/permission/permission'

export interface AssetRequestProps {
  onEdit?: (data: any) => void;
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable?: any;
  queryParam?: any;
  setCurrentData?: any;
  updateEmployeeStatus?: any;
  updateEmployeeAssetsData?: any;
  onDelete?: any;
}
const AssetRequestcolumns = observer(
  ({
    onEdit,
    setRefreshTable,
    refreshTable,
    queryParam,
    setCurrentData,
    updateEmployeeAssetsData,
    onDelete,
  }: AssetRequestProps) => {
    const tableRef = useRef(null)
    const [currentId, setCurrentId] = useState([])
    const [statusLoader, setStatusLoader] = useState(false)

    const {
      userInfo: { getcompany },
    } = useStore(null)

    const setStatus = async (id, value) => {
      let response = null
      setCurrentId(id)
      setStatusLoader(true)
      setCurrentData({ status: value })
      const formData = new FormData()
      formData.append('status', value)
      response = await updateEmployeeAssetsData(formData, id)
      if (response?.success) {
        setRefreshTable(true)
      }
      setStatusLoader(false)
    }

    const colums = [
      {
        title: 'Employee Name',
        dataIndex: 'employee',
        render: (_text, row) => (
          <p>
            {row?.employee?.firstName + ' ' + row?.employee?.lastName || '-'}
          </p>
        ),
      },
      {
        title: 'Asset Name',
        dataIndex: 'asset_name',
        render: (_text, row) => (
          <div>
            {row?.assetList?.map(fileds => (
              <p>{fileds?.name || '-'}</p>
            ))}
          </div>
        ),
      },

      {
        title: 'Team Lead',
        dataIndex: 'team_lead',
        render: (_text, row) => <p>{row?.teamLead || '-'}</p>,
      },
      {
        title: 'Applied Date',
        dataIndex: 'created_at',
        render: (_text, row) => (
          <p>{row ? moment(row?.createdAt).format(DATE_FORMAT) : '-'}</p>
        ),
      },
      {
        title: 'Duration',
        dataIndex: 'days',
        render: (_text, row) => (
          <div>
            <p>{row?.startDate + ' ' + row?.endDate || '-'}</p>
            <p>{row?.days + ' Day(s)'}</p>
          </div>
        ),
      },

      {
        title: 'Reason',
        dataIndex: 'reason',
        render: (_text, row) => (
          <Tooltip title={row?.reason} placement='topLeft' minLength={24}>
            <ReasonDiv>{row ? row?.reason : '-'}</ReasonDiv>
          </Tooltip>
        ),
      },
      {
        title: STATUS_TITLE,
        dataIndex: STATUS,
        render: (text, record) =>
          (getcompany?.isLead || getcompany?.admin || getcompany?.superadmin) && record?.status == 'Pending' ? (
            <div className='d-flex align-items-center gap-1'>
              <StyledIconWrapper
               
                listHeight={120}
                size='large'
                value={record?.status ? record?.status : '-'}
                onChange={value => {
                  setStatus(record?.id, value)
                }}
              />
              <span>
                {record?.id === currentId && statusLoader && (
                  <LoadingOutlined />
                )}
              </span>
            </div>
          ) : text === 'Approved' ? (
            <div className='approvedIcon'>
              <MdApproved />
              {text}
            </div>
          ) : text === 'Rejected' ? (
            <div className='rejectedIcon'>
              <MdReject/>
              {text}
            </div>
          ) : text === 'Pending' ? (
            <div className='pendingIcon'>
              <MdPending />
              {text}
            </div>
          ) : (
            '-'
          ),
      },
      ...(!(
        getcompany?.admin ||
        getcompany?.isLead ||
        permit(getcompany, ASSET_HANDLING, UPDATE_METHOD)
      )
        ? [
          {
            title: <CenterDivText>Action</CenterDivText>,
            render: (_text, row) => (
              <>
                {row?.status === 'Pending' ? (
                  <>
                    <Flexdiv>
                      <Tooltip title='Edit'>
                        <IconWrapper>
                          <MdEdit onClick={() => onEdit(row)} />
                        </IconWrapper>
                      </Tooltip>
                      <Tooltip title='Delete'>
                        <IconWrapper>
                          <MdDelete onClick={() => onDelete(row)} />
                        </IconWrapper>
                      </Tooltip>
                    </Flexdiv>
                  </>
                ) : (
                  '-'
                )}
              </>
            ),
          },
        ]
        : []),
    ]

    return (
      <>
        <Table
          ref={tableRef}
          dataUrl={assetpermissionUrl}
          columns={colums}
          queryParam={queryParam}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
        />
      </>
    )
  }
)

export default AssetRequestcolumns
const CenterDivText = styled.div`
display: flex;
justify-content: center;
`

const Flexdiv = styled.div`
  display: flex;
  justify-content: center;
`

const IconWrapper = styled.div`
  border-radius: 32px;
    width: 35px;
    height: 35px;
  display: grid;
  place-items: center;
  padding: 8px;

  &:hover {
    background-color: rgb(218, 216, 216);
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`
const StyledIconWrapper = styled(StatusDropdown)`
  svg {
    font-size: 15px;
  }
`
const ReasonDiv = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const MdReject = styled(MdBlockFlipped )`
color: #ef0f0f !important;
margin-bottom: 2px ;
`
const MdApproved = styled(MdCheckCircle)`
color: #16d61e !important;
margin-bottom: 2px ;

`
const MdPending = styled(AiFillClockCircle)`
color: #d7e90d !important;
margin-bottom: 2px ;

`