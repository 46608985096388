import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Modal, Space, Table,} from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import { constRoute } from '@utils/route'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react-lite'
import CustomButton from '@commonComponents/button'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import BreadCrumbs from '@commonComponents/breadcrums'
import AddButton from '@commonComponents/add-button'
import { CommonInput } from '@commonComponents/input'
import style from '../style.module.scss'

const BoardLists = observer(() => {
  const { projectID } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [updateID, setID] = useState(null)
  const [boardData, setBoardData] = useState([])
  const [Visible, setVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [rowData, setRowData] = useState(null)
  const {
    boardHomeDetails: {
      getProjectBoardData,
      loadBoardDetails,
      deleteBoardDetails,
      setBoardDetails,
      updateBoardDetails,
      isLoading,
    },
  } = useStore(null)

  useEffect(() => {
    async function load() {
      await loadBoardDetails(projectID)
    }
    load()
  }, [])

  useEffect(() => {
    if (getProjectBoardData) {
      setBoardData(getProjectBoardData)
    }
  }, [getProjectBoardData])

  const deleteBoard = async row => {
    const res = await deleteBoardDetails(row.id, row.project)
    if (res.success) {
      setDeleteVisible(false)
    }
  }
  const addBoard = async value => {
    let res = null
    if (updateID !== null) {
      res = await updateBoardDetails(updateID, value, projectID)
    } else {
      res = await setBoardDetails({ name: value.name, project: projectID })
    }
    if (res.success) {
      form.resetFields()
      setVisible(false)
      setID(null)
    }
  }

  const updatePopup = row => {
    setVisible(true)
    setID(row.id)
    form.setFieldsValue({
      name: row.name,
    })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, data) => (
        <strong className='cursor-pointer' onClick={() => getRowData(data)}>
          {text}
        </strong>
      ),
    },

    {
      title: 'Board Lists',
      dataIndex: 'board_list',
      render: (text, record) => (
        <div>
          {record.board_list &&
            record.board_list.map(value => {
              return (
                <span className='m-1'>
                  <span
                    className='p-1 text-white fw-bold fs-10 rounded'
                    style={{
                      backgroundColor: `${value.BoardLabel__color ? value.BoardLabel__color : ''
                      }`,
                    }}
                  >
                    {value.count}
                  </span>
                  <span className='text-capitalize m-2 fs-10 font-weight-lighter'>
                    {value.BoardLabel__name}
                  </span>
                </span>
              )
            })}
        </div>
      ),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div className='justify-content-around d-flex'>
          <Tooltip title='Edit'>
            <EditOutlined
              className='text-primary'
              onClick={() => {
                updatePopup(record)
              }}
            />
          </Tooltip>
          <Tooltip title='Delete'>
            <DeleteOutlined
              className='text-danger'
              onClick={() => {
                setDeleteVisible(true)
                setRowData(record)
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  const getRowData = data => {
    navigate(`${constRoute.taskBoard}/${data.id}/${data.project}`)
  }

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header d-flex justify-content-between'>
          <BreadCrumbs
            list={[
              { name: 'Dashboard', link: constRoute.home },
              { name: 'Project', link: constRoute.projects },
              { name: 'Board', link: `${constRoute.boardList}/${projectID}` },
            ]}
            pageTitle={'Boards'}
          >
            <AddButton onClick={() => setVisible(true)} title='Add' />
          </BreadCrumbs>
        </div>
        {/* /Page Header */}
        <div className='row'>
          <div className='col-md-12'>
            <div className='table-responsive'>
              <Table
                className='table-striped'
                columns={columns}
                dataSource={boardData}
                rowKey={record => record.id}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={700}
        title='Add Board'
        visible={Visible}
        footer={[]}
        onCancel={() => {
          setVisible(false), form.resetFields()
        }}
        destroyOnClose={true}
      >
        <Form layout='vertical' form={form} onFinish={addBoard} className={style.boardForm}>
          <div>
            <Form.Item name='name'
              label='Project Name'
            >
              <CommonInput placeholder='Enter Board Name' />
            </Form.Item>
          </div>
          <Form.Item>
            <div className={style.formbtn}>
              <Space>
                <CustomButton
                  type='primary'
                  htmlType='submit'
                  title='Submit'
                  shape='round'
                  size='large'
                  loading={isLoading}
                  className={style.submitbtn}
                />
                <CustomButton
                  type='primary'
                  title='Cancel'
                  shape='round'
                  size='large'
                  onClick={() => setVisible(false)}
                  className={style.cancelbtn}
                />
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={'Confirm Deletion'}
        visible={deleteVisible}
        onOk={() => deleteBoard(rowData)}
        onCancel={() => setDeleteVisible(false)}
        confirmLoading={isLoading}
        okText='Confirm'
        destroyOnClose={true}
      >
        <p>{`Are you sure to delete ${rowData?.name}?`}</p>
      </Modal>
      {/* /Page Content */}
    </div>
  )
})

export default BoardLists
