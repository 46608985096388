import React from 'react';
import classnames from 'classnames';
import { Pagination as PaginationAntd } from 'antd';
import { PaginationProps as PaginationPropsAntd } from 'antd/lib/pagination';
import styles from './styles.module.scss';
import styled from 'styled-components'
import { colors } from 'constant/colors';

const { TealishBlue } = colors

interface PaginationProps extends PaginationPropsAntd {
	typeCase?: string;
}

export type { PaginationProps };

export const Pagination: React.FC<PaginationProps> = ({
  className, typeCase, ...props
}) => {
  return <Wrapper>
    <PaginationAntd
      showSizeChanger
      className={classnames('pagination', className, styles[typeCase])}
      showTotal={(total, [from, to]) => {
        return (
          <span className={styles.fromTo}>
            {from}-{to} {'of'} {total} {'results shown'}
          </span>
        );
      }}
      showLessItems
      {...props}
    />
  </Wrapper>
};

const Wrapper = styled.div`

.ant-pagination-item-container {
	top: 3px !important;

	svg {
		position: relative;
		top: 2px !important;
	}
}

.pagination {
	.ant-pagination-prev,
	.ant-pagination-next {
	  padding: 0px !important;
	  border: none !important;
	  text-align: center !important;
	}
   .ant-pagination-item {
	  line-height: unset !important;
	}
	
  }
  
.ant-pagination {
	padding: 10px 0px 10px 0px !important;
    flex-wrap: wrap !important;
    display: flex;
    align-items: center;
	justify-content: end;
	margin-right:20px;
	li {
		margin: 0px 5px !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ant-pagination-total-text {
		span {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			word-spacing: 2px;
			position: relative;

		}

	}

	.ant-pagination-prev {
		.ant-pagination-item-link {
			color: #FFFFFF;
			background: ${TealishBlue};
			border: 1px solid ${TealishBlue};
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      display:flex;
      align-items:center;
      justify-content:center;
      .anticon{
        margin-top:6px !important;

        svg{
          margin-bottom: 6px;
        }
      }
		}

	}
  .ant-pagination-next {
    .ant-pagination-item-link {
      color: #FFFFFF;
      background: ${TealishBlue};
      border: 1px solid ${TealishBlue};
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      display:flex;
      align-items:center;
      justify-content:center;
      .anticon{
        margin-top:6px !important;
        svg{
          margin-bottom: 6px;
        }
      }
    }
  }
  
}

.ant-pagination-item {
	border: 1px solid ${TealishBlue};
	border-radius: 6px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	color: ${TealishBlue} !important;
	background: #FFFFFF !important;
	a{
		height: -webkit-fill-available;
    display: flex;
    text-align: center;
    align-items: center;
	justify-content: center;
	}
}

.ant-pagination-item-active {
	font-weight: 500;
	border: 2px solid #2e71d8 !important;
  }
  
  .ant-table-pagination-right {
	float: none !important;
  }
.ant-pagination-options {
	width: 100px;
  height: 32px !important;
  .ant-select-selector{
    height:32px !important;
  }
  .ant-select {
    height:32px !important;
  }
  .ant-select-selection-item{
    height: 30px !important;
    line-height: 30px !important
  }
  .ant-select-arrow{
    margin-top:-4px
  }
  .ant-select-selection-item{
	line-height: 28px !important;
  }
}

.ant-select {
	display: flex;
    justify-content: space-between;
    width: fit-content;
    align-items: center;

	.ant-select-selection-item {
		font-size: 14px !important;
		align-items: center;
		display: inline-block !important;
		line-height: 30px;
	}

	.ant-select-selector {
		border-radius: 8px !important;

		padding: 0px 10px 0 10px !important;
		transition: 0.3s all ease-in-out;
		border-color: lightgray !important;
		width: 100%;

		&:hover {
			border-color: #2e71d8 !important;
			outline: none !important;
			transition: 0.3s all ease-in-out;
		}

		&:focus,
		&:active,
		&:visited {
			border-color: #2e71d8 !important;
			border-right-width: 1px !important;
			box-shadow: $color-shadow-hover !important;
			outline: 0 !important;
		}

		.ant-select-dropdown-hidden {
			overflow-y: auto !important;
		}
		input{
			font-size: 14px;
		}
	}

	.ant-select-selecton {
		&:hover {
			border-color: #2e71d8 !important;
			cursor: pointer;
		}
	}
}
`