import React, {useMemo, useRef} from 'react'
import { observer } from 'mobx-react'
import { Table } from '@commonComponents/table-v2'
import { remoteLeaveHistoryUrl } from '@api/const'
import styled from 'styled-components'
import { getLeaveHistoryColumns } from '@mainPage/EmployeeLeave/utils'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const EmployeeRemoteLeaveHistoryTable = observer(({refreshTable, setRefreshTable,queryParam}: any) => {
  const tableRef = useRef(null)
  const columns = useMemo(() => getLeaveHistoryColumns(true),[refreshTable])

  return (
    <>
      <div className='tableWidth custom-Pagination'>
        <EmployeeLeaveHistoryStyledTable
          ref={tableRef}
          dataUrl={remoteLeaveHistoryUrl}
          columns={columns}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={Object.keys(queryParam).length !== 0 && queryParam}
          loaderPosition={'center'}
        />
      </div>
    </>
  )
})

export default EmployeeRemoteLeaveHistoryTable

const EmployeeLeaveHistoryStyledTable = styled(Table)`
.ant-pagination-item a{
  justify-content:center !important;
}
.ant-select .ant-select-selector{
  justify-content: center;
    display: flex;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  justify-content: center;
    text-align: center;
    display: flex;
}
.ant-select .ant-select-selection-item {
  text-align: center;
  justify-content: center;
  display: flex !important;
  margin-top: 3px;
}
`