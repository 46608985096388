import { flow, types } from 'mobx-state-tree'
import { languageApi } from '../../api'
import { notification } from '@utils/notifications'

export const language = types.model({
  name: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
})

export const languageModal = types
  .model({
    data: types.maybeNull(types.array(language)),
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getLanguageData() {
      return self.data
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setLanguageData = flow(function* (data) {
      try {
        self.loading = true
        yield languageApi.setLanguage(data)
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })
    const loadLanguage = flow(function* (num = 1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield languageApi.getLanguage(num)
        self.data = res.results
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Language')
        }
      } finally {
        self.loading = false
      }
    })

    const updateLanguage = flow(function* (id, data) {
      try {
        self.loading = true
        const res = yield languageApi.updateLanguage(id, data)
        self.data = res.results
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const deleteLanguage = flow(function* (id) {
      try {
        self.loading = true
        const res = yield languageApi.deleteLanguage(id)
        self.data = res.results
      } catch (error) {
        notification.error(error?.response?.data?.message)
      } finally {
        self.loading = false
      }
    })

    return { setLanguageData, loadLanguage, deleteLanguage, updateLanguage }
  })

export function initLanguageModal() {
  return languageModal.create({})
}
