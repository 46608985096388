
import { observer } from 'mobx-react-lite'
import { loanUrl } from '@api/const';
import { useRef, useState } from 'react';
import { Table } from '@commonComponents/table-v2'


const LoanForApproval = observer(({ refreshTable, setRefreshTable, queryParam, resetTable, setResetTable, columns }:any) => {
  const [setPage] = useState(null)
  const tableRef = useRef(null)

  return (
    <>
      <Table
        ref={tableRef}
        dataUrl={loanUrl}
        columns={columns}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        queryParam={queryParam}
        setPageNumber={() => setPage}
        resetTable={resetTable}
        setResetTable={setResetTable}
      />
    </>
  )
})


export default LoanForApproval;
