import React, { useState, useRef, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { Table } from '@commonComponents/table-v2'
import {  remoteCancelLeaveUrl } from '@api/const'
import EmployeeLeaveForm from '@mainPage/EmployeeLeave/EmployeeLeave/EmployeeLeaveForm/employeeLeaveForm'
import CustomDrawer from '@commonComponents/drawer'
import { LEAVE_DETAILS, EDIT_LEAVE_APPLICATION, ADD_LEAVE_APPLICATION } from '../../const'
import styled from 'styled-components'
import { getLeaveApprovalColumns } from '@mainPage/EmployeeLeave/utils'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const EmployeeRemoteLeaveCancelApprovalTable = observer(({ refreshTable, setRefreshTable, queryParam }: any) => {
  const [currentData, setCurrentData] = useState({ id: null, value: null, data: {leaveTypeName: '', leaveStartDate: '', leaveEndDate: '', employee: { firstName: '', lastName: '' }, numberOfDays: '', isFullLeave: '' } })
  const [statusLoader, setStatusLoader] = useState(false)
  const [visible, setVisible] = useState(false)
  const [viewDetail] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const tableRef = useRef(null);

  const {
    employeeRemoteCancelLeaveApprovalModal:{ updateEmployeeRemoteCancelLeaveApproval },
  } = useStore(null)

  const onCancelApprove = async (id: string) => {
    const res = await updateEmployeeRemoteCancelLeaveApproval(id)
    if (res.success) {
      setRefreshTable(true)
      setStatusLoader(false)
    }
  }


  const columns = useMemo(() => getLeaveApprovalColumns(statusLoader , currentData , onCancelApprove , true , true,null,null,null,null,null),[refreshTable])

  return (
    <>
      <div className='tableWidth table-responsive custom-Pagination'>
        <EmployeeApprovalTable
          ref={tableRef}
          dataUrl={`${remoteCancelLeaveUrl}`}
          columns={columns}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={Object.keys(queryParam).length !== 0 && queryParam}
          loaderPosition={'center'}
        />
      </div>

      {/* Add EmployeeLeave Modal */}
      <CustomDrawer
        width={400}
        className='addDrawer'
        title={
          viewDetail
            ? LEAVE_DETAILS
            : isUpdate
              ? EDIT_LEAVE_APPLICATION
              : ADD_LEAVE_APPLICATION
        }
        visible={visible}
        setVisible={() => {
          setVisible(false)
          setIsUpdate(false)
          setCurrentData(null)
        }}
      >
        {visible && (
          <EmployeeLeaveForm
            update={isUpdate}
            data={currentData.id}
            viewDetail={viewDetail}
            setVisible={setVisible}
            leaveApproval={false}
            setRefreshTable={val => setRefreshTable(val)}
          />
        )}
      </CustomDrawer>
    </>
  )
})

export default EmployeeRemoteLeaveCancelApprovalTable

const EmployeeApprovalTable = styled(Table)`
.ant-pagination-item a{
  justify-content:center !important;
}
.ant-select .ant-select-selector{
  justify-content: center;
    display: flex;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  justify-content: center;
    text-align: center;
    display: flex;
}
.ant-select .ant-select-selection-item {
  text-align: center;
  justify-content: center;
  display: flex !important;
  margin-top: 3px;
}
`