import styled from 'styled-components'
import { Progress } from 'antd';
import employeeName from '@utils/employee_name';

export const reportColumns = () => {
  return [
    ...([employeeName('setVisible', 'setRecord', 'reprotsTable')]),
    {
      title:<StyledHeader>Present days</StyledHeader> ,
      dataIndex: 'presentdays',
      render: (_text, row) => <StyledCenter>{row?.totalPresents || '-'}</StyledCenter>,
    },
    {
      title:<StyledHeader>Working hours</StyledHeader> ,
      dataIndex: 'workinghours',
      render: (_text, row) => <StyledCenter>{row?.totalWorkHours || '-'}</StyledCenter>,
    },
    {
      title:<StyledHeader>Total leaves</StyledHeader> ,
      dataIndex: 'totalleaves',
      render: (_text, row) => <StyledCenter>{row?.totalLeaves || '-'}</StyledCenter>,
    },
    {
      title:<StyledHeader>Stack</StyledHeader> ,
      dataIndex: 'stack',
      render: (_text, row) => <StyledCenter>{row?.stack || '-'}</StyledCenter>,
    },
    {
      title:<StyledHeader>Current Project</StyledHeader> ,
      dataIndex: 'currentproject',
      render: (_text, row) => <StyledCenter>{row?.currentProject || '-'}</StyledCenter>,
    },
    {
      title:<StyledHeader>Progress</StyledHeader> ,
      dataIndex: 'progress',
      render: (_text, row) =>  (row?.rating ? <Progress percent={row?.rating*100/5} size='small' strokeColor={row?.rating*100/5 >= 80 ? 'green' : row?.rating*100/5 >= 60 && row?.rating*100/5 < 80  ? 'yellow': row?.rating*100/5 < 60 ? 'red' : ''} /> : '-'),
    },
  ]
}

const StyledHeader = styled.div`
display:flex;
justify-content:center;
color:#000000;
font-family: Outfit;
font-size: 18px;
font-weight: 600;
line-height: 23px;
letter-spacing: 0em;
`
const StyledCenter = styled.p`
display:flex;
justify-content:center;
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:#525353;
`