import { BaseApi } from '@api/baseApi';
import { attendanceWorkStatsUrl } from '@api/const';

class WorkStatsApi extends BaseApi {
    getAttendanceWorkStats = async () => {
        try {
            const res = await this.axios.get(attendanceWorkStatsUrl)            
            return res.data
        } catch (error) {
            const apiError = error
            throw apiError
        }
    }
}

export default WorkStatsApi