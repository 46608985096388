import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeLeaveStatsApi } from '@api'
import { notification } from '@utils/notifications'

export const employeeLeaveStats = types.model({
  employeeFirstName: types.maybeNull(types.string),
  employeeLastName: types.maybeNull(types.string),
  leaveStartDate: types.maybeNull(types.string),
  leaveEndDateDate: types.maybeNull(types.string),
  approvedBy: types.maybeNull(types.string),
  leavetype: types.maybeNull(types.string),
  numberOfDays: types.maybeNull(types.number),
  reason: types.maybeNull(types.string),
  employee: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  approvedRequests: types.maybeNull(types.number)

})

export const employeeAbsentStats = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string)
})

export const onLeaveModel = types.model({
  id: types.maybeNull(types.string),
  approvedBy: types.maybeNull(types.string),
  employee: types.maybeNull(types.string),
  employeeFirstName: types.maybeNull(types.string),
  employeeLastName: types.maybeNull(types.string),
  leaveEndDate: types.maybeNull(types.string),
  leaveStartDate: types.maybeNull(types.string),
  leavetype: types.maybeNull(types.string),
  numberOfDays: types.maybeNull(types.number),
  reason: types.maybeNull(types.string),
  status: types.maybeNull(types.string),

})

export const employeeLeaveStatsStatistics = types.model({
  employeeCount: types.maybeNull(types.number),
  pendingRequests: types.maybeNull(types.number),
  plannedLeaves: types.maybeNull(types.number),
  unplannedLeaves: types.maybeNull(types.number),
  todayLeavesCount: types.maybeNull(types.number),
  rejectedRequests: types.maybeNull(types.number),
  approvedRequests: types.maybeNull(types.number),
  onLeave: types.maybeNull(types.array(onLeaveModel)),
})
   
export const employeeLeaveStatsModal = types
  .model({
    data: types.maybeNull(types.array(employeeLeaveStats)),
    absentData: types.maybeNull(types.array(employeeAbsentStats)),
    loading: types.optional(types.boolean, false),
    leaveStatics: types.maybeNull(employeeLeaveStatsStatistics),
    count: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
  })
  .views(self => ({
    get getemployeeLeaveStatsData() {
      return toJS(self.data)
    },
    get getemployeeAbsentData(){
      return toJS(self.absentData)
    },
    get getleaveStaticsData(){
      return toJS(self.leaveStatics)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const loadEmployeeLeaveStats = flow(function* (num=1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeLeaveStatsApi.getEmployeeLeaveStats(num)  
        const leaveData  = res || {}
        self.data = cast(leaveData.onLeave)
        self.absentData = cast(leaveData.todayAbsent)
        self.leaveStatics = cast({
          employeeCount: leaveData?.employeeCount,
          pendingRequests: leaveData?.pendingRequests,
          plannedLeaves: leaveData?.plannedLeaves,
          unplannedLeaves: leaveData?.unplannedLeaves,
          todayLeavesCount: leaveData?.todayLeavesCount,
          rejectedRequests: leaveData?.rejectedRequests,
          approvedRequests: leaveData?.approvedRequests,
          onLeave: leaveData?.onLeave,
        })
        self.count = res[0]?.totalAbsent
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Employee Leave Stats')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })


    return {
      loadEmployeeLeaveStats,
    }
  })

export function initEmployeeLeaveStatsModal() {
  return employeeLeaveStatsModal.create({})
}