import { Input } from 'antd'
import { Label } from '../label'
import styles from './styles.module.scss'
import classnames from 'classnames'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import styled from 'styled-components'
import { colors } from 'constant/colors';
const { TextArea } = Input

const { TealishBlue, mediumGray } = colors

export enum InputSize {
  Small,
  Medium,
  Large,
}
const renderInputSize = (size: InputSize) => {
  switch (size) {
    case InputSize.Small:
      return {
        height: 30,
        fontSize: 12,
        fontWeight: 'lighter',
        fontFamily: 'Poppins'
      }
    case InputSize.Medium:
      return {
        height: 45,
        fontSize: 14,
        fontWeight: 'lighter',
        fontFamily: 'Poppins'
      }
    case InputSize.Large:
      return {
        height: 60,
        fontSize: 14,
        fontWeight: 'lighter',
        fontFamily: 'Poppins'
      }
  }
}
const renderTextAreaSize = (size: InputSize) => {
  switch (size) {
    case InputSize.Small:
      return {
        height: 55,
        fontSize: 12,
        fontWeight: 'lighter',
        fontFamily: 'Poppins'
      }
    case InputSize.Medium:
      return {
        height: 70,
        fontSize: 12,
        fontWeight: 'lighter',
        fontFamily: 'Poppins'
      }
    case InputSize.Large:
      return {
        height: 90,
        fontSize: 14,
        fontWeight: 'lighter',
        fontFamily: 'Poppins'
      }
  }
}

export interface CommonInputProps {
  testId?: string;
  placeholder?: string;
  size?: InputSize;
  inputType?: 'text' | 'password' | 'textarea' | 'number' | 'tel' | 'email';
  variant?: 'light' | 'dark' | 'transparent';
  value?: string;
  disabled?: boolean;
  isRequired?: boolean;
  isValid?: boolean;
  readOnly?: boolean;
  onChange?: (
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeNumber?: (event?: string) => void;
  onInput?: (event) => void;
  onBlur?: (event) => void;
  onFocus?: (event) => void;
  keypress?: (event) => void;
  label?: string;
  autoSizeCheck?: boolean;
  name?: string;
  id?: string;
  autoFocus?: boolean;
  round?: boolean;
  maxlength?: number;
  min?: string;
  max?: string;
  type?: string;
  defaultValue?: string;
  className?: string;
  containerClassName?: string;
  icon?: React.ReactNode;
  formatter?: (value: string) => string;
  parser?: (value: string) => string;
  iconClasses?: string;
  onPressEnter?: (event) => void;
  cyId?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ref?: any;
  allowClear?: boolean;
  onKeyDown?: (event) => void;
  incrementOnWheel?: boolean;
  prefix?: any;
}

export const CommonInput: React.FC<CommonInputProps> = (
  props: CommonInputProps
) => {
  const inputSize = props.size || InputSize.Medium
  const label = props.label || null
  const autoSizeCheck = props.autoSizeCheck || false

  return (
    <CustomInp>
      {label && (
        <Label htmlFor={props.name} id={props.id}>
          {label}
        </Label>
      )}
      {props.inputType === 'textarea' ? (
        <TextArea
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          autoSize={autoSizeCheck}
          onChange={props.onChange ? props.onChange : null}
          onInput={props.onInput ? props.onInput : null}
          style={renderTextAreaSize(inputSize)}
          autoFocus={props.autoFocus}
          readOnly={props.readOnly}
          disabled={props.disabled}
          className={classnames(
            {
              [styles.light]: props.variant === 'light',
              [styles.dark]: props.variant === 'dark',
              [styles.transparent]: props.variant === 'transparent',
            },
            styles.roundInput,
            props.className
          )}
          prefix={props.prefix}
        />
      ) : props.inputType === 'number' ? (
        <Input
          data-cy={props.cyId}
          onPressEnter={props.onPressEnter}
          onInput={props.onInput ? props.onInput : null}
          onBlur={props.onBlur ? props.onBlur : null}
          onKeyDown={props.onKeyDown}
          onFocus={props.onFocus ? props.onFocus : null}
          type={'number'}
          name={props.name}
          value={props.value || props.defaultValue}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onChange={props.onChange ? props.onChange : null}
          style={renderInputSize(inputSize)}
          autoFocus={props.autoFocus}
          maxLength={props.maxlength}
          readOnly={props.readOnly}
          ref={props.ref}
          max={props.max}
          min={props.min}
          onWheel={!props.incrementOnWheel ? event => event.currentTarget.blur() : () => null}
          className={classnames(
            {
              [styles.light]: props.variant === 'light',
              [styles.dark]: props.variant === 'dark',
              [styles.transparent]: props.variant === 'transparent',
            },
            styles.roundInput,
            props.className
          )}
          prefix={props.prefix}
          disabled={props.disabled}
          required={props.isRequired}
        />
      ) : props.inputType === 'password' ? (
        <Input.Password
          data-cy={props.cyId}
          onPressEnter={props.onPressEnter}
          onInput={props.onInput ? props.onInput : null}
          onBlur={props.onBlur ? props.onBlur : null}
          onFocus={props.onFocus ? props.onFocus : null}
          type={props.inputType || 'text'}
          name={props.name}
          data-testid={props.testId}
          value={props.value || props.defaultValue}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onChange={props.onChange ? props.onChange : null}
          style={renderInputSize(inputSize)}
          autoFocus={props.autoFocus}
          maxLength={props.maxlength}
          readOnly={props.readOnly}
          ref={props.ref}
          className={classnames(
            {
              [styles.light]: props.variant === 'light',
              [styles.dark]: props.variant === 'dark',
              [styles.transparent]: props.variant === 'transparent',
            },
            styles.roundInput,
            props.className
          )}
          iconRender={visible => (visible ? <MdVisibility /> : <MdVisibilityOff />)}
          disabled={props.disabled}
          required={props.isRequired}
          prefix={props.prefix}
        />
      ) : (
        <Input
          data-cy={props.cyId}
          onPressEnter={props.onPressEnter}
          onInput={props.onInput ? props.onInput : null}
          onBlur={props.onBlur ? props.onBlur : null}
          onFocus={props.onFocus ? props.onFocus : null}
          type={props.inputType || 'text'}
          name={props.name}
          data-testid={props.testId}
          value={props.value || props.defaultValue}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onChange={props.onChange ? props.onChange : null}
          style={renderInputSize(inputSize)}
          autoFocus={props.autoFocus}
          maxLength={props.maxlength}
          readOnly={props.readOnly}
          ref={props.ref}
          allowClear={props.allowClear}
          className={classnames(
            {
              [styles.light]: props.variant === 'light',
              [styles.dark]: props.variant === 'dark',
              [styles.transparent]: props.variant === 'transparent',
            },
            styles.roundInput,
            props.className
          )}
          disabled={props.disabled}
          required={props.isRequired}
          prefix={props.prefix}
        />
      )}
      <span className={props.iconClasses}>{props.icon && props.icon}</span>
    </CustomInp>
  )
}


const CustomInp = styled.div`
display: flex;
position: relative;
width: 100%;

.ant-input-affix-wrapper:focus{
  border-color: ${TealishBlue} !important;
}

.ant-input-affix-wrapper:active{
  border-color: ${TealishBlue} !important;
}

input,
.roundInput {
  border-radius: 8px;
  font-weight: 400;
  transition: 0.3s all ease-in-out;
  width: 100%;

  &:hover {
    outline: none;
    transition: 0.3s all ease-in-out;
  }

  &:focus,
  &:active,
  &:visited {
    border-right-width: 1px !important;
    box-shadow: $color-shadow;
    outline: 0;
  }
}

.ant-input-focused, .ant-input:focus {
  border-color: ${TealishBlue} !important;
}


.inputNumber {
  border: 0;
  width: 100%;

  input {
    height: 100%;
    position: absolute;
    top: 0;
    border: 1px solid ${mediumGray};
  }
}
input{
    &::placeholder{
      font-size: 14px;
    }
  }

 
`