import { flow, types, cast } from 'mobx-state-tree'
import { gradeApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'

export const gradeInfo = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  parentGrade: types.maybeNull(types.string),
  branch: types.maybeNull(types.string),
  parentName: types.maybeNull(types.string),
  branchName: types.maybeNull(types.string),
})

export const gradeDetails = types
  .model({
    gradeInfo: types.maybeNull(types.array(gradeInfo)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextGra: types.optional(types.number, 1),
  })
  .views(self => ({
    get getGradeData() {
      return toJS(self.gradeInfo)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setGrade = flow(function* (data) {
      try {
        self.loading = true
        yield gradeApi.setGrade(data)
        notification.success('Grade Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadGrade = flow(function* (page = 1, pageSize = 20, queryParam = '', resetList = false) {
      try {
        self.loading = true
        if (resetList) {
          self.gradeInfo = cast([])
          self.nextGra = 1
        }
        const res = yield gradeApi.getGrade(page, pageSize, queryParam)
        if (!res?.isCancel) {
          self.gradeInfo = cast([...toJS(self.gradeInfo ?? []), ...res.items])
          self.count = res.count
          if (res.next) {
            self.nextGra = self.nextGra + 1
          } else {
            self.nextGra = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Designation';
        notification.error(message);
      } finally {
        self.loading = false
      }
    })


    const updateGrade = flow(function* (id, data) {
      try {
        self.loading = true
        yield gradeApi.updateGrade(id, data)
        notification.success('Grade Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteGrade = flow(function* (id) {
      try {
        self.loading = true
        yield gradeApi.deleteGrade(id)
        notification.success('Grade Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error('Failed To Delete Grade')
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setGrade,
      loadGrade,
      deleteGrade,
      updateGrade,
    }
  })

export function initGradeDetails() {
  return gradeDetails.create({})
}
