
import { BaseApi } from '../baseApi'
import { shiftUrl } from '../const/index'

class ShiftApi extends BaseApi {
  setShift = async data => {
    try {
      const response = await this.axios.post(shiftUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getShift = async (num ,pageSize, queryParam={}) => {
    try {
      const response = await this.axios.get(
        shiftUrl,
        {
          params: queryParam ? {...queryParam} : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateShift = async (id, data) => {
    try {
      const response = await this.axios.patch(
        `${shiftUrl+id}/`,
        {
          ...data,
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteShift = async id => {
    try {
      const response = await this.axios.delete(`${shiftUrl+id}/`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ShiftApi
