import { cast, flow, types } from 'mobx-state-tree'
import { omsNotificationApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'

export const notifications = types.model({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  visible: types.maybeNull(types.boolean),
  employee: types.maybeNull(types.string),
})

export const notificationOms = types
  .model({
    notif: types.maybeNull(types.array(notifications)),
    loading: types.optional(types.boolean, false),
    nextRole: types.optional(types.number, 1),

  })
  .views(self => ({
    get getNotificationOms() {
      return toJS(self.notif)
    },
    get isLoading() {
      return self.loading
    },

  }))
  .actions(self => {

    const loadNotificationOms = flow(function* () {
      try {
        self.loading = true
        const res = yield omsNotificationApi.getNotification()
        if (!res?.isCancel) {
          self.notif = cast([...toJS(self.notif ?? []), ...res])
        }
      } catch (error) {        
        const message = error?.response?.statusText || 'Failed to Load Notifications';
        notification.error(message);
      } finally {
        self.loading = false
      }
    })

   
    
    return {
      loadNotificationOms
    }
  })

export function initNotificationOms() {
  return notificationOms.create({})
}
