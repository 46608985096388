export const constRoute = {
  signup: '/test_register',
  branch: '/branch',
  home: '/',
  adminDashbaord: '/app/main/dashboard',
  employeeDashbaord: '/ ',
  chat: '/conversation/chat',
  voiceCall: '/conversation/voice-call',
  videoCall: '/conversation/video-call',
  outGoingCall: '/conversation/outgoing-call',
  inCommingCall: '/conversation/incoming-call',
  calendar: '/app/apps/calendar',
  contacts: '/app/apps/contacts',
  email: '/email/inbox',
  fileManager: '/app/apps/file-manager',
  newEmployee: '/app/employee/employees',
  employeesList: `/app/employee/employees-list`,
  holiday: '/app/employee/holidays',
  leaveAdmin: '/app/employee/leaves-admin',
  leaveEmployee: '/app/employee/leave-application',
  remoteEmployee: '/app/employee/remote-work',
  leaveSetting: '/app/employee/leave-settings',
  attendanceAdmin: '/app/employee/attendance-admin',
  attendanceEmployee: '/app/employee/attendance-employee',
  departments: '/app/employee/departments',
  designations: '/app/employee/designations',
  timesheet: '/app/employee/timesheet',
  shift: '/app/employee/shift',
  overtime: '/app/employee/overtime',
  client: '/app/employees/clients',
  clientsList: '/app/employees/clients-list',
  attendance: '/app/employees/attendance',
  projects: '/app/projects/project-dashboard',
  projectList: `/app/projects/projects-list`,
  projectView: `/app/projects/projects-view`,
  budgetExpense: `/app/accounts/budget-expenses`,
  clients: `/app/employees/clients`,
  ticketView: `/app/employees/ticket-view`,
  taskLayout: '/tasks',
  tasks: '/tasks/tasks',
  boardList: '/app/projects/board',
  taskBoard: '/app/projects/task-board',
  leads: '/app/employees/leads',
  tickets: '/app/employees/tickets',
  estimates: '/app/sales/estimates',
  estimatesView: `/app/sales/estimatesview`,
  editestimates: 'app/sales/editestimates',
  createestimates: `/app/sales/createestimates`,
  invoices: '/app/sales/invoices',
  invoicecreate: `/app/sales/invoices-create`,
  invoiceedit: `/app/sales/invoices-edit`,
  invoiceview: `/app/sales/invoices-view`,
  payments: '/app/sales/payments',
  expenses: '/app/sales/expenses',
  providentFund: '/app/sales/provident-fund',
  taxes: '/app/sales/taxes',
  accountCategories: '/app/accounts/categories',
  subCategory: `app/accounts/sub-category`,
  budgets: '/app/accounts/budgets',
  budgetsExpense: '/app/accounts/budget-expenses',
  budgetRevenue: '/app/accounts/budget-revenues',
  employeeSalary: '/app/payroll/_salary',
  payslip: '/app/payroll/salary-view',
  payrollItems: '/app/payroll/payroll-items',
  polices: '/app/hr/policies',
  expenseReport: '/app/reports/expense-reports',
  invoiceReport: '/app/reports/invoice-reports',
  paymentReport: '/app/reports/payments-reports',
  projectReport: '/app/reports/project-reports',
  taskReport: '/app/reports/task-reports',
  userReport: '/app/reports/user-reports',
  employeeReport: '/app/reports/employee-reports',
  payslipReport: '/app/reports/payslip-reports',
  attendanceReport: '/app/reports/attendance-reports',
  leaveReport: '/app/reports/leave-reports',
  dailyReport: '/app/reports/daily-reports',
  performance: `/app/performances/performance-review`,
  performanceIndicator: '/app/performances/performance-indicator',
  performanceReview: '/app/performances/performance-review',
  performaceApraisal: '/app/performances/performance-appraisal',
  goalList: '/app/goals/goal-tracking',
  goalType: '/app/goals/goal-type',
  trainingList: '/app/training/training-list',
  trainers: '/app/training/trainer',
  trainingType: '/app/training/training-type',
  promotion: '/app/performance/promotion',
  resignation: '/app/performance/resignation',
  termination: '/app/performance/termination',
  assets: '/app/administrator/assets',
  assetshistory: '/app/administrator/assets-history',
  userDasboard: '/app/administrator/user-dashboard',
  jobsDasboard: '/app/administrator/jobs-dashboard',
  manageJobs: '/app/administrator/jobs',
  managedJobs: '/app/administrator/jobs',
  manageResumes: '/app/administrator/manage-resumes',
  shortlistCandidates: '/app/administrator/shortlist-candidates',
  interviewQuestions: '/app/administrator/interview-questions',
  offerApprovals: '/app/administrator/offer_approvals',
  experienceLevel: '/app/administrator/experiance-level',
  candidatesList: '/app/administrator/candidates',
  scheduletiming: '/app/administrator/schedule-timing',
  aptitudeResults: '/app/administrator/apptitude-result',
  knowledgebase: '/app/administrator/knowledgebase',
  knowledgebaseView: '/app/administrator/knowledgebase-view',
  activities: '/app/administrator/activities',
  users: '/app/administrator/users',
  userAllJobs: '/app/administrator/user-all-jobs`',
  savedjobs: '/app/administrator/saved-jobs',
  appliedJobs: '/app/administrator/applied-jobs',
  interviewing: '/app/administrator/interviewing',
  offeredJobs: '/app/administrator/offered-jobs',
  visitedjobs: '/app/administrator/visited-jobs',
  archivedJobs: '/app/administrator/archived-jobs',
  jobdetails: '/app/administrator/job-details',
  appliedCandidate: '/app/administrator/job-applicants',
  apptitudeResults: '/app/administrator/apptitude-result',
  jobapptitude: '/app/administrator/job-aptitude',
  questions: '/app/administrator/questions',
  settingsLayout: '/settings',
  employeeProfile: '/app/profile/employee-profile/:employeeID',
  employeesingleProfile: '/app/profile/employee-profile/',
  clientProfile: '/app/profile/client-profile/:clientID',
  login: '/login',
  register: '/register',
  forgotPassword: '/forgotpassword',
  resendVerification: '/resend-verification',
  otp: '/otp',
  lockScreen: '/lockscreen',
  four0fourError: '/error-404',
  errorFive: '/error-500',
  subscriptionsAdmin: '/app/subscription/subscriptionadmin',
  subscriptionsCompany: '/app/subscription/subscriptioncompany',
  subscribedCompanies: '/app/subscription/subscribedcompanies',
  search: '/app/pages/search',
  faq: '/app/pages/faq',
  terms: '/app/pages/terms',
  privacyPolicy: '/app/pages/privacypolicy',
  blankPage: '/app/pages/blank',
  components: 'ui-components',
  basicInputs: '/app/ui-interface/forms/basicinputs',
  inputGroups: '/app/ui-interface/forms/inputgroups',
  horizontalForm: '/app/ui-interface/forms/horizontalform',
  verticalForm: '/app/ui-interface/forms/verticalform',
  formMask: '/app/ui-interface/forms/formmask',
  formValidation: '/app/ui-interface/forms/formvalidation',
  tables: '/app/ui-interface/tables/basic',
  basicTables: '/app/ui-interface/tables/basic',
  dataTable: '/app/ui-interface/tables/data-table',
  backtoHome: '/app/main/dashboard',
  companySettings: '/settings/companysetting',
  localization: '/settings/localization',
  themeSettings: '/settings/theme-settings',
  rolesPermissions: '/settings/roles-permissions',
  emailSettings: '/settings/email-settings',
  performanceSettings: '/settings/performance-setting',
  approvalSettings: '/settings/approval-setting',
  invoiceSettings: '/settings/invoice-settings',
  salarySettings: '/settings/salary-settings',
  notifications: '/settings/notifications',
  changePassword: '/settings/change-password',
  leaveType: '/settings/leave-type',
  toxBoxSettings: '/settings/toxbox-setting',
  cronSettings: '/settings/cron-setting',
  goalTracking: `/app/goals/goal-tracking`,
  chatLayout: '/conversation',
  emailLayout: '/email',
  jobsList: '/applyjob/joblist',
  rolePermission: '/app/role&Permission',
  confirmPassword: '/changePassword',
  payroll: '/app/payroll',
  allowance: '/app/allowance',
  payrollSlip: '/app/payroll-slip',
  profile: '/app/profile/employee-profile',
  settings: '/app/settings',
  grade: '/app/grade',
  section: '/app/section',
  reset: '/reset-password',
  registration: '/app/resignation',
  loan: '/app/loan',
  eCanteen: '/app/eCanteen',
  clearance: '/app/clearance',
  newPage: '/app/newpage',
  feedback: '/app/feedback',
  onBoarding: '/app/onBoarding',
  assetHandling: '/app/employee/asset-handling',
  reports:'/app/employees/reports',
  ErrorPage:'/mainPage/404Error/errorPage',

}