import { observer } from 'mobx-react'
import style from './style.module.scss'
import { colors } from 'constant/colors'
import React, { useEffect } from 'react'
import { Line } from '@ant-design/plots'
import { useStore } from '@stores/root-store'

const { TealishBlue } = colors

export const DemoLine = observer(() => {

  const {
    WorkStatsModel: {
      loadWorkTimeStats, WorkStatsData
    }
  } = useStore(null)

  useEffect(() => {
    loadWorkTimeStats()
  }, [])  

  const config = {
    data: WorkStatsData || [],
    xField: 'dates',
    yField: 'hours',
    lineStyle: {
      stroke: TealishBlue,
      lineWidth: 2,
      strokeOpacity: 1,
      shadowColor: '#8884d8',
      shadowBlur: 2,
      shadowOffsetX: 1,
      shadowOffsetY: 1,
      cursor: 'pointer',
      lineDash: [3, 5],
    },
    smooth: true,
    animation: {
      appear: {
        animation: 'wave-in',
        duration: 3000,
        delay: 1000,
      },
    },
    point: {
      size: 5,
      shape: 'circle',
      style: {
        fill: '#fff',
        stroke: '#8884d8',
        lineWidth: 2,
        shadowBlue: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: 'blue',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  }
  return <Line className={style.dboard_graph} {...config} />
})
