import {Link} from 'react-router-dom';
import {CopyOutlined } from '@ant-design/icons'
import {Avatar} from 'antd';
import React from 'react';
import Tooltip from '@commonComponents/CustomToolTip'
import ShowOnPermission from '@commonComponents/permit'
import { MdDelete } from 'react-icons/md'
import { StyledActionIconWrapper } from '@mainPage/categories/columns';
import styled from 'styled-components';
import DisplayPicture from '@commonComponents/avatar';
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import {
  EMPLOYEE_MOD,
  UPDATE_METHOD,
} from 'constant/constant'

export const getColumns = onDelete => {
  const [copyEmail, setCopyEmail] = useState('Copy email');
const copyText = text => {
  navigator.clipboard.writeText(text)
  setCopyEmail('Copied')
  setTimeout(() => {
    setCopyEmail('Copy email')
  }, 500)
}
  return [
    {
      title: 'Lead Name',
      key: 'leadName',
      render: (text, data) => {
        return (
          <span className='d-flex'>
            <h2 className='table-avatar'>
              <Link to={`#`} style={{ marginRight: '10px' }}>
                 <DisplayPictures
          style={{
            backgroundColor:  'white',
            border: !data?.employeeImage && '3px solid black',
            color: !data?.employeeImage && 'black'
          }}
          size={35}
          src={data?.employeeImage}
          name={data?.leadName}
        />
              </Link>
            </h2>
            <h2 className='table-avatar'>
              <Link style={{cursor:'text'}} to={`#`}>
                {(data?.leadName)}
              </Link>
            </h2>
          </span>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) =>
      <>
        <div className='d-flex'>
          <div>
            <Tooltip minLength={18} title={text}>
              <div className='emailWidth d-flex align-items-center' style={{cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faEnvelope} />
                <Email >{record.email ? record.email : '-'}</Email>
              </div>
            </Tooltip>
          </div>
          <div>
            {
              <ShowOnPermission mod={EMPLOYEE_MOD} method={UPDATE_METHOD}>
                <Tooltip minLength={5} title={copyEmail} >
                  <div >
                    <CopyOutlined onClick={() => copyText(text)}  />
                  </div>
                </Tooltip>
              </ShowOnPermission>
            }
          </div>
        </div>
        </>
    },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
        render: text => (
          <div style={{ display: 'flex'}}>
          <FontAwesomeIcon style={{paddingRight:'10px', paddingTop:'5px'}} icon={faPhone} />          
             <div>{text ? text : '-'}</div>
          </div>
        ),
      },
    
    {
      title: 'Project',
      dataIndex: 'projectLeader',
      key: 'projectLeader',
      render: (text, record) => (
        <div>
          {record.project_leader?.length ? (
            <Avatar.Group maxCount={2} size={32}>
              {record.projectLeader?.map((e, index) => (
                <Avatar
                  size={35}
                  style={
                    index == 0
                      ? { backgroundColor: '#99e6ff' }
                      : { backgroundColor: '#809fff' }
                  }
                >
                  {e}
                </Avatar>
              ))}
            </Avatar.Group>
          ) : (
            '-'
          )}{' '}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) =>  <div className='actions'>
        <StyledActionIconWrapper>
        <Tooltip title='Delete' minLength={0}>
          <MdDelete style={{cursor:'pointer',width: '20px',height: '20px',display: 'flex'}} onClick={() => onDelete(record)}/>
        </Tooltip>
        </StyledActionIconWrapper>
      </div> ,
    },
  ]
}

const DisplayPictures = styled(DisplayPicture)`
.ant-avatar-circle{
border:2px solid red !important;
line-height: 32px !important;

  .table-avatar {
    background: red;
    a {
      color: white !important;
    }
  }
span{
  line-height: 32px !important;
}
}
`
const Email = styled.p`
max-width:200px !important
`