import React from 'react'
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { SearchOutlined } from '@ant-design/icons'
import { CommonInput } from '@commonComponents/input'
import SearchFilter from '@commonComponents/SearchFilter'
import { CommonSelect } from '@commonComponents/select'
import { LARGE } from 'constant/constant'

const SearchReports = observer(() => {
  return (
    <>
      <div className='row filter-row'>
        <SearchFilter
          children={
            <div className='d-lg-flex w-100'>
              <Row gutter={8} className='rowDiv flex-grow-1'>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item name='employee_name'>
                    <CommonInput
                      placeholder='Name'
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item name=''>
                    <CommonSelect
                      listHeight={120}
                      placeholder='Current Project'
                      allowClear
                      size={LARGE}
                      getPopupContainer={trigger => trigger.parentNode}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item name=''>
                    <CommonSelect
                      listHeight={120}
                      placeholder='Stack'
                      allowClear
                      size={LARGE}
                      getPopupContainer={trigger => trigger.parentNode}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          }
        />
      </div>
    </>
  )
})
export default SearchReports
