import { toJS } from 'mobx'
import { cast, flow, types } from 'mobx-state-tree'
import { categoriesApi } from '../../api'
import { notification } from '@utils/notifications'

export const category = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  categoryType: types.maybeNull(types.string),
  assignable: types.maybeNull(types.boolean),
  description: types.maybeNull(types.string),
  parentCategoryId: types.maybeNull(types.string),
})

export const hrCategoryModal = types
  .model({
    data: types.maybeNull(types.array(category)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextCat: types.optional(types.number, 1),
  })
  .views(self => ({
    get getCategoriesData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setCategoryData = flow(function* (data) {
      try {
        self.loading = true
        yield categoriesApi.setCategory(data)
        notification.success('Category Added Successfully')
        return { status: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadCategory = flow(function* (page=1, pageSize=100, queryParam='', resetList=false) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextCat = 1
        }
        const res = yield categoriesApi.getCategory(page , pageSize, queryParam)
        self.data = res?.items
        if (!res?.isCancel){
          self.data = cast([...toJS(self.data ?? []), ...res.results])
          self.count = res.count
          if (res.next) {
            self.nextCat = self.nextCat + 1
          } else {
            self.nextCat = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        }
      } finally {
        self.loading = false
      }
    })

    const updateCategory = flow(function* (id, data) {
      try {
        self.loading = true
        yield categoriesApi.updateCategory(id, data)
        notification.success('Category Updated Successfully')
        return { status: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteCategory = flow(function* (id) {
      try {
        self.loading = true
        yield categoriesApi.deleteCategory(id)
        notification.success('Category Deleted Successfully')
        return { status: true }
      } catch (error) {
        notification.error('This Category is used in Expense or Asset')
        return { status: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setCategoryData,
      loadCategory,
      updateCategory,
      deleteCategory,
    }
  })

export function initHRCategoryModal() {
  return hrCategoryModal.create({})
}
