import { Space } from 'antd'
import { useState } from 'react'
import FeedbackTable from './utils'
import { constRoute } from '@utils/route'
import QuestionForm from './QuestionForm'
import { CANCEL, FEEDBACK_MOD, POST_METHOD } from 'constant/constant';
import CustomButton from '@commonComponents/button'
// import CreateFeedback from './CreateFeedbackDrawer'
import CustomDrawer from '@commonComponents/drawer'
import BreadCrumbs from '@commonComponents/breadcrums'
import ShowOnPermission from '@commonComponents/permit'

const Feedback = () => {
  const [visible, setVisible] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  // const [feedBackDrawer, setFeedBackDrawer] = useState(false)
  const [questionData, setQuestionData] = useState(null)


  const getCurrentQuestion = async question => {
    setQuestionData(question)
  }

  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='d-flex justify-content-between'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Feedback', link: constRoute.feedback },
              ]}
              pageTitle={'Feedback'}
            ></BreadCrumbs>
            <div>
              <ShowOnPermission mod={FEEDBACK_MOD} method={POST_METHOD}>
                <CustomButton
                  title={'Create Question'}
                  onClick={() => {
                    setQuestionData(null)
                    setVisible(true)
                  }}
                />
              </ShowOnPermission>
            </div>
            {/* Comment untill Final flow is not decided */}
            {/* <div>
              <CustomButton
                title={'Create Feedback'}
                onClick={() => setFeedBackDrawer(true)}
              />
            </div> */}
          </div>
        </div>
        <CustomDrawer
          className='addDrawer'
          visible={visible}
          title={questionData ? 'Edit Question' : 'Add Question'}
          setVisible={setVisible}
          width={600}
          customFooter={
            <Space>
              <div>
                <CustomButton
                  htmlType='submit'
                  type='primary'
                  title={CANCEL}
                  onClick={() => setVisible(false)}
                />
              </div>
              <div>
                <CustomButton
                  form='question-form'
                  htmlType='submit'
                  title={questionData ? 'Update' : 'Add'}
                  type='primary'
                />
              </div>
            </Space>
          }
        >
          <QuestionForm
            setRefreshTable={setRefreshTable}
            questionData={questionData}
            setVisible={setVisible}
          />
        </CustomDrawer>
        {/* <CreateFeedback
          visible={feedBackDrawer}
          setVisible={setFeedBackDrawer}
        /> */}
        <div className='table-responsive'>
          <FeedbackTable
            setRefreshTable={setRefreshTable}
            refreshTable={refreshTable}
            getCurrentQuestion={getCurrentQuestion}
            setVisible={setVisible}
          />
        </div>
      </div>
    </div>
  )
}
export default Feedback
