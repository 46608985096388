
import { BaseApi } from '../baseApi'
import { departmentUrl } from '../const/index'

class DepartmentApi extends BaseApi {
  setDepartment = async data => {
    try {
      const response = await this.axios.post(
        departmentUrl,
        {
          title: data.title,
          is_active: data.isActive,
          branch: data.branch,
          description:data.description
        },
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getDepartment = async (num ,pageSize, queryParam={}) => {
    try {
      let url = departmentUrl
      url += `?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateDepartment = async (id, data) => {
    try {
      const response = await this.axios.patch(
        `${departmentUrl+id}/`,
        {
          title: data.title,
          is_active: data.isActive,
          branch: data.branch,
          description:data.description
        },
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteDepartment = async id => {
    try {
      const response = await this.axios.delete(`${departmentUrl+id}/`)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default DepartmentApi
