import { menusList } from '@utils/menu'
import { toJS } from 'mobx'
import { flow, types } from 'mobx-state-tree'
import { UserLoginDataApi } from '../../api'

export const menuType = types.model({
  name: types.optional(types.string, ''),
  api: types.optional(types.string, ''),
  icon: types.optional(types.string, ''),
  link: types.optional(types.string, ''),
})

export const menuList = types.model({
  menuTitle: types.optional(types.string, ''),
  menu: types.optional(types.array(menuType), []),
})


const Role = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  allowedMethod: types.frozen(),
});

const User = types.model({
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  roles: types.optional(types.array(Role), []),
});

const Designation = types.model({
  id: types.identifier,
  title: types.string,
});

const Supervisor = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});
const Shift = types.model({
  endTime: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  startTime: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
})
const Details = types.model({
  company: types.maybeNull(Designation),
  branch: types.maybeNull(Designation),
  designation: types.maybeNull(Designation),
  section: types.maybeNull(Designation)
})
export const userBoarding = types.model({
  id: types.maybeNull(types.string),
  user: types.maybeNull(User),
  employeeImage: types.maybeNull(types.string),
  availability: types.maybeNull(types.string),
  remote: types.maybeNull(types.boolean),
  supervisor: types.maybeNull(Supervisor),
  shift: types.maybeNull(Shift),
  is_active: types.maybeNull(types.boolean),
  employeeId: types.maybeNull(types.string),
  employment_model: types.array(types.string),
  menu: types.optional(types.array(menuList), []),
  designation: types.maybeNull(Designation),
  details: types.maybeNull(Details),
  admin: false,
  superadmin: false,
  isLead: false,

})

export const userInfoModal = types
  .model({
    erorMessage: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    data: types.maybeNull(userBoarding),
    roles: types.frozen()
  })
  .views(self => ({
    get getcompany() {
      return toJS(self.data)
    },
    get errorMessage() {
      return self.erorMessage
    },
    get isLoadings() {
      return self.loading
    },
    get getRoles() {
      return self.roles
    }
  }))
  .actions(self => {
    const onBoardUser = flow(function* fetchData() {
      try {
        self.loading = true
        const res = yield UserLoginDataApi.getCompany()
        
        if (res.data?.user?.roles) {

          const combinedAllowedMethods = {};
          res.data?.user?.roles.forEach(items => {
            const { allowedMethod } = items;
            
            Object.keys(allowedMethod).forEach(key => {
              if (!combinedAllowedMethods[key]) {
                combinedAllowedMethods[key] = [];
              }

              allowedMethod[key].forEach((method, index) => {
                if (!combinedAllowedMethods[key][index]) {
                  combinedAllowedMethods[key][index] = method;
                }
              });
            });
          });

          const moduleKeys = Object.keys(combinedAllowedMethods)

          res.data.menu = menusList?.map(item => {
            return {
              menuTitle: item.menuTitle,
              menu: item?.menu
                ?.map(list => {
                  return moduleKeys.findIndex(name => name === list.api) > -1
                    ? list
                    : undefined
                })
                .filter(notUndefined => notUndefined !== undefined),
            }
          })
          self.roles = combinedAllowedMethods
        }

        self.data = res.data
        return res
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        self.loading = false
      }
    })

    return { onBoardUser }
  })

export const userInfoModalStore = userInfoModal.create({})
