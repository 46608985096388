import React, { useEffect, useState } from 'react'
import {Card,Timeline} from 'antd';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root-store';
import styled from 'styled-components'
import { colors } from 'constant/colors';

const { mediumGray } = colors

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const PayrollHistory = observer(({ payrollId }: any) => {

  const [payrollHistory, setPayrollHistory] = useState(null)

  const {
    employeePayroll: {
      getEmployeePayrollHistory
    },
  } = useStore(null)

  useEffect(() => {
    const loadHistory = async () => {
      if (payrollId) {
        const response = await getEmployeePayrollHistory(payrollId)
        if (response.success) {
          setPayrollHistory(response.res)
        }
      }
    }
    loadHistory()
  }, [])

  return (
    <Phistory>
      <h4>Time Line Of Employee Salary</h4>
      <Empcardtwo>
        <Timeline mode='left'>
          {/*<Timeline.Item color='#C9C9C9' label='Nov 12, 2018'>*/}
          {/*  <div className={`${style.cardOne} ${style.cardWrapper}`}>*/}
          {/*    <h5>First Salary </h5>*/}
          {/*    <p>Amount: Rs.30, 000</p>*/}
          {/*  </div>*/}
          {/*</Timeline.Item>*/}
          {/*<Timeline.Item color='#C9C9C9' label='Dec 10, 2018'>*/}
          {/*  <div className={`${style.cardTwo} ${style.cardWrapper}`}>*/}
          {/*    <h5>Bonus</h5>*/}
          {/*    <p>Amount: Rs.30, 000</p>*/}
          {/*  </div>*/}
          {/*</Timeline.Item>*/}
          {/*<Timeline.Item color='#C9C9C9' label='1st Month'>*/}
          {/*  <div className={`${style.cardThree} ${style.cardWrapper}`}>*/}
          {/*    <h5>Allownce</h5>*/}
          {/*    <p>Amount: Rs.30, 000</p>*/}
          {/*  </div>*/}
          {/*</Timeline.Item>*/}

          {/*<Timeline.Item color='#C9C9C9' label='Feb 12, 2019' >*/}
          {/*  <div className={`${style.cardFive} ${style.cardWrapper}`}>*/}
          {/*    <h5>Salary Increment</h5>*/}
          {/*    <Row className={style.lableFour} >*/}
          {/*      <p>Increment by: Rs.10,0000</p>*/}
          {/*      <p>Old salary: Rs.30,000</p>*/}
          {/*      <p>Updated salary: Rs.40,000</p>*/}
          {/*    </Row>*/}
          {/*  </div>*/}
          {/*</Timeline.Item>*/}
          {payrollHistory?.history?.map(item => {
            return (
              <Timeline.Item color='#C9C9C9' position='right'>
                <p>Month Salary</p>
                <span>{item?.date}</span>
                <div
                >
                  <Dedcutions>
                    <div>
                      <p>{item.type}</p><span>{item.value}</span>
                    </div>
                  </Dedcutions>
                </div>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Empcardtwo>
    </Phistory>
  )
})
export default PayrollHistory

const Phistory = styled.div`
  .empiInfo {
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 320px) {
        flex-direction: column;
      }
      li {
        flex-basis: 35%;
        p {
          color: #0186e1;
          font-weight: 600;
          font-size: 16px;
          span {
            color: #2f2f2f;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
    }
  }
  h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #525353;
    text-align: center;
    margin-top: 30px;
    word-spacing: 1px;
  }
  .employeeStep {
    padding: 40px 0;
    position: relative;
    overflow: auto;
    .ant-timeline-item-content {
      p {
        color: #2980ff;
      }
      p.firstp {
        color: #34444c;
      }
      span.firstspan {
        color: #7460ee;
      }
    }
    .ant-timeline {
      max-width: 250px;
     .ant-timeline-item {
        padding-bottom: 48px;
        .ant-timeline-item-head {
          width: 14px;
          height: 14px;
          margin-left: -6px;
        }
        .ant-timeline-item-tail {
          height: calc(100% - -80px);
        }
      }
    }
    .ant-card {
      border: 1px solid ${mediumGray};
      border-radius: 4px;
      min-height: 60px;
      height: auto;
      margin-top: 8px;
      .ant-card-body {
        padding: 10px;
      }
    }
    .netSalary {
      position: absolute;
      min-width: 500px;
      width: auto;
      left: 120px;
      top: 0;
      z-index: 1;
      text-align: left;
      p {
        color: #55ce63;
      }
      .dedcution {
        .ant-card-body {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .allowance {
            p {
              color: #ffc024;
            }
          }
          .deductions {
            padding: 0 20px 0 20px;
            p {
              color: #ff3737;
            }
          }
          .increment {
            padding-right: 20px;
            p {
              color: #00c0f9;
            }
          }
        }
      }
    }
  }
`
const Empcardtwo = styled.div`
  height: 100%;
  padding: 0px 30px;
  .ant-timeline-item {
    .ant-timeline-item-label {
      max-width: 100px;
    }
    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      left: 110px !important;
    }
    .ant-timeline-item-content {
      left: 104px !important;
      width: calc(100% - 130px) !important;
    }
  }
`
const Dedcutions = styled(Card)`
  .ant-card-body {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .allowance {
      p {
        color: #ffc024;
      }
    }
    .deductions {
      padding: 0 20px 0 20px;
      p {
        color: #ff3737;
      }
    }
    .increment {
      padding-right: 20px;
      p {
        color: #00c0f9;
      }
    }
  }
`