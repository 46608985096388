import { cast, flow, types } from 'mobx-state-tree'
import { employeeProfileApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { employeeInfo, employeeWithoutPayrollInfo } from '@stores/employee/Employee'

export const Supervisor = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string)
})

export const Role = types.model({
  title: types.maybeNull(types.string),
  roleId: types.maybeNull(types.string),
})

export const employeeProfile = types.model({
  id: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  employee: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  designation: types.maybeNull(types.string),
  designationId: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  contactNumber: types.maybeNull(types.string),
  bloodGroup: types.maybeNull(types.string),
  joiningDate: types.maybeNull(types.string),
  shift: types.maybeNull(types.string),
  shiftId: types.maybeNull(types.string),
  shiftTitle: types.maybeNull(types.string),
  avgRating: types.maybeNull(types.number),
  supervisor: types.maybeNull(types.string),
  payrollId: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  roles: types.maybeNull(types.array(Role)),
})

export const profileDetails = types
  .model({
    data: types.maybeNull(employeeProfile),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    erorMessage: types.maybeNull(types.string),
    employeeInfo: types.maybeNull(types.array(employeeInfo)),
    employeeWithoutPayroll: types.maybeNull(types.array(employeeWithoutPayrollInfo)),


  })
  .views(self => ({
    get profileData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get getEmployeeData() {
      return toJS(self.data)
    },
    get getEmployeesWithoutPayroll() {
      return toJS(self.employeeWithoutPayroll)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadProfileDetails = flow(function* (employee_id, isCurrentUser, isAdmin,canViewAll, reset = false) {
      try {
        self.loading = true
        if (reset) {
          self.data = null
        }
        const res = yield employeeProfileApi.getEmployeeProfileDetails(
          employee_id,
          isCurrentUser,
          isAdmin,
          canViewAll
        )
        self.data = cast(res.data)
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'Failed to Load Profile')
        return { success: false, statusCode: error?.response?.status }
      } finally {
        self.loading = false
      }
    })

    const updateProfileDetails = flow(function* (
      id,
      data
    ) {
      try {
        self.loading = true
        yield employeeProfileApi.updateEmployeeProfileDetails(
          id,
          data,
        )
        notification.success('Profile updated successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.detail || 'Failed to update profile')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadProfileDetails,
      updateProfileDetails,


    }
  })

export function initEmployeeProfileDetails() {
  return profileDetails.create({})
}
