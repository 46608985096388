import React from 'react'
import { Col, Form, Radio, Row } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { SearchOutlined } from '@ant-design/icons';
import SearchFilter from '@commonComponents/SearchFilter'
import styled from 'styled-components'
import { colors } from 'constant/colors';

const { TealishBlue } = colors

interface DepartmentProps {
  setQueryParam: (data: object) => void;
}

const Searchdepartment = observer((props: DepartmentProps) => {
  return (
    <div className=''>
      <SearchFilter
        {...props}
        children={
          <div className='d-lg-flex w-100'>
            <Row gutter={4} className='rowDiv flex-grow-1 gap-md-2 gap-lg-3'>
              <Col sm={11} xs={24} md={12} lg={6} >
                <div >
                  <Form.Item
                    name='department_name'
                  >
                    <CommonInput
                      testId='search-department'
                      placeholder='Search Department'
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col sm={13} xs={24} md={11} lg={10} className={` d-flex align-items-center flex-wrap`}>
                <StyledFilterRadioWrapperDepartment className='mb-2'>
                  <StyleRadio>
                    <Form.Item name='is_active'>
                      <Radio.Group name='is_active'>
                        <Radio value={'true'}>Active</Radio>
                        <Radio value={'false'}>Inactive</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </StyleRadio>
                </StyledFilterRadioWrapperDepartment>
              </Col>
            </Row>
          </div>
        } />
    </div>
  )
})
export default Searchdepartment

export const AntRadio = styled(Radio)`
  span:last-child{
    font-size: 14px !important;
    line-height: 1px !important;
  }

  .ant-radio{
    .ant-radio-inner{
      width: 14px !important;
      height: 14px !important;
      border: 1.2px solid rgb(129 120 120) !important;
    }
  }
`
const StyledFilterRadioWrapperDepartment = styled.div`
justify-content: flex-start;
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
   color: #525353; 
    word-spacing: 4px;
  }
 
  .ant-radio-wrapper {
    .ant-radio {
      .ant-radio-inner {
        background: #ffffff;
        border: 1.2px solid #e2e2e2;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 26.4px;
        width: 24px;
        height: 24px;

        &::after {
          content: unset;
        }
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border: 7px solid ${TealishBlue};

          &:after {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
    }
  }

`
const StyleRadio = styled.div`
  margin-top: 8px !important;
`