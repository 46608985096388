import React from 'react'
import {MdMinimize} from 'react-icons/md'
import {Checkbox, Col, Row} from 'antd'
import {CommonInput} from '@commonComponents/input'
import {numberFormat} from 'components/utils/string_formate'
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

const AllowancesOrDeduction = ({
  dataList,
  record,
  setRecord,
  modalFor,
}) => {

  const selectAllowance = allowance => {
    const allowanceIndex = record.findIndex(item => item.id === allowance.id)
    if (allowanceIndex > -1) {
      const tempArray = [...record]
      tempArray.splice(allowanceIndex, 1);
      setRecord(tempArray)
    } else {
      setRecord(oldArray => [...oldArray, allowance]);
    }
  }
  return (
    <>
      <Tree>
        <div className={`mb-5`}>
                    {modalFor === 'allowance' && (
            dataList?.map(item=> (
              item.wageType === 'allowance' && 
            <Allowance >
              <Row gutter={12} align='middle'>
                <Col span={1}>
                  <div><Checkbox
                    onChange={() => selectAllowance(item)}
                    checked={record?.some(checkedItem => checkedItem.wageId === item.id || checkedItem.id === item.id)}
                  /></div>
                </Col>
                <Col span={18}>
                  <AllownaceText>{item.title}</AllownaceText>
                </Col>
                <Col span={1}>
                  <MdMinimize />
                </Col>
                <Col span={4}>
                  <Commoninputs disabled inputType='text' value={numberFormat(item.amount)} />
                </Col>
              </Row>
            </Allowance>
            )))
          }
          {modalFor === 'deduction' && (
            dataList?.map(item=> (
              item.wageType === 'deduction' && 
            <Allowance >
              <Row gutter={12} align='middle'>
                <Col span={1}>
                  <div><Checkbox
                    onChange={() => selectAllowance(item)}
                    checked={record?.some(checkedItem => checkedItem.wageId === item.id  || checkedItem.id === item.id)}
                  /></div>
                </Col>
                <Col span={18}>
                  <AllownaceText>{item.title}</AllownaceText>
                </Col>
                <Col span={1}>
                  <MdMinimize />
                </Col>
                <Col span={4}>
                  <Commoninputs disabled inputType='text' value={numberFormat(item.amount)} />
                </Col>
              </Row>
            </Allowance>
            )))
          }
        </div>
      </Tree>
    </>
  )
}

export default AllowancesOrDeduction

const Tree = styled.div`
  .CheckBox {
    width: 22px;
    height: 22px;
    background: ${TealishBlue};
    border: 1px solid #e2e2e2;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &::after {
      content: '';
      width: 15px;
      height: 2px;
      background-color: #fff;
      position: absolute;
    }

    &:active::after {
      content: unset;
    }
  }

  .texable {
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #525353;
  }

  .Allowance {
    padding-left: 29px;
    margin-bottom: 10px;

    .allowancetext {
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 5px 12px;
      border: 1px solid #e2e2e2;
    }

    .allowanceInput {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #525353;
      height: 34px !important;
      border-radius: 12px;
    }
  }
`

const Allowance = styled.div`
  padding-left: 29px;
  margin-bottom: 10px;

  .allowancetext {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 5px 12px;
    border: 1px solid #e2e2e2;
  }

  .allowanceInput {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #525353;
    height: 34px !important;
    border-radius: 12px;
  }
`

const AllownaceText = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 5px 12px;
  border: 1px solid #e2e2e2;
`

const Commoninputs = styled(CommonInput)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #525353 !important;
  height: 34px !important;
  border-radius: 12px;
`