import { LoadingOutlined } from '@ant-design/icons'
import { attendanceUrl } from '@api/const'
import absent from '@assets/img/cross.svg'
import halfLeaveAbsent from '@assets/img/leaveAbsent.svg'
import halfLeavePresent from '@assets/img/leavePresent.svg'
import leave from '@assets/img/leavestar.svg'
import remoteWork from '@assets/img/remoteWork.svg'
import present from '@assets/img/tick.svg'
import { Table } from '@commonComponents/table-v2/index'
import {
  faUser,
  faUserMinus,
  faUserPlus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ArrowDown, ArrowUP } from '@mainPage/newEmployee/EmployeeTable'
import {
  DownloadBtnWrapper,
  DownloadExtendWrapper,
  DownloadRecordWrapper,
} from '@mainPage/newEmployee/newEmployee'
import { useStore } from '@stores/root-store'
import { getNormalMonthYearDate, hasValue } from '@utils/common-functions'
import employeeName from '@utils/employee_name'
import { Button, Col, Row, Spin, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ATTENDENCE_FILTER_PARAMS,
  CURRENT_MONTH,
  CURRENT_YEAR,
  ATTEDENCE_MOD,
  VIEW_ALL,
} from '../../../../constant/constant'
import AttendanceSideDrawer from '../AttendanceSideDrawer'
import { FilterAdminView } from '../LeadAdminView/FilterAdminView'
import { showColumns } from '../utils'
import { AttendenceAdminView } from './StaticsEmpLeadView'
import { colors } from 'constant/colors'
import { permit } from '@utils/permission/permission'

const {TealishBlue} = colors

export const AttendenceLeadEmp = observer(
  ({
    setRefreshTable,
    refreshTable,
    showDrawer,
    open,
    onClose,
    drawerEmpId,
    employeeRecord,
    empId,
    clickedDate,
    selectedCol,
  }: any) => {
    const {
      userInfo: { getRoles, getcompany },
      attendanceModal: {
        loadAttendanceHierarchyParent,
        attendanceHierarchyData,
        loadAttendanceChildrenHierarchy,
      },
      themesModel: { getColorsData },
    } = useStore(null)

    const { accessView } = getRoles
    const isAdmin = accessView?.includes('is_admin')
    const isLead = getcompany?.isLead

    const ifAuthorized = isAdmin || isLead

    const [showInterns, setshowInterns] = useState(false)
    const tableRef = useRef(null)
    const [showIcons, setShowIcons] = useState(true)
    const [showHierarchy, setshowHierarchy] = useState(false);

    const [queryParam, setQueryParam] = useState<any>(ATTENDENCE_FILTER_PARAMS)
    const [hierarchyData, setHierarchyData] = useState([])
    const [loader, setLoader] = useState(null)

    const canViewInterns = permit(getRoles, 'accessView', 'view_intern')
    const canViewAll  = permit(getRoles, ATTEDENCE_MOD, VIEW_ALL)

    const AdminOrLead = isAdmin || isLead;
    const isFilter = AdminOrLead || canViewInterns || canViewAll;

    async function load() {
      await loadAttendanceHierarchyParent()
    }

    useEffect(() => {
      ifAuthorized && load()
    }, [])

   const resultMonth = queryParam?.month ? getNormalMonthYearDate(queryParam.month) : `${CURRENT_MONTH} ${CURRENT_YEAR}`

    const internsOnly = () => {      
      if (!showInterns) {
        setQueryParam({
          ['view_intern']: true,
        })
      } else {
        setQueryParam({})
      }
      setshowInterns(!showInterns)
    }

    const hierarchySwitch = (event: number) => {
      if (event == 1) {
        load()
      }
      setshowHierarchy(!showHierarchy)
      setQueryParam({})
    }

    const showAdvanceBTN = () => {
      setShowIcons(!showIcons)
    }

    if (!queryParam) {
      setQueryParam(ATTENDENCE_FILTER_PARAMS)
    }

    const CustomHalfLeavePresentIcon = () => {
      return (
        <HalfLeaveIconContainer theme={getColorsData?.theme}>
          <img src={showStatus[0].icon} alt='present icon' />
          <img src={showStatus[2].icon} alt='leave icon' />
        </HalfLeaveIconContainer>
      )
    }

    const CustomHalfLeaveAbsentIcon = () => {
      return (
        <HalfLeaveIconContainer theme={getColorsData?.theme}>
          <img src={showStatus[1].icon} alt='absent icon' />
          <img src={showStatus[2].icon} alt='leave icon' />
        </HalfLeaveIconContainer>
      )
    }

    const columns = [
      ...[employeeName('setRecord', 'setVisible', 'attendanceTable')],
      ...showColumns(
        queryParam,
        showDrawer,
        CustomHalfLeaveAbsentIcon,
        CustomHalfLeavePresentIcon
      ),
    ]
    const showStatus = [
      { id: 1, text: 'Present', icon: present, style: 'present' },
      { id: 2, text: 'Absent', icon: absent, style: 'absent' },
      { id: 3, text: 'Leave', icon: leave, style: 'absent' },
      {
        id: 4,
        text: 'Present | Leave',
        icon: halfLeavePresent,
        style: 'halfLeavePresent',
      },
      { id: 5, text: 'Absent | Leave', icon: halfLeaveAbsent, style: 'absent' },
      { id: 5, text: 'Remote Leave', icon: remoteWork, style: 'absent' },
    ]

    useEffect(() => {
      setHierarchyData(attendanceHierarchyData)
    }, [attendanceHierarchyData])

    const loadData = async (record, onExpand, e) => {
      setLoader({ id: record.id, loader: true })
      await loadAttendanceChildrenHierarchy(record.id, record)
      onExpand(record, e)
      setLoader({ id: '', loader: false })
    }

    
    return (
      <>
        {!isAdmin && <AttendenceAdminView />}
        {!showHierarchy && (
            <FilterAdminView
              setQueryParam={setQueryParam}
              queryParam={queryParam}
              adminLead={AdminOrLead}
              canViewInterns={canViewInterns}
              canViewAll={canViewAll}
              showInterns={showInterns}
            />
)}
        <AttendanceSideDrawer
          onClose={onClose}
          open={open}
          drawerEmpId={drawerEmpId}
          employeeRecord={employeeRecord}
          empId={empId}
          clickedDate={clickedDate}
          calledFrom='attendance'
        />
        <FlatList>
          <Row>
            <Col xs={24} md={24} lg={24}>
              <AttendanceCard className='attendanceFilter'>
                <Row justify='space-evenly'>
                  {showStatus.map(val => (
                    <Col key={val.id}>
                      <div className='d-flex'>
                        {val.text === 'Present | Leave' ? (
                          <CustomHalfLeavePresentIcon />
                        ) : val.text === 'Absent | Leave' ? (
                          <CustomHalfLeaveAbsentIcon />
                        ) : (
                          <img
                            className={val.style}
                            style={{
                              width: val.text === 'Remote Leave' && '25px',
                              aspectRatio: '1',
                            }}
                            src={val.icon}
                            alt={val.text}
                          />
                        )}
                        <p>{val.text}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </AttendanceCard>
            </Col>
          </Row>
          {/* <ShowOnPermission mod={ATTEDENCE_MOD} method={PUT_METHOD}> */}
            {isFilter && <DownloadRecordWrapper
              className={`advanceBtn d-flex justify-content-end`}
            >
              <DownloadBtnWrapper>
                {showIcons ? (
                  <DownloadExtendWrapper>
                    {!showInterns && (AdminOrLead || canViewAll) && (
                      <IconWrapper>
                        <Tooltip
                          title={
                            !showHierarchy
                              ? 'Employee Hierarchy'
                              : 'Employee List'
                          }
                        >
                          {!showHierarchy  ? (
                            <FontAwesomeIcon
                              icon={faUsers}
                              onClick={() => hierarchySwitch(1)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faUser}
                              onClick={() => hierarchySwitch(2)}
                            />
                          )}
                        </Tooltip>
                      </IconWrapper>
                    )}
                      {!showHierarchy && ( isAdmin || canViewInterns || canViewAll ) && <Tooltip
                        title={!showInterns ? 'View Interns' : 'Hide Interns'}
                      >
                        <span style={{ cursor: 'pointer' }} className='d-flex'>
                          <FontAwesomeIcon
                            icon={showInterns ? faUserMinus : faUserPlus}
                            onClick={() => internsOnly()}
                          />
                        </span>
                      </Tooltip>}
                  </DownloadExtendWrapper>
                ) : null}
                <Button onClick={showAdvanceBTN}>Advance</Button>
              </DownloadBtnWrapper>
            </DownloadRecordWrapper>}
          {/* </ShowOnPermission> */}
        </FlatList>
        <div className='table-responsive'>
          <Tablediv theme={getColorsData?.theme}>
            <div>
              {!showHierarchy && (
                <Attendencetable
                  ref={tableRef}
                  columns={selectedCol?.length ? selectedCol : columns}
                  dataUrl={attendanceUrl}
                  refreshTable={refreshTable}
                  setRefreshTable={setRefreshTable}
                  queryParam={hasValue(queryParam) ? queryParam : ''}
                />
               )}
              {showHierarchy && (
                <Attendencetable
                  queryParam={queryParam}
                  dataSource={hierarchyData}
                  columns={columns}
                  expandable={{
                    rowExpandable: record => record.name !== 'Not Expandable',
                    expandIcon: ({ expanded, onExpand, record }) => {
                      if (expanded) {
                        if (record?.hasChild) {
                          return (
                            <ArrowUP
                              style={{ fontSize: '28px', cursor: 'pointer' }}
                              onClick={async e => {
                                record['children'] = []
                                onExpand(record, e)
                              }}
                            />
                          )
                        }
                      } else if (record?.hasChild) {
                        if (loader?.loader && loader?.id === record?.id) {
                          return (
                            <Spinner
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 14 }}
                                  spin
                                />
                              }
                            />
                          )
                        }
                        return (
                          <ArrowDown
                            style={{ fontSize: '28px', cursor: 'pointer' }}
                            onClick={e => loadData(record, onExpand, e)}
                          />
                        )
                      }
                    },
                  }}
                />
              )}
            </div>
            <div className='attendanceResult'>
              <div>
                <p>
                  Showing results for <span>{resultMonth}</span>
                </p>
              </div>
            </div>
          </Tablediv>
        </div>
      </>
    )
  }
)

const Tablediv = styled.div`
  .attendanceResult {
    width: fit-content;
    height: 42px;
    position: absolute;
    left: 31px;
    background: ${props => (props.theme === 'dark' ? '#0d0d0d' : '#ffffff')};
    border-radius: 0px 0px 12px 12px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      color: #525353;
      word-spacing: 0px !important;
      text-align: center;
    }

    span {
      color: ${TealishBlue};
    }
  }

  .ant-table-cell {
    min-width: 80px !important;
  }
`

const AttendanceCard = styled.div`
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  .ant-row {
    width: 1037px;
    justify-content: start;
  }

  p {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px !important;
    width: 125px;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
  }

  img {
    margin-right: 10px;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    p {
      width: 100px;
      font-size: 12px;
      padding: 3px;
      margin: 3px;
    }
  }
  .d-flex {
    align-items: center;
    margin-left: 10px;

    img {
      margin-right: 5px;
    }
  }
`
const Attendencetable = styled(Table)`
  .ant-table-container table > tbody > tr td:first-child {
    display: flex;
    align-items: center;
    .textHandle {
      max-width: 140px !important;
    }
  }
`

const IconWrapper = styled.span`
  margin-left: 15px;
  display: flex;
  cursor: pointer;
`

const HalfLeaveIconContainer = styled.div`
  background: ${props => (props.theme === 'dark' ? '#0d0d0d' : '#ffffff')};
  border-radius: 50%;
  padding: 2px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: 0 !important;
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
`

const FlatList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -25px;
`

export const Spinner = styled(Spin)`
  position: absolute;
  right: 0;
`
