import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Timeline } from 'antd'
import { MdFactCheck } from 'react-icons/md'
import { Skeleton } from 'antd'
import { useParams } from 'react-router-dom'
import { useStore } from '@stores/root-store'
import styled from 'styled-components'
import moment from 'moment'
import { MDY_DATE_FORMAT } from '@mainPage/constant'
import { colors } from 'constant/colors'

const { TealishBlue } = colors


const EmployeeExperienceProf = observer(() => {
  const [isExperDataLoader, setIsExperDataLoader] = useState(true)
  const { id: employeeID } = useParams()
  const {
    experienceDetails: { experienceData, loadExperienceDetails },
  } = useStore(null)

  async function load() {
    await loadExperienceDetails(employeeID)
    setIsExperDataLoader(false)
  }
  useEffect(() => {
    load()
  }, [employeeID])

  return (
    <Skeleton
      active
      loading={isExperDataLoader}
      paragraph={{ rows: 5 }}
      style={{ paddingLeft: 70, paddingRight: 250 }}
    >
      <StyledDiv className={'education'}>
        <div
          className={`${'educationInfo'} d-flex gap-3 mb-3 align-items-center`}
        >
          <div>
            <MdFactCheck />
          </div>
          <div className={'timelineTitle'}>Experience</div>
        </div>
        <div className={'degrees'}>
          {experienceData?.length > 0 && experienceData[0].position ? (
            <Timeline>
              {experienceData.map(item => {
                return (
                  <Timeline.Item>
                    <div className={'title'}>{item?.position}</div>
                    <div className={'subtitle'}>
                      {item?.company}, {item?.address} |{' '}
                      {moment(item?.fromDate)?.format(MDY_DATE_FORMAT)} -{' '}
                      {item?.toDate
                        ? moment(item?.toDate)?.format(MDY_DATE_FORMAT)
                        : 'Present'}
                    </div>
                  </Timeline.Item>
                )
              })}
            </Timeline>
          ) : (
            <div className={'dummyExperience'}>
              <p className={'subtitle'}>No Data</p>
            </div>
          )}
        </div>
      </StyledDiv>
    </Skeleton>
  )
})

export default EmployeeExperienceProf

const StyledDiv = styled.div`
  padding: 10px 30px 30px 30px;
  .educationInfo {
    svg {
      fill: currentColor;
      font-size: 20px;
      position: relative;
      line-height: 25px;
      top: 3px;
    }
    .timelineTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #191919;
      font-family: 'Outfit';
    }
  }
  .dummyExperience {
    .title {
      color: #34444c57 !important;
    }
    .subtitle {
      color: #6f6f6f36 !important;
    }
  }
  .degrees {
    padding-left: 38px;
    .ant-timeline {
      .ant-timeline-item-head-blue {
        background-color: ${TealishBlue};
        border: 2px solid #e3e3e3;
      }
      .ant-timeline-item {
        .ant-timeline-item-last {
          .ant-timeline-item-tail {
            display: block;
            height: 100%;
          }
        }
        .ant-timeline-item-head {
          width: 16px;
          height: 16px;
          left: -2px;
        }
      }
    }
    .dummyEducation {
      .title {
        color: #34444c57 !important;
      }
      .subtitle {
        color: #6f6f6f36 !important;
      }
      .smalltext {
        color: #6f6f6f33 !important;
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #34444c !important;
    font-family: 'Poppins';
  }
  .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #6f6f6f !important;
  }
  .smalltext {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #6f6f6f !important;
    width: 100%;
    max-width: 650px;
    font-family: 'Poppins';
  }
`
