export const permit = (getcompany, mod, method) => {
  const findResourcesByMethod = (obj, method) => {
    if (
      !obj ||
      typeof obj !== 'object' ||
      !mod ||
      typeof mod !== 'string' ||
      !method ||
      typeof method !== 'string'
    ) {
      throw new Error('Invalid input parameters. Please provide valid inputs.')
    }

    const allowedResources = []
    for (const [resource, methods] of Object.entries(obj)) {
      if ((methods as string[])?.includes(method)) {
        allowedResources.push(resource)
      }
    }
    return allowedResources
  }

  try {
    const allowedResources = findResourcesByMethod(getcompany, method)
    const isPermissionGranted = allowedResources.includes(mod)
    return isPermissionGranted
  } catch (error) {
    console.error(error.message)
    return false
  }
}
