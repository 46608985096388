import { headerAuthorization } from '../common-utils'

import { projectsUrl } from '../const'
import { BaseApi } from '../baseApi'

class ProfileProjectsApi extends BaseApi {
  getProfileProjectsDetails = async (profile, employee_id) => {
    try {
      let url = projectsUrl
      if (!profile) {
        url += `?employee_id=${employee_id}`
      }
      const response = await this.axios.get(url, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ProfileProjectsApi

