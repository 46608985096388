/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState, useEffect } from 'react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { Form, Space, Card, Menu, Dropdown, Tooltip } from 'antd'
import { constRoute } from '@utils/route'
import AssetForm from './AssetForm'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react-lite'
import CustomButton from '@commonComponents/button'
import { assetUrl } from '@api/const'
import { Table } from '@commonComponents/table-v2/index'
import SearchAsset from './searchAssets'
import AssetsHistory from './assetHistory'
import CustomDrawer from '@commonComponents/drawer'
import styled from 'styled-components';
import { StyledAttachmentWrapper, StyledChildDiv, LeftImageWrpper, StyledLeftImage, RightImageWrapper, StyledRightImage } from '@commonComponents/attachment/index.styles'
import { APPROVAL_LIST, ASSET_LIST } from './const'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import CustomColumnSelect from '@commonComponents/customColumn/customColumnSelect'
import { CustomSpanIcon } from '@mainPage/newEmployee/newEmployee'
import { CommonSelect } from '@commonComponents/select'
import { MdEdit, MdRemoveRedEye } from 'react-icons/md'
import {faClockRotateLeft} from '@fortawesome/free-solid-svg-icons'
import { ASSETS_MOD, HISTORY, POST_METHOD, PUT_METHOD, VIEW_ALL } from 'constant/constant'
import { StatusButtons, convertToUppercase, statusIcons } from '@utils/common-functions'
import { permit } from '@utils/permission/permission'
import ShowOnPermission from '@commonComponents/permit'


const Assets = observer(() => {
  const [form] = Form.useForm()
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [singleAsset, setSingleAsset] = useState(null)
  const [isUpdate, setisUpdate] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const tableRef = useRef(null);
  const [resetTable, setResetTable] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyData, setHistoryData] = useState(null)
  const [queryParam, setQueryParam] = useState({})
  const [showAttachment, setShowAttachment] = useState(false)
  const [tabList, setTabList] = useState([])
  const [activeTab, setActiveTab] = useState('')
  const [attachment, setAttachments] = useState([])
  const [defaut, setDefault] = useState('')
  const [btnLoader, setBtnLoader] = useState(false)
  const [showIcon, setShowIcon] = useState(true)
  const [customCol, setCustomCol] = useState([])
  const [columnns, setColumnns] = useState(null)
  const [selectedCol, setSelectedColumn] = useState(null)
  const [clickTriggetColor, setClickTriggetColor] = useState(true)
  const [resetFilters, setResetFilters] = useState(false)

  const {
    assetsModel: { updateAssets, exportAssetRecord , getAssetsData,loadAssets},
    employeeDetails: { loadAllEmployeeList, getEmployeeAllList },
    employeeDetails: { setEmployeeColField, loadEmployeeColField, getEmpFields },
    themesModel: { getColorsData, loadColor },
    userInfo: { getRoles }
  } = useStore(null)


  useEffect(() => {
    async function loadData() {
      await loadAllEmployeeList()
      !getColorsData &&  await loadColor()
    }
    loadData()
  }, [])

  useEffect(() => {
    async function loadAssetForApproval() {
      await loadAssets()
    }
    loadAssetForApproval()
  }, [refreshTable])



  const filterData = getAssetsData?.filter(item => item?.status === 'pending') || [];
  const permission = permit(getRoles ,ASSETS_MOD,PUT_METHOD)

  useEffect(() => {
    let tab_List = []
    permission ? tab_List = [...ASSET_LIST, ...APPROVAL_LIST] : tab_List = [...ASSET_LIST]
    setTabList(tab_List)
    setActiveTab(tab_List[0].key)
  }, [])

  const onStatusChange = async (record,status)=>{ 
    const attributesIds = record.attributes.map(item => item.id)  
    const updatedRecord = {
      ...record,
      status: status,
      attributes: attributesIds
    };
      const res = await updateAssets(record.id, updatedRecord);
      res?.success && setRefreshTable(true)  
  }

  const editForm = data => {
    setSingleAsset(data)
    setIsEditModalVisible(true)
    setisUpdate(true)
  }

  const HistoryCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = row => {
    setHistoryData({ asset_id: row.id, status: row.status })
    setIsModalOpen(true);
  };

  const showAttachmentmodal = data => {
    setShowAttachment(true)
    setAttachments(data.attachments)
    setDefault(data.attachments?.[0]?.attachments)

  }

  const onPreview = item => {
    setDefault(item)
  }

  const onTabChange = key => {
    setActiveTab(key)
    setQueryParam({})
    setResetFilters(true)

  }

  const handleOptionClick = ({ key }) => {
    exportAssetRecord(key, queryParam)
  };

  const handleIcon = () => {
    setShowIcon(!showIcon);
  };

  useEffect(() => {
    if (getEmpFields?.length && getEmpFields[0]?.fields?.assetFields?.length) {
      const newCol = columns?.filter((col, index) => {
        if (getEmpFields[0]?.fields?.assetFields[index]?.isActive && col.id == getEmpFields[0]?.fields?.assetFields[index]?.id) {
          return col
        }
      });
      setCustomCol([...getEmpFields[0].fields.assetFields]);
      setSelectedColumn([...newCol]);
    }
  }, [getEmpFields])


  const DownloadOptions = (
    <DropDownMenuWrapper>
      <Menu onClick={handleOptionClick}>
        <Menu.Item key='pdf'>PDF Format</Menu.Item>
        <Menu.Item key='csv'>CSV Format</Menu.Item>
      </Menu>
    </DropDownMenuWrapper>
  );

  
  const allEmployees = getEmployeeAllList?.map(value => ({ key: value.id, value: value.fullName }));

  const onAssigned = async (data,record)=>{
    const attributesIds = record.attributes.map(item => item.id)
   const allData = {
      ...record,
      'assigned_to_id':data,
      attributes: attributesIds
    }
   const response = await updateAssets(record.id,allData)
   response.success && setRefreshTable(true)
}
  const columns = [
    {
      title: 'Name',
      fixed: 'left' as const,
      dataIndex: 'title',
      id: 1,
      render: text => (<Tooltip title={text}>{text}</Tooltip>)
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      id: 2,
      render: text => (<Tooltip title={text}>{text}</Tooltip>)
    },
    {
      title: 'Assets Category',
      dataIndex: 'categoryTitle',
      id: 3,
      render: data => (<Tooltip title={data}>{data}</Tooltip>)
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedToId',
      key: 'assignedToId',
      id: 4,
      render: (text, record) => {
        return (
          <>
          {permission ?
         ( <CommonSelect
          listHeight={125}
          getPopupContainer={trigger => trigger.parentNode}
          placeholder='Select Employee'
          data={allEmployees}
          showSearch
          value={text}
          onChange={(data => onAssigned(data,record))}
          filterOption={(input, option) =>(option.children.toLowerCase().includes(input.toLowerCase()))}/>) : record?.assignedToName?.trim() ?? 'Un Assigned'}
          </>
        )
      }
    },
    {
      title: 'Assigned By',
      dataIndex: 'assignedByName',
      id: 4,
      render: (text => <Tooltip>{text?.trim() ? text : 'Un Assigned'}</Tooltip>)
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      key: 'condition',
      id: 6,
      render: (text => <Tooltip title={text}>{text}</Tooltip>)
    },
    {
      title: 'Authorized By',
      dataIndex: 'authorizedByName',
      render: (text => <Tooltip title={text?.trim() ? text : 'Unauthorized'}>{text?.trim() ? text : 'Unauthorized'}</Tooltip>),
    },
    {
      title: 'Asset Expense',
      dataIndex: 'purchasedDate',
      id: 8,
      render: (_, data) => (
        <>
          <Tooltip title={data.purchased_amount}>
            <SetFont primary>PKR {data.purchasedAmount}</SetFont>
          </Tooltip>
          <SetFont>{ data.purchasedDate}</SetFont>
        </>
      )
    }, 
       {
      title: 'Status',
      dataIndex: 'status',
      render: (text,record) => (
        <>
        {activeTab === 'assets' ?
        <div className={`${text}Icon`}>{statusIcons[text]}<div>{convertToUppercase(text)}</div></div>:
        <StyleStatusMain>
          <p className={`${text}`}>{convertToUppercase(text)}</p>
          <StyleStatusImage>
            {Object.entries(StatusButtons).map(([status,image])=>(
              <img src={image} alt={status} onClick={()=>onStatusChange(record,status)} />
            ))}
          </StyleStatusImage>
        </StyleStatusMain>}
        </>
      ),
    },
    {
      
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      id: 10,
      render: (row, data) => (
      <div className='d-flex accordingToTheme'>
        <ShowOnPermission mod={ASSETS_MOD} method={PUT_METHOD}>
        <div className='actionIcons'>
          <Tooltip title='Edit'>
            <MdEdit onClick={() => { editForm(data); setisUpdate(true)}} />
          </Tooltip>
        </div>
        </ShowOnPermission>
        <div className='actionIcons'>
          <Tooltip title='Attachment'>
            <MdRemoveRedEye onClick={() => showAttachmentmodal(data)} />
          </Tooltip>
        </div>
        <div className='actionIcons'>
         <Tooltip title={HISTORY}>
          <FontAwesomeIcon onClick={() => showModal(data)} icon={faClockRotateLeft} />
         </Tooltip>
        </div>
      </div>
      ),
    }
  ]
  useEffect(() => {
    setColumnns(columns)
  }, [])

  
  const ColumnsForApproval = columns.filter(item => item.title !== 'Assigned To' && item.title !== 'Assigned By' && item.title !== 'Authorized By' && item.title !== 'Action')
  const contentList = {
    assets: (
      <>
          <div className='advanceBtn d-flex justify-content-end '>
            <DownloadBtnWrapper>
              {showIcon ? (
                <DownloadExtendWrapperr>
                  <Dropdown
                    getPopupContainer={trigger => trigger.parentElement}
                    overlay={DownloadOptions}
                    placement='bottom'
                    onOpenChange={() => setClickTriggetColor(!clickTriggetColor)}>
                    <Tooltip title={`Download Files`}>
                      <CustomSpanIcon>
                        <FontAwesomeIcon icon={faDownload} style={{color: `${getColorsData?.bodyColor}`}}/>
                      </CustomSpanIcon>
                    </Tooltip>
                  </Dropdown>
                  <CustomColumnSelect
                    customCol={customCol}
                    setCustomCol={setCustomCol}
                    loadColField={loadEmployeeColField}
                    setColField={setEmployeeColField}
                    getColFields={getEmpFields}
                    columns={columnns}
                    callFrom={'assetFields'} />
                </DownloadExtendWrapperr>)
                : null}
              <button onClick={handleIcon} style={{backgroundColor: `${getColorsData?.bodyColor}` , border: `1px solid ${getColorsData?.bodyColor}`}}>Advance</button>
            </DownloadBtnWrapper>
          </div>
          <div className='table-responsive mt-3'>
            <Table
              ref={tableRef}
              dataUrl={assetUrl}
              columns={columns}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              resetTable={resetTable}
              queryParam={queryParam}
              setResetTable={setResetTable}
              setPageNumber={data => setPageNumber(data)}
            />
            </div>
      </>
    ),
    assetsForApproval: (
      <div className='table-responsive mt-3'>
        <Table
          dataSource = {filterData}
          columns={ColumnsForApproval}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          resetTable={resetTable}
          queryParam={queryParam}
          setResetTable={setResetTable}
          />
      </div>
    ),
  }


  return (
    <div className='page-wrapper' >
      {/* Page Content */}
      < div className='content container-fluid' >
        {/* Page Header */}
        < div className='page-header' >
          <div className=''>
            <div className='d-flex justify-content-between mb-4'>
              <BreadCrumbs
                list={[
                  { name: 'Dashboard', link: constRoute.home },
                  { name: 'Assets', link: constRoute.assets },
                ]}
                pageTitle={'Assets'}
              >
              </BreadCrumbs>
              <ShowOnPermission mod={ASSETS_MOD} method={POST_METHOD}>
              <div>
                  <CustomButton
                    onClick={() => {
                      setisUpdate(false)
                      setIsEditModalVisible(true)
                      setSingleAsset(null)
                    }}
                    title='Add Assets'
                  /> 
              </div>
              </ShowOnPermission>
            </div>
          </div>
        </div>
        <div>
          <div className='customTabs'>
            <TabCard
              className='d-flex justify-content-between align-items-center mb-4'
              tabList={tabList}
              activeTabKey={activeTab}
              onTabChange={key => {
                onTabChange(key)
              }}
            >
            </TabCard>
          </div>
          <ShowOnPermission mod={ASSETS_MOD} method={VIEW_ALL}>
            <SearchAsset
              activeTab={activeTab}
              setQueryParam={setQueryParam}
              resetFilters={resetFilters}
              setResetFilters={setResetFilters}
            />
          </ShowOnPermission>
          {contentList[activeTab]}
        </div>
        <div className='row'>
          <CustomDrawer
            className='addDrawer'
            title={singleAsset ? 'Update Assets' : 'Add Assets'}
            visible={isEditModalVisible}
            setVisible={() => {
              setIsEditModalVisible(false)
              form.resetFields()
            }}
            width={700}
            customFooter={
              <Space>
                <CustomButton
                  htmlType='submit'
                  type='primary'
                  title={'Cancel'}
                  className='cancelButton'
                  onClick={() => {
                    setIsEditModalVisible(false)
                    setSingleAsset({ id: null })
                  }}
                />
                <CustomButton
                  form='assets-form'
                  htmlType='submit'
                  type='primary'
                  loading={btnLoader}
                  disabled={btnLoader}
                  title={singleAsset ? 'Update' : 'Add'}
                  className='addButton'
                />
              </Space>
            }
          >
            <AssetForm
              isUpdate={isUpdate}
              data={singleAsset}
              queryParam={queryParam}
              setIsEditModalVisible={setIsEditModalVisible}
              setRefreshTable={setRefreshTable}
              setBtnLoader={setBtnLoader}
            />
          </CustomDrawer>
          <>
            <CustomDrawer
              className='addDrawer'
              width={600}
              title='Asset History '
              visible={isModalOpen}
              setVisible={HistoryCancel}
            >
              <AssetsHistory
                historyData={historyData}
              />
            </CustomDrawer>
          </>
          <CustomDrawer
            title='Attachment'
            className='addDrawer'
            visible={showAttachment}
            setVisible={() => {
              setShowAttachment(false)
            }}
            width={600}
          >
            {attachment && attachment.length ?
              <StyledAttachmentWrapper>
                <StyledChildDiv flexBasis='20%'>
                  <LeftImageWrpper>
                    {attachment.map(item => {
                      return <StyledLeftImage src={item.attachments} onClick={() => { onPreview(item.attachments) }} />;
                    })}
                  </LeftImageWrpper>
                </StyledChildDiv>
                <StyledChildDiv flexBasis='80%'>
                  <RightImageWrapper>
                    <StyledRightImage src={defaut} />
                  </RightImageWrapper>
                </StyledChildDiv>
              </StyledAttachmentWrapper> :
              <SetAttach>
                <span className='d-flex justify-content-center'>No Attachments</span>
              </SetAttach>}
          </CustomDrawer>
        </div>
      </div >
      <div style={{ display: 'none' }}>{pageNumber}</div>
    </div >
  )
})

export default Assets;


export const DownloadBtnWrapper = styled.div`
    display: flex;
    border: 1px solid rgba(217, 217 , 217);
    border-radius: 36px;
    align-items: center;
    height: 40px;

  button {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`

export const DownloadRecord = styled.div`
  margin-bottom: 30px;
`

const TabCard = styled(Card)`
  .ant-tabs-nav-more{
    visibility: hidden;
    order: 1;
  }
`

export const DropDownMenuWrapper = styled.div`
     ul{
    border-radius: 8px;

      li{
        border: 1px solid #E3E3E3;
        margin: 13px 12px 13px 12px;
        border-radius: 8px;
        align-items: center;
        transition: all .2s ease-in;
      }
    }
    .ant-dropdown-menu-title-content{
      display: flex;
      align-items: center;
    }
`

export const SetFont = styled.div`
  font-size: ${props => props.primary ? '16px' : '12px'};
  width:fit-content;
`

const SetAttach = styled.div`
font-size: 12px;
font-style: italic;
color: rgb(145 142 142);
`

export const DownloadExtendWrapperr = styled.div`
    width: hug(255px);
    height: hug(48px);
    border-radius: 36px 0px 0px 36px;
    border-right: 0px;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex: 1;
    width: 130px;

    svg { 
      background-color: transparent;
    } 

    .anticon-usergroup-delete{
      padding-left: 5px;
      font-size: 20px;

      &:hover {
        font-size: 28px;
      }
      svg:hover{
        background-color: unset !important;
      }
    }
`

export const StyleStatusImage = styled.div`
display: flex;
justify-content: center;
width: 117px;
gap: 10px;
  img{
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`

export const StyleStatusMain = styled.div`
  text-align: center;
  p{
    letter-spacing: 1px;
    padding: 0px 8px;
    width: 100px;
    border-radius: 15px;
  }

  .pending{
    color: rgb(221, 200, 6);
    background-color: rgba(221, 200, 6, 0.2);
  }
`