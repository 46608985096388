import { BaseApi } from '../baseApi'
import { leaveTypeUrl } from '../const/index'

class EmployeeLeaveTypeApi extends BaseApi {
  setEmployeeLeaveType = async data => {
    try {
      const response = await this.axios.post(leaveTypeUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getEmployeeLeaveType = async (num, pageSize, queryParam = {}) => {
    try {
      let url = leaveTypeUrl

      url += `?page=${num ? num : 1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {},
        }
      )
      return response.data.items
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeeLeaveType = async (id, data) => {
    try {
      const response = await this.axios.put(`${leaveTypeUrl}/${id}`,data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteEmployeeLeaveType = async id => {
    try {
      const response = await this.axios.delete(`${leaveTypeUrl}/${id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeLeaveTypeApi
