import { headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class TeamsApi extends BaseApi {
  setTeamsDetails = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}teams/`,
        {
          name: data.name,
        },
        headerAuthorization(this)
      )
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getTeamsDetails = async () => {
    try {
      const response = await this.axios.get(`${baseUrl}teams/`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateTeamsDetails = async (data, recordId) => {
    try {
      const response = await this.axios.put(
        `${baseUrl}teams/${recordId}/`,
        {
          name: data,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteTeamsDetails = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}teams/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default TeamsApi
