import { leaveHistoryUrl } from '@api/const'
import pending from '@assets/img/pending-icon.svg'
import Tooltip from '@commonComponents/CustomToolTip'
import { Table } from '@commonComponents/table-v2'
import {
  APPLIER_NAME,
  LEAD_NAME,
  NORMAL_DATE_FORMAT,
  DURATION
} from '@mainPage/EmployeeLeave/const'
import { statusImages } from '@mainPage/EmployeeLeave/utils'
import { getFullName } from '@utils/common-functions'
import { colors } from 'constant/colors'
import { REASON } from 'constant/constant'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useRef } from 'react'
import styled from 'styled-components'

const EmployeeLeaveHistoryTable = observer(
  ({
    refreshTable,
    setRefreshTable,
    queryParam,
    resetTable,
    setResetTable,
  }: any) => {
    const tableRef = useRef(null)

    const columns = [
      {
        title: <CenteredDiv>{APPLIER_NAME}</CenteredDiv>,
        fixed: window.innerWidth >= 700 ? ('left' as const) : false,
        render: (_, row) => {
          return (
            <CenteredDiv>
              <p>{getFullName(row) || '-'}</p>
            </CenteredDiv>
          )
        },
      },
      {
        title: <CenteredDiv>{LEAD_NAME}</CenteredDiv>,
        dataIndex: 'leadName',
        width: '220px',
        render: leadName => {
          return (
            <CenteredDiv>
              <Tooltip
                placement='topLeft'
                title={leadName}
                minLength={15}
              >
                <p>{leadName || 'No Lead Selected'}</p>
              </Tooltip>
            </CenteredDiv>
          )
        },
      },
      {
        title: <CenteredDiv>Leave Type</CenteredDiv>,
        dataIndex: 'leaveTypeTitle',
        render: leaveType => (
          <CenteredDiv className='text-capitalize'>{leaveType}</CenteredDiv>
        )
      },
      {
        title: <CenteredDiv>{DURATION}</CenteredDiv>,
        width: '220px',
        render: (_, row) => {
          return (
            <CenteredDiv>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p> {moment(row?.startDate).format(NORMAL_DATE_FORMAT)} - {moment(row?.endDate).format(NORMAL_DATE_FORMAT)}</p>
                <div style={{ textAlign: 'center' }}><span className='duration-text' style={{ padding: '1px 6px', backgroundColor: colors.lightGray8, borderRadius: '10px', width: 'min-content' }}>{row?.duration} Day(s)</span> {' '}</div>
              </div>
            </CenteredDiv>
          )
        },
      },
      {
        title: <CenteredDiv>{REASON}</CenteredDiv>,
        dataIndex: 'reason',
        width: '220px',
        render: reason => (
          <CenteredDiv>
            <Tooltip placement='topLeft' title={reason} minLength={15}>
              <span>{(reason?.slice(0,15) + (reason?.length > 15 ? '....' : '' ))|| 'No reason provided'}</span>
            </Tooltip>
          </CenteredDiv>
        ),
      },
      {
        title: <CenteredDiv>Status</CenteredDiv>,
        dataIndex: 'status',
        render: status => {
          return (
            <CenteredDiv>
              <Tooltip placement='topLeft' minLength={0} title={capitalize(status || '')}>
                {statusImages[status] || <img width='30px' src={pending} />}{' '}
              </Tooltip>
            </CenteredDiv>
          )
        },
      },
    ]

    return (
      <div className='tableWidth custom-Pagination'>
        <EmployeeLeaveHistoryStyledTable
          ref={tableRef}
          dataUrl={leaveHistoryUrl}
          columns={columns}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={Object.keys(queryParam).length !== 0 && queryParam}
          resetTable={resetTable}
          setResetTable={setResetTable}
          loaderPosition={'center'}
        />
      </div>
    )
  }
)

export default EmployeeLeaveHistoryTable

const EmployeeLeaveHistoryStyledTable = styled(Table)`
  .ant-pagination-item a {
    justify-content: center !important;
  }
  .ant-select .ant-select-selector {
    justify-content: center;
    display: flex;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    justify-content: center;
    text-align: center;
    display: flex;
  }
  .ant-select .ant-select-selection-item {
    text-align: center;
    justify-content: center;
    display: flex !important;
  }
`

const CenteredDiv = styled.div`
  text-align: center;
`
