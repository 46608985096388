import React, { useEffect } from 'react'
import { Col, Form, Radio, Row } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { CommonSelect } from '@commonComponents/select'
import { SearchOutlined } from '@ant-design/icons';
import { SALARY_RANGE } from '@mainPage/E-payroll/constant';
import SearchFilter from '@commonComponents/SearchFilter';
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { useStore } from '@stores/root-store'
import styled from 'styled-components'

const SearchPayroll = observer((props: any) => {

  const {
    designationModal: {
      getDesignationData,
      loadDesignation,
      nextDes,
    },
  } = useStore(null)

  useEffect(() => {
    async function load() {
      if (!getDesignationData?.length) {
        await loadDesignation(1, 20, '', true)
      }
    }
    load()
  }, [])
  return (
    <SearchFilter className='marginCustom' style={{ marginBottom: '-15px' }}
      {...props}
      calledFrom={'payroll_filter'}
      children={
        <div className='flex-grow-1 mb-0'>
          <Row gutter={4}>
            <Col sm={12} xs={24} lg={5}>
              <Iconstyle>
                <Form.Item name='name'>
                  <Commoninput
                    placeholder='Search by Name'
                    icon={<SearchOutlined />}
                  />
                </Form.Item>
              </Iconstyle>
            </Col>
            <Col sm={12} xs={24} lg={5}>
              <>
                <Dynamicselect
                  placeholder='Find by Designation'
                  isForm={true}
                  name={'designation'}
                  getData={getDesignationData}
                  loadData={loadDesignation}
                  clearMyData={true}
                  queryParam={'search'}
                  nextPage={nextDes}
                  valueKey1='title'
                />
              </>
            </Col>
            <Col sm={12} xs={24} lg={5}>
              <Form.Item name='salary_range'>
                <Commonselect
                  placeholder='Select Salary Range'
                  getPopupContainer={trigger => trigger.parentElement}
                  allowClear={true}
                  data={SALARY_RANGE}
                  queryParam={'salary_range'}
                />
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={16} lg={4}>
              <Form.Item name={'increment'}>
                <Radio.Group>
                  <Radiobtn value={'false'}>No Increment</Radiobtn>
                  <Radiobtn value={'true'}>Last Increment</Radiobtn>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
      }
    ></SearchFilter>
  )
})
export default SearchPayroll

const Iconstyle = styled.div`
  .anticon-search {
    position: absolute;
    left: 15px;
    top: 16px;
    color: #525353;
    z-index: 2;
  }
`
const Radiobtn = styled(Radio)`
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color:#525353;
  span.ant-radio+*{
      width:max-content;
  }
`

const Commoninput = styled(CommonInput)`
  padding-left: 35px !important;
  &::placeholder {
    color: rgba(82, 83, 83, 0.5);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    word-spacing: 2px;
  }
`
const Commonselect = styled(CommonSelect)`
.ant-select-selection-placeholder{
  margin-right: 18px;
}
`
const Dynamicselect = styled(DynamicSelect)`
.ant-select-single{
   .ant-select-selector{
     .ant-select-selection-item{
       .ant-select-single{
         .ant-select-selector{
           .ant-select-selection-placeholder{
              margin-right: 18px;
}}}}}}
`