import { cast, flow, types } from 'mobx-state-tree'
import { leadApi } from '@api'
import clientSuccessNoti from '../../utils/notification'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'


export const leads = types.model({
  id: types.maybeNull(types.string),
  leadName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  mobileNumber: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
  joiningDate: types.maybeNull(types.string),
  projectLeader: types.maybeNull(types.array(types.string)),
})
export const leadsList = types
  .model({
    data: types.maybeNull(types.array(leads)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getLeads() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadLeads = flow(function* () {
      try {
        self.loading = true
        self.data = cast([])
        const res = yield leadApi.getLead()
        self.data = res
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Leads')
        }
      } finally {
        self.loading = false
      }
    })

    const updateLead = flow(function* (id, data) {
      try {
        self.loading = true
        yield leadApi.updateLead(id, data)
        clientSuccessNoti('Status Updated Successfully', 'success')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } 
    })

    return {
      loadLeads,
      updateLead,
    }
  })

export function initLead() {
  return leadsList.create({})
}
