import { flow, types, cast } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'
import { assetPermissionApi } from '@api'

export const assetHandling = types.model({
  asset: types.maybeNull(types.number),
  startDate: types.maybeNull(types.number),
  endDate: types.maybeNull(types.number),
  reason: types.maybeNull(types.string),
})

export const employeeassets = types.model({
  name: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
})

export const assetHandlingModal = types
  .model({
    data: types.maybeNull(types.array(assetHandling)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextassetHandle: types.optional(types.number, 1),
    getEmployeeassets: types.maybeNull(types.array(employeeassets)),
  })
  .views(self => ({
    get getassetHandlingData() {
      return toJS(self.data)
    },

    get isLoading() {
      return self.loading
    },

    get getemployeeAssect() {
      return toJS(self.getEmployeeassets)
    },
  }))
  .actions(self => {
    const setAssetPermission = flow(function* (data) {
      try {
        self.loading = true
        yield assetPermissionApi.setassetPermissionData(data)
        notification.success('Asset request submitted successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadassetHandling = flow(function* (
      page = 1,
      pageSize = 20,
      queryParam = '',
      resetList = false,
      saveInStore = true
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextassetHandle = 1
        }
        const res = yield assetPermissionApi.getassetPermission(
          page,
          pageSize,
          queryParam
        )
        if (!res?.isCancel && saveInStore) {
          self.data = cast([...toJS(self.data ?? []), ...res.results])
          self.count = res.count
          if (res.next) {
            self.nextassetHandle = self.nextassetHandle + 1
          } else {
            self.nextassetHandle = 1
          }
        } else if (!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.success('Failed to Load Asset Handle')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadEmployeeAssetsData = flow(function* (
      resetList = false,
      saveInStore = true
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.getEmployeeassets = cast([])
          self.nextassetHandle = 1
        }
        const res = yield assetPermissionApi.getEmployeeAssets()
        if (!res?.isCancel && saveInStore) {
          self.getEmployeeassets = cast([
            ...toJS(self.getEmployeeassets ?? []),
            ...res,
          ])
          self.count = res.count
          if (res.next) {
            self.nextassetHandle = self.nextassetHandle + 1
          } else {
            self.nextassetHandle = 1
          }
        } else if (!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.success('Failed to Load Asset Handle')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeAssetsData = flow(function* (data, id) {
      try {
        self.loading = true
        yield assetPermissionApi.updateEmployeeAsset(data, id)
        notification.success('Asset request updated successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEmployeeAssetsData  = flow(function* (id) {
      try {
        self.loading = true
        yield assetPermissionApi.deleteEmployeeAsset(id)
        notification.success('Asset request deleted successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setAssetPermission,
      loadassetHandling,
      loadEmployeeAssetsData,
      updateEmployeeAssetsData,
      deleteEmployeeAssetsData
    }
  })

export function initassetHandlingModal() {
  return assetHandlingModal.create({})
}
