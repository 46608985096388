import { useStore } from '@stores/root-store'
import { Row, Tag, Form } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomButton from '@commonComponents/button'
import { CommonInput } from '@commonComponents/input'
import { CommonSelect } from '@commonComponents/select'


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const LabelForm = observer((props: any) => {
  const { boardID } = useParams()
  const [form] = Form.useForm()
  const [labelID, setLabelID] = useState(null)
  const [updatedDate, setUpdatedData] = useState({})

  const {
    boardDetails: {
      getDataLabel,
      setBoardListDetails,
      isLoading,
      setLabelDetail,
      loadLabelDetail,
      deleteLabelDetail,
      updateLabelDetail,
    },
  } = useStore(null)

  useEffect(() => {
    async function load() {
      await loadLabelDetail(boardID)
      form.resetFields()
    }
    load()
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [props.isUpdate, getDataLabel, isLoading, props.boardEditVisibility])

  const updateTask = async changeValue => {
    const key = Object.keys(changeValue)[0]
    updatedDate[key] = changeValue[Object.keys(changeValue)[0]]
    setUpdatedData(updatedDate)
  }

  const getLabel = async values => {
    let res = null
    if (!props.isUpdate) {
      if (props.boardVisibility) {
        res = await setBoardListDetails(
          getDataLabel.filter(label => label.id === values.labels)[0]
        )
      } else if (labelID) {
        res = await updateLabelDetail(boardID, labelID, {
          name: values.name,
          color: values.color,
        })
      } else {
        res = await setLabelDetail(values, boardID)
        await loadLabelDetail(boardID)
      }
    }
    if (res.success) {
      props.boardEditVisibility(false)
      form.resetFields()
      setLabelID(null)
      await loadLabelDetail(boardID)
    }
  }

  const deleteTag = async tagId => {
    const res = await deleteLabelDetail(tagId, boardID)
    if (res.success) {
      await loadLabelDetail(boardID)
    }
  }

  const updateLabel = item => {
    form.setFieldsValue({
      name: item.name,
      color: item.color,
    })
    setLabelID(item.id)
  }
  const children = []
  getDataLabel?.map(e => {
    children.push({ key: e.id, value: e.name })
  })

  return (
    <Form
      layout='vertical'
      form={form}
      onValuesChange={updateTask}
      onFinish={getLabel}
    >
      <div>
        {props.isUpdate || props.boardVisibility ? (
          <Form.Item name='labels'>
            <CommonSelect
              placeholder='Please Select Department....'
              getPopupContainer={trigger => trigger.parentNode}
              data={children}
            />
          </Form.Item>
        ) : (
          <div>
            {' '}
            <p>Name</p>
            <Form.Item name={'name'}>
              <CommonInput />
            </Form.Item>{' '}
            <p>Color</p>
            <Form.Item name={'color'}>
              <CommonInput />
            </Form.Item>
            {getDataLabel?.length ? (
              <p>Current Labels</p>
            ) : (
              <p>No Current Labels</p>
            )}
            <Row>
              {getDataLabel && getDataLabel?.length > 0
                ? getDataLabel.map(item => {
                  return (
                    <Tag
                      className='d-flex align-items-center'
                      color={item.color}
                      closable
                      onClose={() => deleteTag(item.id)}
                      onClick={() => updateLabel(item)}
                    >
                      {item.name}
                    </Tag>
                  )
                })
                : ''}
            </Row>
            <br />
          </div>
        )}
      </div>

      <Form.Item>
        <CustomButton
          type='primary'
          htmlType='submit'
          title='Submit'
          loading={isLoading}
        />
      </Form.Item>
    </Form>
  )
})

export default LabelForm
