import { Pagination, Table } from 'antd'
import { itemRender } from '@mainPage/paginationfunction'
import classNames from 'classnames';

export interface tableProps {
  className?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  dataSource: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  columns: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  rowKey: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  total?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  current?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onChange?: (event?: any) => void;
  module?: string;
  showHeader?: boolean;
  rowClassName?: string;
}

const CommonTable = (props: tableProps) => {
  return (
    <>
      <Table
        className={classNames( props.className)}
        rowClassName='tabel table-striped'
        dataSource={props.dataSource}
        columns={props.columns}
        rowKey={props.rowKey}
        pagination={false}
        {...props}
      />
      {props.total ? (
        <Pagination
          total={props.total}
          current={props.current}
          showTotal={(total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} ${props.module}`
          }
          itemRender={itemRender}
          onChange={props.onChange}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default CommonTable
