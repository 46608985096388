import { EmployeeDetailsTypes } from '@api/employee/employee'
import { exportCsv, exportPdf } from '@utils/common-functions'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { cast, flow, types } from 'mobx-state-tree'
import { employeeApi } from '../../api'
import { filterHirerachyData } from 'components/utils/filter_data'

export const Role = types.model({
  title: types.maybeNull(types.string),
  roleId: types.maybeNull(types.string),
})

export const employeeInfo = types.model({
  id: types.maybeNull(types.string),
  userFullName: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  userFirstName: types.maybeNull(types.string),
  userLastName: types.maybeNull(types.string),
  userEmail: types.maybeNull(types.string),
  userContactCumber: types.maybeNull(types.string),
  userRoleId: types.maybeNull(types.string),
  userRoleTitle: types.maybeNull(types.string),
  availability: types.maybeNull(types.string),
  remote: types.maybeNull(types.boolean),
  designationId: types.maybeNull(types.string),
  shiftId: types.maybeNull(types.string),
  shiftTitle: types.maybeNull(types.string),
  shiftStarTime: types.maybeNull(types.string),
  shiftEndTime: types.maybeNull(types.string),
  shiftOffDays: types.maybeNull(types.array(types.string)),
  departmentId: types.maybeNull(types.string),
  departmentTitle: types.maybeNull(types.string),
  supervisorId: types.maybeNull(types.string),
  supervisorFullName: types.maybeNull(types.string),
  avgRating: types.maybeNull(types.number),
  is_lead: types.maybeNull(types.boolean),
  isDeleted: types.maybeNull(types.boolean),
  isActive: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  roles: types.maybeNull(types.array(Role)),
})

export const empHierarchyArray = types.model({
  id: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  designation: types.maybeNull(types.string),
  userContactNumber: types.maybeNull(types.number),
  joiningDate: types.maybeNull(types.string),
  roleId: types.maybeNull(types.number),
  roleName: types.maybeNull(types.string),
  status: types.maybeNull(types.boolean),
  isAdmin: types.maybeNull(types.boolean),
  shiftName: types.maybeNull(types.string),
  shiftTime: types.maybeNull(types.string),
  supervisorId: types.maybeNull(types.string),
  supervisorName: types.maybeNull(types.string),
  employmentCompanyId: types.maybeNull(types.string),
  employmentCompanyName: types.maybeNull(types.string),
  rating: types.maybeNull(types.string),
  hasChildren: types.maybeNull(types.boolean),
  attendance: types.maybeNull(types.string),
  children: types.optional(
    types.array(types.late(() => empHierarchyArray)),
    []
  ),
})

export const employeeWithoutPayrollInfo = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})

export const employeeList = types.model({
  id: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
})

export const supervisorList = types.model({
  employeeId: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
})

export const allFieldValues = types.model({
  title: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isActive: types.optional(types.boolean, true),
})

export const columnFiledsModel = types.model({
  employeeFiled: types.maybeNull(types.array(allFieldValues)),
  assetFields: types.maybeNull(types.array(allFieldValues)),
  clientsFields: types.maybeNull(types.array(allFieldValues)),
})

export const empFields = types.model({
  fields: types.maybeNull(columnFiledsModel),
})

export const LeadRole = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  allowedMethod: types.frozen(),

})

const LeadDesignation = types.model({
  id: types.maybeNull(types.string),
  isDeleted: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  title: types.maybeNull(types.string),
  branchId: types.maybeNull(types.string),
  departmentId: types.maybeNull(types.string),
});

export const LeadShift = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  startTime: types.maybeNull(types.string),
  endTime: types.maybeNull(types.string),
  offDays: types.maybeNull(types.array(types.string)),
  note: types.maybeNull(types.string),
})

export const hierarchyModel = types.model({
  id: types.maybeNull(types.string),
  branchId: types.maybeNull(types.string),
  isDeleted: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  employeeId: types.maybeNull(types.string),
  availability: types.maybeNull(types.string),
  remote: types.maybeNull(types.boolean),
  designationId: types.maybeNull(types.string),
  shiftId: types.maybeNull(types.string),
  supervisorId: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  departmentId: types.maybeNull(types.string),
  isLead: types.maybeNull(types.boolean),
  userEmail: types.maybeNull(types.string),
  userFirstName: types.maybeNull(types.string),
  userLastName: types.maybeNull(types.string),
  userFullName: types.maybeNull(types.string),
  userContactNumber: types.maybeNull(types.string),
  designationTitle: types.maybeNull(types.string),
  supervisorFullName: types.maybeNull(types.string),
  departmentTitle: types.maybeNull(types.string),
  shiftTitle: types.maybeNull(types.string),
  shiftStartTime: types.maybeNull(types.string),
  shiftEndTime: types.maybeNull(types.string),
  shiftOffDays: types.maybeNull(types.array(types.string)),
  userRoleId: types.maybeNull(types.string),
  userRoleTitle: types.maybeNull(types.string),
  avgRating: types.maybeNull(types.number),
  hasChild: types.maybeNull(types.boolean),
  roles: types.maybeNull(types.array(Role)),
});

export const employeeDetails = types
  .model({
    employeeName: types.maybeNull(types.array(employeeInfo)),
    employeeInfo: types.maybeNull(types.array(employeeInfo)),
    employeeList: types.maybeNull(types.array(employeeList)),
    supervisorList: types.maybeNull(types.array(supervisorList)),
    parentHierarchyList: types.maybeNull(types.array(hierarchyModel)),
    employeeListHierarchy: types.maybeNull(types.array(hierarchyModel)),
    employeeWithoutPayroll: types.maybeNull(
      types.array(employeeWithoutPayrollInfo)
    ),
    employeeFields: types.maybeNull(types.array(empFields)),
    loading: types.optional(types.boolean, false),
    countEmp: types.maybeNull(types.number),
    nextEmp: types.optional(types.number, 1),
    erorMessage: types.maybeNull(types.string),
    filter: types.maybeNull(types.string),
    LeadShift: types.maybeNull(types.array(LeadShift)),
    LeadDesignation: types.maybeNull(types.array(LeadDesignation)),
    LeadRole: types.maybeNull(types.array(LeadRole)),
  })
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get getEmployeeData() {
      return toJS(self.employeeInfo)
    },
    get getAllEmployeeData() {
      return toJS(self.employeeInfo)
    },
    get getEmployeeAllList() {
      return toJS(self.employeeList)
    },
    get getsupervisorList() {
      return toJS(self.supervisorList)
    },
    get getHierarchyList() {
      return toJS(self.employeeListHierarchy)
    },
    get getEmployeesWithoutPayroll() {
      return toJS(self.employeeWithoutPayroll)
    },
    get getEmpFields() {
      return toJS(self.employeeFields)
    },
    get isLoading() {
      return self.loading
    },
    get getParentHierarchyList() {
      return toJS(self.parentHierarchyList)
    },
    get getLeadShift() {
      return toJS(self.LeadShift)
    },
    get getLeadDesignation() {
      return toJS(self.LeadDesignation)
    },
    get getLeadRole() {
      return toJS(self.LeadRole)
    }
  }))
  .actions(self => {
    const setEmployeeDetails = flow(function* (data: EmployeeDetailsTypes) {
      try {
        self.loading = true
        yield employeeApi.setEmployeeDetails(data)
        self.loading = true
        notification.success('Employee added successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error.message)
        return { success: false, error: error.message }
      } finally {
        self.loading = false
      }
    })
    const loadEmployeeDetails = flow(function* (
      page = 1,
      pageSize = 20,
      queryParam = '',
      resetList = false,
      saveInStore = true
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.employeeInfo = cast([])
          self.nextEmp = 1
        }
        const res = yield employeeApi.getEmployeeDetails(
          page,
          pageSize,
          queryParam
        )

        if (!res?.isCancel && saveInStore) {
          self.employeeInfo = cast([
            ...toJS(self.employeeInfo ?? []),
            ...res.items,
          ])
          self.countEmp = res.count
          if (res.next) {
            self.nextEmp = self.nextEmp + 1
          } else {
            self.nextEmp = 1
          }
        }
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadAllEmployeeList = flow(function* () {
      self.loading = true
      self.employeeList = cast([])
      try {
        const res = yield employeeApi.getEmployeeList()
        const list = res.map(el => ({ ...el, id: el.employeeId }))
        self.employeeList = cast(list)
        return toJS(res)
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadSupervisorList = flow(function* () {
      self.loading = true
      self.supervisorList = cast([])
      try {
        const res = yield employeeApi.getSupervisorList()
        const list = res.map(el => ({ ...el, id: el.employeeId }))
        self.supervisorList = cast(list)
        return toJS(res)
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadLeadShift = flow(function* () {
      self.loading = true
      self.supervisorList = cast([])
      try {
        const res = yield employeeApi.getLeadShift()
        return self.LeadShift = res
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadLeadDesignation = flow(function* (num, pageSize, queryParam = {}) {
      self.loading = true
      self.supervisorList = cast([])
      try {
        const res = yield employeeApi.getLeadDesignation(num, pageSize, queryParam)
        return self.LeadDesignation = res
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadLeadRole = flow(function* () {
      self.loading = true
      self.supervisorList = cast([])
      try {
        const res = yield employeeApi.getLeadRole()
        return self.LeadRole = res
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadHierarchyList = flow(function* (supervisorId, obj) {
      self.loading = true

      try {
        const res = yield employeeApi.getEmployeeHierarchy(supervisorId);
        const findSetNestedData = (id, parentObj, response) => {
          const findParent = obj => obj.id === id;

          const findAndSetChildren = obj => {
            if (findParent(obj)) {
              const children = response.map(item => ({
                ...item,
                key: item.id,
                children: [],
              }))
              obj.children = children?.sort((child1, child2) => !child1.hasChild && child2.hasChild ? 1 : -1);
            } else if (obj.children) {
              obj.children.forEach(findAndSetChildren);
            }
            return obj
          };

          return findAndSetChildren(parentObj);
        };
        const hierarchyData = findSetNestedData(supervisorId, obj[0], res);

        return { success: true, hierarchyData }
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadHierarchyListParent = flow(function* () {
      self.loading = true
      try {
        const res = yield employeeApi.getEmployeeHierarchyParent()
        if (res) {
          self.parentHierarchyList = res
        }
        return toJS(res.results)
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const downloadEmployeeDetails = flow(function* (queryParam) {
      try {
        self.loading = true
        const response = yield employeeApi.exportEmployeeRecord(queryParam)
        if (queryParam.file_type === 'csv') {
          exportCsv(response)
        } else if (queryParam.file_type === 'pdf') {
          exportPdf(response)
        }
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateEmployeeDetails = flow(function* (
      id,
      data,
      permission
    ) {
      try {
        self.loading = true
        yield employeeApi.updateEmployeeDetails(id, data)
        if (!permission) {
          notification.success('Employee Updated Successfully')
        }
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const allEmployeeList = flow(function* (
      page = 1,
      pageSize = 20,
      queryParam = '',
      resetList = false,
      saveInStore = true
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.employeeInfo = cast([])
          self.nextEmp = 1
        }
        const res = yield employeeApi.getAllEmployeeList(
          page,
          pageSize,
          queryParam
        )
        if (!res?.isCancel && saveInStore) {
          self.employeeInfo = cast([
            ...toJS(self.employeeInfo ?? []),
            ...res.items,
          ])
          self.countEmp = res.count
          if (res.next) {
            self.nextEmp = self.nextEmp + 1
          } else {
            self.nextEmp = 1
          }
        } else if (!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Employee';
        notification.error(message);
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadEmployeesWithoutPayroll = flow(function* (
      // page = 1,
      // pageSize = 20,
      // queryParam = '',
      // resetList = false,
      // saveInStore = true
    ) {
      try {
        self.loading = true
        // if (resetList) {
        //   self.employeeWithoutPayroll = cast([])
        //   self.nextEmp = 1
        // }
        const res = yield employeeApi.getEmployeesWithoutPayroll(
          // page, pageSize, queryParam
        )
        // if (!res?.isCancel && saveInStore) {

        self.employeeWithoutPayroll = res
        self.countEmp = res.count
        if (res.next) {
          self.nextEmp = self.nextEmp + 1
        } else {
          self.nextEmp = 1
        }
        // } else if (!res?.isCancel && !saveInStore) {
        //   return res || []
        // }
      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Employee';
        notification.error(message);
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const errorMsgClear = () => {
      self.erorMessage = null
    }

    const setEmployeeColField = flow(function* (data) {
      try {
        self.loading = true
        yield employeeApi.employeeColField(data)
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadEmployeeColField = flow(function* () {
      try {
        self.loading = true
        self.employeeFields
        const res = yield employeeApi.loademployeeColField()
        self.employeeFields = res.items
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const searchEmployees = flow(function* (querParam) {
      self.loading = true
      self.employeeListHierarchy = cast([])
      try {
        const res = yield employeeApi.searchFilterEmployees(querParam)
        if (res) {
          const employeeList = self.employeeListHierarchy ?? []
          const results = res.results ?? []
          self.employeeListHierarchy = cast([...toJS(employeeList), ...results])
        }
        return toJS(res.results)
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const filteredOutHirerachy = function (data,id){
          const newData = filterHirerachyData(data,id);
          return newData ;
    }

    return {
      setEmployeeDetails,
      loadEmployeeDetails,
      loadEmployeesWithoutPayroll,
      updateEmployeeDetails,
      errorMsgClear,
      loadAllEmployeeList,
      downloadEmployeeDetails,
      allEmployeeList,
      loadHierarchyList,
      loadHierarchyListParent,
      setEmployeeColField,
      loadEmployeeColField,
      searchEmployees,
      loadSupervisorList,
      loadLeadShift,
      loadLeadDesignation,
      loadLeadRole,
      filteredOutHirerachy,
    }
  })

export function initEmployeeDetails() {
  return employeeDetails.create({})
}
