import { notification } from '@utils/notifications'
import { flow, types } from 'mobx-state-tree'
import { teamsApi } from '../../api/index'

export const teams = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
})
export const teamsModal = types
  .model({
    data: types.maybeNull(types.array(teams)),
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getTeamsData() {
      return self.data
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setTeamsDetails = flow(function* (data) {
      try {
        self.loading = true
        return yield teamsApi.setTeamsDetails(data)
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })
    const loadTeamsDetails = flow(function* () {
      try {
        self.loading = true
        const res = yield teamsApi.getTeamsDetails()
        self.data = res
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Team')
        }
      } finally {
        self.loading = false
      }
    })
    const updateTeamsDetails = flow(function* (data, id) {
      try {
        self.loading = true
        const res = yield teamsApi.updateTeamsDetails(data, id)
        return res
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteTeamsDetails = flow(function* (id) {
      try {
        self.loading = true
        const res = yield teamsApi.deleteTeamsDetails(id)
        self.data = res.results
      } finally {
        self.loading = false
      }
    })

    return {
      setTeamsDetails,
      loadTeamsDetails,
      updateTeamsDetails,
      deleteTeamsDetails,
    }
  })

export function initTeamsModal() {
  return teamsModal.create({})
}
