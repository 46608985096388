import { colors } from 'constant/colors';
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const Loader = () => {
  const [loaderColor, setLoaderColor] = useState(null);
  const { TealishBlue } = colors

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem('colorTheme'));
    setLoaderColor(storedTheme ? storedTheme?.bodyColor : TealishBlue);
  })

  return (
    <Container>
      <LoadingContainer loaderColor={loaderColor}>
        <LoadingText>
          <Letter visible>E</Letter>
          <Letter visible>N</Letter>
          <Letter visible>I</Letter>
          <Letter visible>G</Letter>
          <Letter visible>M</Letter>
          <Letter visible>A</Letter>
          <Letter visible>T</Letter>
          <Letter visible>I</Letter>
          <Letter visible>X</Letter>
        </LoadingText>
      </LoadingContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-family: 'Garamond';
`;

const movingLine = keyframes`
  0% {
    opacity: 0;
    width: 0;
  }

  33.3%, 66% {
    opacity: 0.8;
    width: 100%;
  }

  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  max-width: 520px;
  text-align: center;
  color: ${props => (props.collapsed ? '#B5DEFF' : props?.loaderColor)};
  position: relative;
  margin: 0 32px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: ${props => (props.collapsed ? '#B5DEFF' : props?.loaderColor)};
    bottom: 0;
    left: 0;
    border-radius: 10px;
    animation: ${movingLine} 3s infinite ease-in-out;
  }
`;

const LoadingText = styled.div`
  font-size: 5vw;
  line-height: 64px;
  letter-spacing: 10px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-evenly;
`;

const moveLetters = keyframes`
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }

  33.3%, 66% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
`;

const Letter = styled.span`
  animation: ${moveLetters} 3s infinite ease-in-out;
  transform: translatex(0);
  position: relative;
  display: inline-block;
  opacity: ${props => (props.visible ? 1 : 0)};
  text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);

  &:nth-child(1) {
    animation-delay: 0.1s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.3s;
  }
  &:nth-child(4) {
    animation-delay: 0.4s;
  }
  &:nth-child(5) {
    animation-delay: 0.5s;
  }
  &:nth-child(6) {
    animation-delay: 0.6s;
  }
  &:nth-child(7) {
    animation-delay: 0.7s;
  }
  &:nth-child(8) {
    animation-delay: 0.8s;
  }
  &:nth-child(9) {
    animation-delay: 0.9s;
  }
`;

export default Loader;
