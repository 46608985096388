import { flow, types } from 'mobx-state-tree'
import { teamMembersApi } from '../../api/index'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
export const memberRole = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
})
export const members = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
})
export const membersData = types.model({
  id: types.maybeNull(types.string),
  member: types.maybeNull(types.array(members)),
  memberName: types.maybeNull(types.string),
  memberRole: types.maybeNull(memberRole),
  role: types.maybeNull(types.string),
  team: types.maybeNull(types.string),
  teamName: types.maybeNull(types.string),
})
export const teamMembersModal = types
  .model({
    data: types.maybeNull(types.array(membersData)),
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getTeamMembersData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setTeamMembersData = flow(function* (data, id) {
      try {
        self.loading = true
        yield teamMembersApi.setTeamMembersDetails(data, id)
        notification.success('Team Added Successfully')
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })
    const loadTeamMembers = flow(function* () {
      try {
        self.loading = true
        const res = yield teamMembersApi.getTeamMembersDetails()
        self.data = res.data
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Team Members')
        }
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const updateTeamMembers = flow(function* (data, id, teamID) {
      try {
        self.loading = true
        const res = yield teamMembersApi.updateTeamMembersDetails(
          data,
          id,
          teamID
        )
        notification.success('Team Updated Successfully')
        return res
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteTeamMembers = flow(function* (id) {
      try {
        self.loading = true
        const res = yield teamMembersApi.deleteTeamMembersDetails(id)
        self.data = res.results
        notification.success('Team Deleted Successfully')
      } finally {
        self.loading = false
      }
    })

    return {
      setTeamMembersData,
      loadTeamMembers,
      updateTeamMembers,
      deleteTeamMembers,
    }
  })

export function initTeamMembersModal() {
  return teamMembersModal.create({})
}
