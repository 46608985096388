
import { BaseApi } from '../baseApi'
import { leaveCancelGetUrl, leaveCancelApprovalUrl } from '../const/index'

class EmployeeLeaveCancelApprovalApi extends BaseApi {

  getEmployeeLeaveCancelApproval = async num => {
    try {
      const response = await this.axios.get(
        `${leaveCancelGetUrl}?page=${num ? num : 1}`,
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeeLeaveCancelApproval = async (id: string) => {
    try {
      const response = await this.axios.put(`${leaveCancelApprovalUrl}${id}/leave`)
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeLeaveCancelApprovalApi
