import { CommonInput } from '@commonComponents/input'
import { CommonSelect } from '@commonComponents/select'
import { useStore } from '@stores/root-store'
import {
  Col,
  Form,
  Row,
  DatePicker,
  // UploadProps,
  Avatar,
  Upload,
  Image,
  Menu,
  Dropdown,
} from 'antd'
import ImgCrop from 'antd-img-crop'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import countryList from 'react-select-country-list'
import styled from 'styled-components'
import { MdDelete, MdEdit } from 'react-icons/md'
import moment from 'moment'
import { checkUUID, hasPermission, isCurrentUsersId } from '@utils/common-functions'
import { BLOOD_GROUP_LIST, ISO_DATE_FORMAT } from '@mainPage/constant'
import imageFallback from '@assets/img/fallback-image.png'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { permit } from '@utils/permission/permission'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { FaUpload } from 'react-icons/fa'
import { EMPLOYEE_MOD, VIEW_ALL } from 'constant/constant'


interface ProfileData {
  address: string;
  blood_group: string;
  city: string;
  contact_number: string;
  country: string;
  email: string;
  first_name: string;
  joining_date: string | any;
  last_name: string;
  postal_code: string;
  designation: string;
  shift: string;
  profile_picture: string;
}

interface AddEmployee {
  empId?: string;
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddEmployee = observer(
  ({ empId, setRefreshTable }: AddEmployee) => {
    const [form] = Form.useForm()

    const options = useMemo(() => countryList().getData(), [])
    const [fileList, setFileList] = useState([])
    const [image, setImage] = useState('')


    const {
      profileDetails: {
        updateProfileDetails,
        loadProfileDetails,
        profileData,
      },
      designationModal: { getDesignationData, loadDesignation, nextDes },
      shiftsModal: { getShiftData, loadShift, nextShi },
      themesModel: { getColorsData },
      userInfo: { getRoles, getcompany },
    } = useStore(null)
    const isCurrentUser = isCurrentUsersId(empId)
    const hasUpdatePermission = permit(getRoles, 'employee', 'view')
    const isAdmin = permit(getRoles, 'accessView', 'is_admin')
    const isAdminOrLead = isAdmin || getcompany?.isLead;
    const canGetDesignation = hasPermission(getRoles, 'designation', ['get', 'view_all'])
    const canGetShift = hasPermission(getRoles, 'shift', ['get', 'view_all'])
    const canViewALl = permit(getRoles,EMPLOYEE_MOD,VIEW_ALL)

    const canModify = isCurrentUser || hasUpdatePermission ||  isAdminOrLead

    const load = async () => {
      if (!getDesignationData?.length && (isAdminOrLead || canGetDesignation)) {
        await loadDesignation(1, 20, '', true)
      }
      if (!getShiftData?.length && (isAdminOrLead || canGetShift)) {
        await loadShift(1, 20)
      }
      await loadProfileDetails(empId, isCurrentUser, isAdmin,canViewALl)
    }


    const removeImage = async () => {
      if (fileList.length) {
        setImage(profileData?.employeeImage || '')
        setFileList([])
        return
      }
      if (image) {
        const payload = {
          first_name: profileData?.firstName,
          last_name: profileData?.lastName,
          joining_date: profileData?.joiningDate,
          designation: checkUUID(profileData?.designation) ? profileData?.designation : profileData.designationId,
          shift: checkUUID(profileData?.shift) ? profileData?.shift : profileData.shiftId,
          city: profileData?.city,
          address: profileData?.address,
          country: profileData?.country,
          blood_group: profileData?.bloodGroup,
          contact_number: profileData?.contactNumber,
          postal_code: profileData?.postalCode
        };

        const formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        const updateProfileRes = await updateProfileDetails(profileData?.employee, formData)
        if (updateProfileRes?.success) {
          load()
          setImage('')
          setFileList([])
        }
      }
    }

    const onSubmit = async (submittedData: ProfileData) => {
      const { shift, designation, ...rest } = submittedData
      const payLoad = new FormData()
      payLoad.append('data', JSON.stringify({
        ...rest,
        shift: checkUUID(shift) ? shift : profileData.shiftId,
        designation: checkUUID(designation) ? designation : profileData.designationId
      }));
      if (fileList.length > 0 ) {
        payLoad.append('profile_picture', fileList[0])

      }

      const data: any = {};

      payLoad.forEach((value:any, key) => {
        try {
          data[key] = JSON.parse(value);
        } catch (e) {
          data[key] = value;
        }
      });

      data.data.joining_date = submittedData.joining_date?.format('YYYY-MM-DDTHH:mm:ss')
      const res = await updateProfileDetails(profileData?.employee, data?.data)
      if (res?.success) {
        load()
        // setFileList([])
      }
      setRefreshTable?.(true)
    }
    useEffect(() => {
      load()
    }, [])


    useEffect(() => {
      form.setFieldsValue({
        first_name: profileData?.firstName || '',
        email: profileData?.email,
        city: profileData?.city || '',
        last_name: profileData?.lastName || '',
        address: profileData?.address,
        country: profileData?.country,
        postal_code: profileData?.postalCode,
        contact_number: profileData?.contactNumber || '',
        designation: profileData?.designation,
        shift: profileData?.shiftTitle,
        blood_group: profileData?.bloodGroup,
        joining_date: profileData?.joiningDate
          ? moment(profileData?.joiningDate, ISO_DATE_FORMAT)
          : '',
      })

      setImage(profileData?.employeeImage || '')
    }, [profileData])

    const fileProps = {
      onRemove: file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList,);
      },
      beforeUpload: file => {
        const allowedFormats = ['.jpg', '.jpeg', '.png']
        const fileExtension = file.name.slice(
          ((file.name.lastIndexOf('.') - 1)) + 2
        ) // Extract the file extension
        if (allowedFormats.includes('.' + fileExtension.toLowerCase())) {
          file.arrayBuffer().then(arrayBuffer => {
            new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
            // setBlob(blob)
          });

          const reader = new FileReader()
          setFileList([file])
          reader.addEventListener('load', () => {
            setImage(reader.result as string)
          })
          reader.readAsDataURL(file)
        } else {
          // Display an error message or notification to the user
          alert('Only .jpg, .jpeg, and .png file formats are supported.')
        }

        return false
      },
      fileList,
      capture: undefined,
    }

    const ImageOptions = (
      <StyledMenu>
        <Menu.Item key='upload'>
          <ImgCrop>
            <Upload {...fileProps} showUploadList={false}>
              <div className='option'>
                <FaUpload size={18} color={getColorsData?.bodyColor} /> Upload
              </div>
            </Upload>
          </ImgCrop>
        </Menu.Item>
        <Menu.Item key='remove'>
          <div className='option' onClick={removeImage}>
            <MdDelete size={18} color={'red'} /> Remove
          </div>
        </Menu.Item>
      </StyledMenu>
    )

    return (
      <div style={{ marginTop: '30px', paddingLeft: '24px', paddingRight: '24px' }}>
        <Form
          id='add-employee'
          layout='vertical'
          onFinish={onSubmit}
          form={form}
          disabled={!canModify}
        >
          <Labelalign gutter={24}>
            <Col xs={24} lg={12}>
              <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                <AvatarDiv>
                  {image ? (
                    <CircleDiv>
                      <div className='image-cont'>
                        <Image
                          width={140}
                          height={140}
                          className='image'
                          src={image}
                          alt={'image'}
                          fallback={imageFallback}
                          preview={false}
                        />
                      </div>
                    </CircleDiv>
                  ) : (
                    <Avatar size={140} className={'imgTextSize'}>
                      {profileData?.firstName?.[0]?.toUpperCase() || 'U'}
                    </Avatar>
                  )}

                  {/* the edit icon */}
                  <div className='edit-cont'>
                    <Dropdown overlay={ImageOptions} getPopupContainer={triger => triger.parentElement} trigger={['click']}>
                      <div
                        className='shadow-sm d-flex align-items-center p-1 rounded-circle'
                        style={{
                          backgroundColor: getColorsData?.bodyColor || 'blue',
                        }}
                      >
                        <MdEdit className='edit-icon' />
                      </div>
                    </Dropdown>
                  </div>
                </AvatarDiv>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <Row>
                <Col xs={24} lg={24}>
                  <Form.Item
                    name={['first_name']}
                    label='First Name'
                    style={{
                      position: 'relative',
                    }}
                    rules={[validateRequired('First Name'), validateNoWhiteSpace()]}
                  >
                    <CommonInput
                      className='inputField'
                      inputType='text'
                      placeholder='Enter your Last Name...'
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item
                    name={['last_name']}
                    label='Last Name'
                    style={{
                      position: 'relative',
                    }}
                  >
                    <CommonInput
                      className='inputField'
                      inputType='text'
                      placeholder='Enter your Last Name...'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Labelalign>

          <Labelalign gutter={24}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['email']}
                label='E-mail'
              // rules={[
              //   { type: 'email', message: 'Email is invalid' },
              //   validateRequired('Email')
              // ]}
              // validateTrigger={['onBlur']}
              >
                <CommonInput
                  disabled={true}
                  className={`inputField`}
                  inputType='text'
                  placeholder='example@gmail.com'
                  onFocus={() =>
                    form.setFields([{ name: 'email', errors: [] }])
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['country']}
                label='Country'
                style={{
                  position: 'relative',
                }}
                rules={[validateRequired('Country Name')]}
              >
                <CommonSelect
                  listHeight={120}
                  placeholder={'Select your country'}
                  size='large'
                  getPopupContainer={trigger => trigger.parentNode}
                  showSearch
                  options={options}
                />
              </Form.Item>
            </Col>
          </Labelalign>

          <Labelalign gutter={24}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['city']}
                label='City'
                style={{
                  position: 'relative',
                }}
              >
                <CommonInput
                  className='inputField'
                  inputType='text'
                  placeholder='Enter your City...'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['postal_code']}
                label='Postal Code'
                style={{
                  position: 'relative',
                }}
              >
                <CommonInput
                  className='inputField'
                  inputType='text'
                  placeholder='Enter your Postal Code...'
                />
              </Form.Item>
            </Col>
          </Labelalign>

          <Labelalign>
            <Col span={24}>
              <Form.Item
                name='address'
                label='Address'
                style={{
                  position: 'relative',
                }}
              >
                <Inputfield inputType='textarea' />
              </Form.Item>
            </Col>
          </Labelalign>

          <Labelalign gutter={24}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['contact_number']}
                label='Mobile Number'
                style={{
                  position: 'relative',
                }}
                rules={[
                  validateRequired('Mobile Number'),
                  validateNoWhiteSpace()
                ]}
              >
                <PhoneInput
                  country={'pk'}
                  placeholder={'+92 3XX-XXXXXXXX'}
                  buttonStyle={{ borderWidth: '1px', zIndex: 9 }}
                  disabled={!canModify}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <DynamicSelect
                disable={!canModify}
                getData={getDesignationData}
                loadData={loadDesignation}
                nextPage={nextDes}
                valueKey1={'title'}
                isForm={true}
                queryParam={'id'}
                showSearch={true}
                name={'designation'}
                placeholder='Please select Designation'
                formLabel={'Designation'}
                fetchData={true}
                formRules={[validateRequired('Designation')]}
                defaultValue={profileData?.designation}
              />
            </Col>


            <Col xs={24} sm={12}>
              <DynamicSelect
                disable={!canModify}
                getData={getShiftData}
                loadData={loadShift}
                nextPage={nextShi}
                valueKey1={'title'}
                isForm={true}
                queryParam={'id'}
                showSearch={true}
                name={'shift'}
                placeholder='Please select Shift'
                formLabel={'Shift'}
                fetchData={true}
                formRules={[validateRequired('Shift')]}
                defaultValue={profileData?.shift}
              />
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name={['joining_date']}
                label='Joining Date'
                style={{
                  position: 'relative',
                }}
                rules={[validateRequired('Joining Date')]}
              >
                <DatePicker
                  format={ISO_DATE_FORMAT}
                  getPopupContainer={trigger => trigger.parentElement}
                  className='form-control'
                  disabled={!canModify}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item name={['blood_group']} label='Blood Group'>
                <CommonSelect
                  listHeight={140}
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Please Select Blood Group'
                  showIcon={true}
                  data={BLOOD_GROUP_LIST}
                />
              </Form.Item>
            </Col>
          </Labelalign>
        </Form>
      </div>
    )
  }
)

export default AddEmployee

export const StyledMenu = styled(Menu)`
  top: 6px;
  position: relative;
  padding: 10px !important;
  right: 52px;

  .option {
    display: flex;
    gap: 10px;
  }

  &::after {
    content: '';
    position: absolute;
    top: -13px;
    right: -5px;
    transform: translateX(-50%);
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`

const AvatarDiv = styled.div`
  width: 140px;
  height: 140px;
  position: relative;

  .edit-cont {
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .edit-icon {
    width: 22px;
    height: 22px;
    fill: #fff;
  }
`

const CircleDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;

  .image-cont {
    width: 100%;
    height: 100%;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    border-radius: 50%;
  }
  .image {
    object-fit: cover;
    
  }
`

const Labelalign = styled(Row)`
  padding: 0 16px 0 16px !important;

  .ant-input {
    color: #525353 !important;
  }
  .ant-form-item-label {
    padding: 0 3px 3px !important;
  }
`

const Inputfield = styled(CommonInput)`
  .ant-input {
    height: 90px !important;
  }
`
