import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import { getColumns } from '@mainPage/Leads/getColumns'
import { Table } from '@commonComponents/table-v2'
import LeadForm from './leadForm'
import { useStore } from '@stores/root-store'

const Projects = observer(() => {

  const [refreshTable, setRefreshTable] = useState(false)

  const {
    leadsList: { getLeads, loadLeads },
    employeeDetails: { updateEmployeeDetails },
  } = useStore(null)

  useEffect(()=>{
    async function load(){
      await loadLeads()
    }
    load()
  }, [])

  const onDelete = async record=>{
    const dataLead = {
      'id': record.id,
      'is_lead': false
    }
    await updateEmployeeDetails(record.id, dataLead, false)
    setRefreshTable(true)
    await loadLeads()
  }
  const columns = getColumns(onDelete)

  
  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header d-flex align-items-center'>
          <BreadCrumbs
            list={[
              { name: 'Dashboard', link: constRoute.home },
              { name: 'Lead', link: constRoute.leads }
            ]}
            pageTitle={'Lead'}
          >
           
          </BreadCrumbs>
        </div>
        <LeadForm  setRefreshTable={setRefreshTable} />
        {/* /Page Header */}
        <div className='table-responsive'>
          <Table
            columns={columns}
            dataSource={getLeads}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            scroll = {{x: 'auto', y: 500}}
          />
        </div>
      </div>
    </div>
  )
})

export default Projects
