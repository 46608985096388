import { toJS } from 'mobx'
import { flow, types} from 'mobx-state-tree'
import { totalExpenseAmountapi } from '../../api'
import { notification } from '@utils/notifications'
import { exportCsv, exportPdf } from '@utils/common-functions'


export const totalExpenseAmount = types.model({
  totalExpense: types.maybeNull(types.number),
})


export const monthlyExpense = types.model({
  currentMonthExpense: types.maybeNull(types.number),
})


export const totalExpenseAmountModal = types
  .model({
    data: types.maybeNull(totalExpenseAmount),
    monthlyExpenseAmount: types.maybeNull(monthlyExpense),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get getTotalExpenseAmount() {
      return toJS(self.data)
    },
    get getMonthlyExpenseAmount(){
      return toJS(self.monthlyExpenseAmount)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const loadExpenseAmount = flow(function* () {
      try {
        self.loading = true
        const res = yield totalExpenseAmountapi.getTotalExpense()
        self.data = res
        return res;
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Expenses Amount')
        }
      } finally {
        self.loading = false
      }
    })


    const loadMonthlyExpense = flow(function* () {
      try {
        self.loading = true
        const res = yield totalExpenseAmountapi.getMonthlyExpense()
        self.monthlyExpenseAmount = res
        return res;
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Expenses Amount')
        }
      } finally {
        self.loading = false
      }
    })
    const exportExpenseRecord = flow(function* (format) {
      try {
        self.loading = true
        const response = yield totalExpenseAmountapi.exportExpenseRecord(format)
        if(response.includes('PDF')){
          exportPdf(response)
        }else{
          exportCsv(response)
        }         
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })


    return {
      loadExpenseAmount,
      exportExpenseRecord,
      loadMonthlyExpense
    }
  })


export function initExpenseAmountModal() {
  return totalExpenseAmountModal.create({})
}