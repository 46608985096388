import { flow, types ,cast} from 'mobx-state-tree'
import { attendanceStatsApi } from '@api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'


export const attendanceStats = types.model({
  employeeFirstName: types.maybeNull(types.string),
  employeeId:types.maybeNull(types.string),
  employeeLastName:types.maybeNull(types.string),
  punchInTime:types.maybeNull(types.string),
  punchOutTime:types.maybeNull(types.string),
  today:types.maybeNull(types.string),
  totalHours: types.maybeNull(types.number),
  totalMinutes: types.maybeNull(types.number),
})


export const attendancePunchModal = types
  .model({
    data: types.maybeNull(attendanceStats),
  })
  .views(self => ({
    get getAttendanceStatsDataPunch() {
      return toJS(self.data)
    },
  }))
  .actions(self => {
    const loadAttendanceEmployeeData = flow(function* () {
      try {
        const res = yield attendanceStatsApi.getEmployeeAttendancePunchData()
        if (!res?.isCancel){
          const response_results = res.results 
          self.data = cast(response_results[response_results.length - 1])
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Attendance Punch Data.')
        }        return { success: false }
      }
    })

    return {
      loadAttendanceEmployeeData,
    }
  })

export function initAttendancePunchList() {
  return attendancePunchModal.create({
    data: {}
  })
}