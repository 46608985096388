import { Tooltip } from 'antd'
import { NAME, ONBOARDING_MOD, PATCH_METHOD } from 'constant/constant'
import { MdDescription, MdEdit } from 'react-icons/md'
import { CommonSelect } from '@commonComponents/select'
import showColData from '@commonComponents/show-col-data/showColData'
import { ACTION_TITLE } from 'constant/constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import ShowOnPermission from '@commonComponents/permit'
import { humanize } from 'components/utils/string_formate'

export const getCommonColumns = ( setEmpRecord, setApprovedDrawer, onEdit, setViewCv, setApprove) => {

  const selectChange = (value: string) => {
    setApprovedDrawer(true)
    setApprove(value !== 'interview')
  };

  return (

    [
      {
        title: `${NAME}`,
        fixed: 'left' as const,
        dataIndex: 'name',
        render: (_, row) =>
          <Tooltip title={row?.firstName}>
            <div>{row?.firstName}</div>
          </Tooltip>
      },
      {
        title: 'Contact Details',
        dataIndex: 'email',
        render: (text, record) =>
          <Tooltip title={text}>
            <div className='accordingToTheme d-flex flex-column'>
              <div><FontAwesomeIcon className='mr-4' icon={faEnvelope} />{text}</div>
              <div><FontAwesomeIcon className='mr-4' icon={faPhone} /> {`+${record.contactNumber}`}</div>
            </div>
          </Tooltip>
      },
      {
        title: 'Department',
        dataIndex: 'departmentName',
        render: showColData,
      },
      {
        title: 'Designation',
        dataIndex: 'designationName',
        render: showColData,
      },
      {
        title: 'Reffered to Lead',
        dataIndex: 'referredName',
        render: showColData,
      },
      {
        title: 'Employee Status',
        dataIndex: 'isIntern',
        render: (_, row) => (
          <div>{row?.isIntern === true ? 'Intern' : 'Employee'}</div>
        )
      },
      {
        title: 'Status',
        render: (_a: any, row) => (
          <div style={{ width: '150px' }} className='selection'>
            <CommonSelect
              listHeight={140}
              value={humanize(row?.status)}
              getPopupContainer={trigger => trigger.parentNode}
              onChange={value => { selectChange(value); setEmpRecord(row); }}
              showIcon={true}
              options={[
                { value: 'interview', label: 'Interview', disabled: ['approved', 'forward'].includes(row?.status) },
                { value: 'approved', label: 'Approved', disabled: ['approved', 'pending'].includes(row?.status) }
              ]}
            />
          </div>
        )
      },

      {
        title: ACTION_TITLE,
        dataIndex: 'actions',
        render: (_, row) => (
          <div className='d-flex accordingToTheme'>
            <ShowOnPermission mod={ONBOARDING_MOD} method={PATCH_METHOD}>
                <div className=' actionIcons'>
                  <Tooltip title='Edit'>
                    <MdEdit id='onboarding_edit' onClick={() => {setEmpRecord(row), onEdit(row)}}/>
                  </Tooltip>
                </div>
            </ShowOnPermission>
            {row.attachments && (
              <div className='actionIcons'>
                <Tooltip title='View CV'>
                  <MdDescription id='onboarding_descri' onClick={() => { setEmpRecord(row),setViewCv(true)}}/>
                </Tooltip>
              </div>)}
          </div>
        ),
      },
    ]
  )
}
