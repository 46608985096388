import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { constRoute } from '@utils/route'
import Department from '@mainPage/Department/department'
import Designations from '@mainPage/Designation/designation'
import Projects from '@mainPage/Projects/projects'
import TaskBoard from '@mainPage/Projects/taskBoard/taskboard'
import ClientsList from '@mainPage/Client/clients'
import ClientProfile from '@mainPage/Client/clientprofile'
import NewEmployee from '@mainPage/newEmployee/newEmployee'
import Expense from '@mainPage/expenses/expense'
import BoardLists from '@mainPage/Projects/taskBoard/boardlist'
import Board from '@mainPage/Projects/taskBoard/board'
import Leads from '@mainPage/Leads/leads'
import AccountCategories from '@mainPage/categories/category'
import EmployeeLeave from '@mainPage/EmployeeLeave/employeeLeave'
import RemoteLeave from '@mainPage/EmployeeLeave/remoteLeave'
import Assets from '@mainPage/Asset/assets'
import RolePermisson from '@mainPage/rolePermission/rolespermission'
import ForgotPassword from '@initialPage/forgotpassword'
import Payroll from '@mainPage/E-payroll/payroll'
import Ecanteen from '@mainPage/Ecanteen/eCanteen'
import PayrollSlip from '@mainPage/E-payroll/PayrollSlip'
import NewPage from '@mainPage/newpage/newpage'
import Settings from '@mainPage/settings/settings'
import Dashboard from '@mainPage/Dashboard/dashboard'
import Grade from '@mainPage/grade/grade'
import Section from '@mainPage/Section/section'
import Branch from '@mainPage/Branch/branch'
import ResetPassword from '@initialPage/reset_password'
import Shift from '@mainPage/Shift/shift'
import Resignation from '@mainPage/userResignation/resignation'
import Loan from '@mainPage/Loan/loan'
import ClearanceTable from '@mainPage/Clearance/clearanceTable'
import Terminationtable from '@mainPage/Temination/terminationTable'
import AttendecneNewDesign from '@mainPage/attendence/attendanceMainView'
import Allowance from '@mainPage/Allowence/allowence'
import Onboarding from '@mainPage/Onboarding/onboarding'
import AssetHandling from '@mainPage/AssetHandling/AssetHandling'
import Reports from '@mainPage/Roports/Reports'
import Feedback from '@mainPage/Feedback/Feedback'
import ErrorPage from '@mainPage/404Error/errorPage'


const Routing = () => {
  const location = useLocation()

  useEffect(() => {
    const splitedArr = location.pathname.split('/')
    const titles = splitedArr.pop()
    let doubleWords = titles.charAt(0).toUpperCase() + titles.slice(1)

    if (doubleWords.includes('-') || doubleWords.includes('&')) {
      const splitChar = doubleWords.includes('-') ? '-' : '&'
      const split = doubleWords.split(splitChar)
      const secondWord = split[1].charAt(0).toUpperCase() + split[1].slice(1)
      doubleWords =
        splitChar === '&'
          ? split[0] + ' ' + '&' + ' ' + secondWord
          : split[0] + ' ' + secondWord
    }

    const pageTitles = {
      '/': 'Enigmatix OMS',
      newpage: 'Employee Profile',
      'payroll-slip': 'Payroll Slip',
    }

    if (splitedArr.includes('payroll-slip')) {
      document.title = pageTitles['payroll-slip'] || doubleWords
    } else if (splitedArr.includes('newpage')) {
      document.title = pageTitles['newpage'] || doubleWords
    } else if (location.pathname.includes('project-dashboard')) {
      document.title = 'Projects'
    } else {
      document.title = pageTitles[location.pathname] || doubleWords
    }
  }, [location.pathname])

  return (
    <Routes>
      <Route path={`${constRoute.home}`} element={<Dashboard />} />
      {/* Employee  */}
      <Route
        path={`${constRoute.newEmployee}`}
        element={<NewEmployee />}
      ></Route>
      <Route path={`${constRoute.departments}`} element={<Department />} />
      <Route path={`${constRoute.designations}`} element={<Designations />} />
      <Route path={`${constRoute.client}`} element={<ClientsList />} />
      <Route path={`${constRoute.clientProfile}`} element={<ClientProfile />} />
      <Route path={`${constRoute.assetHandling}`} element={<AssetHandling />} />
      <Route path={constRoute.feedback} element={<Feedback />} />
      <Route path={`${constRoute.reports}`} element={<Reports />} />
      {/* Projects  */}
      <Route path={`${constRoute.projects}`} element={<Projects />} />
      <Route
        path={`${constRoute.boardList}/:projectID`}
        element={<BoardLists />}
      />
      <Route
        path={`${constRoute.taskBoard}/:boardID/:projectID`}
        element={<TaskBoard />}
      />
      <Route path={`${constRoute.shift}`} element={<Shift />} />
      <Route path={`${constRoute.expenses}`} element={<Expense />} />
      <Route path={`${constRoute.clearance}`} element={<ClearanceTable />} />
      <Route
        path={`${constRoute.termination}`}
        element={<Terminationtable />}
      />
      <Route
        path={`${constRoute.accountCategories}`}
        element={<AccountCategories />}
      />
      <Route path={`${constRoute.payroll}`} element={<Payroll />} />
      <Route path={`${constRoute.allowance}`} element={<Allowance />} />
      <Route path={`${constRoute.payrollSlip}/:id`} element={<PayrollSlip />} />
      <Route
        path={`${constRoute.rolePermission}`}
        element={<RolePermisson />}
      />
      <Route path={`${constRoute.newPage}/:id`} element={<NewPage />} />
      <Route path={`${constRoute.resignation}`} element={<Resignation />} /> :
      <Route path={constRoute.loan} element={<Loan />} />
      <Route
        path={`${constRoute.projectView}/:projectID`}
        element={<Board />}
      />
      <Route path={`${constRoute.eCanteen}`} element={<Ecanteen />} />
      <Route path={`${constRoute.leads}`} element={<Leads />} />
      <Route path={`${constRoute.reset}`} element={<ResetPassword />} />
      <Route path={`${constRoute.leaveEmployee}`} element={<EmployeeLeave />} />
      <Route path={`${constRoute.remoteEmployee}`} element={<RemoteLeave />} />
      <Route path={`${constRoute.assets}`} element={<Assets />} />
      <Route path={`${constRoute.grade}`} element={<Grade />} />
      <Route path={`${constRoute.onBoarding}`} element={<Onboarding />} />
      <Route path={`${constRoute.settings}`} element={<Settings />} />
      <Route path={`${constRoute.section}`} element={<Section />} />
      <Route path={`${constRoute.branch}`} element={<Branch />} />
      <Route
        path={`${constRoute.attendance}`}
        element={<AttendecneNewDesign />}
      />
      <Route
        path={`${constRoute.forgotPassword}`}
        element={<ForgotPassword />}
      />
      <Route path={`${constRoute.employeeDashbaord}`} element={<Dashboard />} />
      <Route path={'*'} element={<ErrorPage />} />
    </Routes>
  )
}
export default Routing
