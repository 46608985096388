import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Col, Form, Row } from 'antd'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { isCurrentUsersId } from '@utils/common-functions'
import CustomButton from '@commonComponents/button'
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader'
import { MdAddCircleOutline, MdClose } from 'react-icons/md';
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { colors } from 'constant/colors'

const { TealishBlue, lightGray } = colors


interface BankData {
  id?: string;
  title: string;
  iban: string;
  number: string;
}

interface Bankcard {
  empId?: string;
  closeDrawer: () => void;
}

interface Bankform {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: any;
  closeDrawer: () => void;
  data?: BankData;
  method: string;
  formId?: string;
  empId: string;
}

const Bankcard = observer(({ empId, closeDrawer }: Bankcard) => {
  const [showForm, setShowForm] = useState(false)
  const getRef = useRef(null)
  const canModify = isCurrentUsersId(empId)

  const {
    bankDetails: {
      loadBankDetails,
      bankData,
      updateBankDetails,
      deleteBankDetails,
      setBankDetail,
      isLoading,
    },
    themesModel: { getColorsData },
  } = useStore(null)
  const isDarkTheme = getColorsData?.theme === 'dark'

  const load = async () => {
    await loadBankDetails(empId)
  }

  const addForm = () => {
    setShowForm(true)
    setTimeout(() => {
      getRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      })
    }, 500)
  }

  useEffect(() => {
    if (!bankData?.length) {
      load()
    }
  }, [empId])

  useEffect(() => {
    if (!bankData?.length && !showForm) {
      addForm()
    }
  }, [bankData, showForm])

  const onSubmit = async (data: BankData, id: string) => {
    const res = await updateBankDetails(id, data)
    if (res?.success) {
      load()
    }
  }

  const onCreate = async (data: BankData) => {
    data['employee_id'] = empId
    const res = await setBankDetail(data)
    if (res?.success) {
      // remove the local form after the bank details has been created and refetch the data
      setShowForm(false)
      load()
    }
  }

  const onDelete = async (id: string) => {
    const res = await deleteBankDetails(id)
    if (res?.success) {
      load()
    }
  }

  if (!bankData?.length && isLoading) {
    return <SkeletonRingLoader width='100%' height='50px' align='center' />
  }

  return (
    <div ref={getRef}>
      <Wrapper isDarkTheme={isDarkTheme}>
        <div className={'addMore'}>
          <AddButton onClick={addForm} isDarkTheme={isDarkTheme} disabled={!canModify}>
            <MdAddCircleOutline fill={getColorsData?.bodyColor || 'blue'} className='add-icon' />
          </AddButton>
        </div>
        {/* Mapping over fetched bank details to render a form for each one to update it */}
        {bankData?.map((data, index) => (
          <Fragment key={data.id}>
            <div className='d-flex align-items-center mb-1 mt-3 details' style={{gap: '16px'}}>
              <h5 className='titleName mt-2' style={{whiteSpace: 'nowrap'}}>Bank Detail {index + 1}</h5>
              <div className='separator' />
              <button
                type='button'
                className='deleteBtn d-flex align-items-center'
                onClick={() => onDelete(data.id)}
                disabled={!canModify}
              >
                <MdClose className='deleteIcon' />
              </button>
            </div>
            <BankForm
              data={data}
              onSubmit={formData => onSubmit(formData, data.id)}
              method='Update'
              closeDrawer={closeDrawer}
              empId={empId}
            />
          </Fragment>
        ))}

        {showForm && (
          <>
            <div className='d-flex align-items-center mb-1 mt-3 details' style={{gap: '16px'}}>
              <h5 className='titleName mt-2' style={{whiteSpace: 'nowrap'}}>
                Bank Detail {(bankData?.length || 0) + 1}
              </h5>
              <div className='separator' />
              <button
                type='button'
                className='deleteBtn d-flex align-items-center'
                onClick={() => setShowForm(false)}
                disabled={!canModify}
              >
                <MdClose className='deleteIcon' />
              </button>
            </div>
            <BankForm
              formId='add-bank-detail'
              onSubmit={onCreate}
              method='Add'
              closeDrawer={closeDrawer}
              empId={empId}
            />
          </>
        )}
      </Wrapper>
    </div>
  )
})

export default Bankcard

const BankForm = observer(({ data, onSubmit, method, formId, closeDrawer, empId }: Bankform) => {
  const [form] = Form.useForm()
  const {
    themesModel: { getColorsData },
  } = useStore(null)
  const buttonColor = getColorsData?.bodyColor
  const canModify = isCurrentUsersId(empId)

  const setInitialFields = () => {
    form.setFieldsValue({
      title: data?.title || '',
      number: data?.number || '',
      iban: data?.iban || '',
    })
  }

  useEffect(() => {
    setInitialFields()
  }, [data])

  return (
    <Form
      layout='vertical'
      id={data?.id || formId}
      onFinish={onSubmit}
      form={form}
      disabled={!canModify}
    >
      <Labelalign gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['title']}
            label='Account Title'
            rules={[validateRequired('Account Title'), validateNoWhiteSpace()]}
            style={{
              position: 'relative',
            }}
          >
            <CommonInput className='inputField' inputType='text' />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
  <Form.Item
    name={['number']}
    label='Account Number'
    
    style={{
      position: 'relative',
    }}
  >
    <CommonInput className='inputField' inputType='text' maxlength={16} />
  </Form.Item>
</Col>


      </Labelalign>
      <Labelalign gutter={24}>
      <Col xs={24} sm={12}>
  <Form.Item
    name={['iban']}
    label='IBAN Number'
   
    style={{
      position: 'relative',
    }}
  >
    <CommonInput
      className='inputField'
      inputType='text'
      maxlength={34}
    />
  </Form.Item>
</Col>


      </Labelalign>
      <Aligncontent>
        <Styledbutton
          title='Cancel'
          htmlType='button'
          size='small'
          type='primary'
          onClick={closeDrawer}
          style={{ backgroundColor: buttonColor }}
        />
        <Styledbutton
          form={data?.id || formId}
          title={`${method} Detail`}
          htmlType='submit'
          size='small'
          key='submit'
          type='primary'
          style={{ backgroundColor: buttonColor }}
        />
      </Aligncontent>
    </Form>
  )
})

const AddButton = styled.button<{isDarkTheme?: boolean}>`
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props?.isDarkTheme ? '#000' : ''};

  .add-icon {
    width: 20px;
    height: 20px;
  }
`

const Labelalign = styled(Row)`
  padding: 0 16px 0 16px !important;
  .ant-form-item-label {
    padding: 0 3px 3px !important;
  }
`

const Aligncontent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
`

const Styledbutton = styled(CustomButton)`
  background-color: blue;
  border-radius: 5px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  min-width: 100px;
  font-weight: 500 !important;
  padding: 16px;

  &:hover {
    color: white !important;
    background-color: blue;
  }

  &:focus {
    background-color: blue;
  }
`

const Wrapper = styled.div<{ isDarkTheme?: boolean }>`
  padding: 12px 0;
  .addMore {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin: -23px 0 20px 0;
    z-index: 12;
    position: relative;
    img {
      cursor: pointer;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: ${TealishBlue};
      word-spacing: 0px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
  .separator {
    height: .5px;
    flex-grow: 1;
    background-color: ${props => props?.isDarkTheme ? '#fff' : '#1f1f1f'};
  }
  .details {
    padding: 0 16px 0 16px;
  }
  .titleName {
    color: ${props => props?.isDarkTheme ? '#fff' : '#000'};
    white-space: nowrap;
  }
  .deleteBtn {
    outline: none;
    border: none;
    background-color: ${props => props?.isDarkTheme ? '#000' : lightGray};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;

    :hover {
      background-color: #fa9494;
    }
  }

  .deleteIcon {
    width: 18px;
    height: 18px;
    fill: #f42c2c;
  }
`
