import React from 'react'
import error from '../../../assets/img/error404.svg'
import styled from 'styled-components';

function ErrorPage() {
  return (
    <FlexWrapper>
    <ImgWrapper>
        <img src={error} alt='img not found' />
    </ImgWrapper>
    </FlexWrapper>
   
  )
}

export default ErrorPage

const FlexWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
align-self: center;
`
const ImgWrapper = styled.div`
padding-top:100px;
display: flex;
justify-content: center;
align-items: center;
img{
    width:50%;
}
` 