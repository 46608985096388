const ClientAllTask = () => {
  return (
    <div className='tab-content'>
      <div className='tab-pane show active' id='all_tasks'>
        <div className='task-wrapper'>
          <div className='task-list-container'>
            <div className='task-list-body'>
              <ul id='task-list'>
                <li className='task'>
                  <div className='task-container'>
                    <span className='task-action-btn task-check'>
                      <span
                        className='action-circle large complete-btn'
                        title='Mark Complete'
                      >
                        <i className='material-icons'>check</i>
                      </span>
                    </span>
                    <span
                      className='task-label'
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                    >
                      Patient appointment booking
                    </span>
                    <span className='task-action-btn task-btn-right'>
                      <span className='action-circle large' title='Assign'>
                        <i className='material-icons'>person_add</i>
                      </span>
                      <span
                        className='action-circle large delete-btn'
                        title='Delete Task'
                      >
                        <i className='material-icons'>delete</i>
                      </span>
                    </span>
                  </div>
                </li>
                <li className='task'>
                  <div className='task-container'>
                    <span className='task-action-btn task-check'>
                      <span
                        className='action-circle large complete-btn'
                        title='Mark Complete'
                      >
                        <i className='material-icons'>check</i>
                      </span>
                    </span>
                    <span
                      className='task-label'
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                    >
                      Appointment booking with payment gateway
                    </span>
                    <span className='task-action-btn task-btn-right'>
                      <span className='action-circle large' title='Assign'>
                        <i className='material-icons'>person_add</i>
                      </span>
                      <span
                        className='action-circle large delete-btn'
                        title='Delete Task'
                      >
                        <i className='material-icons'>delete</i>
                      </span>
                    </span>
                  </div>
                </li>
                <li className='completed task'>
                  <div className='task-container'>
                    <span className='task-action-btn task-check'>
                      <span
                        className='action-circle large complete-btn'
                        title='Mark Complete'
                      >
                        <i className='material-icons'>check</i>
                      </span>
                    </span>
                    <span className='task-label'>Doctor available module</span>
                    <span className='task-action-btn task-btn-right'>
                      <span className='action-circle large' title='Assign'>
                        <i className='material-icons'>person_add</i>
                      </span>
                      <span
                        className='action-circle large delete-btn'
                        title='Delete Task'
                      >
                        <i className='material-icons'>delete</i>
                      </span>
                    </span>
                  </div>
                </li>
                <li className='task'>
                  <div className='task-container'>
                    <span className='task-action-btn task-check'>
                      <span
                        className='action-circle large complete-btn'
                        title='Mark Complete'
                      >
                        <i className='material-icons'>check</i>
                      </span>
                    </span>
                    <span
                      className='task-label'
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                    >
                      Patient and Doctor video conferencing
                    </span>
                    <span className='task-action-btn task-btn-right'>
                      <span className='action-circle large' title='Assign'>
                        <i className='material-icons'>person_add</i>
                      </span>
                      <span
                        className='action-circle large delete-btn'
                        title='Delete Task'
                      >
                        <i className='material-icons'>delete</i>
                      </span>
                    </span>
                  </div>
                </li>
                <li className='task'>
                  <div className='task-container'>
                    <span className='task-action-btn task-check'>
                      <span
                        className='action-circle large complete-btn'
                        title='Mark Complete'
                      >
                        <i className='material-icons'>check</i>
                      </span>
                    </span>
                    <span
                      className='task-label'
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                    >
                      Private chat module
                    </span>
                    <span className='task-action-btn task-btn-right'>
                      <span className='action-circle large' title='Assign'>
                        <i className='material-icons'>person_add</i>
                      </span>
                      <span
                        className='action-circle large delete-btn'
                        title='Delete Task'
                      >
                        <i className='material-icons'>delete</i>
                      </span>
                    </span>
                  </div>
                </li>
                <li className='task'>
                  <div className='task-container'>
                    <span className='task-action-btn task-check'>
                      <span
                        className='action-circle large complete-btn'
                        title='Mark Complete'
                      >
                        <i className='material-icons'>check</i>
                      </span>
                    </span>
                    <span
                      className='task-label'
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                    >
                      Patient Profile add
                    </span>
                    <span className='task-action-btn task-btn-right'>
                      <span className='action-circle large' title='Assign'>
                        <i className='material-icons'>person_add</i>
                      </span>
                      <span
                        className='action-circle large delete-btn'
                        title='Delete Task'
                      >
                        <i className='material-icons'>delete</i>
                      </span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className='task-list-footer'>
              <div className='new-task-wrapper'>
                <textarea
                  id='new-task'
                  placeholder='Enter new task here. . .'
                  defaultValue={''}
                />
                <span className='error-message hidden'>
                  You need to enter a task first
                </span>
                <span className='add-new-task-btn btn' id='add-task'>
                  Add Task
                </span>
                <span className='btn' id='close-task-panel'>
                  Close
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientAllTask
