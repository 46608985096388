import { Tooltip } from 'antd'
import showColData from '@commonComponents/show-col-data/showColData'
import { STATUS, STATUS_TITLE, REASON_TITLE, REASON, RESIGNATION_MOD, PATCH_METHOD, DELETE_METHOD } from 'constant/constant'
import styled from 'styled-components'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useStore } from '@stores/root-store'
import employeeName from '@utils/employee_name'
import { permit } from '@utils/permission/permission'
import { StyleStatusImage, StyleStatusMain } from '@mainPage/Asset/assets'
import { StatusButtons, convertToUppercase, statusIcons, toCapitalizedWords } from '@utils/common-functions'
import ShowOnPermission from '@commonComponents/permit'
import { notification } from '@utils/notifications'

export const getColumns = (onEdit, setRefreshTable, setCurrentData, onDelete, setIsResignationDisable,getResignationData) => {

  const { resignationModel: { updateResignation },
    userInfo: { getRoles, data: userData } }
    = useStore(null)

  const isCurrentUser = (applicantId: string) => {
    return applicantId === userData?.id;
  };

  const showActionColumn = getResignationData?.some(item=>item?.status === 'pending')
  

  const setStatus = async (record, value) => {
    if (record?.isCleared || value === 'rejected') {
      setCurrentData({ status: value })
      const val = { status: value.toLowerCase() }
      const response = await updateResignation(val, record.id, record?.employeeId)
      if (response?.success) {
        setRefreshTable(true)
      }
    } else {
      return notification.info('Clearance is Pending')
    }
  }

  const isAdmin = permit(getRoles, 'accessView', 'is_admin')
  const canEdit = permit(getRoles, RESIGNATION_MOD, PATCH_METHOD)
  const canDelete = permit(getRoles,RESIGNATION_MOD,DELETE_METHOD)

  return (
    [
      ...([employeeName(()=>null, ()=>null, 'resignation')]),
      {
        title: 'Designation',
        dataIndex: 'designation',
        render: showColData,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        render: showColData,
      },
      {
        title: 'Applied Date',
        dataIndex: 'appliedDate',
        render: showColData,
      },
      {
        title: 'Resign Date',
        dataIndex: 'resignationDate',
        render: showColData,
      },
      {
        title: 'Notice Period',
        dataIndex: 'noticePeriod',
        render: (text, record) => {
          record?.status?.includes('pending') || record?.status?.includes('forward') && setIsResignationDisable(true)

          return `${record?.noticePeriod} Days`;
        },
      },
      {
        title: REASON_TITLE,
        dataIndex: REASON,
        render: (_, row) => (
          <ReasonWrapper>
            {row?.reason?.length > 10 ?
              (<Ellipsis>
                <Tooltip placement='top' title={row?.reason}>
                  {row?.reason?.slice(0, 50)}
                </Tooltip>
              </Ellipsis>) : (<Ellipsis>{row?.reason}</Ellipsis>)
            }
          </ReasonWrapper>
        ),
      },
      {
        title: STATUS_TITLE,
        dataIndex: STATUS,
        render: (text, record) =>
          <>
            {!canEdit || text === 'approved' || text === 'rejected' ?
              <div className={`${text}Icon`}>{statusIcons[text]}<div>{convertToUppercase(text)}</div></div> :
              <StyleStatusMain>
                <Tooltip title={record.status === 'pending' ? 'Clearance is Pending' : ''}>
                  <p className={`${text}`}>{convertToUppercase(text)}</p>
                </Tooltip>
                <StyleStatusImage>
                  {!isCurrentUser(record?.employeeId) && Object.entries(StatusButtons).map(([status, image]) => (
                    <Tooltip key={status} title={toCapitalizedWords(status)}>
                      <img src={image} alt={status} onClick={() => setStatus(record, status)} />
                    </Tooltip>
                  ))}
                </StyleStatusImage>
              </StyleStatusMain>
            }
          </>
      },

      (canEdit || canDelete ) && showActionColumn && !isAdmin ?  {
        title: <ActionCenter>Action</ActionCenter>,
        render: (_, row) => {
          const isPending = row.status === 'pending';
          const currentUserIsApplicant = isCurrentUser(row.employeeId);

          return (
            <CenterDive className='accordingToTheme'>
                {(isPending && currentUserIsApplicant) &&
                 <>
                    <ShowOnPermission mod={RESIGNATION_MOD} method={PATCH_METHOD}>
                      <div className='actionIcons'>
                        <Tooltip title={'Edit'}>
                          <MdEdit id='resignation_edit' onClick={() => onEdit(row)} />
                        </Tooltip>
                      </div>
                    </ShowOnPermission>
                    <ShowOnPermission mod={RESIGNATION_MOD} method={DELETE_METHOD}>
                      <div className='actionIcons'>
                        <Tooltip title={'Delete'}>
                          <MdDelete id='resignation_delete' onClick={() => onDelete(row)} />
                        </Tooltip>
                      </div>
                    </ShowOnPermission>
                  </>
              }
            </CenterDive>
          );
        },
      } : null ,

    ].filter(Boolean)
  )
}


const ReasonWrapper = styled.div`
display: flex;
align-items: center;
position: relative;
width: 100%;
`
const Ellipsis = styled.div`
overflow: hidden;
text-overflow: ellipsis;
width: 100%;
position: absolute;
`
const ActionCenter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;`
const CenterDive = styled.div`
display:flex;
justify-content:center;
align-items:center;
`
