import moment from 'moment'
import { MdAddCircleOutline } from 'react-icons/md'
import IncrementAndBonusInput from '@mainPage/E-payroll/IncrementAndBonusInput'
import { Tooltip } from 'antd'
import employeeName from '@utils/employee_name'
import { GET_METHOD, PATCH_METHOD, PAYROLL_MOD } from 'constant/constant'
import ShowOnPermission from '@commonComponents/permit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faHistory, faPrint } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { TealishBlue } = colors


export const setBg = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16)
  const rendomColor = '#' + randomColor
  return rendomColor
}

export const numInWords = number => {
  const first = [
    '',
    'One ',
    'Two ',
    'Three ',
    'Four ',
    'Five ',
    'Six ',
    'Seven ',
    'Eight ',
    'Nine ',
    'Ten ',
    'Eleven ',
    'Twelve ',
    'Thirteen ',
    'Fourteen ',
    'Fifteen ',
    'Sixteen ',
    'Seventeen ',
    'Eighteen ',
    'Nineteen ',
  ]
  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ]
  const mad = ['', 'Thousand', 'Million', 'Billion', 'Trillion']
  let word = ''

  for (let i = 0; i < mad.length; i++) {
    let tempNumber = number % (100 * Math.pow(1000, i))
    if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
      if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
        word =
          first[Math.floor(tempNumber / Math.pow(1000, i))] +
          mad[i] +
          ' ' +
          word
      } else {
        word =
          tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
          '-' +
          first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
          mad[i] +
          ' ' +
          word
      }
    }

    tempNumber = number % Math.pow(1000, i + 1)
    if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
      word =
        first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
        'Hunderd ' +
        word
  }
  return word
}

export const getSalaryMonth = salaryMonth => {
  if (salaryMonth) {
    return moment(salaryMonth).format('MMMM YYYY') || '-'
  } else {
    return 'No Date'
  }
}

export const getColumns = ({
  assetHistoryPAth,
  payRollHistoryModal,
  selectedType,
  setSelectedType,
  selectedRecord,
  setSelectedRecord,
  increasedVal,
  setIncreasedVal,
  recommendedBy,
  setRecommendedBy,
  submitIncrementOrBonus,
  resetValues,
  editPayroll,
}) => {
  return [
    employeeName(false, false, 'payroll'),
    {
      title: 'Basic Salary',
      dataIndex: 'basicSalary',
      render: (_, row) => {
        return (
          <p>
            <Tooltip
              placement='topLeft'
              title={
                row.basicRecommendedBy &&
                `Recommended By: ${row.basicRecommendedBy}`
              }
              color={TealishBlue}
            >
              {row?.basicSalary || '-'}
            </Tooltip>
          </p>
        )
      },
    },
    {
      title: 'Last Increment',
      dataIndex: 'lastIncrement',
      render: (_, row) => {
        const increment = row?.lastIncrement || '-'
        return (
          <>
            <IncrementAndBonusInput
              type={'increment'}
              employeeId={row.employee}
              employeeName={row.employeeName}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedRecord={selectedRecord}
              setSelectedRecord={setSelectedRecord}
              displayValue={increment}
              inputValue={increasedVal}
              setInputValue={setIncreasedVal}
              lastRecommendedBy={row.incrementRecommendedBy}
              recommendedBy={recommendedBy}
              setRecommendedBy={setRecommendedBy}
              resetValues={resetValues}
              rowRecord={row}
              onSubmit={() => submitIncrementOrBonus(row.employee)}
            />
            <div>{row.incrementedDate}</div>
          </>
        )
      },
    },
    {
      title: 'Bonus',
      dataIndex: 'bonus',
      render: (_, row) => {
        const bonus = row?.bonus || '-'
        if (bonus)
          return (
            <>
              <IncrementAndBonusInput
                type={'bonus'}
                employeeId={row.employee}
                employeeName={row.employeeName}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                selectedRecord={selectedRecord}
                setSelectedRecord={setSelectedRecord}
                displayValue={bonus}
                inputValue={increasedVal}
                setInputValue={setIncreasedVal}
                lastRecommendedBy={row.bonusRecommendedBy}
                recommendedBy={recommendedBy}
                setRecommendedBy={setRecommendedBy}
                rowRecord={row}
                resetValues={resetValues}
                onSubmit={() => submitIncrementOrBonus(row.employee)}
              />
              <div>{row.bonusDate}</div>
            </>
          )
        return (
          <>
            <MdAddCircle className={`d-flex align-items-center gap-2`}>
              <MdAddCircleOutline />
              <div>Add</div>
            </MdAddCircle>
          </>
        )
      },
    },
    {
      title: 'Total Allowance',
      dataIndex: 'total_earning',
      render: (_, row) => {
        return <p>{row.netAllowance || '-'}</p>
      },
    },
    {
      title: 'Total Deduction',
      dataIndex: 'total_deduction',
      render: (_, row) => {
        return <p>{row.netDeduction || '-'}</p>
      },
    },
    {
      title: 'Net Salary',
      dataIndex: 'net_salary',
      render: (_, row) => {
        return <p>{row?.netSalary || '-'}</p>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'action',
      render: (_, row) => {
        return (
          <IconsBox>
            <ShowOnPermission mod={PAYROLL_MOD} method={GET_METHOD}>
              <Icons
                className='editAction'
                onClick={() => assetHistoryPAth(row)}
              >
                <Tooltip placement='top' title='Print' color={TealishBlue}>
                  <FontAwesomeIcon icon={faPrint} />
                </Tooltip>
              </Icons>
              <Icons
                className='editAction'
                onClick={() => {
                  payRollHistoryModal(row)
                }}
              >
                <Tooltip placement='top' title='History' color={TealishBlue}>
                  <FontAwesomeIcon icon={faHistory} />
                </Tooltip>
              </Icons>
            </ShowOnPermission>
            <ShowOnPermission mod={PAYROLL_MOD} method={PATCH_METHOD}>
              <Icons className='editPayroll' onClick={() => editPayroll(row)}>
                <Tooltip placement='top' title='Edit' color={TealishBlue}>
                  <FontAwesomeIcon icon={faPen} />
                </Tooltip>
              </Icons>
            </ShowOnPermission>
          </IconsBox>
        )
      },
    },
  ]
}

const MdAddCircle = styled.div`
  cursor: pointer;

  svg {
    color: ${TealishBlue};
  }
`
const IconsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const Icons = styled.div`
  border-radius: 32px;
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  padding: 8px;

  &:hover {
    background-color: rgb(218, 216, 216);
    cursor: pointer;
  }
`
