import { Dropdown, Menu } from 'antd'
import React from 'react'
import { MoreOutlined } from '@ant-design/icons'


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const MenuIcon = (props: any) => {
  const menu = data => (
    <Menu>
      {props.edit && (
        <Menu.Item onClick={() => props.edit(data)}>
          <i className='fa fa-pencil m-r-5' /> Edit
        </Menu.Item>
      )}
      <Menu.Item onClick={() => props.delete(data.id)}>
        <i className='fa fa-trash-o m-r-5' /> Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <div id={`edit_delete_menu_id_${props?.data?.id}`}>
      <Dropdown
        getPopupContainer={trigger => trigger.parentElement}
        className='dropdown profile-action position-relative'
        overlay={() => menu(props.data)}
        placement='bottomRight'
        trigger={['click']}
      >
        <MoreOutlined
          className='material-icons'
          onClick={e => e.preventDefault()}
        />
      </Dropdown>
    </div>
    
  )
}
export default MenuIcon
