import React, { useMemo, useRef, useState } from 'react'
import { Table } from '@commonComponents/table-v2'
import 'antd/dist/antd.min.css'
import { Space } from 'antd'
import { clearanceUrl } from '@api/const'
import Updateclearance from './updateClearance'
import CustomDrawer from '@commonComponents/drawer'
import { constRoute } from '@utils/route'
import BreadCrumbs from '@commonComponents/breadcrums'
import CustomButton from '@commonComponents/button'
import { useStore } from '@stores/root-store'
import SearchClearance from './searchClearance'
import { getColumns } from './columns'
import { CANCEL_TITLE, CLEARANCE_MOD, DASHBOARD, GET_METHOD, PRIMARY, SUBMIT, UPDATE } from 'constant/constant'
import { ADDDRAWERCLASS, CLEARANCE_TITLE } from './constant'
import ShowOnPermission from '@commonComponents/permit'

const ClearanceTable = () => {
  const [clearance, setClearance] = useState(false)
  const [data, setData] = useState([]);
  const [queryParam, setQueryParam] = useState({})
  const [refreshTable, setRefreshTable] = useState(false);
  const {
    clearanceModel: {
      updateEmployeeClearance
    },
  } = useStore(null)
  const tableRef = useRef(null);

  const onEdit = async data => {
    setData(data)
    setClearance(!clearance)
  }

  const columns = useMemo(() => getColumns(onEdit), [])

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header mb-2' >
          <div className='row align-items-center'>
            <div className={`d-flex justify-content-between`}>
              <BreadCrumbs
                list={[
                  { name: DASHBOARD, link: constRoute.home },
                  { name: CLEARANCE_TITLE, link: constRoute.clearance },
                ]}
                pageTitle={CLEARANCE_TITLE}
              >
              </BreadCrumbs>
            </div>
          </div>
        </div>
        <ShowOnPermission mod={CLEARANCE_MOD} method={GET_METHOD}>
          <SearchClearance
            setQueryParam={setQueryParam}
          />
        </ShowOnPermission>
        <div className='table-responsive'>
          <Table
            ref={tableRef}
            columns={columns}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            dataUrl={clearanceUrl}
            queryParam={queryParam}
          />
        </div>
        <CustomDrawer
          visible={clearance}
          title='Update Employee Clearance'
          setVisible={setClearance}
          className={ADDDRAWERCLASS}
          width={400}
          customFooter={
            <Space>
              <CustomButton
                htmlType={SUBMIT}
                type={PRIMARY}
                title={CANCEL_TITLE}
                onClick={() => setClearance(false)}
              />
              <CustomButton
                form='update-clearance'
                htmlType={SUBMIT}
                type={PRIMARY}
                title={UPDATE}
              />
            </Space>
          }
        >
          <Updateclearance
            data={data}
            showModal={setClearance}
            setRefreshTable={setRefreshTable}
            updateEmployeeClearance={updateEmployeeClearance}
            />
        </CustomDrawer>
      </div>
    </div>


  )
}

export default ClearanceTable;
