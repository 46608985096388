import { emergencyInfoUrl } from '../const'
import { BaseApi } from '../baseApi'

interface EmergencyData {
  title: string;
  relation: string;
  contact_number_1: string;
  contact_number_2: string;
  employee_id?: string;
}

class EmployeeEmergencyApi extends BaseApi {
  setEmergencyDetails = async (data: EmergencyData) => {
    const response = await this.axios.post(emergencyInfoUrl, data)

    return response.data
  }

  getEmployeeEmergencyDetails = async (employee_id: string) => {
    const url = `${emergencyInfoUrl}/${employee_id}`
    const response = await this.axios.get(url)

    return response.data
  }
  updateEmployeeEmergencyDetails = async (
    employee_id: string,
    data: EmergencyData
  ) => {
    const url = `${emergencyInfoUrl}/${employee_id}`
    const response = await this.axios.put(url, data)
    return response.data
  }

  deleteEmployeeEmergencyDetails = async (id: string) => {
    const url = `${emergencyInfoUrl}/${id}`
    const response = await this.axios.delete(url)

    return response.data
  }
}

export default EmployeeEmergencyApi
