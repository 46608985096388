import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { userLevelApi } from '@api'
import { notification } from '@utils/notifications'

export const userLevel = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  employees: types.maybeNull(types.string),
  employeeName:types.maybeNull(types.string),
  branch: types.maybeNull(types.number),
  branchName:types.maybeNull(types.string),
  company: types.maybeNull(types.number),
})
export const userLevelModal = types
  .model({
    data: types.maybeNull(types.array(userLevel)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextUser: types.optional(types.number, 1)
  })
  .views(self => ({
    get getUserLevelData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setUserLevelData = flow(function* (data) {
      try {
        self.loading = true
        yield userLevelApi.setUserLevel(data)
        notification.success('User Level Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadUserLevel = flow(function* (page=1, pageSize=20, queryParam='', resetList=false) {
      self.loading = true
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextUser = 1
        }
        const res = yield userLevelApi.getUserLevel(page , pageSize, queryParam)
        if (!res?.isCancel){
          self.data = cast([...toJS(self.data ?? []), ...res.results])
          self.count = res.count
          if (res.next) {
            self.nextUser = self.nextUser + 1
          } else {
            self.nextUser = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load User Level')
        }
      } finally {
        self.loading = false
      }
    })

    const updateUserLevel = flow(function* (id, data) {
      try {
        self.loading = true
        yield userLevelApi.updateUserLevel(id, data)
        notification.success('User Level Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteUserLevel = flow(function* (id) {
      try {
        self.loading = true
        yield userLevelApi.deleteUserLevel(id)
        notification.success('User Level Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setUserLevelData,
      loadUserLevel,
      deleteUserLevel,
      updateUserLevel,
    }
  })

export function initUserLevelModal() {
  return userLevelModal.create({})
}
