/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Form, Col, DatePicker, Row,Card } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import moment from 'moment'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { CommonSelect } from '@commonComponents/select'
import AssetAttributeForm from '@mainPage/Asset/assetAttribute'
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader'
import { conditionAsset } from './const'
import styled from 'styled-components';
import Dragger from 'antd/lib/upload/Dragger'
import { DeleteOutlined } from '@ant-design/icons';
import { validateEmptySpaces } from '@utils/common-functions'
import { StyleCommonInput } from '@mainPage/userResignation/resignationapplyForm'
import { getAlphabetRegex } from '@utils/common-functions/formValidations'

interface attributes{
  id?: string;
  title?: string;
  values?: number;
}
interface formValues {
  title: string;
  purchased_from: string;
  purchased_date: string;
  condition: string;
  purchased_amount: string;
  category_id: string;
  attributes: Array<attributes>;
  serial_number: string;
  amount: number;
  attachments: string;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const AssetForm = observer((props:any) => {

  const [form] = Form.useForm()
  const [loader, setLoader] = useState(true)
  const [fileList, setFileList] = useState([])
  const [assetAttrVisible, setAssetAttrVisible] = useState(false)
  const [attachmentsList, setattachmentsList] = useState(null)
  const {
    employeeDetails: { getAllEmployeeData, allEmployeeList, nextEmp },
    assetAttributeModel: { getAssetsData, getAssetAttribute, nextAss },
    hrCategoryModal: { getCategoriesData, loadCategory },
    assetsModel: {setAssetsData, updateAssets },
  } = useStore(null)
  useEffect(() => {
    const load = async () => {
      if (!getAllEmployeeData?.length) {
        await allEmployeeList(nextEmp, 20, '',true)
      }
      if (!getCategoriesData?.length) {
        await loadCategory()
      }
      setLoader(false)
    }
    load()
  }, [])

  useEffect(() => {
    const load = async () => {
      await getAssetAttribute(1, 20 , '' , true)
    }
    load()
  }, [assetAttrVisible])

  useEffect(() => {
    if (props.data) {
      form.resetFields()
      form.setFieldsValue({
        title: props.data.title,
        purchased_from: props.data.purchasedFrom,
        purchased_date: props.data.purchasedDate ? moment(props.data.purchaseDate) : '',
        condition: props.data.condition,
        purchased_amount: props.data.purchasedAmount,
        category_id: props.data.categoryId,
        summary: props.data.summary,
        file: props.data.file,
        serial_number: props.data.serialNumber,
        attributes: props?.data?.attributes?.map(item=>({label:`${item.title}-${item.value}`,id:item.id}))
      })
    }

  }, [props.data, getAssetsData])
  

  useEffect(() => {
    setattachmentsList(props?.data?.file);
  }, [])

  const onFinish = async (values:formValues) => {            
    values.purchased_date = moment(values.purchased_date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    let response = null
    if (props.isUpdate) {
      const attributes = values.attributes.map(item => item.id)
     response =  await updateAssets(props.data.id,{...values,attributes})
    }
    else {
      const formData = new FormData();
      fileList.forEach(file => {formData.append('file', file)});
      formData.append('data', JSON.stringify(values));
      response = await setAssetsData(formData);
    }
    if (response.success) {
      await props.setRefreshTable(true)
      props.setIsEditModalVisible(false)
    }

  }
  if (loader) {
    return <SkeletonRingLoader />
  }
  const fileProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      const allowedFormats = ['.jpg', '.jpeg', '.png'];
      const fileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2); // Extract the file extension
      if (allowedFormats.includes('.' + fileExtension.toLowerCase())) {
        setFileList([file]);
      } else {
        // Display an error message or notification to the user
        alert('Only .jpg, .jpeg, and .png file formats are supported.');
      }
  
      return false;
    },
    fileList,
    capture: undefined,
  };


  async function delAttachment(id, index) {
    attachmentsList?.splice(index, 1)
    const newlist = [...attachmentsList]
    setattachmentsList(newlist);
    const formData = new FormData();
    formData.append('attachments_to_delete', id);
    await updateAssets(formData, props.data.id);
  }
  const getCategory = getCategoriesData?.filter(item => item?.categoryType.includes('asset')).map(({ id, title }) => ({ key: id, value: title }))

  return (
    <AttrForm>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        scrollToFirstError
        id='assets-form'
      >
        <div className='form-auto-height'>
          <Row>
            <Col xs={24} sm={11}>
              <Formitem
                name='title'
                label='Asset Name'
                rules={[
                  { required: true, message: 'Asset Name is Required' },
                  { message: 'Use Only Alphabets', pattern: getAlphabetRegex() }
                ]}
              >
                <CommonInput placeholder='Asset Name' inputType='text' disabled={props?.data?.status === 'approved' || props?.data?.status === 'forward' ? true : false} />
              </Formitem>
            </Col>
            <Col span={2}></Col>
            <Col xs={24} sm={11}>
              <Formitem
                name='purchased_from'
                label='Purchased From'
                rules={[
                  { required: true, message: 'Purchased Form is required' },
                  { message: 'Use Only Alphabets', pattern: getAlphabetRegex() },
                  {validator: validateEmptySpaces},
                ]}              >
                <CommonInput placeholder='Purchased From' inputType='text' disabled={props?.data?.status === 'approved' || props?.data?.status === 'forward' ? true : false} />
              </Formitem>
            </Col>

          </Row>
          <Row>
            <Col xs={25} sm={11}>
              <Formitem
                name='purchased_date'
                label='Purchase Date'
                rules={[{ required: true, message: 'Purchase Date is Required' }]}
              >
                <DatePicker
                  format='YYYY/MM/DD'
                  getPopupContainer={trigger => trigger.parentElement}
                  className='w-100 inputField'
                  onFocus={e => {
                    if (!e.target.value) {
                      form.setFieldsValue({ purchase_date: moment(new Date()) })
                    }
                  }}
                  placeholder='12/03/2023' disabled={props?.data?.status === 'approved' || props?.data?.status === 'forward' ? true : false} />
              </Formitem>
            </Col>
            <Col span={2}></Col>
            <Col xs={24} sm={11}>
              <Formitem
                name='condition'
                label='condition'
                rules={[{ required: true, message: 'Condition is Required' }]}
              >
                <CommonSelect
                  listHeight={120}
                  className=''
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Select Condition...'
                  showSearch
                  filterOption={(input, option) => (option.children.toLowerCase().includes(input.toLowerCase()))}
                  data={conditionAsset} />
              </Formitem>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Row>
            <Col xs={24} sm={11}>
              <Formitem
                name='purchased_amount'
                label='Purchased Amount'
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/i),
                    message: 'Amount is Required',
                  },
                ]}
              >
                <CommonInput
                  inputType='number'
                  placeholder='PKR 1000'
                  disabled={props?.data?.status === 'approved' || props?.data?.status === 'forward' ? true : false} />
              </Formitem>
            </Col>
            <Col span={2}></Col>
            <Col xs={24} sm={11}>
              <Form.Item label='Category' name={'category_id'}>
                <CommonSelect
                  listHeight={125}
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Select Parent Category'
                  data={getCategory}
                  showSearch
                  filterOption={(input, option) => (option?.children?.toLowerCase().includes(input.toLowerCase()))} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={11} className={`addNewAttr`}>
              <DynamicSelect
                mode='multiple'
                placeholder='Add Attributes'
                getData={getAssetsData}
                loadData={getAssetAttribute}
                nextPage={nextAss}
                valueKey1={'title'}
                valueKey2={'value'}
                name={'attributes'}
                isForm={true}
                fetchData={false}
                formLabel={<><div>
                  <span>Asset Attribute&nbsp;</span>
                </div>
                <div className='addtext' onClick={() => setAssetAttrVisible(true)}>
                    Add New
                </div></>}
              />
            </Col>
            <Col span={2}></Col>
            <Col xs={24} sm={11}>
              <Formitem
                name='serial_number'
                label='Serial Number'
              >
                <CommonInput
                  disabled={props?.data?.status === 'approved' || props?.data?.status === 'forward' ? true : false}
                  placeholder='Serial Number' />
              </Formitem>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Formitem name='summary' label='Summary'>
                <StyleCommonInput inputType='textarea' placeholder='Describe All About'
                  disabled={props?.data?.status === 'approved' || props?.data?.status === 'forward' ? true : false} />
              </Formitem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Formitem name='file' label='Attachment'
              >
                <Card className='upload-card'>
                  <Dragger {...fileProps}>
                    <p className='ant-upload-text'>
                      Drop your image here or browse <a>browser</a>
                      <br /> Support .jpg, .jpeg, .png
                    </p>
                  </Dragger>
                  {
                    <StyleAttach>
                      {attachmentsList?.map((item, index) => (
                        <AttachmentDiv>
                          <a key={index} href={item.file} target='_blank' rel='noopener noreferrer'>
                            Attachment {index +1}
                          </a>
                          <span onClick={() => delAttachment(item.id, index)}>
                            <DeleteOutlined />
                          </span>
                        </AttachmentDiv>
                      ))}
                    </StyleAttach>         
                  } 
                </Card>
              </Formitem>
            </Col>
          </Row>
        </div>
      </Form>
      <AssetAttributeForm setAssetAttrVisible={setAssetAttrVisible} assetAttrVisible={assetAttrVisible} />
    </AttrForm>
  )
})
export default AssetForm

const AttrForm = styled.div`
.addNewAttr{
  .ant-select-selector{
      height: fit-content !important;
  }
}
`
export const StyleAttach = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  div{
    padding: 0 5px;
    span{
      visibility: hidden;
    }
    
    &:hover{
      background-color: whitesmoke;
      
      span{
        visibility: visible;
        color: #a59999;
        cursor: pointer;

        &:hover {
          color: black;
        }
      }
    }
  }
`


export const AttachmentDiv = styled.div`
  display: flex;
  gap: 20px;
  a{
    width: 100%;
  }
  span{
    display: flex;
    align-items: center;
  }
  `

const Formitem = styled(Form.Item)`
  .ant-form-item-label {
    label{
      width: auto;
    }
  }
  `