import { BaseApi } from '../baseApi'
import { baseUrl, clearanceUrl } from '@api/const'

class ClearanceApi extends BaseApi {
  // date type not mentioned

  setClearance = async data => {
    try {
      const response = await this.axios.post(`${clearanceUrl}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getClearanceAssetList = async id => {
    try {
      const response = await this.axios.get(`${baseUrl}asset_list/?employee_id=${id}`)
      return response.data

    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  //
  updateClearance = async (data, id) => {
    try {
      const response = await this.axios.patch(`${clearanceUrl}${id}`, data)
      return response.data
    } catch (error) {
      const x = error
      throw x
    }
  }
  getClearance = async (num, pageSize, queryParam={}) => {
    try {
      let url = clearanceUrl
      url += `?page=${num}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {}
        }
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getClearanceforEmployee = async queryParam => {
    
   
    try {
      const url = `${clearanceUrl}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {}
        }
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}
  
export default ClearanceApi;