import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { SearchOutlined } from '@ant-design/icons'
import RoleSelect from '@commonComponents/roleDropdown/roleDropdown'
import SearchFilter from '@commonComponents/SearchFilter'
interface SearchClientProps {
  setQueryParam: (data: object) => void;
}
const SearchClients = observer((props: SearchClientProps) => {
  return (
    <div className='row ' >
      <SearchFilter
        {...props}
        children={
          <Row gutter={2} className={`rowDiv gap-lg-0  gap-xl-0 gap-xxl-2 flex-grow-1`}>
            <Col xs={24} sm={12} lg={5}>
              <div className='input-dark-theme'>
                <Form.Item name={'clientID'}>
                  <CommonInput
                    placeholder='Search by ID'
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className='SearchClient' md={5} lg={5}>
              <Form.Item name='first_name'>
                <CommonInput
                  className='form-control inputField'
                  placeholder='First Name'
                />
              </Form.Item>
            </Col>
            <Col className='SearchClient' md={5} lg={5}>
              <Form.Item name='last_name'>
                <CommonInput
                  className='form-control inputField'
                  placeholder='Last Name'
                />
              </Form.Item>
            </Col>
            <Col className='SearchClient commonSelect-dark-theme' md={5} lg={5}>
              <Form.Item name={'role_name'}>
                <RoleSelect
                  placeholder={'Select Role'}
                  isForm={true}
                />
              </Form.Item>
            </Col> 
          </Row>
        } />
    </div>
  )
})
export default SearchClients

