import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl } from '../const/index'
class LanguageApi extends BaseApi {
  setLanguage = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}language/`,
        { name: data },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getLanguage = async num => {
    try {
      const response = await this.axios.get(
        `${baseUrl}language/?page=${num ? num : 1}`,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateLanguage = async (id, data) => {
    try {
      const response = await this.axios.put(
        `${baseUrl}language/${id}/`,
        { name: data },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteLanguage = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}language/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default LanguageApi
