import { BaseApi } from '../baseApi'
import { employeeProfileUrl } from '../const'

class EmployeeProfileApi extends BaseApi {
  getEmployeeProfileDetails = async (employee_id: string, isCurrentUser: boolean, isAdmin: boolean,canViewAll:boolean) => {
    let url: string
    if (isCurrentUser) {
      url = employeeProfileUrl
    } else if (canViewAll || isAdmin) {
      url = `${employeeProfileUrl}profile/${employee_id}`
    } else {
      url = `${employeeProfileUrl}lead_view/${employee_id}`
  }
    const response = await this.axios.get(url)
    return response;
  }
  updateEmployeeProfileDetails = async (profile_id: string, data) => {
    try {
      const url = `${employeeProfileUrl}profile/${profile_id}`
      const response = await this.axios.put(url, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeProfileApi
