import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const Stopwatch = observer(({ PunchState, elapsedTimeProp }: any) => {
  const [elapsedTime, setElapsedTime] = useState(0)
  const states = ['punch_out', 'finish_recess', 'conclude_prayer']

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (states.includes(PunchState)) {
        setElapsedTime( prevElapsedTime => prevElapsedTime + 1)
      } else {
        setElapsedTime(elapsedTimeProp)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [PunchState, elapsedTimeProp])


  useEffect(()=>{
       setElapsedTime(elapsedTimeProp)
  },[elapsedTimeProp])

  const hours = Math.floor(elapsedTime / 3600)
  const minutes = Math.floor((elapsedTime % 3600) / 60)
  const seconds = elapsedTime % 60

  return (
    <Timers className={hours > 7 ? 'exceedTime' : ''}>
      {typeof elapsedTime == 'number'
        ? hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ':' +
          minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ':' +
          seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        : '00:00:00'}
    </Timers>
  )
})

export default Stopwatch

const Timers = styled.h1`
  font-size: 18px;
  color: ${props =>
    props.className == 'exceedTime' ? '#0ed71e !important' : ''};
`
