import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { projectsClientsUrl } from '../const/index'

class ProjectsClientsApi extends BaseApi {

  getProjectsClient = async (num ,pageSize, queryParam={}) => {
    try {
      const url = `${projectsClientsUrl}?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ProjectsClientsApi