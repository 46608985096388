import { headerAuthorization } from '../common-utils'

import { baseurlV2 } from '../const'
import { BaseApi } from '../baseApi'

class LeadApi extends BaseApi {
  getLead = async () => {
    try {
      const response = await this.axios.get(`${baseurlV2}leads/`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateLead = async (id, data) => {
    try {
      const response = await this.axios.patch(`${baseurlV2}leads/${id}/`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default LeadApi
