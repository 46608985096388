import React from 'react'

import { ButtonHTMLType, ButtonType } from 'antd/lib/button/button'
import { PlusOutlined } from '@ant-design/icons'
export interface buttonProps {
  className?: string;
  block?: boolean;
  danger?: boolean;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  htmlType?: ButtonHTMLType;
  icon?: React.ReactNode;
  loading?: boolean;
  target?: string;
  type?: ButtonType;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const AddButton = (props: buttonProps) => (
  <a
    className='add-btn btn'
    href={props.href}
    target={props.target}
    type={props.type}
    onClick={props.onClick}
  >
    <PlusOutlined /> {props.title}
  </a>
)

export default AddButton
