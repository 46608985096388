import { CenteredDiv } from '@mainPage/newEmployee/employeeTableColumns';
import { REASON } from './const';
import Tooltip from '@commonComponents/CustomToolTip';

export const getColumns = () => {
  return [
    {
      title: <CenteredDiv>{REASON}</CenteredDiv>,
      dataIndex: 'reason',
      width: '220px',
      render: reason => (
          <div
            style={{
              maxHeight: '40px',
              maxWidth: '170px', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              wordWrap: 'break-word', 
              wordBreak: 'break-all',
              textAlign: 'center',
            }}
          >
             <Tooltip placement='topLeft' title={reason} minLength={15}>
              <span style={{cursor:'pointer'}}>{(reason?.slice(0,15) + (reason?.length > 15 ? '....' : '' ))|| 'No reason provided'}</span>
            </Tooltip>
          </div>
      ),
    },
  ];
};
