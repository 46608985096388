import { cast, flow, types } from 'mobx-state-tree'
import { shiftApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
export const shift = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  startTime: types.maybeNull(types.string),
  endTime: types.maybeNull(types.string),
  offDays: types.array(types.string),
  note: types.maybeNull(types.string),
})

export const shiftsModal = types
  .model({
    shift: types.maybeNull(types.array(shift)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextShi: types.optional(types.number, 1),
  })
  .views(self => ({
    get getShiftData() {
      return toJS(self.shift)
    },
    get isLoading() {
      return self.loading
    },

  }))
  .actions(self => {
    const setShift = flow(function* (data) {
      try {
        self.loading = true
        yield shiftApi.setShift(data)
        notification.success('Shift Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadShift = flow(function* (page = 1, pageSize = 20, queryParam = '', resetList = false, saveInStore = true) {
      self.loading = true
      self.shift = cast([])
      try {
        self.loading = true
        if (resetList) {
          self.nextShi = 1
        }
        const res = yield shiftApi.getShift(page, pageSize, queryParam)
        if (!res?.isCancel && saveInStore) {
          self.shift = cast([...toJS(self.shift ?? []), ...res.items])
          self.count = res.count
          if (res.next) {
            self.nextShi = self.nextShi + 1
          } else {
            self.nextShi = 1
          }
        } else if (!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Shift')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateShift = flow(function* (id, data) {
      try {
        self.loading = true
        yield shiftApi.updateShift(id, data)
        notification.success('Shift Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteShift = flow(function* (id) {
      try {
        self.loading = true
        yield shiftApi.deleteShift(id)
        notification.success('Shift Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'You can\'t delete this Shift')
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setShift,
      loadShift,
      updateShift,
      deleteShift
    }
  })

export function initShiftModal() {
  return shiftsModal.create({})
}
