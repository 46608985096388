import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ListActionIcons from '../../../../assets/img/listActionsIcon.svg'
import addListIcon from '../../../../assets/img/addListIcon.svg'
import closeIcon from '../../../../assets/img/close_icon.svg'
import { Avatar, DatePicker, Divider, Dropdown, Input, Menu, Select, Space } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import hourglassEmpty from '../../../../assets/img/hourglass_empty.svg'
import dueDate from '../../../../assets/img/dueDate.svg'
import startDate from '../../../../assets/img/startDate.svg'
import close from '../../../../assets/img/close.svg'
import rename from '../../../../assets/img/rename.svg'
import HighPriority from '../../../../assets/img/high-priority.svg'
import edit from '../../../../assets/img/edit.svg'
import { SketchPicker } from 'react-color'
import TaskDetails from './taskDetail'
import { useStore } from '@stores/root-store'
import LowPriority from '../../../../assets/img/LowPriority .svg'
import Clear from '../../../../assets/img/thiscantbdone.svg'
import MediumPriority from '../../../../assets/img/medium-priority.svg'
import { observer } from 'mobx-react-lite'
import { ArrowUpOutlined } from '@ant-design/icons';
import DeletePopUp from '@commonComponents/delete-popup'
import deleteBtnIcon from '../../../../assets/img/delete-basket-icon.svg';
import { DATE_FORMATE } from '@mainPage/EmployeeLeave/const'
import moment from 'moment'
import plus from '../../../../assets/img/plus.svg'
import { text } from '@fortawesome/fontawesome-svg-core'
import { colors } from 'constant/colors'

const { TealishBlue, CyanBlue } = colors

interface propsData {
  activeSprintId?: string;
  projectID?: string;
}

const BoardView = observer(({ activeSprintId, projectID }: propsData) => {
  const [addNewTask, setAddNewTask] = useState(0)
  const [isInputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const headingRef = useRef(null)
  const [sketchColor, setSketchColor] = useState('#bcc0c7')
  const [open, setOpen] = useState(false)
  const [showInput, setShowInput] = useState({});
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [inputValue1, setInputValue1] = useState({ itemId: null, listName: null });
  const [deleteListId, setDeleteListId] = useState(null)
  const [focusedInputId, setFocusedInputId] = useState(null);
  const inputRef = useRef(null);
  const [updatedLabelData, setUpdatedLabelData] = useState({ labelID: null, boardID: null })
  const [editLabel, setEditLabel] = useState(false)
  const [deleteTagData, setDeleteTagData] = useState({ labelID: null, boardID: null });
  const [labels, setLabels] = useState(['jack', 'lucy']);
  const [color, setColor] = useState(null)
  const [name, setName] = useState('');
  const labelInputRef = useRef(null);
  const [actions, setActions] = useState('')
  const [taskName, setTaskName] = useState('')
  const [startdate, setStartDate] = useState(false)
  const [duedate, setDueDate] = useState(false)
  const [selectedPriorityKey, setSelectedPriorityKey] = useState('LOW');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedDueDate, setSelectedDueDate] = useState(null);
  const [newTaskEstimatedTime, setNewTaskEstimatedTime] = useState('')
  const [details,setDetails] = useState(null)
  const [updateTaskID, setUpdateTaskID] = useState(null)
  const [empname, setEmpName] = useState([]);
  const [Id, setId] = useState(null)
  const { Option } = Select;

  const handleMenuClick = e => {
    setSelectedPriorityKey(e.key);
  };
  const {
    boardDetails: {
      getCompleteBoard,
      setBoardListDetails,
      loadBoardListDetails,
      getBoardListData,
      updateBoardListDetails,
      deleteBoardListDetails,
      deleteLabelDetail,
      loadLabelDetail,
      updateLabelDetail,
      getDataLabel,
      setLabelDetail,
      setSprintTaskDetails,
      isLoading,
      loadSprintList,
      updateTaskDetails,
    },
    employeesOpenList: { loadEmployeesOpenList, getEmployeesOpenList }
  } = useStore(null)



  useEffect(() => {
    async function load() {
      await loadEmployeesOpenList();
    }
    load();
  }, []);

  useEffect(()=>{
    loadSprintList(getCompleteBoard?.[0]?.id)
  },[details])

  useEffect(() => {
    loadLabel();
  }, [getCompleteBoard])
  useEffect(() => {
    loadList();
  }, [activeSprintId]
  )
  useEffect(() => {
    loadLabel();
  }, [getCompleteBoard]
  )

  useEffect(() => {
    generateColor();
    const handleClickOutside = event => {
      if (headingRef.current && !headingRef.current.contains(event.target)) {
        setInputVisible(false)
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = event => {
      // Check if the clicked element is an input field or the "Rename" option
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        event.target.getAttribute('alt') !== '_rename'
      ) {
        setInputVisible(false)
        setFocusedInputId(null) // Remove focus from the input field
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [focusedInputId])

  const handleHeadingClick = () => {
    setInputVisible(true);
  };

  const loadList = () => {
    activeSprintId && loadBoardListDetails(activeSprintId)
  }

  const loadLabel = () => {
    getCompleteBoard && loadLabelDetail(getCompleteBoard?.[0]?.id)
  }

  const handleSaveButtonClick = async () => {
    const response = await setBoardListDetails({
      sprint: activeSprintId,
      label: inputValue,
      color: sketchColor,
    })
    if (response?.success) {
      setInputVisible(false)
      loadList()
    }
  }

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleClickOnModal = id => {
    if (id) {
      setAddNewTask(id)
    }
  }

  const handleInputRenameChange = (event, itemId) => {
    setInputValue1(prevState => ({
      ...prevState,
      [itemId]: event.target.value,
    }))
  }

  const handleTickClick = async itemId => {
    const res = await updateBoardListDetails({
      id: itemId,
      name: inputValue1[itemId],
    })
    if (res?.success) {
      setShowInput(prevState => ({ ...prevState, [itemId]: false }))
      loadList()
    }
  }

  const handleCrossClick = itemId => {
    setShowInput(prevState => ({ ...prevState, [itemId]: false }))
  }

  const deleteBoardListHandler = async () => {
    const res = await deleteBoardListDetails(deleteListId)
    if (res.success) {
      setVisibleDelete(false)
      loadList()
    }
  }
  const deleteTaskLabelHandler = async () => {
    const res = await deleteLabelDetail(deleteTagData?.labelID, 4)
    if (res?.success) {
      setVisibleDelete(false);
      loadLabel();
    }
  }
  const handleTaskNameChange = e => {
    setTaskName(e.target.value)
  }

  const handleSaveButton = async () => {
    const data = {
      BoardList: addNewTask,
      name: taskName,
      estimated_time: newTaskEstimatedTime,
      priority: selectedPriorityKey !== 'null' ? selectedPriorityKey : 'LOW',
      sprint: activeSprintId,
      due_date: moment(selectedStartDate).format(`${DATE_FORMATE} hh:mm:ss`),
      project: projectID,
    }
    await setSprintTaskDetails(data)
    setTaskName('')
    setAddNewTask(0)
    loadList();
  }
  const StartDate = (
    <div>
      <StylePicker>
        <StyleDatePicker
          onOpenChange={() => setStartDate(false)}
          open={startdate}
          onChange={date => setSelectedStartDate(date)}
          value={selectedStartDate}
        >
        </StyleDatePicker>
      </StylePicker>
    </div>
  )

  const DueDate = (
    <div>
      <StylePicker>
        <StyleDatePicker
          onOpenChange={() => setDueDate(false)}
          onChange={date => setSelectedDueDate(date)}
          value={selectedDueDate}
          open={duedate}
        >
        </StyleDatePicker>
      </StylePicker>
    </div>
  )

  const menus = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='LOW'>
        <img src={LowPriority} alt='' /> Low
      </Menu.Item>
      <Menu.Item key='MEDIUM'>
        <img src={MediumPriority} alt='' /> Normal{' '}
      </Menu.Item>
      <Menu.Item key='HIGH'>
        <img src={HighPriority} alt='' /> High
      </Menu.Item>
      <Menu.Item key='null'>
        <img src={Clear} width={13} height={13} alt='' /> Clear{' '}
      </Menu.Item>
    </Menu>
  )
  const selectedPriorityMenuItem = menus.props.children.find(
    item => item.key === selectedPriorityKey
  );
  const selectedImage = selectedPriorityMenuItem && selectedPriorityMenuItem.props.children[0];
  const EstimateMenu = (
    <Menu>
      <div className='d-flex styleinput' style={{ flexDirection: 'column', padding: '10px 10px 5px 10px' }}>
        <label>Time Estimate ?</label>
        <Input value={newTaskEstimatedTime} onChange={e => setNewTaskEstimatedTime(e.target.value)} />
      </div>
    </Menu>
  )

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (editLabel) {
        updateLabel();
      }
      else {
        addItem(event);
      }
    }
  };
  const handleClick = event => {
    event.stopPropagation();
  };

  const handleDeleteLabel = (labelID, boardID) => {
    setVisibleDelete(true)
    setDeleteTagData({ labelID: labelID, boardID: boardID })
  }

  const handleLabelEdit = (labelID, boardID, labelName) => {
    setName(labelName)
    setEditLabel(true)
    setUpdatedLabelData({ labelID: labelID, boardID: boardID })
  }
  const updateLabel = async () => {
    await updateLabelDetail(4, updatedLabelData?.labelID, { name: name })
    setEditLabel(false);
    setName('');
    loadLabel();
  }


  const handleModal = item=>{
    setDetails(item)
    setOpen(true)
  }

  const addItem = async e => {
    await generateColor();
    e.preventDefault();
    setLabels([...labels, name || `New item ${labels.length + 1}`]);
    await setLabelDetail({ name: name, color: color?.darkShade, text_color: color?.lightShade }, '4')
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
    loadLabel();
  };

  const generateColor = () => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    const lightShade = lightenColor(randomColor, 100);
    const darkShade = darkenColor(randomColor, 20);

    setColor({ lightShade, darkShade });
  };

  const lightenColor = (color, amount) => {
    const [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16));
    const convertedColor = [
      Math.min(255, r + amount) || 255, // Fallback to 255 if NaN
      Math.min(255, g + amount) || 255, // Fallback to 255 if NaN
      Math.min(255, b + amount) || 255, // Fallback to 255 if NaN
    ];

    return `rgb(${convertedColor.join(',')})`;
  };

  const darkenColor = (color, amount) => {
    const [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16));
    const convertedColor = [
      Math.max(0, r - amount) || 0, // Fallback to 0 if NaN
      Math.max(0, g - amount) || 0, // Fallback to 0 if NaN
      Math.max(0, b - amount) || 0, // Fallback to 0 if NaN
    ];

    return `rgb(${convertedColor.join(',')})`;
  };
  const LabelMenu = (
    <div style={{ background: 'white !1important' }}>
      <CustomMenu style={{ background: 'white', width: '200px !important ' }}>
        {getDataLabel?.map(item => (
          <><CustomMenuItem key={item?.id}><DropdownName color={item?.color}>{item?.name}</DropdownName><LabelActions onClick={handleClick}><img src={edit} onClick={() => handleLabelEdit(item.id, item?.boardID, item?.name)} /><img src={deleteBtnIcon} onClick={() => handleDeleteLabel(item?.id, item?.boardID)} /></LabelActions></CustomMenuItem></>
        ))}
        <Divider style={{ margin: '8px 0' }} />
        <Space style={{ padding: '0 8px 4px' }}>
          <Input
            placeholder='Enter Label Name'
            ref={labelInputRef}
            value={name}
            onChange={event => setName(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          {editLabel ? <StyledArrowUpOutlined style={{ cursor: 'pointer' }} onClick={updateLabel} /> :
            <AddIcon onClick={addItem} style={{ cursor: 'pointer' }}>+</AddIcon>}
        </Space>
      </CustomMenu>
    </div>
  )

  const updateTask = async (assignee, taskID) => {
    const data = {assignee : assignee}
    await updateTaskDetails(data , taskID)
    loadList();
  }

  const handleAddName = assigneeID => {
    const dummyAssigneeArray = [...empname , assigneeID ]
    setEmpName(prevItems => [...prevItems, assigneeID]);
    updateTask(dummyAssigneeArray, updateTaskID);

  };



  const handleDelete = (assigneeArray , taskID, assigneeToBeDelID) => {
    const dummyAssigneeArray = [...assigneeArray]
    const indexToRemove = dummyAssigneeArray.findIndex(item => item === assigneeToBeDelID);
    if (indexToRemove !== -1) {
      dummyAssigneeArray.splice(indexToRemove, 1);
    }
    updateTask(dummyAssigneeArray, taskID);
  };


  const handleselect = (assignee, id) => {
    setId(id)
    setEmpName([...assignee]);
    setUpdateTaskID(id);
  }

  return (
    <>
      {getBoardListData?.map(item => {
        const itemId = item.id
        return (
          <List>
            <ListHeaderContainer>
              <ListHeading color={item?.color} className='ListHeadings'>
                {showInput[itemId] ? (
                  <RenameIcons>
                    <RenameInput
                      ref={input => {
                        if (input && itemId === focusedInputId) {
                          input.focus()
                        }
                      }}
                      type='text'
                      value={inputValue1[itemId]}
                      onClick={() => setFocusedInputId(itemId)}
                      onChange={event => handleInputRenameChange(event, itemId)}
                    />
                    <span onClick={() => handleTickClick(itemId)}>
                      <img width='30px' src={rename} alt='_rename' />
                    </span>
                    <span onClick={() => handleCrossClick(itemId)}>
                      <img width='30px' src={close} alt='_close' />
                    </span>
                  </RenameIcons>
                ) : (
                  <>
                    <ListName>{item?.name}</ListName>
                    <ListActions>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key='rename'
                              onClick={() => {
                                setShowInput({ ...showInput, [itemId]: true })
                                setInputValue1(prevState => ({
                                  ...prevState,
                                  [itemId]: item?.name,
                                }))
                                setFocusedInputId(itemId)
                              }}
                            >
                              Rename
                            </Menu.Item>
                            <Menu.Item
                              key='delete'
                              onClick={() => {
                                setDeleteListId(item?.id)
                                setVisibleDelete(true)
                              }}
                            >
                              Delete
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <span>
                          <div>
                            <img src={ListActionIcons} style={{ cursor: 'pointer' }} alt='list_actions' onClick={() => setActions('list')} />
                          </div>
                        </span>
                      </Dropdown>
                      <img
                        src={addListIcon}
                        style={{ cursor: 'pointer' }}
                        alt='add_list'
                        onClick={() => handleClickOnModal(item?.id)}
                      />
                    </ListActions>
                  </>
                )}
              </ListHeading>
            </ListHeaderContainer>
            <ListTasks>
              {addNewTask == item?.id && (
                <AddTask>
                  <TaskInputArea className='antInput'>
                    <img
                      src={closeIcon}
                      alt='_close'
                      onClick={() => setAddNewTask(0)}
                    />
                    <NewTaskInput
                      placeholder='Type Task Name ...'
                      value={taskName}
                      onChange={handleTaskNameChange}
                    />
                    <Avatar.Group
                      maxCount={2}
                      maxStyle={{
                        color: '#f56a00',
                        backgroundColor: '#fde3cf',
                      }}
                    >
                      <Avatar src='https://joeschmoe.io/api/v1/random' />
                      <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
                      <Tooltip title='Ant User' placement='top'>
                        <Avatar style={{ backgroundColor: '#87d068' }} />
                      </Tooltip>
                      <Avatar style={{ backgroundColor: CyanBlue }} />
                    </Avatar.Group>
                  </TaskInputArea>
                  <IconsDiv>
                    <OptionsDiv
                      style={{
                        jutifyContent: 'flex-start !important',
                        gap: '10px',
                      }}
                    >
                      <OptionsDiv>
                        <Dropdown overlay={menus} trigger={['click']}>
                          <span>
                            <Actions>
                              {selectedImage}
                            </Actions>
                          </span>
                        </Dropdown>
                        <Dropdown overlay={StartDate} trigger={['click']}>
                          <span>
                            <Actions>
                              <img
                                src={startDate}
                                onClick={() => setStartDate(true)}
                              />
                            </Actions>
                          </span>
                        </Dropdown>
                        <Dropdown overlay={DueDate} trigger={['click']}>
                          <span>
                            <Actions>
                              <img
                                src={dueDate}
                                onClick={() => setDueDate(true)}
                              />
                            </Actions>
                          </span>
                        </Dropdown>
                        <div>
                          <Dropdown overlay={EstimateMenu} trigger={['click']}>
                            <span>
                              <Actions>
                                <img src={hourglassEmpty} />
                              </Actions>
                            </span>
                          </Dropdown>
                        </div>
                      </OptionsDiv>
                    </OptionsDiv>
                    <AddTaskSaveButton
                      onClick={handleSaveButton}
                    >
                      Save
                    </AddTaskSaveButton>
                  </IconsDiv>
                </AddTask>
              )}
              {item?.tasks?.map(e => {
                return (
                  <Task className='TaskProjects'>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <TaskId className='tasIdProjects'> Shared with me &lt; {item?.sprintName}</TaskId>
                      </div>

                    </div>
                    <TaskHeader>
                      <TaskHeading onClick={() => handleModal({e,item})}>
                        {e.name}
                      </TaskHeading>
                      <div>
                        <StyledCenteredText >
                          <Avatar.Group maxCount={2} >
                            {e?.assigneeDetails?.map((employee, index) => (
                              <StyledDiv
                                index={index}
                                length={text?.length}
                                style={{ color: 'white !important' }}>
                                <div className='AvatarIcon'>
                                  <Avatar key={index} style={
                                    { backgroundColor: index === 0 ? '#edafbc' : '#edb777', zIndex: '999' }} onClick={() => setId(null)}>
                                    {employee?.firstName?.charAt(0).toUpperCase()}
                                  </Avatar>
                                  <img className={`${e?.assigneeDetails.indexOf(employee) > -1 ? 'innerCrossIcon' : ''} img3 srtZ`} src={plus} key={index} alt='' style={{ zIndex: '999', cursor: 'pointer' }} onClick={() => handleDelete(e?.assignee, e.id, employee?.id)} />
                                </div >
                              </StyledDiv>
                            ))}
                            <AddAssigneeIcon onClick={() => handleselect(e?.assignee, e.id)}>+</AddAssigneeIcon>
                          </Avatar.Group>
                          <div className='select'>
                            <Select
                              style={{ width: 200, visibility: Id === e.id ? 'visible' : 'hidden' }}
                              open={Id === e.id}
                              placement='bottomLeft'
                              showSearch
                              placeholder='Select Assignee'
                              onDropdownVisibleChange={() => setId(null)}
                              onSelect={value => handleAddName(value)}
                            >
                              {getEmployeesOpenList?.map((name, item) => (
                                <Option key={item} value={name.id}>
                                  {name.firstName}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </StyledCenteredText>
                      </div>
                    </TaskHeader>
                    <DataIconDiv>
                      <IconData>
                        <img width='18px' src={e.priority.toLowerCase() === 'low' ? LowPriority : e.priority.toLowerCase() === 'high' ? HighPriority : e.priority.toLowerCase() === 'medium' ? MediumPriority : Clear} />
                        <IconDataText className='tasIdProjects'>{e?.priority ? e.priority : 'No Priority Slected'}</IconDataText>
                      </IconData>
                      <IconData>
                        <img src={hourglassEmpty} />
                        <IconDataText className='tasIdProjects'>{e.estimatedTime}</IconDataText>
                      </IconData>
                      <IconData>
                        <img src={startDate} />
                        <IconDataText className='tasIdProjects'>{e.createdAt.split('T')[0]}</IconDataText>
                      </IconData>
                      <IconData>
                        <img src={dueDate} />
                        <IconDataText className='tasIdProjects'>{e.dueDate.split('T')[0]}</IconDataText>
                      </IconData>
                    </DataIconDiv>
                    <LabelsDiv>
                      {getDataLabel?.map(item => {
                        return (
                          <Label className='labelProjects' color={item?.color} textColor={item?.textColor}>{item?.name}</Label>
                        )
                      })}
                      <CustomDropdown overlay={LabelMenu} trigger={['click']}>
                        <span>
                          <div><EditIcon style={{ cursor: 'pointer', padding: '8px', borderRadius: '24px' }} src={edit} alt='edit' onClick={() => { setActions('label'); setName(''); setEditLabel(false) }} /> </div>
                        </span>
                      </CustomDropdown>
                    </LabelsDiv>
                  </Task>
                )
              })}
            </ListTasks>
          </List>
        )
      })}
      <List>
        {!isInputVisible ? (
          <AddListHeading
            className='ListHeadings'
            borderColor='#bcc0c7'
            ref={headingRef}
            onClick={handleHeadingClick}
          >
            + ADD STATUS
          </AddListHeading>
        ) : (
          <AddListHeading borderColor={sketchColor} ref={headingRef}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '273px',
              }}
            >
              <InputContainer>
                <AddListInput
                  isVisible={isInputVisible}
                  placeholder='Add Status'
                  onChange={handleInputChange}
                />
                {isInputVisible && (
                  <SaveButton onClick={handleSaveButtonClick}>Save</SaveButton>
                )}
              </InputContainer>
              <StyledSketchPicker
                color={sketchColor}
                onChange={e => setSketchColor(e.hex)}
              />
            </div>
          </AddListHeading>
        )}
      </List>
      <TaskDetails open={open} setOpen={setOpen} details={details}></TaskDetails>

      {/* Delete Popup */}
      <DeletePopUp
        delete={actions === 'list' ? deleteBoardListHandler : deleteTaskLabelHandler}
        msgTitle={actions === 'list' ? 'Delete Board List' : 'Delete Board Label'}
        renderMsg={`Are you sure you want to delete this Board ${actions === 'list' ? 'List' : 'Label'}?`}
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText={'Delete'}
        handleCancel={() => setVisibleDelete(false)}
      />
    </>
  )
})
export default BoardView

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 8px;
`
const ListHeading = styled.p`
  display: flex;
  width: 262px;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  border-top: 3px solid ${props => props.color};
  color: black;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
`
const AddListHeading = styled.p`
  display: flex;
  width: 262px;
  height: 54px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  border-top: 3px solid ${props => props.borderColor};
  color: #bcc0c7;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`
const AddListInput = styled(Input)`
  width: 262px;
  height: 58px;
  border: none;
  outline: none;
  border-radius: 12px 12px 0px 0px;
  height:30px;
`
const ListTasks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 275px;
  border-radius: 4px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top:5px;
  padding-bottom:5px;
`
const Task = styled.div`
  border-radius: 12px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 262px;
`
const TaskHeading = styled.p`
  color: #000000;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  width: 90%;
`
const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;
  margin-top: 10px;
`
const LabelsDiv = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  flex-wrap: wrap;
`
const Label = styled.p`
display: flex;
  padding: 3px 8px;
  align-items: center;
  gap: 10px;
  color: black;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  border-radius: 0px 12px 12px 0px;

  background: ${({ textColor }) => {
    return textColor;
  }};
`
const OptionsDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 26px;
  align-items: center;

  .ant-picker {
    border: none !important;
    svg {
      margin-top: 20px !important;
    }
    &:hover {
      border: none !important;
    }
  }
`
const ListName = styled.p`
  color: #000;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
`
const ListActions = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`
const TaskId = styled.p`
  color: #544d61;
  font-family: Poppins;
  font-size: 9.7px;
  font-weight: 275;
`
const StyledSketchPicker = styled(SketchPicker)`
  margin-top: 15px;
  & > div:nth-child(3) {
    display: none !important;
  }
  & > div:nth-child(2) {
    & > div:first-child {
      & > div:nth-child(2) {
        display: none;
      }
      & > div:nth-child(1) {
        height: 24px !important;
      }
    }
  }
`
const SaveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  color: white;
  border-radius: 8px;
  background: #08c7e0;
`
const AddTaskSaveButton = styled.button`
  padding: 2px 16px;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  background: #08c7e0;
  height: 30px;
`
const InputContainer = styled.div`
  position: relative;
`
const AddTask = styled.div`
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #3bd6e6;
  width: 262px;
  padding: 8px;
`

const NewTaskInput = styled(Input)`
  border: none;
  outline: none;
  box-shadow: 0px 0px 0px 0px !important;
  .ant-input:focus {
    box-shadow: 0px 0px 0px 0px !important;
  }
`
const TaskInputArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`
const IconsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`
const RenameInput = styled.input`
  width: 75%;
  height: 50px;
  border: 0px;
  outline: none;
  font-weight: 600;
`
const RenameIcons = styled.div`
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Actions = styled.div`
  border-radius: 25px;
  padding: 5px;
  &:hover {
    background-color: #eaf2fb;
  }
  img {
    margin-top: 3px;
    width: 24px;
    height: 24px;
  }
`
const StyleDatePicker = styled(DatePicker)`
  .ant-picker-input {
    visibility: hidden !important;
    display: none !important;
  }
`

const StylePicker = styled.div`
  margin-bottom: -24px !important;
  .ant-picker {
    border: none !important;
  }
`

export const StyleMenuTime = styled.div`
  .ant-dropdown-menu {
    width: 21pc !important;
    height: 9pc !important;
    border-radius: 8px !important;
  }
  .ant-input {
    width: 10pc !important;
  }

  .styleinput {
    justify-content: center;
    align-items: center;
    padding: 15px !important;
    gap: 10px;
  }
`

export const StyleFooter = styled.div`
  border-top: 1px solid rgb(244 244 244);
  padding: 9px 16px 0 12px;
  font-size: 10px;
  span {
    font-size: 14px;
    font-weight: 600;
  }
  p {
    margin-top: 3px;
  }
`
const CustomDropdown = styled(Dropdown)`
.ant-dropdown{
  width: 200px !important;
}
`
const CustomMenu = styled(Menu)`
.ant-dropdown-menu{
  width: 200px !important;
  background: white;

}`
const AddIcon = styled.p`
background: ${TealishBlue};
color: white;
padding: 0px 10px;
font-size: 20px;
border-radius: 4px;
`
const LabelActions = styled.div`
display: none;
`
const CustomMenuItem = styled(Menu.Item)`
height: 50px;
.ant-dropdown-menu-title-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &:hover ${LabelActions} {
    display: flex;
    gap:8px;
  }
  `
const DropdownName = styled.span`
color: ${props => props.color ? props.color : 'black'};
`

const EditIcon = styled.img`
&:hover {
  background-color: #e7f1fc;
}
`
const StyledArrowUpOutlined = styled(ArrowUpOutlined)`
background: ${TealishBlue};
color: white;
padding: 6px;
font-size: 20px;
border-radius: 4px;`

const DataIconDiv = styled.div`
display: flex;
gap:5px;
flex-wrap: wrap;
margin: 10px 0px 10px 0px;
`
const IconData = styled.div`
display: flex;
gap:8px;
`
const IconDataText = styled.span`
font-size:11px;
color: gray !important;
width: 100%;
`
const ListHeaderContainer = styled.div`
display: flex;
align-items:center;
justify-content: center;
`

export const SetMenu = styled.div`
  .ant-dropdown-menu{
    height: 198px !important;
       overflow: auto !important;
       border-radius: 15px !important;
  }
  `
export const StyledCenteredText = styled.span`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
.ant-avatar{
  margin-left: 3px !important;
}
.ant-dropdown-menu{
  border-radius: 15px !important;
}

.select{
  .ant-select{
    margin-top: 60px !important;
    z-index: 1;
    position: absolute;
  }
}

.addIcon{
  position: relative;
  svg{
    width: 25px;
    height: 30px;
    display: flex;
  }
}

.AvatarIcon{
    position: relative;
    display: flex;
    margin-left: -14px;
    .img3{
      position: absolute;
      margin-left: 10px;
    margin-top: -6px;
    rotate: 45deg;
    visibility: hidden;
    height: 20px;
    width: 20px;
    background: white;
    border-radius: 12px;
    border: 1px solid white;
    }

    &:hover .img3 {
  visibility: visible;
  }
  }


`
export const StyledDiv = styled.span`
.ant-avatar-circle{
display: flex !important;
justify-content: center !important;
 position: ${props => props.index == 0 ? 'absolute' : ''} ;
 position: ${props => props.length == 1 ? 'relative' : ''} !important;
 margin-left: ${props => props.index == 0 ? '-25px' : ''}; 
 margin-left: ${props => props.length == 1 ? '0px' : ''}; 

};
.innerCrossIcon{
  z-index: 999;
  position: fixed;
  margin-top: -42px;
  margin-left: 16px;
  rotate: 225deg;
  background: white;
  border: 2px solid white;
  border-radius: 12px;
  width: 23px;
}
.anticon{
 z-index: 1;
position: absolute;
margin-left: 10px;
  border: none;
background-color: black;
color: white !important;

  padding: 5px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50%;
  margin-top: -2px;
    margin-left: 15px;
    padding: 3px ;
    color: red !important;
  svg{
    color: white !important;
    img{
      color: red !important;
    }
  }
  .lFLkD .page-header *{
    color: white !important;
  }
}

`
const AddAssigneeIcon = styled.div`
border: 1px dashed gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    font-size: 18px;
    border-radius: 25px;
    cursor: Pointer;
`