import React  from 'react'
import { Form, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { CommonSelect } from '@commonComponents/select'
import {
  APPROVED,
  PENDING,
  STATUS_TITLE,
  LARGE,
  REASON_LEAVE,
  PENDING_LOWERCASE,
  APPROVED_LOWERCASE,
} from 'constant/constant'
import { SEARCH_EMPLOYEE, RESIGN_TITLE_LOWERCASE, TERMINATE_TITLE_LOWERCASE } from './constant'
import { CommonInput } from '@commonComponents/input'
import SearchFilter from '@commonComponents/SearchFilter'
import { SearchOutlined } from '@ant-design/icons'

interface ClearanceFormProps {
  setQueryParam: (data: object) => void;
}
const SearchClearance = observer((props: ClearanceFormProps) => {

  return (
    <div className='mb-1'>
      <SearchFilter
        {...props}
        children={
          <div className='w-100 input-dark-theme commonSelect-dark-theme' >
            <Row gutter={4} className={`rowDiv gap-lg-0  gap-xl-0 gap-xxl-2 flex-grow-1`}>
              <Col xs={24} sm={24} md={8} lg={6}>
                <Form.Item name='name'>
                  <CommonInput
                    placeholder={SEARCH_EMPLOYEE}
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name='reason_to_leave'
                >
                  <CommonSelect
                    placeholder={REASON_LEAVE}
                    listHeight={120}
                    allowClear
                    size={LARGE}
                    getPopupContainer={trigger => trigger.parentNode}
                    data={[{ key: RESIGN_TITLE_LOWERCASE, value: 'Resigned' },
                      { key: TERMINATE_TITLE_LOWERCASE, value: 'Terminated' }]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name='clearance_status'
                >
                  <CommonSelect
                    placeholder={STATUS_TITLE}
                    listHeight={120}
                    size={LARGE}
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    data={[
                      { key: PENDING_LOWERCASE, value: PENDING },
                      { key: APPROVED_LOWERCASE, value: APPROVED },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>} />
    </div>
  )
})
export default SearchClearance
