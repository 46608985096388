import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Card, Upload, Select } from 'antd'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import PhoneInput from 'react-phone-input-2'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { CommonSelect } from '@commonComponents/select'
import { StyleCommonInput } from '@mainPage/userResignation/resignationapplyForm'
import { isValidEmail } from '@utils/common-functions'
import { colors } from 'constant/colors'
import { validateRequired } from '@utils/common-functions/formValidations'

const { TealishBlue } = colors

const { Option } = Select
const { Dragger } = Upload
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const OnboardingForm = observer((props: any) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState(null);
  const [emailError, setEmailError] = useState(false)
  const [country, setCountry] = useState('')
  const [message, setMessage] = useState('')
  const [deleteList, setDeleteList] = useState(null)

  const {
    employeeDetails: {
      errorMessage,
    },
  } = useStore(null)

  useEffect(() => {
    if (errorMessage == 'employee with this email already exists.') {
      setEmailError(true)
    }
  }, [errorMessage])
  const {
    departmentModal: { getDepartmentData, loadDepartment },
    onboardingModal: { setOnboardingData, updateOnBoarding },
    designationModal: { loadDesignation, getDesignationData },
    employeeDetails: { loadAllEmployeeList, getEmployeeAllList }
  } = useStore(null)

  useEffect(() => {
    const load = async () => {
      await loadDepartment(1, 10, '', true)
      await loadDesignation(1, 10, '', true)
      await loadAllEmployeeList()
    }
    load()
  }, [])


  useEffect(() => {
    form.resetFields()
    if (props.data) {

      props?.data?.attachments && setFileList([{ name: `CV Attachment` }])
      form.setFieldsValue({
        first_name: props.data?.firstName,
        last_name: props.data?.lastName,
        is_intern: props.data?.isIntern,
        department: props.data?.departmentName,
        designation: props.data?.designationName,
        referred_to: props.data?.referredTo,
        contact_number: props.data?.contactNumber,
        email: props.data?.email,
        attachment: props.data.attachments,
      })
    }
  }, [props.data])

  const submitData = async values => {

    let response = null
    props.setBtnLoad(true)
    if (props.data) {
      setDeleteList(deleteList)
      response = await updateOnBoarding(props.data.id, values)

    }
    else {
      response = await setOnboardingData(values)
    }
    if (response?.success) {
      props?.setRefreshTable && props?.setRefreshTable(true)
      props.setVisible(false)
      form.resetFields()
    }
    props.setBtnLoad(false)
  }

  const fileProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      const allowedFormats = ['.jpg', '.pdf', '.png'];
      const fileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2); // Extract the file extension
      if (allowedFormats.includes('.' + fileExtension.toLowerCase())) {
        setFileList([file]);
        form.setFieldsValue({ attachment: file });
        form.validateFields(['attachment']);
        return false;
      } else {
        // Display an error message or notification to the user
        alert('Only .jpg, .pdf, and .png file formats are supported.');
      }

      return false;
    },
    fileList,
    capture: undefined,
  };
  const changeHandler = (country, a) => {
    setMessage('Phone number must be ' + a.format.split(' ').join('').length + ' character long')
    setCountry(country)
  }

  const designationdata = getDesignationData?.map(e => ({ key: e.id, value: e.title }))
  const departmentData = getDepartmentData?.map(e => ({ key: e.id, value: e.title }))
  const allEmployees = getEmployeeAllList?.map(value => ({ key: value.id, value: value.fullName }));

  return (
    <>
      <Form
        id='onboarding-form'
        form={form}
        onFinish={submitData}
        layout='vertical'
      >
        <Row>
          <Col xs={24} sm={11}
          >
            <Form.Item
              name={['first_name']}
              label='First Name'
              rules={[validateRequired('First Name')]}
            >
              <CommonInput
                placeholder='First Name' />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col xs={24} sm={11}>
            <Form.Item
              name={['last_name']}
              label='Last Name'
              rules={[validateRequired('Last Name')]}
            >
              <CommonInput
                placeholder='Last Name' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={11}>
            <Form.Item
              name={['is_intern']}
              label='Candidate Status'
              rules={[validateRequired('Candidate Status')]}
            >
              <DropWrapper placeholder='Select Status' >
                <Option value={true}>
                  Intern
                </Option>
                <Option value={false}>
                  Employee
                </Option>
              </DropWrapper>

            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col xs={24} sm={11}>
            <Form.Item
              label='Department'
              name={'department'}>
              <CommonSelect
                listHeight={125}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder='Please Select a Department'
                data={departmentData}
                showSearch
                filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={11} >
            <Form.Item name='designation' label='Designation'>
              <CommonSelect
                listHeight={125}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder='Please Select a Designation'
                data={designationdata}
                showSearch
                filterOption={(input, option) => option?.children?.toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>

          </Col>
          <Col span={1}></Col>
          <Col xs={24} sm={11} >
            <Form.Item
              name={['contact_number']}
              label='Phone'
              rules={[
                validateRequired('Phone Number'),
                { min: 11, max: 15, message: message }
              ]}
            >
              <PhoneInput
                country={'pk'}
                value={country}
                onChange={changeHandler}
                placeholder={'+92 3XX-XXXXXXXX'}
                buttonStyle={{ borderWidth: '1px' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={11}>
            <Form.Item
              name={['email']}
              label='Email'
              rules={[
                validateRequired('Email'),
                { validator: isValidEmail }
              ]}
              validateTrigger={['onBlur']}
            >
              <CommonInput
                className={'inputField'}
                inputType='email'
                placeholder='example@gmail.com'
              />
            </Form.Item>
            {emailError ? <div style={{ color: 'red' }}>{errorMessage}</div> : null}
          </Col>
          <Col span={1}></Col>
          <Col xs={24} sm={11}>
            <Form.Item
              label='Lead'
              name={'referred_to_id'}>
              <CommonSelect
                listHeight={125}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder='Select Lead'
                data={allEmployees}
                showSearch
                filterOption={(input, option) => (option.children.toLowerCase().includes(input.toLowerCase()))} />
            </Form.Item>
          </Col>

        </Row>
        <Col xs={24} sm={24}>
          <Form.Item name='summery' label={'Summary'}>
            <StyleCommonInput inputType='textarea' />
          </Form.Item>
        </Col>
        <Row className='mb-3'>
          <Col xs={24} lg={12}>
            <Form.Item
              label='Upload Resume/CV'
              name='attachments'
              rules={[validateRequired('Attachments')]}
            >
              <Card className='upload-card' style={{ width: '700px' }}>
                <Dragger {...fileProps}>
                  <p className='ant-upload-text'>
                    Drop your Resume/CV here or  <a style={{ color: TealishBlue }}>browse</a>
                    <br /> Support .pdf, .png, .jpg
                  </p>
                </Dragger>
              </Card>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
})
export default OnboardingForm
const DropWrapper = styled(Select)`
width: 100%;
 .ant-select-selector {
    height: 45px !important;
    align-items:center;
    border-radius: 8px !important;
  }
`

export const StyleAttach = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  div{
    padding: 0 5px;
    span{
      visibility: hidden;
    }
    
    &:hover{
      background-color: whitesmoke;
      
      span{
        visibility: visible;
        color: #a59999;
        cursor: pointer;

        &:hover {
          color: black;
        }
      }
    }
  }
`