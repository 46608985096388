import React, { useRef, useState } from 'react'
import { Table } from '@commonComponents/table-v2'
import 'antd/dist/antd.min.css'
import { Tooltip } from 'antd'
import { eCanteenUrl } from '@api/const'
import 'antd/dist/antd.css'
import CustomButton from '@commonComponents/button'
import { Row, Col, Space, Form } from 'antd'
import { CommonInput } from '@commonComponents/input'
import { useRequest } from 'ahooks';
import { ecanteenApi } from '@api'
import DeletePopUp from '@commonComponents/delete-popup'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { permit } from '@utils/permission/permission'
import ShowOnPermission from '@commonComponents/permit'
import { ADD_METHOD, CANTEEN_MOD, DELETE, DELETE_METHOD, EDIT_TITLE, UPDATE_METHOD } from 'constant/constant'
import employeeName from '@utils/employee_name'
import { SearchOutlined } from '@ant-design/icons'
import { MdDelete, MdEdit } from 'react-icons/md'
import styled from 'styled-components'
import { StatusDropdown } from '@commonComponents/select'
import { colors } from 'constant/colors'

const { mediumGray } = colors


export interface EcanteenTable {
  refreshTable?: boolean;
  isUpdate?: boolean;
  setIsUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setPayloadModelVisible?: React.Dispatch<React.SetStateAction<boolean>>;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setData?: React.Dispatch<React.SetStateAction<any[]>>;
}

const EcanteenTable = observer(({ setPayloadModelVisible, setRefreshTable, refreshTable, setIsUpdate, setData }: EcanteenTable) => {
  const [queryParam, setQueryParam] = useState({})
  const tableRef = useRef(null);
  const [currentData, setCurrentData] = useState(null)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [isFilterData, setIsFilterData] = useState(false)
  const [searchForm] = Form.useForm()

  const { userInfo: { getcompany }, } = useStore(null)

  const { run } = useRequest(data => ecanteenApi.updateEcanteen(data, data.id), {
    refreshDeps: [],
    manual: true,
    onSuccess: () => {
      setRefreshTable(true)
    }
  })

  const updateOrderStatus = async (data, status) => {
    data.status = status;
    run(data)
  }

  const { run: runDelete } = useRequest(id => ecanteenApi.deleteEcanteen(id), {
    refreshDeps: [],
    manual: true,
    onSuccess: () => {
      setRefreshTable(true)
    }
  })

  async function submitFilterData(values) {
    if (values.employeeName || '') {
      setQueryParam({
        employee_name: values.employeeName || '',
      })
    }
  }

  const resetSearchForm = async () => {
    setIsFilterData(false)
    searchForm.resetFields()
    setQueryParam('')
  }

  const onDelete = async data => {
    setCurrentData({ id: data?.id })
    setVisibleDelete(true)
  }

  const deleteHandler = async id => {
    runDelete(id)
  }

  const onEdit = async data => {
    setData(data)
    setIsUpdate(true)
    setPayloadModelVisible(true)
  }
  const onValuesChange = e => {
    if (e?.employeeName) {
      setIsFilterData(true)
    } else {
      setIsFilterData(false)
    }
  }
  const isActionsExist = permit(getcompany, CANTEEN_MOD, UPDATE_METHOD) || permit(getcompany, CANTEEN_MOD, DELETE_METHOD)

  const columns = [
    ...([employeeName('setRecord', 'setVisible', null)]),
    {
      title: 'Order Items',
      dataIndex: 'totalItem',
      render: text => <p>{text ? text : '-'}</p>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: text => <p>{text ? text : '-'}</p>,
    },

    {
      title: 'Order Amount',
      dataIndex: 'orderAmount',
      render: text => <p>{text ? text : '-'}</p>,
    },
    {
      title: 'Remaining Amount',
      dataIndex: 'remainAmount',
      render: text => <p>{text ? text : '-'}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, row) => (
        <div className='d-flex align-items-center gap-1'>
          <StatusDropdown
            defaultValue={row.status}
            onChange={status => updateOrderStatus(row, status)}
            listHeight={120}
            size='large'
            value={text ? text : '-'}
          >
          </StatusDropdown>
        </div >
      ),
    },
    ...(isActionsExist ? [{
      title: <CenterdActionColum>Action</CenterdActionColum>,
      dataIndex: 'Action',
      render: (_, row) =>
        <CenterdActionColum>
          <ShowOnPermission method={UPDATE_METHOD} mod={CANTEEN_MOD}>
            <StyledAction>
              <Tooltip title={EDIT_TITLE}>
                <MdEdit onClick={() => onEdit(row)} />
              </Tooltip>
            </StyledAction>
          </ShowOnPermission>
          <ShowOnPermission mod={CANTEEN_MOD} method={DELETE_METHOD}>
            <StyledAction>
              <Tooltip title={DELETE}>
                <MdDelete onClick={() => onDelete(row)} />
              </Tooltip>
            </StyledAction>
          </ShowOnPermission>
        </CenterdActionColum>
    }] : [])
  ]

  return (
    <>
      <OrderForm>
        <ShowOnPermission mod={CANTEEN_MOD} method={ADD_METHOD}>
          <Form
            form={searchForm}
            onFinish={submitFilterData}
            onValuesChange={e => onValuesChange(e)}
            layout='vertical'>
            <Row gutter={16}>
              <Col xs={24} md={12} lg={6}>
                <Form.Item name='employeeName'>
                  <CommonInput
                    placeholder='Enter Employee Name'
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={18} className='mb-4'>
                <div className={`${'btn'} d-flex`}>
                  <Space size={'middle'} className='justify-content-end d-flex flex-grow-1'>
                    <CustomButton
                      type='primary'
                      htmlType='submit'
                      disabled={!isFilterData}
                      title='Search'
                      size='large'
                    ></CustomButton>
                    <CustomButton
                      htmlType='reset'
                      disabled={!isFilterData}
                      size='large'
                      title='Reset'
                      onClick={resetSearchForm}
                    ></CustomButton>
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>
        </ShowOnPermission>
      </OrderForm>
      <EconteenTable>
        <Table
          ref={tableRef}
          queryParam={queryParam}
          columns={columns}
          refreshTable={refreshTable}
          dataUrl={eCanteenUrl}
          setRefreshTable={setRefreshTable}
          className='table-responsive'
        />
      </EconteenTable>
      <DeletePopUp
        delete={() => {
          deleteHandler(currentData.id)
          setVisibleDelete(false)
        }}
        msgTitle='Delete Order'
        visibleDelete={visibleDelete}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
      />
    </>
  )
})

export default EcanteenTable

const StyledAction = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  &:hover {
   background-color: #dad8d8;
    cursor: pointer;
  }
  svg {
    width: 19px;
    height: 20px;
  }
`
const CenterdActionColum = styled.div`
   display: flex;
  align-items: center;
  justify-content: center;
`
const OrderForm = styled.div`
  
  .menuBtn {
        margin-left: 2px;

        @media (max-width: 990px) {
          margin: 0 auto 10px auto;
          padding: 20px;
        }
      }

      .breakfastInput {
        position: relative;

        .anticon-caret-down {
          position: absolute;
          top: 17px;
          right: 12px;
          width: 13px;
          height: 19px;
          color: #afafaf;
        }

        .ant-select {
          .ant-select-arrow {
            display: none;
          }
        }
      }

      .items {
        background: #ffffff;
        border: 1px solid ${mediumGray};
        border-radius: 6px;
        min-height: 86px;
        height: auto;
        margin: 10px 0 24px 0;
        position: relative;

        .anticon-plus {
          width: 26px;
          height: 26px;
          background: linear-gradient(89.25deg, #24d0ff 33.64%, #0eb7e8 82.49%);
          position: absolute;
          bottom: 8px;
          right: 8px;
          border-radius: 50%;
          color: #fff;
          display: grid;
          place-items: center;
          font-size: 24px;
          cursor: pointer;
        }
      }
      .customLable {
        font-size: 16px;
      } 
`
const EconteenTable = styled.div`
  
  tr {
    .ant-table-cell {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 20%;
    }

    .ant-table-cell:nth-child(1) {
      max-width: 200px;

      p {
        padding-left: 12px;
      }
    }

    .ant-table-cell:nth-child(4) {
      max-width: 200px;
    }

    .ant-table-cell:nth-child(2),
    .ant-table-cell:nth-child(3),
    .ant-table-cell:nth-child(5) {
      max-width: 150px;
    }

    .ant-table-cell:nth-child(6) {
      min-width: 170px;
      overflow: unset;
    }

    .ant-table-cell:nth-child(7) {
      .anticon-edit{
        color: $color-cyan-primary;
        padding-left: 15px;
        cursor: pointer;
      }

      .anticon-close {
        color: $color-cyan-primary;
        padding-left: 15px;
        cursor: pointer;
        color: #ff3737;
      }
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-pagination {
    .ant-pagination-total-text {
      @media (max-width: 640px) {
        display: block;
      }
    }
  }

`