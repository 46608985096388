import React, { useState, useRef, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { Table } from '@commonComponents/table-v2'
import {  approvalRemoteLeaveUrl } from '@api/const'
import CustomDrawer from '@commonComponents/drawer'
import { APPROVED_TEXT, REJECTED_TEXT,  LEAVE_DETAILS, 
  EDIT_LEAVE_APPLICATION, ADD_LEAVE_APPLICATION} from '../../const'
import LeaveApprovedPopup from '@commonComponents/leave_approved_popup'
import styled from 'styled-components'
import EmployeeLeaveForm from '@mainPage/EmployeeLeave/EmployeeLeave/EmployeeLeaveForm/employeeLeaveForm'
import { getLeaveApprovalColumns } from '@mainPage/EmployeeLeave/utils'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const EmployeeRemoteLeaveApprovalTable = observer(({ refreshTable, setRefreshTable, queryParam ,state,setState,checkedData,setCheckedData}: any) => {
  const [currentData, setCurrentData] = useState({ id: null, value: null, data: {leaveTypeName: '', leaveStartDate: '', leaveEndDate: '', employee: { firstName: '', lastName: '' }, numberOfDays: '', isFullLeave: '' } })
  const [statusLoader, setStatusLoader] = useState(false)
  const [visible, setVisible] = useState(false)
  const [viewDetail] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [rejectReason, setRejectReason] = useState('')
  const tableRef = useRef(null);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [singleCheck,setSingleCheck] = useState([]) as any;

  const {
    employeeRemoteLeaveModal: { loadEmployeeRemoteLeave , getEmployeeRemoteLeaveData, updateEmployeeRemoteLeaveStatus, updateEmployeeRemoteLeaveRejected },
  } = useStore(null)

  const approvelData = async ({ id }) => {
    const res = await updateEmployeeRemoteLeaveStatus(id)
    if (res.success) {
      setRefreshTable(true)
      setStatusLoader(false)
      setSingleCheck([])
      setCheckedData([])
      setIsCheckAll(false)
    }
  }

  useEffect(() => {
    queryParam?.status !== 'Approved' && queryParam?.status && loadEmployeeRemoteLeave(queryParam?.status ? `/?status=${queryParam?.status}&` : '/')
  }, [queryParam])
  
  useEffect(()=>{
    if (!queryParam?.status) {
      setSingleCheck([])
      setIsCheckAll(false)
    }
  },[queryParam])

  const rejectedData = async ({ id, value, data }) => {
    setVisibleDelete(true)
    setCurrentData({ id, value, data })
  }

  const APPROVEL_LEAVE = {
    [APPROVED_TEXT]: ({ id }) => approvelData({ id }),
    [REJECTED_TEXT]: ({ id, value, data }) => rejectedData({ id, value, data }),
  }

  const onStatusChange = async (id, value, data?) => {
    const upateValue = APPROVEL_LEAVE[value] || rejectedData
    upateValue({ id, value, data })
  }

  const approvedHandler = async () => {
    const res = await updateEmployeeRemoteLeaveRejected(currentData.id, {status: currentData.value, rejection_reason: rejectReason })
    if (res.success) {
      setRefreshTable(true)
      setStatusLoader(false)
      setVisibleDelete(false)
      setRejectReason('')
    }
  }

  const handleSingleCheck = (e, row, index) => {
    setSingleCheck(prevState => {
      const newCheck = [...prevState];
      newCheck[index] = e?.target?.checked;
      return newCheck
    });
    e?.target?.checked ?
      !checkedData.find(i => i?.id === row?.id) && setCheckedData(prevObjects => [...prevObjects, row]) : setCheckedData(prevArray => prevArray.filter(i => row?.id !== i?.id));
  };

  useEffect(() => {
    const id = checkedData.map(data=> data.id)
    const res = state == true ? onStatusChange(id, 'Approved') : null
    res && setState(false)
  }, [state])

  const handleCheckAll = event => {
    setIsCheckAll(event?.target?.checked)
    if(event?.target?.checked){
      getEmployeeRemoteLeaveData?.map((value, index) => {
        handleSingleCheck(event, value, index);
      })  
    }else{
      setCheckedData([])
      setSingleCheck([])
      setIsCheckAll(false)
    }
  }
  const columns = useMemo(() => getLeaveApprovalColumns(statusLoader , currentData , onStatusChange , true , false,null,null,handleSingleCheck,handleCheckAll,queryParam, isCheckAll),[refreshTable,isCheckAll,queryParam,getEmployeeRemoteLeaveData,singleCheck])

  return (
    <>
      <div className='tableWidth custom-Pagination'>
        <EmployeeApprovalTable
          ref={tableRef}
          dataUrl={`${approvalRemoteLeaveUrl}`}
          columns={columns}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={Object.keys(queryParam).length !== 0 && queryParam}
          loaderPosition={'center'}
        />
      </div>

      {/* Add EmployeeLeave Modal */}
      <CustomDrawer
        width={400}
        className='addDrawer'
        title={
          viewDetail
            ? LEAVE_DETAILS
            : isUpdate
              ? EDIT_LEAVE_APPLICATION
              : ADD_LEAVE_APPLICATION
        }
        visible={visible}
        setVisible={() => {
          setVisible(false)
          setIsUpdate(false)
          setCurrentData(null)
        }}
      >
        {visible && (
          <EmployeeLeaveForm
            update={isUpdate}
            data={currentData.id}
            viewDetail={viewDetail}
            setVisible={setVisible}
            leaveApproval={false}
            setRefreshTable={val => setRefreshTable(val)}
          />
        )}
      </CustomDrawer>
      <LeaveApprovedPopup
        approvedHandler={approvedHandler}
        msgTitle='Confirm Leave Status'
        visibleDelete={visibleDelete}
        okText='Reject'
        handleCancel={() => setVisibleDelete(false)}
        setRejectReason={e=>setRejectReason(e)}
      />
    </>
  )
})

export default EmployeeRemoteLeaveApprovalTable

const EmployeeApprovalTable = styled(Table)`
.ant-pagination-item a{
  justify-content:center !important;
}
.ant-select .ant-select-selector{
  justify-content: center;
    display: flex;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  justify-content: center;
    text-align: center;
    display: flex;
}
.ant-select .ant-select-selection-item {
  text-align: center;
  justify-content: center;
  display: flex !important;
  margin-top: 3px;
}
`