import { cast, flow, types } from 'mobx-state-tree'
import { assetAttributeApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'


export const assetAttributes = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
})

export const assetAttributeModel = types
  .model({
    data: types.maybeNull(types.array(assetAttributes)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextAss: types.optional(types.number, 1),
  })
  .views(self => ({
    get getAssetsData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
   
  }))

  .actions(self => {
    const setAssetAttributeData = flow(function* (data) {
      try {
        self.loading = true
        yield assetAttributeApi.setAssetAttribute(data)
        notification.success('Asset Attribute Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)        
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const getAssetAttribute = flow(function* (page=1, pageSize=20, queryParam='', resetList=false, saveInStore=true) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextAss = 1
        }
        const res = yield assetAttributeApi.getAssetAttribute(page , pageSize, queryParam)
        if (!res?.isCancel && saveInStore){
          self.data=cast([])
          self.data = cast([...toJS(self.data ?? []), ...res.items])
          self.count = res.count
          if (res.next) {
            self.nextAss = self.nextAss + 1
          } else {
            self.nextAss = 1
          }
        } else if(!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.success('Failed to Load Attributes')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteAssetAttribute = flow(function* (id) {
      try {
        self.loading = true
        yield assetAttributeApi.deleteAssetAttribute(id)
        notification.success('Attribute Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const updateAssets = flow(function* (data, id) {
      try {
        self.loading = true
        yield assetAttributeApi.updateAssetAttributes(data, id )
        notification.success('Asset Attribute Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
  
    return {
      getAssetAttribute,
      setAssetAttributeData,
      deleteAssetAttribute,
      updateAssets
    }
  })

export function initassetAttributeModel() {
  return assetAttributeModel.create({})
}
