/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import CustomButton from '@commonComponents/button'
import { CommonInput } from '@commonComponents/input'
import { Form, Col, Row, Space, Tooltip } from 'antd'
import CustomDrawer from '@commonComponents/drawer'
import { useStore } from '@stores/root-store'
import { Table } from '@commonComponents/table-v2'
import { assetAttributeUrl } from '@api/const'
import DeletePopUp from '@commonComponents/delete-popup'
import { ASSET_ATTRIBUTE_DELETE } from './const'
import { MdDelete, MdEdit } from 'react-icons/md'
import styled from 'styled-components'

const AssetAttributeForm = observer(props => {
  const [form] = Form.useForm()
  const [refreshTable, setRefreshTable] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [edit, setEdit] = useState(null)
  const [currId, setCurrId] = useState('')
  const [deleteText, setDeleteText] = useState('')
  const {
    assetAttributeModel: { setAssetAttributeData, deleteAssetAttribute, updateAssets, isLoading },
    themesModel: { getColorsData, loadColor },
  } = useStore(null)

  useEffect(() => {
    if (props.assetAttrVisible) {
      form.resetFields()
      setEdit('Add')

    }
  }, [props.assetAttrVisible])

  useEffect(() => {
    async function loadData() {
      !getColorsData &&  await loadColor()
    }
    loadData()
  }, [])

  const onDelete = async row => {
    setDeleteText(row?.name)
    setCurrentId(row.id)
    setVisibleDelete(true)
  }

  const deleteAssetAttributes = async () => {
    const res = await deleteAssetAttribute(currentId)
    if (res.success) {
      setRefreshTable(true)
      setVisibleDelete(false)
    }
  }

  const submitData = async values => {
    if (edit === 'Update') {
      const setIId = currId
      await updateAssets(values, setIId)
      form.resetFields()
      setRefreshTable(true)
      setEdit('Add')
    }
    else {
      await setAssetAttributeData(values)
      setRefreshTable(true)
      form.resetFields()
    }

  }


  const editForm = row => {
    form.setFieldsValue({
      title: row.title,
      value: row.value
    });
    setCurrId(row.id)
    setEdit('Update')
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      render: text => (
        <Tooltip title={text}>{text}</Tooltip>
      )
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: text => (
        <Tooltip title={text}>{text}</Tooltip>
      )
    },
    {
      title: 'Action',
      render: (text, row) => (
        <div className='d-flex accordingToTheme'>
        <div className='actionIcons'>
          <Tooltip title='Edit'>
            <MdEdit fill={getColorsData?.bodyColor} onClick={() => editForm(row)} />
          </Tooltip>
        </div>
        <div className='actionIcons'>
        <Tooltip title='Delete'>
           <MdDelete fill={getColorsData?.bodyColor} onClick={() => onDelete(row)} />
        </Tooltip>
        </div>
      </div>
      ),
    },

  ]


  return (
    <>
      <CustomDrawer
        title='Add Asset Attribute'
        className='addDrawer'
        visible={props.assetAttrVisible}
        setVisible={() => { props.setAssetAttrVisible(false)}}
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => props.setAssetAttrVisible(false)}
            />
            <CustomButton
              htmlType='submit'
              form='assetAttribute-form'
              type='primary'
              title={edit}
              loading={false}
              disabled={false}
              shape='round'
              onClick={() => props.setAssetAttrVisible(true)}
              className='addButton m-auto'
            />
          </Space>
        }
      >
        <Form
          form={form}
          layout='vertical'
          onFinish={submitData}
          id='assetAttribute-form'
        >
          <Row className='justify-content-between mt-2'>
            <Col span={11}>
              <Form.Item
                name='title'
                label='Name'
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <CommonInput
                  className='inputField'
                  inputType='text'
                  placeholder='Enter Name'
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                name='value'
                label='Value'
                rules={[{ required: true, message: 'Value is required' }]}
              >
                <CommonInput
                  className='inputField'
                  placeholder='Enter Attribute'
                  inputType='text'
                />
              </Form.Item>
            </Col>
          </Row>
          <DeletePopUp
            delete={deleteAssetAttributes}
            msgTitle={ASSET_ATTRIBUTE_DELETE}
            visibleDelete={visibleDelete}
            isLoading={isLoading}
            okText='Confirm'
            handleCancel={() => setVisibleDelete(false)}
            renderMsg={<span>Are you sure you want to delete <Styledelete>{deleteText}</Styledelete></span>}
          />
        </Form>

        <Table
          dataUrl={assetAttributeUrl}
          columns={columns}
          setRefreshTable={setRefreshTable}
          refreshTable={refreshTable}
        />


      </CustomDrawer>
    </>
  )
}
)

export default AssetAttributeForm;

const Styledelete = styled.span`
font-weight:bold;
`
