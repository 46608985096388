import { cast, flow, types } from 'mobx-state-tree'
import { employeeRoleApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'

export const roleDropdown = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
})


export const employeeRole = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  allowedMethod: types.frozen(),

})

export const employeeRoles = types
  .model({
    role: types.maybeNull(types.array(employeeRole)),
    permissions: types.frozen(),
    dropdownRole: types.maybeNull(types.array(roleDropdown)),
    loading: types.optional(types.boolean, false),
    nextRole: types.optional(types.number, 1),

  })
  .views(self => ({
    get getEmployeeRoleData() {
      return toJS(self.role)
    },
    get getEmployeeDropdownRoleData() {
      return toJS(self.dropdownRole)
    },
    get getDefaultPermissionList() {
      return toJS(self.permissions)
    },
    get isLoading() {
      return self.loading
    },

  }))
  .actions(self => {
    const setEmployeeRole = flow(function* (data) {
      try {
        self.loading = true
        yield employeeRoleApi.setEmployeeRole(data)
        notification.success('Role Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadEmployeeRole = flow(function* (resetList = false) {
      try {
        self.loading = true
        if (resetList) {
          self.role = cast([])
        }
        const res = yield employeeRoleApi.getEmployeeRole(1, 500)
        if (!res?.isCancel) {
          self.role = cast([...toJS(self.role ?? []), ...res.items])
        }
      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Role';
        notification.error(message);
      } finally {
        self.loading = false
      }
    })


    const loadEmployeeDropdownRole = flow(function* (resetList = false) {
      try {
        self.loading = true;
        if (resetList) {
          self.dropdownRole = cast([]);
        }
        const res = yield employeeRoleApi.getEmployeeDropdownRole(1, 400);
        if (!res?.isCancel) {
          const existingIds = self.dropdownRole.map(item => item.id);
          const filteredData = res.items.filter(item => !existingIds.includes(item.id));
          self.dropdownRole = cast([...toJS(self.dropdownRole ?? []), ...filteredData]);
        }
      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Role';
        notification.error(message);
      } finally {
        self.loading = false;
      }
    });
    

    const updateEmployeeRole = flow(function* (id, data) {
      try {
        self.loading = true
        yield employeeRoleApi.updateEmployeeRole(id, data)
        notification.success('Role Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteEmployeeRole = flow(function* (id) {
      try {
        self.loading = true
        yield employeeRoleApi.deleteEmployeeRole(id)
        notification.success('Role Deleted Successfully')
      } catch (error) {
        notification.error(error?.response?.data?.message)
      } finally {
        self.loading = false
      }
    })

    const loadDefaultPermissions = flow(function* () {
      try {
        self.loading = true
        const res = yield employeeRoleApi.getRoleDefaultPermissions()
        self.permissions = cast(res)
      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Default Permissions List';
        notification.error(message);
      } finally {
        self.loading = false
      }
    })
    return {
      setEmployeeRole,
      loadEmployeeRole,
      deleteEmployeeRole,
      updateEmployeeRole,
      loadEmployeeDropdownRole,
      loadDefaultPermissions
    }
  })

export function initEmployeeRole() {
  return employeeRoles.create({})
}
