import React, { useState, useRef } from 'react'
import { Col, Row, Space } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import { observer } from 'mobx-react'
import BranchForm from './branchForm'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import CustomButton from '@commonComponents/button'
import { Table } from '@commonComponents/table-v2'
import { branchUrl } from '@api/const'
import CustomDrawer from '@commonComponents/drawer'
import { EDIT_TITLE, DELETE, DELETE_BRANCH, DEFAULT_COLOR, BRANCH_MOD, PATCH_METHOD, DELETE_METHOD, POST_METHOD } from 'constant/constant'
import styled from 'styled-components'
import { MdDelete, MdEdit } from 'react-icons/md'
import DeletePopUp from '@commonComponents/delete-popup'
import { useStore } from '@stores/root-store'
import ShowOnPermission from '@commonComponents/permit'
import SearchBranch from './SearchBranch'

const Branch = observer(() => {
  const [isUpdate, setIsUpdate] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [deleteMessage, setDeleteMessage] = useState()
  const [visible, setVisible] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [queryParam, setQueryParam] = useState()
  const [page, setPage] = useState(1)
  const tableRef = useRef(null);
  const {
    branchModel: { deleteBranch, isLoading },
    themesModel: { getColorsData }

  } = useStore(null)


  const onEdit = data => {
    setCurrentData(data)
    setIsUpdate(true)
    setVisible(true)
  }
  const onAdd = () => {
    setCurrentData(null)
    setIsUpdate(false)
    setVisible(true)
  }

  const onDelete = data => {
    setCurrentData(data)
    setDeleteMessage(data?.title)
    setVisibleDelete(true)
  }
  const deleteBranchHandler = async () => {
    const response = await deleteBranch(currentData?.id)
    if (response.success) {
      setVisibleDelete(false)
      setCurrentData({})
      setRefreshTable(prev => !prev);
    }
    else {
      setVisibleDelete(false)

    }


  };

  const columns = [
    {
      title: 'Sr No',
      dataIndex: 'sr No',
      render: (text, row, index) => (
        <p style={{ margin: '0' }}>
          <Tooltip title={row.id} placement='topLeft'>
            {page > 1 ? 10 * (page - 1) + index + 1 : index + 1}
          </Tooltip>
        </p>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'title',
      render: text => (
        <Tooltip placement='topLeft' title={text}>
          <Pwrapper>{text}</Pwrapper>
        </Tooltip>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: text => (
        <Tooltip placement='topLeft' title={text}>
          <Pwrapper>{text}</Pwrapper>
        </Tooltip>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: text => (
        <Tooltip placement='topLeft' title={text}>
          <Pwrapper>{text}</Pwrapper>
        </Tooltip>
      )
    },
    {
      title: 'Country',
      dataIndex: 'country',
      render: text => (
        <Tooltip placement='topLeft' title={text}>
          <Pwrapper>{text || '-'}</Pwrapper>
        </Tooltip>
      )
    },
    {
      title: <CenteredAction>{'Action'}</CenteredAction>,
      render: (text, row) => (
        <CenteredAction>
          <ShowOnPermission mod={BRANCH_MOD} method={PATCH_METHOD}>
            <Actions onClick={() => onEdit(row)} >
              <Tooltip title={EDIT_TITLE} minLength={0}>
                <MdEdit fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
              </Tooltip>
            </Actions>
          </ShowOnPermission>
          <ShowOnPermission mod={BRANCH_MOD} method={DELETE_METHOD}>
            <Actions onClick={() => onDelete(row)} >
              <Tooltip title={DELETE} minLength={0}>
                <MdDelete fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
              </Tooltip>
            </Actions>
          </ShowOnPermission>
        </CenteredAction>
      ),
    },
  ]

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='d-flex'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Branch', link: constRoute.branch },
              ]}
              pageTitle={'Branch'}
            > </BreadCrumbs>
            <ShowOnPermission mod={BRANCH_MOD} method={POST_METHOD}>
              <CustomButton
                className={'Addbtn'}
                onClick={onAdd}
                title='Add Branch'
              />
            </ShowOnPermission>
          </div>
        </div>
        <SearchBranch setQueryParam={setQueryParam} />
        {/* /Page Header */}
        <CustomDrawer
          className='addDrawer'
          title={isUpdate ? 'Update Branch' : 'Add Branch'}
          visible={visible}
          setVisible={() => {
            setVisible(false)
          }}
          width={600}
          customFooter={
            <Space>
              <CustomButton
                htmlType='submit'
                type='primary'
                title={'Cancel'}
                className='cancelButton'
                onClick={() => setVisible(false)}
              />
              <CustomButton
                form='branch-form'
                htmlType='submit'
                type='primary'
                title={isUpdate ? 'Update' : 'Add'}
                className='addButton'
              />
            </Space>
          }
        >
          <BranchForm
            update={isUpdate}
            data={currentData}
            setVisible={setVisible}
            setCurrentData={data => setCurrentData(data)}
            setRefreshTable={setRefreshTable}

          />
        </CustomDrawer>
        <Row> <Col span={24}>
          <div className='table-responsive'>
            <div className='tableWidth custom-Pagination'>
              <StyledDiv >
                <Table
                  ref={tableRef}
                  dataUrl={branchUrl}
                  columns={columns}
                  queryParam={queryParam}
                  refreshTable={refreshTable}
                  setRefreshTable={setRefreshTable}
                  setPageNumber={data => setPage(data)}
                />
              </StyledDiv>
            </div>
          </div>
        </Col></Row>
      </div>

      <DeletePopUp
        delete={deleteBranchHandler}
        msgTitle={DELETE_BRANCH}
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText='Yes! Delete'
        handleCancel={() => setVisibleDelete(false)}
        msg={deleteMessage}
      />

    </div>
  )
})

export default Branch

const Pwrapper = styled.p`
  width: 132px;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Actions = styled.div`
  border-radius: 43px;
  width: 36px;
  height: 36px;
  padding: 8px;
  &:hover {
   background-color: #dad8d8;
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
  } 
`
const CenteredAction = styled.div`
 display:flex;

`

const StyledDiv = styled.div`
  tr {
    .ant-table-cell {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .ant-table-cell:nth-child(1) {
      max-width: 30px;
      p {
        padding-left: 11px;
      }
    }

    .ant-table-cell:nth-child(2),
    .ant-table-cell:nth-child(3),
    .ant-table-cell:nth-child(4) {
      max-width: 130px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ant-table-cell:nth-child(6) {
      max-width: 110px;
    }

    .ant-table-cell:nth-child(5) {
      max-width: 160px;
    }

    .ant-table-cell:nth-child(7) {
      max-width: 100px;
    }
  }

`