import { observer } from 'mobx-react'
import styled from 'styled-components'
import style from './style.module.scss'
import { colors } from 'constant/colors'
import GaugeChart from 'react-gauge-chart'
import { MdFilterAlt } from 'react-icons/md'
import { useStore } from '@stores/root-store'
import { FaTelegramPlane } from 'react-icons/fa'
import { DEFAULT_COLOR } from 'constant/constant'
import absent from '../../../assets/img/cross.svg'
import present from '../../../assets/img/tick.svg'
import React, { useEffect, useState } from 'react'
import { CommonInput } from '@commonComponents/input'
import {
  Card,
  Col,
  Row,
  DatePicker,
  Progress,
  Tooltip,
  Modal,
  Empty,
} from 'antd'
import {
  MdInfoOutline,
  MdOutlineAddTask,
  MdOutlineHowToReg,
  MdPersonAdd,
  MdPersonOff,
} from 'react-icons/md'

const { TealishBlue } = colors

const DashboardCards = observer(
  ({
    employeeAttendanceData,
    submitFilterData,
  }) => {
    const [textColor, setTextColor] = useState(null)
    const [activeCard, setActiveCard] = useState(null)
    const [showAttendanceModal, setShowAttendanceModal] = useState(false)
    const [themeColor, setThemeColor] = useState(null)
    const [blackTheme, setBlackTheme] = useState(null)
    const [showSearchInput, setShowSearchInput] = useState(true)
    const [searchTermPresent, setSearchTermPresent] = useState('')
    const [searchTermAbsent, setSearchTermAbsent] = useState('')
    const [searchTermLeave, setSearchTermLeave] = useState('')
    const [searchTermUpcoming, setSearchTermUpcoming] = useState('')
    const [searchTermRemote, setSearchTermRemote] = useState('')
    const [color, setColor] = useState(null)
    useEffect(() => {
      const bodycolor = JSON.parse(localStorage.getItem('colorTheme'))
      setColor(bodycolor?.bodyColor || TealishBlue)
    }, [])
    const handleSearchInputChange = (e, setSearchTerm) => {
      setSearchTerm(e.target.value)
    }
    const handleCardClick = (cardName: string) => {
      setActiveCard(cardName)
      setShowAttendanceModal(true)
    }

    const {
      themesModel: { getColorsData },
    } = useStore(null)

    const getGaugeColors = (percent: number) => {
      const colors = []
      const numLevels = 20
      for (let i = 0; i < numLevels; i++) {
        if (i <= percent * numLevels) {
          colors.push(themeColor)
        } else {
          colors.push(blackTheme)
        }
      }
      return colors
    }

    const clearSearchTerm = () => {
      ('')
    }

    useEffect(() => {
      const storedTheme = JSON.parse(localStorage.getItem('colorTheme'))
      setThemeColor(storedTheme ? storedTheme?.bodyColor : TealishBlue)
      setBlackTheme(storedTheme?.theme === 'dark' ? 'white' : 'black')
      setTextColor(storedTheme?.theme === 'dark' ? 'white' : 'rgb(66, 66, 66)')
    }, [])
    const bodyColor = getColorsData?.bodyColor || DEFAULT_COLOR
    return (
      <>
        <CardContainers>
          <div className={style.dashboard}>
            <div className={style.cardsContainer}>
              <Row gutter={[16, 16]} className={style.cardsRow}>
                <Col xs={24} sm={24} md={16} lg={16}>
                  <Row gutter={[16, 16]} style={{ height: '100%' }}>
                    <Col xs={24} sm={12} md={12} lg={8}>
                      <DashboardCardSelect>
                        <CardTitleSelect style={{ color: textColor }}>
                          Select Date
                          <Tooltip title={'Select Date'}>
                            <InfoIcon color={color} />
                          </Tooltip>
                        </CardTitleSelect>
                        <DatePicker
                          format={'YYYY-MM-DD'}
                          className='w-100 inputField'
                          getPopupContainer={triger => triger.parentElement}
                          onChange={submitFilterData}
                        />
                      </DashboardCardSelect>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8}>
                      <DashboardCard
                        onClick={() => {
                          handleCardClick('Present Employees')
                          setSearchTermPresent('')
                        }}
                      >
                        <PersonAdd fill={bodyColor} />
                        <CardTitle style={{ color: textColor }}>
                          Present Employees
                          <Tooltip title={'Explore Today Present Employee'}>
                            <InfoIcon color={color} />
                          </Tooltip>
                        </CardTitle>
                        <p>{employeeAttendanceData?.totalPresents}</p>
                      </DashboardCard>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                      <DashboardCard
                        onClick={() => {
                          handleCardClick('Absent Employees')
                          setSearchTermAbsent('')
                        }}
                      >
                        <PersonOff fill={bodyColor} />
                        <CardTitle style={{ color: textColor }}>
                          Absent Employees
                          <Tooltip title={'Explore Today Absent Employee'}>
                            <InfoIcon color={color} />
                          </Tooltip>
                        </CardTitle>
                        <p>{employeeAttendanceData?.totalAbsents}</p>
                      </DashboardCard>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                      <DashboardCard
                        onClick={() => {
                          handleCardClick('Employees on Leave')
                          setSearchTermLeave('')
                        }}
                      >
                        <OutlineHowToReg fill={bodyColor} />
                        <CardTitle style={{ color: textColor }}>
                          Employees on Leave
                          <Tooltip title={'Explore Today Leave Employee'}>
                            <InfoIcon color={color} />
                          </Tooltip>
                        </CardTitle>
                        <p>{employeeAttendanceData?.totalEmpOnLeave}</p>
                      </DashboardCard>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                      <DashboardCard
                        onClick={() => {
                          handleCardClick('Upcoming Leaves')
                          setSearchTermUpcoming('')
                        }}
                      >
                        <OutlineAddTask fill={bodyColor} />
                        <CardTitle style={{ color: textColor }}>
                          Upcoming Leaves
                          <Tooltip title={'Explore Upcoming Leaves'}>
                            <InfoIcon color={color} />
                          </Tooltip>
                        </CardTitle>
                        <p>{employeeAttendanceData?.totalUpcomingLeave}</p>
                      </DashboardCard>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                      <DashboardCard
                        onClick={() => {
                          handleCardClick('Remote Leaves')
                          setSearchTermRemote('')
                        }}
                      >
                        <BiLogotelegram fill={bodyColor} />
                        <CardTitle style={{ color: textColor }}>
                          Remote Leaves
                          <Tooltip title={'Explore Remote Leaves'}>
                            <InfoIcon color={color} />
                          </Tooltip>
                        </CardTitle>
                        <p>{employeeAttendanceData?.totalEmpRemoteLeave}</p>
                      </DashboardCard>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <GuageStyle>
                    <div>
                      <GaugeMeter
                        className={'ClassCustom'}
                        id='gauge-chart3'
                        nrOfLevels={20}
                        colors={getGaugeColors(
                          employeeAttendanceData?.presentPercentage > 0
                            ? employeeAttendanceData?.presentPercentage / 100
                            : 0
                        )}
                        arcWidth={0.36}
                        percent={
                          employeeAttendanceData?.presentPercentage > 0
                            ? employeeAttendanceData?.presentPercentage / 100
                            : 0
                        }
                        textColor={'black'}
                        hideText={true}
                        minWidth={300}
                        minHeight={146}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '70%',
                        margin: 'auto',
                      }}
                    >
                      <GuageFontSize>
                        <Tooltip title={`Present Employee`}>
                          {employeeAttendanceData?.totalPresents}
                        </Tooltip>
                      </GuageFontSize>
                      <GuageFontSize>
                        <Tooltip title={`Present Employee Percentage`}>
                          {Math.trunc(
                            employeeAttendanceData?.presentPercentage
                          )}
                          %
                        </Tooltip>
                      </GuageFontSize>
                      <GuageFontSize>
                        <Tooltip title={`Total Employee`}>
                          {employeeAttendanceData?.totalNumEmployees}
                        </Tooltip>
                      </GuageFontSize>
                    </div>
                    <div
                      style={{
                        width: '80%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: 'auto',
                        marginTop: '20px',
                      }}
                    >
                      <div style={{ width: '100%', textAlign: 'start' }}>
                        Check-in On Time
                        <Progress
                          strokeColor='green'
                          percent={Math.round(
                            employeeAttendanceData?.totalOntimeEmployees
                          )}
                        />
                      </div>
                      <div style={{ width: '100%', textAlign: 'start' }}>
                        Late Check-in
                        <Progress
                          strokeColor='red'
                          percent={Math.round(
                            employeeAttendanceData?.totalLateEmployees
                          )}
                        />
                      </div>
                    </div>
                  </GuageStyle>
                </Col>
              </Row>
            </div>
          </div>
        </CardContainers>
        <ModalStyle
          title={
            activeCard === 'Present Employees' ? (
              <>
                <CountText>Present Employees</CountText>
                <CountNumber>
                  Count: {employeeAttendanceData?.totalPresents}
                </CountNumber>
              </>
            ) : activeCard === 'Absent Employees' ? (
              <>
                <CountText>Absent Employees</CountText>
                <CountNumber>
                  Count: {employeeAttendanceData?.totalAbsents}
                </CountNumber>
              </>
            ) : activeCard === 'Employees on Leave' ? (
              <>
                <CountText>Employees on Leave</CountText>
                <CountNumber>
                  Count: {employeeAttendanceData?.totalEmpOnLeave}
                </CountNumber>
              </>
            ) : activeCard === 'Upcoming Leaves' ? (
              <>
                <CountText>Upcoming Leaves </CountText>
                <CountNumber>
                  {' '}
                  Count: {employeeAttendanceData?.totalUpcomingLeave}
                </CountNumber>
              </>
            ) : activeCard === 'Remote Leaves' ? (
              <>
                <CountText>Remote Work Leaves</CountText>
                <CountNumber>
                  Count: {employeeAttendanceData?.totalEmpRemoteLeave}
                </CountNumber>
              </>
            ) : null
          }
          visible={showAttendanceModal}
          onCancel={() => setShowAttendanceModal(false)}
          footer={null}
        >
          {activeCard === 'Present Employees' && (
            <>
              <FilterContainer>
                <FilterIcon
                  onClick={() => setShowSearchInput(!showSearchInput)}
                ></FilterIcon>
                {showSearchInput && (
                  <SearchInput
                    type='text'
                    placeholder='Search Employee by Name'
                    value={searchTermPresent}
                    onChange={(e: any) =>
                      handleSearchInputChange(e, setSearchTermPresent)
                    }
                  />
                )}
              </FilterContainer>
              <CardMain>
                <CardContainer>
                  {employeeAttendanceData?.presentEmployees
                    ?.filter(emp =>
                      emp?.fullName
                        ?.toLowerCase()
                        .includes(searchTermPresent.toLowerCase())
                    )
                    .map((emp, index) => (
                      <StyledCard key={index}>
                        <div
                          className='d-flex justify-content-between'
                          style={{ width: '100%' }}
                        >
                          <EmployeeInfo>
                            <Name>{emp?.fullName}</Name>
                            <Designation>{emp?.designationTitle}</Designation>
                          </EmployeeInfo>
                          <div
                            className='d-flex align-items-center'
                            style={{ gap: '5px', marginRight: '10px' }}
                          >
                            <Designation className='lh-3'>Status</Designation>
                            <img src={present} width={15} alt='absent_pic' />
                          </div>
                        </div>
                      </StyledCard>
                    ))}
                  {searchTermPresent !== '' &&
                    employeeAttendanceData?.presentEmployees?.filter(emp =>
                      emp?.fullName
                        ?.toLowerCase()
                        .includes(searchTermPresent.toLowerCase())
                    ).length === 0 && (
                      <NodataStyled>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </NodataStyled>
                    )}
                </CardContainer>
              </CardMain>
            </>
          )}
          {activeCard === 'Absent Employees' && (
            <>
              <FilterContainer>
                <FilterIcon
                  onClick={() => {
                    setShowSearchInput(!showSearchInput)
                    clearSearchTerm()
                  }}
                ></FilterIcon>

                {showSearchInput && (
                  <SearchInput
                    type='text'
                    placeholder='Search Employee by Name'
                    value={searchTermAbsent}
                    onChange={(e: any) =>
                      handleSearchInputChange(e, setSearchTermAbsent)
                    }
                  />
                )}
              </FilterContainer>
              <CardMain>
                <CardContainer>
                  {employeeAttendanceData?.absentsEmployees
                    ?.filter(emp =>
                      emp?.fullName
                        ?.toLowerCase()
                        .includes(searchTermAbsent.toLowerCase())
                    )
                    .map((emp, index) => (
                      <StyledCard key={index}>
                        <div
                          className='d-flex justify-content-between'
                          style={{ width: '100%' }}
                        >
                          <EmployeeInfo>
                            <Name>{emp?.fullName}</Name>
                            <Designation>{emp?.designationTitle}</Designation>
                          </EmployeeInfo>
                          <div
                            className='d-flex align-items-center'
                            style={{ gap: '5px', marginRight: '10px' }}
                          >
                            <Designation>Status</Designation>
                            <img src={absent} width={15} alt='absent_pic' />
                          </div>
                        </div>
                      </StyledCard>
                    ))}
                </CardContainer>
              </CardMain>
            </>
          )}

          {activeCard === 'Employees on Leave' && (
            <>
              <FilterContainer>
                <FilterIcon
                  onClick={() => setShowSearchInput(!showSearchInput)}
                ></FilterIcon>
                {showSearchInput && (
                  <SearchInput
                    type='text'
                    placeholder='Search Employee by Name'
                    value={searchTermLeave}
                    onChange={(e: any) =>
                      handleSearchInputChange(e, setSearchTermLeave)
                    }
                  />
                )}
              </FilterContainer>
              {employeeAttendanceData?.onLeaveEmp?.filter(emp =>
                emp?.fullName
                  ?.toLowerCase()
                  .includes(searchTermLeave.toLowerCase())
              ).length > 0 ? (
                <CardMain>
                  <CardContainer>
                    {employeeAttendanceData?.onLeaveEmp
                      ?.filter(emp =>
                        emp?.fullName
                          ?.toLowerCase()
                          .includes(searchTermLeave.toLowerCase())
                      )
                      .map(emp => {
                        const end_Date = emp?.endDate
                        const start_Date = emp?.startDate

                        const monthNames = [
                          'Jan',
                          'Feb',
                          'Mar',
                          'Apr',
                          'May',
                          'Jun',
                          'Jul',
                          'Aug',
                          'Sep',
                          'Oct',
                          'Nov',
                          'Dec',
                        ]
                        const startDate = new Date(start_Date)
                        const endDate = new Date(end_Date)
                        const startDay = startDate.getDate()
                        const endDay = endDate.getDate()
                        const startMonth = monthNames[startDate.getMonth()]
                        const endMonth = monthNames[endDate.getMonth()]
                        const emp_start_Date = `${startDay}-${startMonth}`
                        const emp_end_Date = `${endDay}-${endMonth}`

                        const isShortLeave = emp?.leaveType === 'Short Leave'
                        const startTime = isShortLeave
                          ? ` ${startDate.toLocaleTimeString('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}`
                          : ''
                        const endTime = isShortLeave
                          ? ` ${endDate.toLocaleTimeString('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}`
                          : ''
                        return (
                          <StyledCard key={emp?.id}>
                            <EmployeeInfo>
                              <NameAndDesignation>
                                <Name style={{ color: themeColor }}>
                                  {emp?.fullName}
                                </Name>
                                <EmpDesignation>{`(${emp?.designationTitle})`}</EmpDesignation>
                              </NameAndDesignation>
                              <InfoRow>
                                <span
                                  className='type-prop'
                                  style={{ fontWeight: 'Bold' }}
                                >
                                  Leave Type:{' '}
                                </span>
                                <div className='leave-type '>
                                  {emp?.leaveType
                                    ? emp?.leaveType
                                    : 'Remote Leave'}
                                </div>
                                <div className='border'></div>

                                <div className='leave-type'>
                                  <span
                                    className='type-prop'
                                    style={{ fontWeight: 'Bold' }}
                                  >
                                    Date:{' '}
                                  </span>
                                  <Tooltip
                                    title={
                                      isShortLeave
                                        ? `${emp_start_Date} ${startTime} - ${endTime}`
                                        : `${emp_start_Date} to ${emp_end_Date}`
                                    }
                                    zIndex={10000}
                                  >
                                    <span className='type-val date-val'>
                                      {isShortLeave
                                        ? `${emp_start_Date} ${startTime} - ${endTime}`
                                        : `${emp_start_Date} to ${emp_end_Date}`}
                                    </span>
                                  </Tooltip>
                                </div>
                              </InfoRow>

                              <InfoRow>
                                <div className='leave-type'>
                                  <span
                                    className='type-prop'
                                    style={{ fontWeight: 'Bold' }}
                                  >
                                    Leaves Taken:{' '}
                                  </span>
                                  <span className='type-val'>
                                    {emp?.leaveCount}
                                  </span>
                                </div>
                                <div className='border'></div>

                                <div className='leave-type'>
                                  <span
                                    className='type-prop'
                                    style={{ fontWeight: 'Bold' }}
                                  >
                                    Reason:{' '}
                                  </span>
                                  <Tooltip
                                    title={emp?.leaveReason}
                                    zIndex={10000}
                                  >
                                    <span className='type-val reason-val'>
                                      {emp?.leaveReason}
                                    </span>
                                  </Tooltip>
                                </div>
                              </InfoRow>
                            </EmployeeInfo>
                          </StyledCard>
                        )
                      })}
                  </CardContainer>
                </CardMain>
              ) : (
                <NodataStyled>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </NodataStyled>
              )}
            </>
          )}

          {activeCard === 'Upcoming Leaves' && (
            <>
              <FilterContainer>
                <FilterIcon
                  onClick={() => setShowSearchInput(!showSearchInput)}
                ></FilterIcon>
                {showSearchInput && (
                  <SearchInput
                    type='text'
                    placeholder='Search Employee by Name'
                    value={searchTermUpcoming}
                    onChange={e =>
                      handleSearchInputChange(e, setSearchTermUpcoming)
                    }
                  />
                )}
              </FilterContainer>
              {employeeAttendanceData?.upcomingLeave?.filter(emp =>
                emp?.fullName
                  ?.toLowerCase()
                  .includes(searchTermUpcoming.toLowerCase())
              ).length > 0 ? (
                <CardMain>
                  <CardContainer>
                    {employeeAttendanceData?.upcomingLeave
                      ?.filter(emp =>
                        emp?.fullName
                          ?.toLowerCase()
                          .includes(searchTermUpcoming.toLowerCase())
                      )
                      .map(emp => {
                        const end_Date = emp?.endDate
                        const start_Date = emp?.startDate

                        const monthNames = [
                          'Jan',
                          'Feb',
                          'Mar',
                          'Apr',
                          'May',
                          'Jun',
                          'Jul',
                          'Aug',
                          'Sep',
                          'Oct',
                          'Nov',
                          'Dec',
                        ]
                        const startDate = new Date(start_Date)
                        const startDay = startDate.getDate()
                        const startMonth = monthNames[startDate.getMonth()]
                        const emp_start_Date = `${startDay}-${startMonth}`

                        const endDate = new Date(end_Date)
                        const endDay = endDate.getDate()
                        const endMonth = monthNames[endDate.getMonth()]
                        const emp_end_Date = `${endDay}-${endMonth}`

                        return (
                          <StyledCard key={emp?.id}>
                            <EmployeeInfo>
                              <NameAndDesignation>
                                <Name style={{ color: themeColor }}>
                                  {emp?.fullName}
                                </Name>
                                <EmpDesignation>{`(${emp?.designationTitle})`}</EmpDesignation>
                              </NameAndDesignation>
                              <InfoRow>
                                <span
                                  className='type-prop'
                                  style={{ fontWeight: 'Bold' }}
                                >
                                  Leave Type:{' '}
                                </span>
                                <span className='leave-type'>
                                  {emp?.leaveType
                                    ? emp?.leaveType
                                    : 'Remote Leave'}
                                </span>
                                <div className='border'></div>

                                <div className='leave-type'>
                                  <span
                                    className='type-prop'
                                    style={{ fontWeight: 'Bold' }}
                                  >
                                    Date:{' '}
                                  </span>
                                  <Tooltip
                                    title={`${emp_start_Date} to ${emp_end_Date}`}
                                    zIndex={10000}
                                  >
                                    <span className='type-val reason-val'>
                                      {emp_start_Date} to {emp_end_Date}
                                    </span>
                                  </Tooltip>
                                </div>
                              </InfoRow>

                              <InfoRow>
                                <div className='leave-type'>
                                  <span
                                    className='type-prop'
                                    style={{ fontWeight: 'Bold' }}
                                  >
                                    Leaves Taken:{' '}
                                  </span>
                                  {emp?.leaveCount}
                                </div>
                                <div className='border'></div>

                                <div className='leave-type'>
                                  <span
                                    className='type-prop'
                                    style={{ fontWeight: 'Bold' }}
                                  >
                                    Reason:{' '}
                                  </span>
                                  <Tooltip
                                    title={emp?.leaveReason}
                                    zIndex={10000}
                                  >
                                    <span className='type-val reason-val'>
                                      {emp?.leaveReason}
                                    </span>
                                  </Tooltip>
                                </div>
                              </InfoRow>
                            </EmployeeInfo>
                          </StyledCard>
                        )
                      })}
                  </CardContainer>
                </CardMain>
              ) : (
                <NodataStyled>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </NodataStyled>
              )}
            </>
          )}

          {activeCard === 'Remote Leaves' && (
            <>
              <FilterContainer>
                <FilterIcon
                  onClick={() => setShowSearchInput(!showSearchInput)}
                ></FilterIcon>
                {showSearchInput && (
                  <SearchInput
                    type='text'
                    placeholder='Search Employee by Name'
                    value={searchTermRemote}
                    onChange={e =>
                      handleSearchInputChange(e, setSearchTermRemote)
                    }
                  />
                )}
              </FilterContainer>
              {employeeAttendanceData?.remoteLeaveEmp?.filter(emp =>
                emp?.fullName
                  ?.toLowerCase()
                  .includes(searchTermRemote.toLowerCase())
              ).length > 0 ? (
                <CardMain>
                  <CardContainer>
                    {employeeAttendanceData?.remoteLeaveEmp?.map(emp => {
                      const end_Date = emp?.endDate
                      const start_Date = emp?.startDate

                      const monthNames = [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                      ]
                      const startDate = new Date(start_Date)
                      const startDay = startDate.getDate()
                      const startMonth = monthNames[startDate.getMonth()]
                      const emp_start_Date = `${startDay}-${startMonth}`
                      const endDate = new Date(end_Date)
                      const endDay = endDate.getDate()
                      const endMonth = monthNames[endDate.getMonth()]
                      const emp_end_Date = `${endDay}-${endMonth}`
                      return (
                        <StyledCard>
                          <EmployeeInfo>
                            <NameAndDesignation>
                              <Name style={{ color: themeColor }}>
                                {emp?.fullName}
                              </Name>
                              <EmpDesignation>{`(${emp?.designationTitle})`}</EmpDesignation>
                            </NameAndDesignation>

                            <InfoRow>
                              <div className='leave-type'>
                                <span
                                  className='type-prop'
                                  style={{ fontWeight: 'Bold' }}
                                >
                                  Date:{' '}
                                </span>
                                <Tooltip
                                  title={`${emp_start_Date} to ${emp_end_Date}`}
                                  zIndex={10000}
                                >
                                  <span className='type-val '>
                                    {emp_start_Date} to {emp_end_Date}
                                  </span>
                                </Tooltip>
                              </div>
                            </InfoRow>
                            <InfoRow>
                              <div className='leave-type'>
                                <span
                                  className='type-prop'
                                  style={{ fontWeight: 'Bold' }}
                                >
                                  Reason:{' '}
                                </span>
                                <Tooltip
                                  title={emp?.leaveReason}
                                  zIndex={10000}
                                >
                                  <span className='type-val reason-remote'>
                                    {emp?.leaveReason}
                                  </span>
                                </Tooltip>
                              </div>
                            </InfoRow>
                          </EmployeeInfo>
                        </StyledCard>
                      )
                    })}
                  </CardContainer>
                </CardMain>
              ) : (
                <NodataStyled>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </NodataStyled>
              )}
            </>
          )}
        </ModalStyle>
      </>
    )
  }
)

export default DashboardCards

const CardContainers = styled.div`
  justify-content: space-between;
  height: auto;
  @media (max-width: 500px) {
    margin-top: 5rem;
  }
  @media (max-width: 404px) {
    margin-top: 6rem;
  }
`

const CardTitleSelect = styled.h4`
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  color: rgb(66, 66, 66);
  font-size: 16px;
`
const InfoIcon = styled(MdInfoOutline)`
  color: ${(props: { color: any }) => (props.color ? props.color : '')};
  font-size: 20px;
  margin-bottom: -4px;
  padding-left: 3px;
`
const DashboardCard = styled(Card)`
  height: 100%;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  }

  .content {
    position: relative;
    z-index: 1;
  }

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`

const DashboardCardSelect = styled(Card)`
  z-index: 999;
  height: 100%;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  }

  .content {
    position: relative;
    z-index: 1;
  }

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`
const PersonAdd = styled(MdPersonAdd)`
  color: ${(props: { color: any }) => (props.color ? props.color : '')};
  font-size: 50px;
`
const PersonOff = styled(MdPersonOff)`
  color: ${(props: { color: any }) => (props.color ? props.color : '')};
  font-size: 50px;
`
const OutlineHowToReg = styled(MdOutlineHowToReg)`
  color: ${(props: { color: any }) => (props.color ? props.color : '')};
  font-size: 55px;
`
const OutlineAddTask = styled(MdOutlineAddTask)`
  color: ${(props: { color: any }) => (props.color ? props.color : '')};
  font-size: 55px;
`
export const CardTitle = styled.h6`
  margin-bottom: 10px;
  gap: 4px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  font-size: 16px !important;
`
const GuageFontSize = styled.div`
  font-size: 12px;
`
const GuageStyle = styled(Card)`
  height: 100%;
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`

const GaugeMeter = styled(GaugeChart)`
  height: auto;
`
const BiLogotelegram = styled(FaTelegramPlane)`
  color: ${(props: { color: any }) => (props.color ? props.color : '')};
  font-size: 55px;
`
const CountText = styled.span`
  font-weight: bold;
  border-radius: 32px;
  padding: 5px;
`
const ModalStyle = styled(Modal)`
  .ant-modal-title {
    font-weight: bold !important;
  }
  border-top-left-radius: 20px;
  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-header {
    border-radius: 14px 14px 0 0;
  }
  .ant-modal-close-icon {
    margin-top: 11px;
    svg {
      width: 30px;
      height: 30px;
      border-radius: 32px;
      padding: 6px;
      color: black;
      border-color: rgba(0, 0.1, 0, 0.5) !important;
      transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      transition-delay: 0.2;
      &:hover {
        color: black;
        border-radius: 32px;
        box-shadow: 0px 0px 10px rgba(0, 0.1, 0, 0.5);
        transform: rotate(180deg);
      }
    }
  }
`
const CardMain = styled.div`
  max-height: 400px;
  border-radius: 12px !important;
  overflow-y: auto;
`

const CardContainer = styled.div`
  max-height: 100%;
  height: 400px;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: max-height 0.2s;

  &:hover {
    overflow-y: auto;
    max-height: 1000px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
`
const StyledCard = styled(Card)`
  width: 450px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  .ant-card-body {
    padding: 10px !important;
  }
`

const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 8px;
`

const NameAndDesignation = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
`
const InfoRow = styled.div`
  display: flex;
  width: 520px;
  gap: 10px;

  .reason-val {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    width: 200px;
  }

  .typereason-val {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    width: 125px;
  }

  .date-val {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    width: 190px;
  }
  .reason-remote {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    width: 360px;
  }
  .border {
    border: 1px solid #5d5e5f !important;
  }
`

const Name = styled.p`
  font-size: 14px;
  font-weight: bold;
`

const Designation = styled.p`
  font-size: 11px;
  margin-bottom: 0px !important;
`
const EmpDesignation = styled.p`
  font-size: 11px;
`
const NodataStyled = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CountNumber = styled.span`
  box-shadow: 0px 0px 5px rgba(0, 0, 0.2, 0.2);
  padding: 6px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
`
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
`
const FilterIcon = styled(MdFilterAlt)`
  position: absolute;
  cursor: pointer;
  right: 50px;
  cursor: pointer;
  font-size: 20px;
`
const SearchInput = styled(CommonInput)`
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 auto;
  display: block;
  border: 1px solid rgb(217, 217, 217);
  top: 0;
  height: 32px !important;
  width: 4000 !important;
`
