import { presentAbsent } from './Utils'

export const assetSubmissioncolumns = () => {
  return [
    {
      title: 'Employee Name',
      dataIndex: 'employee',
      render: (_text, row) => (
        <p>{row?.employee?.firstName + ' ' + row?.employee?.lastName || '-'}</p>
      ),
    },
    {
      title: 'Asset Name',
      dataIndex: 'asset_name',
      render: (_text, row) => <p>{row?.asset?.name || '-'}</p>,
    },
    {
      title: 'Submitted',
      dataIndex: 'submitted',
      render: (data, row) => (
        <>
          <div className='d-flex'>
            <img src={presentAbsent(data)} alt='present' />
            <p>{row?.submittedDate || '-'}</p>
          </div>
        </>
      ),
    },
    {
      title: 'Received',
      dataIndex: 'received',
      render: (data, row) => (
        <>
          <div className='d-flex'>
            <img src={presentAbsent(data)} alt='present' />
            <p>{row?.receivingDate || '-'}</p>
          </div>
        </>
      ),
    },
  ]
}
