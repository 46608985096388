/* eslint-disable */ 
import { flow, types } from 'mobx-state-tree'
import { employeeBankApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { errorHandling } from '@utils/common-functions'

export const bank = types.model({
  id: types.maybeNull(types.string),
  number: types.maybeNull(types.string),
  iban: types.maybeNull(types.string),
  title: types.maybeNull(types.string)
})

export const bankDetails = types
  .model({
    data: types.maybeNull(types.array(bank)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get bankData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const setBankDetail = flow(function* (data) {
      try {
        self.loading = true
        yield employeeBankApi.setBankDetails(data)
        notification.success('Bank Data Added Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to create bank detail')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadBankDetails = flow(function* (employee_id) {
      try {
        self.loading = true
        const res = yield employeeBankApi.getEmployeeBankDetails(employee_id)
        self.data = res
      } catch (error) {
        errorHandling(error,'Failed to Load Bank Details')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateBankDetails = flow(function* (id, data) {
      try {
        self.loading = true
        yield employeeBankApi.updateEmployeeBankDetails(
          id,
          data
        )
        notification.success(' Bank Updated Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to update bank detail')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteBankDetails = flow(function* (id) {
      try {
        self.loading = true
        yield employeeBankApi.deleteEmployeeBankDetails(id)
        notification.success(' Bank Deleted Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to delete bank detail')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadBankDetails,
      updateBankDetails,
      setBankDetail,
      deleteBankDetails,
    }
  })

export function initEmployeeBankDetails() {
  return bankDetails.create({})
}
