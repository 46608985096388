import { BaseApi } from '../baseApi'
import { assetAttributeUrl } from '../const/index'

class AssetAttributeApi extends BaseApi {
  setAssetAttribute = async data => {
    try {
      const response = await this.axios.post(assetAttributeUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getAssetAttribute = async (num ,pageSize, queryParam={}) => {
    try {
      let url = assetAttributeUrl
      url += `?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateAssetAttributes = async (data, id) => {
    try {
      const response = await this.axios.put(`${assetAttributeUrl+id}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteAssetAttribute = async id => {
    try {
      const response = await this.axios.delete(
        `${assetAttributeUrl}${id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default AssetAttributeApi


