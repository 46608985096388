import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { feedbackApi } from '../../api'
import { notification } from '@utils/notifications'

export const Answer = types.model({
  id: types.maybeNull(types.number),
  questionId: types.maybeNull(types.number),
  message: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
})

export const Question = types.model({
  question: types.maybeNull(types.string),
  questionId: types.maybeNull(types.string),
})

export const Feedback = types.model({
  endDate: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  questions: types.maybeNull(types.array(Question)),
  startDate: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
})


const Answers = types.model( {
  id: types.string,
  questionId: types.string,
  message: types.string,
  rating: types.string,
});

const FeedbackResponse = types.model( {
  feedbackId: types.string,
  forEmployee: types.string,
  note: types.string,
  avgRating: types.string,
  answers: types.array(Answers),
});


export const feedbackModal = types
  .model({
    data: types.maybeNull(Feedback),
    loading: types.maybeNull(types.boolean),
    nextFeed: types.optional(types.number, 1),
    count: types.maybeNull(types.number),
    feedbackData: types.maybeNull(types.maybe(FeedbackResponse))
  })
  .views(self => ({
    get getFeedback() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
   get getFeedbackData() {
      return toJS(self.feedbackData)
    }
  }))

  .actions(self => {
    const updateLeadToEmpFeedback = flow(function* (id, data) {
      try {
        self.loading = true
        yield feedbackApi.updateLeadToEmpFeedback(id, data)
        notification.success('Feedback Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadLeadSingleFeedBack = flow(function* () {
      try {
        self.loading = true
        const res = yield feedbackApi.loadLeadSingleFeedback()
        self.data = cast(res)
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          error.response.status == 400 ? null : notification.error(message)
        } else {
          notification.error('Failed to Load Feedback Question')
        } return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadLeadToEmpFeedBack = flow(function* (id) {
      try {
        self.loading = true
        const res = yield feedbackApi.getLeadToEmpFeedback(id)
        self.feedbackData = cast(res)
      } catch (error) {
        const message = error?.response?.data?.message
        self.feedbackData = null
        if (message) {
          notification.info(message)
        } else {
          notification.error('Failed to Load Feedback')
        } return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadLeadSingleFeedBack,
      updateLeadToEmpFeedback,
      loadLeadToEmpFeedBack,
    }
  })

export function initFeedbackModal() {
  return feedbackModal.create({})
}