import { flow, types, cast } from 'mobx-state-tree'
import { dashboardAttendanceApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'

export const timeLineData = types.model({
  duration:types.maybeNull(types.string),
  punch:types.maybeNull(types.string),
})


export const attendance = types.model({
  totalPresence: types.maybeNull(types.number),
  totalAbsence: types.maybeNull(types.number),
  leaves: types.maybeNull(types.number),
  late:types.maybeNull(types.number),
  onTime:types.maybeNull(types.maybeNull(types.number)),
  overTimeMonthly:types.maybeNull(types.number),
  results:types.maybeNull(types.array(timeLineData))
})

export const dashboardAttendanceModal = types
  .model({
    data: types.maybeNull(types.array(attendance)),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get getAttendenceOverview() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setAttandanceData = flow(function* (data) {
      try {
        self.loading = true
        yield dashboardAttendanceApi.setAttandance(data)
        notification.success('Attandance Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadAttandance = flow(function* (queryParam = '', resetList = true) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
        }
        const res = yield dashboardAttendanceApi.getAttendence(queryParam)
        self.data = cast([res] ?? [])
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load attendance')
        } return { success: false }
      } finally {
        self.loading = false
      }
    })
    const updateAttandance = flow(function* (data, id) {
      try {        
        const payload = {
          employee: data.employee,
          notice_period: data.notice_period,
          resign_submit_on: data.resign_submit_on,
          reason: data.reason,
          status: data.status
        }
        self.loading = true
        yield dashboardAttendanceApi.updateAttandance(payload, id)
        notification.success('attendance Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteAttandance = flow(function* (id) {
      try {
        self.loading = true
        yield dashboardAttendanceApi.deleteAttandance(id)
        notification.success('attendance Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setAttandanceData,
      loadAttandance,
      updateAttandance,
      deleteAttandance
    }
  })
export function initdashboardAttendanceModal() {
  return dashboardAttendanceModal.create({})
}