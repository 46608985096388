import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import {serialNumberUrl } from '../const/index'
class SerialNumberApi extends BaseApi {

  getSerialNumberApi = async (queryParam={}) => {
    try {
      const response = await this.axios.get(
        serialNumberUrl,
        {
          params: queryParam,
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  
  
}

export default SerialNumberApi
