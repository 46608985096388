import { getAuthorizationHeader, headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class ClientProfileApi extends BaseApi {
  setClientProfile = async data => {
    try {
      const response = await this.axios.post(`${baseUrl}client_profile/`, data, {
        headers: {
          'content-type':
            'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      })

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getClientProfile = async num => {
    try {
      const response = await this.axios.get(
        `${baseUrl}client_profile/?page=${num ? num : 1}`,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateClientProfile = async (data, recordId) => {
    try {
      const response = await this.axios.patch(
        `${baseUrl}client_profile/${recordId}/`,
        data,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteClientProfile = async (id: number) => {
    try {
      const response = await this.axios.delete(`${baseUrl}client/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ClientProfileApi
