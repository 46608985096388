import React, {  useState } from 'react'
import { Space } from 'antd'
import 'antd/dist/antd.css'
import { observer } from 'mobx-react'
import CustomButton from '@commonComponents/button'
import CustomDrawer from '@commonComponents/drawer'
import { APPLY_TERMINATION, TerminationApplyProps } from './constant'
import {PRIMARY,SUBMIT,ADD_TITLE,CANCEL_TITLE,ADD_DRAWER,CANCEL_BUTTON_CLASS,ADD_BUTTON_CLASS,} from 'constant/constant'
import TerminationForm from './terminationForm'

const ApplyTermination = observer(({ setRefreshTable }: TerminationApplyProps) => {

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  return (
    <>
      <div>
        <CustomButton
          onClick={() => setIsOpenDrawer(true)}
          title={APPLY_TERMINATION}
        />
      </div>
      <CustomDrawer
        className={ADD_DRAWER}
        title={APPLY_TERMINATION}
        visible={isOpenDrawer}
        setVisible={() => setIsOpenDrawer(false)}
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType={SUBMIT}
              type={PRIMARY}
              title={CANCEL_TITLE}
              className={CANCEL_BUTTON_CLASS}
              onClick={() => setIsOpenDrawer(false)}
            />
            <CustomButton
              form='termination-form'
              htmlType={SUBMIT}
              type={PRIMARY}
              title={ADD_TITLE}
              className={ADD_BUTTON_CLASS}
            />
          </Space>
        }
      >
        <TerminationForm setRefreshTable={setRefreshTable} setIsOpenDrawer={setIsOpenDrawer} />
      </CustomDrawer>
    </>
  )
})
export default ApplyTermination;
