import CustomButton from '@commonComponents/button'
import CustomDrawer from '@commonComponents/drawer'
import { Card, Col, Space } from 'antd'
import { humanize } from 'components/utils/string_formate'
import { observer } from 'mobx-react'
import { useState } from 'react'
import styled from 'styled-components'
import Addemployee from '../ProfileCards/myCard/Addemployee'
import DrawerBankCard from '../ProfileCards/myCard/Bank'
import Certification from '../ProfileCards/myCard/Certification'
import Education from '../ProfileCards/myCard/Education'
import Emergency from '../ProfileCards/myCard/Emergency'
import Experience from '../ProfileCards/myCard/Experience'
import Family from '../ProfileCards/myCard/Family'
import { useStore } from '@stores/root-store'

interface ProfileSideDrawer {
  isVisible: boolean;
  setIsvisible: React.Dispatch<React.SetStateAction<boolean>>;
  empId: string;
  setRefreshTable?: () => void;
}

const ProfileSideDrawer = observer(
  ({
    isVisible,
    empId,
    setIsvisible,
    setRefreshTable,
  }: 
  ProfileSideDrawer) => {
    const [accountActiveTab, setAccountActiveTab] = useState('Employee')
    const { themesModel: { getColorsData } } = useStore(null)

    const tabList2 = [
      {
        key: 'Employee',
        tab: <span>Employee Info</span>,
      },
      {
        key: 'Education',
        tab: <span>Education Information</span>,
      },
      {
        key: 'Bank',
        tab: <span>Bank Information</span>,
      },
      {
        key: 'Family',
        tab: <span>Family Information</span>,
      },
      {
        key: 'Emergency',
        tab: <span>Emergency Contact</span>,
      },
      {
        key: 'Experience',
        tab: <span>Experience Details</span>,
      },
      {
        key: 'Certification',
        tab: <span>Certification Details</span>,
      },
    ]

    const commonFooterTabs = ['Employee']

    const contentList2 = {
      Employee: (
        <Addemployee
          empId={empId}
          setRefreshTable={setRefreshTable}
        />
      ),
      Bank: (
        <DrawerBankCard empId={empId} closeDrawer={() => setIsvisible(false)} />
      ),
      Education: (
        <Education empId={empId} closeDrawer={() => setIsvisible(false)} />
      ),
      Family: (
        <Family empId={empId} closeDrawer={() => setIsvisible(false)} />
      ),
      Certification: (
        <Certification empId={empId} closeDrawer={() => setIsvisible(false)} />
      ),
      Experience: (
        <Experience empId={empId} closeDrawer={() => setIsvisible(false)} />
      ),
      Emergency: (
        <Emergency empId={empId} closeDrawer={() => setIsvisible(false)} />
      ),
    }

    return (
      <div>
        <CustomProfileDrawer
          className={`addDrawer`}
          open={isVisible}
          title='Update Employee'
          onClose={() => setIsvisible(false)}
          customFooter={
            commonFooterTabs.includes(accountActiveTab)
              ? [
                  <Alignbuttons>
                    <Space>
                      <Cancelbutton
                        title='Cancel'
                        htmlType='reset'
                        type='primary'
                        onClick={() => setIsvisible(false)}
                      />
                      <Addbutton
                        form='add-employee'
                        title={`Update ${humanize(accountActiveTab)}`}
                        htmlType='submit'
                        key='submit'
                        type='primary'
                      />
                    </Space>
                  </Alignbuttons>,
                ]
              : null
          }
        >
          <Tabpadding>
            <Col lg={24}>
              <div>
                    <Tabprofilecard
                      isDark={getColorsData?.theme === 'dark'}
                      className={`TabProfile`}
                      tabList={tabList2}
                      activeTabKey={accountActiveTab}
                      onTabChange={key => {
                        setAccountActiveTab(key)
                      }}
                    ></Tabprofilecard>
                    {contentList2[accountActiveTab]}
              </div>
            </Col>
          </Tabpadding>
        </CustomProfileDrawer>
      </div>
    )
  }
)

export default ProfileSideDrawer

const CustomProfileDrawer = styled(CustomDrawer)`
  .ant-drawer-footer .ant-btn {
    color: white !important;

    &:hover {
      color: white !important;
    }
  }
  .ant-drawer-body{
    padding: 0 0 0 0 !important;
  }

  .ant-tabs-tab {
    background: #fff;
    padding: 0 !important;

    & + .ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-nav {
      margin-bottom: 0 !important;
    }
    .ant-tabs-nav-wrap {
      margin-top: 0px !important;
      .ant-tabs-nav-list {
        .ant-tabs-ink-bar {
          width: 0px !important;
        }
      }
    }

    .ant-tabs-tab-btn {
      span {
        font-size: 14px;
        line-height: 21px;
        display: block;
        padding: 14px 17px;
        border-right: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
      }
    }
  }

  .ant-tabs-tab-active {
    background: white;
  }
`

const Alignbuttons = styled.div`
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
`

const Cancelbutton = styled(CustomButton)`
  border-radius: 9px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  min-width: 100px;
  background: transparent;
  border: 1px solid #00c1f9;
  border-radius: 5px;
  color: $color-cyan-primary;
  margin-right: 10px;
  margin: auto;
  font-weight: 500 !important;

  &:hover {
    box-shadow: unset !important;
    background: rgb(248, 248, 248);
    color: #00c0f9;
  }

  &:focus {
    background: unset;
    color: #00c0f9;
    box-shadow: unset !important;
  }
`

const Addbutton = styled(CustomButton)`
  border-radius: 5px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  min-width: 100px;
  font-weight: 500 !important;
`

const Tabpadding = styled.div`
  padding: 0 0 0 0px;
`

const Tabprofilecard = styled(Card)<{ isDark?: boolean }>`
  border: 0px !important;
  padding: 0 0 0 0 !important;
  background-color: ${props => props?.isDark ? '#1f1f1f' : '#fff'};

  .ant-card-head {
    padding: 0px 0px;
    border: 1px solid #e1e1e1;
    position: fixed;
    z-index: 999;
    background-color: #fff; 
    border-bottom: none;
    margin-bottom: 20px;
   
    width: -webkit-fill-available;

  }

  .ant-tabs-nav-wrap {
    padding-right: 10px;
  }

  .ant-tabs-ink-bar {
    background: transparent;
  }
  .ant-card-head-tabs{
    margin-bottom: 10px;
  }

  .ant-tabs-tab-btn {
    background-color: ${props => props?.isDark ? '#1f1f1f' : '#fff'};
    color: ${props => props?.isDark ? '#fff' : '#000'};
  }

  .anticon {
    color: ${props => props?.isDark ? '#fff' : 'inherit'};
  }
`;