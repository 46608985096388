export const colors = {
    white: 'white',
    black:'black',
    sootyBlack: '#141414',
    lightGray: '#f0f0f0',
    WhiteSmoke: '#f5f5f5',
    CyanBlue: '#1890ff',
    GhostWhite: '#fafafa',
    red: '#ff4d4f',
    mediumGray: '#d9d9d9',
    TealishBlue: '#2d71d8',
    TealishBlueLight: 'rgb(20, 135, 241, 0.2)',
    charcoal: '#525353',
    lightGray4: '#e3e3e3',
    darkGray: '#34444c',
    black2: '#010101',
    darkRed: '#ef0f0f',
    bernRed: '#EA0A0A',
    bernRedLight: 'rgba(234, 10, 10, 0.2)',
    green2: '#16d61e',
    green2Light: 'rgba(22, 214, 30,0.2)',
    lightGray5: '#C9C9C9',
    lightGray6: '#e2e2e2',
    navyBlue: '#286ace',
    lightGray7: '#f8f8f8',
    lightGray8: '#DDDDDD',
    lightGray9: '#E1E1E1',
    darkRed2: '#EF0F0F',
    violet: '#7460ee',
    indigo: '#3F51B5',
    amber: '#FFC107',
    deepOrange: '#FF5722',
    black3: '#000000',
    darkGray2: '#424242',
    lightBlue2: '#99e6ff',
    periwinkle: '#809fff',
    lightPink: '#edafbc',
    lightOrange: '#edb777',
    emerald: '#55CE63',
    orangeRed: '#f56a00',
    lightGray10: '#cec8c4',
    darkRed3: '#ff3737',
    darkGray3: '#333333',
    skyBlue2: '#00c0f9',
    slateGray: '#747979',
    darkOrange: '#ffbc34',
    lightGray11: '#dfeaf9',
    skyBlue3: '#00c1f9',
    darkGray4: '#1f1f1f',
    white2: '#fff',
    lightRed: '#fa9494',
    darkRed4: '#f42c2c',
    blue3: '#0958d9',
    lightGray12: '#eeeeee',
    babyBlue: '#89cff0',
    darkGray5: '#4c4c4c',
    lightGray13: '#ffec40',
    darkRed5: '#ff3737',
    lightGray14: '#f7f7f7',
    steelBlue: '#3778da',
    skyBlue4: '#00b7ed',
    lightGray15: '#e8e8e8',
    skyBlue5: '#00b7ed',
    darkGray6: '#606369',
    lightGray16: '#e8e8e8',
    forestGreen: '#369637',
    lightYellow: '#f3f8b6',
    mogwaYellow: '#DDC806',
    mogwaYellowLight: 'rgba(221, 200, 6,0.2)',
    salmon: '#f49eA0',
    lightCyan: '#C6FFFB',
    lightGreen: '#9ce9a0',
    darkRed6: '#f42c2c',
    lightPink2: '#f7f4fb',
    lightYellow2: '#feffce',
    mauvePink: '#e1b0ff',
    peach: '#f7b18f',
    willow: '#d7dea6',
    hamGreen: '#79bd8d',
    grassGreen: '#82a348',
    CadetBlue: '#50969A',
    VenetianRed: '#D6494D',
    Arsenic: '#3C4448',
    PaleGrey: '#DDFFEB80',
    TreeGreen:'#119617',
    DareRed:'#EF0F0F1A',
    TimerColor : '#0ed71e'
};
