import React from 'react'
import no_internet from '../assets/img/no_internet.png'
import styled from 'styled-components'

function NoInternet() {
  return (
    <NoInternetErrorWrapper>
      <img src={no_internet} width={250} height={250} />
      <h5>Connection disturbed!</h5>
      <h3>Make Sure your Device has an active internet connection...</h3>
    </NoInternetErrorWrapper>
  )
}

export default NoInternet

const NoInternetErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  h3 {
    font-size: 1.4rem;
    width: 40%;
    text-align: center;
  }

  h5{
    font-weight: bold;
  }
`
