import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { categoryUrl } from '../const/index'

class HRCategoriesApi extends BaseApi {
  setCategory = async data => {
    try {
      const response = await this.axios.post(`${categoryUrl}`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getCategory = async (num ,pageSize, queryParam={}) => {
    try {
     const url = `${categoryUrl}?page=${num ? num :1}&size=${pageSize}`
      const response = await this.axios.get(
        url,{params: queryParam ? {...queryParam} : {}}
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }


  updateCategory = async (id, data) => {
    try {
      const response = await this.axios.put(`${categoryUrl}${id}`, data, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteCategory = async id => {
    try {
      const response = await this.axios.delete(`${categoryUrl}${id}`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default HRCategoriesApi
