import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Checkbox, Col, DatePicker, Form, Row } from 'antd'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import moment from 'moment'
import { formatDateValue, isCurrentUsersId } from '@utils/common-functions'
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader'
import CustomButton from '@commonComponents/button'
import { MdAddCircleOutline, MdClose } from 'react-icons/md'
import { ISO_DATE_FORMAT } from '@mainPage/constant'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { colors } from 'constant/colors'

const { TealishBlue, black, lightGray } = colors

interface ExperienceData {
  position: string;
  company: string;
  address: string;
  from_date: string;
  to_date?: string;
  present?: boolean;
}

interface ExperienceInfo {
  id?: string;
  position: string;
  company: string;
  address: string;
  fromDate: string;
  toDate?: string;
  present?: boolean;
}

interface Experience {
  empId?: string;
  closeDrawer: () => void;
}

interface Experienceform {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: any;
  data?: ExperienceInfo;
  method: string;
  formId?: string;
  closeDrawer: () => void;
  empId: string;
}

const Experience = observer(({ empId, closeDrawer }: Experience) => {
  const [showForm, setShowForm] = useState(false)
  const getRef = useRef(null)
  const canModify = isCurrentUsersId(empId)

  const {
    experienceDetails: {
      updateExperienceDetails,
      experienceData,
      loadExperienceDetails,
      setExperienceDetails,
      deleteExperienceDetails,
      isLoading,
    },
    themesModel: { getColorsData },
  } = useStore(null)
  const isDarkTheme = getColorsData?.theme === 'dark'

  const load = async () => {
    await loadExperienceDetails(empId)
  }

  useEffect(() => {
    if (!experienceData?.length && !showForm) {
      setShowForm(true)
    }
  }, [experienceData, showForm])

  useEffect(() => {
    if (!experienceData?.length) {
      load()
    }
  }, [empId])

  const addForm = () => {
    setShowForm(true)
    setTimeout(() => {
      getRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      })
    }, 500)
  }

  const onSubmit = async (
    data: ExperienceData,
    presentState: boolean,
    id: string
  ) => {
    formatDateValue(data, ISO_DATE_FORMAT)
    if (presentState === true) {
      data.present = true
      delete data.to_date
    } else {
      data.present = false
    }

    const res = await updateExperienceDetails(id, data)
    if (res?.success) {
      load()
    }
  }

  const onCreate = async (data: ExperienceData, presentState: boolean) => {
    formatDateValue(data, ISO_DATE_FORMAT)
    data['employee_id'] = empId
    if (presentState) {
      data.present = true
      delete data.to_date
    } else {
      data.present = false
    }

    const res = await setExperienceDetails(data)
    if (res?.success) {
      // remove the local form after the bank details has been created and refetch the data
      setShowForm(false)
      load()
    }
  }

  if (!experienceData?.length && isLoading) {
    return <SkeletonRingLoader width='100%' height='50px' align='center' />
  }

  const deleteExperience = async (id: string) => {
    const res = await deleteExperienceDetails(id)
    if (res?.success) {
      load()
    }
  }

  return (
    <Wrapper isDarkTheme={isDarkTheme}>
      <div className={'addMore'}>
        <AddButton onClick={addForm} isDarkTheme={isDarkTheme} disabled={!canModify}>
          <MdAddCircleOutline
            fill={getColorsData?.bodyColor || 'blue'}
            className='add-icon'
          />
        </AddButton>
      </div>
      <Experiencestyle ref={getRef}>
        {/* Mapping over fetched bank details to render a form for each one to update it */}
        {experienceData?.map((data, index) => (
          <Fragment key={data.id}>
            <div
              className='d-flex align-items-center justify-content-between mb-1 mt-3 details'
              style={{ gap: '16px' }}
            >
              <h5 className='titleName mt-2'>
                Experience Detail {index + 1}
              </h5>
              <div className='separator' />
              <button
                type='button'
                className='deleteBtn d-flex align-items-center'
                onClick={() => deleteExperience(data.id)}
                disabled={!canModify}
              >
                <MdClose className='deleteIcon' />
              </button>
            </div>
            <ExperienceForm
              data={data}
              onSubmit={(formData, checked) =>
                onSubmit(formData, checked, data?.id)
              }
              method='Update'
              closeDrawer={closeDrawer}
              empId={empId}
            />
          </Fragment>
        ))}

        {showForm && (
          <>
            <div
              className='d-flex align-items-center mb-1 mt-3 details'
              style={{ gap: '16px' }}
            >
              <h5 className='titleName mt-2'>
                Experience Detail {(experienceData?.length || 0) + 1}
              </h5>
              <div className='separator' />
              <button
                type='button'
                className='deleteBtn d-flex align-items-center'
                onClick={() => setShowForm(false)}
                disabled={!canModify}
              >
                <MdClose className='deleteIcon' />
              </button>
            </div>
            <ExperienceForm
              formId='add-experience-detail'
              onSubmit={(formData, checked) => onCreate(formData, checked)}
              method='Add'
              closeDrawer={closeDrawer}
              empId={empId}
            />
          </>
        )}
      </Experiencestyle>
    </Wrapper>
  )
})

export default Experience

const ExperienceForm = observer(
  ({ data, onSubmit, method, formId, closeDrawer, empId }: Experienceform) => {
    const [form] = Form.useForm()
    const [checked, setChecked] = useState(false)
    const {
      themesModel: { getColorsData },
    } = useStore(null)
    const buttonColor = getColorsData?.bodyColor
    const canModify = isCurrentUsersId(empId)

    const setInitialFields = () => {
      form.setFieldsValue({
        position: data?.position || '',
        company: data?.company || '',
        address: data?.address || '',
        from_date: data?.fromDate ? moment(data?.fromDate, ISO_DATE_FORMAT) : '',
        to_date: data?.toDate ? moment(data?.toDate, ISO_DATE_FORMAT) : '',
        present: data?.toDate ? false : data?.present,
      })
      setChecked(data?.present)
    }

    useEffect(() => {
      setInitialFields()
    }, [data])

    const onCheckboxChange = (boxChecked: boolean) => {
      setChecked(boxChecked)
      setChecked(boxChecked);
      if (boxChecked === true) {
        form.setFieldValue('to_date', '')
        form.setFieldValue('to_date', '');
        form.setFields([
          {
            name: ['to_date'],
            errors: [],
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['to_date'],
            errors: [],
          },
        ]);
      }
    }


    return (
      <Form
        layout='vertical'
        id={data?.id || formId}
        onFinish={formData => onSubmit(formData, checked)}
        form={form}
        disabled={!canModify}
      >
        <Labelalign gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['position']}
              label='Job Position'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Job Position'),
                validateNoWhiteSpace(),
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['company']}
              label='Company'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Company'),
                validateNoWhiteSpace()
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['address']}
              label='Address'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Address'),
                validateNoWhiteSpace(),
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['from_date']}
              label='Period (From)'
              style={{
                position: 'relative',
              }}
              rules={[validateRequired('From Date')]}
            >
              <DatePicker
                format={ISO_DATE_FORMAT}
                getPopupContainer={trigger => trigger.parentElement}
                className='form-control'
              />
            </Form.Item>
          </Col>
        </Labelalign>
        <Labelalign gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['to_date']}
              label='To Date'
              style={{
                position: 'relative',
              }}
              rules={[checked ? {} : validateRequired('To Date')]}
            >
              <DatePicker
                format={ISO_DATE_FORMAT}
                getPopupContainer={trigger => trigger.parentElement}
                className='form-control'
                disabled={!canModify || checked}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} className='d-flex align-items-end'>
            <Form.Item>
              <Checkbox
                checked={checked}
                onChange={e => onCheckboxChange(e.target.checked)}
                style={{color: getColorsData?.theme === 'dark' ? '#fff' : '#000'}}
              >
                Present
              </Checkbox>
            </Form.Item>
          </Col>

          <Aligncontent>
            <Styledbutton
              title='Cancel'
              htmlType='button'
              size='small'
              type='primary'
              onClick={closeDrawer}
              style={{ backgroundColor: buttonColor }}
            />
            <Styledbutton
              form={data?.id || formId}
              title={`${method} Detail`}
              htmlType='submit'
              size='small'
              key='submit'
              type='primary'
              style={{ backgroundColor: buttonColor }}
            />
          </Aligncontent>
        </Labelalign>
      </Form>
    )
  }
)

const Experiencestyle = styled.div`
  margin-top: 20px;
`

const AddButton = styled.button<{isDarkTheme?: boolean}>`
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props?.isDarkTheme ? '#000' : ''};

  .add-icon {
    width: 20px;
    height: 20px;
  }
`

const Labelalign = styled(Row)`
  padding: 0 16px 0 16px !important;
  .ant-form-item-label {
    padding: 0 3px 3px !important;
  }
`

const Aligncontent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
`

const Styledbutton = styled(CustomButton)`
  border-radius: 5px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  min-width: 100px;
  font-weight: 500 !important;
  padding: 16px;

  &:hover {
    color: white !important;
    background-color: blue;
  }

  &:focus {
    background-color: blue;
  }
`

const Wrapper = styled.div<{ isDarkTheme?: boolean }>`
  padding: 12px 0;
  .addMore {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin: -23px 0 20px 0;
    z-index: 12;
    position: relative;
    img {
      cursor: pointer;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: ${TealishBlue};
      word-spacing: 0px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
  .separator {
    height: .5px;
    flex-grow: 1;
    background-color: ${props => props?.isDarkTheme ? '#fff' : '#1f1f1f'};
  }
  .details {
    padding: 0 16px 0 16px;
  }
  .titleName {
    color: ${props => props?.isDarkTheme ? '#fff' : '#000'};
    white-space: nowrap;
  }
  .deleteBtn {
    outline: none;
    border: none;
    background-color: ${props => props?.isDarkTheme ? black : lightGray};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;

    :hover {
      background-color: #fa9494;
    }
  }

  .deleteIcon {
    width: 18px;
    height: 18px;
    fill: #f42c2c;
  }
`
