import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import { observer } from 'mobx-react'
import { imgBaseUrl } from '@api/const'
import MenuIcon from '@commonComponents/menu-icon'
import { constRoute } from '@utils/route'


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const ProjectCard = observer((props: any) => {
  return (
    <div className='col-lg-4 col-sm-6 col-md-4 col-xl-3'>
      <div className='card'>
        <div className='card-body project-card'>
          <div className='project-header'>
            <h4 className='project-title'>
              <Link to={`${constRoute.projectView}/${props.data.id}/${props.pageNumber}`}>
                {props.data.name}
              </Link>
            </h4>
            {props.dropdownVisible && (
              <MenuIcon
                data={props.data}
                edit={dataa => props.editForm(dataa)}
                delete={id => props.onDelete(id)}
              />
            )}
          </div>

          <small className='block text-ellipsis m-b-15'>
            <span className='text-xs'>5</span>{' '}
            <span className='text-muted'>open tasks, </span>
            <span className='text-xs'>12</span>{' '}
            <span className='text-muted'>tasks completed</span>
          </small>
          <p className='text-muted'>
            {props.data.description?.length > 200
              ? props.data.description.substring(0, 200) + '...'
              : props.data.description}
          </p>
          <div className='pro-deadline m-b-15'>
            <div className='sub-title'>Priority:</div>
            <div className='text-muted'>{props.data.priority}</div>
          </div>
          <div className='project-members m-b-15'>
            <div>Project Leader :</div>
            <ul className='team-members'>
              {props.data.projectLeader?.map(e => {
                return (
                  <li>
                    <Tooltip title={e.firstName}>
                      <Avatar
                        className='profile-img'
                        size={28}
                        alt='project_leader'
                        src={imgBaseUrl + e.employeeImage}
                      />
                    </Tooltip>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className='project-members m-b-15'>
            <div>Team :</div>
            <ul className='team-members'>
              {props.data.projectTeam?.map(itm => {
                return (
                  <li>
                    <Tooltip title={itm.firstName}>
                      <Avatar
                        size={28}
                        className='profile-img'
                        alt=''
                        src={imgBaseUrl + itm.employeeImage}
                      />
                    </Tooltip>
                  </li>
                )
              })}
            </ul>
          </div>
          <p className='m-b-5'>
            Progress <span className='text-success float-end'>50%</span>
          </p>
          <div className='progress progress-xs mb-0'>
            <div
              className='progress-bar bg-success'
              role='progressbar'
              data-bs-toggle='tooltip'
              title='40%'
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default ProjectCard
