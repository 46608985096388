import React, { useEffect } from 'react'
import { Form, Row, Col, DatePicker, TimePicker, } from 'antd'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import TextArea from 'antd/lib/input/TextArea'
import styled from 'styled-components'
import moment from 'moment'
import { CommonSelect } from '@commonComponents/select'


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const OnboardingForm = observer((props: any) => {
  const [form] = Form.useForm()
  const {
    onboardingModal: { updateOnBoarding,updateOnBoardingLead },
  } = useStore(null)


  useEffect(() => {
    form.resetFields()
    if (props.data) {

      form.setFieldsValue({
        candidate_name: props.data?.firstName || '',
      })
    }
  }, [])

  const submitData = async values => {
    let response = null;
    props.setBtnLoad(true);
   

    if (props?.approve) {
      const fields = {
        joining_date: moment(values?.joining_date).format('YYYY-MM-DD'),
        summery: values?.remarks,
        status: values?.status
      };
      response = await updateOnBoarding(props.data.id, fields);
    } else {
      const date = moment(values?.interview_date).format('YYYY-MM-DD');
      const time = moment(values?.interview_datetime).format('hh:mm:ss');      
      delete values?.interview_date;
      delete values?.interview_datetime;
      delete values?.candidate_name;
      const data = {
        ...values,
        interview_datetime: `${date} ${time}`,
        status: 'approved'
      };
      response = await updateOnBoardingLead(props.data.id, data);
    }
  
    if (response?.success) {
      props?.setRefreshTable && props?.setRefreshTable(true);
      props.setVisible(false);
      form.resetFields();
    }
    props.setBtnLoad(false);
  };
  
  return (
    <>
      <Form
        id='LeadDrawer'
        form={form}
        onFinish={submitData}
        layout='vertical'
      >
        {!props?.approve ? (
          <>
            <Row>
              <Col xs={24} sm={24} >
                <Form.Item
                  name='candidate_name'
                  label='Candidate Name'
                  style={{
                    position: 'relative',
                  }}
                >
                  <CommonInput
                    disabled={true}
                    placeholder='Enter Candidate Name'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24}>
                <Form.Item
                  name='interview_date'
                  label='Interview  Date'
                  rules={[{ required: true, message: 'Select date!' }]}
                  style={{
                    position: 'relative',
                  }}
                >
                  <DatePicker
                    format='MM/DD/YYYY'
                    getPopupContainer={trigger => trigger.parentElement}
                    className='w-100 inputField'
                    placeholder='Jan 12, 2023'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24}>
                <Form.Item
                  name={['interview_datetime']}
                  label='Interview Time'
                  rules={[
                    { required: true, message: 'Interview Time is required' },
                  ]}
                >
                  <TimePicker
                    format='hh:mm'
                    className='w-100 inputField'
                    placeholder='13:00' />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24}>
                <Form.Item
                  name='summery'
                  label='Remarks'
                >
                  <TextAreaStyled
                    style={{ height: '200px' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (

          <><Row>
            <Col xs={24} sm={24}>
              <Form.Item
                name='joining_date'
                label='Joining Date'
                rules={[{ required: true, message: 'Select date!' }]}
                style={{
                  position: 'relative',
                }}
              >
                <DatePicker
                  format='MM/DD/YYYY'
                  getPopupContainer={trigger => trigger.parentElement}
                  className='w-100 inputField'
                  placeholder='Jan 12, 2023' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <Form.Item name={'status'} label='Select Status'>
              <CommonSelect
                listHeight={140}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder='Select Status'
                showIcon={true}
                options={[
                  {value:'approved',label:'Approved'},
                  {value:'rejected',label:'Rejected'}
                ]}
              />
              </Form.Item>
            </Col>
          </Row>
          <Row>
              <Col xs={24} sm={24}>
                <Form.Item
                  name='remarks'
                  label='Remarks'
                >
                  <TextAreaStyled
                    style={{ height: '200px' }} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form >
    </>
  )
})

export default OnboardingForm


const TextAreaStyled = styled(TextArea)`
  border: 1px dashed #e2e2e2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`


