import { headerAuthorization } from '../common-utils'
import {BaseApi} from '../baseApi'
import {assetHistoryUrl, baseUrl} from '@api/const'

class AssetsHistoryApi extends BaseApi {
  setAssetsHistory = async data => {
    try {
      const response = await this.axios.post(`${baseUrl}asset_history/`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getAssetsHistory = async id => {
    try {
      const response = await this.axios.get(
        assetHistoryUrl,
        {
          params: {asset_id: id} ,
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default AssetsHistoryApi;
