import { Card, Steps } from 'antd'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { colors } from 'constant/colors'
import { timezoneFormat } from '@utils/common-functions'

const { mediumGray } = colors

const TodayAttendance = observer(({ punchHistory }) => {
  const punchStatuses = {
    punch_in: 'Checked In',
    punch_out: 'Checked Out',
    commence_prayer: 'Prayer In',
    begin_recess: 'Lunch Break Started',
    conclude_prayer: 'Prayer Out',
    finish_recess: 'Lunch Break Ended',
  }

  const items = punchHistory?.results
    ?.filter(item => item !== null)
    .map(item => ({
      title: punchStatuses[item.punch],
      description: `${timezoneFormat(item?.duration, 'HH:mm A','add')}`,
    }))

  const ReverseItems = items?.reverse();  

  if (!punchHistory)
    return (
      <ActivityWrapper>
        <h4>No Activity</h4>
      </ActivityWrapper>
    )

  return (
    <CardWrapper>
      <Steps progressDot current={1} direction='vertical' items={ReverseItems} />
    </CardWrapper>
  )
})

export default TodayAttendance

const CardWrapper = styled(Card)`
  .ant-steps-item-title {
    font-size: 14px;
    margin-top: -5px;
  }
  .ant-steps-item-description {
    padding: 0 0 20px 4px !important;
    font-size: 13px;
    margin-left: 10px;
    color: #8f8f8f;
    position: relative;
  }
`

const ActivityWrapper = styled.div`
  width: 100%;
  min-height: 360px;
  position: relative;
  display: grid;
  place-items: center;
  h4 {
    color: ${mediumGray};
  }
`
