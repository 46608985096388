import { flow, types } from 'mobx-state-tree'
import { reportsApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'

export const reportType = types.model({
  date: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
})
export const reports = types.model({
  client: types.maybeNull(types.array(reportType)),
  employee: types.maybeNull(types.array(reportType)),
  project: types.maybeNull(types.array(reportType)),
})

export const reportsDetails = types
  .model({
    reports: types.maybeNull(reports),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getReportsData() {
      return toJS(self.reports)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadReportsDetails = flow(function* () {
      try {
        self.loading = true
        const res = yield reportsApi.setReportsDetails()
        self.reports = res
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Reports')
        }
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateReportDetails = flow(function* (data) {
      try {
        self.loading = true
        const res = yield reportsApi.updateReportDetails(data)
        self.reports = res
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadReportsDetails,
      updateReportDetails,
    }
  })

export function initReportsDetails() {
  return reportsDetails.create({})
}
