import { Avatar, Collapse, DatePicker, Dropdown, Input, Menu, Tooltip } from 'antd'
import 'antd/dist/antd.min.css'
import styled from 'styled-components'
import circle from '../../../../assets/img/circle.svg'
import priorityGreen from '../../../../assets/img/pri-green-flag.svg'
import priorityrRed from '../../../../assets/img/pri-red-flag.svg'
import priorityYellow from '../../../../assets/img/pri-yellow-flag.svg'
import dueDate from '../../../../assets/img/dueDate.svg'
import startDate from '../../../../assets/img/startDate.svg'
import { CaretRightOutlined } from '@ant-design/icons';
import Clear from '../../../../assets/img/thiscantbdone.svg'
import hourglassEmpty from '../../../../assets/img/hourglass_empty.svg'
import { useStore } from '@stores/root-store'
import closeIcon from '../../../../assets/img/close_icon.svg'
import { useState } from 'react'
import TaskDetails from './taskDetail'
import { colors } from 'constant/colors'

const { CyanBlue } = colors

const ListData = props => {
  const [addNewTask, setAddNewTask] = useState(0)
  const [taskName, setTaskName] = useState('')
  const [startdate, setStartDate] = useState(false)
  const [duedate, setDueDate] = useState(false)
  const [open,setOpen] = useState(false)
  const {
    boardDetails: {
      getDataLabel
    },
  } = useStore(null)

  const handleTaskNameChange = e => {
    setTaskName(e.target.value)
  }
  const handleSaveButton = () => {
    setTaskName('')
    setAddNewTask(0)
  }

  const priorityColorMap = {
    'low': priorityGreen,
    'medium': priorityYellow,
    'high': priorityrRed
  };

  const menus = (
    <Menu>
      <Menu.Item key='low'>
        <img src={priorityGreen} alt='' /> Low
      </Menu.Item>
      <Menu.Item key='normal'>
        <img src={priorityYellow} alt='' /> Normal{' '}
      </Menu.Item>
      <Menu.Item key='high'>
        <img src={priorityrRed} alt='' /> High
      </Menu.Item>
      <Menu.Item key='clear'>
        <StyleMenu>
          <img src={Clear} width={13} height={13} alt='' /> Clear{' '}
        </StyleMenu>
      </Menu.Item>
    </Menu>
  )

  const StartDate = (
    <div>
      <StylePicker>
        <StyleDatePicker
          onOpenChange={() => setStartDate(false)}
          open={startdate}
        >
        </StyleDatePicker>
      </StylePicker>
    </div>
  )

  const DueDate = (
    <div>
      <StylePicker>
        <StyleDatePicker
          onOpenChange={() => setDueDate(false)}
          open={duedate}
        >
        </StyleDatePicker>
      </StylePicker>
    </div>
  )

  const EstimateMenu = (
    <StyleMenuTime>
      <Menu>
        <div className='d-flex styleinput'>
          <label>Time Estimate ?</label>
          <Input />
        </div>
        <StyleFooter className='d-flex justify-content-center gap-3 text-align-center'>
          <span>Total with subtasks 2h</span>
          <p>Changes Automatically saved</p>
        </StyleFooter>
      </Menu>
    </StyleMenuTime>
  )
  const getItems = () => [
    {
      key: '1',
      header: <div><div><span>{props.data?.name}</span><span style={{padding: '4px 14px', borderRadius: '0px 4px 4px 0px'}}>{props?.data?.tasks?.length}</span></div><img onClick={event => {setAddNewTask(1),event.stopPropagation()}} src={circle} /></div>,
      content: (<>
        <div className='d-flex gap-4  styleitems'>
          <div style={{ width: '45%' }}>
            <div style={{ fontWeight: '600' }}>Task</div>
          </div>
          <><div style={{ width: '15%', fontWeight: '600' }} className='d-grid'>
            <p>ASSIGNEE</p>
          </div><div style={{ width: '15%', fontWeight: '600' }} className='d-grid'>
              <p>DUE DATE</p>
            </div><div style={{ width: '15%', fontWeight: '600' }} className='d-grid'>
              <p>PRIORITY</p>
            </div>
          </>
        </div>
        {props.data?.tasks?.map(e => {
          return(
            <Task className='d-flex gap-4'>
              <div style={{ width: '45%' }}>
                <div className='d-flex align-items-center gap-4 mt-2'>
                  <div className='StyleBackground'></div>
                  <div>
                    <TaskName onClick={()=>setOpen(true)}>
                      {e.name}
                    </TaskName>
                    <div className='gap-4 mt-2 setLabel'>
                      {getDataLabel?.map(item => (
                        <StyleLabel color={item?.color} textColor={item?.textColor}  >{item?.name}</StyleLabel>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <><div style={{ width: '15%', fontWeight: '600' }} className='d-grid'>
                <div>
                  <Avatar.Group
                    maxCount={2}
                    maxStyle={{
                      color: '#f56a00',
                      backgroundColor: '#fde3cf',
                    }}
                  >
                    <Avatar src='https://joeschmoe.io/api/v1/random' />
                    <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
                    <Tooltip title='Ant User' placement='top'>
                      <Avatar style={{ backgroundColor: '#87d068' }} />
                    </Tooltip>
                    <Avatar style={{ backgroundColor: CyanBlue }} />
                  </Avatar.Group>
                </div>
              </div><div style={{ width: '15%', fontWeight: '600' }} className='d-grid'>
                  <div style={{display: 'flex',gap: '8px',alignItems: 'center'}}>
                    <OptionIcon src={dueDate} alt='dueDate' /><DueDateText>{e.dueDate.split('T')[0]}</DueDateText>
                  </div>
                </div><div style={{ width: '15%', fontWeight: '600' }} className='d-grid'>
                  <div>
                    <OptionIcon
                      src={priorityColorMap[e.priority.toLowerCase()] || Clear}
                      alt='report'
                    />
                  </div>
                </div>
              </>
              {/* Tasks End */}
            </Task>
          )
        })}
        {addNewTask > 0 && (
          <AddTask>
            <TaskInputArea className='antInput'>
              <img
                src={closeIcon}
                alt='_close'
                onClick={() => setAddNewTask(0)}
              />
              <NewTaskInput
                placeholder='Type Task Name ...'
                value={taskName}
                onChange={handleTaskNameChange}
              />
              <Avatar.Group
                maxCount={2}
                maxStyle={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                }}
              >
                <Avatar src='https://joeschmoe.io/api/v1/random' />
                <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
                <Tooltip title='Ant User' placement='top'>
                  <Avatar style={{ backgroundColor: '#87d068' }} />
                </Tooltip>
                <Avatar style={{ backgroundColor: CyanBlue }} />
              </Avatar.Group>
              <OptionsDiv>
                <Dropdown overlay={menus} trigger={['click']}>
                  <span>
                    <Actions>
                      <img style={{width:'16px'}} src={priorityGreen} />
                    </Actions>
                  </span>
                </Dropdown>
                <Dropdown overlay={StartDate} trigger={['click']}>
                  <span>
                    <Actions>
                      <img
                        src={startDate}
                        onClick={() => setStartDate(true)}
                      />
                    </Actions>
                  </span>
                </Dropdown>
                <Dropdown overlay={DueDate} trigger={['click']}>
                  <span>
                    <Actions>
                      <img
                        src={dueDate}
                        onClick={() => setDueDate(true)}
                      />
                    </Actions>
                  </span>
                </Dropdown>
                <div>
                  <Dropdown overlay={EstimateMenu} trigger={['click']}>
                    <span>
                      <Actions>
                        <img src={hourglassEmpty} />
                      </Actions>
                    </span>
                  </Dropdown>
                </div>
                <AddTaskSaveButton
                  onClick={handleSaveButton}
                >
                  Save
                </AddTaskSaveButton>
              </OptionsDiv>
            </TaskInputArea>
          </AddTask>
        )}
        <TaskDetails open={open} setOpen={setOpen} ></TaskDetails>
      </>
      ),
    },
  ];

  const items = getItems();

  return (
    <CollapseStyle color={props.color} style={{width: 'auto', maxWidth: '98%'}} className='antCollapse'>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        {items.map(item => (
          <Collapse.Panel header={item.header} key={item.key}>
            {item.content}
          </Collapse.Panel>
        ))}
      </Collapse>
    </CollapseStyle>
  );
};



export default ListData


const OptionIcon = styled.img`
  width: 25px;
`

const CollapseStyle = styled.div`
width: 85vw;
  .ant-collapse{
    background-color: white;
  }
  .ant-collapse-header-text{
    padding: 0px;
    color: #ffff;
    background: white;
    & > div {
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
    gap: 2px;
        & > span {
        background: ${(props => props.color)};
        border-radius: 4px 0px 0px 4px;
    padding: 4px 20px;
      }}
    }
  }
  .ant-collapse-header{
    padding: 6px 18px;
    width: -webkit-fill-available !important;
    margin-bottom: -15px;
    display: flex !important;
    align-items: center !important;
  }

  .ant-collapse-expand-icon{
    span{
      border: 2px solid  ${props=>props.color};
      border-radius: 10px;
      padding: 2px;
      svg{
        color: ${props=>props.color};
      }
    }
  }
  .StyleBackground{
    background-color: ${props => props.color};
    height: 10px;
    width: 10px;
    margin-bottom: 40px; 
  }
  .styleitems{
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 8px;
    margin-left: 31px;
  }
  .setLabel{
    display: flex !important  ;
    gap: 10px !important;
  }
`

const StyleLabel = styled.label`
display: flex;
  padding: 3px 8px;
  align-items: center;
  gap: 10px;
  color: black;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  border-radius: 0px 12px 12px 0px;

  background: ${({ textColor }) => {
    return textColor;
  }};
`

const AddTask = styled.div`
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #3bd6e6;
  padding: 8px;
  margin-left:31px;
  margin-top:20px;
`
const TaskInputArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  @media screen and (min-width: 0px) and (max-width: 764px) {
    flex-wrap: wrap;
  }

`
const NewTaskInput = styled(Input)`
  border: none;
  outline: none;
  box-shadow: 0px 0px 0px 0px !important;
  .ant-input:focus {
    box-shadow: 0px 0px 0px 0px !important;
  }
`
const OptionsDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  .ant-picker {
    border: none !important;
    svg {
      margin-top: 20px !important;
    }
    &:hover {
      border: none !important;
    }
  }
`
const Actions = styled.div`
  border-radius: 25px;
  padding: 5px;
  &:hover {
    background-color: #eaf2fb;
  }
  img {
    margin-top: 3px;
    width: 24px;
    height: 24px;
  }
`
const StyleMenu = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
`
const StylePicker = styled.div`
  margin-bottom: -24px !important;
  .ant-picker {
    border: none !important;
  }
`
const StyleDatePicker = styled(DatePicker)`
  .ant-picker-input {
    visibility: hidden !important;
    display: none !important;
  }
`
const StyleMenuTime = styled.div`
  .ant-dropdown-menu {
    width: 21pc !important;
    height: 9pc !important;
    border-radius: 8px !important;
  }
  .ant-input {
    width: 10pc !important;
  }

  .styleinput {
    justify-content: center;
    align-items: center;
    padding: 15px !important;
    gap: 10px;
  }
`
const StyleFooter = styled.div`
  border-top: 1px solid rgb(244 244 244);
  padding: 9px 16px 0 12px;
  font-size: 10px;
  span {
    font-size: 14px;
    font-weight: 600;
  }
  p {
    margin-top: 3px;
  }
`
const AddTaskSaveButton = styled.button`
  padding: 2px 16px;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  background: #08c7e0;
  height: 30px;
`
const Task = styled.div`
border: #dedede solid 1px;
    margin-left: 31px;
    padding: 10px;
    align-items: center;
    border-top: none`
const TaskName = styled.p`
    font-size: 13px;`

const DueDateText = styled.p`
    font-weight: lighter;
    font-size: 13px;`