import { flow, types } from 'mobx-state-tree'
import { notification } from '@utils/notifications'
import { UserLoginDataApi } from '@api'

const info = types.model({
  userName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
})
export const signupInfo = types
  .model({
    userInfo: types.array(info),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const registerInfo = flow(function* fetchData(info) {
      try {
        self.loading = true
        yield UserLoginDataApi.setCompany({
          username: info.username,
          email: info.email,
          password: info.password,
          name: info.name,
        })
        notification.success('User Registered Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return { registerInfo }
  })

export function initSignupInfo() {
  return signupInfo.create({
    userInfo: [],
  })
}
