import TodayAttendance from '@mainPage/attendence/PunchInOutHistorySidebar'
import { useStore } from '@stores/root-store'
import { Card, Col, Progress, Row, Timeline } from 'antd'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PunchButton } from '../PunchButtonCounter/PunchButton'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

export const AttendenceAdminView = observer(() => {
  const {
    attendanceStatsModal: { loadAttendanceStats, getAttendanceStatsData },
    themesModel: { getColorsData },
    userInfo: { getcompany },
    singlePunchModal: { loadSinglePunch },
  } = useStore(null)

  const { id: employee_id } = getcompany || {}

  const [timeStats, setTimeStats] = useState({
    todayTotalTime: 0,
    todayTimeP: 0,
    todayTime: 0,
    weekTotalTime: 0,
    weekTimeP: 0,
    weekTime: 0,
    monthTotalTime: 0,
    monthTimeP: 0,
    monthTime: 0,
    remainTimeP: 0,
    remainTime: 0,
  })

  const [refreshStatistics, setRefreshStatistics] = useState(false)
  const [punchHistory, setPunchHistory] = useState(null)
  const [refreshPunchActivity, setRefreshPunchActivity] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      await loadAttendanceStats()
      await attendanceTodaysActivity()
    }

    fetchData()
  }, [])

  const attendanceTodaysActivity = async () => {
    const data = await loadSinglePunch(
      employee_id,
      moment(new Date()).format('YYYY-MM-DD')
    )
    setPunchHistory(data)
  }

  useEffect(() => {
    const todayP = Math.floor(
      (getAttendanceStatsData?.todayWorkingHours /
        getAttendanceStatsData?.todayTotalHours) *
        100
    )
    const weekP = Math.floor(
      (getAttendanceStatsData?.weekWorkingHours /
        getAttendanceStatsData?.weekTotalHours) *
        100
    )
    const monthP = Math.floor(
      (getAttendanceStatsData?.monthWorkingHours /
        getAttendanceStatsData?.monthTotalHours) *
        100
    )
    const remain =
      getAttendanceStatsData?.monthTotalHours -
      getAttendanceStatsData?.monthWorkingHours
    const remainP = monthP

    const timeStats = {
      todayTotalTime: getAttendanceStatsData?.todayTotalHours,
      todayTimeP: todayP,
      todayTime: getAttendanceStatsData?.todayWorkingHours,
      weekTotalTime: getAttendanceStatsData?.weekTotalHours,
      weekTimeP: weekP,
      weekTime: getAttendanceStatsData?.weekWorkingHours,
      monthTotalTime: getAttendanceStatsData?.monthTotalHours,
      monthTimeP: monthP,
      monthTime: getAttendanceStatsData?.monthWorkingHours,
      remainTimeP: remainP,
      remainTime: remain,
    }
    setTimeStats(timeStats)
  }, [getAttendanceStatsData, refreshStatistics])


  return (
    <>
      <div>
        <div>
          <Row gutter={24}>
            <Col xs={24} xl={18} md={24}>
              <PunchButton
                refreshStatistics={refreshStatistics}
                setRefreshStatistics={setRefreshStatistics}
                refreshPunchActivity={refreshPunchActivity}
                setRefreshPunchActivity={setRefreshPunchActivity}
              />
              <CardWrapper>
                <StatisticHeading>Statistics</StatisticHeading>
                <div className='main-pg-card'>
                  <div className='progressCard-container'>
                    <div className='progressCard'>
                      <div>
                        <Progress
                          className='todayProgress'
                          type='circle'
                          percent={timeStats.todayTimeP}
                          format={() =>
                            `${timeStats.todayTime}/ ${timeStats.todayTotalTime} hrs`
                          }
                          strokeWidth={10}
                          strokeColor={getColorsData?.bodyColor}
                        />
                      </div>
                      <div>
                        <Progress
                          className='weekProgress'
                          type='circle'
                          percent={timeStats.weekTimeP}
                          format={() =>
                            `${timeStats.weekTime}/ ${timeStats.weekTotalTime} hrs`
                          }
                          strokeWidth={10}
                          strokeColor={getColorsData?.bodyColor}
                        />
                      </div>
                      <div>
                        <Progress
                          className='monthProgress'
                          type='circle'
                          percent={timeStats.monthTimeP}
                          format={() =>
                            `${timeStats.monthTime}/ ${timeStats.monthTotalTime} hrs`
                          }
                          strokeWidth={10}
                          strokeColor={getColorsData?.bodyColor}
                        />
                      </div>
                      <div>
                        <Progress
                          className='remainProgress'
                          type='circle'
                          percent={timeStats.remainTimeP}
                          format={() =>
                            `${
                              timeStats.monthTime > 160
                                ? 0
                                : timeStats.remainTime
                            } hrs`
                          }
                          strokeWidth={10}
                          strokeColor={getColorsData?.bodyColor}
                        />
                      </div>
                      <div>
                        <Progress
                          className='overtimeProgress'
                          type='circle'
                          percent={
                            timeStats?.monthTime > timeStats?.monthTotalTime
                              ? ((timeStats?.monthTime -
                                  timeStats?.monthTotalTime) /
                                  100) *
                                100
                              : 0
                          }
                          format={() =>
                            `${
                              timeStats.monthTime > timeStats.monthTotalTime
                                ? timeStats.monthTime - timeStats.monthTotalTime
                                : '00 hrs'
                            }`
                          }
                          strokeWidth={10}
                          strokeColor={getColorsData?.bodyColor}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CardWrapper>
            </Col>
            <StyledActivityCol xs={24} md={24} xl={6}>
              <ActivCard>
                <StatisticHeading className='today-activity-heading'>
                  Today’s Activity
                </StatisticHeading>
                <div className='Activity'>
                  <StyledTimeline>
                    <TodayAttendance punchHistory={punchHistory} />
                  </StyledTimeline>
                </div>
              </ActivCard>
            </StyledActivityCol>
            <Col xs={24}></Col>
          </Row>
        </div>
      </div>
    </>
  )
})

const ActivCard = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: 'poppins';
  margin-bottom: 25px;
  height: 100%;

  .ant-card-body {
    .Activity {
      margin-top: 0;
    }
  }

  border: none !important;

  * {
    word-spacing: 0px !important;
  }

  h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #010101;
    @media (max-width: 576px) {
      text-align: center;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #525353;
    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  h1 {
    color: ${TealishBlue};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 0px;
    }
  }

  span {
    font-style: normal;
    font-family: 'Outfit';
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #525353;
    word-spacing: 2px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .Activity {
    margin-top: 25px;
    height: 450px;
    overflow: auto;

    .ant-timeline-item-content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #525353;

      span {
        font-size: 14px;
      }
    }
  }
`

const CardWrapper = styled(Card)`
  .main-pg-card {
    display: flex;
    justify-content: center;
    margin: auto;
  }

  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: 'poppins';
  margin-bottom: 25px;

  * {
    word-spacing: 0px !important;
  }

  h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #010101;
    @media (max-width: 576px) {
      text-align: center;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #525353;
    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  h1 {
    color: ${TealishBlue};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 0px;
    }
  }

  span {
    font-style: normal;
    font-family: 'Outfit';
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #525353;
    word-spacing: 2px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .progressCard-container {
    display: flex;
    overflow: auto;

    .progressCard {
      display: flex;
      gap: 15px;
    }
  }

  .todayProgress,
  .weekProgress,
  .monthProgress,
  .remainProgress,
  .overtimeProgress {
    .ant-progress-text {
      font-size: 14px;
      color: #525353;
    }

    .ant-progress-text::before {
      content: 'Today';
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
      color: #010101;
      word-spacing: 2px;
    }

    .ant-progress-inner {
      width: 168px !important;
      height: 168px !important;
    }
  }

  .weekProgress {
    .ant-progress-text::before {
      content: 'This Week';
    }
  }

  .monthProgress {
    .ant-progress-text::before {
      content: 'This Month';
    }
  }

  .remainProgress {
    .ant-progress-text::before {
      content: 'Remaining';
    }
  }

  .overtimeProgress {
    .ant-progress-text::before {
      content: 'Overtime';
    }
  }
`

const StyledActivityCol = styled(Col)`
  margin-bottom: 25px;
`
const StyledTimeline = styled(Timeline)`
  .ant-card-bordered {
    border: none !important;
  }
`
export const StatisticHeading = styled.h2`
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 17px;
  margin-bottom: 8px;
`
