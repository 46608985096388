import { cast, flow, types } from 'mobx-state-tree'
import { employeePersonalApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'

export const personal = types.model({
  id: types.maybeNull(types.number),
  employee: types.maybeNull(types.string),
  telephoneNumber: types.maybeNull(types.string),
  nationality: types.maybeNull(types.string),
  religion: types.maybeNull(types.string),
  maritalStatus: types.maybeNull(types.string),
  birthDate: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  cnic: types.maybeNull(types.string),
})

export const personalDetails = types
  .model({
    data: types.maybeNull(types.array(personal)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),    
    erorMessage: types.maybeNull(types.string)
  })
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get getPersonalData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadPersonalDetails = flow(function* (profile, employee_id) {
      try {
        self.loading = true
        const res = yield employeePersonalApi.getEmployeePersonalDetails(
          profile,
          employee_id
        )
        self.data = cast(res)
      } catch (error) {
        const message = error?.response?.data?.message 
        
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Personal Details')
        }
      } finally {
        self.loading = false
      }
    })

    const updatePersonalDetails = flow(function* (
      profile,
      employee_id,
      id,
      data
    ) {
      try {
        self.loading = true
        yield employeePersonalApi.updateEmployeePersonalDetails(
          profile,
          employee_id,
          id,
          data
        )
        self.erorMessage = ''
        notification.success('Personal Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.erorMessage = error?.response?.data?.message 
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const clearErrorMsg = () => {
      self.erorMessage = ''
    }
    return {
      loadPersonalDetails,
      updatePersonalDetails,
      clearErrorMsg
    }
  })

export function initEmployeePersonalDetails() {
  return personalDetails.create({})
}
