import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import CustomButton from '@commonComponents/button'
import { Form, Space } from 'antd'
import { humanize } from '../../utils/string_formate';
import CustomDrawer from '@commonComponents/drawer'
import { MdAddCircleOutline } from 'react-icons/md'
import AllowancesOrDeduction from '@mainPage/E-payroll/AllowancesOrDeduction'
import { useStore } from '@stores/root-store';
import styled from 'styled-components'

export interface valuesProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRecord: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  record: any;
  modalType?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  editRecord?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setEditRecord?: any;
}

const PayrollAllowanceAndDeduction = observer(
  ({
    modalType = 'allowance',
    record,
    setRecord,
    editRecord,
    setEditRecord
  }: valuesProps) => {

    const {allowanceModel: {allowancesList}} = useStore(null)  
    const [form] = Form.useForm()
    const [assVisible, setAssVisible] = useState(false)
    const [selectedAllowance, setSelectedAllowance] = useState(record)
    const [selectedDeduction, setSelectedDeduction] = useState(record)

    const isValid = () => modalType?.includes(editRecord?.type || '') && editRecord?.value
    const [color, setColor] = useState(null)

    useEffect(() => {
      setColor(JSON.parse(localStorage.getItem('colorTheme')))
    }, [])

    function isColorDark(color) {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      return brightness < 128;
    }

    useEffect(() => {
      if (isValid()) {
        const key = Object.keys(editRecord?.value)[0]
        setAssVisible(true)
        form.setFieldsValue({
          name: key,
          value: editRecord?.value[key]
        })
      }
    }, [editRecord])

    useEffect(() => {
      if (modalType === 'allowance') setSelectedAllowance(record)
      else if (modalType === 'deduction') setSelectedDeduction(record)
    }, [record])

    const resetAllowanceOrDeduction = () => {
      if (modalType === 'allowance') setSelectedAllowance(record)
      else if (modalType === 'deduction') setSelectedDeduction(record)
    }

    const submitAllowanceOrDeduction = () => {
      if (modalType === 'allowance') setRecord(JSON.parse(JSON.stringify(selectedAllowance)))
      else if (modalType === 'deduction') setRecord(JSON.parse(JSON.stringify(selectedDeduction)))
    }

    return (
      <>
        <Formfield>
          <Form.Item
            name='values'
            className='addMoreBtn'
            label={
              <>
                <AddMore color={color || '#2d71d8'} textColor={isColorDark(color?.bodyColor || '#2d71d8')} onClick={() => setAssVisible(true)}>
                  <MdAddCircleOutline />
                  <span>Add more&nbsp;</span>
                </AddMore>
              </>
            }
          >
          </Form.Item>
        </Formfield>
        <div>
          <CustomDrawer
            title={`${isValid() ? 'Edit' : 'Add'} ${humanize(modalType)}`}
            visible={assVisible}
            width={900}
            setVisible={() => {
              setAssVisible(false)
              setEditRecord({})
              resetAllowanceOrDeduction()
            }}
            className='addDrawer'
            customFooter={
              <Space>
                <CustomButton
                  htmlType='submit'
                  type='primary'
                  title={'Cancel'}
                  className='cancelButton'
                  onClick={() => {
                    setAssVisible(false)
                    resetAllowanceOrDeduction()
                  }}
                />
                <CustomButton
                  form='payroll-values-form'
                  htmlType='submit'
                  type='primary'
                  title={`${isValid() ? 'Edit' : 'Add'} ${humanize(modalType)}`}
                  loading={false}
                  disabled={false}
                  shape='round'
                  className='addButton'
                  onClick={() => {
                    setAssVisible(false)
                    submitAllowanceOrDeduction()
                  }}
                />
              </Space>
            }
          >
            <AllowancesOrDeduction
              dataList={allowancesList}
              record={modalType === 'allowance' ? selectedAllowance : selectedDeduction}
              setRecord={modalType === 'allowance' ? setSelectedAllowance : setSelectedDeduction}
              modalFor={modalType}
            />
          </CustomDrawer>
        </div>
      </>
    )
  }
)

export default PayrollAllowanceAndDeduction;

const Formfield = styled.div`
  .addMore {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 9px;
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: ${props => props.color?.bodyColor} !important;
      word-spacing: 0px;
      cursor: pointer;
    }
    svg {
      color: ${props => props.color?.bodyColor} !important;
      cursor: pointer;
    }
  }
`
const AddMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 9px;
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    word-spacing: 0px;
    cursor: pointer;
    color: ${props => props.color?.bodyColor} !important;
  }
  svg {
    color: ${props => props.color?.bodyColor} !important;
    cursor: pointer;
  }
`