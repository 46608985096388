import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { cast, flow, types } from 'mobx-state-tree'
import { designationApi } from '../../api'

export const designation = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  company: types.maybeNull(types.string),
  branch: types.maybeNull(types.string),
  department: types.maybeNull(types.string),
  section: types.maybeNull(types.string),
  object_id: types.maybeNull(types.string),
})

export const designationModal = types
  .model({
    data: types.maybeNull(types.array(designation)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextDes: types.optional(types.number, 1),
  })
  .views(self => ({
  
    
    get getDesignationData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setDesignationData = flow(function* (data) {
      
      try {
        self.loading = true
        yield designationApi.setDesignation(data)
        notification.success('Designation Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadDesignation = flow(function* (page = 1, pageSize = 20, queryParam = '', resetList = false) {
      self.loading = true
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextDes = 1
        }

        const res = yield designationApi.getDesignation(page, pageSize, queryParam)
        self.data = res.items
        
        if (!res?.isCancel ) {
          self.data = cast([])
          self.data = cast([...toJS(self.data ?? []), ...res.items])
          self.count = res.count
          if (res.next) {
            self.nextDes = self.nextDes + 1
          } else {
            self.nextDes = 1
          }
        }
        return toJS(res)

      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Designation';
        notification.error(message);
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateDesignation = flow(function* (id, data) {
      try {
        self.loading = true
        yield designationApi.updateDesignation(id, data)
        notification.success('Designation Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message.split(':')[1])
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteDesignation = flow(function* (id) {
      try {
        self.loading = true
        yield designationApi.deleteDesignation(id)
        notification.success('Designation Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'You can\'t delete this Designation')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setDesignationData,
      loadDesignation,
      deleteDesignation,
      updateDesignation,
    }
  })

export function initDesignationModal() {
  return designationModal.create({})
}
