import { attendanceApi } from '@api'
import { toJS } from 'mobx'
import { flow, types } from 'mobx-state-tree'

export const attendanceListModel = types.model({
  date: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  workReport: types.maybeNull(types.string),
})

export const attendanceList = types.model({
  designation: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  attendanceList: types.maybeNull(types.array(attendanceListModel)),
  children: types.optional(types.array(types.late(() => attendanceList)), []),
})

export const attendanceListV2 = types.model({
  attendanceList: types.maybeNull(types.array(attendanceListModel)),
  hasChildren: types.maybeNull(types.boolean),
  designation: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  children: types.optional(types.array(types.late(() => attendanceListV2)), []),
})

const AttendanceListItem = types.model({
  status: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
})

const attendanceHierarchy = types.model({
  hasChild: types.maybeNull(types.boolean),
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  designationName: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
  attendanceList: types.maybeNull(types.array(AttendanceListItem)),
})

export const attendanceModal = types
  .model({
    loading: types.optional(types.boolean, false),
    hierarchyData: types.maybeNull(types.array(attendanceHierarchy)),
  })
  .views(self => ({
    get attendanceHierarchyData() {
      return toJS(self.hierarchyData)
    },
  }))
  .actions(self => {
    const loadAttendanceChildrenHierarchy = flow(function* (supervisorId, obj) {
      self.loading = true
      try {
        const res = yield attendanceApi.getAttendanceChildrenHierarchy(
          supervisorId
        )

        const findSetNestedData = (id, parentObj, response) => {
          const findAndSetChildren = obj => {
            if (obj.id === id) {
              const children = response.map(item => ({
                ...item,
                key: item.id,
                children: [],
              }))
              obj.children = children?.sort((child1, child2) => !child1.hasChild && child2.hasChild ? 1 : -1);
            } else if (obj.children) {
              obj.children.forEach(findAndSetChildren)
            }
          }
          findAndSetChildren(parentObj)
        }

        findSetNestedData(supervisorId, obj, res)
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadAttendanceHierarchyParent = flow(function* () {
      self.loading = true
      try {
        const res = yield attendanceApi.getAttendanceHierarchyParent()

        self.hierarchyData = res
      } catch (error) {
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadAttendanceHierarchyParent,
      loadAttendanceChildrenHierarchy,
    }
  })

export function initAttendanceModal() {
  return attendanceModal.create({})
}
