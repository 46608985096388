import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'
import { feedbackLeadQuestionsApi } from '@api'

export const Question = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
})

export const feedbackLeadQuestionModal = types
  .model({
    data: types.maybeNull(types.array(Question)),
    loading: types.maybeNull(types.boolean),
    nextFeed: types.optional(types.number, 1),
    count: types.maybeNull(types.number),
  })  
  .views(self => ({
    get getFeedbackQuestions() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadLeadFeedBackQuestion = flow(function* (
      saveInStore=true) {
      try {
        self.loading = true
        const res= yield feedbackLeadQuestionsApi.loadLeadFeedbackQuestions()
        if (!res?.isCancel && saveInStore){
          self.data=null
          self.data = cast([...toJS(self.data ?? []), ...res]) // add items when pagination is integrated
          self.count = res.count
          if (res.next) {
            self.nextFeed = self.nextFeed + 1
          } else {
            self.nextFeed = 1
          }
        } else if(!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.success('Failed to Load Feedback Question')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })
  
    return {
      loadLeadFeedBackQuestion
    }
  })

export function initFeedbackLeadQuestionModal() {
  return feedbackLeadQuestionModal.create({})
}