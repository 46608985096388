import React, { useEffect, useMemo, useState } from 'react'
import style from '@mainPage/rolePermission/style.module.scss'
import CustomButton from '@commonComponents/button'
import { useStore } from '@stores/root-store'
import { METHODS_LIST, PATCH_METHOD, PERMISSIONS_LIST, ROLE } from '../../../constant/constant'
import LoadingSkeletonRole from '@mainPage/rolePermission/loadingSkeleton';
import ShowAllowanceAccess from '@mainPage/rolePermission/ShowAllowanceAccess';
import { getColumns, getMethod, methodMap } from '@mainPage/rolePermission/utils';
import { observer } from 'mobx-react'
import PermissionPopup from '@commonComponents/permission-popup'
import styled from 'styled-components'
import { METHOD_NAMES, PERMISSIONS_NAME } from './constants'
import ShowOnPermission from '@commonComponents/permit'
import { convertCamelToSnake } from '@utils/common-functions'
interface TransformedMenu {
  name: string;
  methods: string[];
}

const ModuleAccess = observer(({ roleId }) => {
  const [modules, setModules] = useState([])
  const [data, setData] = useState({})
  const [visible, setVisible] = useState(false)
  const [checkBoxes, setCheckBoxes] = useState({});

  const {
    employeeRole: { getEmployeeRoleData, isLoading, updateEmployeeRole, loadEmployeeRole, getDefaultPermissionList },
  } = useStore(null)

  const transformedArray: TransformedMenu[] = Object.entries(getDefaultPermissionList || {})?.map((entry: [string, string[]]) => {
    const [name, methods] = entry;
    return { name, methods };
  });



  const changeData = (e, val) => {

    const updatedModules = [...modules];
    const updatedData = { ...data };
    const updatedCheckBoxes = { ...checkBoxes };

    if (e) {
      updatedModules.push(val);
      !Object.prototype.hasOwnProperty.call(updatedData, val.api) && (updatedData[val?.api] = []);
      updatedData[val?.api].push({
        name: val?.api,
        methods: val?.api === 'accessView' ? PERMISSIONS_LIST : METHODS_LIST,
      });

    } else {
      const moduleIndex = updatedModules.findIndex(item => item?.api === val?.api);
      moduleIndex !== -1 && updatedModules.splice(moduleIndex, 1);
      delete updatedData[val?.api];
    }

    setModules(updatedModules);
    setData(updatedData);
    setCheckBoxes(updatedCheckBoxes);
  };

  //convert key from camel case to snack case
  const convertKeysToSnakeCase = obj =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [convertCamelToSnake(key), value]))
//action to remove all dashes containing key
const excludeKeysWithAllDashes = obj => {
  const filteredEntries = Object.entries(obj).filter(
    /* eslint-disable */
    ([key, value]) => Array.isArray(value) && value.some(item => item !== '-')
  )
  return convertKeysToSnakeCase(Object.fromEntries(filteredEntries));
}

  const onSubmit = async e => {
    e.preventDefault();
    const payload = Object.keys(checkBoxes).reduce((preObj, nextItem) => {
      return {
        ...preObj,
        [nextItem]: nextItem === 'accessView'
          ? Array(5).fill('-').map((_, index) => PERMISSIONS_NAME[checkBoxes?.[nextItem]?.methods?.[index]] || '-')
          : Array(6).fill('-').map((_, index) => METHOD_NAMES[checkBoxes?.[nextItem]?.methods?.[index]] || '-')
      }
    }, {});
    const allowedMethods = excludeKeysWithAllDashes(payload)
    const res = await updateEmployeeRole(
      getEmployeeRoleData?.filter(item => item.id === roleId)[0]?.id,
      { allowed_method: allowedMethods }
    );

    res?.success && await loadEmployeeRole(true);
  };

  const getval = (event, row, meth) => {
    let method = row.name === 'accessView' ? methodMap(meth) : getMethod(meth);
    setCheckBoxes(prevCheckBoxes => ({
      ...prevCheckBoxes,
      [row.name]: {
        ...prevCheckBoxes[row.name],
        methods: {
          ...prevCheckBoxes[row.name]?.methods,
          [meth]: event.target.checked ? method : '-',
        },
      },
    }));

  };

  const columns = useMemo(
    () => getColumns({ getval, checkBoxes }),
    [data, checkBoxes]
  );

  useEffect(() => {
    const datalist = []; 
    const employeeData = getEmployeeRoleData?.filter(item => item.id === roleId)[0]?.allowedMethod || [];
    Object.keys(employeeData).forEach(name => {
      datalist[name] = employeeData[name];
    });

    setData(datalist);
    const modules = [];

    if (employeeData) {
      Object.keys(employeeData).forEach(key => {
        modules.push({
          name: key,
          api: key,
        });
      });
    }

    setModules([...modules]);
    {/* Set the initial checkbox Value*/ }
    const initialCheckBoxes = {};
    Object.keys(datalist).forEach(name => {

      initialCheckBoxes[name] = {
        methods: [],
      };

      datalist[name]?.forEach((method: string, index: number) => {
        initialCheckBoxes[name].methods[index] = method === '-' ? '' : method;
      });
    });

    setCheckBoxes(initialCheckBoxes);

  }, [roleId, getEmployeeRoleData]);

  if (isLoading) {
    return (
      <div className={`col-sm-6 col-md-7 col-lg-8 col-xl-9`}>
        <LoadingSkeletonRole loading={isLoading} />
      </div>
    )
  }



  return (
    <div className={`col-sm-6 col-md-7 col-lg-8 col-xl-9 ${style.roleModuleAccess} roleModuleAccess`}>

      <Styledheader>
        <CustomModule className='module'>Module Access</CustomModule>
        <ShowOnPermission mod={ROLE} method={PATCH_METHOD}>
          <CustomBtn title={'Apply'} onClick={onSubmit} />
        </ShowOnPermission>
      </Styledheader>

      <div className={style.rolesList}>

        <ShowAllowanceAccess
          modules={modules}
          columns={columns}
          valMenu={transformedArray}
          data={data}
          changeData={changeData}
        />
      </div>
      <div className='table-responsive'>
        <PermissionPopup
          delete={() => {
            setVisible(false);
          }}
          msgTitle='Role & permission'
          visibleDelete={visible}
          isLoading={isLoading}
          okText='Allow'
          handleCancel={() => {
            setVisible(false);
          }}
          msg={'Are you sure you want to give the Role Admin Permission'}
        />
      </div>
    </div>    
  );
})

export default ModuleAccess;

const Styledheader = styled.div`
display:flex;
width:100%;
align-items:center;

color: black;
font-size: 24px;
font-family: 'Outfit';
font-weight: bold;
font-weight: 600;
letter-spacing: 1px;
padding:22px 8px 22px 8px;
`
const CustomBtn = styled(CustomButton)`
display:block;
margin:0px 0px auto auto;
`
const CustomModule = styled.span`
width:100%;
`