import React, { useRef, useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import { Table } from '@commonComponents/table-v2'
import { ASSET_HANDLING,UPDATE_METHOD } from 'constant/constant'
import CustomButton from '@commonComponents/button'
import CustomDrawer from '@commonComponents/drawer'
import { Card, Space } from 'antd'
import AssetHandlingForm from './AssetHandlingForm'
import SearchAssetHandling from './SearchAssetHandling'
import { assetHandlingUrl } from '@api/const'
import { assetSubmissioncolumns } from './assetSubmissioncolumns'
import { useStore } from '@stores/root-store'
import AssetRequestcolumns from './assetRequestcolumns'
import DeletePopUp from '@commonComponents/delete-popup'
import styled from 'styled-components';
import { permit } from '@utils/permission/permission'

const AssetHandling = observer(() => {
  const [visible, setVisible] = useState(false)
  const [activeTab, setActiveTab] = useState('assetsubmission')
  const columns: any = assetSubmissioncolumns()
  const tableRef = useRef(null)
  const [refreshtable, setRefreshTable] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [paramState, setParamState] = useState(null)
  const [queryparam, setQueryParam] = useState({})
  const [currentData, setCurrentData] = useState(null)
  const [currentId, setCurrentId] = useState(null)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [btnhide, setBtnhide] = useState('')
  const [resetFilters , setResetFilters] = useState(false)

  const {
    assetHandlingModal: {
      updateEmployeeAssetsData,deleteEmployeeAssetsData, isLoading,
    },
    userInfo: { getcompany },
  } = useStore(null)
  
  const onTabChange = (key: React.SetStateAction<string>) => {
    setActiveTab(key)
    setBtnhide(key)    
    setQueryParam({})
    setResetFilters(true)
  }

  const onAdd = () => {
    setVisible(true)
    setCurrentData({})
    setParamState('Add Request')
  }

  const onEdit = (val: any) => {
    setIsUpdate(true)
    setVisible(true)
    setCurrentData(val)
    setParamState('Update Request')
  }

  const updateEmployeeStatus = useCallback(async (data, status) => {
    data.status = status
    await updateEmployeeAssetsData(data, data.id)
    setRefreshTable(true)
  }, [])

  const deleteHandler = async () => {
    const res = await deleteEmployeeAssetsData(currentId)
    if (res?.success) {
      setCurrentId('')
      setVisibleDelete(false)
      setRefreshTable(true)
    }
  }

  const onDelete = (row: { id: any }) => {
    setCurrentId(row?.id)
    setVisibleDelete(true)
  }

  const tabList = [
    {
      key: 'assetsubmission',
      tab: 'Asset Submission',
    },
    {
      key: 'assetrequested',
      tab: 'Asset Requested',
    },
  ]
  const contentList = {
    assetsubmission: (
 <div className='table-responsive'>

        <Table
          columns={columns}
          ref={tableRef}
          dataUrl={assetHandlingUrl}
          queryParam={queryparam}
          refreshTable={refreshtable}
          setRefreshTable={setRefreshTable}
        />
        </div>
    
    ),
  
    assetrequested: (
      <div className='table-responsive'>
        <AssetRequestcolumns
          onEdit={onEdit}
          refreshTable={refreshtable}
          setRefreshTable={setRefreshTable}
          queryParam={queryparam}
          updateEmployeeAssetsData={updateEmployeeAssetsData}
          setCurrentData={setCurrentData}
          updateEmployeeStatus={updateEmployeeStatus}
          onDelete={onDelete}
        />
        </div>
    ),
  }
  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid designationTable'>
        {/* Page Header */}
        <div className='d-flex justify-content-between'>
          <BreadCrumbs
            list={[
              { name: 'Dashboard', link: constRoute.home },
              { name: 'Asset Handling', link: constRoute.assetHandling },
            ]}
            isAlpha
            pageTitle={'Asset Handling'}>
          </BreadCrumbs>
          {!(getcompany.admin || getcompany?.isLead || permit(getcompany, ASSET_HANDLING, UPDATE_METHOD))  && (
            <div>
              {btnhide==='assetrequested'&&
            <CustomButton onClick={onAdd} title='Add Request' />
              }
            </div>
          )}
        </div>
        <div className='mt-3'>
          <SearchAssetHandling
            setResetTable={setRefreshTable}
            setQueryParam={setQueryParam}
            resetFilters={resetFilters}
            setResetFilters={setResetFilters}
            activeTab={activeTab}
          />
        </div>
        <div className='col-md-12'>
          <div className='customTabs'>
            <TabCard
              tabList={tabList}
              activeTabKey={activeTab}
              onTabChange={key => {
                onTabChange(key)
              }}
            >
            </TabCard>
          </div>
          {contentList[activeTab]}
        </div>
      </div>
      {/* Add Section Modal */}
      <CustomDrawer
        visible={visible}
        title={paramState}
        setVisible={setVisible}
        className='addDrawer'
        width={300}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => {setVisible(false); setIsUpdate(false)}}
            />
            <CustomButton
              form='assetHandle-form'
              htmlType='submit'
              type='primary'
              title={paramState}
              className='addButton'
            />
          </Space>
        }
      >
        <AssetHandlingForm
          setRefreshTable={setRefreshTable}
          setVisible={setVisible}
          isUpdate={isUpdate}
          currentData={currentData}
        />
      </CustomDrawer>
      <DeletePopUp
        delete={deleteHandler}
        msgTitle={'Delete Request'}
        isLoading={isLoading}
        visibleDelete={visibleDelete}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
        renderMsg='Are you sure you want to delete'
      />
    </div>
  )
})

export default AssetHandling

const TabCard = styled(Card)`
  .ant-tabs-nav-more{
    visibility: hidden;
    order: 1;
  }
`