import { flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { dashBoardApi } from '@api'
import { notification } from '@utils/notifications'
import moment from 'moment';


const Leave = types.model({
    id: types.maybeNull(types.string),
    fullName: types.maybeNull(types.string),
    employeeId: types.maybeNull(types.string),
    designationTitle: types.maybeNull(types.string),
    leaveDuration: types.maybeNull(types.number),
    leaveReason: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    leaveType: types.maybeNull(types.string),
    leaveCount: types.maybeNull(types.number),
});

const employee = types.model({
    id: types.maybeNull(types.string),
    fullName: types.maybeNull(types.string),
    employeeId: types.maybeNull(types.string),
    designationTitle: types.maybeNull(types.string),
})

const singleEmployeeAttendance = types.model({
    totalDays: types.maybeNull(types.number),
    totalPresentDays: types.maybeNull(types.number),
    totalAbsentDays: types.maybeNull(types.number),
    onLeaves: types.maybeNull(types.number),
    totalOvertime: types.maybeNull(types.string),
    lateComing: types.maybeNull(types.number),
    onTime: types.maybeNull(types.number)
})

const employeeAttendance = types.model({
    totalPresents: types.maybeNull(types.number),
    presentEmployees: types.maybeNull(types.array(employee)),
    totalAbsents: types.maybeNull(types.number),
    absentsEmployees: types.maybeNull(types.array(employee)),
    totalEmpOnLeave: types.maybeNull(types.number),
    onLeaveEmp: types.maybeNull(types.array(Leave)),
    totalEmpRemoteLeave: types.maybeNull(types.number),
    remoteLeaveEmp: types.maybeNull(types.array(Leave)),
    totalEmpRejectedLeave: types.maybeNull(types.number),
    rejectedLeaveEmployee: types.maybeNull(types.array(employee)),
    totalUpcomingLeave: types.maybeNull(types.number),
    upcomingLeave: types.maybeNull(types.array(Leave)),
    totalNumEmployees: types.maybeNull(types.number),
    totalEmployees: types.maybeNull(types.array(employee)),
    presentPercentage: types.maybeNull(types.number),
    totalOntimeEmployees: types.maybeNull(types.number),
    totalLateEmployees: types.maybeNull(types.number)
})

export const DashBoardModel = types
    .model({
        employeeAttendance: types.maybeNull(employeeAttendance),
        singleEmployeeAttendance: types.maybeNull(singleEmployeeAttendance),
    })
    .views(self => ({
        get employeeAttendanceData() {
            return toJS(self.employeeAttendance)
        },
        get singleEmployeeAttendanceData() {
            return toJS(self.singleEmployeeAttendance)
        },
    }))
    .actions(self => {
        const loadEmployeeAttendanceOverview = flow(function* (params) {
            try {
                const res = yield dashBoardApi.getEmployeeAttendanceOverview(params)
                self.employeeAttendance = res
            } catch (error) {
                const message = error?.response?.data?.message
                message ? notification.error(message) : null
            }
        })
        const loadSingleEmployeeAttendance = flow(function* () {
            try {
                const res = yield dashBoardApi.getSingleEmployeeAttendance()

                const overTimeInHours =  moment.duration(res?.totalOvertime, 'hours').hours()

                self.singleEmployeeAttendance = res
                self.singleEmployeeAttendance.totalOvertime = overTimeInHours.toString()
            } catch (error) {
                const message = error?.response?.data?.message
                message ? notification.error(message) : null
            }
        })
        return {
            loadEmployeeAttendanceOverview,
            loadSingleEmployeeAttendance,
        }
    })

export function initDashBoardModal() {
    return DashBoardModel.create({})
}