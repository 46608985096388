/**
 * App Header
 */
import React, { useState, useEffect } from 'react'
import { Menu } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import '../style.module.scss'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import { customIcon, MenuIcon } from '@utils/menu/menuIcon'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Sidebar = ({ collapsed }: any) => {
  const {
    userInfo: { getcompany },
  } = useStore(null)
  const [activeElement, setActiveElement] = useState('')
  const location = useLocation()

  useEffect(() => {
    setActiveElement(location?.pathname)
  }, [location?.pathname])

  const menuList =
    [
      {
        menuTitle: '',
        menu: [
          {
            name: 'Dashboard',
            api: 'dashboard',
            icon: MenuIcon.dashboard,
            link: constRoute.home,
          },
        ],
      },
      ...getcompany.menu,
    ]

  menuList?.map(item => {
    item?.menu?.map((menuItem, index) => {
      if (menuItem.name == 'Leave Approval') {
        item.menu.splice(index, 1)
      }
      if (menuItem.name == 'Assets Approval') {
        item.menu.splice(index, 1)
      }
      if (menuItem.name == 'Expenses Approval') {
        item.menu.splice(index, 1)
      }
      if (menuItem.name == 'Remote Approval') {
        item.menu.splice(index, 1)
      }
    })
  })

  return (
    <DivWrapper>
      <Menu
        className='main-menu h-100'
        theme='dark'
        mode='inline'
        inlineCollapsed={collapsed}
        selectedKeys={[window?.location?.pathname]}
        defaultSelectedKeys={[window?.location?.pathname]}
      >
        {menuList.map((val, key1) => {
          const element = val.menu.map((menu, key2) => {
            return (
              <Menu.Item
                className={
                  menu.link === activeElement
                    ? 'ant-menu-item-selected'
                    : 'ant-menu-item-back'
                }
                key={`submenu-${key1.toString()}-${key2.toString()}`}
                icon={<FontAwesomeIcon icon={customIcon[menu.icon]} />}
              >
                <NavLink
                  style={{ fontSize: '13px' }}
                  key={`nav-${key1.toString()}-${key2.toString()}`}
                  to={menu.link}
                >
                  {menu.name}
                </NavLink>
              </Menu.Item>
            )
          })
          return (
            <>
              {(val?.menuTitle && val?.menu?.length) ? (
                <Menu.Item
                  key={`menu-${key1.toString()}`}
                  className='menuTitle menuTitleHeading'
                  title={val.menuTitle}
                >
                  {val.menuTitle}
                </Menu.Item>
              ) : null}
              {element}
            </>
          )
        })}
      </Menu>
    </DivWrapper>
  )
}

export default Sidebar

const DivWrapper = styled.div`
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0px !important;
  }

  .ant-menu-inline .ant-menu-item {
    margin-top: 0px !important;
  }

  .ant-menu-inline .ant-menu-submenu-title {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .main-menu {
    padding-top: 10px !important;
  }

  .main-menu .ant-menu-inline .ant-menu-submenu {
    padding-bottom: 0px !important;
  }
  .main-menu .menuTitle {
    color: #9e9e9e;
    font-size: 14px;
    font-weight: bold;
    opacity: 1;
    margin: 10px 0px;
    padding: 5px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #89cff0;
  }
  .main-menu {
    .ant-menu-item {
      &:hover {
        color: $color-cyan-primary;

        a {
          color: $color-cyan-primary;
        }

        .anticon {
          color: $color-cyan-primary;
        }
      }
    }

    .ant-menu-item-selected {
      color: $color-cyan-primary;

      a {
        color: $color-cyan-primary !important;
      }

      .anticon {
        color: $color-cyan-primary !important;
      }
    }
  }
`
