import React, { useState, useEffect } from 'react'
import { Col, DatePicker, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import moment from 'moment'
import type { Moment } from 'moment'
import { SwapRightOutlined } from '@ant-design/icons'
import { DATE_FORMATE, REASON_IS_REQUIRED, SELECT_DATES_YOU_WANT_TO_CANCEL , Write_Cancellation_Reason , Write_Reason } from '@mainPage/EmployeeLeave/const'
import { getFullDateTime } from '@utils/common-functions'

export interface EmployeeLeaveProps {
  update: boolean;
  data?: {
    id: number;
    reason: string;
    startDate: string;
    endDate: string;
    leaveType: number;
    leaveTypeName: string;
    status: string;
    statusReason: string;
    employee: number;
    lead: number;
    approvedBy: number;
    rejectedBy: number;
    isFullLeave: boolean;
    fullCancelLeave: boolean;
  };
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddBtnDisable?: React.Dispatch<React.SetStateAction<boolean>>;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  reconsider?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  viewDetail: boolean;
  isCancel?: boolean;
}

type RangeValue = [Moment | null, Moment | null] | null

const EmployeeRemoteLeaveForm = observer(
  ({ update, data, setVisible, viewDetail,setRefreshTable , isCancel }: EmployeeLeaveProps) => {
    const [form] = Form.useForm()
    const [leaveDates, setLeaveDates] = useState([])
    const [updateLeaveDate, setUpdateLeaveDate] = useState({})
    const [arrayLeaveDate, setArrayLeaveDate] = useState([])
    const [dateCount, setDateCount] = useState(60)
    const [cancelText, setCancelText] = useState(false)
    const [dates, setDates] = useState<RangeValue>(null)
    const [hackValue, setHackValue] = useState<RangeValue>(null)
    const [value, setValue] = useState<RangeValue>(null)
    const {
      employeeRemoteLeaveModal :{setEmployeeRemoteLeaveData , updateEmployeeRemoteLeave},
      employeeRemoteCancelLeaveApprovalModal:{ halfCancelLeave },
    } = useStore(null)

    const { RangePicker } = DatePicker


    useEffect(() => {
      form.resetFields()
      if (update) {
        form.setFieldsValue({
          reason: data.reason
        })
        const leaveStartDate = data.startDate ? moment(data.startDate) : ''
        const leaveEndDate = data.endDate ? moment(data.endDate) : ''
        setLeaveDates([leaveStartDate, leaveEndDate])
        form.setFieldsValue({
          duration: [
            leaveStartDate,
            leaveEndDate,
          ]
        })
      }
      if(isCancel){
        setCancelText(true)
        form.setFieldsValue({
          reason:''
        })
      }
    }, [data, update])

    const submitData = async values => {

      let response = null
      values.start_date = moment(values.duration[0]).format(
        'YYYY-MM-DD HH:mm:ss'
      )
      values.end_date = moment(values.duration[1]).format(
        'YYYY-MM-DD HH:mm:ss'
      )
      values.is_remote = true
      delete values.duration
      delete values.leave_type_id

      if(isCancel){
        const cancelData = { 
          cancel_start_date: values.start_date,
          cancel_end_date: values.end_date,
          cancellation_reason: values.reason,
        }
        response = await halfCancelLeave(data.id, cancelData)
        if (response?.success) {
          setVisible(false)
          setRefreshTable(true);
          form.resetFields()  
        }
        return
      }

      if(update){    
          const UpdateData = {
          leave_id: data.id,
          reason: values.reason,
          start_date: getFullDateTime(values.start_date),
          end_date: getFullDateTime(values.end_date),
          }
        response = await updateEmployeeRemoteLeave(UpdateData)
      }
      if(!update && !isCancel){
        response = await setEmployeeRemoteLeaveData(values)
      }  
      if (response?.success) {
        setVisible(false)
        setRefreshTable(true);
        form.resetFields()  
      }
    }

    const disabledDate = (current: Moment) => {
      if (!dates) {
        return false
      }
      if (dates[0]) {
        const tooLate = dates[0] && current.diff(dates[0], 'days') > dateCount - 1
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > dateCount
        setDateCount(dateCount)
        return !!tooEarly || !!tooLate
      }
      else {
        return current.isBefore(moment().subtract(1, 'day'))
      }
    }

    const onOpenChange = (open: boolean) => {

      if (open) {
        setHackValue([null, null])
        setDates([null, null])
      } else {
        if (Object.keys(updateLeaveDate).length > 0) {
          setArrayLeaveDate([updateLeaveDate])
        }
        setHackValue(null)
        setUpdateLeaveDate(updateLeaveDate)
        setLeaveDates(leaveDates) 
      }
    }

    const rangePickerClick = val => {
      setValue(val)
    }
    return (
      <Form
        form={form}
        layout='vertical'
        onFinish={submitData}
        id='employee-form'
      >
        <Row>
          <Form.Item
            name={['duration']}
            label={isCancel ? SELECT_DATES_YOU_WANT_TO_CANCEL :'Duration'}
            rules={[
              !viewDetail
                ? { required: true, message: 'Duration is required!' }
                : {},
            ]}
            className='w-100 position-relative'
          >{isCancel ?  <RangePicker
              disabledDate={d =>
                !d ||
              d.isAfter(moment(data.endDate, DATE_FORMATE)) ||
              d.isBefore(moment(data.startDate, DATE_FORMATE)) ||
              !d.isAfter(moment().endOf('day'))
              }
              inputReadOnly
              defaultValue={[
                moment(data.startDate, DATE_FORMATE),
                moment(data.endDate, DATE_FORMATE)
              ]}
              className='w-100 inputField'
              disabled={[false, false]}
              value={hackValue || value}
              onChange={val => {
                setValue(val);
                setLeaveDates(val);
                setUpdateLeaveDate({
                  start_date: moment(val[0]).format(DATE_FORMATE),
                  end_date: moment(val[1]).format(DATE_FORMATE)
                });
              }}
              onCalendarChange={val => setDates(val)}
              onOpenChange={onOpenChange}
              getPopupContainer={trigger => trigger.parentElement}
            />: <RangePicker
              inputReadOnly
              value={hackValue || value}
              disabledDate={disabledDate}
              onCalendarChange={val => rangePickerClick(val)}
              onChange={val => (setValue(val), setLeaveDates(val))}
              onOpenChange={onOpenChange}
              className='w-100 inputField'
              getPopupContainer={trigger => trigger.parentElement}
            /> }
            
          </Form.Item>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name={['reason']}
              label={cancelText ? 'Cancellation Reason' :'Reason'}
              rules={[
                !viewDetail
                  ? { required: true, message: REASON_IS_REQUIRED }
                  : {},
                {
                  whitespace: true,
                  message: 'Can\'t be just whitespace',
                }
              ]}
            >
              <CommonInput
                disabled={viewDetail}
                inputType='textarea'
                className='w-100 inputField'
                placeholder={cancelText ? Write_Cancellation_Reason  : Write_Reason }
              />
            </Form.Item>
          </Col>
          {
            isCancel && value && (<Col span={24}>
              <Form.Item
                name={['leave-update']}
                label='Cancel Dates'
              >
                {
                  <div>
                    <Row>
                      <Col xs={24} lg={16}>
                        <Row>
                          <Col lg={10}>{moment(arrayLeaveDate[arrayLeaveDate.length -1]?.start_date).format(DATE_FORMATE)}</Col>
                          <Col lg={4}><SwapRightOutlined /></Col>
                          <Col lg={10}>{moment(arrayLeaveDate[arrayLeaveDate.length -1]?.end_date).format(DATE_FORMATE)}</Col>
                        </Row>
                      </Col>
                      <Col xs={24} lg={8}> {moment(arrayLeaveDate[arrayLeaveDate.length -1]?.end_date)?.diff(moment(arrayLeaveDate[arrayLeaveDate.length -1]?.start_date), 'days') + 1}</Col>
                    </Row>
                  </div>
                }
              </Form.Item>
            </Col>)
          }

        </Row>
      </Form>
    )
  }
)

export default EmployeeRemoteLeaveForm

