import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl, profileUrl } from '../const/index'
class ProjectsApi extends BaseApi {
  setProjects = async data => {
    try {
      const response = await this.axios.post(`${baseUrl}projects/`, data, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getProjects = async (num ,pageSize, queryParam={}) => {
    try {
      let url = profileUrl
      url += `?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  filterProjects = async (
    projectName,
    projectTeamFirstName,
    projectTeamDesignation,
    num
  ) => {
    try {
      const response = await this.axios.get(
        `${baseUrl}projects/?name=${projectName}&project_team__first_name=${projectTeamFirstName}&project_team__designation=${projectTeamDesignation}&page=${
          num ? num : 1
        }`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateProjects = async (data, id) => {
    try {
      const response = await this.axios.patch(`${baseUrl}projects/${id}/`, data, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteProjects = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}projects/${id}/`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ProjectsApi
