import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Form, Row, Col, DatePicker } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import SearchFilter from '@commonComponents/SearchFilter'

interface AttendanceFormProps {
  setQueryParam: (data: object) => void;
  adminLead?: boolean;
  queryParam?: any;
  canViewInterns?: boolean;
  canViewAll?: boolean;
  showInterns?: boolean;
}
export const FilterAdminView = observer((props: AttendanceFormProps) => {
  return (
    <div>
      <SearchFilter
        {...props}
        calledFrom='attendance'
        format={'YYYY-MM'}
        children={
          <div className='d-lg-flex flex-grow-1'>
            <Row gutter={10} className='rowDiv searchForm flex-grow-1'>
              {((props.adminLead || props.canViewAll) || (props.canViewInterns && props.showInterns)) && <Col xs={24} md={8} lg={8} className='cellPadding forWidth'>
                <div className='icon'>
                  <Form.Item name='name'>
                    <CommonInput
                      placeholder='Search by Name'
                      className='searchInput'
                      inputType='text'
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>
                </div>
              </Col>}
              <Col xs={24} md={8} lg={8} className='cellPadding forWidth'>
                <Form.Item name='date' className='dateAlign'>
                  <DatePicker
                    picker='month'
                    format='YYYY-MM'
                    getPopupContainer={trigger => trigger.parentElement}
                    className='form-control'
                    placeholder='Date'
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        } />
    </div>
  )
}
)
export default FilterAdminView

