import { headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class BoardApi extends BaseApi {
  setBoardHomeDetails = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}board/`,
        {
          name: data.name,
          project: data.project,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getBoardHomeDetails = async projectID => {
    try {
      const response = await this.axios.get(
        `${baseUrl}board/?projectID=${projectID}`,
        headerAuthorization(this)
      )

      return response
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateBoardHomeDetails = async (dataid, value, projectID) => {
    try {
      const response = await this.axios.patch(
        `${baseUrl}board/${dataid}/?projectID=${projectID}`,
        {
          name: value.name,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteBoardHomeDetails = async (id, projectID) => {
    try {
      const response = await this.axios.delete(
        `${baseUrl}board/${id}/?projectID=${projectID}`,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default BoardApi
