import { headerAuthorization } from '../common-utils'
import { BaseApi } from '../baseApi'
import { baseUrl, assetpermissionUrl } from '../const/index'

class AssetPermissionApi extends BaseApi {
  setassetPermissionData = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}asset_permission/`,
        data,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getassetPermission = async (num, pageSize, queryParam = {}) => {
    try {
      let url = assetpermissionUrl
      url += `?page=${num ? num : 1}&page_size=${pageSize}`
      const response = await this.axios.get(url, {
        params: queryParam ? { ...queryParam } : {},
        ...headerAuthorization(this),
      })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeeAssets = async () => {
    try {
      const url = `${baseUrl}asset_permission/get_employee_assigned_asset/`
      const response = await this.axios.get(url, {
        ...headerAuthorization(this),
      })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateEmployeeAsset = async (data, id) => {
    try {
      const response = await this.axios.patch(
        `${baseUrl}asset_permission/${id}/`,
        data,
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteEmployeeAsset = async id => {
    try {
      const response = await this.axios.delete(
        `${baseUrl}asset_permission/${id}/`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}
export default AssetPermissionApi
