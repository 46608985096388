import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Col, Form, Row, DatePicker, Checkbox } from 'antd'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import moment from 'moment'
import { formatDateValue, isCurrentUsersId } from '@utils/common-functions'
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader'
import CustomButton from '@commonComponents/button'
import { MdAddCircleOutline, MdClose } from 'react-icons/md'
import { ISO_DATE_FORMAT } from '@mainPage/constant'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { colors } from 'constant/colors'

const { TealishBlue, lightGray, black } = colors

interface CertificationData {
  title: string;
  authority: string;
  issue_date: string;
  expire_date: string;
  license_number: string;
  url: string;
  expires: boolean;
}

interface CertificationInfo {
  id: string;
  employeeId: string;
  title: string;
  authority: string;
  issueDate: string;
  expireDate: string | null;
  licenseNumber: string | null;
  url: string | null;
  expires: boolean;
}

interface Certification {
  empId?: string;
  closeDrawer: () => void;
}

interface CertificationForm {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: any;
  closeDrawer: () => void;
  data?: CertificationInfo;
  formId?: string;
  method: string;
  empId: string;
}

const Certification = observer(({ empId, closeDrawer }: Certification) => {
  const [showForm, setShowForm] = useState(false)
  const ref = useRef(null)
  const canModify = isCurrentUsersId(empId)

  const {
    certificateDetails: {
      certificateData,
      loadCertificateDetails,
      setCertificateDetails,
      deleteCertificateDetails,
      updateCertificateDetails,
      isLoading,
    },
    themesModel: { getColorsData },
  } = useStore(null)
  const isDarkTheme = getColorsData?.theme === 'dark'

  const addForm = () => {
    setShowForm(true)
    setTimeout(() => {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      })
    }, 500)
  }

  const load = async () => {
    await loadCertificateDetails(empId)
  }

  useEffect(() => {
    if (!certificateData?.length) {
      load()
    }
  }, [empId])

  useEffect(() => {
    if (!certificateData?.length && !showForm) {
      setShowForm(true)
    }
  }, [certificateData, showForm])

  const deleteCertificate = async (id: string) => {
    const res = await deleteCertificateDetails(id)
    if (res?.success) {
      load()
    }
  }

  const onUpdate = async (
    formData: CertificationData,
    expires: boolean,
    id: string
  ) => {
    const data = { ...formData }
    formatDateValue(data, ISO_DATE_FORMAT)
    for (const key in data) {
      // remove falsy values from 'data'
      if (!data[key]) {
        delete data[key]
      }
    }
    if (!expires) {
      data['expire_date'] = null
    }
    data['expires'] = expires
    const res = await updateCertificateDetails(id, data)
    if (res?.success) {
      load()
    }
  }

  const onCreate = async (formData: CertificationData, expires: boolean) => {
    const data = { ...formData }
    formatDateValue(data, ISO_DATE_FORMAT)
    for (const key in data) {
      // remove falsy values from 'data'
      if (!data[key]) {
        delete data[key]
      }
    }
    if (!expires) {
      delete data['expire_date']
    }
    data['expires'] = expires
    data['employee_id'] = empId
    const res = await setCertificateDetails(data)
    if (res?.success) {
      setShowForm(false)
      load()
    }
  }

  if (!certificateData?.length && isLoading) {
    return <SkeletonRingLoader width='100%' height='50px' align='center' />
  }

  return (
    <Wrapper isDarkTheme={isDarkTheme} ref={ref}>
      <div className={'addMore'}>
        <AddButton onClick={addForm} isDarkTheme={isDarkTheme} disabled={!canModify}>
          <MdAddCircleOutline
            fill={getColorsData?.bodyColor || 'blue'}
            className='add-icon'
          />
        </AddButton>
      </div>

      {/* Mapping over fetched certificate details to render a form for each one to update it */}
      {certificateData?.map((data, index) => (
        <Fragment key={data.id}>
          <div
            className='d-flex align-items-center mb-1 mt-3 details'
            style={{ gap: '16px' }}
          >
            <h5 className='titleName mt-2' style={{ whiteSpace: 'nowrap' }}>
              Certificate Detail {index + 1}
            </h5>
            <div className='separator' />
            <button
              type='button'
              className='deleteBtn d-flex align-items-center'
              onClick={() => deleteCertificate(data?.id)}
              disabled={!canModify}
            >
              <MdClose className='deleteIcon' />
            </button>
          </div>
          <CertificationForm
            data={data}
            onSubmit={(formData, expires) =>
              onUpdate(formData, expires, data?.id)
            }
            method='Update'
            closeDrawer={closeDrawer}
            empId={empId}
          />
        </Fragment>
      ))}

      {showForm && (
        <>
          <div
            className='d-flex align-items-center mb-1 mt-3 details'
            style={{ gap: '16px' }}
          >
            <h5 className='titleName mt-2' style={{ whiteSpace: 'nowrap' }}>
              Certificate Detail {(certificateData?.length || 0) + 1}
            </h5>
            <div className='separator' />
            <button
              type='button'
              className='deleteBtn d-flex align-items-center'
              onClick={() => setShowForm(false)}
              disabled={!canModify}
            >
              <MdClose className='deleteIcon' />
            </button>
          </div>
          <CertificationForm
            formId='add-bank-detail'
            onSubmit={(formData, expires) => onCreate(formData, expires)}
            method='Add'
            closeDrawer={closeDrawer}
            empId={empId}
          />
        </>
      )}
    </Wrapper>
  )
})

export default Certification

const CertificationForm = ({
  data,
  formId,
  onSubmit,
  method,
  closeDrawer,
  empId,
}: CertificationForm) => {
  const [form] = Form.useForm()
  const [expires, setExpires] = useState(true)
  const {
    themesModel: { getColorsData },
  } = useStore(null)
  const buttonColor = getColorsData?.bodyColor
  const canModify = isCurrentUsersId(empId)

  const setFieldValues = () => {
    form.setFieldsValue({
      authority: data?.authority || '',
      title: data?.title || '',
      url: data?.url || '',
      expire_date: data?.expires ? moment(data?.expireDate) : '',
      issue_date: data?.issueDate ? moment(data?.issueDate) : '',
      license_number: data?.licenseNumber || '',
    })
    setExpires(data?.expires ?? true) // data.expires maybe undefined
  }

  useEffect(() => {
    setFieldValues()
  }, [data])

  const onCheckboxChange = (boxChecked: boolean) => {
    setExpires(boxChecked);
    if (boxChecked === true) {
      form.setFieldValue('expire_date', '');
      form.setFields([
        {
          name: ['expire_date'],
          errors: [],
        },
      ]);
    } else {
      form.setFields([
        {
          name: ['expire_date'],
          errors: [],
        },
      ]);
    }
  };

  return (
    <Form
      id={data?.id || formId}
      layout='vertical'
      onFinish={data => onSubmit(data, expires)}
      form={form}
      disabled={!canModify}
    >
      <Labelalign gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['title']}
            label='title'
            style={{
              position: 'relative',
            }}
            rules={[
              validateRequired('Certification Title'),
              validateNoWhiteSpace()
            ]}
          >
            <CommonInput className='inputField' inputType='text' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['authority']}
            label='Certification Authority'
            style={{
              position: 'relative',
            }}
            rules={[
              validateRequired('Certification Authority'),
              validateNoWhiteSpace()
            ]}
          >
            <CommonInput className='inputField' inputType='text' />
          </Form.Item>
        </Col>
      </Labelalign>
      <Labelalign gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['issue_date']}
            label='Issue Date'
            style={{
              position: 'relative',
            }}
            rules={
              [
                validateRequired('Issue date'),
                {
                  message: 'Issue Date Can\'t be greater than Expiry Date',
                  validator: (_, value) => {
                    // to ensure that issue date is greater than expiry date
                    const endDate = form.getFieldValue('expire_date')
                    if (endDate) {
                      const endingDate = moment(endDate)
                      const isAfter = value?.isAfter(endingDate)
                      if (isAfter) {
                        return Promise.reject()
                      }
                    }
                    return Promise.resolve()
                  }
                }
              ]
            }
          >
            <DatePicker
              format={ISO_DATE_FORMAT}
              getPopupContainer={trigger => trigger.parentElement}
              className='form-control'
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['expire_date']}
            label='Expire Date'
            style={{
              position: 'relative',
            }}
            rules={[expires ? validateRequired('Expire Date') : {}]}
          >
            <DatePicker
              format={ISO_DATE_FORMAT}
              getPopupContainer={trigger => trigger.parentElement}
              className='form-control'
              disabled={!canModify || !expires}
            />
          </Form.Item>
        </Col>
      </Labelalign>
      <Labelalign gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['license_number']}
            label='License Number'
            style={{
              position: 'relative',
            }}
          >
            <CommonInput className='inputField' inputType='text' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['url']}
            label='Certificate URL'
            style={{
              position: 'relative',
            }}
          >
            <CommonInput className='inputField' inputType='text' />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            style={{
              position: 'relative',
            }}
          >
            <Checkbox
              checked={!expires}
              onChange={e => onCheckboxChange(!e.target.checked)}
              style={{
                color: getColorsData?.theme === 'dark' ? '#fff' : '#000',
              }}
            >
              This certificate doesn't expire.
            </Checkbox>
          </Form.Item>
        </Col>
      </Labelalign>
      <Labelalign gutter={24}>
        <Aligncontent>
          <Styledbutton
            title='Cancel'
            htmlType='button'
            size='small'
            type='primary'
            onClick={closeDrawer}
            style={{ backgroundColor: buttonColor }}
          />
          <Styledbutton
            form={data?.id || formId}
            title={`${method} Detail`}
            htmlType='submit'
            size='small'
            key='submit'
            type='primary'
            style={{ backgroundColor: buttonColor }}
          />
        </Aligncontent>
      </Labelalign>
    </Form>
  )
}

const AddButton = styled.button<{ isDarkTheme?: boolean }>`
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props?.isDarkTheme ? '#000' : '')};

  .add-icon {
    width: 20px;
    height: 20px;
  }
`

const Labelalign = styled(Row)`
  padding: 0 16px 0 16px !important;
  .ant-form-item-label {
    padding: 0 3px 3px !important;
  }
`

const Aligncontent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
`

const Styledbutton = styled(CustomButton)`
  background-color: blue;
  border-radius: 5px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  min-width: 100px;
  font-weight: 500 !important;
  padding: 16px;

  &:hover {
    color: white !important;
    background-color: blue;
  }

  &:focus {
    background-color: blue;
  }
`

const Wrapper = styled.div<{ isDarkTheme?: boolean }>`
  padding: 12px 0;
  .addMore {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin: -23px 0 20px 0;
    z-index: 12;
    position: relative;
    img {
      cursor: pointer;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: ${TealishBlue};
      word-spacing: 0px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
  .separator {
    height: 0.5px;
    flex-grow: 1;
    background-color: ${props => (props?.isDarkTheme ? '#fff' : '#1f1f1f')};
  }
  .details {
    padding: 0 16px 0 16px;
  }
  .titleName {
    color: ${props => (props?.isDarkTheme ? '#fff' : '#000')};
    white-space: nowrap;
  }
  .deleteBtn {
    outline: none;
    border: none;
    background-color: ${props =>
    props?.isDarkTheme ? black : lightGray};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;

    :hover {
      background-color: #fa9494;
    }
  }

  .deleteIcon {
    width: 18px;
    height: 18px;
    fill: #f42c2c;
  }
`
