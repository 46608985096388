import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { flow, types } from 'mobx-state-tree'
import {employeePayrollApi} from '@api'
import {exportCsv, exportPdf} from '@utils/common-functions';

export const payrollStat = types.model({
  totalEmployees: types.maybeNull(types.number),
  totalSalary: types.maybeNull(types.number),
  currentMonthClosed: types.maybeNull(types.boolean),
})


export const payrollAllowanceType = types.model({
  id: types.maybeNull(types.string),
  basicPayroll: types.maybeNull(types.string),
  employeeName: types.maybeNull(types.string),
  wageType: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  isDeduction: types.maybeNull(types.boolean),
})

export const payrollType = types.model({
  id: types.maybeNull(types.string),
  employee: types.maybeNull(types.string),
  employeeName: types.maybeNull(types.string),
  employeeEmployeeID: types.maybeNull(types.string),
  recommendedBy: types.maybeNull(types.string),
  payslip: types.maybeNull(types.string),
  basicSalary: types.maybeNull(types.number),
  salaryStartDate: types.maybeNull(types.string),
  incrementedDate: types.maybeNull(types.string),
  incrementValue: types.maybeNull(types.number),
  bonus: types.maybeNull(types.number),
  netAllowance: types.maybeNull(types.number),
  netDeduction: types.maybeNull(types.number),
  netSalary: types.maybeNull(types.number),
  allowances: types.maybeNull(types.array(payrollAllowanceType)),
})

export const employeePayroll = types
  .model({
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    erorMessage: types.maybeNull(types.string),
    loadingPay: types.optional(types.boolean, false),
    empPayroll: types.maybeNull(payrollType),
    nextSal: types.maybeNull(types.number),
    payrollStat: types.maybeNull(payrollStat),
  })
  .views(self => ({
    get getPayrollStat() {
      return toJS(self.payrollStat)
    },
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const createEmployeePayroll = flow(function* (data) {
      try {
        self.loading = true
        const res =  yield employeePayrollApi.createEmployeePayroll(data)
        notification.success('Salary Added Successfully')
        self.loading = false
        return { success: true, res: res }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        if (error?.response?.status == 400) {
          self.erorMessage = error?.response?.data?.message;
        }
        self.loading = false
        return { success: false }
      }
    })

    const updateEmployeePayroll = flow(function* (data, payrollId) {
      try {
        self.loading = true
        const response = yield employeePayrollApi.updateEmployeePayroll(data, payrollId)
        notification.success('Salary updated Successfully')
        self.loading = false
        return {success: true, res: response}
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const retrieveEmployeePayroll = flow(function* (payrollId, isProfile) {
      try {
        self.loading = true
        const response = yield employeePayrollApi.retrieveEmployeePayroll(payrollId)
        self.loading = false
        return {success: true, res: response}
      } catch (error) {
        isProfile? '' : notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const updateEmployeeIncrementOrBonus = flow(function* (data, type) {
      try {
        self.loading = true
        yield employeePayrollApi.updateEmployeeIncrementOrBonus(
          data,
          type
        )
        notification.success('Employee payroll updated successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const getEmployeePayrollHistory = flow(function* (payrollId) {
      try {
        self.loading = true
        const response = yield employeePayrollApi.getEmployeePayrollHistory(payrollId)
        return { success: true, res: response }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false, error:error }
      } finally {
        self.loading = false
      }
    })

    const exportPayrollRecord = flow(function* (format,queryParam='',) {
      try {
        self.loading = true
        const response = yield employeePayrollApi.exportPayrollRecord(queryParam,format)
        if (format === 'pdf') {
          exportPdf(response)
        } else if (format === 'csv') {
          exportCsv(response)
        }
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const closeCurrentMonthPayroll = flow(function* () {
      try {
        self.loading = true
        yield employeePayrollApi.closeCurrentMonthPayroll()
        notification.success('Current Month has been closed')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const errorMsgClear = ()=>{
      self.erorMessage = null
    }

    return {
      exportPayrollRecord,
      createEmployeePayroll,
      updateEmployeePayroll,
      retrieveEmployeePayroll,
      closeCurrentMonthPayroll,
      getEmployeePayrollHistory,
      updateEmployeeIncrementOrBonus,
      errorMsgClear,
    }
  })

export function initEmployeePayroll() {
  return employeePayroll.create({})
}
