import React, { useState } from 'react'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import { observer } from 'mobx-react'
import 'antd/dist/antd.css'
import CustomButton from '@commonComponents/button'
import OrderMenu from './OrderMenu'
import EcanteenTable from './ecanteenTable'
import CustomDrawer from '@commonComponents/drawer'
import { Space } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import ShowOnPermission from '@commonComponents/permit'
import { ADD_METHOD, CANTEEN_MOD } from 'constant/constant'
import styled from 'styled-components'

const Ecanteen = observer(() =>
{
  const [isPayloadModelVisible, setPayloadModelVisible] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [data, setData] = useState([])


  const onAdd = () =>
  {
    setPayloadModelVisible(true)
  }

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='d-flex'>
              <BreadCrumbs
                list={[
                  { name: 'Dashboard', link: constRoute.home },
                  { name: 'E-Canteen', link: constRoute.eCanteen },
                ]}
                isAlpha
                pageTitle={`E-Canteen`}
              >
              </BreadCrumbs>
              <div>
                <ShowOnPermission mod={CANTEEN_MOD} method={ADD_METHOD}>
                  <CustomButton title='Order' onClick={onAdd} />
                </ShowOnPermission>
              </div>
            </div>
          </div>
        </div>
        <EcanteenTable
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          setPayloadModelVisible={setPayloadModelVisible}
          setData={setData}
        />
        {isPayloadModelVisible && (
          <CustomDrawer
            title='Place Order'
            className='addDrawer'
            setVisible={() =>
            {
              setPayloadModelVisible(false)
              setData([])
              setIsUpdate(false)
            }}
            visible={isPayloadModelVisible}
            width={600}
            customFooter={
              <Space>
                <CustomButton
                  htmlType='submit'
                  type='primary'
                  title={'Cancel'}
                  className='cancelButton'
                  onClick={() =>
                  {
                    setPayloadModelVisible(false)
                    setData([])
                    setIsUpdate(false)
                  }}
                />
                <CustomButton
                  form='order-form'
                  icon={<ShoppingCartOutlined />}
                  title={isUpdate ? 'Update' : 'Add To Cart'}
                  htmlType='submit'
                  type='primary'
                />
              </Space>
            }
          >
            <OrderMenu
              isUpdate={isUpdate}
              setIsUpdate={setIsUpdate}
              setRefreshTable={setRefreshTable}
              setPayloadModelVisible={setPayloadModelVisible}
              data={data}
            />
          </CustomDrawer>
        )}
      </div>
    </div>
  )
})

export default Ecanteen


export const AlphaSpan = styled.span`
  background:rgb(45, 113, 216);
  color: white !important;
  border-radius: 5px;
  margin-left: 12px;
  padding: 5px;
  font-weight: 100;
  font-size: 10px;
`
