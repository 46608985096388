import { Card, Col, Row, Typography, Form } from 'antd'
import PhoneInput from 'react-phone-input-2'
import { Skeleton } from 'antd'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { CommonInput } from '@commonComponents/input'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import { observer } from 'mobx-react-lite'
import DisplayPicture from '@commonComponents/avatar'
import { colors } from 'constant/colors';

const { mediumGray } = colors


const { Title } = Typography
const accountSettings = observer(() => {
  const [form] = Form.useForm();
  const [isSkLoading, setIsSkLoading] = useState(true);
  const [getterCall, setGetterCall] = useState(false)


  const navigate = useNavigate()
  const {
    profileDetails: { profileData, loadProfileDetails },
    userInfo: { getcompany },
  } = useStore(null)

  useEffect(() => {
    async function callData() {
      setIsSkLoading(true);
      await loadProfileDetails(getcompany?.employeeId, true,true,false);
    }
    callData();
    getterCall && setGetterCall(false);
  }, [getterCall])


  const color = JSON.parse(localStorage.getItem('colorTheme'))
  function load() {
    setIsSkLoading(false);
  }
  const settingFormHandler = async data => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    setGetterCall(true);
    load();
  };

  useEffect(() => {
    load();
  }, [profileData])

  return (
    <AccountSetting >
      <Form form={form} name='basic' layout={'vertical'} onFinish={settingFormHandler}>
        <Row gutter={24}>
          <Col xs={24} lg={16}>
            {isSkLoading &&
              <Card
              >
                <Skeleton active paragraph={{ rows: 6 }} loading={isSkLoading} />
              </Card>}
            {!isSkLoading && <AccountForm >
              <div>
                <Avatarstyling className={'newAvatarImg'} >
                  <DisplayPicture
                    name={''}
                    size={75}
                    style={{ margin: 10 }}
                  />
                </Avatarstyling>
              </div>
              <Row gutter={24}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item name='employee_first_name' label='First Name'
                    rules={[{ required: true, message: <span style={{ color: 'red' }}>
                        Please input your first name
                      </span> }]}
                  >
                    <CommonInput type='text' />

                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item name='employee_last_name' label='Last Name'
                    rules={[{ required: true, message: <span style={{ color: 'red' }}>
                        Please input your last name
                      </span> }]}
                  >
                    <CommonInput type='text' />

                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item name='employee_email' label='Email'
                    rules={[{ required: true, message:
                        <span style={{ color: 'red' }}>
                          Please input your Email
                        </span>}]}
                  >
                    <CommonInput disabled={true} type='email' />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item name={'employee_mobile_number'} label='Phone '
                    rules={[{ required: true, message:
                        <span style={{ color: 'red' }}>
                          Please input your phone
                        </span>}]}
                  >
                    <PhoneInput
                      country={'pk'}
                      dropdownStyle={{ backgroundColor: color?.theme === 'dark' ? 'black' : 'white', padding: 10 }}
                      inputStyle={{ backgroundColor: color?.theme === 'dark' ? 'transparent' : 'white', color: color?.theme === 'dark' ? 'white' : 'black' }}
                      placeholder={'+92 3XX-XXXXXXXX'}
                      buttonStyle={{ borderWidth: '1px', zIndex: 9, backgroundColor: color?.theme === 'dark' ? 'black' : 'white' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className='d-flex justify-content-end mt-3'>
                <button
                  style={{ backgroundColor:  color?.bodyColor ,color:'white',border: 'none', outline: 'none',borderRadius:20 ,padding:10} }  type='submit'>Save Changes</button>
              </div>
            </AccountForm>}
          </Col>

          <Col xs={24} lg={8}>
            {isSkLoading && <Card >
              <Skeleton active paragraph={{ rows: 3 }} loading={isSkLoading} />
            </Card>}
            {isSkLoading && <Card
            >
              <Skeleton active paragraph={{ rows: 3 }} loading={isSkLoading} />
            </Card>}
            {!isSkLoading && <ChangePasswordCard
            >
              <Title level={3}>Change Password</Title>
              <p>
                Choose Password. You might need to
                sign in again. Select Change
                Password, then Enter your new password.
              </p>
              <div
              >
                <button onClick={() => navigate(`${constRoute.reset}`)} style={{width:'100%',color:'white', backgroundColor:  color?.bodyColor ,border: 'none', outline: 'none',borderRadius:20 ,marginTop:10 ,padding:8}}>Change Password</button>
              </div>
              <div>

              </div>
            </ChangePasswordCard>}
          </Col>

        </Row>
      </Form>
    </AccountSetting>
  )
})
export default accountSettings

const Avatarstyling = styled.div`
span{
span{
  line-height: unset !important;
}
}
`

const AccountSetting = styled.div`
margin: 30px 0 30px 0;
.accountForm {
  border-radius: 12px;
  padding-bottom: 100px;
  .profile {
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 576px) {
      flex-direction: column;
      justify-content: center;
    }
    .avatar {
      &:hover .edit-icon-container {
        display: flex;
        cursor: pointer;
        visibility: visible;
        opacity: 1;
      }
      width: 72px;

      height: 72px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      .edit-icon-container {
        position: absolute;
        left: 0;
        bottom: 1px;
        background: rgba(20, 18, 18, 0.323);
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: color 0.3s;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2px 0 4px 0px;
        &::before {
          content: '\f030';
          font-family: fontAwesome;
          color: white;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .sublabel {
    font-size: 15px;
    color: #65c4f0;
    margin-top: 5px;
  }
  .linkedText {
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #a0a0a0;
    }
  }
  .connectAccount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    :global(.ant-btn) {
      width: 104px;
      height: 45px;
      border: 1px solid ${mediumGray};
      color: #7460ee;
      font-size: 1rem;
    }
  }
}
.changePassword {
  border-radius: 12px;
  p {
    font-weight: 200;
    font-size: 13px;
    line-height: 19px;
    color: #898888;
    text-align: left;
  }
  .passwordButton {
    padding: 40px 0px 20px !important;
    @media (max-width: 320px) {
      padding: 20px 0;
    }
    :global(.ant-btn) {
      height: 46px;
      border: 1px solid #4abdde !important;
      border-radius: 100px;
      color: $color-cyan-primary;
      font-size: 16px;
      height: 40px !important;
    }
  }
}
`

const AccountForm = styled(Card)`
  padding: 20px !important;
  padding-bottom: 60px !important;
`

const ChangePasswordCard = styled(Card)`
  padding: 20px !important;
    p {
      white-space: pre-line;
    }
`
