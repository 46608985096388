import React, { useMemo, useState } from 'react'
import { Form, Row, Col, DatePicker, Select } from 'antd'
import CustomButton from '@commonComponents/button'
import { CommonInput } from '@commonComponents/input'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import countryList from 'react-select-country-list'
import moment from 'moment'

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

const ClientProfileForm = observer(props => {
  const [isLoading, setIsLoading] = useState(false)
  const options = useMemo(() => countryList().getData(), [])
  const [form] = Form.useForm()
  const { Option } = Select

  const {
    clientProfileDetails: { setClientProfile, loadClientProfile },
  } = useStore(null)

  const submitData = async values => {
    let response = null
    const formData = new FormData()
    values.birth_date.toString()
    formData.append('client', props.clientID)
    formData.append('country', props.country)
    formData.append('client_company', values.client_company)
    formData.append('client_designation', values.client_designation)
    formData.append('birth_date', values.birth_date.format('YYYY-MM-DD'))
    formData.append('gender', values.gender)
    setIsLoading(true)
    response = await setClientProfile(formData)
    if (response?.success) {
      await loadClientProfile(1)
      form.resetFields()
      props.setVisible(false)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Form
        layout='vertical'
        form={form}
        onFinish={submitData}
        validateMessages={validateMessages}
      >
        <div>
          <Row className='rowDiv'>
            <Col span={12}>
              <Form.Item label='Country' name='country'>
                <Select options={options} placeholder='Select Country' />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label='Client Company' name='client_company'>
                <CommonInput
                  className='form-control inputField'
                  inputType='text'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className='rowDiv'>
            <Col span={12}>
              <Form.Item name='client_designation' label='Client Designation'>
                <CommonInput
                  className='form-control inputField'
                  inputType='text'
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                name='birth_date'
                label='Date of birth'
                style={{
                  position: 'relative',
                }}
              >
                <DatePicker
                  format='YYYY-MM-DD'
                  getPopupContainer={trigger => trigger.parentElement}
                  className='form-control inputField'
                  onFocus={e => {
                    if (!e.target.value) {
                      form.setFieldsValue({ birth_date: moment(new Date()) })
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className='rowDiv'>
            <Col span={12}>
              <Form.Item name='gender' label='Gender'>
                <Select>
                  <Option value={'Male'}>Male</Option>
                  <Option value={'Female'}>Female</Option>
                  <Option value={'Other'}>Other</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className='submitBtn'>
            <CustomButton
              type='primary'
              htmlType='submit'
              title='Submit'
              loading={isLoading}
              disabled={isLoading}
            ></CustomButton>
          </Form.Item>
        </div>
      </Form>
    </>
  )
})
export default ClientProfileForm
