import BreadCrumbs from '@commonComponents/breadcrums'
import CustomButton from '@commonComponents/button'
import ProfileSideDrawer from '@mainPage/newEmployee/ProfileSideDrawer'
import { useStore } from '@stores/root-store'
import { permit } from '@utils/permission/permission'
import { constRoute } from '@utils/route'
import { Card, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import styled from 'styled-components'
import EmployeeBankDetailProf from './employeeProfBankDetail'
import EmployeeCertificateProf from './employeeProfCertificate'
import EmployeeEdu from './employeeProfEducation'
import EmployeeExperienceProf from './employeeProfExperience'
import EmployeeIntro from './employeeProfIntro'
import { checkUUID } from '@utils/common-functions'


const NewPage = observer(() =>
{
  
  const [visible, setVisible] = useState(false)
  const { id: employeeID } = useParams()

  
  const {
    userInfo: { getRoles },
  } = useStore(null)
  const hasUpdatePermission = permit(getRoles, 'employee', 'put')
  const isValidId = checkUUID(employeeID)
  const [record, setRecord] = useState(null);

  const canModify = hasUpdatePermission
 
  const list_items = [
    { name: 'Employee', link: constRoute.newEmployee },
    { name: 'Profile', link: '' },
  ]

  if (!isValidId) return <Navigate to='/' />

const profileRecord = data => {
  useEffect(() => {
    setRecord(data)
  },[data])
}


  return (
    <>
      <div className='page-wrapper'>
        {/* Page Content */}
        <div className='content container-fluid'>
          {/* Page Header */}
          <div className='page-header'>
            <div className='d-flex justify-content-between'>
              <BreadCrumbs
                list={list_items}
                pageTitle={'Profile'}
              >
              </BreadCrumbs>
              { canModify && record?.isActive && (
                <div>
                  <CustomButton
                    title='Edit Profile'
                    onClick={() =>
                    {
                      setVisible(true)
                    }}
                  />
              </div>
              )}
            </div>
          </div>
          <StyledDiv>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={24} lg={7}>
                <EmployeeIntro profileRecord={profileRecord}/>
              </Col>
              <Col xs={24} sm={24} md={24} lg={17}>
                <Card className={'employeeInfo'}>
                  <EmployeeBankDetailProf />
                  <hr />
                  <EmployeeEdu />
                  <EmployeeExperienceProf />
                  <EmployeeCertificateProf />
                </Card>
              </Col>
            </Row>
          </StyledDiv>
            <ProfileSideDrawer isVisible={visible} setIsvisible={setVisible} setRefreshTable={()=>{setVisible(false)}} empId={employeeID} />
        </div>
   
      </div>
    </>
  )
})

export default NewPage

const StyledDiv = styled.div`
  .employeeInfo {
    font-family: 'Outfit';
    min-height: 100%;
    height: auto;
  }

`