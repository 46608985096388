
import { BaseApi } from '../baseApi'
import { bankInfoUrl } from '../const'

interface BankData {
  employee_id?: string;
  title: string;
  number: string;
  iban: string;
}

class EmployeeBankApi extends BaseApi {
  setBankDetails = async (data: BankData) => {
    try {
      const response = await this.axios.post(bankInfoUrl, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getEmployeeBankDetails = async (employee_id: string) => {
    try {
      const url = `${bankInfoUrl}/${employee_id}`
      const response = await this.axios.get(url)
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeeBankDetails = async (id: string, data: BankData) => {
    try {
      const url = `${bankInfoUrl}/${id}`
      const response = await this.axios.put(url, data)
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  deleteEmployeeBankDetails = async (id: string) => {
    try {
      const url = `${bankInfoUrl}/${id}`
      const response = await this.axios.delete(url)
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeBankApi
