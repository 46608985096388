export const isProduction = () => {
  const url = window.location.href
  return url.includes('https://oms.enigmatix.co')
}
export const isProductionServer = isProduction()
export const baseUrl = process.env.REACT_APP_BASE_URL
export const baseurlV2 = process.env.REACT_APP_BASE_URL_V2
export const baseUrlStart = process.env.REACT_APP_BASE_URL_START
export const imgBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL
// micro-services URLS
export const baseServiceUrl = process.env.REACT_APP_BASE_SERVICE_URL;
export const accountServiceUrl = process.env.REACT_APP_ACCOUNT_SERVICE_URL;
export const employeeServiceUrl = process.env.REACT_APP_EMPLOYEE_SERVICE_URL; 
export const hrServicesUrl = process.env.REACT_APP_HR_SERVICE_URL;
export const administrationUrl = process.env.REACT_APP_ADMINISTRATION_SERVICE_URL;

// encryption key
export const secretEncryptionKey = process.env.REACT_APP_ENCRYPTION_KEY
// Employee Profile API URL constants
export const feedbackQuestionUrl = `${baseUrl}question/`
export const feedbackUrl = `${baseUrl}feedback/`
export const bankUrl = `${baseUrl}bank_information/`
export const certificationUrl = `${baseUrl}employee_certificate/`
export const educationUrl = `${baseUrl}education_information/`
export const emergencyUrl = `${baseUrl}emergency_contact/`
export const experienceUrl = `${baseUrl}experience_information/`
export const familyUrl = `${baseUrl}family_information/`
export const personalUrl = `${baseUrl}personal_information/`
export const profileUrl = `${baseUrl}employee_profile/`
export const projectsUrl = `${baseUrl}employee_projects/`
export const projects_Url = `${baseUrl}projects/`
export const payrollUrl = `${baseUrl}payroll/`
export const payrollCsvUrl = `${baseUrl}payroll_csv/`
export const payrollPdfUrl = `${baseUrl}payroll/payroll_export/?file_type=pdf`
export const payrollHistoryUrl = `${baseUrl}payroll_history/`
export const payrollClosing = `${baseUrl}payroll_closing/`
export const employeeOpenListUrl = `${baseUrl}employee_list/`
export const assetLeadUrl = `${baseUrl}employee/get-all-leads/`
export const clientUrl = `${baseUrl}client/`
export const ngrok = 'http://192.168.9.229:8000/api/v2/client/'
export const loanUrl = `${baseUrl}employee_loan/`
export const dashboardAttendanceUrl = `${baseUrl}attendance_list/user-attendance/`
export const expenseUrl = `${baseUrl}expense/`
export const simpleExpenseCsvUrl = `${baseUrl}expense/expense_export/`
export const simpleExpensePdfUrl = `${baseUrl}expense/expense_export/?file_type=pdf`
export const employeeExpenseCsvUrl = `${baseUrl}expense/employee_expense/?file_export=true`
export const employeeExpensePdfUrl = `${baseUrl}expense/employee_expense/?file_export=true&file_type=pdf`
export const totalExpenseAmountUrl = `${baseUrl}expense/total_expense/`
export const expenseCsvUrl = `${baseUrl}expense/expense_export/`
export const employeesPDFUrl = `${baseUrl}employee/employee_export/?export_file=true&file_type=pdf`
export const applyEmployeeLeaveUrl = `${baseUrl}apply_employee_leave/`
export const assetApprovalUrl = `${baseUrl}asset/`
export const assetCsvUrl = `${baseUrl}asset/employee_asset/?file_export=true`
export const assetPdfUrl = `${baseUrl}asset/employee_asset/?file_export=true&file_type=pdf`
export const assetApprovalPdf = `${baseUrl}asset/asset_export/?file_type=pdf`
export const assetApprovalCsv = `${baseUrl}asset/asset_export`
export const wagesTypeUrl = `${baseUrl}wages_type/`
export const reportUrl = `${baseUrl}get_employee_all_reports/`
export const empAPIUrl = `${baseUrl}employee/`
export const empAPIUrlHierarchy = `${baseurlV2}employee/?page=1&page_size=20`
export const attHieraricyUrl = `${baseUrl}all_employee_attendance/attendance_hierarchy/`
export const attendanceV2Url = `${baseurlV2}employee_attendance/`

export const assetHistoryUrl = `${baseUrl}asset_history/`
export const salaryIncrementUrl = `${baseUrl}increment_salary/`
export const basicSalaryUrl = `${baseUrl}basic_salary/`
export const projectsClientsUrl = `${baseUrl}projects/`
export const projectSprint = `${baseUrl}api/v1/sprints/`
export const allEmployeeAttendance = `${baseUrl}all_employee_attendance/`
export const serialNumberUrl = `${baseUrl}asset_serial_numbers_list`
export const clearanceEmployeeData = `${baseUrl}asset_list/`
export const eCanteenUrl = `${baseUrl}meal_order/`
export const eCanteenmenuUrl = `${baseUrl}meal_menu/`
export const refreshTokenUrl = `${baseUrl}refreshtoken/`
export const LEAVE_API = `${baseurlV2}leads`
export const employeeresignUrl = `${baseUrl}employee_clearance/employee_resign_or_terminate_list/`
export const employeehistoryUrl = `${baseUrl}employee/employee-history`
export const assetHandlingUrl = `${baseUrl}asset_handling/`
export const assetpermissionUrl = `${baseUrl}asset_permission/`
export const newDashboardUrl = `${baseUrl}dashboard_detail`

//base services URLs
export const getUserUrl = `${baseServiceUrl}employee/?login=true`
export const loginUrl = `${baseServiceUrl}login/`
export const branchUrl = `${baseServiceUrl}branch/`
export const departmentUrl = `${baseServiceUrl}department/`
// export const employmentCompanyUrl = `${baseServiceUrl}company/`
export const designationUrl = `${baseServiceUrl}designation/`
export const sectionUrl = `${baseServiceUrl}section/`
export const userLevelUrl= `${baseServiceUrl}user-level/`
export const shiftUrl = `${baseServiceUrl}shift/`
export const gradeLevelUrl = `${baseServiceUrl}grade-level/`
export const passwordResetUrl = `${baseServiceUrl}password_reset/`
export const confirmPasswordUrl = `${baseServiceUrl}password_reset/confirm/`
export const rolePermissionUrl = `${baseServiceUrl}role/`
export const roleDefaultPermissions = `${baseServiceUrl}default-permissions/`
export const notificationUrl = `${baseServiceUrl}user_fcm_device_add/`
export const employeeProfileUrl = `${employeeServiceUrl}employee_profile/`
export const adminEmployeeProfileUrl = `${baseServiceUrl}admin-employee-profile/`

//employee in base
export const employeeBaseUrl = `${baseServiceUrl}employee/`
export const employmentCompanyUrl = `${baseServiceUrl}employment-model/`

// employee service URLS
export const dashboardUrl =  `${employeeServiceUrl}dashboard`
export const FeedBackUrl = `${employeeServiceUrl}feedback`
export const singleFeedBackUrl = `${employeeServiceUrl}active/feedback`
export const employeeFeedBackUrl = `${employeeServiceUrl}employee-feedback`
export const feedbackQuestion = `${employeeServiceUrl}question`
export const bankInfoUrl = `${employeeServiceUrl}bank_info`
export const experienceInfoUrl = `${employeeServiceUrl}experience-info`
export const leaveUrl = `${employeeServiceUrl}leave`
export const leaveTypeUrl = `${employeeServiceUrl}leave-type`
export const applyLeaveUrl =`${employeeServiceUrl}leave/apply-leave`
export const leaveCancelGetUrl = `${employeeServiceUrl}leave/get-cancel-leaves-approval/leave`
export const leaveCancelApprovalUrl = `${employeeServiceUrl}leave/cancel-leave-approval/`
export const leaveHalfCancelUrl = `${leaveUrl}/half-cancel-leave`
export const deleteLeaveUrl =`${employeeServiceUrl}leave/delete-leave`
export const updateLeaveUrl =`${employeeServiceUrl}leave/update-leave`
export const educationInfoUrl = `${employeeServiceUrl}employee-education`
export const familyInfoUrl = `${employeeServiceUrl}employee-family`
export const certificateInfoUrl = `${employeeServiceUrl}certificate_info`
export const emergencyInfoUrl =`${employeeServiceUrl}emergency_contact`
export const attendanceUrl = `${employeeServiceUrl}attendance/month`  
export const attendanceStats = `${employeeServiceUrl}attendance/attendance_stats`
export const attendanceTimer = `${employeeServiceUrl}attendance/todays_timesheet`
export const recessTimer = `${employeeServiceUrl}attendance/recess`

export const approvalLeaveUrl = `${employeeServiceUrl}leave/get-leaves-for-approval/leave`
export const employeeLeaveUrl = `${employeeServiceUrl}leave/leave`

export const remoteLeaveUrl = `${employeeServiceUrl}leave/remote_leave`
export const ApplyremoteLeaveUrl = `${employeeServiceUrl}leave/apply-leave`
export const RemoteLeaveApproveUrl = `${employeeServiceUrl}leave/leave-approval`
export const RemoteLeaveARejectedUrl = `${employeeServiceUrl}leave/leave-reject`
export const approvalRemoteLeaveUrl = `${employeeServiceUrl}leave/get-leaves-for-approval/remote_leave`
export const remoteCancelLeaveUrl = `${employeeServiceUrl}leave/get-cancel-leaves-approval/remote_leave`
export const remoteLeaveHistoryUrl= `${employeeServiceUrl}leave/get-leaves-history/remote_leave`
export const attendanceWorkStatsUrl = `${employeeServiceUrl}attendance/worktime_stats`
export const attendanceTimeSheetUrl = `${employeeServiceUrl}attendance/todays_timesheet_employees`
export const attendanceHierarchyParent = `${employeeServiceUrl}attendance/attendance_hierarchy`
export const leaveHistoryUrl = `${employeeServiceUrl}leave/get-leaves-history/leave`
export const leaveCounts = `${employeeServiceUrl}leave/get-leaves-count`
 
export const leadShift = `${employeeServiceUrl}shift/get_shift_for_lead`
export const leadDesignation = `${employeeServiceUrl}shift/get_designation_for_lead`
export const leadRole = `${employeeServiceUrl}shift/get_roles_for_lead`

//employe in EmployeeService
export const employeeEmpUrl = `${employeeServiceUrl}employee`
export const employeeListUrl = `${employeeServiceUrl}employee/get_employee_hierarchy_list/`
export const allEmployeeListUrl = `${employeeServiceUrl}employee/all_employees_list/`
export const SupervisorListUrl = `${employeeServiceUrl}employee/supervisor_list`
export const employeeFieldManagementUrl = `${baseServiceUrl}fields_management/`
export const employeesHierarchyUrl = `${employeeServiceUrl}employee/employee_hierarchy`
export const employeesCSVUrl = `${employeeServiceUrl}employee/export`
// HR service URLS
export const resignationUrl = `${hrServicesUrl}resignation/`    
export const terminationUrl = `${hrServicesUrl}termination/`
export const clearanceUrl = `${hrServicesUrl}employee-clearance/`
export const onboardingUrl = `${hrServicesUrl}onboarding/`
export const onboardingHistoryUrl = `${hrServicesUrl}employ_onboarded/onboarding-history/`
// Administration URLS
export const assetUrl = `${administrationUrl}asset/`
export const assetForApproval = `${assetUrl}pending_assets/`
export const assetAttributeUrl = `${administrationUrl}attribute/`
export const assetCategoryUrl = `${administrationUrl}category/`
//Accounts service URLS
export const categoryUrl = `${accountServiceUrl}category/`
export const ExpenseUrl = `${accountServiceUrl}expense/`
export const getExpenseForApprovalUrl = `${accountServiceUrl}expense/get-expense-for-approval/`
export const AllowanceUrl = `${accountServiceUrl}wage/`
export const PayrollUrl = `${accountServiceUrl}payroll/`
export const EmployeeWithoutPayroll = `${accountServiceUrl}payroll/employee_without_payroll/`
export const UpdatePayroll = `${accountServiceUrl}payroll/update_payroll/`
export const PayrollPdfUrl = `${accountServiceUrl}payroll/download/pdf`
export const PayrollCsvUrl = `${accountServiceUrl}payroll/download/csv`
export const PayrollClosing = `${accountServiceUrl}payroll/close_current_month_payroll`