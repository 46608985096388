import { flow, types } from 'mobx-state-tree'
import { profileProjectsApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'

export const client = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  mobileNomobileNumber: types.maybeNull(types.number),
})

export const employee_data = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
})

export const project = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  projectId: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  priority: types.maybeNull(types.string),
  contractType: types.maybeNull(types.string),
  rate: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  uploadFiles: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  client: types.maybeNull(client),
  projectLeader: types.maybeNull(types.array(employee_data)),
  projectTeam: types.maybeNull(types.array(employee_data)),
  status: types.maybeNull(types.string),
})

export const profileProjects = types
  .model({
    data: types.maybeNull(types.array(project)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getProfileProjects() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadProfileProjects = flow(function* (profile, employee_id) {
      try {
        self.loading = true
        const res = yield profileProjectsApi.getProfileProjectsDetails(
          profile,
          employee_id
        )
        self.data = res.results
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Profile Projects')
        }
        self.loading = false
        return { success: false }
      }
    })

    return {
      loadProfileProjects,
    }
  })

export function initProfileProjectsDetails() {
  return profileProjects.create({})
}
