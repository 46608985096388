import { useEffect } from 'react'
import { Form, Col, DatePicker, Row } from 'antd'
import 'antd/dist/antd.css'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import moment from 'moment'
import { REASON, TERMINATION_DATE, formValues, TerminationFormProps} from './constant'
import { VERTICAL, REASON_TITLE } from 'constant/constant'
import styled from 'styled-components'
import { CommonSelect } from '@commonComponents/select'
import { StyleCommonInput } from '@mainPage/userResignation/resignationapplyForm'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'


const TerminationForm = observer(({ setRefreshTable, setIsOpenDrawer, data }: TerminationFormProps) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields([])
    form.setFieldsValue({
      employee: data?.employeeName,
      terminated_date: moment(data?.terminatedDate),
      reason: data?.reason,
      annualization: data?.annualization || false,
      bonuses: data?.bonuses || false,
      leaves: data?.leaves || false,
      loans: data?.loans || false,
    })
  }, [])

  const {
    terminationModel: { setTerminationData },
    employeeDetails: { loadAllEmployeeList, getEmployeeAllList }
  } = useStore(null)


  useEffect(() => {
    const load = async () => {
      await loadAllEmployeeList()
    }
    load()
  }, [])


  const onFinish = async (values:formValues) => {    
    const response = await setTerminationData(values)
    if (response.success) {
      setRefreshTable(true)
      setIsOpenDrawer(false)
      form.resetFields()
    }    
  }


  const allEmployees = getEmployeeAllList?.map(value => ({ key: value.id, value: value.fullName })) || [];

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout={VERTICAL}
        scrollToFirstError
        id='termination-form'
      >
        <div className='form-auto-height'>
          <Row>
            <Col xs={24} sm={24}>
              <Form.Item
                label='Select Emloyee'
                name={'employee_id'}>
                <CommonSelect
                  listHeight={125}
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Select Employee'
                  data={allEmployees}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 24]}>
            <DateColumn span={24}>
              <Form.Item
                name={['terminated_date']}
                label={TERMINATION_DATE}
                rules={[validateRequired(TERMINATION_DATE)]}
                className={`customDate`}
              >
                <DatePicker
                  getPopupContainer={trigger => trigger.parentElement}
                  className='form-control inputField'
                  disabled
                />
              </Form.Item>
            </DateColumn>
          </Row>
          <Row gutter={[12, 24]}>
            <Col span={24}>
              <Form.Item
                name={REASON}
                label={REASON_TITLE}
                rules={[validateRequired(REASON_TITLE), validateNoWhiteSpace()]}
              >
                <StyleCommonInput inputType='textarea' />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  )
})
export default TerminationForm;

const DateColumn = styled(Col)`
  label[title='Termination Date']{
  pointer-events: none;
}
`
