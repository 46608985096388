import { constRoute } from '@utils/route'
import { MenuIcon } from '@utils/menu/menuIcon'

export const menusList = [
  {
    menuTitle: 'Employee',
    menu: [
      {
        name: 'Employees',
        api: 'employee',
        icon: MenuIcon.employee,
        link: constRoute.newEmployee,
      },
      {
        name: 'Departments',
        api: 'department',
        icon: MenuIcon.departments,
        link: constRoute.departments,
      },
      {
        name: 'Designations',
        api: 'designation',
        icon: MenuIcon.designations,
        link: constRoute.designations,
      },
      {
        name: 'Section',
        api: 'section',
        icon: MenuIcon.section,
        link: constRoute.section,
      },
      {
        name: 'Attendance',
        api: 'attendance',
        icon: MenuIcon.attendance,
        link: constRoute.attendance,
      },
      {
        name: 'E-Canteen',
        api: 'canteen',
        icon: MenuIcon.eCanteen,
        link: constRoute.eCanteen,
      },
      {
        name: 'Shift',
        api: 'shift',
        icon: MenuIcon.shift,
        link: constRoute.shift,
      },
      {
        name: 'Leave Application',
        api: 'leaveApplication',
        icon: MenuIcon.leaveApplication,
        link: constRoute.leaveEmployee,
      },
      {
        name: 'Leave Approval',
        api: 'leave_approval',
        icon: MenuIcon.leaveApplication,
        link: constRoute.leaveEmployee,
      },
      {
        name: 'Remote work',
        api: 'remoteWork',
        icon: MenuIcon.leaveApplication,
        link: constRoute.remoteEmployee,
      },
      {
        name: 'Remote Approval',
        api: 'remote_approval',
        icon: MenuIcon.leaveApplication,
        link: constRoute.remoteEmployee,
      },
      {
        name: 'Resignation',
        api: 'resignation',
        icon: MenuIcon.resignation,
        link: constRoute.resignation,
      },
    ],
  },
  {
    menuTitle: 'HR',
    menu: [
      {
        name: 'Payroll',
        api: 'payroll',
        icon: MenuIcon.payroll,
        link: constRoute.payroll,
      },
      {
        name: 'Loan',
        api: 'loan',
        icon: MenuIcon.loan,
        link: constRoute.loan,
      },
      {
        name: 'Assets',
        api: 'asset',
        icon: MenuIcon.assets,
        link: constRoute.assets,
      },
      {
        name:'Asset Handling',
        api:'assetHandling',
        icon:MenuIcon.assetHandling,
        link: constRoute.assetHandling,
      },
      {
        name: 'Allowance',
        api: 'allowance',
        icon: MenuIcon.allowance,
        link: constRoute.allowance,
      },
      {
        name: 'Categories',
        api: 'category',
        icon: MenuIcon.categoies,
        link: constRoute.accountCategories,
      },
      {
        name: 'Expenses',
        api: 'expenses',
        icon: MenuIcon.expenses,
        link: constRoute.expenses,
      },
      {
        name: 'Clearance',
        api: 'clearance',
        icon: MenuIcon.clearance,
        link: constRoute.clearance,
      },
      {
        name: 'Termination',
        api: 'termination',
        icon: MenuIcon.termination,
        link: constRoute.termination,
      },

    ],
  },
  {
    menuTitle: 'Administration',
    menu: [
      {
        name: 'Projects',
        api: 'projects',
        icon: MenuIcon.project,
        link: constRoute.projects,
      },
      {
        name: 'Clients',
        api: 'client',
        icon: MenuIcon.client,
        link: constRoute.clients,
      },
      {
        name: 'Branch',
        api: 'branch',
        icon: MenuIcon.branch,
        link: constRoute.branch,
      },

      {
        name: 'Grade',
        api: 'gradeLevel',
        icon: MenuIcon.grade,
        link: constRoute.grade,
      },
      {
        name: 'Feedback',
        api: 'feedback',
        icon: MenuIcon.feedback,
        link: constRoute.feedback,
      },
      {
        name: 'Onboarding',
        api: 'employeeOnBoarded',
        icon: MenuIcon.onBoarding,
        link: constRoute.onBoarding,
      },
      {
        name: 'Leads',
        api: 'lead',
        icon: MenuIcon.leads,
        link: constRoute.leads,
      },
      {
        name: 'Reports',
        api: 'report',
        icon: MenuIcon.reports,
        link: constRoute.reports,
      },
    ],
  },
  {
    menuTitle: 'Settings',
    menu: [
      {
        name: 'Role & Permission',
        api: 'role',
        icon: MenuIcon.rolePermission,
        link: constRoute.rolePermission,
      },
      {
        name: 'Settings',
        api: 'leaveType',
        icon: MenuIcon.generalSettings,
        link: constRoute.settings,
      },

    ],
  },
]
