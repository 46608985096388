import { useStore } from '@stores/root-store';
import { Row, Timeline } from 'antd';
import { colors } from 'constant/colors';
import { DATE_FORMAT } from 'constant/constant';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */

const { mediumGray } = colors

const EmployeeHistory = observer(({ employeeID }: { employeeID: string }) => {

  const {
    employeeHistory: { employeeHistoryData, loadEmployeeHistory },
  } = useStore(null)
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    (async function () {
      if (employeeID) {
        await loadEmployeeHistory(employeeID)
      }
    })()
  }, [])

  useEffect(() => {
    const data:any = {
      salaryStartDate: employeeHistoryData?.salary?.[0]?.history?.salaryStartDate ?? 'No Date',
      basicSalary: employeeHistoryData?.salary?.[0]?.history?.basicSalary ?? 0,
      assets: employeeHistoryData?.assets,
      attendance: employeeHistoryData?.attendance?.[0],
      loan: employeeHistoryData?.loan,
      incrementBy: 0,
      oldSalary: 0,
      updatedSalary: 0,
      incrementedDate: '',
      loanDate: employeeHistoryData?.loan && employeeHistoryData?.loan[employeeHistoryData?.loan?.length -1]?.applyDate,
      assetDate: employeeHistoryData?.assets && moment(employeeHistoryData?.assets[employeeHistoryData?.assets?.length -1]?.updatedAt)?.format(DATE_FORMAT),
    }

    employeeHistoryData?.salary?.forEach((element, index) => {
      if(index -1){
        data.incrementBy = element?.history?.incrementValue;
        data.updatedSalary = element?.history?.netSalary;
        data.incrementedDate = element?.history?.incrementedDate
      } else if(index-2){
        data.oldSalary = element?.history?.netSalary;
      }
    });

    setEmployeeData(data)
  }, [employeeHistoryData])


  return (
    <Phistory
    >
      <h4>Time Line Of Employee</h4>
      <Empcardtwo
      >
        <Timeline mode='left'>
          <Timeline.Item color='#C9C9C9' label={employeeData?.salaryStartDate}>
            <CardWrapper>
              <Cardone >
                <h5>First Salary </h5>
                <p>Amount: {employeeData?.basicSalary}</p>
              </Cardone>
            </CardWrapper>
          </Timeline.Item>

          <Timeline.Item color='#C9C9C9' label={employeeData?.assetDate}>
            <CardWrapper>
              <CardTwo>
                <h5>Assets</h5>
                <div style={{ display: 'flex'}}>
                  {employeeData?.assets?.map(value => (
                    <p>{value?.name}</p>
                  ))}
                </div>
              </CardTwo>
            </CardWrapper>
          </Timeline.Item>
          <Timeline.Item color='#C9C9C9' label={employeeData?.attendance?.date}>
            <CardWrapper>
              <CardThree>
                <h5>Attendance</h5>
                <p>
                  Total Leaves:{' '}
                  <span className='total-leaves'>{employeeData?.attendance?.totalLeaves}</span>{' '} -
                  Total Working hours:{' '}
                  <span className='total-leaves'>{employeeData?.attendance?.totalWorkingHours}</span>
                </p>
              </CardThree>
            </CardWrapper>
          </Timeline.Item>
          <Timeline.Item color='#C9C9C9' label={employeeData?.loanDate}>
            <CardWrapper>
              <CardFour>
                <h5>Loan</h5>
                {employeeData?.loan?.map((value, index) => (
                  <p>Loan: {index +1} - Amount: {value?.loanAmount}</p>
                ))}
              </CardFour>
            </CardWrapper>
          </Timeline.Item>

          <Timeline.Item color='#C9C9C9' label={employeeData?.incrementedDate} >
            <CardWrapper>
              <CardFive>
                <h5>Salary Increment</h5>
                <Rowstyled>
                  <p>
                    Increment by: <StyledData>{employeeData?.incrementBy}</StyledData>
                  </p>
                  <p>
                    Old salary: Rs.<StyledData>{employeeData?.oldSalary}</StyledData>
                  </p>
                  <p>
                    Updated salary: Rs.<StyledData>{employeeData?.updatedSalary}</StyledData>
                  </p>
                </Rowstyled>
              </CardFive>
            </CardWrapper>


          </Timeline.Item>


        </Timeline>
      </Empcardtwo>
    </Phistory>
  )
})
export default EmployeeHistory

const Phistory = styled.div`
  .empiInfo {
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: 320px) {
        flex-direction: column;
      }

      li {
        flex-basis: 35%;

        p {
          color: #0186e1;
          font-weight: 600;
          font-size: 16px;

          span {
            color: #2f2f2f;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
    }
  }

  h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #525353;
    text-align: center;
    margin-top: 30px;
    word-spacing: 1px;
  }

  .employeeStep {
    padding: 40px 0;
    position: relative;
    overflow: auto;

    .ant-timeline-item-content {
      p {
        color: #2980ff;
      }

      p.firstp {
        color: #34444c;
      }

      span.firstspan {
        color: #7460ee;
      }
    }

    .ant-timeline {
      max-width: 250px;

     .ant-timeline-item {
        padding-bottom: 48px;

        .ant-timeline-item-head {
          width: 14px;
          height: 14px;
          margin-left: -6px;
        }

        .ant-timeline-item-tail {
          height: calc(100% - -80px);
        }
      }
    }

    .ant-card {
      border: 1px solid ${mediumGray};
      border-radius: 4px;
      min-height: 60px;
      height: auto;
      margin-top: 8px;

      .ant-card-body {
        padding: 10px;
      }
    }

    .netSalary {
      position: absolute;
      min-width: 500px;
      width: auto;
      left: 120px;
      top: 0;
      z-index: 1;
      text-align: left;

      p {
        color: #55ce63;
      }

      .dedcution {
        .ant-card-body {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          .allowance {
            p {
              color: #ffc024;
            }
          }

          .deductions {
            padding: 0 20px 0 20px;

            p {
              color: #ff3737;
            }
          }

          .increment {
            padding-right: 20px;

            p {
              color: #00c0f9;
            }
          }
        }
      }
    }
  }
`

const Empcardtwo = styled.div`

  height: 100%;
  padding: 0px 30px;
  .ant-timeline-item {
    .ant-timeline-item-label {
      max-width: 100px;
    }
    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      left: 109px !important;
    }
    .ant-timeline-item-content {
      left: 104px !important;
      width: calc(100% - 130px) !important;
    }
 
  }
 
`

const Cardone = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 5px;
width: fit-content;
left: 17px;
background: #ffffff;
border: 1px solid #c9c9c9;
border-radius: 8px;
overflow: hidden;
margin-top: 30px;
`

const CardTwo = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 5px;
width: fit-content;
left: 17px;
top: 0px;
background: #ffffff;
border: 1px solid #c9c9c9;
border-radius: 8px;
overflow: hidden;
p{
  color: #525353;
  border: 1px solid #C9C9C9;
  margin: 2px;
  text-align: center;
  border-radius: 21px;
  padding: 6px;  
}
`

const CardThree = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 5px;
width: fit-content;
left: 17px;
top: 0px;
background: #ffffff;
border: 1px solid #c9c9c9;
border-radius: 8px;
overflow: hidden;
.total-leaves {
  color: #525353;
  font-family: Poppins;
  font-weight: 600;
  }
`

const CardFour = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 5px;
width: fit-content;
left: 17px;
top: 0px;
background: #ffffff;
border: 1px solid #c9c9c9;
border-radius: 8px;
overflow: hidden;
`

const CardFive = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 5px;
left: 17px;
top: 0px;
background: #ffffff;
border: 1px solid #c9c9c9;
border-radius: 8px;
overflow: hidden;
`

const Rowstyled = styled(Row)`
p {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
}
`
const StyledData = styled.span`
font-family: Poppins;
font-weight: 600;
color: #525353;
`;
const CardWrapper = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 11px;
    right: 100%;
    width: 17px;
    border-bottom: 1px solid #c9c9c9;
  }
`;