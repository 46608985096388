import { BaseApi } from '../baseApi'
import { userLevelUrl } from '../const/index'
class UserLevelApi extends BaseApi {
  setUserLevel = async data => {
    try {
      const response = await this.axios.post(userLevelUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getUserLevel = async (num:1 ,pageSize, queryParam={}) => {
    try {
      const url = `${userLevelUrl}?page=${num}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateUserLevel = async (id, data) => {
    try {
      const response = await this.axios.patch(`${userLevelUrl + id}/`, data,)     
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteUserLevel = async id => {
    try {
      const response = await this.axios.delete(`${userLevelUrl + id}/`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}
export default UserLevelApi
