import React, { useEffect } from 'react'
import { Form, } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import { CommonSelect } from '@commonComponents/select'

const EmployeeLeaveTypeForm = observer(
  ({ update, data, setVisible, viewDetail, setRefreshTable }) => {
    const [form] = Form.useForm()

    const {
      employeeLeaveTypeModal: {
        setEmployeeLeaveTypeData,
        updateEmployeeLeaveType,
      },
    } = useStore(null)

    useEffect(() => {
      form.resetFields();
      if (update && data) {
        form.setFieldsValue({
          title: data.title,
          available_days: data.availableDays,
          is_half_leave: data.isHalfLeave,
        });
      }
    }, [data, update]);

    const submitData = async values => {
      let response = null;
      if (update) {
        response = await updateEmployeeLeaveType(data.id, values);
      } else {
        response = await setEmployeeLeaveTypeData(values);
      }
      if (response?.success) {
        setVisible(false);
        setRefreshTable(true);
        form.resetFields();
      }
    };

    const onlyNumber = (e: any) => {
      if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189)
        e.preventDefault();
    }

    const NUMBER_OF_DAYS_FOR_FULL_LEAVE = 365;

    return (
      <Form
        form={form}
        layout='vertical'
        onFinish={submitData}
        id='employee-form'
      >
        <Form.Item
          name='is_half_leave'
          label=' Select Leave Type'
          rules={[{ required: true, message: 'Leave type is required' }]}

        >
          <CommonSelect
            listHeight={120}
            placeholder='Select Leave Type'
            size='large'
            allowClear
            getPopupContainer={trigger => trigger.parentNode}
            data={[
              {
                key: true,
                value: 'Half Leave',
              },
              {
                key: false,
                value: 'Full Leave',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name='title'
          label='Leave Name'
          rules={[{ required: true, message: 'Leave Name is required' }]}
        >
          <CommonInput
            disabled={viewDetail}
            inputType='text'
            className='w-100 inputField'
            placeholder='Ex: Sick'
          />
        </Form.Item>

        <Form.Item
          name='available_days'
          label='Number of Days'
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[0-9]+$/i),
              message: 'Number of days is required ',
            },
            {
              validator: (_, value) => {
                return value > NUMBER_OF_DAYS_FOR_FULL_LEAVE ? Promise.reject('Number of leaves should be less than or equal to 365') : Promise.resolve()
              },
            }
          ]}
        >
          <CommonInput
            disabled={viewDetail}
            className='inputField'
            onKeyDown={e => onlyNumber(e)}
            inputType={'number'}
            maxlength={3}
            // defaultValue=''
            placeholder='Ex: 10'
          />
        </Form.Item>
      </Form>
    )
  }
)

export default EmployeeLeaveTypeForm