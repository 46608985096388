import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { attendanceStatsApi } from '@api'
import { notification } from '@utils/notifications'



export const DashboardEmployeePayroll = types.model({
  totalCount: types.maybeNull(types.number),
})



export const totalCountModel = types.model({
  data : types.maybeNull(DashboardEmployeePayroll)
}).views(self => ({
  get getEmployeeCount(){
    return toJS(self.data)
  }
})).actions(self => {
  const loadEmployeeCount = flow(function* () {
    try {
      const res = yield attendanceStatsApi.getEmployeeTotalCount()
      if (!res?.isCancel){
        self.data = cast(res)
      }
    } catch (error) {
      const message = error?.response?.data?.message 
      if(message){
        notification.error(message)
      } else {
        notification.error('Failed to load Employee Count.')
      }          return { success: false }
    }
  })
    
  return {
    loadEmployeeCount,
  }
})
  

export function initEmployeeCount() {
  return totalCountModel.create({
    data: {}
  })
}
