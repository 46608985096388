import React, { useEffect, useState } from 'react';
import { Avatar, Col, Drawer, Row } from 'antd';
import styled from 'styled-components';
export interface drawerProps {
  children: React.ReactNode;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  customFooter?: React.ReactNode | boolean;
  formId?: string;
  title?: any;
  width?: number;
  isworkReport?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  className?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onClose?: any;
  loading?: boolean;
  maskClosable?: boolean;
  empRecord?: any;
}
const CustomHistoryDrawer = (props: drawerProps) => {
  const [color, setColor] = useState(null)

  useEffect(() => {
    setColor(JSON.parse(localStorage.getItem('colorTheme')))
  }, [])

  function isColorDark(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  }
  const Header = (
    <div>
      <Row>
        <Col lg={14}>
          <Profileheader>
            <Profile>
              <Profileimage>
                {
                  props?.empRecord?.employeeImage ? <Avatar src={props.empRecord.employeeImage} size={75} /> :
                    <Avatar size={75}>
                      {props.empRecord && props.empRecord?.employeeFirstName || '' + ' ' + props.empRecord?.employeeLastName || ''}
                    </Avatar>
                }
              </Profileimage>
              <div className='d-flex align-items-center'>
                <div>
                  <div>
                    {' '}
                    Name :{' '}
                    <span className='detail'>
                      {props.empRecord && props.empRecord?.employeeFirstName || '' + ' ' + props.empRecord?.employeeLastName || ''}
                    </span>
                  </div>
                  <div>
                    Designation :{' '}
                    <span className='detail'>
                      {(props.empRecord && props.empRecord?.employeeDesignation) || '-'}
                    </span>
                  </div>
                  <div>
                    Date of join :{' '}
                    <span className='detail'>
                      {(props.empRecord && props.empRecord?.joiningDate) || '-'}
                    </span>
                  </div>
                </div>
              </div>
            </Profile>
          </Profileheader>
        </Col>
        <Col lg={10}>
          <Profileheader>
            <div>
              <div>
                Employee ID :{' '}
                <span className='detail'>
                  {(props.empRecord && props.empRecord?.employId) || '-'}
                </span>
              </div>
              <div>
                Basic Salary :{' '}
                <span className='detail'>
                  {'Rs '}{(props.empRecord && props.empRecord?.basicSalary) || '-'}
                </span>{' '}
              </div>
              <div>
                Net Salary :{' ㅤ'}
                <span className='detail'>
                  {'Rs '}{(props.empRecord && props.empRecord?.netSalary) || '-'}
                </span>{' '}
              </div>
            </div>
          </Profileheader>
        </Col>
      </Row>
    </div>
  );
  
  return (
    <Wrapper
      color={color || '#2d71d8'} textColor={isColorDark(color?.bodyColor || '#2d71d8')}
      title={Header}
      width={props?.width || 850}
      className={props.className}
      style={{ zIndex: 1050 }}
      onClose={() => {
        props.setVisible(false);
      }}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      maskClosable={false}
      footer={props?.customFooter}
      {...props}
    >
      {props.children}
    </Wrapper>
  );
};
export default CustomHistoryDrawer;
const Profileheader = styled.div`
  display: flex;
  align-items: center;
  height: 121px;
  padding: 7px 16px 16px 7px;
  font-family: "Outfit";
  color: #fff;
  * {
    font-size: 14px;
  }
  .detail,
  ul {
    margin-left: 10px;
  }
  .ant-avatar-string {
    font-size: 23px !important;
    text-transform: capitalize !important;
    color: #6b6b6b !important;
  }
`;
const Profile = styled.div`
  margin-left: 11px;
  display: flex;
  gap: 30px;
  position: relative;
`;
const Profileimage = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #8ab8fd;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled(Drawer)`
  .ant-drawer-header {
    background: ${props => props.color?.bodyColor || '#2d71d8'} !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    padding: 19px !important;
    .ant-drawer-close {
      right: -12px;
      position: absolute;
      background: #ffffff;
      top: 3px;
      display: grid;
      place-items: center;
      width: 36px;
      height: 36px;
      background: ${props => props.color?.bodyColor || '#2d71d8'} !important;
      border-radius: 32px;
      color: #fff;
      .anticon-close {
        transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
        transition-delay: 0.2;
      }
      &:hover {
        color: #fff;
        border-radius: 32px;
        border: 2px solid white;
      
        .anticon-close {
          transform: rotate(180deg);
        }
    }
  }
  .ant-input {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px !important;
    border-radius: 8px !important;
    &:hover {
      border-color: ${props => props.color?.bodyColor || '#2d71d8' } !important;
    }
  }
  .ant-drawer-title {
    color: white;
    .ant-icon {
      color: black;
    }
  }
  .ant-drawer-body{
      padding: 10px 24px 0 24px !important;
      .col-md-12{
          padding:0;
      }
  }
  .ant-drawer-content-wrapper {
    width: 750px !important;
    @media (max-width: 768px) {
      width: 560px !important;
    }
    @media (max-width: 992px) {
      width: 750px !important;
    }
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
  .ant-drawer-footer {
    padding: 25px 16px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
 `