
import { cast, flow, types } from 'mobx-state-tree'
import { assetsLeadsApi } from '@api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'


export const leadsInfo = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  mobileNumber: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
  employeeID: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  designation: types.maybeNull(types.number),
  designationName: types.maybeNull(types.string),
  joiningDate: types.maybeNull(types.string),
  leavingDate: types.maybeNull(types.string),
  status: types.maybeNull(types.boolean),
  supervisor: types.maybeNull(types.string),
  supervisorFirstName: types.maybeNull(types.string),
  supervisorLastName: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
  roleName: types.maybeNull(types.string),
  createUser: types.maybeNull(types.boolean),
  branchName: types.maybeNull(types.string),
  branch: types.maybeNull(types.number),
  company: types.maybeNull(types.number),
  isAdmin: types.maybeNull(types.boolean),
  isLead: types.maybeNull(types.boolean),
  increment: types.maybeNull(types.number)
})

export const assetLeads = types
  .model({
    leadsInfo: types.maybeNull(types.array(leadsInfo)),
    loading: types.optional(types.boolean, false),
    countEmp: types.maybeNull(types.number),
    nextLd: types.optional(types.number, 1),
    erorMessage: types.maybeNull(types.string),
  })
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get getLeadsData() {
      return toJS(self.leadsInfo)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadLeads = flow(function* (
      page = 1,
      page_size=20,
      queryParam='',
      resetList = false,
      saveInStore = true,
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.leadsInfo = cast([])
          self.nextLd = 1
        }
        if(page === 1){
          self.leadsInfo = cast([])
        }
        const res = yield assetsLeadsApi.getLeads(page,page_size,queryParam)
        if (!res?.isCancel && saveInStore) {
          self.leadsInfo = cast([...toJS(self.leadsInfo ?? []), ...res.results])
          self.countEmp = res.count
          if (res.next) {
            self.nextLd= self.nextLd + 1
          } else {
            self.nextLd = 1
          }
        } else if (!res?.isCancel && !saveInStore) {
          return res || []
        }
    

      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Lead')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })


    const errorMsgClear = () => {
      self.erorMessage = null
    }
    return {
      errorMsgClear,
      loadLeads,
      
    }
  })

export function initLeadDetails() {
  return assetLeads.create({})
}
