import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeLeaveHistoryApi } from '@api'
import { notification } from '@utils/notifications'

export const employee_leave = types.model({
  id: types.maybeNull(types.string),
  leaveType: types.maybeNull(types.string),
  employee: types.maybeNull(types.string),
  lead: types.maybeNull(types.string),
  leaveStartDate: types.maybeNull(types.string),
  leaveEndDate: types.maybeNull(types.string),
  numberOfDays: types.maybeNull(types.number),
  reason:types.maybeNull(types.string),
})

export const approved_by = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})

export const employeeLeaveHistory = types.model({
  id: types.maybeNull(types.number),
  employeeLeave: types.maybeNull(employee_leave),
  isAccepted: types.maybeNull(types.boolean),
  status: types.maybeNull(types.string),
  approvedBy: types.maybeNull(approved_by),
})

export const employeeLeaveHistoryModal = types
  .model({
    data: types.maybeNull(types.array(employeeLeaveHistory)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getEmployeeLeaveHistoryData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadEmployeeLeaveHistory = flow(function* (num = 1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeLeaveHistoryApi.getEmployeeLeaveHistory(num)
        self.data = cast(res.results)
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Leave History')
        }
      } finally {
        self.loading = false
      }
    })

    return {
      loadEmployeeLeaveHistory,
    }
  })

export function initEmployeeLeaveHistoryModal() {
  return employeeLeaveHistoryModal.create({})
}
