import { flow, types, cast } from 'mobx-state-tree'
import { assetsApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'
import {exportCsv, exportPdf} from '@utils/common-functions';

export const assetUser = types.model({
  id: types.maybeNull(types.string),
  employeeName: types.maybeNull(types.string),
})

export const asset = types.model({
  id: types.maybeNull(types.string),
  assetId: types.maybeNull(types.string),
  assignedById: types.maybeNull(types.string),
  assignedToId: types.maybeNull(types.string),
  attributes: types.maybeNull(types.frozen()),
  authorizedById: types.maybeNull(types.string),
  categoryId: types.maybeNull(types.string),
  categoryTitle: types.maybeNull(types.string),
  condition: types.maybeNull(types.string),
  purchasedAmount: types.maybeNull(types.number),
  purchasedDate: types.maybeNull(types.string),
  purchasedFrom: types.maybeNull(types.string),
  serialNumber: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  summary: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
})

export const assetsModel = types
  .model({
    data: types.maybeNull(types.array(asset)),
    assetUsers: types.maybeNull(types.array(assetUser)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextAss: types.optional(types.number, 1),
    nextAssetUser: types.optional(types.number, 1)
  })
  .views(self => ({
    get getAssetsData() {
      return toJS(self.data)
    },
    get getAssetsUserData() {
      return toJS(self.assetUsers)
    },
    get isLoading() {
      return self.loading
    },
   
  }))

  .actions(self => {
    const setAssetsData = flow(function* (data) {
      try {
        self.loading = true
        yield assetsApi.setAssets(data)
        notification.success('Asset Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)        
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadAssets = flow(function* (page=1, size=50, queryParam='', resetList=false, saveInStore=true) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextAss = 1
        }
        const res = yield assetsApi.getAssets(page , size, queryParam)
        if (!res?.isCancel && saveInStore){
          self.data = res.items
          if (res?.next) {
            self.nextAss = self.nextAss + 1
          } else {
            self.nextAss = 1
          }
        } else if(!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Assets')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadAssetsUser = flow(function* (page=1, pageSize=20, queryParam='', resetList=false, saveInStore=true) {
      try {
        self.loading = true
        if (resetList) {
          self.assetUsers = cast([])
          self.nextAssetUser = 1
        }
        const res = yield assetsApi.getAssetsUser(page , pageSize, queryParam)
        if (!res?.isCancel && saveInStore){
          self.assetUsers = cast([...toJS(self.assetUsers ?? []), ...res.items])
          if (res?.next) {
            self.nextAssetUser = self.nextAssetUser + 1
          } else {
            self.nextAssetUser = 1
          }
        } else if(!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Assets User')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })
  
  
    const updateAssets = flow(function* (data, id) {
      try {
        self.loading = true
        yield assetsApi.updateAssets(data, id )
        notification.success('Asset Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

 
    const exportAssetRecord = flow(function* (format, queryParam = '',) {
      try {
        self.loading = true
        const response = yield assetsApi.exportAssetRecord(queryParam, format)
        if (format === 'csv') {
          exportCsv(response)
        } else if (format === 'pdf') {
          exportPdf(response)
        }
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const exportAssetApprovalRecord = flow(function* (format, queryParam = '',) {
      try {
        self.loading = true
        const response = yield assetsApi.exportAssetApprovalRecord(queryParam, format)
        if (format === 'csv') {
          exportCsv(response)
        } else if (format === 'pdf') {
          exportPdf(response)
        }
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })




    return {
      setAssetsData,
      loadAssets,
      updateAssets,
      loadAssetsUser,
      exportAssetRecord,
      exportAssetApprovalRecord,
    }
  })

export function initassetsModel() {
  return assetsModel.create({})
}
