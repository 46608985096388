
import { BaseApi } from '../baseApi'
import { passwordResetUrl } from '@api/const'

class ForgotPasswordApi extends BaseApi {
  setEmail = async data => {
    try {
      await this.axios.post(passwordResetUrl, data)
      return true
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default ForgotPasswordApi
