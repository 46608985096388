import React, { useState, useRef } from 'react'
import { Row, Avatar } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import { permit } from '@utils/permission/permission'
import { Table } from '@commonComponents/table-v2'
import { projects_Url } from '@api/const'
import DisplayPicture from '@commonComponents/avatar'
import style from './style.module.scss'
import ShowOnPermission from '@commonComponents/permit'
import { DELETE_METHOD, PROJECTS_MOD, UPDATE_METHOD } from 'constant/constant'
import styled from 'styled-components'
import highPriority from '@assets/img/high-priority.svg'
import lowPriority from '@assets/img/lowPriority.svg'
import mediumPriority from '@assets/img/medium-priority.svg'
import workingStatus from '@assets/img/workingStatus.svg'
import testingStatus from '@assets/img/testingStatus.svg'
import { CheckCircleFilled, ClockCircleFilled } from '@ant-design/icons'
import { MdEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const ProjectsTable = observer(props => {
  const [pageNumber, setPageNumber] = useState(1)
  const tableRef = useRef(null);

  const navigate = useNavigate()
  const {
    userInfo: { getcompany },
  } = useStore(null)

  const handleProjectNavigation = (text, data) => {
    navigate(`${constRoute.projectView}/${data.id}`, {state: data})
  }
  const isActionsExist = permit(getcompany, 'projects', 'edit') || permit(getcompany, 'projects', 'delete');
  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, data) => (
        <div onClick={() => handleProjectNavigation(text, data)} style={{cursor:'pointer'}}>
          <TextStyle>

          <Tooltip title={text} placement='topLeft'>
            {text}
          </Tooltip>
          </TextStyle>
        </div>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_ ,row) => (
        <Tooltip title = {row.client.firstName ? row.client.firstName  + ' ' + (row.client.lastName? row.client.lastName: '') : '-'} placement='topLeft'>{
          row.client.firstName ? row.client.firstName  + ' ' + (row.client.lastName? row.client.lastName: '') : '-'
        }
        </Tooltip>
      ),
    },
    {
      title: 'Lead',
      dataIndex: 'projectLeader',
      key: 'projectLeader',
      render: text => (
        <StyledCenteredText>
          {text?.length ? (
            <Avatar.Group maxCount={2} size={32}>
              {text?.map((e, index) => (
                <StyledDiv 
                  index={index}
                  length={text?.length}
                >
                  <Tooltip title = {e.firstName} placement='topLeft'>
                    <span> 
                      <DisplayPicture
                        size={35}
                        src={e.employeeImage}
                        name={e.firstName}
                        style={
                          index == 0
                            ? { backgroundColor: '#99e6ff' }
                            : { backgroundColor: '#809fff' }
                        }
                      />
                    </span>
                  </Tooltip>
                </StyledDiv>
              ))}
            </Avatar.Group>
          ) : (
            <div>
              <strong>-</strong>
            </div>
          )}
        </StyledCenteredText>
      ),
    },
    {
      title: 'Team',
      dataIndex: 'projectTeam',
      key: 'projectTeam',
      render: text => (
        <StyledCenteredText>
          {text?.length ? (
            <Avatar.Group maxCount={2} size={32}>
              {text?.map((e, index) => (
                
                <Tooltip title = {e.firstName} placement='topLeft'>
                  <StyledDiv
                    index={index}
                    length={text?.length}
                  >
                    <DisplayPicture
                      size={35}
                      src={e.employeeImage}
                      name={e.firstName}
                      style={
                        index == 0
                          ? { backgroundColor: '#edafbc'}
                          : { backgroundColor: '#edb777'}
                      }
                    />
                  </StyledDiv>
                </Tooltip>
              ))}
            </Avatar.Group>
          ) : (
            <div>
              <strong>-</strong>
            </div>
          )}
        </StyledCenteredText>
      ),
    },
    {
      
      
      title: 'Project Type',
      dataIndex: 'projectType',
      key: 'projectType',
      render: text => (
        <TextStyle>
        <Tooltip title ={text}>
          {text}
        </Tooltip>
        </TextStyle>
      ),
    },
    {
      
      title:<span> Start Date <br/> End Date</span>,
      dataIndex: 'rate',
      key: 'rate',
      render: (_, row) => (
        <p>{row.startDate}<br/>{row.endDate}</p>
      ),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'address',
      render: text => (
        <>
          {text =='High' ? 
            <StyledSpan>
              <img className='highPriority' src={highPriority} alt='' />
           High 
            </StyledSpan> :
            text =='Medium' ? 
              <StyledSpan>
                <img src={mediumPriority} alt='' />
           Medium 
              </StyledSpan> : 
              <StyledSpan><img src={lowPriority} alt='' />
           Low
              </StyledSpan>}
        </>
      ),
    },
    {
      title: <StyledCenteredText>{'Status'}</StyledCenteredText>,
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <>
          <StyledCenteredText>
            {text =='Pending' ? 
              <StyledSpan>
                <ClockCircleFilled style={{color:'#D7E90D'}} />
           Pending
              </StyledSpan>: text =='Final' ? 
                <StyledSpan>
                  <CheckCircleFilled style={{color:'#16D61E'}}/>
            Final
                </StyledSpan> :
                text == 'Working' ? 
                  <StyledSpan>
                    <img src={workingStatus} alt='' />
           Working  
                  </StyledSpan> : 
                  <StyledSpan>
                    <img src={testingStatus} alt='' />
          Testing
                  </StyledSpan>}
          </StyledCenteredText>
        </>
      ),
    },
    ...(isActionsExist ? [{
      title: <StyledCenteredText>{'Actions'}</StyledCenteredText>,
      dataIndex: 'id',
      key: 'action',
      render: (text, data) => (
        <StyledCenteredText>
          <Row>
            <ShowOnPermission mod={PROJECTS_MOD} method={UPDATE_METHOD}>
              <Tooltip title='Edit' minLength={0} >
                <Icons>
                  <MdEdit onClick={() => props.onEdit(data)}/>
                </Icons>
              </Tooltip>
            </ShowOnPermission>

            <ShowOnPermission mod={PROJECTS_MOD} method={DELETE_METHOD}>
            </ShowOnPermission>
          </Row>
        </StyledCenteredText>
      ),
    },] : [])
  ]

  return (
    <>
    <div className='table-responsive'>
      <div className={style.projectTable}>
        <Table
          ref={tableRef}
          dataUrl={projects_Url}
          columns={columns}
          refreshTable={props.refreshTable}
          setRefreshTable={props.setRefreshTable}
          setPageNumber={data => setPageNumber(data)}
          resetTable={props.resetTable}
          setResetTable={props.setResetTable}
          queryParam={props.queryParam}
          setTotalRecordRemaining={props.setTotalRecordRemaining}
        />
        </div>
      </div>
      <div style={{ display: 'none' }}>{pageNumber}</div>
    </>
  )
})

export default ProjectsTable

export const StyledDiv = styled.div`
.ant-avatar-circle{
display: flex !important;
justify-content: center !important;
 position: ${props => props.index == 0 ? 'absolute' : ''} ;
 position: ${props => props.length == 1  ? 'relative' : ''} !important;
 margin-left: ${props => props.index == 0 ? '-25px' : ''}; 
 margin-left: ${props => props.length == 1 ? '0px' : ''}; 
};
`
export const StyledCenteredText = styled.span`
display: flex !important;
  justify-content: center !important;
  align-items: center !important;
.ant-avatar-circle{
  margin-right: -1px !important;
  width: 42px !important;
  height: 42px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
};
`
export const StyledSpan = styled.span`
img{
  margin-right: 7px !important;
}
svg{
  margin-right: 7px !important;
}
`
const Icons = styled.div`
  border-radius: 32px;
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  padding: 8px;

  &:hover {
    background-color: #dad8d8;
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  img{
    width: 19px;
    height: 20px;
  }`

  const TextStyle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
  `