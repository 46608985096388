export function disabledColorEffect(color: string, amount: number) {
  const colorValue = color.replace('#', '');
  const num = parseInt(colorValue, 16);
    
  // Extract the RGB components
  let r = (num >> 16) & 0xFF;
  let g = (num >> 8) & 0xFF;
  let b = num & 0xFF;
    
  // Calculate the grayscale value (average of RGB components)
  const gray = Math.round((r + g + b) / 3);
    
  // Desaturate the color by mixing the grayscale value with the original color
  r = Math.round(r + (gray - r) * amount);
  g = Math.round(g + (gray - g) * amount);
  b = Math.round(b + (gray - b) * amount);
    
  // Cap the values to stay within the valid range (0 to 255)
  r = Math.min(Math.max(r, 0), 255);
  g = Math.min(Math.max(g, 0), 255);
  b = Math.min(Math.max(b, 0), 255);
  
  const newColor = (b | (g << 8) | (r << 16)).toString(16);
  return '#' + newColor.padStart(6, '0');
}