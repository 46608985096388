import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import styled from 'styled-components'
import { AttendenceLeadEmp } from './EmpLeadView/TableAttendenceLeadEmp'
import { Timesheet } from './LeadAdminView/TimesheetLeadAdminView'
import getAuthRoles from '@utils/common-functions'

const AttendecneNewDesign = observer(() => {
  const [open, setOpen] = useState(false)
  const [employeeRecord, setEmployeeRecord] = useState(null)
  const [drawerEmpId, setDrawerEmpId] = useState(null)
  const [empID, setEmpID] = useState(null)
  const [clickedDate, setClickedDate] = useState(null)
  const [tabType, setTabType] = useState('AttendenceLeadEmp')
  const { leadOrAdmin: isAdminOrLead } = getAuthRoles()

  const showDrawer = (record, date) => {
    setClickedDate(date ?? record.today)
    record?.id && setEmpID(record.id)
    setOpen(true)
    setEmployeeRecord(record)
    setDrawerEmpId(record.id)
  }

  const onClose = () => {
    setOpen(false)
  }

  const isActive = tabName => {
    if (tabName === tabType) {
      return 'active'
    }
    return 'timesheetBtn'
  }

  return (
    <div className='page-wrapper attendance-page'>
      {/* Page Content */}
      <div className='content container-fluid'>
        <div className='page-header'>
          <AttendanceFlexedStyledWrapper className='d-flex'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Attendance', link: constRoute.attendance },
              ]}
              pageTitle={'Attendance'}
            />
              {isAdminOrLead && (
                <div className={`switchButton mt-2`}>
                <div className='switch-btn-flex'>
                  <CustomAttendanceButton
                    className={isActive('AttendenceLeadEmp')}
                    onClick={() => setTabType('AttendenceLeadEmp')}
                  >
                    Attendance
                  </CustomAttendanceButton>
                  <CustomTimeSheetButton
                    className={isActive('Timesheet')}
                    onClick={() => {
                      onClose()
                      setTabType('Timesheet')
                    }}
                  >
                    Timesheet
                  </CustomTimeSheetButton>
                </div>
              </div>
              )}
          </AttendanceFlexedStyledWrapper>
        </div>
        {tabType === 'AttendenceLeadEmp' ? (
          <AttendenceLeadEmp
            showDrawer={showDrawer}
            open={open}
            employeeRecord={employeeRecord}
            onClose={onClose}
            drawerEmpId={drawerEmpId}
            empId={empID}
            clickedDate={clickedDate}
          />
        ) : (
          <Timesheet
            showDrawer={showDrawer}
            open={open}
            onClose={onClose}
            employeeRecord={employeeRecord}
            clickedDate={clickedDate}
            empID={empID}
          />
        )}
      </div>
    </div>
  )
})
export default AttendecneNewDesign

const AttendanceFlexedStyledWrapper = styled.div`
  justify-content: space-between;

  @media (max-width: 560px) {
    display: block !important;
  }

  .switchButton {
    .switch-btn-flex {
      width: 100%;
    }
  }
`
export const CustomAttendanceButton = styled(Button)`
  @media screen and (min-width: 0px) and (max-width: 374px) {
    width: inherit !important;
  }
`
export const CustomTimeSheetButton = styled(Button)`
  @media screen and (min-width: 0px) and (max-width: 374px) {
    width: inherit !important;
    margin-left: 0px !important;
  }
`
