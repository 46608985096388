import { flow, types } from 'mobx-state-tree'
import { clientLogsApi } from '../../api'

export const client = types.model({
  filedame: types.maybeNull(types.string),
  fieldOldValue: types.maybeNull(types.string),
  fieldNewValue: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
})

export const clientLogsModel = types
  .model({
    data: types.maybeNull(types.array(client)),
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getClientLogsData() {
      return self.data
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadClientLogs = flow(function* (num = 1) {
      try {
        self.loading = true
        const res = yield clientLogsApi.getClientLogs(num)
        self.data = res.results
        self.count = res.count
      } catch (error) {
        // error catch
      } finally {
        self.loading = false
      }
    })
    return { loadClientLogs }
  })

export function initClientLogsModel() {
  return clientLogsModel.create({})
}
