import { Card, Checkbox, Col, Form, Radio, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
const { Title } = Typography
import themelight from '@assets/img/themelight.svg'
import themedark from '@assets/img/themedark.svg'
import type { RadioChangeEvent } from 'antd';
import { useStore } from '@stores/root-store';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

const Appearnace = () => {
  const {
    themesModel: {
      setColor, loadColor, getColorsData
    }
  } = useStore(null)

  const [colorAccent, setColorAccent] = useState({ bodyColor: colors.TealishBlue, headerColor: '', sidebarColor: '', theme: 'light' });
  const [header, setHeader] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [defaultColor, setDefaultColor] = useState(false);
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    async function load() {
      await loadColor()
    }
    load()
  }, [])

  useEffect(() => {
    if (getColorsData) {
      setColorAccent(getColorsData)
      setHeader(getColorsData.headerColor ? true : false)
      setSidebar(getColorsData.sidebarColor ? true : false)
      setTheme(getColorsData.theme ? true : false)

      // change  theme 
      setTheme(theme)
    }
  }, [getColorsData])

  useEffect(() => {
    setDefaultColor(colorAccent.bodyColor == TealishBlue ? true : false)
  }, [getColorsData, colorAccent])

  function handleChange(checkedValues) {
    setColorAccent({ bodyColor: checkedValues.target.value, headerColor: header ? checkedValues.target.value : '', sidebarColor: sidebar ? checkedValues.target.value : '', theme: colorAccent?.theme });
    setColor({ bodyColor: checkedValues.target.value, headerColor: header ? checkedValues.target.value : '', sidebarColor: sidebar ? checkedValues.target.value : '', theme: colorAccent?.theme })
  }


  const onChange = (e: RadioChangeEvent) => {
    setColorAccent(e.target.value);
    e.target.value == 'light' ? setTheme(false) : setTheme(true)
    setColor({ ...colorAccent, theme: e.target.value })
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent, type) => {
    const { checked } = e.target
    checked ? setColor({ ...colorAccent, [type]: colorAccent?.bodyColor }) : setColor({ ...colorAccent, [type]: '' })
    if (type == 'headerColor' && checked) {
      setHeader(true)
    } else if (type == 'headerColor') {
      setHeader(false)
    }
    if (type == 'sidebarColor' && checked) {
      setSidebar(true)
    } else if (type == 'sidebarColor') {
      setSidebar(false)
    }
  };

  const changesDefault = () => {
    setColorAccent({ bodyColor: TealishBlue, headerColor: header ? TealishBlue : '', sidebarColor: sidebar ? TealishBlue : '', theme: colorAccent?.theme });
    setColor({ bodyColor: TealishBlue, headerColor: header ? TealishBlue : '', sidebarColor: sidebar ? TealishBlue : '', theme: colorAccent?.theme })
    setDefaultColor(!defaultColor)
  }

  const allColor = [
    { label: TealishBlue, value: TealishBlue },
    { label: '#F44336', value: '#F44336' },
    { label: '#E91E63', value: '#E91E63' },
    { label: '#9C27B0', value: '#9C27B0' },
    { label: '#673AB7', value: '#673AB7' },
    { label: '#15803d', value: '#15803d' },  //green
    { label: '#3F51B5', value: '#3F51B5' },
    { label: '#2196F3', value: '#2196F3' },
    { label: '#03A9F4', value: '#03A9F4' },
    { label: '#00BCD4', value: '#00BCD4' },
    { label: '#009688', value: '#009688' },
    { label: '#4CAF50', value: '#4CAF50' },
    { label: '#8BC34A', value: '#8BC34A' },
    { label: '#CDDC39', value: '#CDDC39' },
    { label: '#FFEB3B', value: '#FFEB3B' },
    { label: '#FFC107', value: '#FFC107' },
    { label: '#FF9800', value: '#FF9800' },
    { label: '#FF5722', value: '#FF5722' },
    { label: '#166534', value: '#166534' },  //green  
    { label: '#795548', value: '#795548' },
    { label: '#9E9E9E', value: '#9E9E9E' },
    { label: '#607D8B', value: '#607D8B' },
    { label: '#BDBDBD', value: '#BDBDBD' },
    { label: '#757575', value: '#757575' },
    { label: '#616161', value: '#616161' },
    { label: '#424242', value: '#424242' },
    { label: '#EC407A', value: '#EC407A' },
    { label: '#5a2bde', value: '#5a2bde' },
    { label: '#FF1744', value: '#FF1744' },
    { label: '#00E676', value: '#00E676' },
    { label: '#FF9100', value: '#FF9100' },
    { label: '#7B1FA2', value: '#7B1FA2' },
    { label: '#00B8D4', value: '#00B8D4' },
    { label: '#FF6D00', value: '#FF6D00' },
    { label: '#FFAB00', value: '#FFAB00' },
    { label: '#F50057', value: '#F50057' },
    { label: '#00BFA5', value: '#00BFA5' },
    { label: '#FF0000', value: '#FF0000' },  //red
    { label: '#00FF00', value: '#00FF00' },  //green
    { label: '#0000FF', value: '#0000FF' },  //blue
    { label: '#64748b', value: '#64748b' },  //slate
    { label: '#dc2626', value: '#dc2626' },  //red
    { label: '#facc15', value: '#facc15' },  //yellow
    { label: '#38bdf8', value: '#38bdf8' },  //sky
    { label: '#a855f7', value: '#a855f7' },  //purple
    { label: '#22c55e', value: '#22c55e' },  //green    
  ]


  return (
    
    <DivAppearance>
      <Row>
        <Col span={24}>
          <CardAppearance>


            <Radio.Group onChange={onChange} value={colorAccent?.theme}>
              <Row gutter={[24, 24]} className='mt-5 mb-5'>
                <Col xs={24} sm={8} md={8} lg={8}>
                  <DivTheme>

                    <img src={themelight} alt='themedark' className='w-100' />
                    <DivThemeSelect>

                      <Radio value={'light'}>Light</Radio>

                    </DivThemeSelect>

                  </DivTheme>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8}>
                  <DivTheme

                  >
                    <img src={themedark} alt='themedark' className='w-100' />
                    <DivThemeSelect>

                      <Radio value={'dark'}>Dark</Radio>

                    </DivThemeSelect>
                  </DivTheme>

                </Col>
              </Row>
            </Radio.Group>
            <AccentColorDiv >


              <Title level={3}>Colors </Title>
              <Title level={5} className='mb-3'>Choose your accent color</Title>
              <Checkbox checked={defaultColor} onChange={changesDefault}>Automatically pick an accent color from my project theme.</Checkbox>

            </AccentColorDiv>

            <ThemeColor className='mt-4 mb-2'>



              {allColor.map(item => {
                return (
                  <Form.Item>
                    <CheckB key={item.label} checked={item.value == colorAccent.bodyColor} onChange={handleChange} value={item.value} />
                  </Form.Item>
                );
              })}

            </ThemeColor>
            {colorAccent?.theme == 'dark' ? '' :
              <AccentColorDiv>

                <Title level={5} className='mb-4'>Chose your accent color</Title>
                <Checkbox checked={header} onChange={e => onChangeCheckbox(e, 'headerColor')}>Use accent color as header background.</Checkbox>
                <br />
                <Checkbox checked={sidebar} onChange={e => onChangeCheckbox(e, 'sidebarColor')}>Use accent color as inside sidebar.</Checkbox>

              </AccentColorDiv>
            }


          </CardAppearance>
        </Col>
      </Row>

    </DivAppearance>
  )
}

export default Appearnace

const CheckB = styled(Checkbox)`
    .ant-checkbox-inner{
    background-color : ${props => props.value}
    }
`

const DivAppearance = styled.div`
  
`



const DivTheme  = styled.div`
  border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    .ant-radio-wrapper{
      display: flex;
      align-items: center;
      .ant-radio {
        .ant-radio-inner {
          width: 24px;
          height: 24px;
          background-color: transparent !important;
          border-radius: 30px;
          border: 1px solid #77777d;
        }
        .ant-radio-checked {
          .ant-radio-inner {
            border: 2px solid #7460ee;
            &:after {
              width: 15px !important;
              height: 15px !important;
              background-color: #7460ee;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              margin-top: 0px;
              margin-left: 0px;
              transition: none;
            }
          }
        }
        & + span {
          position: relative;
          font-size: 14px;
          font-weight: 400;
          top: 4px;
        }
      }
      }
`

const CardAppearance = styled(Card)`
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  margin-top: 30px;
  padding:20px;
  .ant-radio-wrapper {
      display: flex;
      align-items: flex-end;
      .ant-radio {
        .ant-radio-inner {
          width: 26px;
          height: 26px;
          background-color: transparent !important;
          border-radius: 30px;
        }
        .ant-radio-checked {
          .ant-radio-inner {
            border: 2px solid #7460ee;
            &:after {
              width: 17px !important;
              height: 17px !important;
              background-color: #7460ee;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              margin-top: 0px;
              margin-left: 0px;
              transition: none;
            }
          }
        }
        & + span {
          position: relative;
          top: 2px;
        }
      }
    }
`


const ThemeColor = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-checkbox-wrapper {
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 49px;
          height: 45px;
          border: 1px solid rgba(229, 229, 229, 0.87);
          border-radius: 10px;
        }}
        .ant-checkbox-checked {
          outline: none;
          border-radius: 10px;
          &:after {
            border: 0;
          }
          .ant-checkbox-inner {
            &::after {
              width: 7.714286px;
              height: 15.142857px;
              top: 44%;
              left: 35%;
            }
          }
        }
      }
`

const AccentColorDiv = styled.div`
  .ant-checkbox-wrapper {
    margin-left: 0;
    .ant-checkbox {
      border-radius: 2px;
      .ant-checkbox-inner {
        width: 27px;
        height: 24px;
        border: 0;
        box-shadow: unset;
        border: 2px solid #e2e2e2;
      }
      .ant-checkbox-checked {
        &:after {
          border: 1px solid $color-cyan-primary;
        }
        .ant-checkbox-inner {
          background-color: $color-cyan-primary;
          &::after {
            width: 7.714286px;
            height: 15.142857px;
            top: 40%;
            left: 20.5%;
          }
        }
      }
      & + span {
        top: -3px;
        position: relative;
        font-size: 16px;
        padding-left: 10px;
        color: #34444c;
      }
    }
  }
`

const DivThemeSelect = styled.div`
  display: flex;
  padding: 23px 0 23px 20px;
  align-items: center;
  background: rgba(229, 229, 229, 0.87);
  border: 0.5px solid #b3b6c1;
  border-radius: 0px 0px 5px 5px;
  margin: -13px 12px 0 12px;
  .ant-radio-wrapper{

    .ant-radio-checked{
      border-radius: 50%;
      border: 2px solid #7460ee;
      transform:scale(.8);
      .ant-radio-inner{
     
        :after{
          transform: scale(1.2);
          background-color: #7460ee;
        }
      }

    }
  }

`