import { BaseApi } from '../baseApi'
import { ExpenseUrl } from '../const/index'
class HRExpensesApi extends BaseApi {
  setExpense = async data => {
    try {
      const response = await this.axios.post(`${ExpenseUrl}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getExpense = async (num, pageSize, queryParam = {}) => {
    try {
      const url = `${ExpenseUrl}?page=${num ? num : 1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {params: queryParam ? { ...queryParam } : {}}
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateExpenseData = async (id, data) => {
    try {
      const response = await this.axios.put(`${ExpenseUrl}${id}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  approveExpense = async (id: string) => {
    try {
      const response = await this.axios.patch(`${ExpenseUrl}approved_expense_request/${id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  rejectExpense = async (id: string) => {
    try {
      const response = await this.axios.put(`${ExpenseUrl}expense-reject/${id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default HRExpensesApi
