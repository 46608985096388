import { headerAuthorization } from '../common-utils'
import { BaseApi } from '../baseApi'
import { employeeresignUrl } from '@api/const'

class EmpClearanceDataApi extends BaseApi {

  getEmpClearance = async (queryParam = {}) => {
    try {
      const url = employeeresignUrl
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmpClearanceDataApi
