import { LoadingOutlined } from '@ant-design/icons';
import { employeeEmpUrl } from '@api/const';
import CustomButton from '@commonComponents/button';
import CustomDrawer from '@commonComponents/drawer';
import { Table } from '@commonComponents/table-v2/index';
import { Spinner } from '@mainPage/attendence/EmpLeadView/TableAttendenceLeadEmp';
import { useStore } from '@stores/root-store';
import { Space } from 'antd';
import { CANCEL } from 'constant/constant';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { getColumns } from './employeeTableColumns';
import EmployeeHistory from './employee_history';
import { FeedbackQuestionDrawer } from '@mainPage/feedback/feedbackQuestionDrawer';
import { cloneDeep } from 'lodash';


const EmployeeTable = observer(({ setRefreshTable, queryParam, refreshTable, showHierarchy, setColumns, showInterns, getLeadRole ,setCustomCol}: any) => {

  const [showFeedBackDrawer, setshowFeedBackDrawer] = useState(false);
  const tableRef = useRef(null);
  const [empRecord, setEmpRecord] = useState(null)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(null)
  const [childData, setChildData] = useState(null)


  const {
    employeeDetails: { updateEmployeeDetails, loadHierarchyList, loadHierarchyListParent, getEmpFields, getParentHierarchyList,filteredOutHirerachy},
    userInfo: { getcompany },
    themesModel: { getColorsData },
  } = useStore(null)

  const themeColor = getColorsData?.bodyColor

  useEffect(() => {
    async function load() {
      await loadHierarchyListParent()
    }
    load()
  }, [])



  const getUpdatePayload = (record, fields) => {
    return {
      shift_id: record?.shiftId,
      designation_id: record?.designationId,
      department_id: record?.departmentId,
      supervisor_id: record?.supervisorId,
      roles: record?.roles?.map(el => el.roleId).filter(el => el) || [],
      is_active: record?.isActive,
      ...fields,
    }
  }
  
  useEffect(() => {
    setColumns(columns)
  }, [])

  useEffect(() => {
    if (getEmpFields?.length && getEmpFields[0]?.fields?.employeeFiled?.length) {
      setCustomCol([...getEmpFields[0].fields.employeeFiled]);
    }
  }, [getEmpFields])

  const onStatusChange = async (record, value) => {
    const payload = getUpdatePayload(record, { is_active: value })
    await updateEmployeeDetails(record?.id, payload, false);
    setRefreshTable(true)
  }

  const onRoleChange = async (record, value) => {
    const data = getUpdatePayload(record, {
      roles: [value],
    })
    await updateEmployeeDetails(record.id, data, false)
    setRefreshTable(true)
  }

  const onSupervisorChange = async (record, value) => {
    const data = getUpdatePayload(record, { supervisor_id: value })
    await updateEmployeeDetails(record.id, data, false)
    if(showHierarchy){
      const newData = filteredOutHirerachy(cloneDeep(childData[0]),record.id);
      setChildData([newData]);
    }
    setRefreshTable(true)
  }


  const onShiftChange = async (record, value) => {
    const payload = getUpdatePayload(record, { shift_id: value })
    await updateEmployeeDetails(record.id, payload, false)
    setRefreshTable(true)
  }
  const onEmploymentChange = async (record, value) => {
    const data = { employment_model: value.split(',') };
    await updateEmployeeDetails(record.id, data, false)
    value && setRefreshTable(true)
  }
  const employeeHistoryModal = async id => {
    setSelectedRecordId(id)
    setIsModalOpen(true);
  };

  const columns = useMemo(() => getColumns(
    getcompany,
    setshowFeedBackDrawer,
    onStatusChange,
    onSupervisorChange,
    onShiftChange,
    onRoleChange,
    setEmpRecord,
    onEmploymentChange,
    employeeHistoryModal,
    themeColor,
    getLeadRole,
    getEmpFields
  ), [getcompany, setshowFeedBackDrawer, onStatusChange, onSupervisorChange, onShiftChange, onRoleChange, setEmpRecord, onEmploymentChange, employeeHistoryModal, themeColor, getLeadRole, getEmpFields]);



  const loadData = async (record, onExpand, e) => {
    setLoader({ id: record.id, loader: true })
    const res = await loadHierarchyList(record.id, getParentHierarchyList)
    if (res?.success) {
      setChildData([res.hierarchyData])
    }
    onExpand(record, e)
    setLoader({ id: '', loader: false })
  }


  useEffect(() => {
    if (getParentHierarchyList) {
      setChildData(getParentHierarchyList)
    } 
  }, [getParentHierarchyList])

   useEffect(()=>{
      if(showHierarchy) loadHierarchyListParent();
  },[showHierarchy])

  return (
    <>
      <div className='table-responsive'>
        <div>
          {showInterns && (
            <Employeetable
              ref={tableRef}
              columns={columns}
              dataUrl={`${employeeEmpUrl}/view_intern`}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              queryParam={queryParam}
              loaderPosition={'center'}
            />
          )}
          {(!showHierarchy && !showInterns) && (
            <Employeetable
              ref={tableRef}
              columns={columns}
              dataUrl={employeeEmpUrl}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              queryParam={queryParam}
              loaderPosition={'center'}

            />
          )}
          {showHierarchy && (
            <Employeetable
              ref={tableRef}
              columns={columns}
              dataSource={childData}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              queryParam={queryParam}
              dataComp={childData}
              loaderPosition={'center'}
              expandable={{
                rowExpandable: record => record.name !== 'Not Expandable',
                expandIcon: ({ expanded, onExpand, record }) => {
                  if (expanded) {
                    if (record?.hasChild) {
                      return (
                        <ArrowUP
                          style={{ fontSize: '28px', cursor: 'pointer' }}
                          onClick={async e => {
                            // record['children'] = []
                            onExpand(record, e)
                          }}
                        />
                      )
                    }
                  } else if (record?.hasChild) {
                    if (loader?.loader && loader?.id === record?.id) {
                      return (
                        <Spinner
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 14 }}
                              spin
                            />
                          }
                        />
                      )
                    }
                    return (
                      <ArrowDown
                        style={{ fontSize: '28px', cursor: 'pointer' }}
                        onClick={e => {
                          if (record?.children?.length) {
                            onExpand(record, e);
                          } else {
                            loadData(record, onExpand, e);
                          }
                        }}
                      />
                    )
                  }
                },
              }}
              supervisorId
            />
          )}
        </div>
        {/*Payroll History Modal */}
        <CustomDrawer
          visible={isModalOpen}
          setVisible={setIsModalOpen}
          className={`addDrawer`}
          empRecord={empRecord}
        >
          <EmployeeHistory employeeID={selectedRecordId} />
        </CustomDrawer>
        <CustomDrawer
          visible={showFeedBackDrawer}
          setVisible={setshowFeedBackDrawer}
          onClose={() => setshowFeedBackDrawer(false)}
          className={`addDrawer`}
          empRecord={empRecord}
          customFooter={
            <Space>
              <CustomButton
                htmlType='submit'
                type='primary'
                title={CANCEL}
                className='cancelButton feedbackBtn'
                onClick={() => setshowFeedBackDrawer(false)}
              />
              <CustomButton
                form='feedback-form'
                htmlType='submit'
                type='primary'
                title={'Submit'}
                className='addButton feedbackBtn'
              />
            </Space>
          }
        >
          <FeedbackQuestionDrawer empRecord={empRecord} setRefreshTable={setRefreshTable} setshowFeedBackDrawer={setshowFeedBackDrawer} />
        </CustomDrawer>

      </div>
    </>
  )
})
export default EmployeeTable

const Employeetable = styled(Table)`
.ant-table-container table > tbody > tr td:first-child{
display: flex;
align-items: center;

.textHandle{
  max-width: 140px !important; 
  }
}`
export const commonStyle = css`
  position: absolute;
  right: 0px
  `
export const ArrowUP = styled(MdKeyboardArrowUp)`
${commonStyle}
`
export const ArrowDown = styled(MdKeyboardArrowDown)`
${commonStyle}
`