import React from 'react';
import { Avatar, Col, Drawer, Rate, Row } from 'antd';
import styled from 'styled-components';
import { useStore } from '@stores/root-store';
import { colors } from 'constant/colors';

const { TealishBlue } = colors

export interface drawerProps {
  children: React.ReactNode;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  customFooter?: React.ReactNode | boolean;
  formId?: string;
  title?: any;
  width?: number;
  isworkReport?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  className?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onClose?: any;
  loading?: boolean;
  maskClosable?: boolean;
  empRecord?: any;
}
export function isColorDark(color) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
}
const CustomDrawer = (props: drawerProps) => {
  const{
    themesModel: { getColorsData }
  }=useStore(null)

  const Header = (
    <div>
      <Row>
        <Col lg={14}>
          <Profileheader>
            <Profile>
              <Profileimage>
                {
                  props?.empRecord?.employeeImage ? <Avatar src={props.empRecord.employeeImage} size={75} /> :
                    <Avatar size={75}>
                      {props.empRecord && props.empRecord?.userFullName}
                    </Avatar>
                }
              </Profileimage>
              <Styleactive></Styleactive>
              <div className='d-flex align-items-center'>
                <div>
                  <div>
                    {' '}
                    Name:{' '}
                    <span className='detail'>
                      {props.empRecord && props.empRecord?.userFullName || '-'}
                    </span>
                  </div>
                  <div>
                    Designation :{' '}
                    <DesignationSpan>
                      {props.empRecord && props.empRecord?.designationTitle || '-'}
                    </DesignationSpan>
                  </div>
                  <div>
                    Date of join :{' '}
                    <span className='detail'>
                      {(props.empRecord && props.empRecord?.joiningDate) || '-'}
                    </span>
                  </div>
                </div>
              </div>
            </Profile>
          </Profileheader>
        </Col>
        <Col lg={10}>
          <Profileheader>
            <div>
              <div>
                Employee ID:{' '}
                <span className='detail'>
                  {(props.empRecord && props.empRecord?.employeeId) || '-'}
                </span>
              </div>
              <div>
                Reports to:{' '}
                <span className='detail'>
                  {(props.empRecord && props.empRecord?.supervisorFullName) || '-'}
                </span>{' '}
              </div>
              <div>
                Rating:{' '}
                <Rate
                  disabled
                  allowHalf
                  value={
                    props.empRecord?.avgRating
                      ? props.empRecord?.avgRating
                      : 0
                  }
                />{' '}
              </div>
            </div>
          </Profileheader>
        </Col>
      </Row>
    </div>
  );
  // work report header
  const WorkReportHeader = (
    <div>
      <Row>
        <Col lg={14}>
          <Profileheader>
            <Profile>
              <Profileimage>
                <Avatar size={75}>
                  {props.empRecord && props.empRecord?.employeeName}
                </Avatar>
              </Profileimage>
              <Styleactive></Styleactive>
              <div className='d-flex align-items-center'>
                <div>
                  <div>
                    {' '}
                    Name:{' '}
                    <span className='detail'>
                      {props.empRecord && props.empRecord?.employeeName}
                    </span>
                  </div>
                  {props.empRecord?.designationName ?
                    <div>
                      <span className='detail'>
                        {(props.empRecord && props.empRecord?.designationName)}
                      </span>
                    </div> : ''
                  }
                  <div>
                    Date :{' '}
                    <span className='detail'>
                      {(props.empRecord && props.empRecord?.joiningDate) || '-'}
                    </span>
                  </div>
                </div>
              </div>
            </Profile>
          </Profileheader>
        </Col>
      </Row>
    </div>
  );
  return (
    <Wrapper color={getColorsData || 'blue'} background={getColorsData||'white'} textColor={isColorDark(getColorsData?.bodyColor || TealishBlue)}
      title={props.title ? props.title : props.isworkReport ? WorkReportHeader : Header}
      width={props?.width || 850}
      className={props.className}
      style={{ zIndex: 1050 }}
      onClose={() => {
        props.setVisible(false);
      }}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      maskClosable={false}
      footer={props?.customFooter}
      {...props}
    >
      {props.children}
    </Wrapper>
  );
};
export default CustomDrawer;
const Profileheader = styled.div`
  display: flex;
  align-items: center;
  height: 121px;
  padding: 7px 16px 16px 7px;
  font-family: "Outfit";
  color: #fff;
  * {
    font-size: 14px;
  }
  .detail,
  ul {
    margin-left: 10px;
  }
  .ant-avatar-string {
    font-size: 23px !important;
    text-transform: capitalize !important;
    color: #6b6b6b !important;
  }
`;

const Profile = styled.div`
  margin-left: 11px;
  display: flex;
  gap: 30px;
  position: relative;
`;

const DesignationSpan = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    max-width:180px;
`

const Profileimage = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #8ab8fd;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Styleactive = styled.div`
  width: 12px;
  height: 12px;
  background: #d7e90d;
  border: 1px solid ${TealishBlue};
  border-radius: 20px;
  position: absolute;
  left: 69px;
  bottom: 8px;
`;
const Wrapper = styled(Drawer)`
.ant-drawer-content {
    background-color:  ${props => props?.background?.theme == 'dark' ?'#201f1f':'white' } !important; 
  }
  .ant-form-item-label>label{
    color:  ${props => props?.background?.theme == 'dark' ?'white':'#201f1f' } !important; 
  }
  .ant-drawer-header {
    background: ${props => props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    padding: 19px !important;
    .ant-drawer-close {
      right: -12px;
      position: absolute;
      background: ${props => props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
      top: 10px;
      right: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 32px;
      height: 32px;
      background: #286ACE;
      color: #fff;
      .anticon-close {
        transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
        transition-delay: 0.2;
      }
      &:hover {
        color: #fff;
        border-radius: 32px;
        box-shadow: 0px 0px 10px rgba(0, 0.1, 0, 0.5);      
        .anticon-close {
          transform: rotate(180deg);
      


        }
      }
    }
  }
  .ant-input {
    &:hover {
        border-color: ${props => props.color?.bodyColor} !important;
    }
}
.ant-select .ant-select-selector:hover{
  border-color: ${props => props.color?.bodyColor} !important;
}
.ant-picker {
	&:hover {
        border: 1px solid ${props => props.color?.bodyColor} !important;
    }
}
  .ant-drawer-title {
    color: white;
    padding-right: 23px;
    .ant-icon {
      color: black;
    }
  }
  .ant-drawer-body{
      padding: 10px 24px 0 24px !important;
      .col-md-12{
          padding:0;
      }
  }
  
  .ant-drawer-content-wrapper {
    width: 750px !important;
    @media (max-width: 768px) {
      width: 560px !important;
    }
    @media (max-width: 992px) {
      width: 750px !important;
    }
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
  .ant-drawer-footer {
    padding: 25px 16px;
    align-items: center;
    justify-content: center;
    background-color: 'red';
    display: flex;
    .ant-btn{
      background-color: ${props => props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
      border: 1px solid ${props => props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
      color:${props => props.textColor ? 'white' : 'black'} !important;
      &:hover{
        color:${props => props.textColor ? 'white' : 'black'} !important;
      }
    }
  }
  .ant-tabs-tab:hover{
    color: ${props => props.color?.bodyColor ? props.color?.bodyColor : 'black'} !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: ${props => props.color?.bodyColor ? props.color?.bodyColor : 'black'} !important;
  }
`;
