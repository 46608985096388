import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl } from '../const/index'

class EmployeeStatsApi extends BaseApi {

  setAssetsStats = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}employee_stats_list/`,
        data,
        headerAuthorization(this)
      )
    
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
 
  getEmployeeStats = async () => {
    try {
      const response = await this.axios.get(
        `${baseUrl}employee_stats_list/`,
        headerAuthorization(this)
      )
    
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeStatsApi