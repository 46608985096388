import { LabelHTMLAttributes, ReactNode } from 'react'
import styles from './styles.module.scss'

export type LabelProps = LabelHTMLAttributes<{
  children?: ReactNode;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  id?: any;
}>

export const Label: React.FC<LabelProps> = ({ id, children, ...props }) => {
  return (
    <label id={id} className={styles.label} {...props}>
      {children}
    </label>
  )
}
