import { flow, types } from 'mobx-state-tree'
import { clientTypeApi } from '../../api'
import { notification } from '@utils/notifications'

export const client = types.model({
  clientType: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
})

export const clientTypeModal = types
  .model({
    data: types.maybeNull(types.array(client)),
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    deleteLoading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getClientTypeData() {
      return self.data
    },
    get isLoading() {
      return self.loading
    },
    get isDeleteLoading() {
      return self.deleteLoading
    },
  }))
  .actions(self => {
    const setClientTypeData = flow(function* (data) {
      try {
        self.loading = true
        yield clientTypeApi.setclientType(data)
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadClientType = flow(function* (num = 1) {
      self.loading = true
      try {
        self.loading = true
        const res = yield clientTypeApi.getClientType(num)
        self.data = res.results
        self.count = res.count
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Client Type')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const updateClientType = flow(function* (id, data) {
      try {
        self.loading = true
        const res = yield clientTypeApi.updateClientType(id, data)
        self.data = res.results
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteClientType = flow(function* (id) {
      try {
        self.deleteLoading = true
        const res = yield clientTypeApi.deleteClientType(id)
        self.data = res.results
      } catch (error) {
        // error catch
      } finally {
        self.deleteLoading = false
      }
    })

    return {
      setClientTypeData,
      loadClientType,
      updateClientType,
      deleteClientType,
    }
  })

export function initClientTypeModal() {
  return clientTypeModal.create({})
}
