import { flow, types, cast } from 'mobx-state-tree'
import { clearanceApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'

export const clearance = types.model({
  id: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  assetReceivedBy: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  loanStatus: types.maybeNull(types.boolean),
  eCanteenStatus: types.maybeNull(types.boolean),
  salaryStatus: types.maybeNull(types.boolean),
  assetStatus: types.maybeNull(types.boolean)
})

export const assetData = types.model({
  assetId:types.maybeNull(types.number),
  employeeId:types.maybeNull(types.string),
  employeeName: types.maybeNull(types.string),
  name: types.maybeNull(types.string)


})

export const AssetData = types.model({
  assetId:types.maybeNull(types.number),
  assetHistoryStatus:types.maybeNull(types.string),
  assetHistoryCondition: types.maybeNull(types.string),
  assetName: types.maybeNull(types.string),
})


export const clearanceModel = types
  .model({
    data: types.maybeNull(types.array(clearance)),
    loading: types.optional(types.boolean, true),
    count: types.maybeNull(types.number),
    clearanceData: types.maybeNull(types.array(assetData)),
    nextclearance: types.optional(types.number, 1),
    assetData: types.maybeNull(types.array(AssetData)),
    netSalary: types.maybeNull(types.number),
    canteenAmount: types.maybeNull(types.number)
  })
  .views(self => ({
    get getClearanceData() {
      return toJS(self.data)
    },
     
    get getassetData() {
      return toJS(self.assetData)
    },
    get getcanteenAmount() {
      return toJS(self.canteenAmount)
    },
    get getnetSalary() {
      return toJS(self.netSalary)
    },
    get isLoading() {
      return self.loading
    },
    get clearanceEmployeeData() {
      return toJS(self.clearanceData)
    }
  }))

  .actions(self => {
    const setClearance = flow(function* (data) {
      try {
        self.loading = true
        yield clearanceApi.setClearance(data)
        notification.success('Clearance Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadClearance = flow(function* (page=1, pageSize=20, queryParam='', resetList=false) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextclearance = 1
        }
        const res = yield clearanceApi.getClearance(page, pageSize, queryParam)
        self.data = res.items;        
        if (!res?.isCancel){
          self.data = res
          if (res?.next) {
            self.nextclearance = self.nextclearance + 1
          } else {
            self.nextclearance = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load clearance')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })


    const loadClearanceforEmployee = flow(function* (queryParam) {
      try {
        const res = yield clearanceApi.getClearanceforEmployee(queryParam)
        if (!res?.isCancel){
          self.assetData = cast(res.assetData)
          self.netSalary = cast(res.netSalary[0].netSalary)
          self.canteenAmount = cast(res.canteenAmount[0].canteenAmount)
          if (res?.next) {
            self.nextclearance = self.nextclearance + 1
          } else {
            self.nextclearance = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load clearance')
        }        return { success: false }
      }
    })
    const getClearanceAssetList = flow(function* (id) {
      
      try {
        self.loading = true
        const res = yield clearanceApi.getClearanceAssetList(id)
        if(res.data?.message){
          notification.success('Clearance Updated Successfully')
        }
        return res
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const updateEmployeeClearance = flow(function* (data, id) {
      try {
        self.loading = true
        yield clearanceApi.updateClearance(data, id)
        notification.success('Clearance Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setClearance,
      getClearanceAssetList,
      loadClearance,
      updateEmployeeClearance,
      loadClearanceforEmployee
    }
  })

export function initClearanceModel() {
  return clearanceModel.create({})
}
