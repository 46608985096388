import { cast, flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { employeeStatsApi } from '@api'
import { notification } from '@utils/notifications'


export const employeeRatio = types.model({
  joinedRatio:types.maybeNull(types.number),
  resignedRatio: types.maybeNull(types.number),
  terminatedRatio: types.maybeNull(types.number),

})

export const employee = types.model({
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  status: types.maybeNull(types.string),

})
export const employeeData = types.model({
  employee: types.maybeNull(types.array(employee)),
  ratio: types.maybeNull(types.array(employeeRatio)),

})

export const employeeStatsModal = types
  .model({
    data: types.maybeNull(employeeData),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getEmployeeStatsData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const loadEmployeeStats = flow(function* () {
      self.loading = true
      try {
        self.loading = true
        const res = yield employeeStatsApi.getEmployeeStats()
        self.data = cast(res[0] || {})
        self.count = res.count

      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Employee Stats')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      loadEmployeeStats,
    }
  })

export function initEmployeeStatsModal() {
  return employeeStatsModal.create({})
}
