
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import bankImage from '../../../assets/img/bankImage.svg'
import { Skeleton } from 'antd';
import { useStore } from '@stores/root-store';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'constant/colors';

const { TealishBlue } = colors

const EmployeeBankDetailProf = observer(() => {
  const [isBankDetailLoader, setIsBankDetailLoader] = useState(true)
  const [salary, setSalary] = useState('')
  const { id: employeeID } = useParams()
  
  const {
    bankDetails: {
      bankData,
      loadBankDetails,
    },
    employeePayroll: {
      retrieveEmployeePayroll,
      loading: payrollLoading,
    },
    profileDetails: {
      profileData,
      loading: profileLoading,
    }
  } = useStore(null)

  async function load() {
    await loadBankDetails(employeeID).then(() => setIsBankDetailLoader(false))
  }

  async function loadPayroll(payrollId: string) {
    await retrieveEmployeePayroll(payrollId, true).then(res => {
      setSalary(res?.res?.netSalary)
    })
  }
  useEffect(() => {
    load()
  }, [employeeID])

  useEffect(() => {
    if (profileData?.payrollId) {
      loadPayroll(profileData.payrollId)
    }
  }, [profileData])

  return (
    <Pagediv className='page-wrapper'>
      <Skeleton active loading={isBankDetailLoader || payrollLoading || profileLoading} paragraph={{ rows: 2 }} style={{ paddingLeft: 70, paddingRight: 250, marginTop: 50 }}>
        <StyledDiv  >
          <BankInfoWrapper className='d-flex justify-content-between mb-4'>
            <div className={'cardTitle'}>Bank Information</div>
            <div className={'cardTitle'}>Current Salary</div>
          </BankInfoWrapper>
          <AddResponsive className='d-flex justify-content-between'>
            <StyleImage className='d-flex  gap-2 gap-md-4'>
              <BankImageWrapper><img src={bankImage} alt={bankImage} /></BankImageWrapper>
              {bankData ?
                <BankAccountWrapper className={'bankinfo'}>
                  <div className={'boldText'}>Account Title: <span>{bankData[0]?.title ?? '-'}</span></div>
                  <div className={'boldText'}>Account No: <span className={'span3'}>{bankData[0]?.number ?? '-'}</span></div>
                </BankAccountWrapper>
                : ''}
            </StyleImage>
            <div className={'employeeSalary'}> {salary ? salary : 0} PKR/-</div>
          </AddResponsive>
        </StyledDiv>
      </Skeleton>
    </Pagediv>
  )
})

export default EmployeeBankDetailProf

const Pagediv = styled.div`
min-height: unset !important;
`

const BankImageWrapper = styled.div`
  display:flex;
  @media (max-width:600px){
    width:45px;
    height:45px;
  }
  img{
    @media (max-width:600px){
      height:45px;
      width:45px;
    }
  }

  `
const BankAccountWrapper = styled.div`
    @media (max-width:600px){
      font-size: 14px;
    }
   
  `

const AddResponsive = styled.div`
    @media (max-width: 422px){
      flex-direction: column !important;
    }
  `
const StyleImage = styled.div`
     @media (max-width: 422px){
      flex-direction: column !important;
      justify-content: center;
    align-items: center;
    img{
      width: 60px;
      height: 60px;
    }
    }
    @media (max-width: 600px){
      align-items: center;
    }
  `
const BankInfoWrapper = styled.div`
   @media (max-width: 422px){
      flex-direction: column !important;
      justify-content: center;
    align-items: center;
   }
`
const StyledDiv = styled.div`
padding: 40px 40px 0 40px;
      @media (max-width: 500px) {
        padding: 10px 10px 0 10px;
      }
      .cardTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #010101;
        @media (max-width: 600px) {
          font-size: 16px;
        }
      }
      .bankinfo {
        .boldText {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #010101;
          margin-bottom: 5px;
          @media (max-width: 600px) {
            font-size: 14px;
          }
          @media (max-width: 400px) {
            text-align: center;
          }

          span {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #525353;
            padding-left: 10px;
            @media (max-width: 600px) {
              font-size: 14px;
            }
          }
          .span2 {
            padding-left: 20px;
          }
          .span3 {
            padding-left: 22px;
          }
        }
      }
      .employeeSalary {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        text-align: right;
        color: ${TealishBlue};
        @media (max-width: 600px) {
          font-size: 20px !important;
        }
        @media (max-width: 422px) {
          font-size: 15px !important;
          text-align: center;
        }
      }
`