import style from '@mainPage/rolePermission/style.module.scss';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { Checkbox } from 'antd';
import React from 'react';
import { DELETE_METHOD, GET_METHOD, IS_ADMIN, IS_HR, IS_INTERN, IS_LEAD, PATCH_METHOD, POST_METHOD, PUT_METHOD, VIEW_ALL, VIEW_INTERN } from '../../../constant/constant';
import styled from 'styled-components'
import { getThemeColor } from '@utils/common-functions';
import { useState } from 'react';

export const useCheckedState = () => {
  const [checked, setChecked] = useState(false);
  return { checked, setChecked };
};
export const roleHeader = isTrue => isTrue ? 'Update Role' : 'Add Role'
export const methodMap = meth => {
  switch (meth) {
    case 0:
      return IS_LEAD
    case 1:
      return IS_ADMIN
    case 2:
      return IS_INTERN
    case 3:
      return IS_HR
    case 4:
      return VIEW_INTERN
  }
}
export const getMethod = meth => {
  switch (meth) {
    case 0:
      return GET_METHOD
    case 1:
      return POST_METHOD
    case 2:
      return PUT_METHOD
    case 3:
      return PATCH_METHOD
    case 4:
      return DELETE_METHOD
    case 5:
      return VIEW_ALL
  }
}
export const getColumns = ({ getval, checkBoxes }) => {
  function checkConditions(name,index, condition1, condition2) {
    const isChecked = checkBoxes?.[name || '']?.methods[index];
    return isChecked === condition1 || isChecked === condition2;
  }
  return [
    {
      title: '',
      dataIndex: 'name',
      render: () => (
        <>
          <div className={style.allowedaccess}>
            <MdSubdirectoryArrowRight size={17} />
            <p>Allowed Access</p>
          </div>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'methods',
      render: (text, record) => {

        return (
          <h6 style={{ color: getThemeColor() }}>
            {record.name === 'accessView' ? 'Is Lead' : 'Read'}{' '}

            <StyledCheckbox
              onChange={event => getval(event, record, 0)}
              checked={checkConditions(record.name,0, GET_METHOD, IS_LEAD)}
            />
          </h6>

        )
      }
    },
    {
      title: '',
      dataIndex: 'methods',
      render: (text, record) => (
        <h6 style={{ color: getThemeColor() }}>
          {record.name === 'accessView' ? 'Is Admin' : 'Write'}{' '}
          <StyledCheckbox
            onChange={event => getval(event, record, 1)
            }
            checked={checkConditions(record.name,1, POST_METHOD, IS_ADMIN)}
          />
        </h6>
      ),
    },
    {
      title: '',
      dataIndex: 'methods',
      render: (text, record) => (
        <h6 style={{ color: getThemeColor() }}>
          {record.name === 'accessView' ? 'Is Intern' : 'Update'}{' '}
          <StyledCheckbox
            onChange={
              event => getval(event, record, 2)
            }
            checked={checkConditions(record.name,2, PUT_METHOD, IS_INTERN)}
          />
        </h6>
      ),
    },
    {
      title: '',
      dataIndex: 'methods',
      render: (text, record) => (
        record.name === 'accessView' ? null :   
        <h6 style={{ color: getThemeColor() }}>
          Partial Update{' '}
          <StyledCheckbox
            onChange={event => getval(event, record, 3)}
            checked={checkConditions(record.name, 3, PATCH_METHOD, IS_HR)}
          />
        </h6>
      ),
    },
    {
      title: '',
      dataIndex: 'methods',
      render: (text, record) => (
        <h6 style={{ color: getThemeColor() }}>
          {record.name === 'accessView' ? 'View All Interns' : 'Delete'}{' '}

          <StyledCheckbox
            onChange={
              event => getval(event, record, 4)
            }
            checked={checkConditions(record.name,4, DELETE_METHOD, VIEW_INTERN)}           
          />
        </h6>
      ),
    },
    {
      title: '',
      dataIndex: 'methods',
      render: (text, record) => (
        record.name === 'accessView' ? '' :
          <h6 style={{ color: getThemeColor() }}>
            View ALL{' '}
            <StyledCheckbox
              onChange={
                event => getval(event, record, 5)
              }
              checked={checkBoxes?.[record.name || '']?.methods[5] === VIEW_ALL}
            />
          </h6>
      ),
    },
  ];
};

const StyledCheckbox = styled(Checkbox)`
  .p {
    font-size: 50px;
  }
  .ant-checkbox-inner {
    border-radius: 5px !important;
    padding-top: 5px;
    box-shadow: 0px 2px 2px #E2E2E2 !important;
  }
  .ant-checkbox-wrapper {
    margin-top: 4px;
  }
  .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked:after {
    border-radius: 5px !important;
    border: 1px solid white;

  }
  &:hover {
    border:none !important;
  }
`
