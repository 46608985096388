import { BaseApi } from '../baseApi'
import { PayrollClosing, PayrollCsvUrl, PayrollPdfUrl, payrollHistoryUrl, PayrollUrl, UpdatePayroll } from '../const'
class EmployeePayrollApi extends BaseApi {

  createEmployeePayroll = async data => {
    try {
      const response = await this.axios.post(PayrollUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateEmployeePayroll = async (data, payrollId) => {
    try {
      const response = await this.axios.patch(`${UpdatePayroll}${payrollId}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  retrieveEmployeePayroll = async payrollId => {
    try {
      const response = await this.axios.get(`${PayrollUrl}${payrollId}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateEmployeeIncrementOrBonus = async (data, type) => {
    try {
      const response = await this.axios.post(`${PayrollUrl}${type}/`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeePayrollHistory = async payrollId => {
    try {
      const response = await this.axios.get(`${payrollHistoryUrl}${payrollId}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  exportPayrollRecord = async (queryParam = {}, format = '') => {
    try {
      let response: any
      if (format === 'csv') {
        response = await this.axios.get(PayrollCsvUrl, { params: queryParam ? { ...queryParam } : {} })
      } else if (format === 'pdf') {
        response = await this.axios.get(PayrollPdfUrl, { params: queryParam ? { ...queryParam } : {} })
      }
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  closeCurrentMonthPayroll = async () => {
    try {
      const response = await this.axios.patch(PayrollClosing)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeePayrollApi
