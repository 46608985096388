import { BaseApi } from '../baseApi'
import { dashboardUrl } from '../const'

class DashBoardApi extends BaseApi {
  getEmployeeAttendanceOverview = async params => {
    try {
      const response = await this.axios.get(
        `${dashboardUrl}/employee_attendance_overview`,
        {
          params: { ...(params ? params : {}) },
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getSingleEmployeeAttendance = async () => {
    try {
      const response = await this.axios.get(
        `${dashboardUrl}/employees_attendance`
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default DashBoardApi
