import { flow, types } from 'mobx-state-tree'
import { roleApi } from '../../api/index'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'

export const role = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
})
export const roleModal = types
  .model({
    data: types.maybeNull(types.array(role)),
    id: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getRoleData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setRoleDetailsData = flow(function* (data) {
      try {
        self.loading = true
        yield roleApi.setRoleDetails(data)
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })
    const loadRoleDetails = flow(function* () {
      try {
        self.loading = true
        const res = yield roleApi.getRoleDetails()
        self.data = res
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Role')
        }
      } finally {
        self.loading = false
      }
    })
    const updateRoleDetails = flow(function* (data, id) {
      try {
        yield roleApi.updateRoleDetails(data, id)
        const index = self.data.findIndex(val => val.id === id)
        self.data[index].name = data
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })
    const deleteRoleDetails = flow(function* (id) {
      try {
        yield roleApi.deleteRoleDetails(id)
        const index = self.data.findIndex(val => val.id === id)
        self.data.splice(index, 1)
      } finally {
        self.loading = false
      }
    })

    return {
      setRoleDetailsData,
      loadRoleDetails,
      updateRoleDetails,
      deleteRoleDetails,
    }
  })

export function initRoleModal() {
  return roleModal.create({})
}
