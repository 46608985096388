import { headerAuthorization } from '../common-utils'
import { assetLeadUrl } from '../const'
import { BaseApi } from '../baseApi'



export default class LeadsAssetApi extends BaseApi{
  getLeads = async (num,pageSize,queryParam={}) => {
    try {
      let url = assetLeadUrl
      url += `?page=${num ? num :1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? {...queryParam} : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
     
} 