import React, { useEffect, useState } from 'react'
import { InputNumber, Form, Col, Row } from 'antd'
import 'antd/dist/antd.css'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { REASON, REASON_TITLE, VERTICAL } from 'constant/constant'
import styled from 'styled-components'
import { CommonInput } from '@commonComponents/input'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { colors } from 'constant/colors'

const { TealishBlue, mediumGray } = colors
interface ResignationFormProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setBtnLoader: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  closeModal: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRefreshTable: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  isUpdate: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  currentData: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setApplyresignation: any;

}
const ResignationapplyForm = observer(({ setBtnLoader, setRefreshTable, isUpdate, currentData, setApplyresignation }: ResignationFormProps) => {
  const [form] = Form.useForm()
  const [resignationID, setresignationID] = useState('')

  const {
    resignationModel: { updateEmployeeResignation, setResignationData },
  } = useStore(null)

  useEffect(() => {
    form.resetFields()
    if (isUpdate) {
      setresignationID(currentData?.id || '')
      form.setFieldsValue({
        employeeId: currentData?.employeeId,
        employee: currentData?.employeeName,
        notice_period: currentData?.noticePeriod,
        reason: currentData?.reason,
      })
    }
  }, [isUpdate])

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onlyNumber = (e: any) => {
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189)
      e.preventDefault();
  }

  const onFinish = async values => {
    let response = null
    setBtnLoader(true)

    const submitPayload = {
      notice_period: values?.notice_period,
      reason: values?.reason,
    }
    if (isUpdate) {
      response = await updateEmployeeResignation(submitPayload, resignationID)
    } else {
      response = await setResignationData(submitPayload)
    }
    if (response?.success) {
      setBtnLoader(false)
      setApplyresignation(false)
      setRefreshTable(true)
      form.resetFields()
    }
    setBtnLoader(false)
  }
  return (
    <>
      <Form
        form={form}
        id={'resignation-form'}
        onFinish={onFinish}
        layout={VERTICAL}
        scrollToFirstError>
        <Row>
          <NoticeInputCol span={24}>
            <Form.Item
              name={'notice_period'}
              label='Notice Period'
              rules={[validateRequired('Notice period')]}
              className='w-100'
            >
              <StyledNoticePeriod
                min={0}
                placeholder='Number of days'
                onKeyDown={e => onlyNumber(e)}
                type='number'
              />
            </Form.Item>
          </NoticeInputCol>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name={REASON}
              label={REASON_TITLE}
              rules={[validateRequired(REASON_TITLE), validateNoWhiteSpace()]}>
              <StyleCommonInput inputType='textarea'
                placeholder='Write reason here'/>
            </Form.Item>
          </Col>
        </Row>
      </Form >
    </>
  )
})
export default ResignationapplyForm;

const StyledNoticePeriod = styled(InputNumber)`
    height: 45px;
  width: 100%;
  border: 1px solid ${mediumGray};
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px !important;

  .ant-input-number-handler-wrap {
    opacity: 1;
    border-radius: 0px 8px 8px 0px;

    .ant-input-number-handler {
      border-left: 0;
      top: 12px;
    }

    .ant-input-number-handler-up {
      height: 10% !important;
      width: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 8px solid #333;
    }

    .ant-input-number-handler-down {
      width: 0 !important;
      border-top: 8px solid #333 !important;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      top: 15px;
    }
  }

  .ant-input-number-input {
    margin-top: 8px;
    :hover{
      border-color: ${TealishBlue} !important;
    }
  }
`
const NoticeInputCol = styled(Col)`
  display: flex;
  align-items: center;
`

export const StyleCommonInput = styled(CommonInput)`
  border-radius: 8px;
`