import { headerAuthorization } from '../common-utils'
import { BaseApi } from '../baseApi'
import { dashboardAttendanceUrl, baseUrl} from '@api/const'

class dashboardAttendanceApi extends BaseApi {
 
  setAttandance = async data => {
    try {
      const response = await this.axios.post(`${baseUrl}/attendance_list/user-attendance/`, data, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getAttendence = async (queryParam = {}) => {
    try {
      const url = dashboardAttendanceUrl
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {},
          ...headerAuthorization(this)
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateAttandance = async (data, id) => {
    try {
      const response = await this.axios.put(`${baseUrl}/attendance_list/user-attendance/${id}/`, data, 
        headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteAttandance = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}/attendance_list/user-attendance/${id}/`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default dashboardAttendanceApi