import { flow, types, cast } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { expenseDashboardApi } from '@api'
import { notification } from '@utils/notifications'
export const expenseDashboard = types.
  model({
    type: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
    year: types.maybeNull(types.number),
    month: types.maybeNull(types.number),
  })

export const expenseDashboardModal = types
  .model({
    data: types.maybeNull(types.array(expenseDashboard)),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get getExpenseDashboardData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const loadExpenseDashboard = flow(function* () {
      try {
        self.loading = true
        const res = yield expenseDashboardApi.getExpenseDashboard()
        const finalResult = []
        res.forEach(element => {
          element.forEach(innerElement => {
            finalResult.push(innerElement)
          });
        });
        self.data = cast(finalResult)
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Graph Data.')
        } return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      loadExpenseDashboard
    }
  })

export function initExpenseDashboardModal() {
  return expenseDashboardModal.create({})
}
