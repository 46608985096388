import React, { useEffect } from 'react'
import { Form, Row, Col, DatePicker } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { useStore } from '@stores/root-store'
import { CommonSelect } from '@commonComponents/select' 
import { AiFillCalendar } from 'react-icons/ai';
import SearchFilter from '@commonComponents/SearchFilter'

interface ExpenseSearchFormProps {
  setQueryParam: (data: object) => void;
  activeTab: string;
  resetFilters?: any;
  setResetFilters?: any;
}

const SearchExpense = observer((props: ExpenseSearchFormProps) => {

  const {
    employeeDetails: { loadAllEmployeeList, getEmployeeAllList },
  } = useStore(null)

  useEffect(() => {
    async function load() {
      if (!getEmployeeAllList?.length) {
        await loadAllEmployeeList()
      }
    }
    load()
  }, [])
  const allEmployees = getEmployeeAllList?.map(value => ({ key: value.id, value: value.fullName }));

  return (
    <SearchFilter
      {...props}
      format={'YYYY-MM-DD'}
      children={
        <div>
          <Row gutter={4}>
            <Col xs={24} sm={12} lg={4}>
              <Form.Item name='title'>
                <CommonInput
                  placeholder='Item Name'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={5}>
            <Form.Item name={'purchased_by'}>
            <CommonSelect
            listHeight={125}
            getPopupContainer={trigger => trigger.parentNode}
            placeholder='Purchased By'
            data={allEmployees}
            showSearch
            allowClear
            filterOption={(input, option) =>(option.children.toLowerCase().includes(input.toLowerCase()))}/>
            </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={5}>
              <Form.Item name='payment_type'>
                <CommonSelect
                  listHeight={120}
                  placeholder='Payment Type'
                  size='large'
                  allowClear  
                  getPopupContainer={trigger => trigger.parentNode}
                  data={[
                    { 
                      key: 'cash',  
                      value: 'Cash',
                    },
                    {
                      key: 'cheque',
                      value: 'Cheque',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {props.activeTab === 'expense' &&
              <Col xs={24} sm={12} lg={5}>
                <Form.Item name='status'>
                  <CommonSelect
                    listHeight={120}
                    placeholder='Status'
                    size='large'
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    data={[
                      {
                        key: 'forward',
                        value: 'Forward',
                      },
                      {
                        key: 'approved',
                        value: 'Approved',
                      },
                      {
                        key: 'rejected',
                        value: 'Rejected',
                      },
                      {
                        key: 'pending',
                        value: 'Pending',
                      }
                    ]}
                  />
                </Form.Item>
              </Col>}
            <Col xs={24} sm={12} lg={5}>
              <Form.Item name='purchased_date'>
                <DatePicker
                  format='YYYY-MM-DD'
                  getPopupContainer={trigger => trigger.parentElement}
                  allowClear={false}
                  className='form-control'
                  placeholder='Purchased Date'
                  suffixIcon={<AiFillCalendar />}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>}
    />
  )
})
export default SearchExpense
