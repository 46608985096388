import { useStore } from '@stores/root-store';
import { getDayHour } from '@utils/common-functions';
import { calculateLeaves } from '@utils/common-functions';
import { Button, Drawer, Input, Progress, Row, Skeleton } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import styled from 'styled-components';
import { DATE_FORMATE, progressColor } from './const';
import { colors } from 'constant/colors';

const { TealishBlue } = colors

interface AttendaceSideDrawerProps {
  onClose?: any;
  open?: boolean;
  empLeaveData?: any;
  isCancel?: boolean;
  onStatusChange?: (id: string, value: string, data: any) => void;
}

function EmployeeLeaveHistoryDrawer({onClose, open, empLeaveData, isCancel}: AttendaceSideDrawerProps) {
  const [color, setColor] = useState(null)
  const [loader, setLoader] = useState(false);
  const { TextArea } = Input;

  const {
    employeeLeaveTypeModal: {
      loadEmployeeLeaveType,
      getEmployeeLeaveTypeData,
    },
    employeeLeaveCountModal: {
      loadEmployeeLeaveCount,
      getEmployeeLeaveCountData,
    },
  } = useStore(null);

  useEffect(() => {
    setColor(JSON.parse(localStorage.getItem('colorTheme')))
  }, [])

  useEffect(() => {
    async function loadData() {
      setLoader(true);
      await loadEmployeeLeaveCount(1, empLeaveData?.employee?.id);
      if (!getEmployeeLeaveTypeData) {
        await loadEmployeeLeaveType()
      }
      setLoader(false);
    }
    loadData();
  }, [empLeaveData]);

  const duration = getDayHour(empLeaveData.leaveStartDate, empLeaveData.leaveEndDate);
  const totalTakenLeaves = calculateLeaves(getEmployeeLeaveCountData, 'totalTakenLeaves')
  const totalLeaves = calculateLeaves(getEmployeeLeaveCountData, 'leaveTotalDays')

  const totalPercentageLeaves = (+totalTakenLeaves / +totalLeaves) * 100


  const { leadName, leaveTypeTitle } = empLeaveData || {}

  return (
    <EmployeeLeaveDrawer
      placement={'right'}
      width={625}
      open={open}
      closable={null}
    >
      <Skeleton active loading={loader}>
        <ModalHeader className='modal-header-parent-container' color={color || 'blue'}>
          <div className='modal-header'>Employee Leave History</div>
          <CloseIcon className='close-icon' color={color || 'blue'}>
            <SubCloseIconDiv
              style={{ cursor: 'pointer' }}
              className='sub-close-icon-div'
              onClick={() => onClose()}
            >
              <AiOutlineClose />
            </SubCloseIconDiv>
          </CloseIcon>
        </ModalHeader>

        <div className='modal-body'>
          <LeaveBox className='row-wrap justify-content-end'>
            <LeaveCount bgColor item={{ type: 'total' }}>
              <div className='progressDiv'>
                <Progress
                  type='circle'
                  strokeColor={'#e0c54a'}
                  format={() => <BsFillBriefcaseFill style={{ color: '#e0c54a' }} />}
                  percent={Math.floor(totalPercentageLeaves)}
                />
                <div className='totalLeavesDiv'>
                  <Number>{`${totalTakenLeaves}/${totalLeaves}`}</Number>
                  <LeaveType large>Total</LeaveType>
                </div>
              </div>
            </LeaveCount>
            {getEmployeeLeaveCountData?.map((item, index) => {
              const strokeColor = progressColor[index % progressColor.length];
              return (
                <LeaveCount item={item} key={index}>
                  <div className='progressDiv'>
                    <Progress
                      type='circle'
                      strokeColor={strokeColor}
                      format={() => <BsFillBriefcaseFill style={{ color: strokeColor }} />}
                      percent={Math.floor((item.totalTakenLeaves / item.leaveTotalDays) * 100)}
                    />
                    <div className='totalLeavesDiv'>
                      <Number>{`${item.totalTakenLeaves}/${item.leaveTotalDays}`}</Number>
                      <LeaveType large>{item.leavetype.split(' ')[0]}</LeaveType>
                    </div>
                  </div>
                </LeaveCount>
              );
            })}
          </LeaveBox>
          <div>
            <StyledRow>
              <div>
                <p className='title'>Employee Name</p>
                <p className='text'>{`${empLeaveData?.employee?.firstName} ${empLeaveData?.employee?.lastName}`}</p>
              </div>
              <div className='lead'>
                <p className='title'>Lead Name</p>
                <p className='text'>{leadName}</p>
              </div>
            </StyledRow>
            <StyledRow>
              <div>
                <p className='title'>Leave Type </p>
                <p className='text'>{leaveTypeTitle}</p>
              </div>
              <div className='lead'>
                <p className='title'>Duration</p>
                <p className='text'>
                  {moment(empLeaveData?.startDate).format(DATE_FORMATE)}-{moment(empLeaveData?.endDate).format(DATE_FORMATE)}
                </p>
              </div>
            </StyledRow>
            <StyledRow>
              <div className='leaveDays'>
                <p className='title'>Total Days Count</p>
                <p className='leaveDaysText'>
                  {isCancel
                    ? empLeaveData.isCancelled
                      ? `${empLeaveData.duration} Day(s)`
                      : `${empLeaveData.cancelNumberOfDays} Day(s)`
                    : empLeaveData.isFullLeave
                      ? `${empLeaveData.duration} Day(s)`
                      : `${duration.hours} Hour(s)`}
                </p>
              </div>
              <div className='lead'>
                <p className='title'>Status</p>
                <p className='text'>
                  {empLeaveData?.status == 'Approved' ? 'Approved'
                    : empLeaveData?.status == 'Rejected' ? 'Rejected'
                      : empLeaveData?.status == 'Forward' ? 'Forward'
                        : 'Pending'}
                </p>
              </div>
            </StyledRow>
            <ReasonDiv>
              <div className='textArea'>
                <p className='reason'>Reason</p>
                <TextArea
                  className='reasonText'
                  disabled={true}
                  cols={41}
                  rows={6}
                  value={empLeaveData?.isCancelledLeave ? empLeaveData?.cancelReason : empLeaveData?.reason}
                />
              </div>
            </ReasonDiv>
          </div>
        </div>
      </Skeleton>
    </EmployeeLeaveDrawer>
  )
}

export default EmployeeLeaveHistoryDrawer;

const EmployeeLeaveDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 625px;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          padding: 0 !important;
        }
      }
    }
    .ant-drawer-close {
      right: -12px;
      position: absolute;
      background: ${props => props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
      top: 10px;
      right: -5px;
      display: grid;
      place-items: center;
      width: 32px;
      height: 32px;
      background: #286ACE;
      color: #fff;
      .anticon-close {
        transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
        transition-delay: 0.2;
      }
      &:hover {
        color: #fff;
        border-radius: 32px;
        box-shadow: 0px 0px 10px rgba(0, 0.1, 0, 0.5);      
        .anticon-close {
          transform: rotate(180deg);
        }
      }
    }
  }
  
  @media screen and (min-width: 0px) and (max-width: 630px) {
    .ant-drawer-content-wrapper {
      width: -webkit-fill-available !important;
    }
  }   
`;
const ModalHeader = styled.div`
display: flex;
    justify-content: space-between;
    background: ${props => props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
    color: #f8f9fa;
    padding: 9px 20px;
    height: 60px;
    .modal-header {
      border: none !important;
    }
  @media screen and (min-width: 0px) and (max-width: 630px) {
    overflow-x: scroll;
  }
`

const LeaveBox = styled.div`
  margin-right: -3px;
  justify-content: end;
  padding-left: 8px;
`;

const LeaveCount = styled(Button)`
  @media screen and (min-width: 330px) {
  
    width: 144px !important;
  }
  width: 130px !important;
  height: auto !important;
  padding: 20px !important;
  border: none !important;
  text-align: center;
  cursor: default !important;
  width: 90px;
  word-spacing: 0;

  @media screen and (min-width: 0px) and (max-width: 556px) {
    height: auto !important;
  }
  @media screen and (min-width: 0px) and (max-width: 850px) {
    text-decoration: none;
    padding: 2px;
    font-size: 10px;
    margin: auto !important;
  }
  @media screen and (min-width: 0px) and (max-width: 478px) {
    padding: 2px 2px !important;
  }

  .progressDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px !important;
    .totalLeavesDiv {
      padding: 5px !important;
    }
    .ant-progress-circle .ant-progress-inner {
      width: 50px !important;
      height: 50px !important;
      font-size: 14px !important;
    }
  }
`;

const Number = styled.div`
  font-weight: 600;
  line-height: 30px;
  color: ${TealishBlue};
  @media screen and (min-width: 0px) and (max-width: 800px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
    font-size: 16px !important;
  }
  font-size: 20px;
`;

const LeaveType = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #525353;
  word-spacing: 0;
  font-family: 'Poppins';
  @media screen and (min-width: 0px) and (max-width: 600px) {
    font-size: 12px;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 20px;
  margin-left: 30px;
  @media only screen and (max-width: 346px) {
    display: block;
    flex-direction: column;
    justify-content: center;
    margin-top: 17px;
    margin-left: 30px;
  }
  .lead {
    @media (min-width: 346px) and (max-width: 537px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: auto;
      align-items: flex-end;
    }
  @media only screen and (max-width: 346px) {
  margin-top: 12px;
  }
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: auto;
  }
  .text {
    @media only screen and (min-width: 537px) {
      font-size: 15px;
      color: #9e9999;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 250px;
      border-radius: 5px;
    }
    width: auto;
    font-size: 15px;
    color: #9e9999;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
  }
  .leaveDays {
    display: flex;
    flex-direction: column;
    .leaveDaysText {
      font-size: 15px;
      color: #9e9999;
    }
  }
`;

const ReasonDiv = styled.div`
  margin-top: 20px;
  .textArea {
    height: 190px;
    border-radius: 6px;
    .reason {
      margin-left: 30px;
      font-size: 18px;
      font-weight: 500;
    }
    textarea.ant-input {
      border: 0px;
      max-width: 99% !important;
      outline: none;
      cursor: default;
      margin-left: 18px;
      margin-top: 4px;
      color: #757171;
      background-color: transparent !important;
     
    }
  }
`;

const CloseIcon = styled.div`
  right: -12px;
  background: ${props => props.color?.bodyColor ? props.color?.bodyColor : 'blue'} !important;
  border-radius:32px;
  top: 13px;
  right: 30px;
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  background: #286ACE;
  color: #fff;
  &:hover {
    color: #fff;
    border-radius: 32px;
    box-shadow: 0px 0px 10px rgba(0, 0.1, 0, 0.5);
  } `

const SubCloseIconDiv = styled.div`
  height:17px;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  transition-delay: 0.2;
  ${CloseIcon}:hover & {
    transform: rotate(180deg)
  };`
