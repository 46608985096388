import styled from 'styled-components'
import { disabledColorEffect } from './themeColor'
import { colors } from 'constant/colors'

const { WhiteSmoke, GhostWhite, mediumGray } = colors

export const Wrapper = styled.div`
*{
.ant-input-affix-wrapper:hover{
	border: 1px solid ${props => props.color?.bodyColor};
}

.ant-dropdown-menu{
  .ant-dropdown-menu-item{
	background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'};
	color: ${props => props?.color?.theme == 'dark' ? 'white' : '#141414'};
  }
}
.ant-input-affix-wrapper:focus{
	border: 1px solid ${props => props.color?.bodyColor};
}

.ant-layout-sider-children{	
	background-color: ${props => props?.color?.theme != 'dark' && props.color.sidebarColor ? props.color.sidebarColor : props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
	overflow-x: hidden !important;
}
.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item>a, .ant-menu-dark .ant-menu-item>span>a{
	color:#5F5E5E
}
.ant-menu .ant-menu-item{
	margin: 0 10px !important;
	padding-left: 0px !important;
    border-radius: 10px !important;
	.ant-menu-item-icon{
		padding-left: 12px !important;
	}
}

.customCol p {
	font-size: 12px;
	margin-bottom: 0 !important;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
	filter: ${props => props?.color?.theme == 'dark' ? 'invert(0)' : props.color?.theme == 'light' ? 'invert(0)' : 'invert(1)'} !important;
}
.ant-input {
    font-size: 14px !important;
    &:hover {
        border-color: ${props => props.color?.bodyColor} !important;
    }
}
.ant-picker {
	&:hover {
        border: 1px solid ${props => props.color?.bodyColor} !important;
    }
}
.breadcrumb-item.active a{
	
	color:  ${props => props.color?.bodyColor} !important;
}
.style_commonButton__cv8nD .ant-btn{
	background-color: ${props => props.color?.bodyColor} !important;
	color:${props => props.textColor ? 'white' : 'black'} !important;
	border:3px solid ${props => props.color?.bodyColor} !important;
	box-shadow: rgb(0 0 0 / 32%) 0px 2px 4px !important;
	height: 40px;

	span{
		font-size: 14px;
	}
	 
	&:hover{
	color:${props => props.textColor ? 'white' : 'black'} !important;
	}
}

.versionDiv{
	background :  ${props => props.color?.bodyColor} !important;
}
.ant-btn-primary[disabled], .ant-btn-primary[disabled]:active, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:hover .ant-btn[disabled], .ant-btn[disabled]:active, .ant-btn[disabled]:focus, .ant-btn[disabled]{
	background-color: ${props => disabledColorEffect(props.color?.bodyColor, 0.7)} !important;
	border:3px solid ${props => disabledColorEffect(props.color?.bodyColor, 0.7)} !important;
}
.employee-page, .attendance-page{
	.advanceBtn{
		svg {
			height:17px;
			margin:0 10px;
			path{
			color: ${props => props.color?.bodyColor} !important;
		}}
		.ant-btn{
			background-color: ${props => props.color?.bodyColor};
			span{
				color: ${props => props.textColor ? 'white' : 'black'} !important;
			}
			border:none;
		}
	}
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => props.color?.bodyColor} !important;
}
.ant-input-affix-wrapper:active{
border-color: ${props => props.color?.bodyColor} !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
	border-color: ${props => props.color?.bodyColor} !important;
}
.ant-select .ant-select-selector:hover{
	border-color: ${props => props.color?.bodyColor} !important;
}


.main-menu,
.sub-menu .ant-menu-inline.ant-menu-sub {	
	background-color: ${props => props?.color?.theme != 'dark' && props.color.sidebarColor ? props.color.sidebarColor : props?.color?.theme == 'dark' ? '#141414' : 'white'} ;
	svg{
		path{
			fill: ${props => props?.color?.theme != 'dark' && props.color.sidebarColor ? props.textColor ? 'white' : 'black' : props?.color?.theme == 'dark' ? 'white' : 'black'} !important

			}
	}
	a{
		color: ${props => props?.color?.theme != 'dark' && props.color.sidebarColor ? props.textColor ? 'white' : 'black' : props?.color?.theme == 'dark' ? 'white' : 'black'} !important
	}
	.ant-menu-title-content{
		color: ${props => props?.color?.theme != 'dark' && props.color.sidebarColor ? props.textColor ? 'white' : 'black' : props?.color?.theme == 'dark' ? 'white' : 'black'} !important

	}
}

.ant-menu.ant-menu-dark .ant-menu-item-selected{
	background-color: ${props => props?.color?.theme != 'dark' && props.color.sidebarColor ? props.textColor ? 'white' : 'black' : props.color?.bodyColor} !important;
	border-radius: 10px !important;
	width:auto !important;
	span>a{
		color: ${props => props?.color?.theme != 'dark' && props.color.sidebarColor ? props.textColor ? 'black' : 'white' : 'white'} !important
	}
	svg{
		path{
			fill: ${props => props?.color?.theme != 'dark' && props.color.sidebarColor ? props.textColor ? 'black' : 'white' : 'white'} !important
		}
	}
    .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
		filter:${props => !props.textColor ? 'invert(1)' : props.color.theme == 'dark' ? 'invert(1)' : 'invert(0)'} ;
	}
}


.ant-pagination .ant-pagination-prev .ant-pagination-item-link{
	background-color: ${props => props.color?.bodyColor} !important;
	border: 1px solid  ${props => props.color?.bodyColor} !important;
}
.ant-modal-header{
	background-color: ${props => props.color?.bodyColor} !important;
	color:${props => props.textColor ? 'white' : 'black'} !important;
}
.ant-pagination-item-active{
	border: 2px solid  ${props => props.color?.bodyColor} !important;
	a{
		color:black !important;
	}
}
.ant-pagination-item{
	border: 1px solid  ${props => props.color?.bodyColor};
	color: black !important
}
.ant-pagination .ant-pagination-next .ant-pagination-item-link{
	background-color: ${props => props.color?.bodyColor} !important;
	border: 1px solid  ${props => props.color?.bodyColor} !important;
}
.switchButton {
    .ant-btn {
        height: 45px;
        width: 170px;
        border: 1px solid #e3e3e3;
        border-radius: 31px;
        z-index: 2;
		position: relative;
		background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'};
		span{
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
		}
		
        :hover {
            color: black !important;
        }
        &.active {
            font-family: "Poppins";
            background:  ${props => props.color?.bodyColor} !important;
            color:${props => props.textColor ? 'white' : 'black'} !important;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            z-index: 3;
            :hover {
                color:${props => props.textColor ? 'white' : 'black'} !important;
            }
        }

        &:nth-child(2) {
            margin-left: -30px;
        }
    }
} */
.advanceBtn, commonCancel{
	.ant-btn, span{
		color: '#fff' !important;
	}
}
::-webkit-scrollbar {
	width: 4px;
	height: 5px;
	border-radius: 20px;
  }
  
  ::-webkit-scrollbar-track-piece {
	background: ${WhiteSmoke};
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 0px;
  }
  
  ::-webkit-scrollbar-thumb {
	background: ${props => props.color?.bodyColor};
	border-radius: 20px;
	width: 10px;
  }
  .oms-header{
	background: ${props => props?.color?.theme != 'dark' && props.color?.headerColor?.length ? props.color?.headerColor : props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
  }
}
height:100%;
.page-wrapper {
	left: 0;
	padding-top: 60px;
	position: relative;
	transition: all 0.2s ease-in-out;
	min-height: calc(100vh - 60px);
}

.page-wrapper>.content {
	padding: 18px;
}

.page-header  {
	*{
		color: ${props => props.color?.theme == 'dark' ? 'white' : ''}
	}
	margin-bottom: 1.875rem;
}

.page-header .breadcrumb {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: 1px;
	color: #525353;
}
@media only screen and (max-width: 575.98px) {

.page-header .breadcrumb {
	display:none !important
}
.page-title{
	font-size:20px;
	
}
.page-header{
	div{
		align-items:center !important;
	}
}
}
.page-header .breadcrumb a {
	color: ${props => props?.color?.theme == 'dark' ? 'rgb(225 221 221)' : '#333'} !important;
	&:hover{
	color: ${props => props?.color?.theme == 'dark' ? 'white' : 'black'} !important;
	}
}

.breadcrumb{
	a{
		&:hover{
			background: ${props => props?.color?.theme == 'dark' ? '#383838' : '#ececec'} !important;
		}
	}
}

.ant-layout-sider-children {
	position: fixed;
	width: inherit;

	::-webkit-scrollbar-track-piece {
		background: none !important;
		box-shadow: none !important;
		border-radius: 0px;
	  }
	  ::-webkit-scrollbar-thumb {
		background: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'};
		border-radius: 20px;
		width: 10px;
	  }
	&:hover{
		  ::-webkit-scrollbar-thumb {
			background: ${props => props.color?.bodyColor};
			border-radius: 20px;
			width: 10px;
		  }
	}
	&:hover{
		::-webkit-scrollbar-track-piece {
			background: ${WhiteSmoke} !important;
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
			border-radius: 0px;
		  }
	}
  }
}

.notifications {
	padding: 0;
}

.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}

.notifications .notification-heading {
	margin: 0;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.45;
	color: #616161;
}

.notifications .notification-time {
	font-size: 12px;
	line-height: 1.35;
	color: #bdbdbd;
}

.notification-list {
	list-style: none;
	padding: 10px;
	margin: 0;
}

.notifications ul.notification-list>li {
	margin-top: 0;
	border-bottom: 1px solid ${WhiteSmoke};
}

.notifications ul.notification-list>li:last-child {
	border-bottom: none;
}

.notifications ul.notification-list>li a {
	display: block;
	padding: 12px;
	border-radius: 2px;
}

.notifications ul.notification-list>li a:hover {
	background-color: ${GhostWhite};
}

.notifications ul.notification-list>li .list-item {
	border: 0;
	padding: 0;
	position: relative;
}

.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid ${WhiteSmoke};
}

.notifications .media:last-child {
	border-bottom: none;
}

.notifications .media a {
	display: block;
	padding: 12px;
	border-radius: 2px;
}

.notifications .media a:hover {
	background-color: ${GhostWhite};
}

.notifications .media-list .media-left {
	padding-right: 8px;
}

.ant-notification {
	z-index: 1053;
	&:first-child{
		top:62px !important;
	}
}

.ant-notification-notice {
	position: relative;
	width: 400px;
	max-width: calc(100vw - 24px * 2);
	margin-bottom: 16px;
	margin-left: auto;
	padding: 16px 24px;
	overflow: hidden;
	line-height: 2.5715;
	word-wrap: break-word;
	border-radius: 10px;
	top: 2px;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

	&.ant-notification-notice-success {
		background: #FFFFFF;
		border-radius: 9px 9px 0px 0px;
		border-radius: 12px;
		height: 70px;

	}

	&.ant-notification-notice-error {
		background: #FFFFFF;
		border-radius: 12px;
		height: 65px;
		margin-left: 72px;
		line-height: 2;

	}

	&.ant-notification-notice-info {
		background: #FEF7EA;
		border-radius: 9px 9px 0px 0px;
		border-radius: 12px;
	}

	&.ant-notification-notice-warning {
		background: #FFEAE7;
		border-radius: 9px 9px 0px 0px;
		border-bottom: 4px solid #E46464;
		border-radius: 4px;
	}
}
.ant-notification-notice-with-icon {
	.ant-notification-notice-description {
		font-size: 14px;
		line-height: 2;
		padding-left: 24px;
		overflow: hidden;
		margin-left: 59px;
		text-overflow: ellipsis;
		white-space: nowrap;

	}
}


// dark theme Start

.ant-table-thead>tr>th{
	background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
	color:${props => props?.color?.theme == 'dark' ? 'white' : '#424242 '} !important;
	border: ${props => props?.color?.theme == 'dark' ? 'none' : ''} ;

	div {
		color:${props => props?.color?.theme == 'dark' ? 'white' : '#424242 '} !important;
	}
}
.ant-table-tbody>tr.ant-table-row:hover>td,.ant-table-tbody>tr>td.ant-table-cell-row-hover{
	background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : ''} !important;
		color:${props => props?.color?.theme == 'dark' ? 'white' : '#424242 '} !important;
		 
}
.ant-table-tbody>tr>td {
	border: ${props => props?.color?.theme == 'dark' ? 'none' : ''} !important;
}
.ant-table .ant-table-tbody tr, tbody tr.approved-row td p h6 {
	background-color: ${props => props?.color?.theme == 'dark' ? '#050505' : 'white'} !important;
		color:${props => props?.color?.theme == 'dark' ? 'white' : '#424242 '} !important;
}
.ant-table-cell-fix-left, .ant-table-cell-fix-right{
	background-color: ${props => props?.color?.theme == 'dark' ? '#050505' : 'white'} !important;
		color:${props => props?.color?.theme == 'dark' ? 'white' : '#424242 '} !important;
		
}


.ListHeadings{
	background-color: ${props => props?.color?.theme == 'dark' ? '#403c3c' : ''} !important;
}
.TaskProjects{
	background-color: ${props => props?.color?.theme == 'dark' ? '#403c3c' : ''} !important;
}
.labelProjects{
	color:${props => props?.color?.theme == 'dark' ? 'black' : 'black'} !important;
}
.tasIdProjects{
	color:${props => props?.color?.theme == 'dark' ? '#b5afaf' : ''} !important;
}

.ant-layout{	
	color:${props => props?.color?.theme == 'dark' ? 'white' : 'black'} !important;
	background-color: ${props => props?.color?.theme == 'dark' ? '#0d0d0d' : 'white'};
	*{
		.ant-card{
			*:not(.ant-rate):not(.ant-rate-star-first):not(.ant-rate-star-second) {
				color:${props => props?.color?.theme == 'dark' ? 'white' : 'black'} ;
			}
			background-color: ${props => props?.color?.theme == 'dark' ? 'black' : 'white'} !important;
		}
		.ant-input-affix-wrapper, .ant-picker{
			background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
			svg{
				margin-right:10px;
				path{
					fill : ${props => props?.color?.theme == 'dark' ? 'white' : 'rgb(217, 217, 217)'}
				}
			}
			border: ${props => props?.color?.theme == 'dark' ? '1px solid rgb(52 50 50)' : '1px solid rgb(217, 217, 217)'};
			.ant-input{
				background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
				color: ${props => props?.color?.theme == 'dark' ? 'white' : 'black'} !important;
			}
			
		}
		.ant-input-affix-wrapper:hover{
			border-color: ${props => props.color?.bodyColor} !important;
	}
		.ant-select-selector, .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
			color: ${props => props?.color?.theme == 'dark' ? 'white' : '#424242'} !important;
			border: 1px solid ${props => props.color?.theme == 'dark' ? '#d7cece' : mediumGray};
			
		}
		.sc-beqWaB .ant-input{
			background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
			color: ${props => props?.color?.theme == 'dark' ? 'white' : '#424242'} !important;
			
			svg{
				margin-right:10px;
				path{
					fill : ${props => props?.color?.theme == 'dark' ? 'white' : 'rgb(217, 217, 217)'}
				}
			}

		}
		
		.ant-input {&::placeholder{
		color: ${props => props?.color?.theme == 'dark' ? mediumGray : 'rgb(217, 217, 217)'} !important;
		}}

		.sc-beqWaB.ikYSyD{
			border: 1px solid ${props => props.color?.theme == 'dark' ? '#d7cece' : mediumGray};
			border-radius: 8px;

		}
		.ant-picker {
			border: 1px solid ${props => props.color?.theme == 'dark' ? '#d7cece' : mediumGray};
			border-radius: 8px;
			
		}
		#today ,#date, #specific_date{	&::placeholder{
		color: ${props => props?.color?.theme == 'dark' ? mediumGray : 'rgb(217, 217, 217)'} !important;
		}}
		.ant-select-selection-placeholder{
		color: ${props => props?.color?.theme == 'dark' ? mediumGray : 'rgb(217, 217, 217)'} !important;
		}
	
		.ant-select-dropdown{
			background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
			border : 1px solid ${props => props?.color?.theme == 'dark' ? 'lightgray' : 'white'} !important;
		}
		.ant-select-item:hover{
			background-color: ${props => props?.color?.theme == 'dark' ? '#333333' : 'white'} !important;
		}
		.ant-select-item-option-active, .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
			background-color: ${props => props?.color?.theme == 'dark' ? '#333333' : 'white'} !important;
		}
		.ant-select-item-option{
			color: ${props => props?.color?.theme == 'dark' ? 'white' : '#424242'} !important;
		}
		.ant-table-tbody{
					color:${props => props?.color?.theme == 'dark' ? 'white' : 'black'} !important;
		}
	}
	.ant-drawer-content{
		background: ${props => props?.color?.theme == 'dark' ? '#201f1f' : 'white'} !important; 
		.ant-card{
			background-color: ${props => props?.color?.theme == 'dark' ? '#201f1f' : 'white'} !important;	
		}
	}
	.ant-pagination{
		background: ${props => props?.color?.theme == 'dark' ? '#201f1f' : 'white'} !important; 
	}
	.ant-switch{
		background-color: rgb(82, 83, 83);
	}

	.ant-table-cell:last-child {
		.work-report-icon{
			svg{
				color: red !important;
			}
		}
		svg{
		color: ${props => props?.color?.theme == 'dark' ? '#fff' : 'rgb(110, 110, 110)'} ;
			
		}
	}
    .ant-table-cell {
		&:first-child:not(.ant-avatar-string) {
		  * {
			color: ${props => props?.color?.theme == 'dark' ? 'white' : ''};
		  }
		}
	  }
	.ant-table-wrapper{
		background-color: ${props => props?.color?.theme == 'dark' ? '' : 'white'} !important}
	}
	.attendanceFilter{
		background-color: ${props => props.color?.theme == 'dark' ? '#282828' : 'white'} !important;
		p{
			background-color: ${props => props.color?.theme == 'dark' ? '#0d0d0d' : 'white'} ;
		}
	}
	.anticon-menu svg{
		color: ${props => props?.color?.theme == 'dark' ? 'white' : props?.textColor ? '#141414' : '#141414'} !important;
	}
	.roleModuleAccess{
		background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'} !important
	}
	.ant-card-bordered{
		border: ${props => props?.color?.theme == 'dark' ? 'none' : '1px solid lightgray'}
	}
	.module{
		color : ${props => props?.color?.theme == 'dark' ? 'white' : '#333333'}
	}
	.list-group-item{
		background-color: ${props => props?.color?.theme == 'dark' ? '#141414' : 'white'} !important;
		color : ${props => props?.color?.theme == 'dark' ? 'white' : '#333333'} !important;
p{
	color : ${props => props?.color?.theme == 'dark' ? 'white' : '#333333'} !important

}	
	}
	
.loginRightSide{
	background-color: ${props => props.color?.bodyColor} !important
}
.ant-pagination-total-text{
	color : ${props => props?.color?.theme == 'dark' ? 'white' : '#333333'} !important
}
.ant-table-container, .ant-table-container table>thead>tr:first-child th:first-child {
	border-radius: 0px !important
}
.loaderColor{
  color: ${props => props?.color?.bodyColor};
}
.loaderColor::before {
    background-color: ${props => props?.color?.bodyColor};
  }

  .ant-picker-input > input{
	color : ${props => props?.color?.theme == 'dark' ? 'white' : '#333333'} !important
  }


.avatar-dark-theme{
	.ant-avatar{
		.ant-avatar-string{
			color: ${props => props?.color?.theme == 'dark' && 'black'} !important;
		}
	}
}
.duration-text{
	color:  ${props => props?.color?.theme == 'dark' ? 'gray' : '#333333'} !important;
	background-color:  ${props => props?.color?.theme == 'dark' ? 'rgb(35 31 31)' : '#DDDDDD'} !important;
}
.cancelled-text{
	color: red !important;
	background-color: rgb(255, 220, 217) !important;
}
.Leave-count-button:hover{
	background-color:  ${props => props?.color?.theme == 'dark' && 'gray'} !important;
	color: inherit !important;
}
.Leave-count-button{
	background-color:  ${props => props?.color?.theme == 'dark' && 'black'};}

.antCollapse{
	.ant-collapse{
		background-color:  ${props => props?.color?.theme == 'dark' && '#0d0d0d'};
		.ant-collapse-header-text{
		background-color:  ${props => props?.color?.theme == 'dark' && '#0d0d0d'};
		}
		.ant-collapse-content-box{
		background-color:  ${props => props?.color?.theme == 'dark' && '#0d0d0d'};
		}
	}
}

.antInput{
	.ant-input{
		background-color:  ${props => props?.color?.theme == 'dark' && '#0d0d0d'}!important;
	}
}

.antDatePicker{
.ant-picker{
	background-color:  ${props => props?.color?.theme == 'dark' && '#ffffff'}!important;
}
}

.antSelect{
	.ant-select-selector{
	background-color:  ${props => props?.color?.theme == 'dark' && '#ffffff'}!important;
	}
}

	.breadcrumb .breadcrumb-item .sc-fsQiph{
		color:${props => props.color?.theme == 'dark' ? '#d7cece' : '#000'} !important;
	}	



	.sc-qRumB{

		color: ${props => props.color?.theme == 'dark' ? 'white' : 'black'}
		}
		.ant-picker-cell .ant-picker-cell-inner {
		color: ${props => props.color?.bodyColor}

		}
		.ant-radio-wrapper span{
			color:${props => props.color?.theme == 'dark' ? 'white' : '#000'} !important;

		}
		.sc-ALVnD{
			border : 1px solid ${props => props?.color?.theme == 'dark' ? 'lightgray' : 'white'} !important;
			span{
			color:${props => props.color?.theme == 'dark' ? 'white' : '#000'} !important;
			}

		}
		
		.hobyjn, .NehQP{
			background-color:  ${props => props?.color?.theme == 'dark' ? '#000' : 'white'} !important;
			border : 1px solid ${props => props?.color?.theme == 'dark' ? 'white' : props => props?.color?.bodyColor} !important;
			color:${props => props.color?.theme == 'dark' ? 'white' : '#000'} !important;
			&:hover{
			background-color:  ${props => props?.color?.theme == 'dark' ? '#000' : 'white'} !important;
			color:${props => props.color?.theme == 'dark' ? 'white' : '#000'} !important;

			}
			

		}

	.applierName{
		color:  ${props => props?.color?.theme == 'light' ? 'black' : ''} !important;
	}
	.noData{
		padding: 5px;
    background-color: lightgrey;
    border-radius: 5px;
	}

  .customTabs {
    .ant-card {
      margin-bottom: 20px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 0 !important;
      overflow-x: auto;
      overflow-y: hidden;
      .ant-card-body {
        padding: 0 !important;
      }
      .ant-card-head {
        padding: 0 !important;

        .ant-tabs-nav {
          .ant-tabs-tab {
            padding: 14px 24px;
            border-right: 1px solid #e1e1e1;
			
            & + .ant-tabs-tab {
              margin: 0;
            }
          }

          .ant-tabs-ink-bar-animated {
            display: none !important;
          }
        }
      }
    }
  }

  .ant-tabs-tab-active {
	background-color: ${props => props.color?.theme == 'dark' ? '#2a2a2a' : '#dfeaf9'} !important;
	border-right: 1px solid #e1e1e1;
	color: red;
  }

  .accordingToTheme{
	svg{
		color: ${props => props?.color?.bodyColor} !important;
	}
	div{
		&:hover{
			background-color: ${props => props?.color?.theme == 'dark' && 'black'} !important;
		}
	}
  }

`