import CustomButton from '@commonComponents/button'
import { CommonInput } from '@commonComponents/input'
import { Col, Form, Row, Switch, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { CommonSelect } from '@commonComponents/select'
import countryList from 'react-select-country-list'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import CustomDrawer from '@commonComponents/drawer'
import styled from 'styled-components'


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const BranchForm = observer((props: any) => {
  const [form] = Form.useForm()
  const options = countryList().getData()
  const [country, setCountry] = useState('')
  const [updatedData, setUpdatedData] = useState({})
  const [visible, setVisible] = useState(false)
  const changeHandler = country => {
    setCountry(country)
  }
  const {
    branchModel: { setBranchData, updateBranch, loadBranch, getBranchData },
    employmentCompany: { getEmploymentCompanyData, loadEmploymentCompany },
  } = useStore(null)

  useEffect(() => {
    loadEmploymentCompany(1, 10, '', true)
    loadBranch(1, 10, '', true)

    form.resetFields()
    if (props.update) {
      form.setFieldsValue({
        title: props?.data?.title,
        email: props?.data?.email,
        contact_number: props?.data?.contactNumber,
        company_id: props?.data?.company,
        parent_branch: props?.data?.parentBranch,
        radius: props?.data?.radius,
        address: props?.data?.address,
        country: props?.data?.country,
        city: props?.data?.city,
        latitude: props?.data?.latitude,
        longitude: props?.data?.longitude,
        zip: props?.data?.zip,
        is_main: props?.data?.isMain,
      })
    }
  }, [props.data, props.update])
  const updateTask = async changeValue => {
    const key = Object.keys(changeValue)[0]
    updatedData[key] = changeValue[Object.keys(changeValue)[0]]
    setUpdatedData(updatedData)
  }

  const submitData = async values => {
    let response = null
    if (props.update) {
      response = await updateBranch(props?.data?.id, updatedData)

    } else {
      response = await setBranchData({
        title: values.title,
        company: values.company_id,
        parent_branch: values.parentBranch,
        latitude: values.latitude,
        longitude: values.longitude,
        radius: values.radius,
        email: values.email,
        contact_number:`+${ values.contact_number}`,
        address: values.address,
        country: values.country,
        city: values.city,
        zip: values.zip,
        is_main: values.is_main
      })
    }


    if (response?.success) {
      await props.setRefreshTable(true)
      form.resetFields()
      setUpdatedData({})
      props.setVisible(false)
    }
  }
  const children = options?.map(({ label }) => ({ key: label, value: label }));

  const companyData = getEmploymentCompanyData?.map(e => ({ key: e.id, value: e.title }))
  const branchData = getBranchData?.map(e => ({ key: e.id, value: e.title }))

  return (
    <div>
      <StyledForm
        form={form}
        layout='vertical'
        onValuesChange={updateTask}
        onFinish={submitData}
        initialValues={{ is_main: false }}
        id='branch-form'
      >
        <div className='form-auto-height'>
          <Row>
            <Col span={11}>
              <Form.Item
                name='title'
                label='Name'
                rules={[{ required: true, message: 'Branch name is required' }]}
              >
                <CommonInput className='inputField' placeholder='Please Enter Branch Name' inputType='text' />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <Form.Item
                name='email'
                label='Email'
                rules={[
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'Email is not Valid' }
                ]}
              >
                <CommonInput className='inputField'
                  inputType='email'
                  placeholder='Please Enter Email'
                  onFocus={() => form.setFields([{ name: 'email', errors: [] }])}
                ></CommonInput>
              </Form.Item>
            </Col>
            <Col span={3}></Col>
          </Row>

          <Row>
            <Col span={11}>
              <Form.Item  
                name='contact_number'
                label='Phone'
                rules={[{ required: true, message: 'Contact number is required' },
                {
                  max: 16,
                  message: 'Contact number must be less than 16 characters.',
                },
                {
                  min: 7,
                  message: 'Enter Complete contact number.',
                },
                ]}
              >
                <PhoneInput
                  country={'pk'}
                  value={country}
                  onChange={changeHandler}
                />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <Form.Item
                name={['country']}
                label='Country'
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <CommonSelect
                  listHeight={
                    125}
                  className=''
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Please Select Country'
                  defaultValue={props?.update?props.country:null}
                  data={children}
                  showSearch
                  filterOption={(input, option) =>
                    option?.children
                      ?.toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item
                name='parentBranch'
                label='Parent Branch(optional)'
                rules={[{ required: false, message: 'Parent Branch is required' }]}
              >
                <CommonSelect
                  listHeight={
                    125}
                  className=''
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Please Select a parent Branch'
                  defaultValue={props.update ? props?.data?.parentBranch : null}
                  data={branchData}
                  showSearch
                  filterOption={(input, option) =>
                    option?.children
                      ?.toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>


            <Col span={2} />

            <Col span={11}>
              <Form.Item
                name='company_id'
                label='Company'
                rules={[{ required: true, message: 'Company is required' }]}
              >
                <CommonSelect
                  listHeight={
                    125}
                  className=''
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Please Select a Company'
                  defaultValue={props.update ?props?.data?.company:null }
                  data={companyData}
                  showSearch
                  filterOption={(input, option) =>
                    option?.children
                      ?.toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>

          </Row>

          <Row>
            <Col span={11}>
              <Form.Item name='city' label='City' rules={[{ required: true, message: ' City is required' }]}>
                <CommonInput className='inputField' placeholder='Please Enter City Name' inputType='text' />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <Form.Item
                name='radius'
                label='Radius'
                rules={[{ required: true, message: 'Radius is required' }]}
              >
                <CommonInput className='inputField' placeholder='Please Enter Radius' inputType='number' />
              </Form.Item>
            </Col>

          </Row>
          <Row>
            <Col span={11}>
              <Form.Item
                name='latitude'
                label='latitude'
                rules={[{ required: true, message: 'Latitude is required' }]}
              >
                <CommonInput className='inputField'  placeholder='Please Enter Latitude' inputType='text' />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={6}>
              <Form.Item
                name='longitude'
                label='longitude'
                rules={[{ required: true, message: 'Longitude is required' }]}
              >
                <CommonInput className='inputField' placeholder='Please Enter Longitude' inputType='text' />
              </Form.Item>
            </Col>
            <Col span={2} />
            <ColStyle span={3}>
              <Form.Item label=' Map' >
                <CustomButton
                  className={'Addbtn'}
                  onClick={() => setVisible(true)}
                  title='Show Map'
                />
              </Form.Item>
            </ColStyle>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item
                name='zip'
                label='ZIP Code'
                rules={[{ required: true, message: 'Zip code is required' },
                ]}
              >
                <CommonInput className='inputField' placeholder='Please Enter Zip Code' inputType='text' />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={4}>
              <Tooltip title='You can only select one branch as main branch. The other branch will be deselected.'>
                <Form.Item
                  name={['is_main']}
                  label='Main Branch'
                  rules={[{ required: true, message: 'Main branch is required' }]}
                  valuePropName='checked'
                >
                  <Switch
                    checked={true}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                name='address'
                label='Address'
                rules={[{ required: true, message: 'Address is required' }]}
              >
                <CommonInput className='inputField' placeholder='Please Enter Address' inputType='textarea' />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </StyledForm>
      <CustomDrawer
        width={600}
        className='addDrawer'
        title={'Google Map'}
        visible={visible}
        setVisible={() => {
          setVisible(false)
        }}
      >
      </CustomDrawer>
    </div>
  )
})

export default BranchForm

const ColStyle = styled(Col)`

  button {
    color: black !important;
  }

.style_Addbtn__jbz12{
  color: white;
  background:blue;
}
.ant-btn:hover{
  color: white;
}
`
const StyledForm = styled(Form)`
    .Addbtn {
      margin-left: -20px;
    }
`