import React from 'react'
import { Col, Form, Radio, Row } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { SearchOutlined } from '@ant-design/icons';
import SearchFilter from '@commonComponents/SearchFilter'
import { StyledFilterRadioWrapper } from '@mainPage/categories/SearchCategory';

interface DesignationProps{
  setQueryParam: (data: object) => void;
}

const Searchdesignation = observer((props: DesignationProps) =>
{
  return (
    <div className=''>
      <SearchFilter
        {...props}
        children={
          <div className='d-lg-flex w-100'>
            <Row gutter={4} className='rowDiv searchForm flex-grow-1 gap-md-2 gap-lg-3'>
              <Col sm={11} xs={24} md={12} lg={6} >
                <div>
                  <Form.Item
                    name='designation_name'
                  >
                    <CommonInput
                      placeholder='Search Designation'
                      testId='designation_inp_search'
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col sm={13} xs={24} md={11} lg={10} className={`d-flex align-items-center flex-wrap`}>
                <StyledFilterRadioWrapper>
                  <Form.Item name='is_active'>
                    <Radio.Group name='is_active' >
                      <Radio value={'true'}>Active</Radio>
                      <Radio value={'false'}>Inactive</Radio>
                    </Radio.Group>
                  </Form.Item>
                </StyledFilterRadioWrapper>
              </Col>
            </Row>
          </div>
        } />
    </div>
  )
})
export default Searchdesignation