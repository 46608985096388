export const IGNORE_PROPERTIES = [
  'id',
]

export const ASSET_LIST = [
  {
    key: 'assets',
    tab: 'Assets',
  }
]
export const APPROVAL_LIST = [

  {
    key: 'assetsForApproval',
    tab: 'Asset For Approval'
  }
]
export const ASSET_APPROVAL_MOD = 'asset_approval'
export const ASSET_READ = 'read'
export const ASSET_UPDATE = 'edit'
export const ASSET_ATTRIBUTE_DELETE = 'Delete Asset Attribute'

export const conditionAsset=[   
  { key: 'new', value: 'New' },
  { key: 'branded', value: 'Branded' },
  { key: 'used', value: 'Used' },   
  { key: 'under_repair', value: 'Under Repair' },
  { key: 'damaged', value: 'Damaged' },   
  { key: 'working', value: 'Working' }, 
  { key: 'not_working', value: 'Not Working' }, 
  { key: 'assigned', value: 'Assigned' }, 
  { key: 'un_assigned', value: 'UnAssigned' },   
  { key: 'dead', value: 'Dead' }
]