import { SearchOutlined } from '@ant-design/icons'
import { Form, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { CommonSelect } from '@commonComponents/select'
import SearchFilter from '@commonComponents/SearchFilter'
import { remoteleaveFilterChildrens } from '@mainPage/EmployeeLeave/utils'
import { EMPLOYEE_NAME, FILTER_STATUS } from '@mainPage/EmployeeLeave/const'
import { leaveHistoryChildren } from '@mainPage/EmployeeLeave/utils'

interface LeaveFormProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setQueryParam: any;
  setResetFilters: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRefreshTable: any;
  queryParam: any;
  resetFilters: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  activeTab: any;
  setResetTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckedData?: any;
}

export const EmployeeRemoteLeaveFilter = observer((props: LeaveFormProps) => {
 
  return (
    <SearchFilter
      {...props}
      setResetFilters={props.setResetFilters}
      resetFilters={props.resetFilters}
      children={
        <div className='d-lg-flex w-100'>
          <Row gutter={4} className='rowDiv flex-grow-1'>
            <Col xs={24} md={24} lg={6}>
              <div>
                <Form.Item name={EMPLOYEE_NAME}>
                  <CommonInput
                    placeholder='Search by Employee'
                    inputType='text'
                    className='searchInput'
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} md={24} lg={6}>
              <Form.Item name={FILTER_STATUS}>
                {
                props.activeTab !== 'RemoteCancelApproval' ?
                 (
                  <CommonSelect
                    listHeight={120}
                    placeholder='Select Leave Status'
                    size='large'
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children &&
                      option.children
                        ?.toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    data={
                      props.activeTab == 'RemoteApproval' 
                    ? 
                      remoteleaveFilterChildrens
                    : 
                    leaveHistoryChildren.filter(item =>
                      props.activeTab === 'RemoteHistory'
                      ? item.key.toLowerCase() !== 'pending'
                      : true 
                  )}
                  />
                ) : null}
              </Form.Item>
            </Col>
          </Row>
        </div>
      }
    />
  )
})
export default EmployeeRemoteLeaveFilter
