import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Col, Form, Row } from 'antd'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import PhoneInput from 'react-phone-input-2'
import CustomButton from '@commonComponents/button'
import { isCurrentUsersId } from '@utils/common-functions'
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader'
import { MdAddCircleOutline, MdClose } from 'react-icons/md'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
import { colors } from 'constant/colors'

const { TealishBlue, black, lightGray } = colors

interface FamilyData {
  name: string;
  relation: string;
  phone_number1: string;
  phone_number2?: string;
}

interface FamilyInfo {
  id: string;
  name: string;
  relation: string;
  phoneNumber1: string;
  phoneNumber2?: string;
}

interface Family {
  empId?: string;
  closeDrawer: () => void;
}

interface FamilyForm {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: any;
  data?: FamilyInfo;
  formId?: string;
  method: string;
  closeDrawer: () => void;
  empId: string;
}

const Family = observer(({ empId, closeDrawer }: Family) => {
  const [showForm, setShowForm] = useState(false)
  const getRef = useRef(null)
  const canModify = isCurrentUsersId(empId)

  const {
    familyDetails: {
      updateFamilyDetails,
      familyData,
      loadFamilyDetails,
      setFamilyDetails,
      isLoading,
      deleteFamilyDetails,
    },
    themesModel: { getColorsData },
  } = useStore(null)
  const isDarkTheme = getColorsData?.theme === 'dark'

  const load = async () => {
    await loadFamilyDetails(empId)
  }

  useEffect(() => {
    if (!familyData?.length) {
      load()
    }
  }, [empId])

  const addForm = () => {
    setShowForm(true)
    setTimeout(() => {
      getRef?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      })
    }, 500)
  }

  useEffect(() => {
    if (!familyData?.length && !showForm) {
      setShowForm(true)
    }
  }, [familyData, showForm])

  const onUpdate = async (data: FamilyData, id: string) => {
    const res = await updateFamilyDetails(id, data)
    if (res?.success) {
      load()
    }
  }

  const onCreate = async (data: FamilyData) => {
    data['employee_id'] = empId
    const res = await setFamilyDetails(data)
    if (res?.success) {
      setShowForm(false)
      load()
    }
  }

  const deleteEducation = async (id: string) => {
    const res = await deleteFamilyDetails(id)
    if (res?.success) {
      load()
    }
  }

  if (!familyData?.length && isLoading) {
    return <SkeletonRingLoader width='100%' height='50px' align='center' />
  }

  return (
    <Wrapper ref={getRef} isDarkTheme={isDarkTheme}>
      <div className={'addMore'}>
        <AddButton onClick={addForm} isDarkTheme={isDarkTheme} disabled={!canModify}>
          <MdAddCircleOutline
            fill={getColorsData?.bodyColor || 'blue'}
            className='add-icon'
          />
        </AddButton>
      </div>

      {/* Mapping over fetched education details to render a form for each one to update it */}
      {familyData?.map((data, index) => (
        <Fragment key={data.id}>
          <div
            className='d-flex align-items-center mb-1 mt-3 details'
            style={{ gap: '16px' }}
          >
            <h5 className='titleName mt-2' style={{ whiteSpace: 'nowrap' }}>
              Family Info {index + 1}
            </h5>
            <div className='separator' />
            <button
              type='button'
              className='deleteBtn d-flex align-items-center'
              onClick={() => deleteEducation(data?.id)}
              disabled={!canModify}
            >
              <MdClose className='deleteIcon' />
            </button>
          </div>
          <FamilyForm
            data={data}
            onSubmit={formData => onUpdate(formData, data?.id)}
            method='Update'
            closeDrawer={closeDrawer}
            empId={empId}
          />
        </Fragment>
      ))}

      {showForm && (
        <>
          <div
            className='d-flex align-items-center mb-1 mt-3 details'
            style={{ gap: '16px' }}
          >
            <h5 className='titleName mt-2' style={{ whiteSpace: 'nowrap' }}>
              Family Info {(familyData?.length || 0) + 1}
            </h5>
            <div className='separator' />
            <button
              type='button'
              className='deleteBtn d-flex align-items-center'
              onClick={() => setShowForm(false)}
              disabled={!canModify}
            >
              <MdClose className='deleteIcon' />
            </button>
          </div>
          <FamilyForm
            formId='add-bank-detail'
            onSubmit={onCreate}
            method='Add'
            closeDrawer={closeDrawer}
            empId={empId}
          />
        </>
      )}
    </Wrapper>
  )
})

export default Family

const FamilyForm = observer(
  ({ data, formId, onSubmit, method, closeDrawer, empId }: FamilyForm) => {
    const [form] = Form.useForm()
    const {
      themesModel: { getColorsData },
    } = useStore(null)
    const buttonColor = getColorsData?.bodyColor
    const canModify = isCurrentUsersId(empId)

    const setFieldValues = () => {
      form.setFieldsValue({
        name: data?.name || '',
        relation: data?.relation || '',
        phone_number1: data?.phoneNumber1 || '',
        phone_number2: data?.phoneNumber2 || '',
      })
    }

    useEffect(() => {
      setFieldValues()
    }, [data])

    return (
      <Form
        id={data?.id || formId}
        layout='vertical'
        onFinish={onSubmit}
        form={form}
        disabled={!canModify}
      >
        <Labelalign gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['name']}
              label='Name'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Name'),
                validateNoWhiteSpace()
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['relation']}
              label='Relation'
              style={{
                position: 'relative',
              }}
              rules={[
                validateRequired('Relationship'),
                validateNoWhiteSpace()
              ]}
            >
              <CommonInput className='inputField' inputType='text' />
            </Form.Item>
          </Col>
        </Labelalign>
        <Labelalign gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['phone_number1']}
              label='Phone no 1'
              style={{
                position: 'relative',
              }}
              rules={[validateRequired('Phone Number 1')]}
            >
              <PhoneInput
                country={'pk'}
                placeholder={'+92 3XX-XXXXXXXX'}
                buttonStyle={{ borderWidth: '1px', zIndex: 9 }}
                disabled={!canModify}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['phone_number2']}
              label='Phone no 2'
              style={{
                position: 'relative',
              }}
            >
              <PhoneInput
                country={'pk'}
                placeholder={'+92 3XX-XXXXXXXX'}
                buttonStyle={{ borderWidth: '1px', zIndex: 9 }}
                disabled={!canModify}
              />
            </Form.Item>
          </Col>
        </Labelalign>
        <Labelalign gutter={24}>
          <Aligncontent>
            <Styledbutton
              title='Cancel'
              htmlType='button'
              size='small'
              type='primary'
              onClick={closeDrawer}
              style={{ backgroundColor: buttonColor }}
            />
            <Styledbutton
              form={data?.id || formId}
              title={`${method} Detail`}
              htmlType='submit'
              size='small'
              key='submit'
              type='primary'
              style={{ backgroundColor: buttonColor }}
            />
          </Aligncontent>
        </Labelalign>
      </Form>
    )
  }
)

const AddButton = styled.button<{isDarkTheme?: boolean}>`
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props?.isDarkTheme ? '#000' : ''};

  .add-icon {
    width: 20px;
    height: 20px;
  }
`

const Wrapper = styled.div<{ isDarkTheme?: boolean }>`
  padding: 12px 0;
  .addMore {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin: -23px 0 20px 0;
    z-index: 12;
    position: relative;
    img {
      cursor: pointer;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: ${TealishBlue};
      word-spacing: 0px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
  .separator {
    height: .5px;
    flex-grow: 1;
    background-color: ${props => props?.isDarkTheme ? '#fff' : '#1f1f1f'};
  }
  .details {
    padding: 0 16px 0 16px;
  }
  .titleName {
    color: ${props => props?.isDarkTheme ? '#fff' : '#000'};
    white-space: nowrap;
  }
  .deleteBtn {
    outline: none;
    border: none;
    background-color: ${props => props?.isDarkTheme ? black : lightGray};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;

    :hover {
      background-color: #fa9494;
    }
  }

  .deleteIcon {
    width: 18px;
    height: 18px;
    fill: #f42c2c;
  }
`

const Aligncontent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
`

const Styledbutton = styled(CustomButton)`
  background-color: blue;
  border-radius: 5px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  min-width: 100px;
  font-weight: 500 !important;
  padding: 16px;

  &:hover {
    color: white !important;
    background-color: blue;
  }

  &:focus {
    background-color: blue;
  }
`

const Labelalign = styled(Row)`
  padding: 0 16px 0 16px !important;
  .ant-form-item-label {
    padding: 0 3px 3px !important;
  }
`
