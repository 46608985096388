import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import reject from '@assets/img/cross-icon.svg'
import forward from '@assets/img/forward-icon.svg'
import leaveApproved from '@assets/img/leaveApproved.svg'
import leaveReject from '@assets/img/leaveReject.svg'
import pending from '@assets/img/pending-icon.svg'
import approved from '@assets/img/tick-icon.svg'
import Tooltip from '@commonComponents/CustomToolTip'
import { IconWrapper } from '@mainPage/expenses/expense'
import { Checkbox } from 'antd'
import moment from 'moment'
import { MdDelete, MdEdit, MdRemoveRedEye } from 'react-icons/md'
import styled from 'styled-components'
import { getColumns } from './column'
import {
  ACTION,
  ADD_LEAVE_APPLICATION,
  APPLIER_NAME,
  APPROVED_TEXT,
  CANCEL_LEAVE,
  NORMAL_DATE_FORMAT,
  DELETE,
  DURATION,
  EDIT,
  EDIT_LEAVE_APPLICATION,
  FORWARD,
  FORWARD_TEXT,
  LEAD_NAME,
  LEAVE_DETAILS,
  LEAVE_TYPE,
  PENDING,
  PENDING_APPROVAL,
  REJECTED_TEXT,
  STATUS,
  REASON,
  leaveStatusColors,
  NORMAL_TIME_FORMAT,
  APPROVED_TEXT_HISTORY,
  REJECTED_TEXT_HISTORY,
  SHORT
} from './const'
import { getFullName } from '@utils/common-functions'
import { colors } from 'constant/colors'
import { SetStateAction } from 'react'
import { capitalize } from 'lodash'

const { lightGray8, green2, bernRed, lightGray9 } = colors

const getDeleteButton = ({
  row,
  onDelete,
  setViewDetail,
  setIsDeleteClicked,
}) => {
  return (
    <>
      <Tooltip title={DELETE} minLength={0}>
        <IconButton
          type='button'
          iconColor={'red'}
          id={'delete-leave-button'}
          onClick={() => {
            onDelete(row)
            setViewDetail(false)
            setIsDeleteClicked(true)
          }}
        >
          <MdDelete />
        </IconButton>
      </Tooltip>
    </>
  )
}

export const getLeaveEditButton = ({ onEdit, setViewDetail, row }) => {
  const remoteLeave = row.startDate ? true : false
  const shortLeaveStarted =
    new Date() < new Date(row.leaveStartDate) ? false : true
  const isCancelledAndPending = row.status === 'pending' && row.isCancelled

  return (
    <>
      {(row.isFullLeave ||
        (!row.isFullLeave && !shortLeaveStarted) ||
        (remoteLeave && !row.isCancel)) &&
        !isCancelledAndPending && (
          <Tooltip title={EDIT} minLength={0}>
            <IconButton
              type='button'
              iconColor={'blue'}
              id='edit-leave-button'
              onClick={() => {
                onEdit(row)
                setViewDetail(false)
              }}
            >
              <MdEdit />
            </IconButton>
          </Tooltip>
        )}
    </>
  )
}

export const getLeaveCancelButton = ({ endDate, row, cancelLeave }) => {
  const currentDate = moment()
  const leaveEndDate = moment(endDate)
  const duration = moment.duration(leaveEndDate.diff(currentDate))
  const remainingDaysTillLeaveEnds = Math.round(duration.asDays())
  const isLeaveEnded = new Date() > new Date(endDate) ? true : false
  const isHalfLeave = row?.leaveTypeTitle === SHORT
  return (
    <CenteredDiv>
      {!isLeaveEnded &&
        !isHalfLeave &&
        row?.duration > 1 &&
        remainingDaysTillLeaveEnds >= 1 && (
          <Tooltip title={CANCEL_LEAVE} minLength={0}>
            <IconButton
              type='button'
              id='half-cancel-button'
              onClick={() => cancelLeave(row)}
            >
              <CloseOutlined />
            </IconButton>
          </Tooltip>
        )}
    </CenteredDiv>
  )
}

export const getSideDrawerHeader = (viewDetail, isUpdate, isRemote) => {
  return viewDetail
    ? LEAVE_DETAILS
    : isUpdate
    ? EDIT_LEAVE_APPLICATION
    : isRemote
    ? 'Apply For Remote'
    : ADD_LEAVE_APPLICATION
}

export const statusImages = {
  approved: <img width='30px' src={approved} />,
  cancelled: <img width='30px' src={approved} />,
  rejected: <img width='30px' src={reject} />,
  forward: <img width='30px' src={forward} />,
  pending: <img width='30px' src={pending} />,
}

export const getLeaveColumns = (
  setIsLeaveDisable,
  cancelLeave,
  onDelete,
  onEdit,
  setViewDetail,
  setIsDeleteClicked,
  isRemote,
  setIsRemoteLeaveDisable
) => {
  const data = [
    {
      title: <CenteredDiv>{LEAD_NAME}</CenteredDiv>,
      dataIndex: 'supervisor',
      width: isRemote ? '220px' : '',
      fixed: window.innerWidth >= 700 ? ('left' as const) : false,
      render: (_, row) => (
        <CenteredDiv>
          <div>
            {row?.supervisor && row?.supervisor?.firstName
              ? row.supervisor.firstName +
                ' ' +
                (row.supervisor?.lastName || '')
              : 'No Lead Selected'}
          </div>
        </CenteredDiv>
      ),
    },
    {
      title: <CenteredDiv>{LEAVE_TYPE}</CenteredDiv>,
      dataIndex: 'leaveTypeTitle',
      hidden: isRemote ? true : false,
      render: (_, row) => {
        return (
          <CenteredDiv className='text-capitalize'>
            {row.leaveTypeTitle}
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{DURATION}</CenteredDiv>,
      width: isRemote ? '220px' : '',
      render: (_, row) => {
        const { startDate, endDate } = row
        const isShortLeave = row?.leaveTypeTitle === SHORT
        const startDateObj = moment(startDate)
        const endDateObj = moment(endDate)
        const hoursDifference = moment.duration(endDateObj.diff(startDateObj))
        const duration = hoursDifference.asHours()
        const roundedDuration = duration.toFixed()
        const startTime = startDateObj.format(NORMAL_TIME_FORMAT)
        const endTime = endDateObj.format(NORMAL_TIME_FORMAT)

        return (
          <DurationDiv>
            <p>
              {' '}
              {moment(startDate).format(NORMAL_DATE_FORMAT)}{' '}
              {!isShortLeave &&
                `- ${moment(endDate).format(NORMAL_DATE_FORMAT)}`}{' '}
              {isShortLeave && `${startTime} - ${endTime} ,`}
            </p>
            <StyledDiv>
              <span className='duration-text'>
                {isShortLeave
                  ? `${roundedDuration} Hour(s)`
                  : `${row?.duration} Day(s)`}
              </span>
              {row?.isCancelled && (
              <CancelledText className='cancelled-text'>
                {'Cancelled Leave'}
              </CancelledText>
            )}
            </StyledDiv>
          </DurationDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{STATUS}</CenteredDiv>,
      dataIndex: 'status',
      width: isRemote ? '250px' : '',
      render: (_, row) => {
        if (row?.status === 'forward' || row?.status === 'pending') {
          setIsLeaveDisable(true)
          setIsRemoteLeaveDisable(true)
        }
        return (
          <CenteredDiv>
            <Tooltip
              placement='topLeft'
              minLength={0}
              title={capitalize(row?.status || '')}
            >
              {statusImages[row?.status] || <img width='30px' src={pending} />}{' '}
            </Tooltip>
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>Reject Reason</CenteredDiv>,
      dataIndex: 'reason',
      width: isRemote ? '220px' : '',
      render: (reason, row) => {
        return (
          <CenteredDiv>
            <p style={{ margin: '0' }}>
              <Tooltip
                placement='topLeft'
                title={
                  row.status === 'rejected' ? row?.rejectionReason || '' : ''
                }
              >
                <ReasonSpanStyled>
                  {row.status === 'rejected' ? 
                  <>
                     <Tooltip placement='topLeft' title={reason} minLength={15}>
                        <span>{(reason?.slice(0,15) + (reason?.length > 15 ? '....' : '' ))|| 'No reason provided'}</span>
                     </Tooltip>     
                  </> : '-'
                  }
                </ReasonSpanStyled>
              </Tooltip>
            </p>
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{ACTION}</CenteredDiv>,
      dataIndex: 'action',
      width: isRemote ? '220px' : '',
      render: (_, row) => {
        const canModify = row?.isCancelled === false && row?.status !== 'forward'
        const canEditAndDelete = row?.status === 'pending'
        const canCancel =
          row?.status !== 'pending' && row?.status !== 'rejected'

        return canModify ? (
          <CenteredDiv>
            {canEditAndDelete &&
              getLeaveEditButton({ onEdit, row, setViewDetail })}
            {canEditAndDelete &&
              getDeleteButton({
                row,
                onDelete,
                setViewDetail,
                setIsDeleteClicked,
              })}
            {canCancel &&
              getLeaveCancelButton({
                row,
                cancelLeave,
                endDate: row?.endDate,
              })}
          </CenteredDiv>
        ) : null
      },
    },
  ]

  isRemote && data.splice(1, 1)
  return data
}
const getCalledFrom = ['LeaveForApproval', 'CancelLeaveApproval']

export const getLeaveApprovalColumns = (
  statusLoader,
  currentData,
  onStatusChange,
  isRemote,
  isCancel,
  showDrawer,
  calledFrom,
  handleSingleCheck?: any,
  handleCheckAll?: any,
  queryParam?: any,
  isCheckAll?: any,
  setRecord?: SetStateAction<React.Dispatch<any>>
) => {
  const data = [
    {
      title: (
        <CenteredDiv>
          {queryParam?.status?.length &&
            (queryParam.status === 'Pending_Approval' ||
              queryParam.status === 'Rejected' ||
              queryParam.status === 'Forward') && (
              <Checkbox
                checked={isCheckAll}
                style={{ marginRight: '12px' }}
                onChange={event => handleCheckAll(event)}
              />
            )}
          {APPLIER_NAME}
        </CenteredDiv>
      ),
      fixed: window.innerWidth >= 700 ? ('left' as const) : false,
      render: (_, row) => {
        return (
          <CenteredDiv>
            <StyledSpan>
              <Tooltip placement='topLeft' title={row.employeeName}>
                <p className='applierName'>
                  {getFullName(row) ?? 'No Applier is Selected'}
                </p>
              </Tooltip>
            </StyledSpan>
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{LEAD_NAME}</CenteredDiv>,
      dataIndex: 'leadName',
      render: leadName => {
        return (
          <CenteredDiv>
            <p style={{ margin: '0' }}>
              <Tooltip title={leadName} minLength={15}>
                {leadName || 'No Lead Selected'}
              </Tooltip>
            </p>
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{LEAVE_TYPE}</CenteredDiv>,
      dataIndex: 'leaveTypeTitle',
      render: leaveType => (
        <CenteredDiv className='text-capitalize'>{leaveType}</CenteredDiv>
      ),
    },
    {
      title: <CenteredDiv>Applied Date</CenteredDiv>,
      dataIndex: 'appliedDate',
      width: '220px',
      render: appliedDate => {
        return (
          <CenteredDiv>
            <p style={{ margin: '0' }}>
              {moment(appliedDate).format(NORMAL_DATE_FORMAT) ||
                'Applied Date not found.'}
            </p>
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{DURATION}</CenteredDiv>,
      width: isRemote ? '220px' : '',
      render: (_, row) => {
        const { startDate, endDate } = row
        const isShortLeave = row?.leaveTypeTitle === SHORT
        const startDateObj = moment(startDate)
        const endDateObj = moment(endDate)
        const hoursDifference = moment.duration(endDateObj.diff(startDateObj))
        const duration = hoursDifference.asHours()
        const startTime = startDateObj.format(NORMAL_TIME_FORMAT)
        const endTime = endDateObj.format(NORMAL_TIME_FORMAT)
        return (
          <DurationDiv>
            <p>
              {' '}
              {moment(startDate).format(NORMAL_DATE_FORMAT)}{' '}
              {!isShortLeave &&
                `- ${moment(endDate).format(NORMAL_DATE_FORMAT)}`}{' '}
              {isShortLeave && `${startTime} - ${endTime} ,`}
            </p>
            <div>
              <span className='duration-text'>
                {isShortLeave
                  ? `${duration} Hour(s)`
                  : `${row?.duration} Day(s)`}
              </span>
            </div>
          </DurationDiv>
        )
      },
    },
    ...getColumns(),
    {
      title: <CenteredDiv>{STATUS}</CenteredDiv>,
      dataIndex: 'status',
      width: '140px',
      render: (_, record) => {
        const showActionButtons =
          record?.status !== 'approved' &&
          record?.status !== 'rejected' &&
          record?.status !== 'cancelled'
        const showButton = status =>
          !record?.isCancelled ? true : status === 'rejected' ? false : true
        return (
          <>
            <StyledButton>
              <p className='status text-capitalize'>
                <StyledStatus style={leaveStatusColors[record?.status] || {}}>
                  {record?.status}
                </StyledStatus>
              </p>

              <div className='StyledButton'>
                {showActionButtons && (
                  <FlexCenterDiv>
                    {['approved', 'rejected'].map(status => {
                      return showButton(status) ? (
                        <StyledImageWrapper
                          key={status}
                          type={
                            status === 'approved'
                              ? 'ApprovedIcon'
                              : 'RejectedIcon'
                          }
                          onClick={() => {
                            onStatusChange(record.id, status)
                            setRecord?.(record)
                          }}
                          id={`${status}-button`}
                        >
                          <StyledStatusImage
                            src={
                              status === 'approved'
                                ? leaveApproved
                                : leaveReject
                            }
                          />
                        </StyledImageWrapper>
                      ) : null
                    })}
                  </FlexCenterDiv>
                )}
              </div>

              <div style={{ marginLeft: '10px' }}>
                {record.id === currentData?.id && statusLoader && (
                  <LoadingOutlined />
                )}
              </div>
            </StyledButton>
          </>
        )
      },
    },
    getCalledFrom.includes(calledFrom)
      ? {
          title: <CenteredDiv>{ACTION}</CenteredDiv>,
          width: isRemote ? '220px' : '',
          render: (_, row) => (
            <Tooltip title={'View Details'} minLength={0}>
              <IconWrapper onClick={() => showDrawer(row)}>
                <MdRemoveRedEye />
              </IconWrapper>
            </Tooltip>
          ),
        }
      : [],
  ]
  if (isRemote) {
    data.splice(2, 2);
        data.splice(5, 5);
  }
  !isRemote && isCancel && data.splice(3, 1)
  return data
}

export const getLeaveHistoryColumns = isRemote => {
  const data = [
    {
      title: <CenteredDiv>{APPLIER_NAME}</CenteredDiv>,
      fixed: window.innerWidth >= 700 ? ('left' as const) : false,
      render: (_, row) => {
        return (
        <CenteredDiv>
           <p >{getFullName(row)}</p>
        </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{LEAD_NAME}</CenteredDiv>,
      dataIndex: 'leadName',
      width: '220px',
      render: leadName => {
        return (
          <CenteredDiv>
            <Tooltip placement='topLeft' title={leadName} minLength={15}>
              <p>{leadName || 'No Lead Selected'}</p>
            </Tooltip>
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>Leave Type</CenteredDiv>,
      dataIndex: 'leave_type_title',
      render: (_, row) => {
        return (
          <CenteredDiv>
            <p>{row.leaveTypeTitle}</p>
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>Last Approval</CenteredDiv>,
      dataIndex: 'lead',
      render: (_, row) => {
        const Time_Format = 'hh:mm A'
        return (
          <CenteredDiv>
            <p style={{ margin: '0' }}>
              {isRemote
                ? row?.approvedByName
                  ? row?.approvedByName
                  : '-'
                : row?.approvedBy
                ? (row?.approvedBy?.firstName && row.approvedBy.firstName) +
                  ' ' +
                  (row?.approvedBy?.lastName && row.approvedBy.lastName)
                : '-'}
            </p>
            {isRemote && row?.isCancelLeave && (
              <CancelledText style={{ marginLeft: '-10px !important' }}>
                Cancelled Leave
              </CancelledText>
            )}
            {(row?.employeeLeave?.adminApprovalTime !== null &&
              row?.employeeLeave?.adminApprovalTime !== undefined) ||
            (row?.adminApprovalTime !== null &&
              row?.adminApprovalTime !== undefined) ? (
              <span
                className='duration-text'
                style={{
                  padding: '1px 3px',
                  backgroundColor: ' #DDDDDD',
                  borderRadius: '10px',
                  width: 'min-content',
                }}
              >
                {moment(
                  isRemote
                    ? row?.adminApprovalTime
                    : row?.employeeLeave?.adminApprovalTime
                ).format(`${Time_Format}`)}
              </span>
            ) : (
              ''
            )}
          </CenteredDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{DURATION}</CenteredDiv>,
      width: '220px',
      render: (_, row) => {
        const { startDate, endDate } = row
        const isShortLeave = row?.leaveTypeTitle === SHORT
        const startDateObj = moment(startDate)
        const endDateObj = moment(endDate)
        const hoursDifference = moment.duration(endDateObj.diff(startDateObj))
        const duration = hoursDifference.asHours()
        const startTime = startDateObj.format(NORMAL_TIME_FORMAT)
        const endTime = endDateObj.format(NORMAL_TIME_FORMAT)
        return (
          <DurationDiv>
            <p>
              {' '}
              {isShortLeave && `${startTime} - ${endTime} ,`}{' '}
              {moment(startDate).format(NORMAL_DATE_FORMAT)}{' '}
              {!isShortLeave &&
                `- ${moment(endDate).format(NORMAL_DATE_FORMAT)}`}
            </p>
            <div>
              <span className='duration-text'>
                {isShortLeave
                  ? `${duration} Hour(s)`
                  : `${row?.duration} Day(s)`}
              </span>
            </div>
          </DurationDiv>
        )
      },
    },
    {
      title: <CenteredDiv>{REASON}</CenteredDiv>,
      dataIndex: 'reason',
      width: '220px',
      render: reason => (
       <CenteredDiv>
        <Tooltip placement='topLeft' title={reason} minLength={15}>
              <span>{(reason?.slice(0,15) + (reason?.length > 15 ? '....' : '' ))|| 'No reason provided'}</span>
        </Tooltip>
       </CenteredDiv>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: status => {
        return (
          <span>
            {status && (
              <img
                width='30px'
                src={
                  {
                    approved: approved,
                    rejected: reject,
                    forward: forward,
                  }[status] || pending
                }
                alt={status}
              />
            )}
          </span>
        )
      },
    },
  ]
  isRemote && data.splice(2, 1)
  return data
}

export const filterChildrens = [
  { key: PENDING_APPROVAL, value: PENDING },
  { key: FORWARD_TEXT, value: FORWARD },
]
export const remoteleaveFilterChildrens = [
  { key: PENDING_APPROVAL, value: PENDING },
  { key: FORWARD_TEXT, value: FORWARD },
]

export const leaveHistoryChildren = [
  { key: APPROVED_TEXT, value: APPROVED_TEXT_HISTORY },
  { key: REJECTED_TEXT, value: REJECTED_TEXT_HISTORY },
]

const IconButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 32px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 0px !important;
  background-color: transparent;

  svg {
    width: 15px;
    height: 15px;
    color: ${props => props?.iconColor || 'blue'} !important;
  }

  &:hover {
    background: rgba(45, 113, 216, 0.15);
  }
`

const ReasonSpanStyled = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  display: block;
`
const CancelledText = styled.p`
  color: red !important;
  font-size: 11px !important;
  background: rgb(255, 220, 217);
  width: fit-content;
  padding: 2px 12px;
  border-radius: 25px;
  margin-bottom: 0;
`
const StyledButton = styled.div`
  text-align: center;
  border: none;

  .status {
    margin-left: 0px !important;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
  }
  .StyledButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .Approved {
    background-color: ${green2};
    width: 70px;
    border-radius: 20px;
  }
  .Rejected {
    background-color: ${bernRed};
    width: 70px;
    border-radius: 20px;
  }
  .borderSpan-Approved {
    padding: 4px 16px 4px;
    border: 1px solid;
    border-color: ${lightGray9};
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  .borderSpan-Rejected {
    border: 1px solid;
    border-color: ${lightGray9};
    padding: 4px 16px 4px;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
`
const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  p {
    margin: 0;
    font-size: 20px !important;
  }
`
const StyledStatus = styled.p`
  margin-left: 0px !important;
  letter-spacing: 1px;
  padding: 0px 8px;
  width: 100px;
  border-radius: 15px;
  margin-bottom: 4px;
`
const StyledStatusImage = styled.img`
  width: 30px;
  height: 30px;
`

const StyledImageWrapper = styled.div`
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${props =>
      props.type === 'ApprovedIcon'
        ? 'rgba(86, 216, 55,0.2)'
        : props.type === 'RejectedIcon'
        ? 'rgba(240, 64, 39,0.2)'
        : ''};
  }
`

const CenteredDiv = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`

const DurationDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  .duration-text {
    padding: 1px 6px;
    background-color: ${lightGray8};
    border-radius: 10px;
    width: min-content;
  }
`

const FlexCenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 10px;
`
