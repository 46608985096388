import {
  MdPersonAdd,
  MdPersonOff,
  MdPersonRemove,
  MdTimer,
  MdTimerOff,
} from 'react-icons/md'
import moment from 'moment'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import style from './style.module.scss'
import { colors } from 'constant/colors'
import { useEffect, useState } from 'react'
import { useStore } from '@stores/root-store'
import { DemoLine } from './workStatisticChart'
import total from '../../../assets/img/total.svg'
import { RiTimerFlashFill } from 'react-icons/ri'
import { LoadingOutlined } from '@ant-design/icons'
import { EmployeePunchButton } from './employeeTimeSheet'
import { Avatar, Button, Card, Col, Empty, Row, Timeline } from 'antd'
import TodayAttendance from '@mainPage/attendence/PunchInOutHistorySidebar'

const { TealishBlue } = colors

const employeeDashboard = observer(props => {
  const { firstName, lastName, fullName } =
    props.getcompany.user || {}
  const { id: employee_id } = props.getcompany || {}
  const [loading, setLoading] = useState(true)
  const [graphSwitch, setgraphSwitch] = useState('month')
  const [blackTheme, setBlackTheme] = useState(null)
  const [textColor, setTextColor] = useState(null)
  const [punchHistory, setPunchHistory] = useState(null)
  const [refreshStatistics, setRefreshStatistics] = useState(false)
  const [refreshPunchActivity, setRefreshPunchActivity] = useState(false)

  const {
    singlePunchModal: { loadSinglePunch },
  } = useStore(null)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await attendanceTodaysActivity()
      setLoading(false)
    }
    fetchData()
  }, [])

  const attendanceTodaysActivity = async () => {
    const data = await loadSinglePunch(
      employee_id,
      moment(new Date()).format('YYYY-MM-DD')
    )
    setPunchHistory(data)
  }

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem('colorTheme'))
    setBlackTheme(storedTheme?.theme === 'dark' ? 'black' : 'white')
    setTextColor(storedTheme?.theme === 'dark' ? 'white' : 'rgb(64, 64, 64)')
  }) 
 
  const StyledCard = ({ heading, dataCount, Icon }) => {
    return (
      <StyleCards>
        <Styleddiv>
          <Icon />
        </Styleddiv>
        <StyleFonts style={{ color: textColor }}>{heading}</StyleFonts>
        <Styleddiv style={{ color: textColor }}>{dataCount || 0}</Styleddiv>
      </StyleCards>
    )
  }

  return (
    <div className='page-wrapper'>
      <div className={`${style.employeeDashboard} content container-fluid`}>
        <Card
          style={{
            borderRadius: '12px',
            boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.25)',
            marginBottom: '20px',
          }}
        >
          <Headerui>
            <DisplayPicUi>
              <HeaderUi>
                {props.getcompany.employeeImage ? (
                  <Displaypicture src={props.getcompany.employeeImage} />
                ) : (
                  <Displaypicture>
                    {fullName
                      ? fullName?.[0].toUpperCase()
                      : firstName?.[0].toUpperCase()}
                  </Displaypicture>
                )}
              </HeaderUi>

              <div className={style.employeedetails}>
                <FontStyle>
                  Hi, {fullName ? fullName : firstName + ' ' + lastName}
                </FontStyle>
                <FontStyleText style={{ textAlign: 'start', color: textColor }}>
                  Welcome to your Dashboard!
                </FontStyleText>
              </div>
            </DisplayPicUi>
            <StyledDiv>
              {!props?.getcompany && (
                <RemoteLeaveMessage style={{ backgroundColor: blackTheme }}>
                  <p>{'You are on Leave'}</p>
                </RemoteLeaveMessage>
              )}
              <div
                className={style.totalEmployee}
                style={{ backgroundColor: blackTheme }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '4px',
                  }}
                >
                  <FontStyle
                    style={{
                      fontSize: '30px',
                      fontWeight: '400',
                      marginTop: '4px',
                      marginRight: '6px',
                    }}
                  >
                    {props.singleEmployeeAttendanceData?.totalDays || (
                      <LoadingOutlined />
                    )}
                  </FontStyle>
                  <div>
                    <img src={total} alt='total' />
                  </div>
                </div>
                <FontStyleText style={{ color: textColor }}>
                  Days at Enigmatix
                </FontStyleText>
              </div>
            </StyledDiv>
          </Headerui>
        </Card>
        <div className={style.employeeSataus}>
          {props.singleEmployeeAttendanceData && (
            <Row gutter={24}>
              <Col xs={24} md={24} xl={9}>
                <EmployeePunchButton 
                refreshStatistics={refreshStatistics}
                setRefreshStatistics={setRefreshStatistics}
                refreshPunchActivity={refreshPunchActivity}
                setRefreshPunchActivity={setRefreshPunchActivity}
                />
              </Col>
              <Col xs={24} md={24} xl={9}>
                <StyledAttendenceCard>
                  <div style={{ width: '100%' }}>
                    <StyledAttCard>
                      <FontStyle>Attendance Overview</FontStyle>
                      <FontStyleText
                        style={{ textAlign: 'center', color: textColor }}
                      >
                        (By Month)
                      </FontStyleText>
                    </StyledAttCard>
                  </div>
                  <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                    <StyledCard
                      Icon={MdPersonAdd}
                      heading={'total presents'}
                      dataCount={
                        props.singleEmployeeAttendanceData?.totalPresentDays
                      }
                    />
                    <StyledCard
                      Icon={MdPersonOff}
                      heading={'total absents'}
                      dataCount={
                        props.singleEmployeeAttendanceData?.totalAbsentDays
                      }
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                    <StyledCard
                      Icon={MdPersonRemove}
                      heading={'consuming leaves'}
                      dataCount={props.singleEmployeeAttendanceData?.onLeaves}
                    />
                    <StyledCard
                      Icon={RiTimerFlashFill}
                      heading={'Over Time'}
                      dataCount={
                        props.singleEmployeeAttendanceData?.totalOvertime + ' hours'
                      }
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                    <StyledCard
                      Icon={MdTimer}
                      heading={'On-Time'}
                      dataCount={props.singleEmployeeAttendanceData?.onTime}
                    />
                    <StyledCard
                      Icon={MdTimerOff}
                      heading={'Late'}
                      dataCount={props.singleEmployeeAttendanceData?.lateComing}
                    />
                  </div>
                </StyledAttendenceCard>
              </Col>
              <StyledActivityCol xs={24} md={24} xl={6}>
                <ActivCard>
                  <StatisticHeading className='today-activity-heading'>
                    Today’s Activity
                  </StatisticHeading>
                  <div className='Activity'>
                    <StyledTimeline>
                      <TodayAttendance punchHistory={punchHistory} />
                    </StyledTimeline>
                  </div>
                </ActivCard>
              </StyledActivityCol>
            </Row>
          )}
        </div>
        <div className={style.workTimeStatistic}>
          <Row gutter={24}>
            <Col xs={24} md={24} xl={18}>
              <Card style={{ marginBottom: '20px' }}>
                <GraphHeader>
                  <FontStyle>Work Time Statistics</FontStyle>
                  <Button
                    className={`${graphSwitch === 'month' && style.active} ${
                      style.btn1
                    }`}
                    onClick={() => setgraphSwitch('month')}
                  ></Button>
                  <Button
                    onClick={() => setgraphSwitch('year')}
                    className={`${graphSwitch === 'year' && style.active} ${
                      style.btn1
                    } ${style.year}`}
                  ></Button>
                </GraphHeader>

                {loading ? (
                  <div className='loader'>
                    <LoadingOutlined />
                  </div>
                ) : (
                  <>
                    <div style={{ height: '300px', width: '100%' }}>
                      <DemoLine />
                    </div>
                    <div className='d-flex align-items-center justify-content-center pt-2'>
                      <StyleFonts className={style.date}>Date</StyleFonts>
                    </div>
                    <StyleFonts className={style.hours}>In Hours</StyleFonts>
                  </>
                )}
              </Card>
            </Col>
            <Col xs={24} md={24} xl={6}>
              <Card>
                <StyleAnnounce>
                  <FontStyle>Announcement</FontStyle>
                  <Nodatadiv image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </StyleAnnounce>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
})
export default employeeDashboard

const FontStyle = styled.div`
  width: max-content;
  font-family: 'Poppins' !important;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1px;
  @media (max-width: 425px) {
    font-size: 18px;
  }
  @media (max-width: 360px) {
    line-height: 20px;
    font-size: 17px;
  }
`
const StyleAnnounce = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  align-items: center;
`

const StyledDiv = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-wrap: wrap-reverse;
  }

  @media (max-width: 1080px) {
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
`
const RemoteLeaveMessage = styled.div`
  width: 300px;
  height: 100px;
  padding: 20px;
  background: #dfeaf9;
  margin-right: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1024px) {
    margin-top: 15px;
    margin-right: 0px;
  }
  @media (max-width: 550px) {
    height: 80px;
  }
  @media (max-width: 454px) {
    width: 220px;
    height: 70px;
  }
  @media (max-width: 380px) {
    width: 350px;
    height: 80px;
  }
  p {
    display: flex;
    align-items: center;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 1px;
    color: #525353;
    @media (max-width: 500px) {
      line-height: 20px;
      font-size: 8px;
    }
    @media (max-width: 425px) {
      line-height: 15px;
      font-size: 8px;
    }
  }
`

const StyledAttendenceCard = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
`
const StyledAttCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px !important;
  width: 100% !important;
  .ant-card {
    width: 100% !important;
  }
`
const StyleCards = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 110px !important;
  width: 100% !important;
  .ant-card {
    width: 100% !important;
  }
`
const Styleddiv = styled.div`
  svg {
    font-size: 2rem;
  }

  text-align: center;
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  line-height: 27px !important;
  display: block;
  width: fit-content;
  margin: auto;
  @media (max-width: 560px) {
    font-size: 10px !important;
  }
  @media (max-width: 380px) {
    font-size: 8px !important;
  }
`

const Nodatadiv = styled(Empty)`
  font-weight: 600;
  font-size: 16px;
  color: rgb(64, 64, 64);
  text-transform: capitalize;
  line-height: 27px !important;
  position: relative;
  top: 120px;
`

const StyleFonts = styled.div`
  text-align: center;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  line-height: 27px !important;
  width: max-content;
  display: block;
  margin: auto;
  @media (max-width: 560px) {
    font-size: 10px !important;
    width: max-content;
  }
  @media (max-width: 380px) {
    font-size: 8px !important;
    width: max-content;
  }
`

const HeaderUi = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 315px) {
    display: none;
  }
`
const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 550px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`
const Headerui = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const DisplayPicUi = styled.div`
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
  width: 70%;

  @media (max-width: 700px) {
    width: 100%;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: 320px) {
    gap: 10px;
  }
`
const Displaypicture = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600 !important;
  font-size: 20px !important;
  text-transform: capitalize !important;
  line-height: 27px !important;
  height: 120px !important;
  width: 120px !important;

  @media (max-width: 525px) {
    height: 90px !important;
    width: 90px !important;
  }
  @media (max-width: 435px) {
    height: 70px !important;
    width: 70px !important;
  }

  @media (max-width: 375px) {
    height: 50px !important;
    width: 50px !important;
  }
`

const FontStyleText = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  @media (max-width: 550px) {
    font-size: 14px !important;
  }
  @media (max-width: 450px) {
    font-size: 12px !important;
  }
  @media (max-width: 320px) {
    font-size: 10px !important;
    line-height: 15px;
  }
`

const ActivCard = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: 'poppins';
  margin-bottom: 25px;

  .ant-card-body {
    .Activity {
      margin-top: 0;
    }
  }

  border: none !important;

  * {
    word-spacing: 0px !important;
  }

  h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #010101;
    @media (max-width: 576px) {
      text-align: center;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #525353;
    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  h1 {
    color: ${TealishBlue};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 0px;
    }
  }

  span {
    font-style: normal;
    font-family: 'Outfit';
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #525353;
    word-spacing: 2px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .Activity {
    margin-top: 25px;
    height: 370px;
    overflow: auto;

    .ant-timeline-item-content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #525353;

      span {
        font-size: 14px;
      }
    }
  }
`
const StyledActivityCol = styled(Col)`
  margin-bottom: 25px;
`
const StyledTimeline = styled(Timeline)`
  .ant-card-bordered {
    border: none !important;
  }
`
export const StatisticHeading = styled.h2`
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 17px;
  margin-bottom: 8px;
`
