import { headerAuthorization } from '../common-utils'

import { baseUrl } from '../const'
import { BaseApi } from '../baseApi'

class RoleApi extends BaseApi {
  setRoleDetails = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}role/`,
        {
          name: data,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getRoleDetails = async () => {
    try {
      const response = await this.axios.get(`${baseUrl}role/`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateRoleDetails = async (data, recordId) => {
    try {
      const response = await this.axios.put(
        `${baseUrl}role/${recordId}/`,
        {
          name: data,
        },
        headerAuthorization(this)
      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteRoleDetails = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}role/${id}/`, headerAuthorization(this))

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default RoleApi
