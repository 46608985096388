import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { getCommonColumns } from '@mainPage/Onboarding/Columns'
import { Table } from '@commonComponents/table-v2'
import { onboardingUrl } from '@api/const'
import CustomButton from '@commonComponents/button'
import CustomDrawer from '@commonComponents/drawer'
import { Space } from 'antd'
import LeadDrawer from './leadDrawers'
import HistoryDrawer from './historyDrawer'
import OnboardingForm from './onboardingForm'
import styled from 'styled-components'
import { StyledAttachmentWrapper, StyledChildDiv, RightImageWrapper, StyledRightImage } from '@commonComponents/attachment/index.styles'
import { colors } from 'constant/colors'

const { TealishBlue } = colors


const OnboardingTable = observer(({ refreshTable, setRefreshTable, queryParam }: any) => {

  const [showHistoryDrawer, setshowHistoryDrawer] = useState(false)
  const [showApprovedDrawer, setApprovedDrawer] = useState(false)
  const [showCandidateDrawer, setShowCandidateDrawer] = useState(false)
  const [empRecord, setEmpRecord] = useState(null)
  const [viewCv, setViewCv] = useState(false)
  const [approve, setApprove] = useState(false)

  const onEdit = () => {
    setShowCandidateDrawer(true)
  }

  const columns = useMemo(() => getCommonColumns( setEmpRecord, setApprovedDrawer, onEdit, setViewCv,setApprove), [])
  return (
    <>
      <div className='table-responsive'>
        <Table
          dataUrl={onboardingUrl}
          columns={columns}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam} />
      </div>
      <CustomDrawerStyled
        setVisible={setshowHistoryDrawer}
        visible={showHistoryDrawer}
        className={'addDrawer'}
        width={700}
        title={'History'}
      >
        {<HistoryDrawer setVisible={setshowHistoryDrawer} id={empRecord?.id} />}
      </CustomDrawerStyled>
      <CustomDrawer
        title='Edit'
        setVisible={setApprovedDrawer}
        visible={showApprovedDrawer}
        className='addDrawer'
        width={700}
        customFooter={<Space>
          <CustomButton
            form='LeadDrawer'
            type='primary'
            title={'Cancel'}
            className='cancelButton'
            onClick={() => setApprovedDrawer(false)} />
          <CustomButton
            form='LeadDrawer'
            htmlType='submit'
            type='primary'
            title={'Update'}
            className='addButton' />
        </Space>}
      >
        {<LeadDrawer setVisible={setApprovedDrawer} data={empRecord} setBtnLoad={() => null} setRefreshTable={setRefreshTable} approve={approve} />}
      </CustomDrawer>

      <CustomDrawer
        title='Edit Candidate'
        setVisible={setShowCandidateDrawer}
        visible={showCandidateDrawer}
        className='addDrawer'
        width={700}
        customFooter={<Space>
          <CustomButton
            form='onboarding-form'
            type='primary'
            title={'Cancel'}
            className='cancelButton'
            onClick={() => setShowCandidateDrawer(false)} />
          <CustomButton
            form='onboarding-form'
            htmlType='submit'
            type='primary'
            title={empRecord ? 'Update' : 'Add'}
            className='addButton' />
        </Space>}
      >
        {<OnboardingForm setVisible={setShowCandidateDrawer} data={empRecord} setBtnLoad={() => null} setRefreshTable={setRefreshTable} />}
      </CustomDrawer>

      <CustomDrawer
        title='Attachment'
        className='addDrawer'
        visible={viewCv}
        setVisible={() => {
          setViewCv(false)
        }}
        width={600}
      >
        <StyledAttachmentWrapper>
          <StyledChildDiv flexBasis='100%'>
            <RightImageWrapper>
              {empRecord?.attachments?.includes('.pdf') ? (
                <iframe src={empRecord?.attachments} width='100%' height='600px' />
              ) : (
                <StyledRightImage src={empRecord?.attachments} />
              )}
            </RightImageWrapper>
          </StyledChildDiv>
        </StyledAttachmentWrapper>
      </CustomDrawer >
    </>

  )
})

export default React.memo(OnboardingTable)

const CustomDrawerStyled = styled(CustomDrawer)`
   .ant-drawer-body {
    padding: 0px;
  }
  .ant-drawer-header {
    border-bottom: 1px solid ${TealishBlue};
  }
  .ant-drawer-header-title {
    padding-top: 15px !important;
  }
`
