import { flow, types,cast } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { sectionApi } from '@api'
import { notification } from '@utils/notifications'

export const section = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  department: types.maybeNull(types.string),
  departmentName: types.maybeNull(types.string),
  branch:types.maybeNull(types.string),
  parentSection: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
})

export const sectionModal = types
  .model({
    data: types.maybeNull(types.array(section)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextSec: types.optional(types.number, 1),

  })
  .views(self => ({
    get getSectionData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setSectionData = flow(function* (data) {
      try {
        self.loading = true
        yield sectionApi.setSection(data)
        notification.success('Section Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadSection = flow(function*  (page=1, pageSize=20, queryParam='', resetList=false) {
      self.loading = true
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextSec = 1
        }
        const res = yield sectionApi.getSection(page , pageSize, queryParam)
        if (!res?.isCancel){
          self.data = cast([...toJS(self.data ?? []), ...res.items])
          self.count = res.count
          if (res.next) {
            self.nextSec = self.nextSec + 1
          } else {
            self.nextSec = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Sections')
        }
      } finally {
        self.loading = false
      }
    })

    const updateSection = flow(function* (id, data) {
      try {
        self.loading = true
        yield sectionApi.updateSection(id, data)
        notification.success('Section Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      }  finally {
        self.loading = false
      }
    })

    const deleteSection = flow(function* (id) {
      try {
        self.loading = true
        yield sectionApi.deleteSection(id)
        notification.success('Section Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setSectionData,
      loadSection,
      deleteSection,
      updateSection,
    }
  })

export function initSectionModal() {
  return sectionModal.create({})
}
