import { flow, types, cast } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { newDahboardApi } from '@api'
import { notification } from '@utils/notifications'


export const asset_detail = types.model({
  assignedAsset:types.maybeNull(types.number),
  damagedAsset:types.maybeNull(types.number),
  deadAsset:types.maybeNull(types.number),
  totalAsset:types.maybeNull(types.number),
  unassignedAsset:types.maybeNull(types.number),
  underRepairAsset:types.maybeNull(types.number),
})

export const leave_detail = types.model({
  approvedRequests: types.maybeNull(types.number),
  pendingRequests: types.maybeNull(types.number),
  plannedLeaves: types.maybeNull(types.number),
  rejectedRequests: types.maybeNull(types.number),
  todayLeaves_count: types.maybeNull(types.number),
  unplannedLeaves: types.maybeNull(types.number),
})

export const loan_detail = types.model({
  loanAmount: types.maybeNull(types.number),
  loanedEmployee: types.maybeNull(types.number),
})

export const on_boarding_detail = types.model({
  hairedCandidates: types.maybeNull(types.number),
  rejectedCandidates: types.maybeNull(types.number),
  shortlistCandidates: types.maybeNull(types.number),
  totalCandidates: types.maybeNull(types.number),
})
 
export const payroll_detail = types.model({
  paidEmployees: types.maybeNull(types.number),
  stipendEmployee: types.maybeNull(types.number),
  unpaidEmployees: types.maybeNull(types.number),
})

export const employeesAbsent = types.model({
  name: types.maybeNull(types.string),
  empId:types.maybeNull(types.string),
  empDesignation: types.maybeNull(types.string),
  empImage: types.maybeNull(types.string),
  empRole: types.maybeNull(types.string),
});

const PlannedLeave = types.model({
  name: types.maybeNull(types.string),
  empId: types.maybeNull(types.string),
  empLeaveStartDate: types.maybeNull(types.string),
  empLeaveEndDate: types.maybeNull(types.string),
  empImage: types.maybeNull(types.string),
  empRole: types.maybeNull(types.string),
  empLeaveType: types.maybeNull(types.string),
  empLeaveRejectionReason: types.maybeNull(types.string),
});


const employeeOnLeaveToday = types.model({
  name: types.maybeNull(types.string),
  empId: types.maybeNull(types.string),
  empImage: types.maybeNull(types.string),
  empRole: types.maybeNull(types.string),
  empDesignation: types.maybeNull(types.string),
  empLeaveStartDate: types.maybeNull(types.string),
  empLeaveEndDate: types.maybeNull(types.string),
});

const employeeLeaveRejected = types.model({
  name: types.maybeNull(types.string),
  empId: types.maybeNull(types.string),
  empImage: types.maybeNull(types.string),
  empRole: types.maybeNull(types.string),
  empLeaveStartDate: types.maybeNull(types.string),
  empLeaveEndDate: types.maybeNull(types.string),
  empLeaveType: types.maybeNull(types.string),
  empLeaveRejection: types.maybeNull(types.string),
});

const payrollData = types.model({
  name: types.maybeNull(types.string),
  totalSalary: types.maybeNull(types.string),
  empDesig: types.maybeNull(types.string),
  image: types.maybeNull(types.string)
});


const employeesPresent = types.model({
  name: types.maybeNull(types.string),
  empId: types.maybeNull(types.string),
  empImage: types.maybeNull(types.string),
  empRole: types.maybeNull(types.string),
  empDesignation: types.maybeNull(types.string),

});

const ExpenseItem = types.model({
  month: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  line: types.maybeNull(types.string),
});

export const expenseModel  = types.model({
  assetExpense: types.maybeNull(types.array(ExpenseItem)),
  officeExpense: types.maybeNull(types.array(ExpenseItem)),
  salaryExpense: types.maybeNull(types.array(ExpenseItem)),
})
export const empPercentage = types.model({
  employeesAbsentPercentage:types.maybeNull(types.number),
  employeesCheckInLatePercentage:types.maybeNull(types.number),
  employeesCheckOnTimePercentage:types.maybeNull(types.number),
  employeesPresentPercentage:types.maybeNull(types.number)




})

const Employee = types.model('Employee', {
  name: types.maybeNull(types.string),
  empId: types.maybeNull(types.string),
  empDesignation: types.maybeNull(types.string),
  empImage: types.maybeNull(types.string),
  empRole: types.maybeNull(types.string),
});

const rejectedLeaveEmpData = types.model('Employee', {
  empId: types.maybeNull(types.string),
  empImage: types.maybeNull(types.string),
  empLeaveType: types.maybeNull(types.string),
  empRole: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  rejectedBy: types.maybeNull(types.string),
});

export const dashboardModel = types.model({
  assetDetail: types.maybeNull(types.array(asset_detail)),
  leaveDetail: types.maybeNull(types.array(leave_detail)),
  loanDetail: types.maybeNull(types.array(loan_detail)),
  onBoardingDetail: types.maybeNull(types.array(on_boarding_detail)),
  payrollDetail: types.maybeNull(types.array(payroll_detail)),
  employeesAbsent: types.maybeNull(types.array(employeesAbsent)),
  plannedLeaves: types.maybeNull(types.array(PlannedLeave)),
  employeeOnLeaveToday:types.maybeNull(types.array(employeeOnLeaveToday)),
  employeeLeaveRejected:types.maybeNull(types.array(employeeLeaveRejected)),
  payrollData:types.maybeNull(types.array(payrollData)),
  employeesPresent:types.maybeNull(types.array(employeesPresent)),
  presentEmployee: types.maybeNull(types.number),
  todayAbsent: types.maybeNull(types.number),
  totalEmployees: types.maybeNull(types.number),

})

export const dashboardNewModal = types.model({
  absentEmployeesData: types.maybeNull(types.array(Employee)),
  upComingLeavesData: types.maybeNull(types.array(types.frozen())),
  rejectedLeavesData: types.maybeNull(types.array(rejectedLeaveEmpData)),
  employeesOnLeaveData: types.maybeNull(types.array(types.frozen())),
  workFromHome: types.maybeNull(types.array(types.frozen())),
  employeePresentData: types.maybeNull(types.array(Employee)),
  onTimeCheckInEmployeesPercentage: types.maybeNull(types.number),
  employeeOnLeaveCount: types.maybeNull(types.number),
  upComingLeavesCount: types.maybeNull(types.number),
  employeesPresentCount: types.maybeNull(types.number),
  absentEmployeesCount: types.maybeNull(types.number),
  rejectedLeavesCount: types.maybeNull(types.number),
  totalPresentEmployeesPercentage: types.maybeNull(types.number),
  lateCheckInEmployeesPercentage: types.maybeNull(types.number),
  totalEmployeesCount: types.maybeNull(types.number),
  assetDetail: types.maybeNull(types.array(asset_detail)),
  payrollData: types.maybeNull(types.array(payrollData)),
  totalPayrollEmployee: types.maybeNull(types.number)
})

export const newDashboardModal = types
  .model({
    data: types.maybeNull(dashboardModel),
    expenseGraphdata: types.maybeNull(expenseModel),
    loading: types.optional(types.boolean, false),
    newDashBoardData: types.maybeNull(dashboardNewModal)
  })
  .views(self => ({
    get getNewDashboardData() {
      return toJS(self.data)
    },
    get getexpenseGraphdata(){
      return toJS(self.expenseGraphdata)
    },
    get isLoading() {
      return self.loading
    },
    get getDashBoardData() {
      return toJS(self.newDashBoardData)
    }
  }))
  .actions(self => {
    const setNewDashboard = flow(function* (data) {
      try {
        self.loading = true
        yield newDahboardApi.setNewDashboard(data)
        notification.success('')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {   
        self.loading = false
      }
    })

    const loadNewDashboard = flow(function* (queryParams) {
      try {
        self.data = cast({})
        self.loading = true
        const res = yield newDahboardApi.getNewDashboard(queryParams)
        if (res) {
          self.newDashBoardData = toJS(res)
        }
        return toJS(res.results)
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          // notification.success('Failed to Load Department')
        } 
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const expenseGraphData = flow(function*(){
      try{
        const res = yield newDahboardApi.newDashboardExpenseGraph()
        self.expenseGraphdata = res
        return res
      }catch(error){
        return error
      }
    })

    const updateNewDashboard = flow(function* (id, data) {
      try {
        self.loading = true
        yield newDahboardApi.updateNewDashboard(id, data)
        notification.success('Employee onboard Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setNewDashboard,
      loadNewDashboard,
      updateNewDashboard,
      expenseGraphData
    }
  })

export function initNewDashboardModal() {
  return newDashboardModal.create({})
}
