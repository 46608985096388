export const filterHirerachyData = (data, id) => {
    if (data?.id == id) return false;
    
    if (data?.children?.length) {
      let isRemoved = false;
      const filteredChildren = data?.children
        ?.map(item => filterHirerachyData(item, id))
        .filter(item=>{
            if(!item){
              isRemoved = true;
              return false
            }
            return true;
        });
      
      if(isRemoved && !filteredChildren?.length)  data.hasChild = false;
  
      return { ...data, children: filteredChildren };
    }
    return data;
  };
  