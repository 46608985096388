import { useStore } from '@stores/root-store'
import { Select, Form, Input, DatePicker } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import CustomButton from '@commonComponents/button'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const TaskForm = observer((props: any) => {
  const { boardID, projectID } = useParams()
  const [cardID, setCardID] = useState(null)
  const { Option } = Select
  const [id, setID] = useState(0)
  const [form] = Form.useForm()
  const [updatedDate, setUpdatedData] = useState({})
  const [projectData, setProjectData] = useState({})

  const {
    boardDetails: {
      loadBoardDetails,
      isLoading,
      getDataLabel,
      updateTaskDetails,
      setSprintTaskDetails,
      loadLabelDetail,
    },
  } = useStore(null)

  const {
    userInfo: { onBoardUser },
  } = useStore(null)

  const {
    projectsModel: { getProjectsData, loadProjects },
  } = useStore(null)

  useEffect(() => {
    setID(parseInt(boardID))
    async function load() {
      await loadBoardDetails(projectID)
      await loadLabelDetail(props.bID)
      await loadProjects(1)
    }
    load()
  }, [])

  useEffect(() => {
    async function load() {
      await onBoardUser()
    }

    if (localStorage.getItem('token')) {
      load()
    }
  }, [])

  useEffect(() => {
    if (props.isUpdate) {
      setCardID(props.taskData?.taskID)
      form.setFieldsValue({
      })
    }
  }, [props])

  useEffect(() => {
    if (id === parseInt(boardID)) {
      setProjectData(
        getProjectsData?.filter(project => project.id === projectID)[0]
      )
    }
  }, [getProjectsData])

  const updateTask = async changeValue => {
    const key = Object.keys(changeValue)[0]
    updatedDate[key] = changeValue[Object.keys(changeValue)[0]]
    setUpdatedData(updatedDate)
  }

  const sendTaskUpdate = async values => {
    let res = null
    if (cardID) {
      res = await updateTaskDetails(updatedDate, cardID)
    } else {
      values.assignee = values.assignee !== undefined ? values.assignee : []
      values.labels = values.labels !== undefined ? values.labels : []
      values.due_date = values.due_date !== undefined ? values.due_date : null
      res = await setSprintTaskDetails(values)
    }
    if (res.success) {
      setCardID(null)
      props.visibility(false)
      form.resetFields()
    }
  }

  return (
    <Form
      layout='vertical'
      form={form}
      onValuesChange={updateTask}
      onFinish={sendTaskUpdate}
    >
      <div>
        <p>Task Name</p>
        <Form.Item name='name'>
          <Input />
        </Form.Item>
        <p>Choose Label</p>

        <Form.Item name='labels'>
          <Select
            showSearch
            placeholder='Add Priorities'
            optionFilterProp='children'
            getPopupContainer={trigger => trigger.parentNode}
          >
            {getDataLabel && getDataLabel?.length > 0
              ? getDataLabel.map(item => {
                return <Option value={item.id}>{item.name}</Option>
              })
              : ''}
          </Select>
        </Form.Item>
        <p>Due Date</p>
        <Form.Item
          name='due_date'
          style={{
            position: 'relative',
          }}
        >
          <DatePicker
            disabledDate={current => {
              return moment().add(-1, 'days') >= current
            }}
            format='YYYY-MM-DD'
            getPopupContainer={trigger => trigger.parentElement}
            className='form-control inputField'
            onFocus={e => {
              if (!e.target.value) {
                form.setFieldsValue({ due_date: moment(new Date()) })
              }
            }}
          />
        </Form.Item>
        <p>Assignees</p>
        <Form.Item name='assignee'>
          <Select
            showSearch
            placeholder='Select Employee Type'
            optionFilterProp='children'
            getPopupContainer={trigger => trigger.parentNode}
          >
            {projectData['project_team'] &&
            projectData['project_team']?.length > 0
              ? projectData['project_team'].map(item => {
                return (
                  <Option value={item.id}>
                    {item.first_name + ' ' + item.last_name}
                  </Option>
                )
              })
              : ''}
          </Select>
        </Form.Item>
      </div>

      <Form.Item className='submitBtn w-100'>
        <CustomButton
          type='primary'
          htmlType='submit'
          title='Add'
          className='w-50'
          loading={isLoading}
        />
        <CustomButton
          type='primary'
          htmlType='button'
          title='Cancel'
          className='w-50'
          onClick={() => {
            form.resetFields()
          }}
        />
      </Form.Item>
    </Form>
  )
})

export default TaskForm
