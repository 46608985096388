import { flow, types } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { boardApi } from '../../../api'
import { notification } from '@utils/notifications'

export const boardListData = types.model({
  BoardLabelColor: types.maybeNull(types.string),
  BoardLabelName: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
})

export const boardData = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  project: types.maybeNull(types.string),
  boardList: types.maybeNull(types.array(boardListData)),
})

export const boardHomeDetails = types
  .model({
    data: types.maybeNull(types.array(boardData)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get getProjectBoardData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setBoardDetails = flow(function* (data) {
      try {
        self.loading = true
        const res = yield boardApi.setBoardHomeDetails(data)
        self.data.unshift(res)
        notification.success('Board Added Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const loadBoardDetails = flow(function* (projectID) {
      try {
        self.loading = true
        const res = yield boardApi.getBoardHomeDetails(projectID)
        self.data = res.data
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Board')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateBoardDetails = flow(function* (dataid, value, projectID) {
      try {
        self.loading = true
        const index = self.data.findIndex(value => value.id === dataid)
        self.data[index].name = value.name
        yield boardApi.updateBoardHomeDetails(
          self.data[index].id,
          value,
          projectID
        )
        notification.success('Board Updated Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const deleteBoardDetails = flow(function* (id, projectID) {
      try {
        self.loading = true
        yield boardApi.deleteBoardHomeDetails(id, projectID)
        self.data.splice(id.id, 1)
        notification.success('Board Deleted Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })
    return {
      setBoardDetails,
      loadBoardDetails,
      deleteBoardDetails,
      updateBoardDetails,
    }
  })

export function initBoardHomeDetails() {
  return boardHomeDetails.create({})
}
