export const STATUS_LIST = [
  {
    key: 'pending',
    value: 'Pending',
  },
  {
    key: 'approved',
    value: 'Approved',
  },
  {
    key: 'rejected',
    value: 'Rejected',
  },
  {
    key: 'interview',
    value: 'Interview',
  },
  {
    key: 'forward',
    value: 'Forward',
  },
]
