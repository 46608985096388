
import { onboardingUrl } from '../const'
import { BaseApi } from '../baseApi'

class OnBoardingApi extends BaseApi {

  setOnBoarding = async data => {
    try {
      const response = await this.axios.post(`${onboardingUrl}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getOnBoarding = async (num, pageSize, queryParam = {}) => {
    try {
      const url = `${onboardingUrl}?page=${num ? num : 1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {}       
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateOnBoardingLead = async (id, data) => {
    try {
      const url = `${onboardingUrl}lead/${id}`
      const response = await this.axios.patch(
        url, data
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateOnBoarding = async (id, data) => {
    try {
      const url = `${onboardingUrl}${id}`
      const response = await this.axios.patch(
        url, data
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

}
export default OnBoardingApi
