
import React, { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Form, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { useStore } from '@stores/root-store'
import { CommonSelect } from '@commonComponents/select'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { EMPLOYEE_NAME, FILTER_STATUS } from '../../const'
import SearchFilter from '@commonComponents/SearchFilter'
import {filterChildrens} from '@mainPage/EmployeeLeave/utils';
import { leaveHistoryChildren } from '@mainPage/EmployeeLeave/utils'
interface LeaveFormProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setQueryParam: any;
  setResetFilters: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRefreshTable: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  activeTab: any;
  resetFilters:any;

   setResetTable?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EmployeeFilter = observer((props: LeaveFormProps) => {

  const {
    employeeLeaveTypeModal: { getEmployeeLeaveTypeData, loadEmployeeLeaveType, nextType },
  } = useStore(null)

  useEffect(() =>
  {
    const loadData = async () => {
      if (!getEmployeeLeaveTypeData) {
        await loadEmployeeLeaveType()
      }
    }
    loadData()
  
  }, [])

  return (
    <SearchFilter
      {...props}
      resetFilters={props.resetFilters}
      setResetFilters={props.setResetFilters}
      children={
        <div className='d-lg-flex w-100 '>
          <Row gutter={4} className='`rowDiv` flex-grow-1 mb-0'>
            <Col xs={24} sm={24} md={8} lg={6}>
              <div>
                <Form.Item name={EMPLOYEE_NAME}>
                  <CommonInput
                    placeholder='Search by Employee'
                    inputType='text'
                    className='searchInput'
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
              <DynamicSelect
                isForm={true}
                name={'leave_type'}
                getData={getEmployeeLeaveTypeData}
                loadData={loadEmployeeLeaveType}
                nextPage={nextType}
                valueKey1={'title'}
                clearMyData={true}
                placeholder={' Select Leave Type'}
                fetchData={false}
              />
            </Col>
            <Col xs={24} sm ={24} md={24} lg={6}>
              <Form.Item name={FILTER_STATUS}>
              {props.activeTab !== 'cancelApproval' && (
                <CommonSelect
                  listHeight={120}
                  placeholder='Select Leave Status'
                  size='large'
                  allowClear
                  getPopupContainer={trigger => trigger.parentNode}
                  showSearch={true}
                  filterOption={(input, option) => {
                    props.activeTab != 'history' ? option.children : option.leaveHistoryChildren
                    option?.data
                      ?.toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }}
                  data={props.activeTab!= 'history' ? filterChildrens  : leaveHistoryChildren}
                />
              )}
              </Form.Item>
            </Col>
          </Row>
        </div>}
    />
)
})
export default EmployeeFilter
