import { Card, Checkbox, Form, InputNumber, Input, Row, Col } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'
import foodlogo from '../../../assets/img/foodlogo.svg'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { ecanteenApi } from '@api';
import { CommonInput } from '@commonComponents/input'
import { SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { colors } from 'constant/colors'

const { TealishBlue, mediumGray } = colors


export interface OrderMenuProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any;
  isUpdate?: boolean;
  setIsUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setPayloadModelVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderMenu = observer(({ data, setPayloadModelVisible, setRefreshTable, isUpdate }: OrderMenuProps) => {
  const [menuList, setMenuList] = useState([])
  const [totalAmount, setTotalAmount] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')

  const { eCanteenMenuModal: { geteCanteenmenu, loadEcanteenmenu }
  } = useStore(null)

  useEffect(() => {
    async function loadData() {
      if (!geteCanteenmenu?.length) {
        await loadEcanteenmenu(1, 10, '', true)
      }
    }
    loadData()
  }, [])

  useEffect(() => {
    getsum();
  }, [menuList])

  const onSubmit = async () => {
    const payload = {
      order_items: menuList.map(menuitem => {
        return {
          id: menuitem.id,
          quantity: menuitem.quantity
        }
      })
    }

    if (isUpdate) {
      await ecanteenApi.updateEcanteenOrder(payload, data.id)
    }
    else {
      const response = await ecanteenApi.seteCanteen(payload)
      if (response) {
        setMenuList([])
      }
    }
    setRefreshTable(true)
    setPayloadModelVisible(false)
  }

  const getsum = () => {
    const totalAmt = menuList.map(item => item.quantity * item.price)
      .reduce((acc, current) => {
        return acc + current;
      }, 0)
    setTotalAmount(totalAmt)
  }

  const onChangeData = (isChecked, item) => {
    if (isChecked) {
      setMenuList([...menuList, item])
    } else {
      const selectedItems = menuList.filter(menuitem => menuitem.id !== item.id)
      setMenuList(selectedItems)

    }
  }

  function handleChange(quanity, menuItem) {
    const isMenuAdded = menuList.filter(menu => menu.id === menuItem.id)
    if (isMenuAdded.length) {
      const menuListRecord = menuList.filter(menu => menu.id !== menuItem.id)
      const newMeniItem = { ...isMenuAdded[0], quantity: quanity }
      setMenuList([...menuListRecord, newMeniItem])
    }
  }

  useEffect(() => {
    if (geteCanteenmenu?.length) {
      const newData = data?.itemList?.map(item => {
        const selectedItem = geteCanteenmenu?.filter(menuItem => menuItem.id === item.itemId)
        const select = selectedItem?.length ? selectedItem[0] : { location: '', price: 0 }
        return {
          id: item?.itemId,
          itemName: item?.itemName,
          location: select?.location,
          price: select?.price,
          quantity: item?.quantity
        }
      })
      setMenuList(newData || [])
    }
  }, [data, geteCanteenmenu])

  const selectedItem = useMemo(() => menuList.map(item => item.id), [menuList])

  return (
    <>
      <OrderMenuDiv>
        <h1 style={{ color: TealishBlue }}>Order Food</h1>
        <div className='text-center mb-2'>
          <img className={'foodlogo'} src={foodlogo} alt='Food Logo' />
        </div>
        <h4 style={{ color: TealishBlue }} className='mb-3'>Select Your Order Here</h4>
      </OrderMenuDiv>
      <Form
        layout='vertical'
        onFinish={onSubmit}
        initialValues={{
          'checkbox-group': ['1', '2'],
        }}
        id='order-form'
      >
        <Col xs={24} sm={24} md={8} lg={24}>
          <Form.Item>
            <CommonInput
              placeholder='Search Items...'
              prefix={<SearchOutlined />}
              onChange={e => setSearchFilter(e.target.value)}
            />
          </Form.Item>
        </Col>

        <div className={'formAutoheight'}>
          <Card>
            <div className={`cardTitle`}>
              <Row gutter={24}>
                <Col span={9}><p>Item Name</p></Col>
                <Col span={5}><p>Qunatity</p></Col>
                <Col span={5}><p>Price</p></Col>
                <Col span={5}><p>Location</p></Col>
              </Row>
            </div>
          </Card>
          <div className={'foodList'}>
            {geteCanteenmenu?.filter(item => item.itemName?.toLowerCase().includes(searchFilter.toLowerCase()))?.map(menuItem => {
              return (
                <Row gutter={24}>
                  <Col span={9}>
                    <Form.Item>
                      <Checkbox onChange={e => onChangeData(e.target.checked, menuItem)} checked={selectedItem.includes(menuItem.id)}>{menuItem?.itemName}</Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={5}>
                    <div className={'quantity'}>
                      <Form.Item>
                        <InputNumber onChange={value => handleChange(value, menuItem)} min={1} max={10} defaultValue={menuItem?.quantity} />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col span={5}>
                    <div className={'priceField'}>
                      <Form.Item>
                        <Input type='text' value={menuItem?.price} />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col span={5}>
                    <div className={'priceField'}>
                      <Form.Item><Input type='text' /></Form.Item>
                    </div>
                  </Col>

                </Row>
              )
            })
            }

          </div>
          <div className={`${'total'} d-flex align-items-center justify-content-between`}>
            <div className={'totalPrice'}>Total Price </div>
            <div style = {{color: '#00c5fb' , fontWeight: 600 , fontSize: '18px'}}>Rs: {totalAmount}</div>
          </div>
        </div>
      </Form>
    </>
  )
})

export default OrderMenu

const OrderMenuDiv = styled.div`
 
 position: relative;

.foodlogo {
  max-width: 150px;
}

.ant-card {
  position: sticky;
  top: 0;
  min-width: 530px;

  .ant-card-body {
    padding: 15px 12px;
  }

  .cardTitle {
    p {
      font-size: 16px;
      color: $color-cyan-primary;
    }
  }
}

.searchItems {
  .ant-input {
    position: relative;
  }

  .anticon-search {
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 14px;
    color: #afafaf;
    font-size: 18px;
  }
}

.foodList {
  padding: 20px 12px 20px 12px;
  min-width: 530px;
  max-height: calc(100vh - 379px);
  overflow: auto;

  .ant-form-item {
    margin-bottom: 15px;
  }

  .quantity {
    .ant-input-number {
      background-color: #ebebeb;
      height: 21px;
      width: 100%;
      border: none;
      outline: none;

      input {
        height: 21px;
      }

      &:hover {
        border: none;
      }

      .ant-input-number-handler-wrap {
        background-color: #ebebeb;
        opacity: 1 !important;

        .anticon-up,
        .anticon-down {
          display: none;
          padding: 0;
          margin: 0;
        }

        span {
          border: none !important;
        }

        &:hover {
          .ant-input-number-handler {
            top: 0;
          }
        }
      }

      .ant-input-number-handler-up {
        position: relative;
        display: grid;
        place-items: center;
        margin: 2px 0 2px 0;

        &::before {
          content: '';
          width: 0;
          height: 0;
          position: absolute;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #77777d;
        }
      }

      .ant-input-number-handler-down {
        position: relative;
        display: grid;
        place-items: center;
        margin-top: -5px;

        &::before {
          content: '';
          width: 0;
          height: 0;
          position: absolute;
          border-top: 6px solid #77777d;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }
      }
    }
  }
}

.addTocart {
  text-align: center;

  .cartBtn {
    text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
    display: inline-block;
    position: relative;
    top: 25px;
  }
}

.ant-input-number-focused {
  box-shadow: unset !important;
}

.priceField {
  .ant-input {
    background-color: #ebebeb;
    height: 21px;
    width: 100%;
    box-shadow: none;
    border: none;
    outline: none;
    box-shadow: unset !important;

    &:hover {
      border: none;
    }
  }
}

.plusIcon {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .anticon-plus {
    width: 26px;
    height: 26px;
    background: linear-gradient(89.25deg, #24d0ff 33.64%, #0eb7e8 82.49%);
    border-radius: 50%;
    color: #fff;
    display: grid;
    place-items: center;
    font-size: 24px;
    cursor: pointer;
  }

  span {
    color: #0186e1;
  }
}

.AddMore {
  .ant-form-item-label {
    padding-bottom: 0;
  }

  .itemsfiled {
    height: 35px !important;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px dashed #cfcece;
  margin-top: 10px;

  .totalInput {
    width: 80%;
    height: 35px !important;
  }

  .totalPrice {
    font-weight: 700;
    color: rgb(150, 148, 148);
  }
}

h1 {
  color: $color-cyan-primary;
  text-align: center;
  font-weight: 700;
  font-size: 26px;
  margin: 0px;
}

h4 {
  color: $color-cyan-primary;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.ant-checkbox {
  .ant-checkbox-inner {
    border: 2px solid #00c5fb;
    border-radius: 2px;

    .ant-checkbox {
      .ant-checkbox-inner {
        border: 2px solid #00c5fb;
        border-radius: 2px;
      }
    }

    .ant-checkbox-wrapper {
      .ant-checkbox {
        border-radius: 2px;

        .ant-checkbox-inner {
          border: 2px solid #00c5fb;
          box-shadow: unset;
          width: 15px;
          height: 15px;
        }

        .ant-checkbox-checked {
          &:after {
            border: 1px solid #00c5fb;
          }

          .ant-checkbox-inner {
            background-color: $color-cyan-primary !important;

            &::after {
              width: 5.714286px;
              height: 10.142857px;
              top: 50%;
              left: 10.5%;
            }
          }
        }
      }
    }
  }

  .ant-input-number-input {
    height: 20px;
    border-radius: 3px;
  }

  .orderForm {
    .menuBtn {
      margin-left: 2px;

      @media (max-width: 990px) {
        margin: 0 auto 10px auto;
        padding: 20px;
      }
    }

    .breakfastInput {
      position: relative;

      .anticon-caret-down {
        position: absolute;
        top: 17px;
        right: 12px;
        width: 13px;
        height: 19px;
        color: #afafaf;
      }

      .ant-select {
        .ant-select-arrow {
          display: none;
        }
      }
    }

    .items {
      background: #ffffff;
      border: 1px solid ${mediumGray};
      border-radius: 6px;
      min-height: 86px;
      height: auto;
      margin: 10px 0 24px 0;
      position: relative;

      .anticon-plus {
        width: 26px;
        height: 26px;
        background: linear-gradient(89.25deg, #24d0ff 33.64%, #0eb7e8 82.49%);
        position: absolute;
        bottom: 8px;
        right: 8px;
        border-radius: 50%;
        color: #fff;
        display: grid;
        place-items: center;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .customLable {
      font-size: 16px;
    }
  }

  .orderModal {
    .ant-modal-body {
      padding-bottom: 0;
    }
  }
}

.formAutoheight {
  overflow: auto !important;
}

`
