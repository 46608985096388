import React, { useEffect, useState } from 'react'
import {  Checkbox, Dropdown, Menu, MenuProps, Tooltip} from 'antd'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { CustomSpanIcon } from '@mainPage/newEmployee/newEmployee'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFilter} from '@fortawesome/free-solid-svg-icons'
import { colors as colorObject } from 'constant/colors'

const CustomColumnSelect = observer(({setCustomCol, customCol, setColField, loadColField, getColFields, columns, callFrom}) => {
  const [open, setOpen] = useState(false);
  const [colUpdated, setColUpdated] = useState(false)
  const [clickTriggetColor, setClickTriggetColor] = useState(true)
  const [color, setColor] = useState(null)

  useEffect(() => {
    const  bodycolor = JSON.parse(localStorage.getItem('colorTheme'))
    setColor( bodycolor ? bodycolor?.bodyColor : colorObject.TealishBlue)
  }, [])

  useEffect(() => {
     if (!getColFields?.length || !getColFields[0]?.fields?.length) {
        columns && setCustomCol(columns?.map(col => {
        return { id: col.id, title: col.title?.props?.children || col.title, isActive: true }
      }))
    }
  }, [columns])

  const filterOptions = (item, index) => {
    return <Menu.Item key={item.id} className='customCol'>
      <Checkbox
        disabled={item.title === 'Name'}
        onClick={() => {
          if (item.title !== 'Name') {
            customCol[index].isActive = !customCol[index].isActive
            setCustomCol([...customCol])
            setColUpdated(true)
          }
        }}
        checked={customCol[index].isActive}
      />
      <p>{item.title}</p>
    </Menu.Item>
  };
  
  const handleOpenChange = async flag => {
    setClickTriggetColor(!clickTriggetColor);
    const allComponentCols = getColFields?.[0]?.fields || {};
    if (!flag && colUpdated) {
      allComponentCols[callFrom ] = [...customCol];
      await setColField({ fields: allComponentCols });
      await loadColField();
    }
  
    setOpen(flag);
    setColUpdated(false);
  };
  
  const items: MenuProps['items'] = customCol ? customCol.map((item, index) => ({ key: item.id, label: filterOptions(item, index) })) : []
  return (
    <Dropdown
      menu={{ items }}
      onOpenChange={handleOpenChange}
      open={open}
      getPopupContainer={trigger => trigger.parentElement}
      overlayStyle={{ border: `1px solid ${color || 'blue'}` }}
    >
      <a onClick={e => e.preventDefault()}>
        <Tooltip title={`Columns Filter `}>
          <CustomSpanIcon>
            <FontAwesomeIcon icon={faFilter} style={{color: `${color}`}}/>
          </CustomSpanIcon>
        </Tooltip>
      </a>
    </Dropdown>

  )
})

export default CustomColumnSelect

export const DropDownMenuWrapper = styled.div`
    ul{
      border-radius: 8px;
      li{
        border: 1px solid #E3E3E3;
        margin: 13px 12px 13px 12px;
        width: 180px;
        border-radius: 8px;
        align-items: center;
        transition: all .4s ease-in;
        :hover{
          color: black;
          span{
            color: black;
          }
        }
      }
    }
    .ant-dropdown-menu-title-content{
      display: flex;
      align-items: center;
    }
`