
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { SearchOutlined } from '@ant-design/icons'
import SearchFilter from '@commonComponents/SearchFilter'
interface SearchLoanProps {
  setQueryParam: (data: object) => void;
}
const SearchLoan = observer((props: SearchLoanProps) => {
  return (
    <div className='row' >
      <SearchFilter
        {...props}
        children={
          <Row gutter={2} className={`rowDiv gap-lg-0  gap-xl-0 gap-xxl-2 flex-grow-1`}>
            <Col xs={24} md={12} sm={12} lg={5}>
              <Form.Item name={'employee_name'}>
                <CommonInput
                  placeholder='Employee Name'
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
            </Col>
            <Col className='SearchClient' xs={24} sm={12} md={12} lg={5}>
              <Form.Item name={'loan_name'}>
                <CommonInput
                  className='form-control inputField'
                  placeholder='Loan Name'
                />
              </Form.Item>
            </Col>
          </Row>
        } />
    </div>
  )
})
export default SearchLoan


