import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader';
import { Select } from 'antd'
import React, { useRef } from 'react';
import { ReactNode } from 'react'
import styled from 'styled-components'
import '../../../assets/styles/colors.scss'
import { MdCheckCircle, MdBlockFlipped } from 'react-icons/md'
import { AiFillClockCircle } from 'react-icons/ai'
import { PlusOutlined } from '@ant-design/icons';
import { colors } from 'constant/colors';

const { WhiteSmoke } = colors

const { Option } = Select
export interface SelectDropdown {
  allowClear?: boolean;
  autoClearSearchValue?: boolean;
  autoFocus?: boolean;
  bordered?: boolean;
  defaultActiveFirstOption?: boolean;
  defaultOpen?: boolean;
  defaultValue?: string | string[];
  disabled?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  dropdownStyle?: any;
  dropdownClassName?: string;
  dropdownMatchSelectWidth?: boolean | number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  dropdownRender?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  filterOption?: any;
  filterSort?: (optionA, optionB) => number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  getPopupContainer?: any;
  labelInValue?: boolean;
  listHeight?: number;
  loading?: boolean;
  maxTagCount?: number;
  options?: any;
  placeholder?: string;
  removeIcon?: ReactNode;
  searchValue?: string;
  showArrow?: boolean;
  showSearch?: boolean;
  size?: 'small' | 'large' | 'middle';
  status?: 'error' | 'warning';
  onBlur?: (event) => void;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onChange?: (event?: any) => void;
  onDeselect?: (event) => void;
  onDropdownVisibleChange?: (event) => void;
  onFocus?: () => void;
  onInputKeyDown?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onPopupScroll?: () => void;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSearch?: any;
  onSelect?: () => void;
  className?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any;
  optionFilterProp?: string;
  mode?: 'multiple' | 'tags';
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onLoadMore?: any;
  more?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  value?: any;
  showIcon?: boolean;
  iconClassName?: string;
  loader?: boolean;
  leaveApproval?: boolean;
  calledFrom?:string;
  rowStatus?:string;
  callFrom?: any;
}

export const CommonSelect: React.FC<SelectDropdown> = (
  props: SelectDropdown
) => {

  const input = useRef(null);

  return (
    <CustomSelect>
      <SelectField
        {...props}
        dropdownStyle={props.callFrom}
        ref={input}
        placeholder={props.placeholder}
        value={props.value && props.value}
        onSelect={() => input.current?.blur()}
        getPopupContainer={(trigger: { parentNode }) => trigger.parentNode}
        disable={props.disabled}
        status={props.value}
        leaveApproval={props?.leaveApproval}
      >
        {props?.data?.map(e => (
          e.value !== 'undefined' ?
            <StyledOption value={e.key} status={e.value}>
              {e.value}
            </StyledOption> : ''
        ))}
        {!props?.loader && props.more && (
          <LoadMore disabled={true} style={{cursor:'pointer'}}>
            <div onClick={props.onLoadMore}>
              <PlusOutlined /> Load More{' '}
            </div>
          </LoadMore>
        )}
        {props?.loader && (
          <Loader disabled={true}>
            <SkeletonRingLoader width='50px' height='50px' align='center' />
          </Loader>
        )}
      </SelectField>
    </CustomSelect>
  )
}


export const StatusDropdown: React.FC<SelectDropdown> = (
  props: SelectDropdown
) => {


  const data = props.leaveApproval
    ? [
      { key: 'Approved', value: <span style={{color:'#16d61e'}}>
        Approved</span>, icon: <MdCheckCircle color='#16d61e' /> },
      { key: 'Rejected', value: 'Rejected', icon: <MdBlockFlipped color='#ef0f0f'/> },
    ]
    : [
      { key: 'approved', value: <span style={{color:'#16d61e'}}>
        Approved</span>, icon: <MdCheckCircle color='#16d61e'/> },
      { key: 'rejected', value: <span style={{color:'#ef0f0f'}}>
        Rejected</span>, icon: <MdBlockFlipped color='#ef0f0f' /> },
      {
        key: 'pending',
        value: <span style={{color:'#d7e90d'}}>Pending</span>,
        icon: <AiFillClockCircle color='#d7e90d'  />,
      },
    ]
  const input = useRef(null);

  return (
    <CustomSelect
      calledFrom={props.calledFrom}
      rowStatus={props.rowStatus}
    >
      <SelectField
        {...props}
        ref={input}
        placeholder={props.placeholder}
        value={props.value && props.value}
        onSelect={() => input.current?.blur()}
        getPopupContainer={trigger => trigger.parentNode}
        filterOption={(input, option) =>
          option?.children
            ?.toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        disable={props.disabled}
        status={props.value}
        leaveApproval={props?.leaveApproval}
      >
        {data.map(e => (
          e.value !== 'undefined' ?
            <StyledOption value={e.key} status={e.value}>
              <StyledIconWrapper name={e.key}>
                {e.icon}
              </StyledIconWrapper> {e.value}
            </StyledOption> : ''
        ))}
        {!props?.loader && props.more && (
          <LoadMore disabled={true}>
            <div onClick={props.onLoadMore}>
              <i className='fa fa-plus' /> Load More{' '}
            </div>
          </LoadMore>
        )}
        {props?.loader && (
          <Loader disabled={true}>
            <SkeletonRingLoader width='50px' height='50px' align='center' />
          </Loader>
        )}
      </SelectField>
    </CustomSelect>
  )
}

const CustomSelect = styled.div`
  display: block;
  width: 100%;
  position: relative;

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${(props => props.calledFrom=='resignation' && props.rowStatus=='Approved') && WhiteSmoke}!important   
  }

  .ant-select-selector{
    border-radius: 4px !important;
    font-size: 14px;
    font-weight: 400 !important;
    padding: 0px 10px 0 10px !important;
    transition: 0.3s all ease-in-out;
    border-color: $color-light-gray !important;
    height: 45px !important;
    width: 100%;
    
    .ant-select-selection-search-input{
      cursor: text !important;
      height: 43px !important;
    }

    &:hover {
      border-color: #3576d9 !important;
      outline: none !important;
      transition: 0.3s all ease-in-out;
    }

    &:focus,
    &:active,
    &:visited {
      border-color: #3576d9 !important;
      border-right-width: 1px !important;
      box-shadow: $color-shadow-hover !important;
      outline: 0 !important;
    }

    .ant-select-dropdown-hidden{
      overflow-y: auto !important;
    }
  }
  .ant-select-selection-placeholder{
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
	}
  .ant-select-selector{
    &:hover {
      border-color: #3576d9 !important;
    }
  }

  .ant-select-focused{
    .ant-select-selector{
      border-color: #3576d9 !important;
      border-right-width: 1px !important;
      box-shadow: $color-shadow-hover !important;
      outline: 0 !important;
    }
  }
  .ant-select-item-option-content{
    font-weight: lighter;
    font-family: 'poppins'
  }
  .ant-select-disabled{
    .ant-select-selection-item{
      cursor: not-allowed;
    }
  }


.ant-select {
	display: block;
	width: 100%;

	.ant-select-selection-item {
		font-size: 14px;
		align-items: center;
		display: flex;
    line-height: 40px;
	}

	.ant-select-selector {
		border-radius: 8px !important;

		padding: 0px 10px 0 10px !important;
		transition: 0.3s all ease-in-out;
		border-color: $color-light-gray !important;
		width: 100%;

		&:hover {
			border-color: #3576d9 !important;
			outline: none !important;
			transition: 0.3s all ease-in-out;
		}

		&:focus,
		&:active,
		&:visited {
			border-color: #3576d9 !important;
			border-right-width: 1px !important;
			box-shadow: $color-shadow-hover !important;
			outline: 0 !important;
		}

		.ant-select-dropdown-hidden {
			overflow-y: auto !important;
		}
		input{
			font-size: 14px;
		}
	}

	.ant-select-selecton {
		&:hover {
			border-color: #3576d9 !important;
			cursor: pointer;
		}
	}
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
	.ant-select-selector {
		border-color: #3576d9 !important;
		border-right-width: 1px !important;
		box-shadow: $color-shadow-hover !important;
		outline: 0 !important;
	}
}
`
const SelectField = styled(Select)`
${props => props.disable ? '.ant-select-selector{cursor: not-allowed !important;} ' : '.ant-select-selector{cursor: pointer !important;}'}
${props => props.mode == 'multiple' ? '.ant-select-selector input{margin-left:-7px !important}' : ''}

.ant-select-selector {
  background-color: ${props => (props.status == 'Approved' && props.leaveApproval) ? '#9ce9a0 !important;' : (props.status == 'Pending' && props.leaveApproval) ? '#f3f8b6 !important;' :
    (props.status == 'Rejected' && props.leaveApproval) ? '#f49eA0 !important;' : (props.status == 'Forward' && props.leaveApproval) ? '#C6FFFB !important;' : 'white !important;'};
  color: ${props => (props.status == 'Approved' && props.leaveApproval) ? '#369637 !important' : (props.status == 'Pending' && props.leaveApproval) ? 'black !important' :
    (props.status == 'Rejected' && props.leaveApproval) ? '#c31A1A !important;' : (props.status == 'Forward' && props.leaveApproval) ? '#496B6A !important;' : 'black !important;'}
}
`
const StyledOption = styled(Option)`
font-family: 'poppins';
font-weight: lighter;
`
const LoadMore = styled(Option)`
pointer-events: all !important;
cursor: pointer !important;
color: $color-cyan-primary !important;
text-align: center;
`
const Loader = styled(Option)`
margin: auto;
color: $color-cyan-primary;
`
export const StyledIconWrapper = styled.span`
  svg{
  color: ${props => props.name == 'Approved' ? '#16d61e' : props.name == 'Forward' ? '#387be0' : props.name == 'Rejected' ? '#ef0f0f' : props.forwardIcon ? '#ffbc34' : ''};
  margin: 0px 5px -2px 0px;
  font-size: 15px;
  }
`