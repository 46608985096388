import { Form, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import SearchFilter from '@commonComponents/SearchFilter'
import { SearchOutlined } from '@ant-design/icons'

interface BranchFormProps{
    setQueryParam: (data: any) => void;
}
const SearchBranch = observer((props: BranchFormProps) => {
    return (
        <div className='row filter-row'>
            <SearchFilter
                {...props}
                children={
                <div className={`d-lg-flex w-100`}>
                    <Row gutter={10} className='w-100'>
                        <Col xs={24} sm={8} lg={6}>
                            <Form.Item name='branch_name'>
                                <CommonInput
                                    className='inputField'
                                    placeholder='Branch Name'
                                    prefix={<SearchOutlined />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>} 
            />
        </div>
    )
})
export default SearchBranch;