import DisplayPicture from '@commonComponents/avatar'
import { IconWrapper } from '@mainPage/expenses/expense'
import {
  convertTimeFormat,
  formatMinutesAndSeconds,
} from '@utils/common-functions'
import Tooltip from 'antd/es/tooltip'
import moment from 'moment/moment'
import { MdRemoveRedEye } from 'react-icons/md'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { checkUUID } from 'utils/common-functions'


export const attendanceColumns = showDrawer => [
  {
    title: ' Employee Name',
    render: (_, record) => (
      <div className={`table-avatar d-flex align-items-center`}>
        <DisplayPicture
          style={{
            backgroundColor: 'white',
            color: 'black',
            border: `${record.employeeImage ? '' : '2px solid black'}`,
          }}
          size={35}
          src={record.employeeImage}
          name={record.fullName}
        />
        <div className='textHandle'>
          <Link
            to={
              checkUUID(record?.employeeId)
                ? `/app/newpage/${record?.employeeId}`
                : checkUUID(record?.employee)
                  ? `/app/newpage/${record?.employee}`
                  : `/app/newpage/${record?.id}`
            }
          >
            <Tooltip title={record.fullName}>
              <span>{record.fullName}</span>
              <div className='subTitle'>{record.designation}</div>
            </Tooltip>
          </Link>

        </div>
      </div>
    )
  },
  {
    title: 'Date',
    dataIndex: 'today',
    render: date => (date ? moment(date).format('DD-MM-YYYY') : '-'),
  },
  {
    title: 'Check In',
    render: (_, record) => (
      <Pwrapper>{convertTimeFormat(record.firstCheckIn, 'add') ?? '-'}</Pwrapper>
    ),
  },
  {
    title: 'Check Out',
    render: (_, record) => (
      <Pwrapper>{convertTimeFormat(record.lastCheckOut, 'add') ?? '-'}</Pwrapper>
    ),
  },
  {
    title: 'Total Time Spent',
    render: (_, row) => {
      return <div>{formatMinutesAndSeconds(row?.totalTimeSpent, row?.lastStatus) ?? '-'}</div>
    },
  },
  {
    title: 'Overtime',
    render: (_, row) => {
      return (
        <p className={row.totalHours > 7 ? 'overTime' : ''}>
          {formatMinutesAndSeconds(row?.overTime) ?? '-'}
        </p>
      )
    },
  },
  {
    title: 'Work Report',
    render: (_, record) => {
      return (
        <Tooltip title='Work Report'>
          {record.checkoutDetail?.[record?.checkoutDetail?.length - 1]
            ?.reason ? (
            <IconWrapper
              className='work-report-icon'
              RedEye
              onClick={() => showDrawer(record)}
            >
              <MdRemoveRedEye />
            </IconWrapper>
          ) : (
            <IconWrapper onClick={() => showDrawer(record)}>
              <MdRemoveRedEye />
            </IconWrapper>
          )}
        </Tooltip>
      )
    },
  },
]

const Pwrapper = styled.div`
  img {
    height: 23px !important;
  }
  min-width: 130px !important;
  display: flex;
  justify-content: space-between;
`
