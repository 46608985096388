import { BaseApi } from '../baseApi'
import {AllowanceUrl} from '../const'

class AllowancesApi extends BaseApi {
  getAllowance = async (num ,pageSize, queryParam) => {
    try {
      const response = await this.axios.get(
        `${AllowanceUrl}?page=${num ? num :1}&page_size=${pageSize}`,{
          params: queryParam ? {...queryParam} : {},
        })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  createAllowance = async data => {
    try {
      const response = await this.axios.post(AllowanceUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateAllowance = async (data, id) => {
    try {
      const response = await this.axios.put(`${AllowanceUrl}${id}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteAllowance = async id => {
    try {
      const response = await this.axios.delete(`${AllowanceUrl}${id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default AllowancesApi
