import React, { useState, useEffect, useRef } from 'react'
import Tooltip from '@commonComponents/CustomToolTip'
import { Switch } from 'antd'
import { Table } from '@commonComponents/table-v2'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import {CheckOutlined, CloseOutlined,LoadingOutlined} from '@ant-design/icons'
import DisplayPicture from '@commonComponents/avatar'
import { permit } from '@utils/permission/permission'
import { clientUrl } from '@api/const'
import { MdDelete, MdEdit } from 'react-icons/md'
import styled from 'styled-components'


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const ClientTable = observer(
  ({
    openClient,
    deletClientData,
    refreshTable,
    setRefreshTable,
    queryParam,
    resetTable,
    setResetTable,
    setTotalRecordRemaining,
    setColumns,
    selectedCol
  }: any) => {
    const [currentData, setCurrentData] = useState(null)
    const [page, setPage] = useState(null)
    const tableRef = useRef(null)
    const [statusLoader, setStatusLoader] = useState(false)

    const {
      clientDetails: { updateClientDetails },
      employeeRole: { loadEmployeeRole }, //getEmployeeRoleData jb cHAHIYE HO UNCOMMENT KR LENA
    } = useStore(null)
    const {
      userInfo: { getcompany },
    } = useStore(null)

    useEffect(() => {
      async function load() {
        await loadEmployeeRole(true)
      }
      load()
    }, [])

    const onStatusChange = async (id, value) => {
      let response = null
      const today = new Date().toJSON().slice(0, 10)
      setCurrentData({ id: id })
      setStatusLoader(true)
      const formData = new FormData()
      formData.append('status', value)
      if (!value) {
        formData.append('leaving_date', today)
      }
      response = await updateClientDetails(formData, id)
      if (response?.success) {
        setRefreshTable(true)
        setStatusLoader(false)
      }
    }

    const columns = [
      {
        title: 'Name',
        id:1,
        render: (text, record) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <DisplayPicture
              style={{
                border: !record.clientImage ? '2px solid black' : '',
                backgroundColor: 'white',
                color: 'black',
              }}
              size={35}
              src={record.clientImage}
              name={record?.firstName}
            />

            <Tooltip title={record.firstName + ' ' + (record.lastName || '')}>
              {record?.firstName + ' ' + (record?.lastName || '')}
            </Tooltip>
          </div>
        ),
      },
      {
        title: 'ID',
        dataIndex: 'clientId',
        id:2
      },
      {
        title: 'Project Name',
        dataIndex: 'lead',
        id:3
      },
      {
        title: 'Lead',
        dataIndex: 'clientOwner',
        id:4,
        render: text => (
          <Tooltip title={text?.firstName + ' ' + (text?.lastName || '')}>
            {text ? text?.firstName + ' ' + (text?.lastName || '') : '-'}
          </Tooltip>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '129px',
        id:5,
        render: email => (
          <Tooltip placement='topLeft' title={email}>
            <div style={{ minWidth: '10px' }}>
              <Pwrapper>{email.slice(0, 100)}</Pwrapper>
            </div>
          </Tooltip>
        ),
      },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        id:6,
        render: text => <div>{text ? '+' + text : '-'}</div>,
      },
      {
        title: 'Client Role',
        dataIndex: 'rolename',
        id:7,
        render: (text, record) => (
          <div>
              <div>{record?.roleName}</div>
          </div>
        ),
      },
      {
        title:<p style={{textAlign: 'center'}}>Action</p> ,           
        id:9,
        render: (text, row) => {
          return (

          
            <ActionDiv className='d-flex gap-1'>
              <Loaderflex className='selectTable'>
                <StyledSwitch
                  checked={row?.status}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}

                  defaultChecked
                  onClick={value => onStatusChange(row.id, value)}
                  disabled={!permit(getcompany, 'employee', 'edit')}
                />
                <span style={{ marginLeft: '10px' }}>
                  {row.id === currentData?.id && statusLoader && (
                    <LoadingOutlined />
                  )}
                </span>
              </Loaderflex>
              <div className='d-flex accordingToTheme'>
            <div className='actionIcons'>
              <Tooltip title='Edit'>
                <MdEdit onClick={() => openClient(row)} />
              </Tooltip>
            </div>
            <div className='actionIcons'>
            <Tooltip title='Delete'>
               <MdDelete onClick={() => deletClientData(row)} />
            </Tooltip>
            </div>
          </div>
            </ActionDiv>
          )
        },
      },
    ]

    useEffect(()=>{
      setColumns(columns)
    },[])
    

    return (
      <>
  <div className='table-responsive'>

        <Table
          ref={tableRef}
          dataUrl={clientUrl}
          columns={selectedCol?.length ? selectedCol : columns}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
          setPageNumber={() => setPage}
          resetTable={resetTable}
          setResetTable={setResetTable}
          setTotalRecordRemaining={setTotalRecordRemaining}
        />
        </div>
        <div style={{ display: 'none' }}>{page}</div>
      </>
    )
  }
)

export default ClientTable

const Pwrapper = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 135px;
  display: block;
`
const ActionDiv = styled.div`
.ant-switch{
  .ant-switch-inner{
    .switchIcons{
      svg{
        width: 12px !important;
        height: 12px !important;
      }
      color: white;
      }
  }
}
    svg {
    width: 20px;
    height: 20px;
  }
  
`
const Loaderflex = styled.div`
display:flex;
gap:6px;
 .ant-switch{
  margin-top: 10px !important;
}
`
const StyledSwitch = styled(Switch)`
.ant-switch-inner{
  span{
    svg{
      color: white !important;
      width: 12px !important; 
      height: 12px !important; 
      margin-bottom: 1px !important; 
    }
  }
}
`