import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl, employeeServiceUrl } from '../const/index'

class EmployeeLeaveApprovalApi extends BaseApi {
  setEmployeeLeaveApproval = async leaveId => {
    try {
      const response = await this.axios.post(`${employeeServiceUrl}/leave/leave-approval/${leaveId}/leave`) 
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getEmployeeLeaveApproval = async (searchParams, num , size)=> {
    try {
      const response = await this.axios.get(
        `${baseUrl}approval_leave${searchParams}?page=${num ? num : 1}&page_size=${size ? size  :10}`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateEmployeeLeaveApproval = async id => {
    try {
      const url = `${employeeServiceUrl}leave/leave-approval/${id}/leave`
      const response = await this.axios.put(url)
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteEmployeeLeaveApproval = async id => {
    try {
      const response = await this.axios.delete(`${baseUrl}approval_leave/${id}/`, headerAuthorization(this))
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  employeeLeaveRejection = async (id, payload) => {
    try {
      const response = await this.axios.put(`${employeeServiceUrl}leave/leave-reject/${id}/leave`, payload)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

}

export default EmployeeLeaveApprovalApi
