import { UserLoginDataApi } from '@api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { flow, types } from 'mobx-state-tree'
import {
  setTokenIntoCookies
} from '../../api/common-utils'

const info = types.model({
  userName: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
})

export const signinInfo = types
  .model({
    userInfo: types.array(info),
    erorMessage: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const addInfo = flow(function* fetchData(info) {
      try {
        self.loading = true;
        const res = yield UserLoginDataApi.loginUser({
          email: info.email,
          password: info.password,
        })
        setTokenIntoCookies(res.access, res.refresh)
        window.location.pathname = '/'
        return res
      } catch (error) {
        notification.error(
          error.response.data.message ? 'Invalid Email or Password' : ''
        )
        self.erorMessage = error.response.data.message
      } finally {
        self.loading = false
      }
    })
    return { addInfo }
  })

export function initSigninInfo() {
  return signinInfo.create({
    userInfo: [],
  })
}
