import { ECANTEENBILL, SALARY_TITLE, ASSETSDETAILS } from './constant'
import { ACTION, STATUS, ACTION_TITLE, STATUS_TITLE, CLEARANCE_MOD, PATCH_METHOD } from 'constant/constant'
import showColData from '@commonComponents/show-col-data/showColData'
import { presentAbsent } from './utils'
import Tooltip from '@commonComponents/CustomToolTip'
import { MdEdit } from 'react-icons/md'
import employeeName from '@utils/employee_name'
import { humanize } from 'components/utils/string_formate'
import ShowOnPermission from '@commonComponents/permit'
import { statusIcons } from '@utils/common-functions'
export const getColumns = onEdit => {
  return (

    [
      ...([employeeName('setRecord', 'setVisible', 'clearance')]),
      {
        title: ASSETSDETAILS,
        dataIndex: 'assetStatus',
        render: data => presentAbsent(data)
      },
      {
        title: ECANTEENBILL,
        dataIndex: 'eCanteenStatus',
        render: data => presentAbsent(data)
      },
      {
        title: 'Loan',
        dataIndex: 'loanStatus',
        render: data => presentAbsent(data)
      },
      {
        title: SALARY_TITLE,
        dataIndex: 'salaryStatus',
        render: data => presentAbsent(data)
      },
      {
        title: 'Reason To Leave',
        dataIndex: 'reason',
        render: showColData
      },

      {
        title: STATUS_TITLE,
        dataIndex: STATUS,
        render: status => (
          <div className={`${status}Icon`}>
            {statusIcons[status]}{humanize(status)}
          </div>
        ),
      },
      {
        title:ACTION_TITLE,
        dataIndex: ACTION,
        render: (_, row) => (
          <ShowOnPermission mod={CLEARANCE_MOD} method={PATCH_METHOD}>
            {row?.status !== 'approved' && 
              <div className='actionIcons accordingToTheme'>
                <Tooltip title='Edit' minLength={0}>
                  <MdEdit id='clearance_edit' onClick={() => onEdit(row)} style={{ cursor: 'pointer' }} />
                </Tooltip>
              </div>}
          </ShowOnPermission>
        )
      }
    ]
  )
}


