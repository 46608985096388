import { flow, types, cast } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { departmentApi } from '@api'
import { notification } from '@utils/notifications'

export const department = types.model({

  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  branch:types.maybeNull(types.string),
  parent_department:types.maybeNull(types.string),
  description:types.maybeNull(types.string),
  branch_name:types.maybeNull(types.string),
})

export const departmentModal = types
  .model({
    data: types.maybeNull(types.array(department)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextDep: types.optional(types.number, 1),
  })
  .views(self => ({
    get getDepartmentData() {
      return toJS(self.data)
    },
  
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setDepartmentData = flow(function* (data) {
      try {
        self.loading = true
        yield departmentApi.setDepartment(data)
        notification.success('Department Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {   
        self.loading = false
      }
    })
    const loadDepartment = flow(function* (page=1, pageSize=20, queryParam='', resetList=false, saveInStore=true) {
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextDep = 1
        }
        const res = yield departmentApi.getDepartment(page , pageSize, queryParam)
        if (!res?.isCancel && saveInStore){
          self.data = cast(res.items)
          self.count = res.count
          if (res.next) {
            self.nextDep = self.nextDep + 1
          } else {
            self.nextDep = 1
          }
        } else if(!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Department')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updateDepartment = flow(function* (id, data) {
      try {
        self.loading = true
        yield departmentApi.updateDepartment(id, data)
        notification.success('Department Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteDepartment = flow(function* (id) {
      try {
        self.loading = true
        yield departmentApi.deleteDepartment(id)
        notification.success('Department Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error("You can't delete this department")
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setDepartmentData,
      loadDepartment,
      deleteDepartment,
      updateDepartment,
    }
  })

export function initDepartmentModal() {
  return departmentModal.create({})
}
