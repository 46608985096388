import CustomButton from '@commonComponents/button'
import { CommonInput } from '@commonComponents/input'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import { Col, Form, Row} from 'antd'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import style from './style.module.scss'
import styled from 'styled-components'
import topIcon from '../../assets/img/topicon.svg'
import bottomicons from '../../assets/img/bottomicons.svg'
import enigmatixlogo from '../../assets/img/enigmatixlogo.svg'
import logoblur from '../../assets/img/logoblur.svg'
import { MdMail, MdArrowBack} from 'react-icons/md';
import { colors } from 'constant/colors'

const { red } = colors

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

const ForgotPassword = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [load, setLoad] = useState(false)

  const {
    emailModal: { setEmail },
  } = useStore(null)

  const submitData = async (data: { email: string }) => {
    setLoad(true)
    const res = await setEmail(data)
    setLoad(false)
    if (res?.success) {
      navigate(`${constRoute.confirmPassword}`)
    }
  }

  return (
    <DivWrapper>
      <div className={style.SignIn}>
        <Row>
          <Col xs={24} md={12} lg={12}>
            <div className={style.polygon}>
              <div className={style.backTologin}>
                <Link to={`${constRoute.login}`} className={style.backArrow}>
                  <MdArrowBack className={style.backicon} />
                </Link>
                <span>Back to logIn</span>
              </div>
              <div className={style.signInform}>
                <h1>Forgot Password?</h1>
                <p className='mb-4'>Enter your email address below to reset your password</p>
                <Form
                  layout='vertical'
                  className={style.signinForm}
                  form={form}
                  onFinish={submitData}
                  validateMessages={validateMessages}
                >
                  <Form.Item
                    label='Email'
                    className={style.commonInputField}
                    name='email'
                    validateTrigger={['onBlur']}
                    rules={[{ type: 'email', message: 'Email is not valid' },
                      { required: true, message: 'Email is required' }]}
                  >
                    <CommonInput
                      prefix={<MdMail className={style.MailOutlined} style={{marginBottom : '13px'}}/>}
                      inputType='text'
                      placeholder='example@test.com'
                      onFocus={() => form.setFields([{ name: 'email', errors: [] }])}
                    />
                  </Form.Item>
                  <Form.Item className={style.signinButton}>
                    <CustomButton htmlType='submit' loading={load} className='d-block w-100' title='CONTINUE'/>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className={style.barnd}>
              <div className={style.oms}>
                <img className={style.topIcon} src={topIcon} alt={topIcon} />
                <img className={style.logoblur} src={logoblur} alt={logoblur} />
              </div>
              <div><img className={style.bottomicons} src={bottomicons} alt={bottomicons} /></div>
              <div><img className={style.enigmatixlogo} src={enigmatixlogo} alt={enigmatixlogo} /></div>
            </div>
          </Col>
        </Row>
      </div>
    </DivWrapper>
  )
}

export default ForgotPassword

const DivWrapper = styled.div`

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none;
	visibility: hidden;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
	display: inline-block;
	margin-right: 4px;
	color: ${red};
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "*";
	position: absolute;
	right: -15px;
}
`