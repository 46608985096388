import { cast, flow, types } from 'mobx-state-tree'
import { employeesOpenListApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'



export const employeeInfo = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  mobileNumber: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
  employeeID: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  designation: types.maybeNull(types.number),
  designationName: types.maybeNull(types.string),
  joiningDate: types.maybeNull(types.string),
  leavingDate: types.maybeNull(types.string),
  status: types.maybeNull(types.boolean),
  supervisor: types.maybeNull(types.string),
  supervisorFirstName: types.maybeNull(types.string),
  supervisorLastName: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
  roleName: types.maybeNull(types.string),
  createUser: types.maybeNull(types.boolean),
  branchName: types.maybeNull(types.string),
  branch: types.maybeNull(types.number),
  company: types.maybeNull(types.number),
  isAdmin: types.maybeNull(types.boolean),
  isLead: types.maybeNull(types.boolean),
  increment: types.maybeNull(types.number),
  averageRating: types.maybeNull(types.number),
  employmentCompany: types.maybeNull(types.string),
  employmentCompanyName: types.maybeNull(types.string),
})




export const employeesOpenList = types
  .model({
    employeeInfo: types.maybeNull(types.array(employeeInfo)),
    loading: types.optional(types.boolean, false),
    countEmp: types.maybeNull(types.number),
    nextEmp: types.optional(types.number, 1),
    erorMessage: types.maybeNull(types.string),
    filter: types.maybeNull(types.string),
  })
  .views(self => ({
    get errorMessage() {
      return toJS(self.erorMessage)
    },
    get getEmployeesOpenList() {
      return toJS(self.employeeInfo)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {

    const loadEmployeesOpenList = flow(function* (
      resetList = false,
      saveInStore = true
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.employeeInfo = cast([])
          self.nextEmp = 1
        }
        const res = yield employeesOpenListApi.getEmployeesList()
        if (!res?.isCancel && saveInStore) {
          self.employeeInfo = cast([...toJS(self.employeeInfo ?? []), ...res])
          self.countEmp = res.count
          if (res.next) {
            self.nextEmp = self.nextEmp + 1
          } else {
            self.nextEmp = 1
          }
        } else if (!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if (message) {
          notification.error(message)
        } else {
          notification.error('Failed to Load Employee')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })


    return {
      loadEmployeesOpenList,
    }
  })

export function initEmployeesOpenList() {
  return employeesOpenList.create({})
}
