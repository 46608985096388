import { useStore } from '@stores/root-store'
import { isCurrentUsersId } from '@utils/common-functions'
import { Avatar, Card, Image, Rate, Skeleton, Tooltip } from 'antd'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { MdLocationOn, MdMail, MdOpacity, MdPerson, MdPhone } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import imageFallback from '@assets/img/fallback-image.png'
import { permit } from '@utils/permission/permission'
import { EMPLOYEE_MOD, VIEW_ALL } from 'constant/constant'

const EmployeeIntro = observer(({profileRecord}) => {
  const [isContactLoader, setIsContactLoader] = useState(true)
  const [loader, setLoader] = useState(true)
  const { id: employeeID } = useParams()
  const navigate = useNavigate()
  
  const isCurrentUser = isCurrentUsersId(employeeID)

  const {
    profileDetails: { loadProfileDetails, profileData },
    familyDetails: { familyData, loadFamilyDetails },
    userInfo: { getRoles },
  } = useStore(null)

  const isAdmin = permit(getRoles, 'accessView', 'is_admin')
  const canViewAlll = permit(getRoles,EMPLOYEE_MOD,VIEW_ALL);

  async function load() {
    const res = await loadProfileDetails(employeeID, isCurrentUser, isAdmin, canViewAlll, true)
    if (!res.success && res.statusCode === 403) {
      navigate('/')
    }
    setLoader(false)
    await loadFamilyDetails(employeeID)
    setIsContactLoader(false)
  }

  useEffect(() => {
    load()   
  }, [employeeID])
  profileRecord(profileData)
  return (
    <>
      <Card>
        <Skeleton active loading={loader} paragraph={{ rows: 5 }}>
          <div className='text-center'>
            <ProfileDiv>
              <div className={'circle'}>
                {profileData?.employeeImage ? (
                  <Image
                    width={142}
                    height={142}
                    src={profileData?.employeeImage} 
                    alt={'image'} 
                    preview={true} 
                    fallback={imageFallback}
                  />
                ) : (
                  <Avatar size={140} className={'imgTextSize'}>
                    {profileData?.firstName?.[0]?.toUpperCase()}
                  </Avatar>
                )}
              </div>
              <div className={'active'}></div>
            </ProfileDiv>
          </div>

          <div className='text-center mb-3'>
            <Tooltip title={profileData?.fullName}>
              <Empname> {profileData?.fullName}</Empname>
            </Tooltip>
            <DesigantionDiv>{profileData?.fullName ?? '-'}</DesigantionDiv>
          </div>
        </Skeleton>
        <Skeleton active loading={loader} paragraph={{ rows: 5 }}>
          <EmployeeDetals>
            <div>
              <ul>
                <li>
                  <div className={'textbold'}>Designation :</div>
                  <div className={'textNormal'}>
                    {profileData?.designation}
                  </div>
                </li>
                <li>
                  <div className={'textbold'}>Supervisor :</div>
                  <div className={'textNormal'}>{profileData?.supervisor || '-'}</div>
                </li>
                <li>
                  <div className={'textbold'}>Joining Date :</div>
                  <div className={'textNormal'}>
                    {profileData?.joiningDate
                      ? moment.parseZone(profileData?.joiningDate).format(
                          'MMM-DD-YYYY'
                        )
                      : 'N/A'}
                  </div>
                </li>
                <li>
                  <div className={'textbold'}>Employee ID :</div>
                  <div className={'textNormal'}>
                    {profileData?.employeeId || 'N/A'}
                  </div>
                </li>
                <li>
                  <div className={'textbold'}>Employee Role :</div>
                  <div className={'textNormal'}>{profileData?.roles?.[0]?.title || 'N/A'}</div>
                </li>
                <li>
                  <div className={'textbold'}>Shift :</div>
                  <div className={'textNormal'}>
                    {profileData?.shiftTitle || '-'}
                  </div>
                </li>
                <li>
                  <div className={'textbold'}>Rating :</div>
                  <div className={'textNormal'}>{profileData?.avgRating ? (
                    <Rate allowHalf disabled value={profileData?.avgRating} />
                  ) : 'N/A'}</div>
                </li>
              </ul>
            </div>
          </EmployeeDetals>
        </Skeleton>
        <Skeleton active loading={loader} paragraph={{ rows: 5 }}>
          <StyledDiv className={'mb-4'}>
            <div
              className={`${'antlistheader'} d-flex justify-content-between align-items-center}`}
            >
              <div className={'listTitle'}>Contact Info</div>
            </div>
            <ul className={'antlistitems'}>
              <li>
                <MdPhone />
                {profileData?.contactNumber || '-'}
              </li>
              <li>
                <MdMail />
                {profileData?.email || ' -'}
              </li>
              <li>
                <MdOpacity />
                {profileData?.bloodGroup || ' -'}
              </li>
              <li>
                <span>
                  <MdLocationOn />
                </span>
                {profileData?.address || '-'}
              </li>
            </ul>
          </StyledDiv>
        </Skeleton>
        <Skeleton active loading={isContactLoader} paragraph={{ rows: 5 }}>
          <StyledDiv className={'mb-4'}>
            <div
              className={`${'antlistheader'} d-flex justify-content-between align-items-center}`}
            >
              <div className={'listTitle'}>Family Info</div>
            </div>
            <ul className={'antlistitems'}>
                  <li>
                    <MdPerson />
                    <span className='text-capitalize'>
                      {familyData?.[0]?.relation}: {familyData?.[0]?.name || '-'}
                    </span>
                  </li>
                  <li>
                    <MdPhone />
                    <span> + {familyData?.[0]?.phoneNumber1 || '-'}</span>
                  </li>
                </ul>
          </StyledDiv>
        </Skeleton>
      </Card>
    </>
  )
})

export default EmployeeIntro

const DesigantionDiv = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #525353;
`

const ProfileDiv = styled.div`
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
  .circle {
    width: 142px;
    height: 142px;
    border: 1px solid #e3e3e3;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
  }
  .ant-image {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
  }
  .imgTextSize {
    font-size: 40px !important;
  }
  .active {
    width: 16px;
    height: 16px;
    background: #55ce63;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    position: absolute;
    right: 14px;
    bottom: 12px;
  }
`
const Empname = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #010101;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const EmployeeDetals = styled.div`
  margin-bottom: 30px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .textbold {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #010101;
      }
      .textNormal {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #525353;
      }
    }
  }

  .ant-rate {
    display: flex !important;

    > *:not(.ant-rate-star-first):not(.ant-rate-star-second) {
      color: inherit !important;
      margin-bottom: 0 !important;
    }

    *:not(.ant-rate-star-first):not(.ant-rate-star-second) {
      color: inherit !important;
      margin-bottom: 0 !important;
    }
  }
`
const StyledDiv = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 11px;
  padding: 0 15px;
  .antlistheader {
    padding: 15px 0;
    border-bottom: 1px solid #e3e3e3;
    .listTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #010101;
    }
    .edit {
      svg {
        fill: currentColor;

        cursor: pointer;
        font-size: 16px;
      }
    }
  }
  .antlistitems {
    list-style: none;
    padding: 0;
    li {
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #525353;
      display: flex;
      align-items: center;
      word-break: break-word;
      &:last-child {
        border: none;
      }
      svg {
        fill: currentColor;
        font-size: 16px;
        margin-right: 10px;
        line-height: 21px;
      }
      span {
        position: relative;
        top: 2px;
      }
    }
  }
`
