import React, { useState, useRef } from 'react';
import { Switch, Row, Col, Space } from 'antd';
import Tooltip from '@commonComponents/CustomToolTip';
import { observer } from 'mobx-react';
import { useStore } from '@stores/root-store';
import DeletePopUp from '@commonComponents/delete-popup';
import { LoadingOutlined } from '@ant-design/icons';
import BreadCrumbs from '@commonComponents/breadcrums';
import { constRoute } from '@utils/route';
import SectionForm from './sectionForm';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Table } from '@commonComponents/table-v2';
import { permit } from '@utils/permission/permission';
import CustomButton from '@commonComponents/button';
import { sectionUrl } from '@api/const';
import SearchSection from './SearchSection';
import { MdDelete, MdEdit } from 'react-icons/md';
import CustomDrawer from '@commonComponents/drawer';
import { ACTIONS, ADD_SECTION, DEFAULT_COLOR, DELETE_METHOD, PATCH_METHOD, SECTION_MOD, UPDATE_SECTION } from 'constant/constant';
import styled from 'styled-components';
import { ActionCenter, Actions } from '@mainPage/Designation/columns';
import ShowOnPermission from '@commonComponents/permit';

const Section = observer(() => {

  const [currentData, setCurrentData] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [visible, setVisible] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [resetTable, setResetTable] = useState(false)
  const [statusLoader, setDropDownLoader] = useState(false)
  const [deleteName, setDeleteName] = useState('')
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const [queryParam, setQueryParam] = useState({})
  const tableRef = useRef(null)

  const {
    sectionModal: {
      deleteSection,
      updateSection,
      isLoading,
    },
    userInfo: { getRoles },
    themesModel: { getColorsData }
  } = useStore(null)

  const onStatusChange = async (id, value) => {
    let response = null
    setCurrentData({ id: id })
    setIsUpdate(false)
    setDropDownLoader(true)
    response = await updateSection(id, { is_active: value })
    if (response?.success) {
      setRefreshTable(true)
      setDropDownLoader(false)
    }
  }
  const onAdd = () => {
    setIsUpdate(false)
    setVisible(true)
  }

  const onEdit = data => {
    setCurrentData(data)
    setIsUpdate(true)
    setVisible(true)
  }

  const onDelete = data => {
    setCurrentId(data?.id)
    setDeleteName(data?.sectionName)
    setVisibleDelete(true)
  }

  const deleteSectionHandler = async () => {
    const res = await deleteSection(currentId)
    if (res.success) {
      setVisibleDelete(false)
      setCurrentId('')
      if (totalRecordRemaining == 1) {
        setResetTable(true)
      } else {
        setRefreshTable(true)
      }
      setDeleteName('')

    }
  }
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: text => (
        <Tooltip title={text}>
          <div style={{ width: 'fit-content' }}>{text ? text : '-'}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Department',
      dataIndex: 'departmentName',
      render: text => (
        <Tooltip title={text}>
          <div style={{ width: 'fit-content' }}>{text ? text : '-'}</div>
        </Tooltip>
      ),
    },
    {
      title: 'branch Name',
      dataIndex: 'branchName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },

    {
      width: 100,
      title: <ActionCenter>{ACTIONS}</ActionCenter>,
      render: (text, row) => (
        <ActionCenter>
          <Loaderflex>
            <ShowOnPermission mod={SECTION_MOD} method={PATCH_METHOD}>
              <Tooltip minLength={0} title={text.isActive ? 'InActive' : 'Active'}>
                <StyledSwitch
                  checked={text.isActive}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onClick={id => onStatusChange(text.id, id)}
                  disabled={!permit(getRoles, SECTION_MOD, PATCH_METHOD)}
                />
                <div>
                  {text.id === currentData?.id && statusLoader && (
                    <LoadingOutlined />
                  )}
                </div>
              </Tooltip>
            </ShowOnPermission>
          </Loaderflex>

          <ShowOnPermission mod={SECTION_MOD} method={PATCH_METHOD}>
            <Tooltip minLength={0} title='Edit'>
              <Actions onClick={() => onEdit(row)}  >
                <MdEdit fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
              </Actions>
            </Tooltip>
          </ShowOnPermission>
          <ShowOnPermission mod={SECTION_MOD} method={DELETE_METHOD}>
            <Tooltip minLength={0} title='Edit'>
              <Actions onClick={() => onDelete(row)} >
                <MdDelete fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
              </Actions>
            </Tooltip>
          </ShowOnPermission>
        </ActionCenter >
      ),
    }
  ]

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='d-flex justify-content-between'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Section', link: constRoute.section },
              ]}
              isAlpha
              pageTitle={'Section'}
            >
            </BreadCrumbs>
            <div>
              <CustomButton
                onClick={() => {
                  onAdd()
                }}
                title='Add Section'
              />
            </div>
          </div>
        </div>
        <SearchSection
          setQueryParam={setQueryParam}
        />

        {/* /Page Header */}
        <div className='row'>
          <div className=''>
            <div className='tableWidth'>
            </div>
          </div>
        </div>
        <Row>
          <Col span={24}>
            <div className='table-responsive custom-Pagination '>
              <StyleTable>
                <Table
                  ref={tableRef}
                  dataUrl={sectionUrl}
                  columns={columns}
                  refreshTable={refreshTable}
                  resetTable={resetTable}
                  setRefreshTable={setRefreshTable}
                  queryParam={queryParam}
                  setResetTable={setResetTable}
                  setTotalRecordRemaining={setTotalRecordRemaining}
                />
              </StyleTable>
            </div>
          </Col>
        </Row>
      </div>
      <CustomDrawer
        title={isUpdate ? UPDATE_SECTION : ADD_SECTION}
        setVisible={setVisible}
        visible={visible}
        className='addDrawer'
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='section-form'
              htmlType='submit'
              type='primary'
              title={isUpdate ? 'Update ' : 'Add '}
              className='addButton'
            />
          </Space>
        }
      >
        <SectionForm
          update={isUpdate}
          data={currentData}
          setIsUpdate={val => setIsUpdate(val)}
          setVisible={val => setVisible(val)}
          setRefreshTable={setRefreshTable}
        />
      </CustomDrawer>
      {/* Delete Section Modal */}
      <DeletePopUp
        delete={deleteSectionHandler}
        msgTitle='Delete Section'
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
        msg={deleteName}
      />
    </div>
  )
})
export const ActionsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  .actions {
    div {
      display: flex;
      justify-content: center;
    }
  }
`
const Loaderflex = styled.div`
  display: flex;
  gap: 4px;

  .ant-switch {
    margin-top: 2px !important;
  }
`
export default Section


const StyleTable = styled.div`
  .ant-table-container table>thead>tr:first-child th:last-child {
display: flex !important;
text-align: center !important;
justify-content: center !important;
  }
`
const StyledSwitch = styled(Switch)`
.ant-switch-inner{
  span{
    svg{
      color: white !important;
    }
  }
}
`
