import React, { useEffect, useState } from 'react'
import style from '@mainPage/rolePermission/style.module.scss'
import { CommonInput } from '@commonComponents/input'
import { SearchOutlined } from '@ant-design/icons'
import { Empty, Tooltip } from 'antd'
import { MdEdit } from 'react-icons/md'
import { useStore } from '@stores/root-store'
import LoadingSkeletonRole from '@mainPage/rolePermission/loadingSkeleton'
import { observer } from 'mobx-react'
import styled from 'styled-components';
import { getThemeColor } from '@utils/common-functions'
import { PATCH_METHOD, ROLE } from 'constant/constant'
import ShowOnPermission from '@commonComponents/permit'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

const RolePermissionLists = observer(({ setRoleId, roleId, setRecord, setDesVisible }) => {
  const [input, setInput] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const {
    employeeRole: { isLoading, getEmployeeRoleData},
  } = useStore(null)
  const themeColor=getThemeColor()

  const backgroundColor=themeColor==='black' ? 'white' : 'black';
  const filterBySearch = event => {
    const { value } = event.target
    value[0] == '' ? setInput('') : setInput(value)
    const updatedList = getEmployeeRoleData.filter(item =>
      item.title.toLowerCase().includes(value.toLowerCase())
    )
    updatedList ? setFilteredList(updatedList) : setFilteredList(getEmployeeRoleData)
  }

  useEffect(() => {
    setFilteredList(getEmployeeRoleData)
    if (!getEmployeeRoleData) return
    setRoleId(roleId || getEmployeeRoleData[0]?.id || '')
  }, [getEmployeeRoleData, input == ''])

  if (isLoading) {
    return (
      <div className='col-sm-5 col-md-5 col-lg-4 col-xl-3'>
        <LoadingSkeletonRole loading={isLoading} />
      </div>
    )
  }

  return (
    <div className={`col-sm-5 col-md-5 col-lg-4 col-xl-3`}>
      <div className={style.icon}>
        <CommonInput
          className={style.newCommonInput}
          inputType='text'
          placeholder='Search here...'
          icon={<SearchOutlined />}
          onChange={val => filterBySearch(val)}
          value={input}
          allowClear={true}
          onPressEnter={val => filterBySearch(val)}
        />
      </div>
      <div className={`${style.rolesmenu} ${style.roleModuleAccess}`} style={{background:backgroundColor}}>
        {filteredList?.length === 0 ? (
          <NodataStyled>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </NodataStyled>
        ) : (
          filteredList?.map(value => (
            <div
              className={`list-group-item p-10 ${style.carde} roleCard d-flex justify-content-between `}
              onClick={() => {
                setRoleId(value.id)
                setRecord(value)
              }}
              style={{
                borderLeft: roleId == value.id && `3px solid ${TealishBlue}`
              }}
            >
              <p className={`${roleId == value.id ? style.activeRule : ''} pt-3`}>
                {value.title}
              </p>
              <ShowOnPermission mod={ROLE} method={PATCH_METHOD}>
                <div>
                  <Tooltip title='Edit'>
                    <div className={style.iconsRole}>
                      <MdEdit
                        onClick={() => {
                          setDesVisible({ isOpen: true, isUpdate: true })
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              </ShowOnPermission>
            </div>
          ))
        )}
      </div>
    </div>
  );
})

export default RolePermissionLists;

const NodataStyled = styled.div`
height: 400px;
display: flex;
justify-content: center;
align-items: center;
`