
import { approvalLeaveUrl } from '@api/const'
import CustomDrawer from '@commonComponents/drawer'
import LeaveApprovedPopup from '@commonComponents/leave_approved_popup'
import { Table } from '@commonComponents/table-v2'
import EmployeeLeaveHistoryDrawer from '@mainPage/EmployeeLeave/employeeLeaveSideDrawer'
import { getLeaveApprovalColumns } from '@mainPage/EmployeeLeave/utils'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ADD_LEAVE_APPLICATION,
  EDIT_LEAVE_APPLICATION,
  LEAVE_DETAILS,
} from '../../const'
import EmployeeLeaveForm from '../EmployeeLeaveForm/employeeLeaveForm'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const EmployeeLeaveApprovalTable = observer(({ refreshTable, setRefreshTable, queryParam,resetTable,setResetTable,checkedData,setCheckedData}: any) => {
  const [currentData, setCurrentData] = useState({ id: null, value: null, data: {leaveTypeName: '', leaveStartDate: '', leaveEndDate: '', employee: { firstName: '', lastName: '' }, numberOfDays: '', isFullLeave: '' } })
  const [statusLoader] = useState(false)
  const [visible, setVisible] = useState(false)
  const [viewDetail] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [rejectReason, setRejectReason] = useState('')
  const tableRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [empLeaveData , setEmpLeaveData] = useState({})
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [singleCheck,setSingleCheck] = useState([]) as any;

  const [record, setRecord] = useState(null)

  const {
    employeeLeaveApprovalModal: {
      updateEmployeeLeaveApproval,
      loadEmployeeLeaveApproval ,
      getEmployeeLeaveApprovalData,
      leaveRejection
    },
  } = useStore(null)

  useEffect(() => {
    if (queryParam?.status !== 'Approved') {
      const searchParams = queryParam?.status ? `/?status=${queryParam?.status}&` : '/'
      queryParam?.status && loadEmployeeLeaveApproval(searchParams)
    }
  }, [queryParam])

  useEffect(()=>{
    if (!queryParam?.status) {
      setSingleCheck([])
      setIsCheckAll(false)
    }
  },[queryParam])

  enum types {
    REJECTED= 'rejected',
    APPROVED= 'approved'
  }
  
  const onStatusChange = async (leaveId: string, type: types) => {   
    if(type === types.APPROVED) {
      const res = await updateEmployeeLeaveApproval(leaveId)
      if (res?.success) {
        setRefreshTable(true)
      }
    } else if(type === types.REJECTED) {
      setVisibleDelete(true)
    }
  }
  const payload = {
    status : 'rejected',
    rejection_reason : rejectReason
  }

  const showDrawer = data => {
    setEmpLeaveData(data)
    setOpen(true);
  };

  const handleSingleCheck = (e, row, index) => {
    setSingleCheck(prevState => {
      const newCheck = [...prevState];
      newCheck[index] = e?.target?.checked;
      return newCheck
    });
    e?.target?.checked ? !checkedData.find(i => i?.id === row?.id) && setCheckedData(prevObjects => [...prevObjects, row]) : setCheckedData(prevArray => prevArray.filter(i => row?.id !== i?.id));
  };

  const handleCheckAll = event => {
    setIsCheckAll(event?.target?.checked)
    if(event?.target?.checked){
      getEmployeeLeaveApprovalData?.map((value, index) => {
        handleSingleCheck(event, value, index);
      })  
    }else{
      setCheckedData([])
      setSingleCheck([])
      setIsCheckAll(false)
    }
  }

  const columns = useMemo(() => getLeaveApprovalColumns(statusLoader , currentData , onStatusChange , false , false,showDrawer,'LeaveForApproval',handleSingleCheck,handleCheckAll,queryParam, isCheckAll, setRecord),[refreshTable,isCheckAll,queryParam,getEmployeeLeaveApprovalData,singleCheck])

  return (
    <>
      <div className='tableWidth table-responsive custom-Pagination'>
        <EmployeeApprovalTable
          ref={tableRef}
          dataUrl={`${approvalLeaveUrl}`}
          columns={columns}
          refreshTable={refreshTable}
          resetTable={resetTable}
          setResetTable={setResetTable}
          setRefreshTable={setRefreshTable}
          queryParam={Object.keys(queryParam).length !== 0 && queryParam}
          loaderPosition={'center'}
        />
      </div>

      {/* Add EmployeeLeave Modal */}
      <CustomDrawer
        width={400}
        className='addDrawer'
        title={
          viewDetail
            ? LEAVE_DETAILS
            : isUpdate
              ? EDIT_LEAVE_APPLICATION
              : ADD_LEAVE_APPLICATION
        }
        visible={visible}
        setVisible={() => {
          setVisible(false)
          setIsUpdate(false)
          setCurrentData(null)
        }}
      >
        {visible && (
          <EmployeeLeaveForm
            update={isUpdate}
            data={currentData.id}
            viewDetail={viewDetail}
            setVisible={setVisible}
            leaveApproval={false}
            setRefreshTable={val => setRefreshTable(val)}
          />
        )}
      </CustomDrawer>
      <LeaveApprovedPopup
        approvedHandler={async () => {
          const res = await leaveRejection(record?.id, payload)
          if (res?.success) {
            setVisibleDelete(false)
            setRefreshTable(true);
          }
        }}
        msgTitle='Confirm Leave Status'
        visibleDelete={visibleDelete}
        okText='Reject'
        handleCancel={() => setVisibleDelete(false)}
        setRejectReason={e=>setRejectReason(e)}
      />
      <EmployeeLeaveHistoryDrawer
        open={open}
        onClose={() => setOpen(false)}
        empLeaveData={empLeaveData && empLeaveData}
        isCancel={false}
        onStatusChange={onStatusChange}
      />
    </>
  )
})

export default EmployeeLeaveApprovalTable

const EmployeeApprovalTable = styled(Table)`
.ant-pagination-item a{
  justify-content:center !important;
}
.ant-select .ant-select-selector{
  justify-content: center;
    display: flex;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  justify-content: center;
    text-align: center;
    display: flex;
}
.ant-select .ant-select-selection-item {
  text-align: center;
  justify-content: center;
  display: flex !important;
}
`