import styled from 'styled-components';
import { Tooltip } from 'antd'
import { DELETE, ACTION, PARENT_CATEGORY, CATEGORY_NAME, CATEGORY_TYPE, CATEGORY_MOD, PUT_METHOD, DELETE_METHOD} from 'constant/constant'
import { MdDelete, MdEdit } from 'react-icons/md'
import ShowOnPermission from '@commonComponents/permit';
const getColumns = ( onDelete, onEdit) => {

  return [
    {
      title: CATEGORY_NAME,
      dataIndex: 'title',
      render: text => (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
      ),
    },
    {
      title: CATEGORY_TYPE,
      dataIndex: 'categoryType',
      render: text => (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
      )
    },
    {
      title: PARENT_CATEGORY,
      dataIndex: 'parentCategoryTitle',
      render: text => (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
      )
    },
        {
          title: ACTION,
          render: (text, record) => (
              <StyledActionWrapper className='accordingToTheme'>
                <ShowOnPermission mod={CATEGORY_MOD} method={PUT_METHOD}>
                  <StyledActionIconWrapper>
                    <Tooltip title='Edit'>
                      <MdEdit onClick={() => onEdit(record)} />
                    </Tooltip>
                  </StyledActionIconWrapper>
                  </ShowOnPermission>
                <ShowOnPermission mod={CATEGORY_MOD} method={DELETE_METHOD}>
                  <StyledActionIconWrapper>
                    <Tooltip title={DELETE}>
                      <MdDelete onClick={() => onDelete(record)} />
                    </Tooltip>
                  </StyledActionIconWrapper>
                  </ShowOnPermission>
              </StyledActionWrapper>
          ),
        },
  ]
}

export default getColumns

export const StyledActionWrapper = styled.div`
  display: flex;
`
export const StyledActionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
    svg {
      width: 20px;
      height: 20px;
    }
`