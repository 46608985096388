

import React, { useEffect , useState} from 'react'
import { Form, Col, Row, DatePicker } from 'antd'
import 'react-phone-input-2/lib/style.css'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import CustomButton from '@commonComponents/button'
import moment from 'moment'
import { SkeletonRingLoader } from '@commonComponents/skeleton/ring-loader'
import { DATE_FORMAT } from 'constant/constant'
import { validateNoWhiteSpace, validateRequired } from '@utils/common-functions/formValidations'
interface LoanFormProps {
  setRefreshTable: any;
  setBtnLoad: any;
  setVisible: any;
  isUpdate: any;
  setIsUpdate: any;
  data: any;
}
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const ApplyLoan = observer(({ setRefreshTable, setBtnLoad, setVisible, isUpdate, setIsUpdate, data }: LoanFormProps) => {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(true)

  const {
    employeesOpenList: { loadEmployeesOpenList, getEmployeesOpenList, nextEmp },
    laonDetails: { getLoanDetails, updateLoanDetails, setLoanDetails }
  } = useStore(null)

  useEffect(() => {
    async function load() {
      await loadEmployeesOpenList();
      setLoader(false)
    }
    load()
  }, [])

  const onlyNumber = (e: any) => {
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189)
      e.preventDefault();
  }
  

  useEffect(() => {
    form.resetFields()

    if (isUpdate) {
      form.setFieldsValue({
        id: data?.id,
        employee: data?.employee,
        loan_name: data?.loanName,
        loan_amount: data?.loanAmount,
        no_of_instalments: data?.noOfInstalments,
        installment_start_date: data?.installmentStartDate ? moment(data?.installmentStartDate, 'YYYY-MM-DD') : null,
        loan_disbursement_date: data?.loanDisbursementDate ? moment(data?.loanDisbursementDate, 'YYYY-MM-DD') : null,
        reason: data?.reason,
      })
    }
  }, [data, isUpdate])

  const submitData = async values => {

   
    
    const formData = new FormData()
    let response = null
    values.status = true
    values.apply_date = moment(values.apply_date).format(DATE_FORMAT)
    values.installment_start_date = moment(values?.installment_start_date).format(DATE_FORMAT)
    values.loan_disbursement_date = moment(values?.loan_disbursement_date).format(DATE_FORMAT)
    

    formData.append('employee', values?.employee)
    formData.append('loan_name', values?.loan_name)
    formData.append('apply_date', values?.apply_date)
    formData.append('installment_start_date', values?.installment_start_date)
    formData.append('loan_disbursement_date', values?.loan_disbursement_date)
    formData.append('no_of_instalments', values?.no_of_instalments)
    formData.append('loan_amount', values?.loan_amount)
    formData.append('reason', values?.reason)

    if (isUpdate) {
      response = await updateLoanDetails(formData, data?.id)
    } else {
      response = await setLoanDetails(formData)
    }

    if (response?.success) {
      setVisible(false)
      setRefreshTable(true)
      form.resetFields()
      getLoanDetails(1, 20, '', true)
      setIsUpdate(false)
    }
    setBtnLoad(false)
  }

  if (loader) {
    return <SkeletonRingLoader />
  }

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{ create_user: true, is_lead: false }}
      id='apply-loan'
      onFinish={submitData}
    >
      <Row>
        <Col span={24}>
          <DynamicSelect
            disable={isUpdate ? true : false}
            formLabel='Select Employee'
            getData={getEmployeesOpenList}
            loadData={loadEmployeesOpenList}
            valueKey1={'firstName'}
            valueKey2={'lastName'}
            isForm={true}
            name={'employee'}
            fetchData={true}
            formRules={[validateRequired('Employee name')]}
            placeholder='Select Employee'
            nextPage={nextEmp} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label='loan amount'
            name='loan_amount'
            rules={[
              { required: true, message: 'Loan Amount is required' },
            ]}
          >
            <CommonInput
              className='form-control inputField'
              onKeyDown={e => onlyNumber(e)}
              inputType={'number'}
              placeholder='Ex: 2000'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name={'loan_name'}
            label='Loan Name'
            rules={[
              { required: true, message: 'Loan Name is required' },
              { whitespace: true, message: 'Can\'t be just whitespace' },
            ]}
          >
            <CommonInput
              className='form-control inputField'
              inputType={'text'}
              placeholder='Ex: Personal Loan'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name={'no_of_instalments'}
            label='No Of Installment'
            rules={[
              { required: true, message: 'No of Installment is required' },
            ]}
          >
            <CommonInput
              className='form-control inputField'
              onKeyDown={e => onlyNumber(e)}
              inputType={'number'}
              placeholder='No of Installment'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name={['installment_start_date']}
            label='Installment Start Date'
            rules={[{ required: true, message: 'Installment Start Date is required' }]}
            style={{
              position: 'relative',
            }}
          >
            <DatePicker
              format='YYYY-MM-DD'
              getPopupContainer={trigger => trigger.parentElement}
              className='form-control inputField'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name={['loan_disbursement_date']}
            label='loan Disbursement Date'
            rules={[{ required: true, message: 'Loan Disbursement Date is required' }]}
            style={{
              position: 'relative',
            }}
          >
            <DatePicker
              format='YYYY-MM-DD'
              getPopupContainer={trigger => trigger.parentElement}
              className='form-control inputField'
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} lg={24}>
          <Form.Item
            name={['reason']}
            label='Reason'
            rules={[validateRequired('Reason'), validateNoWhiteSpace()]}
          >
            <CommonInput
              inputType='textarea'
              placeholder='Write Reason for Loan'
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item className={` mb-0 d-none`}>
        <CustomButton
          htmlType='submit'
          type='primary'
          title={'Submit'}
        />
      </Form.Item>
    </Form>
  )
}
)

export default ApplyLoan



