
import React, { useRef, useState } from 'react'
import { Table } from '@commonComponents/table-v2'
import 'antd/dist/antd.min.css'
import { terminationUrl } from '@api/const'
import { observer } from 'mobx-react-lite'
import { constRoute } from '@utils/route'
import BreadCrumbs from '@commonComponents/breadcrums'
import ApplyTermination from './applyTermination'
import { useStore } from '@stores/root-store'
import SearchTermination from './searchTermination'
import { getColumns } from './columns'
import { TERMINATION, DASHBOARD, MESSAGE_TITLE } from './constant'
import CustomDrawer from '@commonComponents/drawer'
import CustomButton from '@commonComponents/button'
import { Space } from 'antd'
import TerminationForm from './terminationForm'
import styled from 'styled-components';
import ShowOnPermission from '@commonComponents/permit'
import DeletePopUp from '@commonComponents/delete-popup'
import { GET_METHOD, POST_METHOD, TERMINATION_MOD } from 'constant/constant'
import { colors } from 'constant/colors'

const { TealishBlue } = colors


const Terminationtable = observer(() => {
  const tableRef = useRef(null);
  const [refreshTable, setRefreshTable] = useState(false)
  const [queryParam, setQueryParam] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [visible, setVisible] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [resetTable, setResetTable] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [deleteName, setDeleteName] = useState('')
  const [newStatus, setStatus] = useState(null)

  const {
    terminationModel: { updatTermination, deleteTermination, isLoading }
  } = useStore(null)
  const updateEmployeeStatus = async (data, status) => {
    setStatus(status)
    const statusVal = { status: status }
    await updatTermination(statusVal, data.id, data.employeeId)
    setRefreshTable(true)
  }
  const deleteTerminationHandler = async () => {
    const res = await deleteTermination(currentId)
    if (res.success) {
      setCurrentId('')
      setVisibleDelete(false)
      setRefreshTable(true)
    }
  }
  const onDelete = row => {
    setDeleteName(row.fullName)
    setCurrentId(row.id)
    setVisibleDelete(true)
  }

  const columns = getColumns(updateEmployeeStatus, setCurrentData, setIsUpdate, setVisible, onDelete)


  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='d-flex justify-content-between mb-4'>
              <BreadCrumbs
                list={[
                  { name: DASHBOARD, link: constRoute.home },
                  { name: TERMINATION, link: constRoute.termination },
                ]}
                pageTitle={TERMINATION}
              >
              </BreadCrumbs>
              <ShowOnPermission mod={TERMINATION_MOD} method={POST_METHOD}>
                <ApplyTermination
                  setRefreshTable={setRefreshTable}
                />
              </ShowOnPermission>
            </div>
          </div>
          <ShowOnPermission mod={TERMINATION_MOD} method={GET_METHOD}>
            <SearchTermination
              setRefreshTable={setRefreshTable}
              setQueryParam={setQueryParam}
              setResetTable={setResetTable}
            />
          </ShowOnPermission>
          <div>
            <div className='table-responsive'>
              <TerminationTable
                ref={tableRef}
                dataUrl={terminationUrl}
                columns={columns}
                refreshTable={refreshTable}
                setRefreshTable={setRefreshTable}
                queryParam={queryParam}
                setResetTable={setResetTable}
                resetTable={resetTable}
                newStatus={newStatus}
                rowClassName={record => (record.status === 'Approved' ? 'approved-row' : '')} />
            </div>
            <CustomDrawer
              title='Update Termination'
              setVisible={setVisible}
              visible={visible}
              className='addDrawer'
              width={400}
              customFooter={
                <Space>
                  <DrawerCancelBtnWrapper>
                    <CustomButton
                      htmlType='submit'
                      type='primary'
                      title={'Cancel'}
                      className='cancelButton'
                      onClick={() => setVisible(false)}
                    />
                  </DrawerCancelBtnWrapper>
                  <DrawerAddBtnWrapper>
                    <CustomButton
                      form='termination-form'
                      htmlType='submit'
                      type='primary'
                      title={isUpdate ? 'Update ' : 'Add '}
                      onClick={() => setVisible(false)}
                    />
                  </DrawerAddBtnWrapper>
                </Space>
              }
            >
              <TerminationForm
                update={isUpdate}
                data={currentData}
                setIsUpdate={setIsUpdate}
                setVisible={setVisible}
                setRefreshTable={setRefreshTable}
                setIsOpenDrawer={undefined} />
            </CustomDrawer>
          </div>
        </div>
      </div>
      <DeletePopUp
        delete={deleteTerminationHandler}
        msgTitle={MESSAGE_TITLE}
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
        renderMsg={`Are you sure you want to delete  ${deleteName} Termination`}
      />
    </div>
  )
})
export default Terminationtable;
const TerminationTable = styled(Table)`
`

const DrawerCancelBtnWrapper = styled.div`
  button{
    font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		letter-spacing: 0.04em;
		min-width: 100px;
		background: transparent !important;
		border: 1px solid #2e71d8;
		border-radius: 31px;
		color: ${TealishBlue} !important;
		margin-right: 10px;

		&:hover {
			box-shadow: unset !important;
		}
  }
`
const DrawerAddBtnWrapper = styled.div`
  button{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.04em;
    min-width: 100px;
  }
`