/* eslint-disable */ 
import { flow, types } from 'mobx-state-tree'
import { familyApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'
import { errorHandling } from '@utils/common-functions'

export const familyMember = types.model({
  id: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  relation: types.maybeNull(types.string),
  phoneNumber1: types.maybeNull(types.string),
  phoneNumber2: types.maybeNull(types.string),
})

export const familyDetails = types
  .model({
    data: types.maybeNull(types.array(familyMember)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get familyData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setFamilyDetails = flow(function* (data) {
      try {
        self.loading = true
        yield familyApi.setFamilyDetails(data)
        notification.success('Family Data Added Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to create Family Detail')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadFamilyDetails = flow(function* (id) {
      try {
        self.loading = true
        const res = yield familyApi.getFamilyDetails(id)
        self.data = res
      } catch (error) {
        errorHandling(error,'Failed to Load Family Details')
      } finally {
        self.loading = false
      }
    })

    const updateFamilyDetails = flow(function* (
      id,
      data
    ) {
      try {
        self.loading = true
        yield familyApi.updateFamilyDetails(id, data)
        notification.success('Family Updated Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to update Family Detail')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteFamilyDetails = flow(function* (id) {
      try {
        self.loading = true
        yield familyApi.deleteFamilyDetails(id)
        notification.success('Family Deleted Successfully')
        return { success: true }
      } catch (error) {
        errorHandling(error,'Failed to delete Family Detail')
      } finally {
        self.loading = false
      }
    })
    return {
      setFamilyDetails,
      loadFamilyDetails,
      updateFamilyDetails,
      deleteFamilyDetails,
    }
  })

export function initEmployeeFamilyDetails() {
  return familyDetails.create({})
}
