const companyName = ' - Enigmatix OMS'

export const documentTitle = {
  reports: 'Reports' + companyName,
  dashboard: 'Dashboard' + companyName,
  board: 'Board' + companyName,
  manage: 'Manage' + companyName,
  clientType: 'Client Type' + companyName,
  addClient: 'Add Client' + companyName,
  clientList: 'Client List' + companyName,
  employeeList: 'Employee List' + companyName,
  addEmployee: 'Add Employee' + companyName,
  employeeType: 'Employee Type' + companyName,
  updateEmployee: 'Update Employee' + companyName,
  addEmployeeSalary: 'Add Employee Salary' + companyName,
  employeeSalaryList: 'Employee Salary List' + companyName,
  updateEmployeeSalary: 'Update Employee Salary' + companyName,
  teamList: 'Team List' + companyName,
  addTeam: 'Add Team' + companyName,
  updateTeam: 'Update Team' + companyName,
  roleList: 'Role List' + companyName,
  languageList: 'Language List' + companyName,
  timeSheet: 'Timesheet' + companyName,
  userList: 'User List' + companyName,
  addUser: 'Add User' + companyName,
  updateUser: 'Update User' + companyName,
  userRoleList: 'User Role List' + companyName,
  userRole: 'User Role List' + companyName,
  addUserRole: 'Add User Role' + companyName,
  updateUserRole: 'Update User Role' + companyName,
  leave: 'Leave' + companyName,
  login: 'Login Page' + companyName,
  signup: 'Signup Page' + companyName,
  profilePage: 'Profile Page' + companyName,
  changePassword: 'Profile | Change Password' + companyName,
}
