import {Col, Form, Row} from 'antd'
import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {CommonInput} from '@commonComponents/input'
import {useStore} from '@stores/root-store'
import {DynamicSelect} from '@commonComponents/select/dynamicSelect'
import {CommonSelect} from '@commonComponents/select'
import SearchFilter from '@commonComponents/SearchFilter';
import { SearchOutlined } from '@ant-design/icons'


interface SearchAssetsProps {
  setQueryParam: (data: object) => void;
  activeTab?: string;
  resetFilters?: any;
  setResetFilters?:any;
}


const SearchAsset = observer((props: SearchAssetsProps) =>{

  const {
    hrCategoryModal: { getCategoriesData, loadCategory, nextCat },
  } = useStore(null)

  useEffect(() =>
  {
    const loadData = async () => {
      if (!getCategoriesData?.length) {
        loadCategory()
      }
    }
    loadData()
  }, [])

  
  return (
    <div>
      <SearchFilter
        {...props}
        children={
          <div className='w-100'>
            <Row gutter={2} className={`rowDiv gap-lg-0  gap-xl-0 gap-xxl-2 flex-grow-1`}>
              <Col xs={24} sm={12}  lg={6}>
                <Form.Item name='name'>
                  <CommonInput
                    placeholder='Asset Name'
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <DynamicSelect
                  isForm={true}
                  name={'asset_category'}
                  getData={getCategoriesData}
                  loadData={loadCategory}
                  queryParam={'asset_category'}
                  nextPage={nextCat}
                  valueKey1={'title'}
                  placeholder='Asset Category'
                  clearMyData={true}
                  fetchData={false}
                />
              </Col> 
              { 
                props.activeTab === 'assets' &&
                <Col xs={24} sm={12} lg={6} >
                  <Form.Item name={'status'}>
                    <CommonSelect
                      listHeight={120}
                      placeholder='Status'
                      allowClear
                      size='large'
                      getPopupContainer={trigger => trigger.parentNode}
                      data={[
                        { key: 'Approved', value: 'Approved' },
                        { key: 'Forward', value: 'Forward' },
                        { key: 'Rejected', value: 'Rejected' },
                        { key: 'Pending', value: 'Pending' }
                      ]}
                    />
                  </Form.Item>
                </Col>
              }
            </Row>
          </div>
        }
      />
    </div>
  )

})
export default SearchAsset
