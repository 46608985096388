import { CommonInput } from '@commonComponents/input'
import { Col, DatePicker, Modal, Row, Space } from 'antd'
import { Form } from 'antd'
import styled from 'styled-components'
import { TimePicker } from 'antd';
import { useEffect, useState } from 'react';
import CustomButton from '@commonComponents/button';
import { CommonSelect } from '@commonComponents/select';
import { useStore } from '@stores/root-store';
import moment from 'moment'
import { observer } from 'mobx-react-lite';

interface propsData {
  settingsp?: boolean;
  setSettingsp?: React.Dispatch<React.SetStateAction<boolean>>;
  length?:number;
  setSprint?: React.Dispatch<React.SetStateAction<boolean>>;
  boardID?: number;
}
const SettingSprint = observer(({ settingsp, setSettingsp,length,setSprint, boardID }: propsData) => {
  const [form] = Form.useForm()
  const [getInput, setInput] = useState('')
  const [getDate, setDate] = useState('')

  const {
    boardDetails: { postSprintDetails },
  } = useStore(null)

  useEffect(() => {
    if (!settingsp) {
      setInput('');
      setDate('');
      form.resetFields()
    }
  }, [settingsp]);



  const handleSprintSetting = async values => {
    const time = moment(values.start_time).format('HH:mm');
    const board = boardID
    parseInt(values.count)
    const counts = parseInt(values.count);
    const weeks = parseInt(values.duration_weeks)
    const sprintsArray = [];
    const Array2 = [];

    if (getInput) {
      Array2.push(`Sprint {${length + 1}} ({${getDate}}-{${getInput}})`);
    }

    for (let i = 0; i < counts; i++) {
      const previousEndDate = i === 0 ? moment(getInput, 'YYYY-MM-DD') : moment(sprintsArray[i - 1].match(/\d{4}-\d{2}-\d{2}/)[0], 'YYYY-MM-DD');
      const multiply = weeks*7
      const nextStartDate = previousEndDate.add(multiply, 'days');
      const nextEndDate = nextStartDate.clone().add(multiply-1, 'days');    
      sprintsArray.push(
        `Sprint {${length + 1+ i}} ({${nextStartDate.format('YYYY-MM-DD')}}-{${nextEndDate.format('YYYY-MM-DD')}})`
      );
    }
    const updatedValues = {
      ...values,
      board: board,
      start_time: time,
      names:sprintsArray,
      count:counts,
      start_date: getDate,
      duration_weeks: weeks
    }

    const response = await postSprintDetails(updatedValues)
    if (response.success) {
      setSettingsp(false)
      form.resetFields()
      setDate('')
      setSprint(true)
    }

  }

  const Days = [
    { key: 'Sunday', value: 'Sunday' },
    { key: 'Monday', value: 'Monday' },
    { key: 'Tuesday', value: 'Tuesday' },
    { key: 'Wednesday', value: 'Wednesday' },
    { key: 'Thursday', value: 'Thursday' },
    { key: 'Friday', value: 'Friday' },
    { key: 'Saturday', value: 'Saturday' },
  ]

  const handleCancel = () => {
    setSettingsp(false)
  }



  const handle = e => {
    const target = parseInt(e.target.value);
    if (getDate && target) {
      const dateObject = moment(getDate);
      const getTotal = dateObject.add(target * 7, 'days');
      const formattedDate = getTotal.format('YYYY-MM-DD');
      setInput(formattedDate);
    }
    else {
      setInput('')
    }
  };

  const getDatePicker = date => {
    const convert = moment(date)?.format('YYYY-MM-DD')
    setDate(convert)
  }
  return (
    <Modal width={800} open={settingsp} onCancel={handleCancel} footer={false} getContainer={false}>
      <div>
        <Form
          form={form}
          onFinish={handleSprintSetting}
          layout='vertical'
          scrollToFirstError
          id='assets-form'
        >
          <div className='form-auto-height antDatePicker  antSelect'>

            <h4>Sprint Setting</h4>
            <Row className='mt-4'>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  name='names'
                  label='Sprint Name'
                >
                  <StyledCommonInput
                    placeholder={getInput ? `Sprint {${length + 1}} ({${getDate}}-{${getInput}}) ` : 'Select day and weeks'}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={11} md={11} lg={11}>
                <Form.Item
                  label='Sprint Start Date'
                  rules={[
                    { required: true, message: 'Start date is required' },
                  ]}
                  style={{
                    position: 'relative',
                  }}
                >
                  <DatePicker
                    value={getDate ? moment(`${getDate}`) : null}
                    getPopupContainer={trigger => trigger.parentElement}
                    className='form-control'
                    onChange={getDatePicker}
                  />
                </Form.Item>
              </Col>
              <Col xs={0} sm={2} md={2} lg={2} />
              <Col xs={24} sm={11} md={11} lg={11}>
                <Form.Item name='duration_weeks'
                  label='Sprint Duration'
                  rules={[{ required: true, message: 'Please Enter Weeks' }]}
                >
                  <StyledCommonInput placeholder='Please Enter Weeks' inputType='number' onChange={handle} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={11} md={11} lg={11}>
                <Form.Item name='start_time'
                  label='Start Time'
                  rules={[{ required: true, message: 'Please Select Start Time' }]}
                >
                  <TimePicker
                    format='HH:mm'
                    getPopupContainer={trigger => trigger.parentElement}
                    className='form-control'
                  />
                </Form.Item>
              </Col>
              <Col xs={0} sm={2} md={2} lg={2} />
              <Col xs={24} sm={11} md={11} lg={11}>
                <Form.Item
                  label='Non Working Days'
                  name='non_working_days'
                  rules={[{ required: true, message: 'Please Select Non Working Days' }]}
                >
                  <CommonSelect
                    listHeight={120}
                    className=''
                    mode='multiple'
                    getPopupContainer={trigger => trigger.parentNode}
                    placeholder='Please Select Day...'
                    showSearch
                    allowClear
                    filterOption={(input, option) => option?.children
                      ?.toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                    data={Days} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={11} md={11} lg={11}>
                <Form.Item
                  name='count'
                  label='Number of Sprints'
                  rules={[
                    { required: true, message: 'Please Enter number of Sprints' },
                  ]}
                >
                  <CommonInput placeholder='Number of Sprints' inputType='number' />
                </Form.Item>
              </Col>
            </Row>
            {<StyledSpace>

              <CustomButton
                type='primary'
                title={'Cancel'}
                className='cancelButton'
                onClick={handleCancel}
              />
              <CustomButton
                htmlType='submit'
                type='primary'
                title={'Create Sprint'}
                className='addButton' />
            </StyledSpace>}
          </div>
        </Form>
      </div>
    </Modal>
  )
})
export default SettingSprint

const StyledCommonInput = styled(CommonInput)`
  height: 45px !important;
`

const StyledSpace = styled(Space)`
  text-align: center;
    display: flex;
    justify-content: flex-end
`
