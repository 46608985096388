import React, { memo } from 'react'
import { Form, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { CommonSelect } from '@commonComponents/select'
import { CommonInput } from '@commonComponents/input'
import {APPROVED,APPROVED_LOWERCASE,DISMISSAL_STATUS,LARGE,PENDING,PENDING_LOWERCASE } from 'constant/constant'
import { EMPLOYEE_NAME, TerminationSearchProps } from './constant'
import SearchFilter from '@commonComponents/SearchFilter'
import { SearchOutlined } from '@ant-design/icons'

const SearchTermination = observer((props: TerminationSearchProps) => {
  
  return (
    <div className='row filter-row'>
      <SearchFilter
          {...props}
          children={
            <div className={`d-lg-flex w-100`}>
              <Row gutter={10} className='w-100'>
                <Col xs={24} sm={8} lg={6}>
                  <Form.Item name={EMPLOYEE_NAME}>
                    <CommonInput
                      className='inputField'
                      placeholder='Employee Name'
                      prefix={<SearchOutlined />}
                      />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={6}>
                  <Form.Item
                    name={DISMISSAL_STATUS}>
                    <CommonSelect
                      listHeight={120}
                      allowClear
                      placeholder='Status'
                      size={LARGE}
                      getPopupContainer={trigger => trigger.parentNode}
                      data={[
                        { key: PENDING_LOWERCASE, value: PENDING },
                        { key: APPROVED_LOWERCASE, value: APPROVED },
                      ]}
                      />
                  </Form.Item>
                </Col>
              </Row>
            </div> }/>
    </div>
  )
})
export default memo(SearchTermination);