import { colors } from 'constant/colors'

const {
  green2,
  bernRed,
  peach,
  hamGreen,
  grassGreen,
  willow,
  mauvePink,
  mogwaYellow,
  green2Light,
  bernRedLight,
  TealishBlue,
  TealishBlueLight,
  mogwaYellowLight,
} = colors

export const REJECTED_TEXT = 'rejected'
export const APPROVED_TEXT = 'approved'
export const FORWARD_TEXT = 'forward'
export const CANCELLED_TEXT = 'cancelled'
export const WAITING_APPROVEL_TEXT = 'Waiting for Approval'
export const PENDING_APPROVAL = 'pending'
export const PENDING = 'Pending'
export const FORWARD = 'Forward'
export const APPROVED = 'Approved'
export const REJECTED = 'Rejected'
export const CANCELLED = 'Cancelled'
export const EDIT = 'Edit'
export const REJECTED_TEXT_HISTORY = 'Rejected'
export const APPROVED_TEXT_HISTORY = 'Approved'

export const DATE_FORMATE = 'YYYY-MM-DD'
export const NORMAL_DATE_FORMAT = 'MMM DD, YYYY'
export const TIME_FORMATE = 'HH:mm'
export const NORMAL_TIME_FORMAT = 'h:mm A'
export const TOTAL_COUNT = 'Total Count'
export const LEAVE_APPLICATIONS = 'leave_application'
export const EDITS = 'edit'

export const CLASS_WARNING = 'text-warning'
export const CLASS_SUCCESS = 'text-success'
export const CLASS_REJECTED = 'text-danger'
export const CLASS_PRIMARY = 'text-primary'
export const CANCEL_LEAVE_APPLICATION = 'Cancel Leave Application'
export const DELETE_LEAVE_APPLICATION = 'Delete Leave Application'
export const REASON = 'Reason'

export const CANCEL_LEAVE = 'Cancel leave'
export const LEAVE_DATES = 'Leave Dates'
export const LEAVE_DATE_IS_REQUIRED = 'Leave date is required'
export const PlASE_SELECT_TYPE_FIRST = 'Please Select Type First'
export const LEAVE_TYPE_IS_REUIRED = 'Leave type is required'
export const TIME_IS_REQUIRED = 'Time is required'
export const SELECT_NEW_LEAVE_DATES = 'Select New Leave Dates'
export const SELECT_DATES_YOU_WANT_TO_CANCEL =
  'Select Leave Dates You Want To Cancel'
export const REASON_IS_REQUIRED = 'Reason is required'
export const CANCEL_MY_LEAVE = 'Cancel My Leave'
export const CANCEL_FULL_LEAVE_MESSAGE = 'Are you sure you want to cancel full leave'

export const LEAVE_DETAILS = 'Leave Details'
export const EDIT_LEAVE_APPLICATION = 'Edit Leave Application'
export const ADD_LEAVE_APPLICATION = 'Add Leave Application'
export const LEAVE_APPLICATION = 'Leave Application'
export const EMPLOYEE = 'employee'
export const EMPLOYEE_NAME = 'employee_name'
export const Write_Cancellation_Reason = 'Write Cancellation Reason'
export const Write_Reason = 'Write Reason'

export const LEAVE_TYPE = 'Leave Type'
export const APPLIER_NAME = 'Applier Name'
export const LEAD_NAME = 'Lead Name'
export const DURATION = 'Duration'
export const STATUS = 'Status'
export const FILTER_STATUS = 'leave_status'
export const ACTION = 'Action'
export const NUMBER_OF_DAYS = 'N-O-Days'
export const SHORT = 'Short'

export const DETAILS = 'Details'
export const DELETE = 'Delete'
export const CANCEL = 'Cancel'
export const ADD_LEAVE = 'Add Leave'
export const UPDATE_LEAVE = 'Update Leave'
export const ADD = 'Add'
export const ADDS = 'add'
export const UPDATE = 'Update'
export const APPLY_LEAVE = 'Apply Leave'

export const APPROVEL_OBJ = {
  text: WAITING_APPROVEL_TEXT,
  className: CLASS_WARNING,
}
export const FORWARD_OBJ = {
  text: FORWARD,
  className: CLASS_PRIMARY,
}

export const LEAVE_TYPES = {
  Pending: APPROVEL_OBJ,
  Pending_Approval: APPROVEL_OBJ,
  Forward: FORWARD_OBJ,
  Approved: { text: APPROVED_TEXT, className: CLASS_SUCCESS },
  Rejected: { text: REJECTED_TEXT, className: CLASS_REJECTED },
}
export const EMPLOYEE_TAB_LIST = [
  {
    key: 'myLeaves',
    tab: 'My Leaves',
  },
]
export const TAB_KEYS = {
  REMOTE_APPROVAL: 'RemoteApproval',
  REMOTE_CANCEL_APPROVAL: 'RemoteCancelApproval',
  REMOTE_HISTORY: 'RemoteHistory',
};
export const TAB_LIST = [
  {
    key: 'approval',
    tab: 'Leaves for Approval',
  },
  {
    key: 'cancelApproval',
    tab: 'Cancellation Approval',
  },
  {
    key: 'history',
    tab: 'Leaves History',
  },
]

export const REMOTE_EMPLOYEE_TAB_LIST = [
  {
    key: 'RemoteWork',
    tab: 'Remote Work',
  },
]
export const REMOTE_TAB_LIST = [
  {
    key: 'RemoteApproval',
    tab: 'Remote for Approval',
  },
  {
    key: 'RemoteCancelApproval',
    tab: 'Cancellation Approval',
  },
  {
    key: 'RemoteHistory',
    tab: 'Remote History',
  },
]

export const progressColor = [mauvePink, peach, willow, hamGreen, grassGreen]

export const leaveStatusColors = {
  approved: { color: green2, backgroundColor: green2Light },
  rejected: { color: bernRed, backgroundColor: bernRedLight },
  cancelled: { color: bernRed, backgroundColor: bernRedLight },
  forward: { color: TealishBlue, backgroundColor: TealishBlueLight },
  pending: { color: mogwaYellow, backgroundColor: mogwaYellowLight },
}
