import { BaseApi } from '../baseApi'
import { hrServicesUrl } from '../const/index'
class TerminationApi extends BaseApi {
  setTermination = async data => {
    try {
      const response = await this.axios.post(`${hrServicesUrl}termination/`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateTermination = async (data, id, employee_id) => {
    try {
      const response = await this.axios.patch(`${hrServicesUrl}termination/${id}?employee_id=${employee_id}`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteTermination = async id => {
    try {
      const response = await this.axios.delete(`${hrServicesUrl}termination/${id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default TerminationApi


