import { flow, types, cast } from 'mobx-state-tree'
import { projectsApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'

export const client = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastLame: types.maybeNull(types.string),
  mobileNomobileNumber: types.maybeNull(types.number),
})

export const employee_data = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
})

export const project = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  projectId: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  priority: types.maybeNull(types.string),
  contractType: types.maybeNull(types.string),
  rate: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  uploadFiles: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  client: types.maybeNull(client),
  projectLeader: types.maybeNull(types.array(employee_data)),
  projectTeam: types.maybeNull(types.array(employee_data)),
  status: types.maybeNull(types.string),
})

export const projectsModel = types
  .model({
    data: types.maybeNull(types.array(project)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextPro: types.optional(types.number, 1)
  })
  .views(self => ({
    get getProjectsData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))

  .actions(self => {
    const setProjectsData = flow(function* (data) {
      try {
        self.loading = true
        yield projectsApi.setProjects(data)
        notification.success('Project Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadProjects = flow(function* (page=1, pageSize=20, queryParam='', resetList=false) {
      self.loading = true
      try {
        self.loading = true
        if (resetList) {
          self.data = cast([])
          self.nextPro = 1
        }
        const res = yield projectsApi.getProjects(page , pageSize, queryParam)
        if (!res?.isCancel){
          self.data = cast([...toJS(self.data ?? []), ...res.results])
          self.count = res.count
          if (res.next) {
            self.nextPro = self.nextPro + 1
          } else {
            self.nextPro = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Projects')
        }
      } finally {
        self.loading = false
      }
    })
    const filterProjects = flow(function* (
      projectName,
      projectTeamFirstName,
      projectTeamDesignation,
      num
    ) {
      try {
        self.loading = true
        const res = yield projectsApi.filterProjects(
          projectName,
          projectTeamFirstName,
          projectTeamDesignation,
          num
        )
        self.data = res.results
        self.count = res.count
      } catch (error) {
        // some error
      } finally {
        self.loading = false
      }
    })
    const updateProjects = flow(function* (data, id) {
      try {
        self.loading = true
        yield projectsApi.updateProjects(data, id)
        notification.success('Project Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteProjects = flow(function* (id) {
      try {
        self.loading = true
        yield projectsApi.deleteProjects(id)
        notification.success('Project Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setProjectsData,
      loadProjects,
      filterProjects,
      updateProjects,
      deleteProjects,
    }
  })

export function initprojectsModel() {
  return projectsModel.create({})
}
