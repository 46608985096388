import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Card, Space } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import DeletePopUp from '@commonComponents/delete-popup'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import CustomButton from '@commonComponents/button'
import { permit } from '@utils/permission/permission'
import CustomDrawer from '@commonComponents/drawer'
import {
  CANCEL,
  CANCEL_LEAVE_APPLICATION,
  DELETE_LEAVE_APPLICATION,
  REMOTE_EMPLOYEE_TAB_LIST,
  REMOTE_TAB_LIST,
  CANCEL_FULL_LEAVE_MESSAGE,
} from './const'
import { getSideDrawerHeader } from './utils'
import styled from 'styled-components'
import EmployeeRemoteLeaveTable from './EmployeeRemote/EmployeeLeadView/employeeRemoteLeaveTable'
import EmployeeRemoteLeaveApprovalTable from './EmployeeRemote/LeadAdminView/employeeRemoteLeaveApprovalTable'
import EmployeeRemoteLeaveCancelApprovalTable from './EmployeeRemote/LeadAdminView/employeeRemoteLeaveCancelApprovalTable'
import EmployeeRemoteLeaveFilter from './EmployeeRemote/LeadAdminView/RemoteLeaveFilter'
import EmployeeRemoteLeaveHistoryTable from './EmployeeRemote/LeadAdminView/employeeRemoteLeaveHistory'
import EmployeeRemoteLeaveForm from './EmployeeRemote/EmployeeRemoteForm/employeeRemoteLeaveForm'
import ShowOnPermission from '@commonComponents/permit'
import { POST_METHOD, Remote_Work } from 'constant/constant'

const RemoteLeave = observer(() => {
  const [searchParams] = useSearchParams()
  useEffect(() => {
    searchParams.get('leave_status') &&
      setQueryParam({ leave_status: searchParams.get('leave_status') })
    searchParams.get('plan') &&
      setQueryParam({ plan: searchParams.get('plan') })
    searchParams.get('id') && setQueryParam({ id: searchParams.get('id') })
  }, [
    searchParams.get('leave_status'),
    searchParams.get('plan'),
    searchParams.get('id'),
  ])

  const [currentData, setCurrentData] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [viewDetail, setViewDetail] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [isCancel, setIsCancel] = useState(false)
  const [queryParam, setQueryParam] = useState({})
  const [isAddBtnDisable, setIsAddBtnDisable] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')
  const [isDisable, setIsDisable] = useState(true)
  const [isRemoteLeaveDisable, setIsRemoteLeaveDisable] = useState(false)
  const [activeRemoteTab, setActiveRemoteTab] = useState('')
  const [remoteTabList, setRemoteTabList] = useState([])
  const [isRemote, setIsRemote] = useState(false)
  const [resetFilters, setResetFilters] = useState(false)
  const [state, setState] = useState(false)
  const [checkedData, setCheckedData] = useState([])
  const {
    userInfo: { getRoles, getcompany },
    employeeLeaveCancellationModal: { setEmployeeLeaveCancellation },
    employeeLeaveModal: { deleteEmployeeLeave },
  } = useStore(null)
  const isAdmin = permit(getRoles, 'accessView', 'is_admin')
  const isLead = getcompany?.isLead

  const onCancelLeave = async () => {
    const props = {
      cancellation_reason: cancellationReason,
    }
    const response = await setEmployeeLeaveCancellation(currentData.id, props)
    if (response?.success) {
      setVisibleDelete(false)
      setRefreshTable(true)
      setCurrentData(currentData)
      
    }
  }

  const handleRefreshTable = () => {
    setRefreshTable(prev => !prev)
    setIsRemoteLeaveDisable(false)
  } 
  const deleteEmployeeLeaveHandler = async () => {
    const res = await deleteEmployeeLeave(currentData?.id)
    if (res.success) {
      setVisibleDelete(false)
      handleRefreshTable()
    }
  }
  useEffect(() => {
    if (refreshTable) {
      setIsRemoteLeaveDisable(false)
    }
  }, [refreshTable])

  useEffect(() => {
    let remote_Tab_List = []
    if (!isAdmin) {
      remote_Tab_List = remote_Tab_List.concat(REMOTE_EMPLOYEE_TAB_LIST)
    }
    if (isLead || isAdmin) {
      remote_Tab_List = remote_Tab_List.concat(REMOTE_TAB_LIST)
    }
    setRemoteTabList(remote_Tab_List)
    setActiveRemoteTab(remote_Tab_List[0].key)
  }, [isLead, isAdmin])

  const onRemoteTabChange = key => {
    setActiveRemoteTab(key)
    setQueryParam({})
    setResetFilters(true)
  }

  const remoteContentList = {
    RemoteWork: (
      <div className='table-responsive'>
        <EmployeeRemoteLeaveTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          isRemote={isRemote}
          setIsRemoteLeaveDisable={setIsRemoteLeaveDisable}
          setIsUpdate={setIsUpdate}
          loaderPosition={'center'}
        />
      </div>
    ),
    RemoteApproval: (
      <div className='table-responsive'>
        <EmployeeRemoteLeaveApprovalTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
          state={state}
          setState={setState}
          checkedData={checkedData}
          setCheckedData={setCheckedData}
          loaderPosition={'center'}
        />
      </div>
    ),
    RemoteCancelApproval: (
      <div className='table-responsive'>
        <EmployeeRemoteLeaveCancelApprovalTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
          loaderPosition={'center'}
        />
      </div>
    ),
    RemoteHistory: (
      <div className='table-responsive'>
        <EmployeeRemoteLeaveHistoryTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          queryParam={queryParam}
          loaderPosition={'center'}
        />
      </div>
    ),
  }

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='customTabs row align-items-center'>
            <div className='d-flex justify-content-between align-items-start mb-3'>
              <BreadCrumbs
                list={[
                  { name: 'Dashboard', link: constRoute.home },
                  { name: 'Remote Work', link: constRoute.leaveEmployee },
                ]}
                pageTitle={'Remote Work'}
              ></BreadCrumbs>
              <ShowOnPermission mod={Remote_Work} method={POST_METHOD}>
                {activeRemoteTab === 'RemoteWork' && (
                  <StyledCustomButton
                    disabled={isRemoteLeaveDisable}
                    onClick={() => {
                      setViewDetail(false)
                      setVisible(true)
                      setIsUpdate(false)
                      setIsCancel(false)
                      setIsAddBtnDisable(false)
                      setIsRemote(true)
                    }}
                    title={'Apply For Remote'}
                  />
                )}
              </ShowOnPermission>
            </div>
            <TabCard
              className='d-flex justify-content-between align-items-center mb-4'
              tabList={remoteTabList}
              activeTabKey={activeRemoteTab}
              onTabChange={key => {
                onRemoteTabChange(key)
              }}
            ></TabCard>
            {(isAdmin || isLead) && activeRemoteTab !== 'RemoteWork' && (
              <EmployeeRemoteLeaveFilter
                activeTab={activeRemoteTab}
                setRefreshTable={setRefreshTable}
                setQueryParam={setQueryParam}
                resetFilters={resetFilters}
                queryParam={queryParam}
                setResetFilters={setResetFilters}
                setCheckedData={setCheckedData}
              />
            )}

            {checkedData.length ? (
              <StyledButton className='approved' onClick={() => setState(true)}>
                Approve
              </StyledButton>
            ) : null}
          </div>
        </div>
        {remoteContentList[activeRemoteTab]}
        {/* /Page Header */}
      </div>
      {/* /Page Content */}
      {/* Add Remote Leave Modal */}
      <StyledCustomDrawer
        title={getSideDrawerHeader(viewDetail, isUpdate, isRemote)}
        visible={visible}
        setVisible={setVisible}
        className='addDrawer'
        width={500}
        customFooter={
          !viewDetail && (
            <Space>
              <CustomButton
                htmlType='submit'
                type='primary'
                title={CANCEL}
                className='cancelButton'
                onClick={() => setVisible(false)}
              />
              <CustomButton
                disabled={isAddBtnDisable}
                form='employee-form'
                htmlType='submit'
                type='primary'
                title={isUpdate ? 'Update Remote Leave' : 'Apply For Remote'}
                className='addButton'
              />
            </Space>
          )
        }
      >
        {visible && (
          <EmployeeRemoteLeaveForm
            update={isUpdate}
            isCancel={isCancel}
            data={currentData}
            viewDetail={viewDetail}
            setVisible={setVisible}
            setRefreshTable={setRefreshTable}
            setIsAddBtnDisable={setIsAddBtnDisable}
          />
        )}
      </StyledCustomDrawer>
      {/* Delete Remote Leave Modal */}
      <DeletePopUp
        delete={onCancelLeave}
        msgTitle={
          currentData?.type?.length
            ? CANCEL_LEAVE_APPLICATION
            : DELETE_LEAVE_APPLICATION
        }
        renderMsg={
          currentData?.type == 'cancel' ? CANCEL_FULL_LEAVE_MESSAGE : null
        }
        visibleDelete={visibleDelete}
        okText={currentData?.type?.length && deleteEmployeeLeaveHandler}
        handleCancel={() => {
          setVisibleDelete(false)
          setIsDisable(true)
        }}
        type={currentData?.type || ''}
        isCancelLeavePopUp={true}
        setCancellationReason={setCancellationReason}
        setIsDisable={setIsDisable}
        isDisable={isDisable}
      />
    </div>
  )
})

export default RemoteLeave

const TabCard = styled(Card)`
  .ant-tabs-nav-more {
    visibility: hidden;
    order: 1;
  }
`
const StyledCustomDrawer = styled(CustomDrawer)`
  .ant-drawer-close {
    margin-top: 2px;
  }
`

const StyledCustomButton = styled(CustomButton)`
  float: right;
  margin-bottom: 30px !important;
`
const StyledButton = styled.button`
  @media screen and (max-width: 991px) {
    float: none;
  }
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16d61e;
  color: #ffffff;
  border-radius: 20px;
  height: 42px;
  margin-top: -15px;
  margin-bottom: 15px;
  border-color: transparent;
  width: 130px;
  .icon {
    display: flex;
    background: white;
    border-radius: 50%;
    margin-right: 4px;
    svg {
      color: #525353;
      text-align: center;
      padding: 2px;
    }
  }
`
