export const TERMINATION_TITLE = 'Termination'
export const TERMINATION = 'Termination'
export const EMPLOYEE_NAME = 'name'
export const APPLY_TERMINATION = 'Apply Termination'
export const REASON = 'reason'
export const MESSAGE_TITLE = 'Delete Termination' 
export const DASHBOARD = 'Dashboard'
export const TERMINATION_DATE = 'Termination Date'
export const RULES_MESSAGE = 'Can\'t be just whitespace'


export interface TerminationApplyProps {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    setRefreshTable: any;
}

export interface TerminationSearchProps {
    setQueryParam: any;
    setRefreshTable: any;
    setResetTable?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface formValues {
    reason: string;
    employee_id: string;
    terminated_date: string;
}

export interface TerminationFormProps {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    setRefreshTable: any;
    setIsOpenDrawer: any;
    setIsUpdate?: any;
    setVisible?: any;
    update?: any;
    data?: {
        id: number;
        branch: number;
        department: number;
        departmentName: string;
        branchName: string;
        terminationName: string;
        terminationCode: string;
        description: string;
        employeeName: string;
        isActive: boolean;
        employee: string;
        terminatedDate: Date;
        reason: string;
        annualization: boolean;
        bonuses: boolean;
        leaves: boolean;
        loans: boolean;
    };
}