import { BaseApi } from '../baseApi'
import { ExpenseUrl } from '../const/index'


class TotalExpenseAmountApi extends BaseApi {
  getTotalExpense = async () => {
    try {
      const url = `${ExpenseUrl}total-expense/`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getMonthlyExpense = async () => {
    try {
      const url = `${ExpenseUrl}monthly-expense/`
      const response = await this.axios.get(url)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  exportExpenseRecord = async format => {
    try {
         const response = await this.axios.get(
        `${ExpenseUrl}download/${format}`
        );
      return response.data;  
    } catch (error) {
      const apiError = error;
      throw apiError;
    }
  };


}

export default TotalExpenseAmountApi