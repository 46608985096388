import { useNavigate } from 'react-router-dom'
import React, { useMemo, useRef, useState } from 'react'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react-lite'
import BreadCrumbs from '@commonComponents/breadcrums'
import CustomButton from '@commonComponents/button'
import ShowOnPermission from '@commonComponents/permit'
import { Table } from '@commonComponents/table-v2'
import { PayrollUrl } from '@api/const'
import { PAYROLL_BREAD_CRUMBS } from '@mainPage/E-payroll/constant';
import CustomDrawer from '@commonComponents/drawer'
import PayrollForm from '@mainPage/E-payroll/Form'
import { getColumns } from '@mainPage/E-payroll/utils';
import ViewSalary from '@mainPage/E-payroll/viewSalary'
import PayrollHistory from '@mainPage/E-payroll/PayrollHistory'
import SearchPayroll from '@mainPage/E-payroll/searchPayroll';
import { MdDownload } from 'react-icons/md';
import { constRoute } from '@utils/route';
import { Dropdown, Menu, Space } from 'antd';
import { GET_METHOD, PAYROLL_MOD, POST_METHOD, VIEW_ALL } from 'constant/constant'
import styled from 'styled-components'
import CustomHistoryDrawer from './PayrollHistoryHeader'
import { DownloadButton } from '@mainPage/expenses/expense'
import { colors } from 'constant/colors'

const { TealishBlue } = colors

const Payroll = observer(() => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalViewOpen, setIsModalViewOpen] = useState(false);
  const tableRef = useRef(null);
  const [refreshTable, setRefreshTable] = useState(false)
  const [increasedVal, setIncreasedVal] = useState(null)
  const [recommendedBy, setRecommendedBy] = useState(null)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [editPayrollObj, setEditPayrollObj] = useState(null)
  const [queryParam, setQueryParam] = useState()
  const [resetTable, setResetTable] = useState(false)
  const [HistoryRecord, setHistoryRecord] = useState(null)

  const {
    employeePayroll: {
      exportPayrollRecord,
      closeCurrentMonthPayroll,
      updateEmployeeIncrementOrBonus,
    },
    themesModel: { getColorsData }
  } = useStore(null)

  const openPayroll = () => {
    setEditPayrollObj(null)
    setVisible(true)
  }
  const editPayroll = data => {
    setEditPayrollObj(data)
    setVisible(true)
  }
  const payRollHistoryModal = row => {
    setSelectedRecordId(row.id)
    setHistoryRecord(row)
    setIsModalOpen(true);
  };

  const SalaryCancel = () => {
    setIsModalViewOpen(false);
  }

  const assetHistoryPAth = row => {
    navigate(`${constRoute.payrollSlip}/${row.id}`);
  };

  const resetValues = () => {
    setIncreasedVal(null)
    setSelectedRecord(null)
    setSelectedType(null)
    setRecommendedBy(null)
  }

  const submitIncrementOrBonus = async () => {
    if (increasedVal || recommendedBy) {
      const data = {
        recommended_by: recommendedBy,
        employee: selectedRecord
      }
      if (selectedType === 'increment') data['increment_value'] = increasedVal
      else if (selectedType === 'bonus') data['bonus'] = increasedVal
      await updateEmployeeIncrementOrBonus(
        data, selectedType
      )
      setRefreshTable(true)
      resetValues()
    }
  }
  const closeCurrentMonth = async () => {
    await closeCurrentMonthPayroll()
    setRefreshTable(true)
  }

  const columns = useMemo(
    () => getColumns(
      {
        assetHistoryPAth,
        payRollHistoryModal,
        selectedType,
        setSelectedType,
        selectedRecord,
        setSelectedRecord,
        increasedVal,
        setIncreasedVal,
        recommendedBy,
        setRecommendedBy,
        submitIncrementOrBonus,
        resetValues,
        editPayroll
      }
    ), [increasedVal, selectedRecord, selectedType, recommendedBy])

  const handleOptionClick = ({ key }) => {
    exportPayrollRecord(key, queryParam)
  };
  const options = (
    <DropDownMenuWrapper>
      <Menu onClick={handleOptionClick}>
        <Menu.Item key='pdf'>PDF Format</Menu.Item>
        <Menu.Item key='csv'>CSV Format</Menu.Item>
      </Menu>
    </DropDownMenuWrapper>
  );

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='d-flex justify-content-between'>
            <BreadCrumbs
              list={PAYROLL_BREAD_CRUMBS}
              pageTitle={'Payroll'}
            >
            </BreadCrumbs>
            <div>
              <ShowOnPermission mod={PAYROLL_MOD} method={POST_METHOD}>
                <Addsalary
                  onClick={openPayroll}
                  title='Add Salary'
                  bgColor={getColorsData?.bodyColor}
                />
              </ShowOnPermission>
            </div>
          </div>
        </div>
        <ShowOnPermission mod={PAYROLL_MOD} method={VIEW_ALL}>
          <SearchPayroll
            setQueryParam={setQueryParam}
            resetTable={resetTable}
            setResetTable={setResetTable}
          />
        </ShowOnPermission>
        <div>
          <ShowOnPermission mod={PAYROLL_MOD} method={GET_METHOD}>
            <DownloadRecords className={`d-flex justify-content-end`}>
              <DownloadBtn >
                <Dropdown overlay={options} trigger={['hover']}>
                  <DownloadButton theme={getColorsData?.theme} bodyColor={getColorsData?.bodyColor || '#2d71d8'}>
                    <MdDownload className='downloadIcon' /> Download all records
                  </DownloadButton>
                </Dropdown>
                <DownloadButton theme={getColorsData?.theme} bodyColor={getColorsData?.bodyColor || '#2d71d8'} onClick={closeCurrentMonth}>Close Month</DownloadButton>
              </DownloadBtn>
            </DownloadRecords>
          </ShowOnPermission>
        </div>
        <div className='table-responsive'>
          <NewpayRollTable className='payrolTable'>
            <Tablestyle
              ref={tableRef}
              columns={columns}
              dataUrl={PayrollUrl}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              resetTable={resetTable}
              setResetTable={setResetTable}
              queryParam={queryParam}
              setPageNumber={() => null}
            />
          </NewpayRollTable>
        </div>
        <Payrollmodal>
          <CustomDrawer
            className='addDrawer'
            title={editPayrollObj ? 'Update Salary' : 'Add Salary'}
            setVisible={() => setVisible(false)}
            visible={visible}
            width={900}
            customFooter={
              <>
                <Space>
                  <CustomButton
                    htmlType='submit'
                    type='primary'
                    title={'Cancel'}
                    className='cancelButton'
                    onClick={() => setVisible(false)}
                  />
                  <CustomButton
                    form='payroll-form'
                    htmlType='submit'
                    type='primary'
                    title={editPayrollObj ? 'Update Salary' : 'Add Salary'}
                    className='addButton'
                  />
                </Space>
              </>
            }
          >
            <PayrollForm
              setEditModalVisible={setVisible}
              setRefreshTable={setRefreshTable}
              editPayroll={editPayrollObj}
            />
          </CustomDrawer>
        </Payrollmodal>
        {/* History Modal */}
        <>
          <CustomHistoryDrawer
            visible={isModalOpen}
            setVisible={setIsModalOpen}
            className={`addDrawer`}
            empRecord={HistoryRecord}
          >
            <PayrollHistory payrollId={selectedRecordId} />
          </CustomHistoryDrawer>
        </>
        {/* Salary View Modal */}
        <>
          <CustomDrawer
            title='Employee Salary View '
            visible={isModalViewOpen}
            setVisible={SalaryCancel}
            className='addDrawer'
            width={500}
          >
            <ViewSalary />
          </CustomDrawer>
        </>
      </div>
    </div>
  )
})
export default Payroll

const DropDownMenuWrapper = styled.div`
    ul{
      border:1px solid rgb(45, 113, 216);
      border-radius: 8px;
      li{
        transition: all .4s ease-in;
        :hover{
          color: black;
          span{
            color: black;
          }
        }
      }
    }
`
const Addsalary = styled(CustomButton)`
  background: ${props => props.bgColor || TealishBlue};
  border: 1px solid ${props => props.bgColor || TealishBlue} !important;
  border-radius: 31px !important;
  width: 145px;
  height: 48px;
`
const DownloadRecords = styled.div`
  margin-bottom: 20px;
  left: 15px;
  right: 15px;
`
const DownloadBtn = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .donwloadAllRecord {
    background: ${TealishBlue};
    border: 1px solid ${TealishBlue} !important;
    color: white;
    &:hover {
      color: #0958d9;
    }
  }
  button {
    background: #ffffff;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    height: 36px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #525353;
    svg {
      color: #0958d9;
      font-size: 18px;
      position: relative;
      top: 4px;
    }
  }
`

const NewpayRollTable = styled.div`
  position: relative;
  .tableStyle {
    overflow: auto !important;
  }
  .dKwBFP {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    padding: 10px 0px;
  }
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        border-right: 1px solid #e9e4e4;
        line-height: 22px;
        &::before {
          right: 0 !important;
          width: 0 !important;
        }
      }
      tr {
        th {
          font-family: 'Outfit';
          font-style: normal;
          font-weight: 600;
          font-size: 18px !important;
          line-height: 23px;
          color: #000000;
          word-spacing: 1px;
        }
      }
      .ant-table-cell:nth-child(8) {
        text-align: center;
      }
    }
    .ant-table-tbody {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      tr {
        .ant-table-cell:nth-child(1) {
          padding-left: 16px !important;
          color: #525353;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          font-weight: 700;
          line-height: 24px;
          word-spacing: 1px !important;
        }
      }
      .ant-table-cell:nth-child(3),
      .ant-table-cell:nth-child(4) {
        position: relative;
        .editCircle,
        .mdcheck {
          width: 24px;
          height: 24px;
          background-color: #2d71d826;
          border-radius: 28.8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          svg {
            fill: currentColor;
            
            width: 12px;
            height: 12px;
          }
        }
        .editCircle {
          position: absolute;
          top: 14px;
          right: 15px;
        }
        .bonusInput {
          height: 30px !important;
          width: 80px !important;
        }
        .close {
          position: relative;
          top: 4px;
          cursor: pointer;
          svg {
            color: #525353;
          }
        }
        .MdAddCircleOutline {
          cursor: pointer;
         
        }
      }
      .ant-table-cell:nth-child(4) {
        .editCircle {
          position: absolute;
          top: 14px;
          right: 15px;
        }
      }
    }
    tr {
      .ant-table-cell {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    td {
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #525353;
      }
    }
  }
  .ant-table-wrapper {
    overflow: auto !important;
  }
  .ant-pagination {
    border-top: 0 !important;
    padding-right: 17px;
  }
`
const Tablestyle = styled(Table)`
  th {
    min-width: 167px !important;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  tr {
    .ant-table-cell {
      text-overflow: ellipsis;
      min-width: 187px !important;
      max-width: 240px;
      overflow: hidden;
      white-space: nowrap;
      p,
      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ant-table-cell:nth-child(1) {
      max-width: 240px;
      .ant-avatar-circle {
        position: relative;
        display: grid;
        place-items: center;
      }
      h2 {
        a {
          &:last-child {
            max-width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
`
const Payrollmodal = styled.div`
width: 700px !important;
`