import { headerAuthorization } from '../common-utils'

import { BaseApi } from '../baseApi'
import { baseUrl } from '../const/index'

class AssetsStatsApi extends BaseApi {
  setAssetsStats = async data => {
    try {
      const response = await this.axios.post(
        `${baseUrl}asset_stats/`,
        data,
        headerAuthorization(this)
      )
    
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
 
  getAssetsStats = async () => {
    try {
      const response = await this.axios.get(
        `${baseUrl}asset_stats/`,
        headerAuthorization(this)
      )
    
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default AssetsStatsApi