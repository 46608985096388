import { BaseApi } from '../baseApi'
import { attendanceTimer, employeeServiceUrl } from '../const/index'

class PunchApi extends BaseApi {
  getPunch = async () => {
    try {
      const response = await this.axios.get(attendanceTimer)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  setPunch = async (latitude, longitude, punchType) => {
    try {
      const res = await this.axios.post(`${employeeServiceUrl}attendance/recess`, {
        latitude,
        longitude,
        status: punchType,
      })
      if (res.data.punch) return { success: true }
      this.getPunch()
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getSinglePunch = async (emp_id: string, clickedDate) => {
    const url = `${employeeServiceUrl}attendance/work_report`
    try {
      const response = await this.axios.get(`${url}`, {
        params: { custom_date: clickedDate, employee_id: emp_id },
      })
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default PunchApi
