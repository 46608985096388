import React, { useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import CustomButton from '@commonComponents/button'
import { useStore } from '@stores/root-store'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { validateRequired } from '@utils/common-functions/formValidations'
export interface SectionProps {
  update: boolean;
  data?: {
    id: number;
    branch: number;
    department: number;
    departmentName: string;
    branchName: string;
    sectionName: string;
    sectionCode: string;
    description: string;
    isActive: boolean;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setIsUpdate: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setVisible: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRefreshTable: any;

}

const LeadForm = observer(props => {
  const [form] = Form.useForm()
  
  const {
    employeeDetails: { getEmployeeData, loadEmployeeDetails, nextEmp, updateEmployeeDetails },
    leadsList: {  loadLeads },
  } = useStore(null)

  useEffect(() => {
    async function load() {
      if (!getEmployeeData) {
        await loadEmployeeDetails(1, 10, '', true)
      }
    }
    load()
  }, [])

  const submitData = async data=>{
    const dataLead = {
      'id': data.employee,
      'is_lead': true
    }
    await updateEmployeeDetails(data.employee, dataLead, false)
    form.resetFields()
    props.setRefreshTable(true)
    await loadLeads()

  }
  return (
    <Form
      form={form}
      layout='vertical'
      id='section-form'
      onFinish={submitData}
    >
      <Row className='align-items-center'>
        <Col xs={24} sm={8}>
          <DynamicSelect
            formLabel='Select Employee'
            getData={getEmployeeData}
            loadData={loadEmployeeDetails}
            valueKey1={'firstName'}
            valueKey2={'lastName'}
            isForm={true}
            name={'employee'}
            fetchData={true}
            formRules={[validateRequired('Employee name')]}
            placeholder='Select Employee'
            nextPage={nextEmp} />
        </Col>
        <Col sm={10} xs={24} className='m-4'>
          <div>
            <CustomButton
              htmlType='submit'
              title='Add Lead'
            />
          </div>
        </Col>
        <Col span={13}></Col>
      </Row>
    </Form>
  )
})

export default LeadForm
