import React from 'react'
import { Button } from 'antd'
import style from './style.module.scss'
import { ButtonHTMLType } from 'antd/lib/button/button'
import { ButtonType, ButtonShape } from 'antd/lib/button'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import classNames from 'classnames'

export interface buttonProps {
  className?: string;
  block?: boolean;
  danger?: boolean;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  form?: string;
  htmlType?: ButtonHTMLType;
  icon?: React.ReactNode;
  loading?: boolean;
  shape?: ButtonShape;
  size?: SizeType;
  target?: string;
  type?: ButtonType;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  title?: any;
  cancelBtn?: boolean;
  style?: any;
  onClick?: React.MouseEventHandler<HTMLElement>;
  id?: string;
}

const CustomButton = (props: buttonProps) => {
  return (
    <div className={props.cancelBtn ? style.commonCancel : style.commonButton} style={{ width: 'auto' }}>
      <Button
        className={classNames(props.className)}
        block={props.block}
        danger={props.danger}
        disabled={props.disabled}
        ghost={props.ghost}
        href={props.href}
        htmlType={props.htmlType}
        form={props.form}
        icon={props.icon}
        loading={props.loading}
        shape={props.shape}
        size={props.size}
        target={props.target}
        type={props.type}
        onClick={props.onClick}
        style={props.style}
        id={props?.id}
        {...props}
      >
        {props.title}
      </Button>
    </div>
  )
}

export default CustomButton
