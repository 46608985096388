import { Col, Form, Row } from 'antd'
import React from 'react'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import SearchFilter from '@commonComponents/SearchFilter';
import { SearchOutlined } from '@ant-design/icons'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const SearchAllowance = observer((props: any) => {

  return (
    <>
      <SearchFilter
        {...props}
        children={
          <Row gutter={4} className={`rowDiv flex-grow-1`}>
            <Col xs={24} sm={12} md={12} lg={5}>
              <Form.Item name='title'>
                <CommonInput placeholder='Allowance Name' prefix={<SearchOutlined />} />
              </Form.Item>
            </Col>
          </Row>
        }
      ></SearchFilter>
    </>
  )

})
export default SearchAllowance;
