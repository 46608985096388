import React, { useState, useRef } from 'react'
import { Table } from '@commonComponents/table-v2'
import { observer } from 'mobx-react'
import { loanUrl } from '@api/const'


/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const LoanTable = observer(({ refreshTable, setRefreshTable, queryParam, resetTable, setResetTable, columns}: any) => {
  const [page, setPage] = useState(null)
  const tableRef = useRef(null)


  return (
    <>
   <div className='table-responsive'>
      <Table
        ref={tableRef}
        dataUrl={loanUrl}
        columns={columns}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        queryParam={queryParam}
        setPageNumber={() => setPage}
        resetTable={resetTable}
        setResetTable={setResetTable}
      />
      </div>
      <div style={{ display: 'none' }}>{page}</div>
    </>
  )
}
)

export default LoanTable


