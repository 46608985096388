import { flow, types } from 'mobx-state-tree'
import { boardProjectApi, boardTaskApi, boardLabelApi } from '../../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'

export const assigneeList = types.model({
  name: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
})

export const labelList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
})

export const esTimeObj = types.model({
  currentDate: types.maybeNull(types.string),
  timeAssign: types.maybeNull(types.string),
  timePassed: types.maybeNull(types.string),
})

export const createDetails = types.model({
  user: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
})
export const historyType = types.model({
  type: types.maybeNull(types.string),
  holder: types.maybeNull(types.string),
})
export const history = types.model({
  creater: types.maybeNull(types.string),
  action: types.maybeNull(types.string),
  fieldType: types.maybeNull(historyType),
  timestamp: types.maybeNull(types.string),
})

export const commentData = types.model({
  img: types.maybeNull(types.string),
  user: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
})
export const taskList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  dueDate: types.maybeNull(types.string),
  BoardList: types.maybeNull(types.number),
  assignee: types.maybeNull(types.array(assigneeList)),
  labels: types.maybeNull(types.array(labelList)),
})

export const bLabelData = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  textColor: types.maybeNull(types.string),
  boardID: types.maybeNull(types.number),
})

export const baordListDetail = types.model({
  id: types.maybeNull(types.number),
  board: types.maybeNull(types.number),
  boardLabel: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  taskList: types.maybeNull(types.array(taskList)),
})

export const companyData = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  noEmployees: types.maybeNull(types.string),
  service: types.maybeNull(types.string),
})

export const projectData = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  client: types.maybeNull(types.string),
})

export const assigneeDetails = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  employeeImage: types.maybeNull(types.string),
})

export const labelDetails = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  textColor: types.maybeNull(types.string),
  board: types.maybeNull(types.number),
})

export const sprintTaskList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  dueDate: types.maybeNull(types.string),
  BoardList: types.maybeNull(types.number),
  boardListName: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  assignee: types.maybeNull(types.array(types.string)),
  labels: types.maybeNull(types.array(types.number)),
  assigneeDetails: types.maybeNull(types.array(assigneeDetails)),
  labelDetails: types.maybeNull(types.array(labelDetails)),
  taskStatus: types.maybeNull(types.string),
  priority: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  estimatedTime: types.maybeNull(types.string),
  attachments: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.number),
  assignedBy: types.maybeNull(types.number),
  assignedTo: types.maybeNull(types.number),
  comments: types.maybeNull(types.string),
  project: types.maybeNull(types.string),
  projectName: types.maybeNull(types.string),
  sprintName: types.maybeNull(types.string),
  trackedTime: types.maybeNull(types.string),
  sprint: types.maybeNull(types.string),
})
// Board Listt Data

export const boardListData = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  sprintName: types.maybeNull(types.string),
  sprint: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  tasks: types.maybeNull(types.array(sprintTaskList)),
})
// Sprint Data
export const sprintsModel = types.model({

})
// Board Data
export const boardData = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  projectId: types.maybeNull(types.string),
  projectName: types.maybeNull(types.string),
  sprints: types.maybeNull(types.array(types.string)),
  updatedAt: types.maybeNull(types.string),
})


export const getSprintFields = types.model({
  id: types.maybeNull(types.string),
  durationWeeks: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  startTime: types.maybeNull(types.string),
  nonWorkingDays: types.maybeNull(types.array(types.string)),
  board: types.maybeNull(types.maybeNull(types.number)),
  boardLists: types.maybeNull(types.array(types.number))
})
export const boardDetails = types
  .model({
    data: types.maybeNull(types.array(boardData)),
    listData: types.maybeNull(types.array(boardListData)),
    dataLabel: types.maybeNull(types.array(bLabelData)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    sprintList: types.maybeNull(types.array(getSprintFields))
  })
  .views(self => ({
    get getBoardListData(){
      return toJS(self.listData)
    },
    get getCompleteBoard() {
      return toJS(self.data)
    },
    get getDataLabel() {
      return toJS(self.dataLabel)
    },
    get isLoading() {
      return self.loading
    },
    get getCount() {
      return self.count
    },
    get getSprint(){
      return toJS(self.sprintList)
    }
  }))
  .actions(self => {
    // board detail apis {

    const setBoardListDetails = flow(function* (data) {
      try {
        self.loading = true
        yield boardProjectApi.setBoardListDetails(data)
        notification.success('Board List Created Successfully!')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const loadBoardDetails = flow(function* (boardID) {
      try {
        self.loading = true
        const res = yield boardProjectApi.getBoardProjectDetails(
          boardID
        )
        self.data = res.data
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Board Details')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadBoardListDetails = flow(function* (sprintID) {
      try {
        self.loading = true
        const res = yield boardProjectApi.getBoardListDetails(
          sprintID
        )
        self.listData = res.data
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Board List Details')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })
    

    const updateBoardListDetails = flow(function* (data) {
      try {
        self.loading = true
        yield boardProjectApi.updateBoardListDetails(data)
        notification.success('Board List Updated Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    // Sprint Store

    const loadSprintList = flow(function* (id) {
      try {
        self.loading = true
        const res = yield boardLabelApi.getProjectSprint(id)
        self.sprintList = res?.data
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to load Sprint List')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const postSprintDetails = flow(function* (data) {
      try {
        self.loading = true
        yield boardLabelApi.postSprint(data)
        notification.success('Sprint Created Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const deleteSprint = flow(function* (id) {
      try {
        self.loading = true
        yield boardLabelApi.deleteSprint(id)
        notification.success('Sprint deleted successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })


    const deleteBoardListDetails = flow(function* (id) {
      try {
        self.loading = true
        yield boardProjectApi.deleteBoardListDetails(id)
        notification.success('Board List Deleted Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    // BOARD ETAIL API ENDS }

    // board Label APIS {

    const setLabelDetail = flow(function* (data, id) {
      try {
        self.loading = true
        yield boardLabelApi.setBoardLabelDetails(data, id)
        notification.success('Board Label Created Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const loadLabelDetail = flow(function* (id) {
      try {
        self.loading = true
        const res = yield boardLabelApi.getBoardLabelDetails(id)
        self.dataLabel = res.data
        self.count = res.count
      } catch (error) {
        notification.error(error?.response?.data?.message)
      } finally {
        self.loading = false
      }
    })

    const updateLabelDetail = flow(function* (boardID, labelID, data) {
      try {
        yield boardLabelApi.updateBoardLabelDetails(boardID, labelID, data)
        notification.success('Board Label Updated Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const deleteLabelDetail = flow(function* (labelID, boardID) {
      try {
        self.loading = true
        yield boardLabelApi.deleteBoardLabelDetails(labelID, boardID)
        notification.success('Board Label Deleted Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    // board Label Apis end }

    // tASK APIS {

    const setSprintTaskDetails = flow(function* (data) {
      try {
        self.loading = true
        yield boardTaskApi.setSprintTaskDetails(data)
        notification.success('Task Created successfully!')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const updateTaskDetails = flow(function* (updatedData, taskID) {
      try {
        self.loading = true
        yield boardTaskApi.updateBoardTaskDetails(
          updatedData,
          taskID
        )
        notification.success('Task Updated Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })

    const deleteTaskDetails = flow(function* (cid) {
      try {
        self.loading = true
        yield boardTaskApi.deleteBoardTaskDetails(cid)
        notification.success('Task Deleted Successfully')
        self.loading = false
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        self.loading = false
        return { success: false }
      }
    })


    // TASK API ENDS }
    return {
      setBoardListDetails,
      loadBoardListDetails,
      loadBoardDetails,
      deleteBoardListDetails,
      updateBoardListDetails,
      setSprintTaskDetails,
      updateTaskDetails,
      deleteTaskDetails,
      setLabelDetail,
      updateLabelDetail,
      deleteLabelDetail,
      loadLabelDetail,
      loadSprintList,
      postSprintDetails,
      deleteSprint
    }
  })

export function initBoardDetails() {
  return boardDetails.create({})
}
