import { cast, flow, types } from 'mobx-state-tree'
import { branchApi } from '../../api'
import { toJS } from 'mobx'
import { notification } from '@utils/notifications'

export const branch = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  zip: types.maybeNull(types.string),
  parent_branch: types.maybeNull(types.string),
  radius: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  contactNumber: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  isMain: types.maybeNull(types.boolean),
  latitude: types.maybeNull(types.string),
  longitude: types.maybeNull(types.string),
})

export const branchModel = types
  .model({
    data: types.maybeNull(types.array(branch)),
    loading: types.optional(types.boolean, false),
    nextBranchPage: types.optional(types.number, 1)
  })
  .views(self => ({
    get getBranchData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))

  .actions(self => {
    const setBranchData = flow(function* (data) {
      try {
        self.loading = true
        yield branchApi.setBranch(data)
        notification.success('Branch Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const loadBranch = flow(function* (page = 1, pageSize = 20, queryParam = '', resetList = false) {
      try {
        self.loading = true
        if (resetList || !!queryParam) {
          self.data = cast([])
          self.nextBranchPage = 1
        }
        const res = yield branchApi.getBranch(page, pageSize, queryParam)
        if (!res?.isCancel) {
          self.data = cast([...toJS(self.data ?? []), ...res.items])
          if (res.next) {
            self.nextBranchPage = self.nextBranchPage + 1
          } else {
            self.nextBranchPage = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message || 'Failed to Load Branch';
        notification.error(message);
        return { success: false }
        throw error
      } finally {
        self.loading = false
      }
    })

    const updateBranch = flow(function* (id, data) {
      try {
        self.loading = true
        yield branchApi.updateBranch(data, id)
        notification.success('Branch Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteBranch = flow(function* (id) {
      try {
        self.loading = true
        yield branchApi.deleteBranch(id)
        notification.success('Branch Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setBranchData,
      loadBranch,
      updateBranch,
      deleteBranch,
    }
  })

export function initBranchModel() {
  return branchModel.create({})
}
