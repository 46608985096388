import { SearchOutlined } from '@ant-design/icons'
import SearchFilter from '@commonComponents/SearchFilter'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import { useStore } from '@stores/root-store'
import { permit } from '@utils/permission/permission'
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
interface SearchEmpProps {
  setQueryParam: (data: object) => void;
  checkedFilter?: string;
  showHierarchy?: boolean;
  hideSupervisorFilter?: boolean;
  loadAllEmployeeList?: () => void;
  loadLeadRole?: any;
  getLeadRole?: any;
  loadLeadDesignation?: any;
  getDesignationData?: any;
  getEmployeeAllList?: any;
  nextEmp?: number;
}
const SearchEmployees = observer((props: SearchEmpProps) => {
  const {
    employmentCompany: { loadEmploymentCompany, getEmploymentCompanyData },
    userInfo: { getRoles }
  } = useStore(null)

  const { accessView } = getRoles
  const isAdmin = accessView?.includes('is_admin')
  const View_All = permit(getRoles, 'employee', 'view_all')
  useEffect(() => {
    async function loadData() {
      await loadEmploymentCompany(1, 20, '')
    }
    if (permit(getRoles, 'employee', 'view_all')) {
      loadData()
    }
  }, [])
  return (
    <div className='row'>
      <SearchFilter
        {...props}
        calledFrom='employee'
        children={
          <Row
            gutter={2}
            className={`rowDiv gap-lg-0  gap-xl-0 gap-xxl-2 flex-grow-1`}
          >
            <Col xs={24} sm={12} lg={5}>
              <div>
                <Form.Item name={'full_name'}>
                  <CommonInput
                    placeholder='Employee'
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={4}>
              <DynamicSelect
                isForm={true}
                name={'designation_id'}
                getData={props.getDesignationData}
                loadData={props.loadLeadDesignation}
                nextPage={1}
                valueKey1={'title'}
                placeholder={' Designation'}
                clearMyData={true}
                fetchData={false}
              />
            </Col>
            <Col xs={24} sm={12} lg={4}>
              <DynamicSelect
                isForm={true}
                name={'role_id'}
                getData={props.getLeadRole}
                loadData={props.loadLeadRole}
                nextPage={1}
                valueKey1={'title'}
                placeholder={'Role'}
                clearMyData={true}
                fetchData={false}
              />
            </Col>
            {(View_All || isAdmin) && !props.hideSupervisorFilter && (
              <Col xs={24} sm={12} lg={4}>
                <DynamicSelect
                  isForm={true}
                  name={'supervisor_id'}
                  getData={props.getEmployeeAllList}
                  loadData={props.loadAllEmployeeList}
                  nextPage={props.nextEmp}
                  valueKey1={'fullName'}
                  placeholder={'Supervisor'}
                  clearMyData={true}
                  fetchData={false}
                />
              </Col>
            )}
            <Col xs={24} sm={12} lg={4}>
              <DynamicSelect
                isForm={true}
                getData={getEmploymentCompanyData}
                loadData={loadEmploymentCompany}
                nextPage={1}
                allowClear
                valueKey1={'title'}
                name={'employment'}
                placeholder={'Employment'}
                fetchData={false}
                clearMyData={true}
              />
            </Col>
          </Row>
        }
      />
    </div>
  )
})
export default SearchEmployees
