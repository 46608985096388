import React, { useState, useEffect } from 'react'
import { Col, Form, Row, TimePicker } from 'antd'
import { observer } from 'mobx-react'
import CustomButton from '@commonComponents/button'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import moment from 'moment'
import { getAlphabetRegex } from '@utils/common-functions/formValidations'
export interface ShiftProps {
  update: boolean;
  data?: {
    id: number;
    startTime: string;
    endTime: string;
    note: string;
    title: string;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setIsUpdate: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setVisible: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setRefreshTable: any;
}

const ShiftForm = observer(({ update, data, setIsUpdate, setVisible, setRefreshTable }: ShiftProps) => {
  const [form] = Form.useForm()
  const [submitLoading, setSubmitLoading] = useState(false)
  const {
    shiftsModal: {
      updateShift,
      setShift,
    },
  } = useStore(null)

  useEffect(() => {
    form.resetFields()
    if (update) {
      form.setFieldsValue({
        title: data.title,
        startTime: data.startTime ? moment(data.startTime, 'HH:mm:ss') : null,
        endTime: data.endTime ? moment(data.endTime, 'HH:mm:ss') : null,
        note: data.note,
      })
    }
  }, [data, update])

  const submitData = async values => {
    setSubmitLoading(true)
    values.start_time = values.startTime.format('HH:mm:ss')
    values.end_time = values.endTime.format('HH:mm:ss')
    values.off_days = [
      '2',
      '34'
    ],
      values.add_note = values.note

    let response = null
    if (update) {
      response = await updateShift(data.id, values)
    } else {
      response = await setShift(values)
    }
    if (response?.success) {
      setRefreshTable(true)
      setVisible(false)
      form.resetFields()
      setIsUpdate(false)
    }
    setSubmitLoading(false)
  }
  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={submitData}
      id='shift-form'
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name='title'
            label='Title'
            rules={[{ required: true, message: 'Shift name is required' }, {
              whitespace: true,
              message: 'Can\'t be just whitespace',
            },
            {
              message: 'Use Only Alphabets',
              pattern: getAlphabetRegex(),
            },
            ]}
          >
            <CommonInput className='inputField' inputType='text' placeholder='Title of Shift' />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name='startTime'
            label='Start Time'
            rules={[{ required: true, message: 'Start Time is required' }]}
          >
            <TimePicker
              format='h:mm A'
              getPopupContainer={trigger => trigger.parentElement}
              className='form-control'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name='endTime'
            label='End Time'
            rules={[{ required: true, message: 'End Time is required' }]}
          >
            <TimePicker
              format='h:mm A'
              getPopupContainer={trigger => trigger.parentElement}
              className='form-control'
            />
          </Form.Item>
        </Col>
      </Row>  
      <Row>
        <Col span={24}>
          <Form.Item name='note' label='Add Note'>
            <CommonInput className='inputField' inputType='textarea' placeholder='Additional Information' />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} className='d-none'>
          <CustomButton
            htmlType='submit'
            type='primary'
            title={update ? 'Update' : 'Add'}
            loading={submitLoading}
            shape='round'
          />
        </Col>
      </Row>
    </Form>
  )
})

export default ShiftForm
