import React, { useEffect } from 'react'
import { Form, Col, Row, Radio } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import { CommonSelect } from '@commonComponents/select'
import { StyleCommonInput } from '@mainPage/userResignation/resignationapplyForm'
import { getAlphabetRegex } from '@utils/common-functions/formValidations'
import { colors } from 'constant/colors'

const { red } = colors
interface FormValues {
  category_type: string;
  title: string;
  parent_category_id: string;
  description: string;
}

const categoryForm = observer(
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ({ update, data, setVisible, setRefreshTable }: any) => {
    const [form] = Form.useForm()
    const {
      hrCategoryModal: { loadCategory, setCategoryData, updateCategory, getCategoriesData },
    } = useStore(null)

    useEffect(() => {
      const load = async () => {
        await loadCategory()
      }
      load()
    }, [])


    useEffect(() => {
      form.resetFields()
      if (update) {
        form.setFieldsValue({
          category_type: data.categoryType,
          title: data.title,
          parent_category_id: data.parentCategoryId,
          description: data.description,
        })
      }
    }, [data, update])

    const submitData = async (values: FormValues) => {
      let response = null
      if (update) {
        response = await updateCategory(data.id, values)
      } else {
        response = await setCategoryData(values)
      }
      if (response?.status) {
        setRefreshTable(true);
        setVisible(false)
        form.resetFields()
      }
    }

    const getCategories = getCategoriesData?.map(({ id, title }) => ({ key: id, value: title }));

    return (
      <div>
        <Form
          form={form}
          onFinish={submitData}
          layout='vertical'
          name='category-form'
          id='category-form'
        >
          <div>
            <Row>
              <Icon>Type</Icon>
            </Row>
            <StyledRadioBtnWrapper>
              <Form.Item name='category_type' initialValue={'asset'}>
                <Radio.Group >
                  <Radio value='asset' >Asset Category</Radio>
                  <Radio value='expense'>Expense Category</Radio>
                </Radio.Group>
              </Form.Item>
            </StyledRadioBtnWrapper>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={'title'}
                  label='Category Name'
                  rules={[
                    { required: true, message: 'Category Name is required' },
                    { whitespace: true, message: 'Can\'t be just whitespace' },
                    { message: 'Use Only Alphabets', pattern: getAlphabetRegex() },
                  ]}
                >
                  <CommonInput placeholder='Anyone' />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} >
                <Form.Item
                  label='Parent Category'
                  name={'parent_category_id'}>
                  <CommonSelect
                    listHeight={125}
                    getPopupContainer={trigger => trigger.parentNode}
                    placeholder='Select Parent Category'
                    data={getCategories}
                    showSearch
                    filterOption={(input, option) => (option?.children?.toLowerCase().includes(input.toLowerCase()))} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Form.Item name={'description'} label='Description'>
                  <StyleCommonInput inputType='textarea' placeholder='Describe All About' />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    )
  }
)

export default categoryForm


const StyledRadioBtnWrapper = styled.div`
  margin: 21px 0px 29px 33px;
  .ant-radio-inner {
    width: 24px;
    height: 24px;
    margin: 0px 3px -3.5px 0px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 26.4px;
    &::after {
      width: 24px;
      height: 24px;
      top: 7px;
      left: 7px;
    }
  }
`

const Icon = styled.span`
::after{
  display: inline-block;
  margin-right: 4px;
  color: ${red};
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
`