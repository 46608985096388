import { toJS } from 'mobx'
import { flow, types } from 'mobx-state-tree'
import { notification } from '@utils/notifications'
import { changePasswordApi } from '../../api'

export const password = types.model({
  password: types.maybeNull(types.array(types.string)),
  password2: types.maybeNull(types.array(types.string)),
  token: types.maybeNull(types.array(types.string)),
})

export const passwordModal = types
  .model({
    data: types.maybeNull(types.array(password)),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get getEmail() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setPassword = flow(function* (data) {
      try {
        self.loading = true
        yield changePasswordApi.setPassword(data)
        notification.success('Password Changed successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.password?.[0] || 'Something Went wrong. Please try again.')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    return {
      setPassword,
    }
  })

export function initPassword() {
  return passwordModal.create({})
}
