import moment from 'moment';
// import BarGraph from './barGraph';
// import { Line } from '@antv/g2plot';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import style from './style.module.scss';
import { colors } from 'constant/colors';
// import MyPieChart from './expenseSummary';
// import ProjectTable from './projectTable';
// import { Chart } from 'react-google-charts';
// import OnboardingPie from './onboardingPie';
import { useStore } from '@stores/root-store';
import React, { useEffect, useState } from 'react';
// import EmployeeLoanTable from './employeeloanTable';
import 'react-circular-progressbar/dist/styles.css';
import { LoadingOutlined, } from '@ant-design/icons';
import { hasPermission } from '@utils/common-functions'
import DashboardCards from '../Dashboard/dashBoardCards';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
// import { Badge, Card, Col, Row, Skeleton, Avatar } from 'antd';
// import { CircularProgressbar } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmployeeDashboard from '@mainPage/EmployeeDashboard/employeeDashboard';

const { TealishBlue } = colors


      {/*Don't Remove Commented Code from this Component*/}


const Dashboard = observer(() => {
  const [themeColor, setThemeColor] = useState(null);
  // const [blackTheme, setBlackTheme] = useState(null);

  const {
    // newDashboardModal: { loadNewDashboard, expenseGraphData, getexpenseGraphdata, getDashBoardData, isLoading },
    DashBoardModel: { loadEmployeeAttendanceOverview, employeeAttendanceData, loadSingleEmployeeAttendance, singleEmployeeAttendanceData },
    userInfo: { getRoles , getcompany }
  } = useStore(null)

  useEffect(() => {
    async function load() {
      if(getcompany?.isLead) {
     await loadEmployeeAttendanceOverview('/')
      } else {
     await loadSingleEmployeeAttendance()
    }}
    load()
  }, [])
  
  async function submitFilterData(date: moment.MomentInput) {
    if (date) {
      await loadEmployeeAttendanceOverview({ custom_date: moment(date).format('YYYY-MM-DD') });
    } else {
      await loadEmployeeAttendanceOverview('/');
    }
  }

  const isAdmin = hasPermission(getRoles, 'accessView', ['is_admin']);
  const isLead = getcompany?.isLead;


  // useEffect(() => {
  //   async function load() {
  //     await loadNewDashboard('/')
  //   }
  //   (isAdmin || isLead) && load();
  // }, [])

  // useEffect(() => {
  //   async function loadExpense() {
  //     return await expenseGraphData()
  //   }
  //   loadExpense();
  // }, [])


  const [greeting, setGreeting] = useState('');
  // const data = [
  //   ['Task', 'Hours per Day'],
  //   ['Amount Paid', 45],
  //   ['Remaining Amount', 55],
  //   ['Loaned Employee', 22],
  // ];

  // const options = {
  //   is3D: true,
  //   colors: ['#D3D8DC', '#3D4448', '#50959A', '#D6494D', '#16D61E'],
  //   legend: {
  //     alignment: 'center',
  //     position: 'top',
  //     maxLines: 2,
  //   },
  // }


  if (!(isAdmin || isLead)) return <EmployeeDashboard getcompany={getcompany} singleEmployeeAttendanceData={singleEmployeeAttendanceData} />

  useEffect(() => {

    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour >= 12 && currentHour < 17) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  }, []);

  // Comment till the Remaining modules are Complete 

  // {
  //   isAdmin &&
  //     useEffect(() => {
  //       const allMonth = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July',
  //         'August', 'September', 'October', 'November', 'December']
  //       blackTheme
  //       const assetexpense = getexpenseGraphdata?.assetExpense
  //       const slaryExpense = getexpenseGraphdata?.salaryExpense
  //       const allData = assetexpense?.concat(slaryExpense)
  //       const datas = allData?.map(item => ({
  //         ...item, month: String(item.month)
  //       }))
  //       const data = []
  //       datas?.map(item => {
  //         const replaceData = allMonth[item?.month]

  //         item.month = replaceData

  //         data.push(item)
  //       })

  //       const monthLineChart = new Line('lineChart', {
  //         data,
  //         xField: 'month',
  //         yField: 'value',
  //         seriesField: 'line',
  //         smooth: true,
  //         lineStyle: {
  //           lineWidth: 2,
  //         },
  //         point: {
  //           size: 3,
  //         },
  //       });
  //       monthLineChart.render();

  //       return () => {
  //         monthLineChart.destroy();
  //       };

  //     }, [data]);

  // }
  const [color, setColor] = useState(null)
  useEffect(() => {
    const bodycolor = JSON.parse(localStorage.getItem('colorTheme'))
    setColor(bodycolor?.bodyColor || TealishBlue)
  }, [])

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem('colorTheme'));
    setThemeColor(storedTheme ? storedTheme?.bodyColor : TealishBlue);
    // setBlackTheme(storedTheme?.theme === 'dark' ? 'white' : 'black')
  })


  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div style={{ padding: '0px', height: '70px', marginTop: '0px' }}
          className={`${style.dashboardHeader} page-header d-flex justify-content-between w-100`}
        >
          <div style={{ width: '100%', marginRight: '2%' }} className={`${style.dashboardTitle} row align-items-center`}>
            <Greeting>
              {greeting}{' '}
              <span style={{ color: themeColor }}>
                {getcompany?.user?.firstName}
              </span>{' '}
              <span style={{ color: themeColor }}>
                {getcompany?.user?.lastName || ''}
              </span>
            </Greeting>
            <GreetingWelcome>Welcome to your dashboard</GreetingWelcome>
          </div>
          <EmployeeDiv className={style.totalEmployee} >
            <p> <TotalEmployee>{employeeAttendanceData?.totalNumEmployees || <LoadingOutlined width={10} height={50} />}</TotalEmployee>
              <FontAwesomeIcon icon={faUsers} /></p>
            <p color={color} >Total Employee</p>
          </EmployeeDiv>

        </div>
        <DashboardCards
          employeeAttendanceData={employeeAttendanceData}
          submitFilterData={submitFilterData}
        />

      {/* Comment till the Remaining modules are Complete */}

        {/* <Col span={24}>
          <ProjectCardStyle>
            <div className={style.tableWrapper} >
              <ProjectTable />
            </div>
          </ProjectCardStyle>
        </Col> 
          {getcompany?.admin &&
          <div className={`${style.graphSection} mt-4`}>
            <Row gutter={[16, 16]}>
              <Col span={16} xs={24} lg={16} >
                <Card>
                  <div className={style.cardHeader}>
                    <ExpenseStyle >Expense Summary</ExpenseStyle>
                  </div>
                  <div style={
                    { padding: ' 12px 25px' }
                  } id='lineChart'></div>
                </Card>
              </Col>
              <Col span={8} xs={24} lg={8}>
                <Card>
                  <AssetData>Asset Statistics</AssetData>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    {getDashBoardData?.assetDetail?.[0] &&
                      Object.keys(getDashBoardData?.assetDetail?.[0])
                        .map(percentage => ({
                          key: percentage,
                          value: getDashBoardData.assetDetail[0]?.[percentage] || 0,
                        }))
                        .sort((a, b) => b.value - a.value)
                        .map(({ key, value }, index) => (
                          <div
                            key={index}
                            style={{
                              width: '100px',
                              margin: '5px',
                            }}
                          >
                            <Progressbars
                              value={value}
                              text={`${value}%`}
                              styles={{
                                root: {},
                                path: {
                                  stroke:
                                    index === 0 ? ' #0b641bd9' :
                                      index === 4 ? '#3F51B5' :
                                        index === 2 ? '#FFC107' :
                                          index === 3 ? '#FF5722' :
                                            index === 1 ? '#9E9E9E' :
                                              '',
                                  strokeWidth: '10px',
                                },
                                text: {
                                  fill:
                                    index === 0 ? '#0b641bd9' :
                                      index === 1 ? '#3F51B5' :
                                        index === 2 ? '#FFC107' :
                                          index === 3 ? '#FF5722' :
                                            index === 4 ? '#9E9E9E' :
                                              '',
                                  fontSize: '24px',
                                },
                              }}
                            />
                            <p className='text-center'>
                              {key?.replace(/asset/i, '').toLocaleUpperCase()}</p>
                          </div>
                        ))}
                  </div>
                </Card>
              </Col> 
            </Row>
          </div>
        }
        {isAdmin &&
          <div className={`${style.graphSection} mt-4`} >
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={16}>
                <Card className={style.payrollCard}
                >
                  <div
                    className={`${style.cardHeader} d-flex justify-content-between w-100`}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <PayrolStyle className={`${style.payroalHeading} `}>
                        On Payroll{' '}
                        <StyledBadge
                          style={{ width: 'fit-content' }}
                          className={style.badge}
                          count={getDashBoardData?.payrollData?.length}
                          overflowCount={getDashBoardData?.payrollData?.length}
                        />
                      </PayrolStyle>
                    </div>
                  </div>
                  <Payrollstyle >
                    <Skeleton active loading={isLoading} paragraph={{ rows: 9 }} style={{ width: 500, marginLeft: 50 }} >
                      {getDashBoardData && getDashBoardData?.payrollData?.map(item => {
                        return (
                          <CardStyle >
                            <Stylediv className={'styledCard'}>
                              <Stylepayroll className={style.userPayroll}>
                                <Avatar className={style.borderCol} >{`${item?.name?.[0].toUpperCase()}`} </Avatar>
                                <div className={style.nameTitle}>
                                  <p>
                                    {(item.name || '')}
                                  </p>
                                  <span>
                                  </span>
                                </div>
                              </Stylepayroll>
                              <StylePrice >
                                {' '}
                                PKR {item.totalSalary}
                              </StylePrice>
                            </Stylediv>
                          </CardStyle>
                        )
                      })}
                    </Skeleton>
                  </Payrollstyle>
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card>
                  <MyPieChart data={getDashBoardData?.payrollData && getDashBoardData?.payrollData[0] ? getDashBoardData?.payrollData[0] : 0} />
                </Card>
              </Col>
            </Row>
          </div>
        } 
        <div className={`${style.graphSection} mt-4`}>
          <Row gutter={[16, 16]}>
            <Col span={16} xs={24} lg={16} >
              <Card>
                <EmployeeLoanTable />
              </Card>
            </Col>
            <Col span={8} xs={24} lg={8} >
              <Card>
                <div className={`${style.cardHeader} d-flex justify-content-between w-100`}>
                  <Emploan >Employee Loan
                    <StaticLable color={color}>
                      <span style={{ color: 'white' }}>Alpha</span>
                    </StaticLable>
                  </Emploan>
                </div>
                <Chart
                  chartType='PieChart'
                  data={data}
                  options={options}
                  width={'100%'}
                  height={500}
                />
              </Card>
            </Col>
          </Row>
        </div> 
         {isAdmin &&
          <div className={`${style.graphSection} mt-4`}>
            <Row gutter={[16, 16]}>
              <Col span={10} xs={24} lg={10} >
                <Card>
                  <OnboardingPie />
                </Card>
              </Col>
              <Col span={14} xs={24} lg={14} >
                <Card>
                  <BarGraph />
                </Card>
              </Col>
            </Row>
          </div>
        } */}
      </div>
    </div>
  );
});
export default Dashboard;


// const AssetData = styled.div`
// margin: 30px;
// margin-bottom: 70px;
// font-size: 17px;
// font-weight: 700;
// line-height: 30px;
// letter-spacing: 1px;

// `
// const ExpenseStyle = styled.div`
// font-size: 17px;
// font-weight: 700;
// line-height: 30px;
// letter-spacing: 1px;
// `
// const PayrolStyle = styled.div`
// font-size: 17px !important;
// font-weight: 700 !important;
// line-height: 30px;
// letter-spacing: 1px;

// `
// const Emploan = styled.div`
// font-size: 17px !important;
// font-weight: 700;
// line-height: 30px;
// letter-spacing: 1px;
// text-align: center;
// color: #010101; 
// `
// const Payrollstyle = styled.div`
// overflow: auto;
// height: 400px;
// width: 100%;
// padding: 0px 12px 0px 12px;

// `
// const CardStyle = styled(Card)`
// height: 65px !important;
// margin-bottom: 12px !important;
// border-radius: 330px !important;
// box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
// :first-child{
// margin-top: 15px;
// }

// `
// const Stylediv = styled.div`
// height: 65px;
// display: flex;
// justify-content: space-between;
// align-items: center;
// padding: 0px 10px 0px 10px ;
// `
// const Stylepayroll = styled.div`
// display: flex;
// justify-content: center;
// align-items: center;
// gap: 6px;

// `
// const StylePrice = styled.div`
// font-family: 'Poppins';
// font-weight: 700;
// line-height: 24px;
// letter-spacing: 0px;
// text-align: right;
// color: #525353;
// `
const EmployeeDiv = styled.div`
align-items: center;
margin-top: 0.6rem;
p{
  color: ${(props: { color: any }) => props.color ? props.color : 'grey'};
  margin-bottom: 0px !important;

}
`
// const ProjectCardStyle = styled.div`
// border-radius: 16px;
// border: 1px solid lightgray;
// padding-bottom: 8px;
// box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
//   `


const TotalEmployee = styled.span`
font-size:12px;
@media (max-width: 340px) {
  font-size: 18px !important;
}`
const Greeting = styled.h3`
font-weight: 600;
font-size: 22px;
`
const GreetingWelcome = styled.h3`
font-weight: 600;
font-size: 17px ;
line-height: 30px;
letter-spacing: 1px;

@media (max-width: 350px) {
  position: absolute;
  top: 13rem;
  white-space: nowrap;
  line-height: 20px;
}
`

// const StaticLable = styled.span`
// background: ${(props: { color: any }) => props.color ? props.color : 'grey'};
//   border-radius:5px;
//   margin-left:12px; 
//   padding:5px;  
//   font-size:10px; `
// const Progressbars = styled(CircularProgressbar)`
// @media screen and (max-width: 767px) {
//   .progress-bar-container {
//     width: 70%; 
//     margin: 10px; 
//   }
// }

// @media screen and (min-width: 768px) and (max-width: 1023px) {
//   .progress-bar-container {
//     width: 33%; 
//     margin: 10px;
//   }
// }

// @media screen and (min-width: 1024px) {
//   .progress-bar-container {
//     width: 20%; 
//     margin: 10px;
// }}

//  `
// const StyledBadge = styled(Badge)`
//  sup{
//   background: #aaaaaa;
//  }`

