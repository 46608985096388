
import { BaseApi } from '../baseApi'
import { remoteLeaveUrl, ApplyremoteLeaveUrl, deleteLeaveUrl, updateLeaveUrl, RemoteLeaveApproveUrl, RemoteLeaveARejectedUrl } from '../const'

class EmployeeRemoteLeaveApi extends BaseApi {
  setEmployeeRemoteLeave = async data => {
    try {
      const response = await this.axios.post(ApplyremoteLeaveUrl,data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
   getEmployeeRemoteLeave = async (searchParams,num) => {
    try {
      const response = await this.axios.get(`${remoteLeaveUrl}${searchParams}?page=${num ? num : 1}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeeRemoteLeaveStatus = async id => {
    try {
      const response = await this.axios.put(`${RemoteLeaveApproveUrl}/${id}/remote_leave`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeeRemoteLeaveRejected = async (id, data) => {
    try {
      const response = await this.axios.put(`${RemoteLeaveARejectedUrl}/${id}/remote_leave`, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  updateEmployeeRemoteLeave = async data => {
    try {
      const response = await this.axios.put(updateLeaveUrl, data)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteEmployeeRemoteLeave = async id => {
    try {
      const response = await this.axios.delete(`${deleteLeaveUrl}/${id}`)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default EmployeeRemoteLeaveApi
