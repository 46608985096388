import React, {useEffect } from 'react'
import { Col, Form } from 'antd'
import { useStore } from '@stores/root-store'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import { CommonSelect } from '@commonComponents/select'
import styled from 'styled-components'

export interface DesignationsProps {
  update: boolean;
  data?: {
    title: string;
    company: string;
    company_id: string;
    id: number;
    department: string;
    departmentName: number;
    designationName: string;
    gradeLevelName: string;
    branch: string;
    section: string;
    objectId: string;
  };
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setBtnLoader?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DesignationsForm = observer(
  ({ update, data, setVisible, setRefreshTable, setBtnLoader }: DesignationsProps) => {
    const [form] = Form.useForm()
    const {
      departmentModal: { loadDepartment, getDepartmentData },
      designationModal: { setDesignationData, updateDesignation, loadDesignation },
    } = useStore(null)
    {/* /initially load data */ }
    useEffect(() => {
      loadDepartment(1, 20, '', true)
    }, [])
    useEffect(() => {
      form.resetFields()
      if (update) {
        form.setFieldsValue({
          title: data.title,
          department: data.department
        })
      }

    }, [data, update])

    const submitData = async values => {
      let response = null
      setBtnLoader(true)
      if (update) {
        response = await updateDesignation(data.id, values)
      } else {

        response = await setDesignationData(values)
      }
      if (response?.success) {
        setVisible(false)
        form.resetFields()
        await loadDesignation(1, 20, '', true)
        if (setRefreshTable) {
          setRefreshTable(true)
        }
      }
      setBtnLoader(false)

    }

    const departmentData = getDepartmentData?.map(({ id, title }) => ({ key: id, value: title }));


    return (
      <>
        <Form
          form={form}
          id='designation-form'
          layout='vertical'
          name='nest-messages'
          onFinish={submitData}
        >
          <FormItem
            name='title'
            label='Designation Name'
            rules={[
              { required: true, message: 'Designation name is required' },
              {
                whitespace: true,
                message: 'Can\'t be just whitespace',
              },
            ]}
          >
            <CommonInput
              className='inputField'
              type='text'
              testId='designation_inp_name'
              placeholder='Ex: Frontend Developer' />
          </FormItem>


          <Col xs={24} sm={24}>


            <FormItem
              name='department'
              label='department'
              rules={[{ required: true, message: `department is required` }]}
            >
              <CommonSelect
                listHeight={
                  125}
                className=''
                getPopupContainer={trigger => trigger.parentNode}
                placeholder={`Please Select a Department`}
                data={departmentData}
                defaultValue={update ? data.objectId : null}
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              />
            </FormItem>
          </Col>
        </Form>

      </>
    )
  }
)

export default DesignationsForm

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
      padding-bottom:0px;
  }
  `