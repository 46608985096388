import { BaseApi } from '../baseApi'
import { branchUrl } from '../const/index'

class BranchApi extends BaseApi {
  setBranch = async data => {
    try {
      const response = await this.axios.post(branchUrl, data)

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getBranch = async (num, pageSize, queryParam = {}) => {
    try {
      const url = `${branchUrl}?page=${num ? num : 1}&page_size=${pageSize}`
      const response = await this.axios.get(
        url,
        {
          params: queryParam ? { ...queryParam } : {},
        }
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  updateBranch = async (data, id) => {
    try {
      const response = await this.axios.patch(`${branchUrl + id}/`, data,)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteBranch = async id => {
    try {
      const response = await this.axios.delete(`${branchUrl + id}/`,)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default BranchApi
