import { LATITUDE, LONGITUTE } from '@mainPage/constant'
import { useStore } from '@stores/root-store'
import { Card, Col, Row, Skeleton } from 'antd'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StatisticHeading } from '../EmpLeadView/StaticsEmpLeadView'
import PunchBtnLoader from './punchBtnLoader'
import Stopwatch from './timer'
import { colors } from 'constant/colors'
import {
  convertDurationToSeconds,
  getLunchTime,
  getPrayerTime,
  getProductiveTime,
} from '../utils'
import {
  PUNCH_IN,
  PUNCH_OUT,
  BEGIN_RECESS,
  FINISH_RECESS,
  COMMENCE_PRAYER,
  CONCLUDE_PRAYER,
} from 'constant/constant'

const { TealishBlue } = colors
interface LoadingIndicatorTypes {
  type: 'prayer' | 'lunch' | '';
  loading: boolean;
}

export const PunchButton = observer(
  ({
    refreshStatistics,
    refreshPunchActivity,
    setRefreshStatistics,
    setRefreshPunchActivity,
  }: any) => {
    const {
      punchModal: { getPunchData, loadPunchData, setPunch },
      themesModel: { getColorsData },
    } = useStore(null)

    const [punchState, setPunchState] = useState('')
    const [isContactLoader, setIsContactLoader] = useState(false)
    const [productiveTimeStatus, setProductiveTimeStatus] = useState('')
    const [prayerTimeStatus, setPrayerTimeStatus] = useState('')
    const [lunchTimeStatus, setLunchTimeStatus] = useState('')

    const [activeLoader, setActiveLoader] = useState<LoadingIndicatorTypes>({
      type: '',
      loading: false,
    })

    useEffect(() => {
      getData()
    }, [])

    const getData = async () => {
      setIsContactLoader(true)
      await loadPunchData()
      setIsContactLoader(false)
    }

    useEffect(() => {
      const stateAttendance = getPunchData?.status
      setPunchState(stateAttendance)

      const productiveTimerState =
        stateAttendance === FINISH_RECESS ||
        stateAttendance === CONCLUDE_PRAYER ||
        stateAttendance === PUNCH_IN
          ? PUNCH_OUT
          : PUNCH_IN

      const recessTimerState =
        stateAttendance === BEGIN_RECESS ? FINISH_RECESS : BEGIN_RECESS

      const prayerTimerState =
        stateAttendance === COMMENCE_PRAYER ? CONCLUDE_PRAYER : COMMENCE_PRAYER
        
      setProductiveTimeStatus(productiveTimerState)
      setLunchTimeStatus(recessTimerState)
      setPrayerTimeStatus(prayerTimerState)
    }, [getPunchData])

    const punchTypeData = {
      lunch: lunchTimeStatus,
      prayer: prayerTimeStatus,
    };

    const handleButtonClick = async type => {
      setActiveLoader({
        type: type,
        loading: true,
      })

      const punchType = punchTypeData[type];

      setPunchState(prevCount => prevCount + 1)
      await setPunch(LATITUDE, LONGITUTE, punchType)
      await loadPunchData()

      setRefreshStatistics(!refreshStatistics)

      setTimeout(() => {
        setActiveLoader({
          type: type,
          loading: false,
        })
      }, 1000)
      setRefreshPunchActivity(!refreshPunchActivity)
    }

    useEffect(() => {
      getProductiveTime(getPunchData)
      getPrayerTime(getPunchData)
      getLunchTime(getPunchData)
    }, [])

    return (
      <PunchWrapper theme={getColorsData?.theme}>
        <StatisticHeading>Today's Timesheet</StatisticHeading>
        <Skeleton active loading={isContactLoader}>
          <RespponsiveRow className='align-items-center' justify='space-evenly'>
            <Timerstylings xs={24} sm={8} md={8} lg={8}>
              <Row className='align-items-center' justify='space-between'>
                <FirstCol xs={24} sm={24} md={24} lg={24}>
                  <h4 style={{ width: '120px', margin: 'auto' }}>
                    <Stopwatch
                      PunchState={productiveTimeStatus}
                      elapsedTimeProp={convertDurationToSeconds(
                        getProductiveTime(getPunchData)
                      )}
                    />
                  </h4>
                  <BottomText>Today's Productive Time</BottomText>
                </FirstCol>
              </Row>
            </Timerstylings>
            {/* lunch timer */}
            <Timerstylings xs={24} sm={8} md={8} lg={8}>
              <Row className='align-items-center' justify='center'>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className='punchButton'>
                    {activeLoader.loading && activeLoader.type === 'prayer' ? (
                      <PunchBtnLoader />
                    ) : (
                      <button
                        className={
                          punchState === COMMENCE_PRAYER
                            ? 'punchinButtonLeave punchinButton'
                            : 'punchinButton'
                        }
                        style={{
                          fontSize: '20px',
                          backgroundColor: getColorsData?.bodyColor,
                        }}
                        onClick={() => handleButtonClick('prayer')}
                      >
                        {'Prayer'}
                      </button>
                    )}
                    <h4 style={{ width: '175px', margin: 'auto' }}>
                      <Stopwatch
                        PunchState={prayerTimeStatus}
                        elapsedTimeProp={convertDurationToSeconds(
                          getPrayerTime(getPunchData)
                        )}
                      />
                    </h4>
                  </div>
                  <BottomText>Prayer Break Time</BottomText>
                </Col>
              </Row>
            </Timerstylings>
            {/* prayer timer */}
            <Timerstylings xs={24} sm={8} md={8} lg={8}>
              <Row className='align-items-center' justify='space-between'>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className='punchButton'>
                    {activeLoader.loading && activeLoader.type === 'lunch' ? (
                      <PunchBtnLoader />
                    ) : (
                      <button
                        className={
                          punchState === BEGIN_RECESS
                            ? 'punchinButtonLeave punchinButton'
                            : 'punchinButton'
                        }
                        style={{
                          fontSize: '20px',
                          backgroundColor: getColorsData?.bodyColor,
                        }}
                        onClick={() => handleButtonClick('lunch')}
                      >
                        {'Lunch'}
                      </button>
                    )}
                    <h4 style={{ width: '180px', margin: 'auto' }}>
                      <Stopwatch
                        PunchState={lunchTimeStatus}
                        elapsedTimeProp={convertDurationToSeconds(
                          getLunchTime(getPunchData)
                        )}
                      />
                    </h4>
                  </div>
                  <BottomText>Lunch Break Time</BottomText>
                </Col>
              </Row>
            </Timerstylings>
          </RespponsiveRow>
        </Skeleton>
      </PunchWrapper>
    )
  }
)

const RespponsiveRow = styled(Row)`
  @media screen and (min-width: 0px) and (max-width: 700px) {
    .ant-row {
      display: felex;
      flex-direction: column;
    }
  }
`
const BottomText = styled.p`
  text-align: center;
  word-spacing: 1px !important;
`
const FirstCol = styled(Col)`
  display: grid;
  @media screen and (min-width: 0px) and (max-width: 576px) {
    gap: 0px;
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    gap: 65px;
  }
`

const PunchWrapper = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: 'poppins';
  margin-bottom: 25px;

  h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #010101;
    @media (max-width: 576px) {
      text-align: center;
    }
  }

  h4 {
    font-size: 16px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: baseline;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    color: #525353;
  }

  h1 {
    color: ${TealishBlue};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 0px;
    }
  }

  span {
    font-style: normal;
    font-family: 'Outfit';
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #525353;
    word-spacing: 2px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  @media (max-width: 305px) {
    .timeSheetCard {
      :global(.ant-card-body) {
        padding: 10px !important;
      }
    }
  }

  .punchinBorder {
    border-right: none;
    h1 {
      font-size: 27px !important;
      @media (max-width: 576px) {
        font-size: 20px !important;
      }
    }
    @media (max-width: 1600px) {
      border: none !important;
    }
    @media (max-width: 576px) {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      align-items: center;
    }
  }

  .borderLeft {
    padding-left: 30px;
    h1 {
      font-size: 27px !important;
      @media (max-width: 576px) {
        font-size: 20px !important;
      }
    }
  }

  @media (max-width: 1600px) {
    .borderLeft {
      padding-left: 0;
    }
    .Border {
      border: none;
    }
  }
  @media (max-width: 576px) {
    .borderLeft {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      align-items: center;
    }
  }

  .punchButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .punchinButton {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 650;
    color: #ffffff;
    font-size: 26px;
    line-height: 33px;
    width: 150px;
    height: 150px;
    border: 5px solid #f8f8f8;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15),
      inset 0px 0px 38px rgba(248, 248, 248, 0.8);
    border-radius: 50%;

    h3 {
      color: #ffffff;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 33px;
      margin-top: 8px;
      align-items: center;
    }

    @media (max-width: 768px) {
      width: 150px;
      height: 150px;
      line-height: 22px;
      font-size: 22px;
    }
  }

  .punchinButtonLeave {
    background-color: red;
  }

  @media (max-width: 768px) {
    .punchinButton {
      height: 130px;
      width: 130px;
    }
  }
`

const Timerstylings = styled(Col)`
  .ant-row h4 {
    justify-content: center !important;
    h1 {
      font-size: 17px !important;
      font-weight: 600;
    }
  }
`
