import { flow, types } from 'mobx-state-tree'
import { terminationApi } from '../../api'
import { notification } from '@utils/notifications'


const termination = types.model({
  id: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  isTerminated:types.maybeNull(types.boolean),
  resignationDate: types.maybeNull(types.number),
})

export const terminationModel = types.model({
  data: types.maybeNull(types.array(termination)),
  loading: types.optional(types.boolean, false),
  count: types.maybeNull(types.number),
  nextAss: types.optional(types.number, 1)
})

  .views(self => ({
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setTerminationData = flow(function* (data) {
      try {
        self.loading = true
        yield terminationApi.setTermination(data)
        notification.success('Termination Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const updatTermination = flow(function* (data, id, employee_id) {
      try {
        self.loading = true
        yield terminationApi.updateTermination(data, id, employee_id)
        notification.success('Termination Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const deleteTermination = flow(function* (id) {
      try {
        self.loading = true
        yield terminationApi.deleteTermination(id)
        notification.success('Termination Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setTerminationData,
      updatTermination,
      deleteTermination

    }
  })
export function initterminationModel() {
  return terminationModel.create({})
}