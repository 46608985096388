import { APPROVED, PENDING, REJECTED } from 'constant/constant'

export const ASSETSDETAILS = 'Assets Details'
export const ECANTEENBILL = 'E-Canteen Bill'
export const CLEARANCE = 'clearance'
export const CLEARANCE_TITLE = 'Clearance'
export const RESIGN_TITLE = 'Resigned' 
export const TERMINATE_TITLE = 'Terminated' 
export const RESIGN_TITLE_LOWERCASE = 'resign' 
export const TERMINATE_TITLE_LOWERCASE= 'terminate' 
export const SELECT_OPTIONS = [{ value: APPROVED }, { value: PENDING }, { value: REJECTED }]
export const SELECT_OPTIONS_REASON = [{ value: RESIGN_TITLE }, { value: TERMINATE_TITLE }]
export const STYLE_MARGIN = { marginRight: '5px' }
export const ADDDRAWERCLASS = 'addDrawer'
export const ADDBUTTONCLASS = 'addButton'
export const CANCELBUTTON = 'cancelButton'
export const ICON_CLASS = 'fa fa-dot-circle-o'
export const ASSET = 'asset'
export const ASSET_TITLE = 'Asset'
export const LOAN_TITLE = 'Loan'
export const SALARY_TITLE = 'Salary'
export const CANTEEN_TITLE = 'Canteen'
export const EMPLOYEE = 'employee'
export const SEARCH_EMPLOYEE = 'Search Employee'