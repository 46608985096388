import { getTokenFromCookies, setTokenIntoCookies } from '@api/common-utils'
import PageLoader from '@commonComponents/ring-loader/PageLoader'
import { RootStoreType, useStore } from '@stores/root-store'
import NoInternet from '@utils/NoInternet'
import useOnlineStatus from '@utils/hooks/useOnlineStatus'
import { notification } from '@utils/notifications'
import { constRoute } from '@utils/route'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type IProps = PropsWithChildren<{
  store: RootStoreType;
}>

const withAuthentication = (WrappedComponent: React.FC) => {
  return (props: IProps) => {
    const navigate = useNavigate()
    const route = useLocation()
    const isJWT = route.search.includes('jwtToken=')
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isUserStatusOnline, setIsUserStatusOnline] = useState(true)

    const {
      userInfo: { onBoardUser },
    } = useStore(null)

    const isOnline = useOnlineStatus()

    useEffect(() => {
      const fetchProfile = async () => {
        const token = isJWT ? route.search.split('?jwtToken=')[1] : getTokenFromCookies()
        if (route.pathname == constRoute.signup) {
          navigate(route.pathname)
        } else {
          if (!token) {
            navigate(constRoute.login)
            return
          }

          isJWT ? setTokenIntoCookies(token) : ''
          if (!isOnline) {
            setIsUserStatusOnline(false)
            notification.error('No internet connection.')
          } else {
            const { success } = await onBoardUser()
            if (success) {
              setIsAuthenticated(true)
            } else {
              setIsAuthenticated(true)
              navigate(route.pathname)
            }
          }
        }
      }
      fetchProfile()
    }, [])

    return isAuthenticated ? (
      <WrappedComponent {...props} />
    ) : (
      isUserStatusOnline ? <PageLoader /> : <NoInternet />
    )
  }
}

export default withAuthentication
