import { feedbackLeadQuestionsApi } from '@api'
import { Col, Form, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect } from 'react'

interface Props {
  title: string;
}

const QuestionForm = ({ questionData, setRefreshTable, setVisible }) => {
  const [form] = Form.useForm()

  const submitData = async (values: Props) => {
    if (!questionData) {
      await feedbackLeadQuestionsApi.setQuestion(values.title)
      setRefreshTable(true)
      setVisible(false)
    }

    await feedbackLeadQuestionsApi.editQuestion(questionData.id, values.title)
    setRefreshTable(true)
    setVisible(false)
  } 

  useEffect(() => {
    if (questionData) {
      form.setFieldsValue({
        title: questionData.title,
      })
    }
  }, [questionData])

  return (
    <Form
      form={form}
      onFinish={submitData}
      layout='vertical'
      name='question-form'
      id='question-form'
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name={['title']}
            label='Question'
            rules={[
              { required: true, message: 'Question is required' },
              {
                whitespace: true,
                message: "Question Can't be just whitespace",
              },
            ]}
          >
            <TextArea placeholder='Enter Question here' />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default QuestionForm
