/**
 * TermsCondition Page
 */
import { useStore } from '../../../stores/root-store'
import { constRoute } from '../../../utils/route'
import { Card, Col, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BreadCrumbs from '@commonComponents/breadcrums'
import ProjectCard from '../Projects/projectCard'
import ClientAllTask from './clientalltask'
import EditIcon from '@commonComponents/edit-icon/edit-icon'
import ClientProfileForm from './clientProfileForm'
import DisplayPicture from '@commonComponents/avatar'


const ClientProfile = () => {
  const { clientID } = useParams()
  const [activeTabKey1, setActiveTabKey1] = useState('projects')
  const [activeTabKey2, setActiveTabKey2] = useState('all_tasks')
  const [visible, setVisible] = useState(false)

  const {
    clientDetails: { loadClientInfo, getClientInfo },
    projectsModel: { getProjectsData, loadProjects },
  } = useStore(null)

  useEffect(() => {
    async function load() {
      await loadClientInfo(1, 20, '',true)
      await loadProjects(1,20,'',true)
    }
    load()
  }, [])

  const tabListNoTitle = [
    {
      key: 'projects',
      tab: 'Projects',
    },
    {
      key: 'tasks',
      tab: 'Tasks',
    },
  ]
  const tabListNoTitle2 = [
    {
      key: 'all_tasks',
      tab: 'All Tasks',
    },
    {
      key: 'pending_tasks',
      tab: 'Pending Tasks',
    },
    {
      key: 'completed_tasks',
      tab: 'Completed Tasks',
    },
  ]

  const contentListNoTitle2 = {
    all_tasks: <ClientAllTask />,
    pending_tasks: <div>Content</div>,
    completed_tasks: <div>Content</div>,
  }

  const contentListNoTitle = {
    projects: (
      <div id='myprojects' className='tab-pane fade show active'>
        <div className='row'>
          {getProjectsData?.map(data => {
            if (data.client.id == clientID) {
              return <ProjectCard data={data} dropdownVisible={false} />
            }
          })}
        </div>
      </div>
    ),
    tasks: (
      <div className='TaskDiv'>
        <Card
          className='cardBody'
          tabList={tabListNoTitle2}
          activeTabKey={activeTabKey2}
          onTabChange={key => {
            onTab2Change(key)
          }}
        >
          {contentListNoTitle2[activeTabKey2]}
        </Card>
      </div>
    ),
  }

  const onTab1Change = key => {
    setActiveTabKey1(key)
  }
  const onTab2Change = key => {
    setActiveTabKey2(key)
  }

  return (
    <div className='page-wrapper profileMainDiv'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <BreadCrumbs
            list={[
              { name: 'Dashboard', link: constRoute.home },
              { name: 'Profile', link: constRoute.clientProfile },
            ]}
            pageTitle={'Client Profile'}
          >
            <></>
          </BreadCrumbs>
        </div>
        {/* /Page Header */}

        {getClientInfo?.map(item => {
          if (item.id == clientID) {
            return (
              <Card className='mainProfileCard'>
                <Row>
                  <Col span={3}>
                    <Link to='/app/profile/employee-profile'>
                      <DisplayPicture
                        size={110}
                        src={item.clientImage}
                        name={item.firstName}
                      />
                    </Link>
                  </Col>
                  <Col span={8}>
                    <h5>
                      {item.lastName
                        ? item.firstName + ' ' + item.lastName
                        : item.firstName}
                    </h5>
                    <small className='greyText'>CEO</small>
                    <div className='textBold'>Client ID : {item.clientID}</div>
                    <br />
                    <Link to={`${constRoute.chat}`} className='btn btn-custom'>
                      Send Message
                    </Link>
                  </Col>
                  <Col span={1} className='dashedDivider'></Col>
                  <Col span={12} className='infoCol'>
                    <Row>
                      <Col span={22}></Col>
                      <Col span={2}>
                        <EditIcon
                          edit={'true'}
                          onClick={() => setVisible(true)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <span className='darkGreyText'>Phone:</span>
                      </Col>
                      <Col span={16}>
                        <span>
                          <a href=''>{item.mobileNumber}</a>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <span className='darkGreyText'>Email:</span>
                      </Col>
                      <Col span={16}>
                        <span>
                          <a href=''>{item.email}</a>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <span className='darkGreyText'>Birthday:</span>
                      </Col>
                      <Col span={16}>
                        <span className='greyText'>2nd August</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <span className='darkGreyText'>Address:</span>
                      </Col>
                      <Col span={16}>
                        <span className='greyText'>
                          5754 Airport Rd, Coosada, AL, 36020
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <span className='darkGreyText'>Gender:</span>
                      </Col>
                      <Col span={16}>
                        <span className='greyText'>Male</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            )
          }
        })}

        <div className='profileCard'>
          <Card
            className='cardBody'
            bordered={false}
            tabList={tabListNoTitle}
            activeTabKey={activeTabKey1}
            onTabChange={key => {
              onTab1Change(key)
            }}
          >
            {contentListNoTitle[activeTabKey1]}
          </Card>
        </div>

        {/* /Page Content */}
      </div>
      <Modal
        className='modal-form'
        title={'Client Details'}
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        footer={false}
        destroyOnClose={true}
      >
        {visible && (
          <ClientProfileForm setVisible={setVisible} clientID={clientID} />
        )}
      </Modal>
    </div>
  )
}
export default ClientProfile
