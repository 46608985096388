import React, { useMemo, useRef } from 'react'
import { Table } from '@commonComponents/table-v2'
import { observer } from 'mobx-react'
import { attendanceTimeSheetUrl } from '@api/const'
import { attendanceColumns } from './columns'
import AttendanceSideDrawer from '../AttendanceSideDrawer'

interface AttendanceProps {
  refreshTable: any;
  setRefreshTable: any;
  queryParam: any;
  showDrawer: () => void;
  onClose: () => void;
  open: boolean;
  employeeRecord: any;
  empID?: string;
  clickedDate?: string;
}

const AttendancePunchTable = observer(
  ({
    queryParam,
    refreshTable,
    setRefreshTable,
    showDrawer,
    onClose,
    open,
    employeeRecord,
    empID,
    clickedDate,
  }: AttendanceProps) => {
    const tableRef = useRef(null)
    const columns = useMemo(() => attendanceColumns(showDrawer), [showDrawer])

    return (
      <>
        <Table
          ref={tableRef}
          className='punchOutTable'
          dataUrl={attendanceTimeSheetUrl}
          columns={columns}
          queryParam={queryParam}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
        />
        <AttendanceSideDrawer
          drawerEmpId={empID}
          onClose={onClose}
          open={open}
          employeeRecord={employeeRecord}
          calledFrom='timesheet'
          clickedDate={clickedDate}
        />
      </>
    )
  }
)

export default React.memo(AttendancePunchTable)
