import Tooltip from '@commonComponents/CustomToolTip'
import BreadCrumbs from '@commonComponents/breadcrums'
import CustomButton from '@commonComponents/button'
import CustomColumnSelect from '@commonComponents/customColumn/customColumnSelect'
import CustomDrawer from '@commonComponents/drawer'
import {
  faDownload,
  faUser,
  faUserMinus,
  faUserPlus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStore } from '@stores/root-store'
import { notification } from '@utils/notifications'
import { permit } from '@utils/permission/permission'
import { constRoute } from '@utils/route'
import { Button, Checkbox, Dropdown, List, Menu, Space } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { EMPLOYEE_MOD, POST_METHOD, VIEW_ALL } from 'constant/constant'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { MdDownload, MdImage, MdPictureAsPdf } from 'react-icons/md'
import styled from 'styled-components'
import EmployeeTable from './EmployeeTable'
import EmployeeForm from './employeeForm'
import SearchEmployees from './searchEmplooyees'
import ShowOnPermission from '@commonComponents/permit'
import { colors } from 'constant/colors'

const { sootyBlack } = colors

const AllEmployees = observer(() => {
  const [visible, setVisible] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  // eslint-disable-next-line prefer-const
  let [queryParam, setQueryParam] = useState({})
  const [btnLoad, setBtnLoad] = useState(false)
  const [showIcons, setShowIcons] = useState(true)
  const [inactive, setInactive] = useState(false)
  const [showHierarchy, setshowHierarchy] = useState(false)
  const [header, setHeader] = useState(false)
  const [clickTriggerColor, setClickTriggerColor] = useState(true)
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false)
  const [checkVal, setCheckVal] = useState<any>('')
  const [showInterns, setShowInterns] = useState(false)
  const [customCol, setCustomCol] = useState(null)
  const [selectedCol, setSelectedColumn] = useState(null)
  const [columns, setColumns] = useState(null)

  const {
    employeeDetails: {
      loadHierarchyListParent,
      getHierarchyList,
      setEmployeeColField,
      loadEmployeeColField,
      getEmpFields,
      downloadEmployeeDetails,
      loadAllEmployeeList,
      loadLeadDesignation,
      getLeadDesignation,
      loadLeadRole,
      getLeadRole,
      getEmployeeAllList,
      nextEmp,
    },
    userInfo: { getRoles, getcompany },
    themesModel: { getColorsData },
  } = useStore(null)

  const isAdmin = permit(getRoles, 'accessView', 'is_admin')
  const isLead = getcompany?.isLead;

  const isFilter = isAdmin || isLead;

  const themeColor = getColorsData?.bodyColor
  const isDarkTheme = getColorsData?.theme == 'dark'
  const canViewInterns = permit(getRoles, 'accessView', 'view_intern')
  const canViewAll  = permit(getRoles, EMPLOYEE_MOD, VIEW_ALL)


  useEffect(() => {
    // if (isLead) {
      loadLeadDesignation(1, 20, '')
      loadLeadRole()
    // }
    // if (isAdmin) {
      loadAllEmployeeList()
    // }
    loadEmployeeColField()
    localStorage.removeItem('queryStates')
  }, [isLead, isAdmin])

  const filteredQuery =
    checkVal === 'Show Inactive Employee'
      ? 'is_active'
      : checkVal === 'No Supervisor'
        ? 'no_supervisor'
        : ''

  const handleOptionClick = (format: any) => {
    setClickTriggerColor(!clickTriggerColor)
    if (filteredQuery) {
      queryParam = {
        ...queryParam,
        [filteredQuery]: filteredQuery === 'is_active' ? false : true,
        file_type: format.key,
      }
    } else {
      queryParam = {
        ...queryParam,
        file_type: format.key,
      }
      downloadEmployeeDetails(queryParam)
    }
  }

  const handleAdvanceClick = () => {
    setShowIcons(prev=>!prev)
  }

  const DownloadOptions = (
    <DropDownMenuWrapper borderColor={themeColor}>
      <Menu onClick={handleOptionClick}>
        <MenuItem key='csv' className='menuItem'>
          <MdImage style={{ color: 'red' }} /> Download CSV
        </MenuItem>
        <MenuItem key='pdf' className='menuItem'>
          <MdPictureAsPdf /> Download PDF
        </MenuItem>
      </Menu>
    </DropDownMenuWrapper>
  )

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setInactive(!inactive)
    const val = checkedValues.at(0)
    setCheckVal(val)
    setHeader(val === 'Show Inactive Employee' && true)
    setIsCheckBoxChecked(val !== undefined ? true : false)
    const showInactiveEmployees = val === 'Show Inactive Employee'
    const filteredQuery = showInactiveEmployees
      ? 'is_active'
      : val === 'No Supervisor'
        ? 'no_supervisor'
        : '/'

    localStorage.setItem('queryStates', JSON.stringify(filteredQuery))
    if (!showHierarchy) {
      if (filteredQuery !== '/') {
        setQueryParam(() => ({
          [filteredQuery]: showInactiveEmployees ? false : true,
        }))
      } else {
        setQueryParam({})
      }
    } else {
      loadHierarchyListParent()
    }
  }

  const handleHierarchyTableView = (index: number) => {
    setQueryParam({})
    setCheckVal('')
    if (index === 1) {
      setRefreshTable(true)
    }
    setshowHierarchy(prev=>!prev)
    setIsCheckBoxChecked(false)
  }

  const empData = [
    { title: 'No Supervisor' },
    { title: 'Show Inactive Employee' },
  ]

  const internsOnly = () => {
    setShowInterns(prev=>!prev)
    setQueryParam({})
    setCheckVal('')
    setIsCheckBoxChecked(false)
  }

  const EmpOptions = (
    <DropDownMenuWrapper borderColor={themeColor}>
      <StyledDropdownList
        itemLayout='horizontal'
        isDarkTheme={isDarkTheme}
        dataSource={empData}
        renderItem={(item: any) => (
          <CustomCheckGroup onChange={onChange} isDarkTheme={isDarkTheme} >
            <List.Item>
              <Checkbox
                value={item.title}
                disabled={isCheckBoxChecked && checkVal !== item.title}
              >
                <CustomCheckDiv isDarkTheme={isDarkTheme}>
                  <span>{item.title}</span>
                </CustomCheckDiv>
              </Checkbox>
            </List.Item>
          </CustomCheckGroup>
        )}
      />
    </DropDownMenuWrapper>
  )

//  const customColumns =  useMemo(() => {
//     if (getEmpFields?.length && getEmpFields[0]?.fields?.employeeFiled?.length) {

//      return [...getEmpFields[0].fields.employeeFiled];
//       // setSelectedColumn([...newCol]);
//     }
//   }, [getEmpFields])

  return (
    <div className='page-wrapper employee-page'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='d-flex justify-content-between'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Employees', link: constRoute.newEmployee },
              ]}
              pageTitle={'Employees'}
            ></BreadCrumbs>
            <div>
              <ShowOnPermission mod={EMPLOYEE_MOD} method={POST_METHOD}>
                <CustomButton
                  onClick={() => setVisible(true)}
                  title='Add Employee'
                />
              </ShowOnPermission>
            </div>
          </div>
        </div>
        {!showHierarchy &&
          ((isFilter || canViewAll) || (showInterns && canViewInterns)) && (
            <SearchEmployees
              setQueryParam={setQueryParam}
              loadAllEmployeeList={loadAllEmployeeList}
              getEmployeeAllList={getEmployeeAllList}
              nextEmp={nextEmp}
              loadLeadRole={loadLeadRole}
              getLeadRole={getLeadRole}
              loadLeadDesignation={loadLeadDesignation}
              getDesignationData={getLeadDesignation}
              checkedFilter={filteredQuery}
              showHierarchy={showHierarchy}
              hideSupervisorFilter={inactive} />
          )}
        {(isFilter || canViewInterns || canViewAll) && (
          <DownloadRecordWrapper
            isDarkTheme={isDarkTheme}
            className={` advanceBtn d-flex justify-content-end`}
          >
            <DownloadBtnWrapper isDarkTheme={isDarkTheme}>
              {showIcons ? (
                <DownloadExtendWrapper>
                  {(isAdmin || canViewAll) &&
                    !showHierarchy &&
                    !showInterns && (
                      <Dropdown
                        overlay={EmpOptions}
                        placement='bottom'
                        // onOpenChange={() => setInactive(!inactive)}
                        getPopupContainer={(triger: any) => triger.parentElement}
                      >
                        <Tooltip title={'Employee Filters'}>
                          <FontAwesomeIcon icon={faUserMinus} />
                        </Tooltip>
                      </Dropdown>
                    )}

                  {(isFilter || canViewAll) && (
                    <Dropdown
                      overlay={DownloadOptions}
                      placement='bottom'
                      onOpenChange={() =>
                        setClickTriggerColor(!clickTriggerColor)
                      }
                      getPopupContainer={(triger: any) => triger.parentElement}
                    >
                      <Tooltip title={`Download Files`}>
                        <CustomSpanIcon>
                          <FontAwesomeIcon icon={faDownload} />
                        </CustomSpanIcon>
                      </Tooltip>
                    </Dropdown>
                  )}
                  {(isFilter || canViewAll) && (
                    <CustomColumnSelect
                      customCol={customCol}
                      setCustomCol={setCustomCol}
                      loadColField={loadEmployeeColField}
                      setColField={setEmployeeColField}
                      getColFields={getEmpFields}
                      columns={columns}
                      callFrom={'employeeFiled'}
                    />
                  )}
                  {(canViewAll || isFilter) && !showInterns && (
                    <Tooltip
                      title={
                        !showHierarchy ? 'Employee Hierarchy' : 'Employee List'
                      }
                      minLength={0}
                    >
                      {!showHierarchy ? (
                        <span style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon
                            icon={faUsers}
                            onClick={() => handleHierarchyTableView(1)}
                          />
                        </span>
                      ) : (
                        <span style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon
                            icon={faUser}
                            onClick={() => handleHierarchyTableView(2)}
                          />
                        </span>
                      )}
                    </Tooltip>
                  )}
                  {!showHierarchy && (isAdmin || canViewInterns || canViewAll || (isAdmin && showInterns)) && (
                    <Tooltip
                      minLength={5}
                      title={showInterns ? 'Hide Interns' : 'View Interns'}
                    >
                      <span style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon
                          icon={showInterns ? faUserMinus : faUserPlus}
                          onClick={internsOnly}
                        />
                      </span>
                    </Tooltip>
                  )}
                </DownloadExtendWrapper>
              ) : null}
              {(isFilter || canViewInterns || canViewAll) && (
                <Button onClick={handleAdvanceClick}>Advance</Button>
              )}
            </DownloadBtnWrapper>
          </DownloadRecordWrapper>
        )}
        {getHierarchyList?.length && header
          ? (notification.inactiveEmployees(
            'Assign the Supervisor to activate the status of an Employee.'
          ),
            setHeader(false))
          : null}
        <EmployeeTable
          inactive={inactive}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          getLeadRole={getLeadRole}
          queryParam={queryParam}
          setCustomCol={setCustomCol}
          customCol={customCol}
          selectedCol={selectedCol}
          setColumns={setColumns}
          setSelectedColumn={setSelectedColumn}
          showHierarchy={showHierarchy}
          showInterns={showInterns}
        />
      </div>
      <CustomDrawer
        title='Add Employee'
        setVisible={setVisible}
        visible={visible}
        className='addDrawer'
        width={700}
        customFooter={
          <Space>
            <CustomButton
              form='employee-form'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='employee-form'
              htmlType='submit'
              type='primary'
              title={'Save Employee'}
              loading={btnLoad}
              disabled={btnLoad}
              className='addButton'
            />
          </Space>
        }
      >
        {
          <EmployeeForm
            setVisible={setVisible}
            setBtnLoad={setBtnLoad}
            loadLeadDesignation={loadLeadDesignation}
            getDesignationData={getLeadDesignation}
            loadLeadRole={loadLeadRole}
            getLeadRole={getLeadRole}
            setRefreshTable={setRefreshTable}
          />
        }
      </CustomDrawer>
    </div>
  )
})

export default AllEmployees

export const DownloadRecordWrapper = styled.div`
  margin-bottom: 40px;
  left: 15px;
  right: 15px;

  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      &:hover {
        background-color: ${props =>
    props?.isDarkTheme ? 'black' : 'white'} !important;
        color: ${props =>
    props?.isDarkTheme ? 'white' : 'black'} !important;

        .ant-dropdown-menu-title-content,
        li {
          background-color: ${props =>
    props?.isDarkTheme ? 'black' : 'white'} !important;
          color: ${props =>
    props?.isDarkTheme ? 'white' : 'black'} !important;
        }
      }
    }
  }
`
export const DownloadBtnWrapper = styled.div`
  display: flex;
  border: 1px solid #e3e3e3;
  border-radius: 36px;
  align-items: center;
  height: 40px;

  .ant-dropdown-menu {
    background-color: ${props => (props?.isDarkTheme ? sootyBlack : 'white')};
  }

  button {
    color: red;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    height: 40px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`
export const DownloadExtendWrapper = styled.span`
  width: hug(255px);
  height: hug(48px);
  border-radius: 36px 0px 0px 36px;
  border-right: 0px;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex: 1;
  width: auto;

  svg {
    background-color: transparent;
  }

  .anticon-usergroup-delete {
    padding-left: 5px;
    font-size: 20px;
    svg:hover {
      background-color: unset !important;
    }
  }
`

export const StyledDropdownList = styled(List)`
  width: 200px !important;
`

export const DropDownMenuWrapper = styled.div`
  border: 1px solid ${props => props?.borderColor || 'blue'};
  background-color: ${props => (props?.isDarkTheme ? sootyBlack : 'white')};
  border-radius: 2px;
  .menuItem {
    display: flex !important;
    justify-content: center !important;
  }
  ul {
    background: white;

    li {
      margin: 4px 2px;
      width: 180px;
      border-radius: 8px;
      align-items: center;
      transition: all 0.2s ease-in;

      :hover {
        color: black;
        span {
          color: black;
        }
      }
      span {
        gap: 5px;
        justify-content: center;
      }
    }
  }
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }

  .ant-row {
    flex-direction: column;
  }
`

export const Mddownload = styled(MdDownload)`
  color: #0958d9;
`
const MenuItem = styled(Menu.Item)`
  justify-content: center;
  align-items: center;
`

export const CustomSpanIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    font-size: 18px;
  }
`

const CustomCheckDiv = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props?.isDarkTheme ? sootyBlack : 'white'} !important;
  color: ${props => (props?.isDarkTheme ? 'white' : 'black')} !important;

  div {
    span {
      svg {
        display: flex;
      }
    }
  }

  span {
    font-size: 12px;
    font-weight: 600;
    background-color: ${props =>
    props?.isDarkTheme ? sootyBlack : 'white'} !important;
    color: ${props => (props?.isDarkTheme ? 'white' : 'black')} !important;
    width: 150px;
  }
`

const CustomCheckGroup = styled(Checkbox.Group)`
  width: 100% !important;
  background-color: ${props => (props?.isDarkTheme ? sootyBlack : 'white')};

  li {
    width: 0 !important;
    border: none !important;
    height: 16px !important;

    label {
      padding: 5px;
      color: ${(props: any) => (props?.isDarkTheme ? 'white' : 'black')} !important;

      &:hover {
        background: ${(props: any) =>
    props?.isDarkTheme ? sootyBlack : 'white'} !important;
        border-radius: 5px;
      }
    }
  }
`
