import ShowOnPermission from '@commonComponents/permit'
import AttendancePunchTable from '@mainPage/attendence/LeadAdminView/TableTimesheet'
import { ATTEDENCE_MOD, VIEW_ALL } from 'constant/constant'
import { useState } from 'react'
import { TimesheetFilters } from './TimesheetFilters'
import { useStore } from '@stores/root-store'
import getAuthRoles from '@utils/common-functions'

export const Timesheet = ({
  showDrawer,
  open,
  onClose,
  employeeRecord,
  empID,
  clickedDate,
}) => {

  const { userInfo: { getcompany: { id } } = {} } = useStore(null)
  const [refreshEpmloyeeTable, setRefreshEpmloyeeTable] = useState(false)
  const [queryParam, setQueryParam] = useState<Record<string, string>>({ supervisor_id:id })  
  const {leadOrAdmin} = getAuthRoles()

  return (
    <>
      {leadOrAdmin ?
        <TimesheetFilters
          setQueryParam={setQueryParam}
          calledFrom={'Timesheet'}
        />:
        <ShowOnPermission mod={ATTEDENCE_MOD} method={VIEW_ALL}>
        <TimesheetFilters
          setQueryParam={setQueryParam}
          calledFrom={'Timesheet'}
        />
        </ShowOnPermission>}
      <div>
        <AttendancePunchTable
          empID={empID}
          refreshTable={refreshEpmloyeeTable}
          setRefreshTable={setRefreshEpmloyeeTable}
          queryParam={queryParam}
          showDrawer={showDrawer}
          open={open}
          onClose={onClose}
          employeeRecord={employeeRecord}
          clickedDate={clickedDate}
        />
      </div>
    </>
  )
}
