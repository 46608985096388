import React, { useEffect, useState } from 'react'
import { Form,Select, Col, Row, DatePicker, Input } from 'antd'
import 'antd/dist/antd.css'
import { observer } from 'mobx-react'
import TextArea from 'antd/lib/input/TextArea'
import { useStore } from '@stores/root-store'
import { imgBaseUrl } from '@api/const'
import moment from 'moment'
import { CommonInput } from '@commonComponents/input'
import { DynamicSelect } from '@commonComponents/select/dynamicSelect'
import style from './style.module.scss'
import SupervisorSelect from '@commonComponents/supervisorDropdown/supervisorDropdown'
import styled from 'styled-components'
import highPriority from '@assets/img/high-priority.svg'
import lowPriority from '@assets/img/lowPriority.svg'
import mediumPriority from '@assets/img/medium-priority.svg'
import workingStatus from '@assets/img/workingStatus.svg'
import testingStatus from '@assets/img/testingStatus.svg'
import { CheckCircleFilled, ClockCircleFilled } from '@ant-design/icons'

const { Option } = Select

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const ProjectForm = observer((props: any) => {
  const [form] = Form.useForm()
  const [projectID, setprojectID] = useState('')
  const [updatedData, setUpdatedData] = useState({})
  const [fileList, setFileList] = useState([])
  const [employeeData, setEmployeeData] = useState([])
  const [clientData, setClientData] = useState([])
  const [, setTeamPreview] = useState([])
  const [, setPreview] = useState([])
  const {
    clientDetails: { getClientInfo, loadClientInfo, nextCli },
    employeeDetails: { getEmployeeData, loadEmployeeDetails },
    projectsModel: { setProjectsData, updateProjects },
  } = useStore(null)

  useEffect(() => {
    async function load() {
      if (!getEmployeeData?.length) {
        await loadEmployeeDetails(1, 20, '', true)
      }
      if (!getClientInfo?.length) {
        await loadClientInfo(1, 20, '', true)
      }
    }
    load()
  }, [])

  useEffect(() => {
    setEmployeeData(getEmployeeData)
    setClientData(getClientInfo)
  }, [getClientInfo, getEmployeeData])

  useEffect(() => {
    form.resetFields()
    setFileList([])
    if (props.isUpdate) {
      setprojectID(props.singleProject.id)
      form.setFieldsValue({
        client: props.singleProject.client.firstName + (props.singleProject.client.lastName ? props.singleProject.client.lastName :'' ) ,
        description: props.singleProject.description,
        project_type: props.singleProject.projectType,
        project_id: props.singleProject.projectId,
        priority: props.singleProject.priority
          ? props.singleProject.priority
          : 'Medium',
        project_leader: props.singleProject.projectLeader.map(e => e.id),
        name: props.singleProject.name,
        rate: props.singleProject.rate,
        contract_type: props.singleProject.contractType,
        start_date: props.singleProject.startDate
          ? moment(props.singleProject.startDate)
          : '',
        end_date: props.singleProject.endDate
          ? moment(props.singleProject.endDate)
          : '',
        project_team: props.singleProject.projectTeam.map(e => e.id),
        upload_files: props.singleProject.uploadFiles,
        status: props.singleProject.status,
      })
      if (props.singleProject.projectTeam) {
        const newData = []
        props.singleProject.projectTeam.forEach((iid, index) => {
          newData[index] = {
            id: iid.id,
            value: `${imgBaseUrl +
              employeeData[employeeData.findIndex(emp => emp.id === iid)]
                ?.employee_image
            }`,
          }
        })
        setTeamPreview([...newData])
      }
      if (props.singleProject.project_leader) {
        const newData1 = []
        props.singleProject.projectLeader.forEach((idd, index) => {
          newData1[index] = {
            id: idd.id,
            value: `${imgBaseUrl +
              employeeData[employeeData.findIndex(emp => emp.id === idd)]
                ?.employee_image
            }`,
          }
        })
        setPreview([...newData1])
      }
    }
  }, [props.singleProject, props.isUpdate])

  const onFinish = async values => {
    let responce = null
    values.start_date = values.start_date?.format('YYYY-MM-DD')
    values.end_date = values.end_date?.format('YYYY-MM-DD')
    const formData = new FormData()
    if (projectID !== '') {
      if (updatedData['project_team']) {
        updatedData['project_team']?.forEach(emp => {
          formData.append('project_team', emp)
        })
      }

      if (updatedData['project_leader']) {
        updatedData['project_leader']?.forEach(emp => {
          formData.append('project_leader', emp)
        })
      }
      if (updatedData) {
        const keys = Object.keys(updatedData)
        for (let i = 0; i < keys?.length; i++) {
          if (
            keys[i] !== 'project_team' &&
            keys[i] !== 'project_leader'
          ) {
            formData.append(`${keys[i]}`, updatedData[`${keys[i]}`])
          }
        }
      }
      responce = await updateProjects(formData, projectID)
    } else {
      fileList?.length && formData.append('upload_files', fileList[0])
      values.name && formData.append('name', values.name)
      values.start_date && formData.append('start_date', values.start_date)
      values.end_date && formData.append('end_date', values.end_date)
      values.project_type && formData.append('project_type', values.project_type)
      values.priority && formData.append('priority', values.priority)
      values.contract_type &&
        formData.append('contract_type', values.contract_type)
      values.rate && formData.append('rate', values.rate)
      values.description && formData.append('description', values.description)
      values.client && formData.append('client', values.client)
      values.status && formData.append('status', values.status)
      values.project_team &&
        values.project_team?.forEach(emp => {
          formData.append('project_team', emp)
        })
      values.project_leader &&
        values.project_leader?.forEach(emp => {
          formData.append('project_leader', emp)
        })

      responce = await setProjectsData(formData)
    }
    if (responce.success) {
      props?.setRefreshTable && props?.setRefreshTable(true)
      props.setIsEditModalVisible(false)
      setFileList([])
      setprojectID('')
      setUpdatedData({})
      form.resetFields()
      setTeamPreview([])
      setPreview(null)
    }
  }
  const children_clint = []
  clientData?.map(e => {
    children_clint.push({ key: e.id, value: e.firstName })
  })

  const children_emp = []
  employeeData?.map(e => {
    children_emp.push({ key: e.id, value: e.firstName })
  })

  const updateTask = async changeValue => {
    const key = Object.keys(changeValue)[0]
    if (key === 'project_team') {
      const newData = []
      changeValue.project_team.forEach((iid, index) => {
        newData[index] = {
          id: iid,
          value: `${imgBaseUrl +
            employeeData[employeeData.findIndex(emp => emp.id === iid)]
              ?.employee_image
          }`,
        }
      })
      setTeamPreview([...newData])
    }

    if (key === 'project_leader') {
      const newData1 = []
      changeValue.project_leader.forEach((idd, index) => {
        newData1[index] = {
          id: idd,
          value: `${imgBaseUrl +
            employeeData[employeeData.findIndex(emp => emp.id === idd)]
              ?.employee_image
          }`,
        }
      })
      setPreview([...newData1])
    }

    if (key === 'start_date' || key === 'end_date') {
      updatedData[key] =
        changeValue[Object.keys(changeValue)[0]].format('YYYY-MM-DD')
    } else {
      updatedData[key] = changeValue[Object.keys(changeValue)[0]]
    }
    setUpdatedData(updatedData)
  }

  return (
    <Form
      form={form}
      onValuesChange={updateTask}
      onFinish={onFinish}
      layout='vertical'
      className='project-form'
      id='project-form'
    >
      <div className='form-auto-height'>
        <Row gutter={24} className='rowDiv'>
          <Col xs={24} lg={12}>
            <Form.Item
              name='name'
              label='Project Name'
              rules={[
                { required: true, message: 'Project Name is required' },
              ]}
            >
              <CommonInput inputType='text'
                placeholder='Enter Project Name'
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} className={style.errorSpace}>
            <DynamicSelect
              isForm={true}
              name={'client'}
              placeholder={'Please Select Client...'}
              getData={getClientInfo}
              loadData={loadClientInfo}
              nextPage={nextCli}
              queryParam={'search'}
              valueKey1={'firstName'}
              valueKey2={'lastName'}
              formLabel={'Client Name'}
              fetchData={false}
              formRules={[
                { required: true, message: 'Client is required' },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={24} className='rowDiv'>
          <Col xs={24} lg={12} className={style.errorSpace}>
            <Form.Item
              name='start_date'
              label='Start Date'
              rules={[{ required: true, message: 'Start date is required' }]}
              style={{
                position: 'relative',
              }}
              className='customDate'
            >
              <DatePicker
                format='YYYY-MM-DD'
                getPopupContainer={trigger => trigger.parentElement}
                className='form-control inputField'
                onFocus={e => {
                  if (!e.target.value) {
                    form.setFieldsValue({ start_date: moment(new Date()) })
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} className={style.errorSpace}>
            <Form.Item
              name='end_date'
              label='End Date'
              rules={[{ required: true, message: 'End date is required' }]}
              style={{
                position: 'relative',
              }}
              className='customDate'
            >
              <DatePicker
                format='YYYY-MM-DD'
                getPopupContainer={trigger => trigger.parentElement}
                className='form-control inputField'
                onFocus={e => {
                  if (!e.target.value) {
                    form.setFieldsValue({ end_date: moment(new Date()) })
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className='rowDiv'>
          <Col xs={24} lg={12}>
            <Form.Item name='priority' label='Priority' initialValue='Medium'>
              <CustomSelectField
                size='large'
                placeholder='Select Priority'
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option value={'High'}>
                  <StyledSpan>
                    <img src={highPriority} alt='' />
                  High
                  </StyledSpan>
                </Option>
                <Option value={'Medium'}>
                  <StyledSpan>
                    <img src={mediumPriority} alt='' />
                   Medium
                  </StyledSpan>
                </Option>

                <Option value={'Low'}>
                  <StyledSpan>
                    <img src={lowPriority} alt='' />
                  Low
                  </StyledSpan>
                </Option>
              </CustomSelectField>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name='status' label='Status' initialValue='Pending'>
              <CustomSelectField
                size='large'
                placeholder='Select Status'
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option value={'Pending'}>
                  <StyledSpan>
                    <ClockCircleFilled style={{color:'#D7E90D'}} />
                    Pending
                  </StyledSpan>
                </Option>
                <Option value={'Final'}>
                  <StyledSpan>
                    <CheckCircleFilled style={{color:'#16D61E'}}/>
                    Final
                  </StyledSpan>
                </Option>
                <Option value={'Working'}>
                  <StyledSpan>
                    <img src={workingStatus} alt='' /> 
                    Working
                  </StyledSpan>
                </Option>
                <Option value={'Testing'}>
                  <StyledSpan>
                    <img src={testingStatus} alt='' />
                    Testing
                  </StyledSpan>
                </Option>
              </CustomSelectField>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className='rowDiv'>
         
          <StyledCol xs={24} lg={12}>
            <Form.Item name='contract_type' label='Contract Type'>
              <CustomSelectField
                size='large'
                placeholder='Select Type'
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option value='Hourly'>Hourly</Option>
                <Option value='Fixed'>Fixed</Option>
              </CustomSelectField>
            </Form.Item>
          </StyledCol>
          <Col xs={24} lg={12}>
            <Form.Item name='rate' label='Rate'>
              <CommonInput placeholder='$50' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className='rowDiv'>
          <Col xs={24} lg={12} className={style.errorSpace}>
            <StyledDiv>
              <SupervisorSelect 
                isForm={true}
                name={'project_leader'}
                mode='multiple'
                formLabel={'Project Leader'}
                placeholder={'Please select lead Person...'}
                fetchData={false}
                formRules={[{ required: true, message: 'Project lead is required' }]}
              />
            </StyledDiv>
          </Col>
          <Col xs={24} lg={12} className={style.errorSpace}>
            <StyledDiv>
              <SupervisorSelect 
                isForm={true}
                mode='multiple'
                name={'project_team'}
                formLabel={'Add Team'}
                placeholder={'Please select Team Person...'}
                fetchData={false}
                formRules={[{ required: true, message: 'Project team is required' }]}
              />
            </StyledDiv>
          </Col>

        </Row>
        <Row className='rowDiv'>
          <Col xs={24} lg={12} className={style.errorSpace}>
            <Form.Item name='project_type' label='Project Type'>
              <CustomInputField
                placeholder='Enter Project Type'
                defaultValue={''}
              />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={24} className='rowDiv'>
          <Col span={24}>
            <Form.Item name='description' label='Description'>
              <TextArea
                rows={4}
                placeholder='Enter your message here'
                defaultValue={''}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  )
})

export default ProjectForm

const CustomSelectField = styled(Select)`
.ant-select-selector{
  border: 1px solid #E2E2E2;
  background: #FFF;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px !important;
  border-radius: 8px !important;
  height: 45px !important;
}
`
const CustomInputField = styled(Input)`
height: 45px;
width:96%;
`
const StyledSpan = styled.span`
display: flex !important;
align-items: center !important;
img{
  margin-right: 7px !important;
}
svg{
  margin-right: 7px !important;
}
`
const StyledDiv = styled.div`
 .ant-select-selection-item{
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
   height: auto !important;
  }
  .ant-select-selector{
    height: auto !important;
  }
`
const StyledCol = styled(Col)`
  .ant-select-selector{
    padding-top: 2px !important;
  }
`