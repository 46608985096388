import { BaseApi } from '../baseApi'
import { headerAuthorization } from '../common-utils'
import { attendanceStats, baseUrl } from '../const/index'

class AttendanceStatsApi extends BaseApi {
  getAttendenceStats = async () => {
    try {
      const response = await this.axios.get(attendanceStats)
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeeTotalCount = async () => {
    try {
      const response = await this.axios.get(
        `${baseUrl}employee/employee-yearly-count/`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  getEmployeeAttendancePunchData = async () => {
    try {
      const response = await this.axios.get(
        `${baseUrl}attendance_list/user-punch-data/`,
        headerAuthorization(this)
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
}

export default AttendanceStatsApi
