import { Form, Checkbox } from 'antd';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { documentTitle } from '@utils/page-title'
import { useStore } from '@stores/root-store'
import { constRoute } from '@utils/route'
import { observer } from 'mobx-react'
import { CommonInput } from '@commonComponents/input'
import style from './style.module.scss'
import styled from 'styled-components'
import { MdMail, MdLock } from 'react-icons/md';
import comlogo from '../../assets/img/companylogo.png'
import CustomButton from '@commonComponents/button'
import { useState, useEffect } from 'react';
import { colors } from 'constant/colors';

const { TealishBlue } = colors

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}



function loginPage() {
  const [form] = Form.useForm()

  const [color, setColor] = useState({ bodyColor: TealishBlue, headerColor: '', sidebarColor: '', theme:'' });

  const {
    signinInfo: { addInfo, isLoading },
    themesModel: { getColorsData, loadColor }
  } = useStore(null)
  
  useEffect(() => {
    async function load() {
      await loadColor()
    }
    load()
  }, [])

  useEffect(() => {
    getColorsData && setColor(getColorsData)
  }, [getColorsData])

  function isColorDark(color) {
    const hex = color?.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  }


  const onLogin = values => {
    addInfo({
      email: values.email,
      password: values.Password,
    })
  }



  return (
    <LoginPageWrapper color={color} textColor={isColorDark( color?.bodyColor || '#5F5E5E')} >
      <Helmet>
        <title>{documentTitle.login}</title>
      </Helmet>
      <LeftSideWrapper>
        <img src={comlogo} className='sider-img' style={{ background: color?.bodyColor }}/>
        <LoginTitle className='loginText'>Login your Account</LoginTitle>
        <Form
          style={{width:'60%'}}
          layout='vertical'
          form={form}
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onLogin}
          autoComplete='off'
          validateMessages={validateMessages}
        >
          <Form.Item
            name='email'
            label='Email'
            className={style.commonInputField}
            rules={[{ type: 'email', message: 'Email is not valid' },
              { required: true, message: 'Email is required' }]}
          >
             
            <CommonInputs prefix={<StyleMdMail />}
              testId='email'
              inputType='text'
              placeholder='example@test.com'
              onFocus={() => form.setFields([{ name: 'email', errors: [] }])}
            />
             
          </Form.Item>
          <Form.Item
            label='Password'
            name='Password' rules={[{ required: true }]}
            className={style.passwordField}
          >
            <CommonInputs
              prefix={<StyleMdLock />}
              inputType='password'
              testId='password'
              placeholder='Ex: Admin@123'

              onInput={e => {
                e.target.value = e.target.value.trim()
              }}
            />
          </Form.Item>
          <FormOptions>
            <Form.Item name='remember' valuePropName='checked' >
              <Checkbox >Remember me</Checkbox>
            </Form.Item>
            <Form.Item>
              <div className='d-flex justify-content-end'>
                <div><Link
                  className={`${style.forget} forgotpsw`}
                  to={`${constRoute.forgotPassword}`}
                >
                              Forgot Password?
                </Link></div>
              </div>
            </Form.Item>
          </FormOptions>
          <Form.Item className={style.signinButton}>
            <LoginButton className='login'
              title='LOGIN'
              htmlType='submit'
              loading={isLoading}
            />
          </Form.Item>
        </Form>
      </LeftSideWrapper>
      <RightSideWrapper  className='loginSide'>
        <LogoDiv><img style={{height:'100%',width:'100%'}} src={comlogo} /></LogoDiv>
        <RightMessage>Unveiling our Integrated Office Management System: Seamlessly blend of office staff management with automated attendance, fostering heightened productivity and organization.</RightMessage>
        

      </RightSideWrapper>
    </LoginPageWrapper >
  )
}

export default observer(loginPage)


const CommonInputs = styled(CommonInput)`
    height:50px !important;
    width: 100%;
    border-radius:8px;
`

const LoginPageWrapper = styled.div`
    height: 100vh;
    display: flex;

    @media only screen and (max-width: 695px) {
      .loginSide{
        display: none;
        visibility: hidden;
      }

      .sider-img{
        display: block !important;
      }
    }
    
    @media only screen and (max-width: 360px) { 
      .forgotpsw{
        font-size: 12px;
      }
    }
    
    .loginSide{
      background-color: ${props=> props?.color?.bodyColor} !important;
      color: ${props=>props.textColor ? 'white' : 'black'}
    }
    .login{
      background-color: ${props=> props?.color?.bodyColor} !important;
      color: ${props=>props.textColor ? 'white' : 'black'}
    }
    .forgotpsw, .loginText{
      color: ${props=> props?.color?.bodyColor} !important;
    }
    `

const LogoDiv = styled.div`
   height: 120px;
   width: 120px;
    margin-bottom: 35px;
    margin-left: 50px;
    `

const LoginTitle = styled.p`
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;

    @media only screen and (max-width: 695px) { 
      font-size: 23px;
    }
  `

const FormOptions = styled.div`
    display:flex;
    justify-content: space-between;

    @media only screen and (max-width: 360px) { 
      .ant-form-item{
        label{
          font-size: 12px;
        }
      }
    }
`

const LoginButton = styled(CustomButton)`
    height: 50px !important;
    width: 100%;
    background-color:${TealishBlue};
    border:none;
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1px;
    border-radius: 8px !important;
    `
const LeftSideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .sider-img{
      display: none;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    
    @media only screen and (max-width: 905px) {
      .ant-form, .ant-form-vertical {
        width: 80% !important;
      }
    }
`

const RightSideWrapper = styled.div`

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 0%,20% 100%,100% 100%,100% 0%);
    `
const RightMessage = styled.p`
    color: white;
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 400; 
    width:60%;
    text-align: justify;
    margin-left: 50px;
    `
const StyleMdMail = styled(MdMail)`
   color: #525353;
   font-size: 20px;
`
const StyleMdLock = styled(MdLock)`
   color: #525353;
   font-size: 20px;
`
