import { Switch } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { MdDelete, MdEdit } from 'react-icons/md'
import {
  DESIGNATION_NAME, DEPARTMENT, COMPANY, STATUS_TITLE, DEFAULT_COLOR, PUT_METHOD, DESIGNATION_MOD, DELETE_METHOD,
} from 'constant/constant'
import { ACTION } from '@mainPage/EmployeeLeave/const'
import styled from 'styled-components'
import ShowOnPermission from '@commonComponents/permit'
import { permit } from '@utils/permission/permission'


const getColumns = (getRoles, currentData, onDelete, onEdit, statusLoader, onStatusChange, getColorsData) => {

  const canEdit  = permit(getRoles,DESIGNATION_MOD,PUT_METHOD)
  const canDelete = permit(getRoles,DESIGNATION_MOD,DELETE_METHOD)

   const columns =  [
      {
        title: <FitContent>{DESIGNATION_NAME}</FitContent>,
        dataIndex: 'title',
        render: text => (
          <Tooltip title={text}>
            <Pwrapper>{text}</Pwrapper>
          </Tooltip>
        )
      },
      {
        title: COMPANY,
        dataIndex: 'company',
        render: text => (
          <Tooltip title={text}>
            <Pwrapper>{text ? text : '-'}</Pwrapper>
          </Tooltip>
        )
      },
      {
        title: DEPARTMENT,
        dataIndex: 'departmentName',
        render: text => (
          <Tooltip title={text}>
            <Pwrapper>{text ? text : '-'}</Pwrapper>
          </Tooltip>
        )
      },
      {
        title: <StatusCenter>{STATUS_TITLE}</StatusCenter>,
        dataIndex: 'is_active',
        render: (text, row) => (
            <StatusDiv>
              <Switch
                checked={row.isActive}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                onClick={check => onStatusChange(row, check)}
                disabled={!permit(getRoles, DESIGNATION_MOD, PUT_METHOD)}
              />
              <div style={{ marginTop: '-4px' }}>
                {row.id === currentData?.id && statusLoader && (
                  <LoadingOutlined />
                )}
              </div>
            </StatusDiv>
        ),
      },
      (canEdit || canDelete) && {
        title: <ActionCenter>{ACTION}</ActionCenter>,
        render: (row: any) => (
          <>
            <ActionCenter>
              {row.isActive && (
                <ShowOnPermission mod={DESIGNATION_MOD} method={PUT_METHOD}>
                  <Tooltip title='Edit' minLength={0}>
                    <Actions onClick={() => onEdit(row)} >
                      <MdEdit fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
                    </Actions>
                  </Tooltip>
                </ShowOnPermission >

              )}
                <ShowOnPermission mod={DESIGNATION_MOD} method={DELETE_METHOD}>
                  <Tooltip title='Edit' minLength={0}>
                    <Actions onClick={() => onDelete(row)} >
                      <MdDelete fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
                    </Actions>
                  </Tooltip>
                </ShowOnPermission >
            </ActionCenter>
          </>
        ),
      }
    ]

    return columns.filter(Boolean)
  
}

export default getColumns;

const StatusDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

export const Actions = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  &:hover {
   background-color: #dad8d8;
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;
  } 
`

const StatusCenter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`
const FitContent = styled.div`
  display: flex;
  justify-content: left;
  text-align: left;
  width:max-content;
  `

export const ActionCenter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const Pwrapper = styled.p`
  width: 132px;
  overflow: hidden;
  text-overflow: ellipsis;
`