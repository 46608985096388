import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import { permit } from '@utils/permission/permission'
import { BRANCH_NAME, DELETE, DEPARTMENT_MOD, Description, TITLE, PATCH_METHOD, DEFAULT_COLOR, DELETE_METHOD, ACTIONS, EDIT_TITLE, STATUS_TITLE } from 'constant/constant'
import styled from 'styled-components'
import { MdDelete, MdEdit } from 'react-icons/md'
import ShowOnPermission from '@commonComponents/permit'
import { ActionCenter, Actions } from '@mainPage/Designation/columns'
const getColumns = (getRoles,getColorsData, currentData, onDelete, onEdit, statusLoader, onStatusChange) => {

  const canEdit  = permit(getRoles,DEPARTMENT_MOD,PATCH_METHOD)
  const canDelete = permit(getRoles,DEPARTMENT_MOD,DELETE_METHOD)
 

  const columns  =   [
      {
        title: TITLE,
        dataIndex: 'title',
        render: (text, record) => (
          <Tooltip title={record.departmentName}>
            <span>{text}</span>
          </Tooltip>
        ),
      },
      {
        title: BRANCH_NAME,
        dataIndex: 'branchName',
      },
      {
        title: Description,
        dataIndex: 'description',
        render : text=>(
          <Tooltip placement='topLeft'  title={text} minLength={15}>
                <span style={{cursor:'pointer'}}>{(text?.slice(0,15) + (text?.length > 15 ? '....' : '' ))|| ''}</span>
          </Tooltip>
       )
      },
      {
        title: <StatusCenter>{STATUS_TITLE}</StatusCenter>,
        dataIndex: 'status',

        render: (text, row) => (
          <StyleSwitch >
            <Switch
              checked={row.isActive}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked
              onClick={id => onStatusChange(row.id, id)}
              disabled={!canEdit}
            />
            <div style={{ marginTop: '-4px' }}>
              {row.id === currentData?.id && statusLoader && <LoadingOutlined />}
            </div>
          </StyleSwitch>
        )
      },
     (canEdit || canDelete) &&  {
        title:<ActionCenter>{ACTIONS}</ActionCenter>,
        dataIndex: 'actions',
        render: (text, row) => (
          <ActionCenter>
            <ShowOnPermission mod={DEPARTMENT_MOD} method={PATCH_METHOD}>
              {row.isActive && (
                <Tooltip minLength={0} title={EDIT_TITLE}>
                  <Actions onClick={() => onEdit(row)}  >
                    <MdEdit fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
                  </Actions>
                </Tooltip>
              )}
            </ShowOnPermission>
            <ShowOnPermission mod={DEPARTMENT_MOD} method={DELETE_METHOD}>
              <Tooltip minLength={0} title={DELETE}>
                <Actions onClick={() => onDelete(row)} >
                  <MdDelete fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
                </Actions>
              </Tooltip>
            </ShowOnPermission>
          </ActionCenter >
        ),
      },

    ]


    return columns.filter(Boolean)
  
}
export default getColumns;

const StyleSwitch = styled.div`
 display:flex;
justify-content:center;
align-items:center;
`
const StatusCenter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`


