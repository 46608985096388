import React from 'react'
import { Tooltip as AntdTootTip } from 'antd'

interface ToolTipProps {
    children: any;
    title: any;
    minLength?: number;
    placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
}

const Tooltip = ({
  children,
  title,
  minLength = 12,
  placement = 'top',
  ...props
}: ToolTipProps) => {

  return (
    (title?.length > 1 ? (minLength < title?.split?.(' ')?.join('')?.length) : '') ? (
      <AntdTootTip title={title} placement={placement} {...props}>
        {children}
      </AntdTootTip>
    ) : children
  )
}

export default Tooltip