import React, { useState } from 'react'
import { Form, Space } from 'antd'
import { observer } from 'mobx-react'
import DeletePopUp from '@commonComponents/delete-popup'
import { useStore } from '@stores/root-store'
import ProjectForm from './ProjectForm'
import ProjectsTable from './projectTable'
import ProjectsSearch from './searchProject'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import CustomButton from '@commonComponents/button'
import CustomDrawer from '@commonComponents/drawer'
import ShowOnPermission from '@commonComponents/permit'
import { ADD_METHOD, PROJECTS_MOD } from 'constant/constant'
import { CustomAttendanceButton, CustomTimeSheetButton } from '@mainPage/attendence/attendanceMainView'
import ProjectManagmentTable from './projectManagmentTable'
import styled from 'styled-components'

const Projects = observer(() => {
  const [form] = Form.useForm()
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [projectID, setprojectID] = useState('')
  const [singleProject, setSingleProject] = useState({})
  const [isUpdate, setisUpdate] = useState(false)
  const [deleteName, setDeleteName] = useState('')
  const [totalRecordRemaining, setTotalRecordRemaining] = useState(0)
  const [resetTable, setResetTable] = useState(false)
  const [queryParam, setQueryParam] = useState({})
  const [isActive,setIsActive] = useState('Projects');
  const {
    projectsModel: {
      isLoading,
      deleteProjects,
    },

  } = useStore(null)


  const deleteProject = async () => {
    let response = null
    response = await deleteProjects(projectID)
    if (response?.success) {
      setIsDeleteModalVisible(false)
      if (totalRecordRemaining == 1) {
        setResetTable(true)
      } else {
        setRefreshTable(true)
      }
    }
  }

  const onEdit = data => {
    setSingleProject(data)
    setIsEditModalVisible(true)
    setprojectID(data.id)
    setisUpdate(true)
  }

  const onDelete = data => {
    setprojectID(data?.id)
    setIsDeleteModalVisible(true)
    setDeleteName(data.name)
  }
  const onAdd = () => {
    setisUpdate(false)
    setIsEditModalVisible(true)
  }

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='d-flex'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Projects', link: constRoute.projects },
                ...(isActive === 'Account' ? [{ name: 'Projects Account' }] : [])
              ]}
              isAlpha
              pageTitle={ isActive === 'Account' ? 'Projects Accounts': 'Projects'}
            >
            </BreadCrumbs> 
            <div>
              <ShowOnPermission mod={PROJECTS_MOD} method={ADD_METHOD}>
                <div className={`switchButton mt-2`}>
                  <div className='switch-btn-flex'>
                    <CustomAttendanceButton className={` ${isActive === 'Projects' ? 'active' : ''}`} onClick={()=>setIsActive('Projects')}>
                        Project
                    </CustomAttendanceButton>
                    <CustomTimeSheetButton className={` ${isActive === 'Account'? 'active' : ''}`} onClick={()=> setIsActive('Account')}>
                        Account
                    </CustomTimeSheetButton>
                  </div>
                </div>
                {
                  isActive === 'Projects' &&
                    
                <StyleBtn>
                  <CustomButton
                    onClick={onAdd}
                    title='Create Project' />
                </StyleBtn>
                }
              </ShowOnPermission>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        {/* Search Filter */}
        <ProjectsSearch
          isActive = {isActive}
          setRefreshTable={setRefreshTable}
          setQueryParam={setQueryParam}
          queryParam={queryParam}
        />
        {/* Search Filter */}
        {
          isActive === 'Projects' ?
            <div className='tableWidth'>
              <ProjectsTable
                onDelete={data => onDelete(data)}
                projectID={setprojectID}
                refreshTable={refreshTable}
                setRefreshTable={setRefreshTable}
                onEdit={data => onEdit(data)}
                resetTable={resetTable}
                setResetTable={setResetTable}
                queryParam={queryParam}
                setTotalRecordRemaining={setTotalRecordRemaining}
              />
            </div>
            :
            <div>
              <ProjectManagmentTable/>
            </div>}
        <div className='row'>
          <CustomDrawer
            className='addDrawer'
            title={isUpdate ? 'Update Project' : 'Add Project'}
            visible={isEditModalVisible}
            setVisible={() => {
              setIsEditModalVisible(false)
              form.resetFields()
            }}
            width={600}
            customFooter={
              <Space>
                <CustomButton
                  htmlType='submit'
                  type='primary'
                  title={'Cancel'}
                  className='cancelButton'
                  onClick={() => setIsEditModalVisible(false)}
                />
                <CustomButton
                  form='project-form'
                  htmlType='submit'
                  type='primary'
                  title={isUpdate ? 'Udpate' : 'Add'}
                  className='addButton'
                />
              </Space>
            }
          >
            {isEditModalVisible &&
              (<ProjectForm
                isUpdate={isUpdate}
                singleProject={singleProject}
                setIsEditModalVisible={setIsEditModalVisible}
                setRefreshTable={setRefreshTable}
              />)
            }
          </CustomDrawer>
          <DeletePopUp
            delete={deleteProject}
            msgTitle='Delete Project'
            visibleDelete={isDeleteModalVisible}
            isLoading={isLoading}
            okText='Confirm'
            handleCancel={() => setIsDeleteModalVisible(false)}
            msg={deleteName}
          />
        </div>
      </div>
    </div>
  )
})

export default Projects

const StyleBtn = styled.div`
.style_commonButton__cv8nD{
  display: flex !important;
  justify-content: end !important;
  margin-top: 20px !important;
}
`