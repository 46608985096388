import React, { useEffect } from 'react'
import { Row, Col, Timeline } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import moment from 'moment'
import styled from 'styled-components'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */

const OnboardingHistoryForm = observer(props => {
  const {
    onboardingHistoryModal: { getOnboardingHistoryData, loadOnboardingHistory }
  } = useStore(null)

  useEffect(() => {
    async function loadData() {
      await loadOnboardingHistory(props?.id)
    }
    loadData()
  }, [])


  const formatHistory = (item: any) => {
    const data = []
    for (const key in item) {
      if (key !== 'updatedAt' && item[key]) {
        data.push(<WrapperedCard>
          <h5>{key == 'currentStatus' ? 'candidate status:' : `${key == 'onPayroll' ? 'On Payroll' : key}`}  <p>{item[key]}</p></h5>
        </WrapperedCard>
        )
      }
    }
    return data
  }

  return (
    <><>
      <StyledH3>TimeLine Of Candidate</StyledH3>
      <Row>
        <Col className='gutter-row' md={18} sm={24}>
          <OnboardingCard>
            <Timeline mode='left'>
              {getOnboardingHistoryData?.history?.map(item => {
                return <><Timeline.Item color='#C9C9C9' label={moment(item.updatedAt).format('MM-DD-YYYY')}>
                  {formatHistory(item)} 
                </Timeline.Item></>

              })}
            </Timeline>
          </OnboardingCard>
        </Col>
      </Row>
    </></>
  )
})

export default OnboardingHistoryForm

const WrapperedCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 5px;
  width: fit-content;
  left: 17px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;

  h5 {
    width: max-content;
  }
  p {
    font-size: 16px;
    margin-top: 5px;
    color: #c9c9c9;
  }
  &::before {
    content: '';
    position: absolute;
    top: 11px;
    right: 100%;
    width: 17px;
    border-bottom: 1px solid #c9c9c9;
  }
`

const OnboardingCard = styled.div`
  height: 580px;
  width: 402px;
  display: flex;
  gap: 30px;
  .ant-timeline-item {
    .ant-timeline-item-label {
      max-width: 100px;
    }
    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      left: 110px !important;
    }
    .ant-timeline-item-content{
      left: 104px !important;
      width: calc(100% - 130px) !important;
    }
  }
`

const StyledH3 = styled.h3`
  text-align: center;
  height: 36px;
  font-family: 'Poppins';
  color: #525353;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
`