
import React, { useState, useRef } from 'react'
import { Space } from 'antd'
import Tooltip from '@commonComponents/CustomToolTip'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import DeletePopUp from '@commonComponents/delete-popup'
import BreadCrumbs from '@commonComponents/breadcrums'
import { constRoute } from '@utils/route'
import GradeForm from './gradeForm'
import CustomButton from '@commonComponents/button'
import { gradeLevelUrl } from '@api/const'
import { Table } from '@commonComponents/table-v2'
import CustomDrawer from '@commonComponents/drawer'
import { Actions, ActionCenter, Pwrapper } from '@mainPage/Designation/columns';
import { MdDelete, MdEdit } from 'react-icons/md'
import { ACTION, DEFAULT_COLOR, DELETE_METHOD, GRADE_MOD, PATCH_METHOD, POST_METHOD } from 'constant/constant'
import ShowOnPermission from '@commonComponents/permit'

const Grade = observer(() => {
  const [currentData, setCurrentData] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [data, setData] = useState('')
  const [visible, setVisible] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [page, setPage] = useState(1)
  const [viewDetail, setViewDetail] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const tableRef = useRef(null);
  const {
    gradeDetails: {
      deleteGrade,
      loadGrade,
      isLoading,
    },
    themesModel: { getColorsData }

  } = useStore(null)

  const onAdd = () => {
    setCurrentData({})
    setIsUpdate(false)
    setVisible(true)
  }

  const onEdit = data => {
    setCurrentData(data)
    setIsUpdate(true)
    setVisible(true)
    setViewDetail(false)
  }

  const onDelete = data => {
    setCurrentData({ id: data?.id })
    setData(data?.title)
    setVisibleDelete(true)
  }

  const deleteGradeHandler = async () => {

    const response = await deleteGrade(currentData?.id)
    if (response?.success) {
      setVisibleDelete(false)
      setData('')
      setRefreshTable(true)
      await loadGrade(1, 20, '', true)

    }
  }
  const columns = [
    {
      title: 'Sr No',
      dataIndex: 'sr No',
      render: (text, row, index) => (
        <p style={{ margin: '0' }}>
          <Tooltip title={row.id} placement='topLeft'>
            {page > 1 ? 10 * (page - 1) + index + 1 : index + 1}
          </Tooltip>
        </p>
      ),
    },

    {
      title: 'Title',
      dataIndex: 'title',
      render: text => (
        <Tooltip placement='topLeft' title={text}>
          <Pwrapper>{text || '-'}</Pwrapper>
        </Tooltip>
      )
    },
    {
      title: 'Level Value',
      dataIndex: 'value',
      render: text => (
        <Tooltip placement='topLeft' title={text}>
          <Pwrapper>{text || '-'}</Pwrapper>
        </Tooltip>
      )
    },

    {
      title: 'Branch',
      dataIndex: 'branchName',
      render: text => (
        <Tooltip placement='topLeft' title={text}>
          <Pwrapper>{text || '-'}</Pwrapper>
        </Tooltip>
      )
    },
    {
      title: 'Parent Grade',
      dataIndex: 'parentName',
      render: text => (
        <Tooltip placement='topLeft' title={text}>
          <Pwrapper>{text || '-'}</Pwrapper>
        </Tooltip>
      )
    },

    {
      title: <ActionCenter>{ACTION}</ActionCenter>,
      render: (text, row) => (
        <ActionCenter>
          <ShowOnPermission mod={GRADE_MOD} method={PATCH_METHOD}>
            <Tooltip minLength={0} title='Edit'>
              <Actions onClick={() => onEdit(row)}>
                <MdEdit fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
              </Actions>
            </Tooltip>
          </ShowOnPermission>
          <ShowOnPermission mod={GRADE_MOD} method={DELETE_METHOD}>
            <Tooltip minLength={0} title='Delete'>
              <Actions onClick={() => onDelete(row)}>
                <MdDelete fill={getColorsData?.bodyColor || DEFAULT_COLOR} />
              </Actions>
            </Tooltip>
          </ShowOnPermission>
        </ActionCenter>
      ),
    }
  ]

  return (
    <div className='page-wrapper'>
      {/* Page Content */}
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className='page-header'>
          <div className='d-flex'>
            <BreadCrumbs
              list={[
                { name: 'Dashboard', link: constRoute.home },
                { name: 'Grade', link: constRoute.grade },
              ]}
              isAlpha
              pageTitle={'Grade'}
            >
            </BreadCrumbs>
            <ShowOnPermission mod={GRADE_MOD} method={POST_METHOD}>
              <CustomButton
                onClick={() => {
                  onAdd()
                }}
                title='Add Grade'
              />
            </ShowOnPermission>
          </div>
        </div>
        {/* /Page Header */}

        <div className='table-responsive'>
          <Table
            ref={tableRef}
            dataUrl={gradeLevelUrl}
            columns={columns}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            setPageNumber={data => setPage(data)}
          />

        </div>
      </div>
      {/* /Page Content */}
      {/* Add Grade Modal */}
      <CustomDrawer
        className='addDrawer'
        title={isUpdate ? 'Update Grade' : 'Add Grade'}
        visible={visible}
        setVisible={() => {
          setVisible(false)
          setIsUpdate(false)
          setCurrentData(null)
        }}
        width={400}
        customFooter={
          <Space>
            <CustomButton
              htmlType='submit'
              type='primary'
              title={'Cancel'}
              className='cancelButton'
              onClick={() => setVisible(false)}
            />
            <CustomButton
              form='grade-form'
              htmlType='submit'
              type='primary'
              title={isUpdate ? 'Update' : 'Add'}
              className='addButton'
            />
          </Space>
        }
      >
        <GradeForm
          setVisible={setVisible}
          viewDetail={viewDetail}
          update={isUpdate}
          data={currentData}
          setRefreshTable={setRefreshTable}
        />
      </CustomDrawer>


      {/* Delete Grade Modal */}
      <DeletePopUp
        delete={deleteGradeHandler}
        msgTitle='Delete Grade'
        visibleDelete={visibleDelete}
        isLoading={isLoading}
        okText='Confirm'
        handleCancel={() => setVisibleDelete(false)}
        msg={data}
      />
      {/* /Delete Grade Modal */}
    </div>
  )
})

export default Grade
