import {cast, flow, types} from 'mobx-state-tree'
import {allowancesApi} from '@api'
import {notification} from '@utils/notifications'
import {toJS} from 'mobx';

export const allowance = types.model({
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  amount: types.maybeNull(types.number),
  wageType:types.maybeNull(types.string)
})

export const allowanceModel = types
  .model({
    allowances: types.maybeNull(types.array(allowance)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextAllowance: types.maybeNull(types.number),
    nextPageNumber: types.optional(types.number, 1)
  })
  .views(self => ({
    get isLoading() {
      return self.loading
    },
    get allowancesList() {
      return toJS(self.allowances)
    }

  }))

  .actions(self => {
    const loadAllowance = flow(function* (
      page=1,
      pageSize=20,
      queryParam='',
      resetList=false,
      saveInStore=true
    ) {
      try {
        self.loading = true
        if (resetList) {
          self.allowances = cast([])
        }
        const res = yield allowancesApi.getAllowance(page, pageSize, queryParam)
        
        if (!res?.isCancel && saveInStore){
          self.allowances = res.items        
          self.count = res.count
          if (res.next) {
            self.nextAllowance = self.nextAllowance + 1
          } else {
            self.nextAllowance = 1
          }
        }else if(!res?.isCancel && !saveInStore) {
          return res || []
        }
      } catch (error) {
        const message = error?.response?.data?.message
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Employee')
        }
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    const createAllowance = flow(function* (data) {
      try {
        self.loading = true
        yield allowancesApi.createAllowance(data)
        notification.success('Allowance created Successfully')
        return {success: true}
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return {success: false}
      } finally {
        self.loading = false
      }
    })
    const updateAllowance = flow(function* (data, id) {
      try {
        self.loading = true
        yield allowancesApi.updateAllowance(data, id)
        notification.success('Allowance Updated Successfully')
        return {success: true}
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return {success: false}
      } finally {
        self.loading = false
      }
    })
    const deleteAllowance = flow(function* (id) {
      try {
        self.loading = true
        yield allowancesApi.deleteAllowance(id)
        notification.success('Allowance Deleted Successfully')
        return {success: true}
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return {success: false}
      } finally {
        self.loading = false
      }
    })

    return {
      loadAllowance,
      createAllowance,
      updateAllowance,
      deleteAllowance
    }
  })

export function initAllowanceModel() {
  return allowanceModel.create({})
}
