
import { BaseApi } from '../baseApi'
import { applyLeaveUrl,deleteLeaveUrl,updateLeaveUrl, leaveUrl, leaveHalfCancelUrl } from '../const/index'

class EmployeeLeaveApi extends BaseApi {
  setEmployeeLeave = async data => {
    try {
      const response = await this.axios.post(
        applyLeaveUrl,
        data,

      )

      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  getEmployeeLeave = async (num = 1) => {
  try {
  const response = await this.axios.get(
  `${leaveUrl}/leave?page=${num}`
  )
      return response.data
  } catch (error) {
  const apiError = error
  throw apiError
  }
  }
  updateEmployeeLeave = async data => {
    try {
      const response = await this.axios.put(
        `${updateLeaveUrl}`,
        {
          ...data,
        },
      )
      
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }
  deleteEmployeeLeave = async id => {
    try {
      const response = await this.axios.delete(
        `${deleteLeaveUrl}/${id}`,
      )
      return response.data
    } catch (error) {
      const apiError = error
      throw apiError
    }
  }

  halfCancelLeave = async (id: string, data: { cancel_start_date: string; cancel_end_date: string; cancellation_reason: string }) => {
    const res = await this.axios.put(`${leaveHalfCancelUrl}/${id}`, data)
    return res.data
  }
}

export default EmployeeLeaveApi
