import { flow, types } from 'mobx-state-tree'
import { experienceApi } from '../../api'
import { notification } from '@utils/notifications'
import { toJS } from 'mobx'

export const experience = types.model({
  id: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  fromDate: types.maybeNull(types.string),
  toDate: types.maybeNull(types.string),
  present: types.maybeNull(types.boolean),
})

export const experienceDetails = types
  .model({
    data: types.maybeNull(types.array(experience)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
  })
  .views(self => ({
    get experienceData() {
      return toJS(self.data)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setExperienceDetails = flow(function* (data) {
      try {
        self.loading = true
        yield experienceApi.setExperienceDetails(data)
        notification.success('Experience Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'Failed to create Experience')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const loadExperienceDetails = flow(function* (employee_id) {
      try {
        self.loading = true
        const res = yield experienceApi.getExperienceDetails(employee_id)
        self.data = res
      } catch (error) {
        notification.error(error?.response?.data?.message  || 'Failed to Load Experience Details')
      } finally {
        self.loading = false
      }
    })

    const updateExperienceDetails = flow(function* (
      id,
      data
    ) {
      try {
        self.loading = true
        yield experienceApi.updateExperienceDetails(
          id,
          data
        )
        notification.success('Experience Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'Failed to update Experience')
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteExperienceDetails = flow(function* (id) {
      try {
        self.loading = true
        yield experienceApi.deleteExperienceDetails(id)
        notification.success('Experience Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message || 'Failed to delete Experience')
      } finally {
        self.loading = false
      }
    })
    return {
      setExperienceDetails,
      loadExperienceDetails,
      updateExperienceDetails,
      deleteExperienceDetails,
    }
  })

export function initEmployeeExperienceDetails() {
  return experienceDetails.create({})
}
