import { toJS } from 'mobx'
import { cast,flow, types } from 'mobx-state-tree'
import { clientApi } from '../../api'
import { notification } from '@utils/notifications'

export const clientOwner = types.model({
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
})

export const clientType = types.model({
  clientType: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
})

export const clientInfo = types.model({
  id: types.maybeNull(types.string),
  clientOwner: types.union(types.string, types.maybeNull(clientOwner)),
  clientID: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  mobileNumber: types.maybeNull(types.string),
  clientImage: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  status: types.maybeNull(types.boolean),
  clientRole: types.maybeNull(types.number),
  clientType: types.maybeNull(clientType),
  branch: types.maybeNull(types.number),
  company: types.maybeNull(types.number),
})

export const clientDetails = types
  .model({
    clientInfo: types.maybeNull(types.array(clientInfo)),
    loading: types.optional(types.boolean, false),
    count: types.maybeNull(types.number),
    nextCli: types.optional(types.number, 1),
    filter: types.maybeNull(types.string),
  })
  .views(self => ({
    get getClientInfo() {
      return toJS(self.clientInfo)
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions(self => {
    const setClientInfo = flow(function* (data) {
      try {
        self.loading = true
        yield clientApi.setClientDetails(data)
        notification.success('Client Added Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      } 
    })
    const loadClientInfo = flow(function* (page=1, pageSize=20, queryParam='', resetList=false) {
      self.loading = true
      try {
        self.loading = true
        if (resetList) {
          self.clientInfo = cast([])
          self.nextCli = 1
        }
        const res = yield clientApi.getClientDetails(page , pageSize, queryParam)
        if (!res?.isCancel){
          self.clientInfo = cast([...toJS(self.clientInfo ?? []), ...res.results])
          self.count = res.count
          if (res.next) {
            self.nextCli = self.nextCli + 1
          } else {
            self.nextCli = 1
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Clients')
        }        return { success: false }
      } finally {
        self.loading = false
      }
    })
    
    const filterClientInfo = flow(function* (
      clientId,
      firstName,
      LastName,
      company,
      num
    ) {
      try {
        self.loading = true
        const res = yield clientApi.filterClientDetails(
          clientId,
          firstName,
          LastName,
          company,
          num
        )
        self.clientInfo = cast(res.results)
        self.count = res.count
        self.nextCli = 1
        self.filter = res?.next || res?.previous || null
      } catch (error) {
        const message = error?.response?.data?.message 
        if(message){
          notification.error(message)
        } else {
          notification.error('Failed to Load Search Client')
        }        return { success: false }
      }
      finally {
        self.loading = false
      }
    })
    const updateClientDetails = flow(function* (data, recordId) {
      try {
        self.loading = true
        yield clientApi.updateClientDetails(data,recordId)
        notification.success('Client Updated Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })

    const deleteClientDetails = flow(function* (id) {
      try {
        self.loading = true
        yield clientApi.deleteClientDetails(id)
        notification.success('Client Deleted Successfully')
        return { success: true }
      } catch (error) {
        notification.error(error?.response?.data?.message)
        return { success: false }
      } finally {
        self.loading = false
      }
    })
    return {
      setClientInfo,
      loadClientInfo,
      filterClientInfo,
      deleteClientDetails,
      updateClientDetails,
    }
  })

export function initClientDetails() {
  return clientDetails.create({})
}
