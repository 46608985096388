import React, { useEffect, useState } from 'react'
import { Form, Col, DatePicker, Row, Card } from 'antd'
import { observer } from 'mobx-react'
import { useStore } from '@stores/root-store'
import { CommonInput } from '@commonComponents/input'
import styled from 'styled-components'
import moment from 'moment'
import Dragger from 'antd/lib/upload/Dragger'
import { CommonSelect } from '@commonComponents/select'
import { StyleAttach } from '@mainPage/Asset/AssetForm'
import { DeleteOutlined } from '@ant-design/icons';
import { StyleCommonInput } from '@mainPage/userResignation/resignationapplyForm'

interface formValues {
    title: string;
    purchased_date: string;
    category_id: string;
    purchased_from: string;
    payment_type: string;
    amount: number;
    summary: string;
    attachments: string;
    employee_id: string;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const expanseForm = observer(({ update, data, setVisible, setRefreshTable, setIstrue }: any) => {
  const [form] = Form.useForm()
  const {
    hrCategoryModal: { getCategoriesData, loadCategory },
    hrExpenseModal: { setExpenseData, updateExpenseData },
    employeeDetails: { getEmployeeData, loadEmployeeDetails },
  } = useStore(null)

  const [updatedData, setUpdatedData] = useState({})
  const [fileList, setFileList] = useState([])
  const [attachmentList, setattachmentList] = useState(null)
  useEffect(() => {
    const loadData = async () => {
      if (!getCategoriesData?.length) {
        await loadCategory();
      }

      if (!getEmployeeData?.length) {
        await loadEmployeeDetails();
      }
 
    };

    loadData();
  }, []);

  useEffect(() => {
    form.resetFields()
    setFileList([])
    if (update) {

      form.setFieldsValue({
        title: data.title,
        purchased_from: data.purchasedFrom,
        purchased_date: data.purchasedDate ? moment(data.purchasedDate) : '',
        purchased_by_name: data.purchasedByName,
        amount: data.amount,
        payment_type: data.paymentType,
        category_id: data.categoryId,
        summary: data.summary,
        attachments: data.attachments
      })
    }
  }, [data, update])

  useEffect(() => {
    setattachmentList(data?.attachments);
  }, [])

  const submitData = async (values:formValues) => {
    let response = null
    values.purchased_date = moment(values.purchased_date).format('YYYY-MM-DD')
    const formData = new FormData()
    formData.append('data', JSON.stringify(values))
    if (update) {
      response = await updateExpenseData(data?.id, values)
    } else {
      response = await setExpenseData(formData)
    }
    if (response?.status) {
      setIstrue(false)
      setVisible(false)
      form.resetFields()
      setRefreshTable(true)
      setUpdatedData({})
      setFileList([])
    }
  }

  const updateExpenseDataAttachment = async changeValue => {
    const key = Object.keys(changeValue)[0]
    if (key === 'attachments') {
      updatedData[key] = 'added'
      setUpdatedData(updatedData)
    } else if (key === 'purchase_date') {
      updatedData[key] =
        changeValue[Object.keys(changeValue)[0]].format('YYYY-MM-DD')
    } else {
      updatedData[key] = changeValue[Object.keys(changeValue)[0]]
      setUpdatedData(updatedData)
    }
  }


  const fileProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      const allowedFormats = ['.jpg', '.jpeg', '.png'];
      const fileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2); // Extract the file extension
      if (allowedFormats.includes('.' + fileExtension.toLowerCase())) {
        setFileList([file]);
      } else {
        // Display an error message or notification to the user
        alert('Only .jpg, .jpeg, and .png file formats are supported.');
      }

      return false;
    },
    fileList,
    capture: undefined,
  };

  const delAttachment = async (attachment_id, index) => {
    attachmentList?.splice(index, 1);
    const newList = [...attachmentList];
    setattachmentList(newList);
    const formData = new FormData()
    formData.append('attachments_delete', attachment_id)
    await updateExpenseData(data?.id, formData)
  }
  const {
    employeeDetails: { loadAllEmployeeList, getEmployeeAllList }
  } = useStore(null)
  useEffect(() => {
    const load = async () => {
      await loadAllEmployeeList()
    }
    load()
  }, [])

  function getExpenseCategories(categoriesData: any[]) {
    if (!Array.isArray(categoriesData)) {
        return [];
    }

    return categoriesData
        .filter(item => item && item.categoryType && item.categoryType.includes('expense'))
        .map(({ id, title }) => ({ key: id, value: title }));
}
  function mapEmployees(employeeList) {
    return employeeList.map(value => ({ key: value.id, value: value.fullName }));
  }
  
  const getCategory = getExpenseCategories(getCategoriesData) || [];
  const allEmployees = mapEmployees(getEmployeeAllList) || [];
  
  return (
    <div>
      <Form
        form={form}
        onFinish={submitData}
        onValuesChange={updateExpenseDataAttachment}
        layout='vertical'
        name='expense-form'
        id='expense-form'
      >
        <div>
          <Row>
            <Col xs={24} sm={11}>
              <Form.Item
                name={'title'}
                label='Item Name'
                rules={[{ required: true, message: 'Item name is required' }, {
                  whitespace: true,
                  message: 'Can\'t be just whitespace',
                }]}
              >
                <CommonInput placeholder='Item name' inputType='text' />
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col xs={24} sm={11}>
              <Form.Item
                name={['purchased_from']}
                label='Purchased From'
                rules={[
                  { required: true, message: 'Purchased from is required' },
                  { whitespace: true, message: 'Can\'t be just whitespace' },
                ]}
              >
                <CommonInput placeholder='Purchased form' inputType='text' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={11}>
              <Form.Item
                name={['purchased_date']}
                label='Purchase Date'
                rules={[
                  { required: true, message: 'Purchase date is required' },
                ]}
              >
                <DatePicker
                  getPopupContainer={trigger => trigger.parentElement}
                  className='form-control'
              />
              </Form.Item>
            </Col>
            <Col span={2}/>
            <Col xs={24} sm={11}>
              <Form.Item label='Category' name={'category_id'}
              rules={[{ required: true, message: 'Category is required' }]}>
                <CommonSelect
                  listHeight={125}
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Select Parent Category'
                  data={getCategory}
                  showSearch
                  filterOption={(input, option) => (option?.children?.toLowerCase().includes(input.toLowerCase()))} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
          <Col xs={24} sm={11}>
              <Form.Item
                name={'amount'}
                label='Amount'
                rules={[
                  { required: true, message: 'Amount is required' },
                ]}
              >
                <CommonInput placeholder='PKR 1000' inputType='number' />
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col xs={24} sm={11}>
                <Form.Item name='payment_type' label=' Payment Type'
                 rules={[{ required: true, message: 'Payment Type is required' }]}>
                  <CommonSelect
                    listHeight={120}
                    placeholder='Payment Type'
                    size='large'
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    data={[
                      { key: 'cash', value: 'Cash'},
                      { key: 'cheque', value: 'Cheque'},
                    ]}
                  />
                </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={24}>
              <Form.Item
                label='Purchased By'
                name={'purchased_by_id'}>
                <CommonSelect
                  listHeight={125}
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder='Select Purchased By'
                  data={allEmployees}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}/>
              </Form.Item>
            </Col>
          <Row>
            <Col sm={24}>
              <Form.Item name={'summary'} label='Summary'>
                <StyleCommonInput inputType='textarea' placeholder='Describe Summary' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                name={['attachments']}
                label='Attachment'
              >
                <UploadCardWrapper>
                  <Card className='upload-card'>
                    <Dragger
                      {...fileProps}>
                      <p className='ant-upload-text'>
                        Drop your image here or browse <a>browser</a>
                        <br /> Support .jpg, .jpeg, .png
                      </p>
                    </Dragger>
                    {data && data?.attachments && (
                      <StyleAttach>
                        {attachmentList?.map((item, index) => (
                          <AttachmentDiv>
                            <a key={index} href={item?.attachments} target='_blank' rel='noopener noreferrer'>
                              Attachment {index + 1}
                            </a>
                            <span onClick={() => delAttachment(item?.id, index)}>
                              <DeleteOutlined />
                            </span>
                          </AttachmentDiv>
                        ))}
                      </StyleAttach>
                    )}
                  </Card>
                </UploadCardWrapper>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>

  )
}
)

export default expanseForm

const UploadCardWrapper = styled.div`
  .ant-card{
    border:none;
    .ant-card-body{
      padding: 6px;
      span{
        .ant-upload{
          border-radius: 10px;
        }
      }
    }
  }
`

const AttachmentDiv = styled.div`
  display: flex;
  gap: 20px;

  a{
    width: 100%;
  }
  span{
    display: flex;
    align-items: center;
  }
`

